import { TokenId } from "../../shared/types";
import { BlockText } from "../../shared/noteFormatter";
import { tokenize, Token } from "./nlp";

export type Hit<Entry> = {
  entry: Entry;
  asText?: BlockText[];
  matches?: TokenId[];
  relevance?: number;
  isFromSimon?: boolean;
};
export type HitWithMatch<Entry> = Hit<Entry> & {
  matches: TokenId[];
  asText: BlockText[];
};

/**
 * Fast unicode multiword searcher based on regexp.
 * It is designed with mobile in mind, garbage-collector friendly and low CPU-upfront / fast indexing.
 * We wanted fast load times and low mem footprint.
 * It has been heavily benchmarked up to 30k notes and is optimized for being excellent between 10k to 30k.
 * Benchmarks are available for documentation
 *
 * - the searcher will find all matches to a multiword query in a provided datastructure
 * - the datastructure must have 1 field with a preprocessed sequence of words. Either an array or a map. So it is the responsibility of the user to preprocess its own data.
 *
 * Features:
 * - multiword search
 * - exact match search
 * - limit number of results
 * - find positions of matches for single word and exact match (multiword non exact positions is WIP)
 *
 * Code is sometimes verbose, but it is only meant to be a fast lib beyond the simple complexity logic, not a delicacy to read.
 */

export function removeDiacritics(s: string) {
  return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/**
 * Convert a query string into a list of tokens used for searching.
 * Adds some app-specific logic over the {@link tokenize} function.
 */
function queryToTokens(query: string): Token[] {
  let tokens = tokenize(query);
  tokens = tokens.filter((t) => t.text !== "");
  if (tokens.length === 0) {
    return [];
  } else if (!tokens.every((t) => t.stopword)) {
    // Filter out stopwords, but only if there are other tokens
    tokens = tokens.filter((t) => !t.stopword);
  }
  // Ignore stems. We found they were producing too many false positives.
  tokens = tokens.map((t) => ({ ...t, stem: null }));
  return tokens;
}

/**
 * Given a map of entries and a query, return an array of matching entries as hits.
 *
 * Arrays are used internally past the first word for speed purposes.
 *
 * @param map Map<T> where T is of shape {...any, [field]: string}
 * @param index
 * @param queryString multiword query string.
 */
export function findInMap<T extends { id: string }>(
  map: Map<any, T>,
  index: Map<string, BlockText[]>,
  queryString: string,
): HitWithMatch<T>[] {
  return findInArray(Array.from(map.values()), index, queryString);
}

/**
 * Given an array of entries and a query, return an array of matching entries as hits.
 *
 * @param array entries to filter
 * @param index a map of entry id to text blocks
 * @param queryString a query string.
 */
export function findInArray<T extends { id: string }>(
  array: T[],
  index: Map<string, BlockText[]>,
  queryString: string,
): HitWithMatch<T>[] {
  if (queryString === "") throw new Error("need to search with a non empty query string");
  const tokens = queryToTokens(queryString);
  if (tokens.length === 0) return [];

  let n = array.map((entry) => ({
    entry,
    asText: index.get(entry.id) ?? [],
    matches: [] as TokenId[],
  }));

  tokens.forEach(({ text, stem }) => {
    n = findSubstringInEntries(n, stem || text);
  });

  return n;
}

/**
 * Filters for entries that match the substring.
 *
 * @param hits entries to filter
 * @param substring a string to be matched
 * @returns entries that match the substring
 */
function findSubstringInEntries<T>(hits: HitWithMatch<T>[], substring: string): HitWithMatch<T>[] {
  const r = new RegExp(substringMatchPattern([substring]), "imu");
  const out = [];

  for (const hit of hits) {
    let found = false;
    for (const { tokenId, text } of hit.asText) {
      if (r.test(removeDiacritics(text))) {
        hit.matches.push(tokenId);
        found = true;
      }
    }
    if (found)
      out.push({
        entry: hit.entry,
        asText: hit.asText,
        matches: hit.matches,
      });
  }
  return out;
}

function substringMatchPattern(substrings: string[]): string {
  if (substrings.length === 0) throw new Error("need at least one substring");
  return substrings.map(removeDiacritics).map(escapeRegExp).join("|");
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

/**
 * Returns a score for the relevance of a note based on the keywords.
 * Provide the keywords in the order they appear in the query.
 */
export function relevance(paragraphs: string[], keywords: string[]) {
  let score = 0;
  const title = paragraphs[0].toLowerCase();
  const body = paragraphs.slice(1).join(" ").toLowerCase();

  for (const keyword of keywords) {
    if (title.startsWith(keyword)) {
      // keyword is prefix of title
      score += 10;
    } else if (title.includes(keyword)) {
      // string in title
      score += 5;
    } else if (body.includes(keyword)) {
      // string in body
      score += 3;
    }
  }

  // We give a bonus if the title contains all the keywords, in the order they
  // appear in the query. This makes queries like "foo bar" rank notes titled
  // "foo bar" higer than "bar foo" or "foo baz bar".
  const allKeywordsRegex = new RegExp(keywords.map(escapeRegExp).join("\\s+"), "i");
  const match = allKeywordsRegex.exec(title)?.[0]?.toLowerCase();
  if (match) {
    if (title === match) {
      score += 40;
    } else if (title.startsWith(match)) {
      score += 30;
    } else if (title.includes(match)) {
      score += 20;
    }
  }

  return score;
}
