import { DecorationSet, Decoration } from "prosemirror-view";
import { PluginKey, Plugin, EditorState } from "prosemirror-state";
import { appNoteStore } from "../../../model/services";
import { Note } from "../../../../shared/types";
import logger from "../../../utils/logger";

type State = {
  note: Note;
  attrs: any;
  offset: number;
}[];

/** get all notes info with the offset in the doc */
const getNotes = (doc: EditorState["doc"]): State => {
  const notes: State = [];
  doc.forEach((node, offset) => {
    const note = appNoteStore.get(node.attrs.noteId)!;
    notes.push({
      attrs: node.attrs,
      offset,
      note,
    });
  });
  return notes;
};

const createDom = (note: Note, attrs: any) => () => {
  const domId = `note-debug__${attrs.noteId}`;
  let iButton = document.getElementById(domId);

  if (iButton === null) {
    iButton = document.createElement("div");
    iButton.className = "note-debug-section";
    iButton.id = domId;
  } else {
    const newElement = iButton.cloneNode(true);
    iButton.parentNode!.replaceChild(newElement, iButton);
  }
  Array.from(iButton.childNodes).forEach((cN) => cN.remove());
  iButton.appendChild(document.createTextNode(attrs.noteId));
  // Other note properties useful for debugging
  iButton.appendChild(document.createElement("br"));
  iButton.appendChild(document.createTextNode(note.position.type + ": " + note.position.position));
  iButton.appendChild(document.createElement("br"));
  iButton.onmouseover = () =>
    logger.info("iButton mouseover", {
      context: {
        attrs,
        noteFromAttributes: note,
        noteFromNoteStore: appNoteStore.get(note.id),
      },
    });
  return iButton;
};

export const noteDebuggerPlugin: Plugin<State> = new Plugin({
  key: new PluginKey("debugger"),
  state: {
    init: (_, state) => getNotes(state.doc),
    apply: (tr, pluginState) => {
      return tr.docChanged ? getNotes(tr.doc) : pluginState;
    },
  },
  props: {
    decorations(state) {
      const notes: State = (this as Plugin<State>).getState(state)!;
      const dateDecorations = notes.map(({ offset, note, attrs }) => {
        return Decoration.widget(offset + 1, createDom(note, attrs), {
          side: -1,
          key:
            JSON.stringify({
              ...attrs,
              position: note.position.type + "--" + note.position.position,
            }) + "-note-debugger",
        });
      });
      return DecorationSet.create(state.doc, dateDecorations);
    },
  },
});
