import { Env, env, isRunningInIOSWebview } from "../../utils/environment";

/** The URLs of the native app are useless when copied by the user, so we want to translate them back to ideaflow.app or develop.ideaflow.app URLS */

export function fixPublicUrlInNativeApp(url: string) {
  if (!isRunningInIOSWebview) return url;
  const origin = window.location.origin;
  if (env === Env.prod) {
    return url.replace(origin, "https://ideaflow.app");
  }
  if (env === Env.dev) {
    return url.replace(origin, "https://develop.ideaflow.app");
  }
  if (env === Env.local) {
    return url.replace(origin, "http://localhost:3000");
  }
  return url;
}
