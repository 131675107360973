import { saveAs } from "file-saver";
import { addToast } from "../components/Toast";
import { isTouchDevice } from "./environment";

export async function downloadOrShare(file: File) {
  // We want to use the Web Share API (share sheet) on mobile devices only
  // Chrome on Windows and Safari on macOS support the share sheet but we want to just trigger a normal download there
  if (navigator.share && isTouchDevice) {
    try {
      await navigator.share({ files: [file] });
    } catch (err) {
      if (err instanceof Error && (err + "").startsWith("NotAllowedError")) {
        // If the `navigator.share` call happened 5s or more after the most recent interaction (e.g. button press)
        // Safari will throw the "NotAllowedError". In that case we need to prompt the user again
        addToast({
          content: "Your file is ready:",
          buttons: [
            {
              text: "Download",
              onClick() {
                navigator.share({ files: [file] });
              },
            },
            { text: "Cancel" },
          ],
        });
      }
    }
  } else {
    saveAs(file);
  }
}
