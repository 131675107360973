import { useEffect, useMemo, useState } from "react";
import { addToast } from "../../../components/Toast";
import { isLocal } from "../../../utils/environment";
import { isOfflineError } from "../../../utils/errorCheckers";
import logger from "../../../utils/logger";
import { ApiClient } from "../../../api/client";
import { getLocalAudio } from "./utils";

const TRANSCRIPT_REFRESH_INTERVAL = 500;

export function useTranscript(transcriptGenId: string | null, callback: (transcript: string | null) => void) {
  useEffect(() => {
    if (transcriptGenId === null) return;
    let isCancelled = false;
    const loopFetchTranscript = async () => {
      if (isCancelled) return;
      try {
        const transcript = await fetchTranscript(transcriptGenId);
        if (transcript === null) {
          setTimeout(loopFetchTranscript, TRANSCRIPT_REFRESH_INTERVAL);
        } else {
          callback(transcript);
        }
      } catch (error) {
        if (!isOfflineError(error)) {
          logger.error("Fetching audio transcription failed", { error, report: true });
          if (isLocal) {
            addToast({ content: "Transcription could not be fetched. See console for details", type: "error" });
          } else {
            addToast("Transcription currently unavailable. We will automatically retry; please check again later.");
          }
        } else {
          logger.info("Audio transcription failed due to offline", { error: error, report: true });
        }
        callback(null);
      }
    };

    loopFetchTranscript();
    return () => {
      isCancelled = true;
    };
  }, [transcriptGenId, callback]);
}

async function fetchTranscript(transcriptGenId: string | null): Promise<string | null> {
  if (!transcriptGenId) {
    return null;
  }

  try {
    const apiClient = ApiClient();
    const result = await apiClient.transcripts.getTranscription(transcriptGenId);

    if (result.error) {
      return null;
    }

    return result.data;
  } catch (error) {
    logger.error("Failed to fetch audio transcription", { error, report: true });
    throw error;
  }
}

function useAwaitedMemo<T>(generateValue: () => Promise<T>, deps: unknown[]) {
  const [value, setValue] = useState<T | null>(null);
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      const value = await generateValue();
      if (!isCancelled) {
        setValue(value);
      }
    })();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return value;
}

export function useLocalAudio(audioId: string, deps: unknown[]) {
  const localAudioBlobs = useAwaitedMemo(() => {
    return getLocalAudio(audioId);
  }, deps);

  return useMemo(() => {
    return localAudioBlobs?.map(({ blob, duration }) => ({
      blob,
      url: window.URL.createObjectURL(new Blob([blob], { type: "audio/mpeg" })),
      duration,
    }));
  }, [localAudioBlobs]);
}
