import { NoteId } from "../../../shared/types";

export default class RamDirtySet {
  private _currTx = 0;
  private _upsert: Map<NoteId, number> = new Map();

  listIds() {
    return [...this._upsert.keys()];
  }

  /**
   * Marks the note as dirty and returns the transaction number.
   */
  startUpsert(ids: NoteId | NoteId[]): number {
    if (ids.length === 0) return -1;
    if (this.isOne(ids)) ids = [ids];
    ids.forEach((id) => {
      this._upsert.set(id, this._currTx);
    });
    return this._currTx++;
  }

  isOne = (id: any): id is NoteId => {
    return !Array.isArray(id);
  };

  /**
   * Marks the note as clean if the transaction number is the same.
   * Returns true if the note was marked as clean.
   */
  stopUpsert(id: NoteId, transactionNumber = Number.MAX_VALUE) {
    const currTx = this._upsert.get(id);
    if (currTx !== undefined && currTx <= transactionNumber) {
      this._upsert.delete(id);
      return true;
    }
    return false;
  }

  has(id: NoteId) {
    return this._upsert.has(id);
  }

  getTransactionId(id: NoteId): number | undefined {
    if (this._upsert.has(id)) return this._upsert.get(id);
  }
}
