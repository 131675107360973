import React, { useState } from "react";
import { copyTextToClipboard } from "../export/copyToClipboard";
import { getEditorView } from "../model/atoms";
import { createEditorStateAndSetStoreFromJSON } from "../utils/setEditorStateAndStores";

const copyEditorState = () => {
  const editorView = getEditorView();
  if (!editorView) return;
  const editorStateJson = editorView.state.toJSON();
  copyTextToClipboard(JSON.stringify(editorStateJson));
};

export const EditorStateImporter = () => {
  const [editorStateJson, setEditorStateJson] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          copyEditorState();
        }}
      >
        copy current editor state json
      </button>
      <span>
        <input
          type="text"
          id="paste-json-editor-state"
          placeholder="paste json here"
          onChange={(e) => {
            setEditorStateJson(e.target.value);
          }}
        />
        <button
          onClick={() => {
            const json = JSON.parse(editorStateJson);
            getEditorView()?.updateState(createEditorStateAndSetStoreFromJSON(json));
          }}
        >
          load editor state from json
        </button>
      </span>
    </div>
  );
};
