import isEqual from "lodash.isequal";
import difference from "lodash.difference";
import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { NoteId } from "../../../shared/types";
import { getTopLevelTokensFromContent } from "../bridge";
import { schema } from "../schema";
import { descendNotes } from "./descendNotes";

/** Finds the deepest note path with the autocomplete open */
function findPathWithAutocomplete(doc: Node) {
  let pathWithAutocomplete: string | null = null;
  doc.descendants((node, pos) => {
    const hasAutocomplete = doc.rangeHasMark(pos, pos + node.nodeSize, schema.marks.autocompleteRegion);
    if (!hasAutocomplete) return false;
    if (node.type === schema.nodes.note) {
      pathWithAutocomplete = node.attrs.path;
    }
  });
  return pathWithAutocomplete;
}

export const getNotesThatChangedInTree = (
  oldDoc: EditorState["doc"],
  newDoc: EditorState["doc"],
): { deletes: NoteId[]; upserts: Node[] } => {
  // Collect all note nodes in the old doc
  const oldNotes = new Map<string, Node>();
  const oldNoteIds = new Set<string>();
  descendNotes(oldDoc, (note, pos) => {
    oldNotes.set(note.attrs.path, note);
    if (note.attrs.depth === 0) {
      oldNoteIds.add(note.attrs.noteId);
    }
  });

  const oldAutocompleteNote = findPathWithAutocomplete(oldDoc);
  const newAutocompleteNote = findPathWithAutocomplete(newDoc);

  // Compare the old and new note nodes
  const newNoteIds = new Set<string>();
  const upserts: Node[] = [];
  descendNotes(newDoc, (note, pos) => {
    if (note.attrs.depth === 0) {
      newNoteIds.add(note.attrs.noteId);
    }
    const oldNote = oldNotes.get(note.attrs.path);
    if (!oldNote) {
      // The note node is new
      upserts.push(note);
      return;
    }
    if (oldNote === note) {
      // If the note node is the same object, none of its children have changed,
      // so we don't need to descend into it.
      return false;
    }

    // If the note node is different, but the content is the same,
    // we still consider it unchanged.
    const newContent = getTopLevelTokensFromContent(note.content);
    const oldContent = getTopLevelTokensFromContent(oldNote.content);
    const newHasAutocomplete = newAutocompleteNote === note.attrs.path;
    const oldHasAutocomplete = oldAutocompleteNote === note.attrs.path;
    if (newHasAutocomplete !== oldHasAutocomplete || !isEqual(newContent, oldContent)) {
      upserts.push(note);
    }
  });

  const deletes = difference([...oldNoteIds], [...newNoteIds]);

  return { deletes, upserts };
};
