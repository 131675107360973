import { NodeSpec } from "prosemirror-model";
import { getDefaultAttrs } from "../../utils/attrs";

export const backlinks: NodeSpec = {
  content: "expandedReference*",
  group: "topLevelNode blockNode",
  defining: true,
  selectable: false,
  isolating: true,
  attrs: { path: { default: null }, noteId: { default: null } },
  parseDOM: [
    {
      tag: "div.backlinksSection",
      getAttrs: getDefaultAttrs([]),
    },
  ],
  toDOM(node) {
    return [
      "div",
      {
        class: "backlinksSection",
      },
      0,
    ];
  },
};
