export function CloseKeyboard({ size = 20 }) {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M20,1H4A3,3,0,0,0,1,4V14a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V4A3,3,0,0,0,20,1Zm1,13a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3H20a1,1,0,0,1,1,1Zm-2-2a1,1,0,0,1-1,1H6a1,1,0,0,1,0-2H18A1,1,0,0,1,19,12Zm0-6a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h2A1,1,0,0,1,19,6ZM10,6a1,1,0,0,1,1-1h2a1,1,0,0,1,0,2H11A1,1,0,0,1,10,6ZM5,6A1,1,0,0,1,6,5H8A1,1,0,0,1,8,7H6A1,1,0,0,1,5,6ZM19,9a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h2A1,1,0,0,1,19,9ZM10,9a1,1,0,0,1,1-1h2a1,1,0,0,1,0,2H11A1,1,0,0,1,10,9ZM5,9A1,1,0,0,1,6,8H8a1,1,0,0,1,0,2H6A1,1,0,0,1,5,9Zm9.707,10.293a1,1,0,0,1,0,1.414l-2,2a1,1,0,0,1-1.414,0l-2-2a1,1,0,0,1,1.414-1.414L12,20.586l1.293-1.293A1,1,0,0,1,14.707,19.293Z"
      />
    </svg>
  );
}
