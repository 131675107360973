import { createPortal } from "react-dom";
import { RefObject, useRef, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import styled from "@emotion/styled";
import { Note, NoteId } from "../../../shared/types";
import { useShortcuts } from "../../shortcuts/useShortcuts";
import { getShortcutString } from "../../shortcuts/shortcuts";

import { menuUpdate, mobileNoteIdMenuAtom } from "../../model/atoms";
import { appFolderStore, appNoteStore } from "../../model/services";
import { isTouchDevice } from "../../utils/environment";
import { zIndexes } from "../../utils/style";
import { AddToFolderIcon, ExpandIcon, FolderIcon, JumpToIcon, MoreIcon, PinIcon, UnpinIcon } from "./Icons";
import { FolderList } from "./FolderList";
import { More } from "./More";
import { useHoveredEmulation } from "./useHoveredEmulation";
import { ModalMenu } from "./ModalMenu";
import { useMenuActions } from "./useMenuActions";

// check the css file for the documentation on the z-index

export const Background = styled.div`
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const NoteMenu = ({ editorDiv }: { editorDiv: RefObject<HTMLDivElement> }) => {
  if (isTouchDevice) {
    const el = document.getElementById("note-menu-container");
    return el ? createPortal(<OnModalMenu />, el) : <OnModalMenu />;
  } else {
    return <OnNoteHoverMenu editorDiv={editorDiv} />;
  }
};

export function useNote(noteId: NoteId | null) {
  // the note may change due to the action in the note menu. To get the fresh value of the note, we force a rerender
  // when signalled by the `forceUpdate` in the `useMenuActions`.
  useAtom(menuUpdate);
  if (!noteId) return null;
  return appNoteStore.get(noteId) ?? null;
}

const OnModalMenu = () => {
  const mobileNote = useAtomValue(mobileNoteIdMenuAtom);
  const note = useNote(mobileNote?.id ?? null);

  return note && mobileNote && <ModalMenu note={note} notePath={mobileNote.path} />;
};

/**
 * A small utility hook that retains the old value from the previous render
 * when the freeze parameter is true.
 */
function useFrozenOldValue<T>(value: T, freeze: boolean) {
  const oldValueRef = useRef(value);
  if (!freeze) {
    oldValueRef.current = value;
  }

  return oldValueRef.current;
}

const OnNoteHoverMenu = ({ editorDiv }: { editorDiv: RefObject<HTMLDivElement> }) => {
  const [shouldStayVisible, setShouldStayVisible] = useState(false);

  // Using `useFrozenOldValue` keeps the element and its position data alive
  // when the `shouldStayVisible` is true. This prevents the hover menu
  // from disappearing until it's no longer in use
  const elementWithBBox = useFrozenOldValue(
    useHoveredEmulation(`div[data-noteid]`, editorDiv, true),
    shouldStayVisible,
  );

  const noteId = elementWithBBox?.element.getAttribute(`data-noteid`);
  const path = elementWithBBox?.element.getAttribute(`data-path`);
  const note = useNote(noteId ?? null);

  if (!note || !path || !elementWithBBox) return null;

  // Calculate right position

  const parentWidth = editorDiv.current?.getBoundingClientRect().width || document.body.clientWidth - 5;
  const rightOffset = parentWidth - elementWithBBox.relativeBBox.right;

  const editorDivOffsetTop = editorDiv.current?.offsetTop || 0;
  const noteOffsetTop = elementWithBBox.relativeBBox.top;
  const topOffset = editorDivOffsetTop + noteOffsetTop + 1;

  return (
    <div
      style={{
        position: `absolute`,
        backgroundColor: `transparent`,
        zIndex: zIndexes.noteMenu,
        right: rightOffset,
        top: topOffset,
      }}
    >
      <DropMenu note={note} notePath={path} setShouldStayVisible={setShouldStayVisible} />
    </div>
  );
};

const DropMenu = ({
  note,
  notePath,
  setShouldStayVisible,
}: {
  note: Note;
  notePath: string;
  setShouldStayVisible: (t: boolean) => void;
}) => {
  const { shortcuts } = useShortcuts();
  const DropMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `;
  const a = useMenuActions(
    note,
    notePath,
    () => {
      setShouldStayVisible(false);
    },
    setShouldStayVisible,
  );
  const folder = note.folderId !== null ? appFolderStore.get(note.folderId) : null;
  return (
    <DropMenuContainer>
      <div className="note-menu-actions">
        <button
          className={`note-menu-button folder-icon ${a.menuExpanded() === "folder" ? "expanded" : ""} ${
            note.folderId ? "active" : ""
          }`}
          style={{ minWidth: "initial" }}
          onClick={a.toggleMenuExpanded("folder")}
          role="tooltip"
          data-microtip-position="bottom"
          aria-label="Add to folder"
        >
          {folder && <span style={{ whiteSpace: "nowrap" }}>{folder.name}&nbsp;</span>}
          {note.folderId ? <FolderIcon /> : <AddToFolderIcon />}
        </button>
        <button
          className={`note-menu-button ${note.positionInPinned ? "active" : ""}`}
          onClick={a.togglePin}
          role="tooltip"
          data-microtip-position="bottom"
          aria-label={`${note.positionInPinned ? "Unpin note" : "Pin note"}`}
        >
          {note.positionInPinned ? <UnpinIcon /> : <PinIcon />}
        </button>
        <button
          className="note-menu-button"
          onClick={a.jump}
          role="tooltip"
          data-microtip-position="bottom"
          aria-label={`Jump To ${getShortcutString(shortcuts.jumpTo)}`}
        >
          <JumpToIcon />
        </button>
        <button
          className="note-menu-button"
          onClick={a.openAsPage}
          title="Open as Page"
          role="tooltip"
          data-microtip-position="bottom"
          aria-label={`Open as page ${getShortcutString(shortcuts.openAsPage)}`}
        >
          <ExpandIcon />
        </button>
        <button
          className={`note-menu-button ${a.menuExpanded() === "more" ? "expanded" : ""}`}
          onClick={a.toggleMenuExpanded("more")}
          role="tooltip"
          data-microtip-position="bottom"
          aria-label="More actions"
        >
          <MoreIcon />
        </button>
      </div>
      {a.menuExpanded() === "folder" ? (
        <FolderList note={note} forceUpdate={a.forceUpdate} closeMenu={() => a.toggleMenuExpanded(null)()} />
      ) : (
        ""
      )}
      {a.menuExpanded() === "more" ? <More note={note} notePath={notePath} actions={a} /> : ""}
      {/* a full screen interactive zone to close the menu when clicking outside. */}
      {a.menuExpanded() && (
        <Background
          onClick={() => {
            a.toggleMenuExpanded(null)();
          }}
        />
      )}
    </DropMenuContainer>
  );
};

export { NoteMenu };
