import { ResolvedPos } from "prosemirror-model";
import { EditorState, Selection, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { schema } from "../../schema";
import { preserveExpandedReferencesAround } from "../reference/referenceExpansionUtils";

/** from https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js#L92-L98 */
function findCutBefore($cut: ResolvedPos) {
  if (!$cut.parent.type.spec.isolating)
    for (let i = $cut.depth - 1; i >= 0; i--) {
      if ($cut.index(i) > 0) return $cut.doc.resolve($cut.before(i + 1));
      if ($cut.node(i).type.spec.isolating) break;
    }
  return null;
}

/** Custom patch to merge (after backspace) a selected paragraph
 * with the last paragraph of a list item that immediately precedes it
 */
export const joinListOnBackspaceCommand = (
  state: EditorState,
  dispatch?: EditorView["dispatch"],
  view?: EditorView,
): boolean => {
  if (dispatch == null) return false;

  const tr = state.tr;
  let shouldDispatchTransaction = false;
  preserveExpandedReferencesAround(state, tr, () => {
    // reference expanding/collapsing may have changed selection, so we need to
    // map it through the transaction so far
    const $anchor = tr.doc.resolve(tr.mapping.map(state.selection.$anchor.pos));

    if (!$anchor || (view ? !view.endOfTextblock("backward", state) : $anchor.parentOffset > 0)) {
      return;
    }

    const $cut = findCutBefore($anchor);
    if ($cut == null) return;
    const before = $cut.nodeBefore;
    const after = $cut.nodeAfter;

    if (!before || !after) return;
    if (before.type !== schema.nodes.bulletList) return;

    const endOfListPOS = $cut.doc.resolve($cut.pos - 1).end() + 1;
    const nodeBefore = state.doc.resolve(endOfListPOS);

    // find the position at the end of the last block node in the last list
    let targetOfEnd = nodeBefore.pos;
    const keepGoing = true;
    while (keepGoing) {
      const targetNode = state.doc.resolve(targetOfEnd);
      if (targetNode.nodeBefore?.type === schema.nodes.paragraph) {
        break;
      }
      targetOfEnd -= 1;
    }

    tr.delete($cut.pos, $cut.pos + after.nodeSize)
      .replaceWith(targetOfEnd, targetOfEnd, after)
      .join(targetOfEnd);

    const newSelection = Selection.findFrom(tr.doc.resolve(tr.mapping.map($cut.pos, -1)), -1);

    if (!newSelection) return;

    const mergedNodeSize = $anchor.node().nodeSize;
    const selectionWithOffset = TextSelection.create(
      tr.doc,
      // subtract the size of the merged node (+ 2 for node boundaries) to maintain expected cursor position
      newSelection.anchor - mergedNodeSize + 2,
    );

    tr.setSelection(selectionWithOffset);
    shouldDispatchTransaction = true;
  });

  if (!shouldDispatchTransaction) return false;

  dispatch(tr);
  return true;
};
