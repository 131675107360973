import React, { useCallback, useEffect, useRef, useState } from "react";
import { Pause, Play } from "react-feather";
import Slider from "react-rangeslider";
import { colors } from "../../../utils/style";
import "react-rangeslider/lib/index.css";
import { AudioMenu } from "./SimpleHamburgerMenu";

function formatDuration(duration: number) {
  let string = "";
  if (duration > 3600) {
    const numHours = Math.floor(duration / 3600);
    const hourString = (numHours < 10 ? "0" : "") + numHours.toFixed(0);
    string += `${hourString}:`;
    duration -= 3600 * numHours;
  }

  const numMinutes = Math.floor(duration / 60);
  const minuteString = (numMinutes < 10 ? "0" : "") + numMinutes.toFixed(0);
  string += `${minuteString}:`;
  duration -= 60 * numMinutes;

  const numSeconds = Math.floor(duration);
  const secondString = (numSeconds < 10 ? "0" : "") + numSeconds.toFixed(0);
  string += secondString;

  return string;
}

export default function CustomAudioElement({
  src,
  audioRef,
  onError,
  menuItems,
}: {
  src?: string;
  audioRef: React.RefObject<HTMLAudioElement>;
  onError: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void;
  menuItems: typeof AudioMenu.prototype.props.items;
}) {
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState(0);
  const [timeProgress, setTimeProgress] = useState(0);
  const playPauseStyle: React.CSSProperties = {
    fill: colors.text.accent,
    stroke: "none",
    height: "20px",
    position: "relative",
    top: "2px",
    cursor: "pointer",
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handlePause = () => setPlay(false);
      const handlePlay = () => setPlay(true);
      audioElement.addEventListener("pause", handlePause);
      audioElement.addEventListener("play", handlePlay);
      return () => {
        audioElement.removeEventListener("pause", handlePause);
        audioElement.removeEventListener("play", handlePlay);
      };
    }
  }, [audioRef]);

  const playAnimationRef = useRef<number>(0);
  const repeat = useCallback(() => {
    const currentTime = audioRef.current?.currentTime || 0;
    setTimeProgress(currentTime);
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, setTimeProgress]);

  return (
    <div
      style={{
        padding: "8px",
        backgroundColor: "rgba(0, 160, 173, 0.1)",
        border: "1px solid rgba(0, 160, 173, 0.5)",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", paddingInline: "4px" }}>
        <div>
          {!play ? (
            <Play
              style={playPauseStyle}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                audioRef.current?.play();
                setPlay(true);
                playAnimationRef.current = requestAnimationFrame(repeat);
              }}
            ></Play>
          ) : (
            <Pause
              style={playPauseStyle}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                audioRef.current?.pause();
                setPlay(false);
                cancelAnimationFrame(playAnimationRef.current);
              }}
            ></Pause>
          )}
        </div>
        <GoBackButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const newTimeProgress = Math.max(0, timeProgress - 10);
            setTimeProgress(newTimeProgress);
            if (audioRef.current) {
              audioRef.current.currentTime = newTimeProgress;
            }
          }}
        ></GoBackButton>
        <GoForwardButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const newTimeProgress = Math.min(duration, timeProgress + 10);
            setTimeProgress(newTimeProgress);
            if (audioRef.current) {
              audioRef.current.currentTime = newTimeProgress;
            }
          }}
        ></GoForwardButton>
      </div>
      <div style={{ color: colors.text.accent, fontSize: "14px" }}>{formatDuration(timeProgress)}</div>
      <div style={{ flex: 1, marginInline: "6px" }}>
        {/*eslint-disable-next-line */}
        {/*@ts-ignore */}
        <Slider
          value={timeProgress}
          max={duration}
          step={0.01}
          // the type definition of the onChange function doesn't include the event
          // eslint-disable-next-line
          // @ts-ignore
          onChange={(value: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setTimeProgress(value);
            if (audioRef.current) {
              audioRef.current.currentTime = value;
            }
            e.stopPropagation();
            e.preventDefault();
            if (e.nativeEvent) {
              e.nativeEvent.stopImmediatePropagation();
            }
          }}
        ></Slider>
      </div>
      <div style={{ color: colors.text.accent, fontSize: "14px" }}>{formatDuration(duration)}</div>
      <AudioMenu items={menuItems} alignLeft={false}></AudioMenu>
      <audio
        src={src}
        ref={audioRef}
        onError={onError}
        onLoadedMetadata={() => {
          setTimeProgress(audioRef.current?.currentTime || 0);
          setDuration(audioRef.current?.duration || 0);
        }}
        onEnded={() => {
          cancelAnimationFrame(playAnimationRef.current);
          setPlay(false);
        }}
      ></audio>
    </div>
  );
}

function GoBackButton({ onClick }: { onClick?: React.MouseEventHandler<HTMLButtonElement> }) {
  return (
    <button
      style={{ background: "transparent", border: "none", padding: 0, height: "24px", color: colors.text.accent }}
      onClick={onClick}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill={colors.text.accent} xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.99 1V5C16.41 5 19.99 8.58 19.99 13C19.99 17.42 16.41 21 11.99 21C7.56999 21 3.98999 17.42 3.98999 13H5.98999C5.98999 16.31 8.67999 19 11.99 19C15.3 19 17.99 16.31 17.99 13C17.99 9.69 15.3 7 11.99 7V11L6.98999 6L11.99 1ZM10.04 16H10.89V11.73H10.8L9.02999 12.36V13.05L10.04 12.74V16ZM15.17 14.24C15.17 14.56 15.14 14.84 15.07 15.06C15 15.28 14.9 15.48 14.78 15.63C14.66 15.78 14.5 15.89 14.33 15.96C14.16 16.03 13.96 16.06 13.74 16.06C13.52 16.06 13.33 16.03 13.15 15.96C12.97 15.89 12.82 15.78 12.69 15.63C12.56 15.48 12.46 15.29 12.39 15.06C12.32 14.83 12.28 14.56 12.28 14.24V13.5C12.28 13.18 12.31 12.9 12.38 12.68C12.45 12.46 12.55 12.26 12.67 12.11C12.79 11.96 12.95 11.85 13.12 11.78C13.29 11.71 13.49 11.68 13.71 11.68C13.93 11.68 14.12 11.71 14.3 11.78C14.48 11.85 14.63 11.96 14.76 12.11C14.89 12.26 14.99 12.45 15.06 12.68C15.13 12.91 15.17 13.18 15.17 13.5V14.24ZM14.28 12.9C14.31 13.03 14.32 13.19 14.32 13.38H14.31V14.35C14.31 14.54 14.29 14.7 14.27 14.83C14.25 14.96 14.21 15.07 14.16 15.15C14.11 15.23 14.04 15.29 13.97 15.32C13.9 15.35 13.81 15.37 13.72 15.37C13.63 15.37 13.55 15.35 13.47 15.32C13.39 15.29 13.33 15.23 13.28 15.15C13.23 15.07 13.19 14.96 13.16 14.83C13.13 14.7 13.12 14.54 13.12 14.35V13.38C13.12 13.19 13.13 13.03 13.16 12.9C13.19 12.77 13.23 12.67 13.28 12.59C13.33 12.51 13.4 12.45 13.47 12.42C13.54 12.39 13.63 12.37 13.72 12.37C13.81 12.37 13.89 12.39 13.97 12.42C14.05 12.45 14.11 12.51 14.16 12.59C14.21 12.67 14.25 12.77 14.28 12.9Z"
        />
      </svg>
    </button>
  );
}

function GoForwardButton({ onClick }: { onClick?: React.MouseEventHandler<HTMLButtonElement> }) {
  return (
    <button
      style={{ background: "transparent", border: "none", padding: 0, height: "24px" }}
      onClick={onClick}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={colors.text.accent}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 19C15.31 19 18 16.31 18 13H20C20 17.42 16.42 21 12 21C7.58 21 4 17.42 4 13C4 8.58 7.58 5 12 5V1L17 6L12 11V7C8.69 7 6 9.69 6 13C6 16.31 8.69 19 12 19ZM10.9 11.73V16H10.05V12.74L9.04 13.05V12.36L10.81 11.73H10.9ZM14.32 11.78C14.14 11.71 13.95 11.68 13.73 11.68C13.51 11.68 13.32 11.71 13.14 11.78C12.96 11.85 12.81 11.96 12.69 12.11C12.57 12.26 12.46 12.45 12.4 12.68C12.34 12.91 12.3 13.18 12.3 13.5V14.24C12.3 14.56 12.34 14.84 12.41 15.06C12.48 15.28 12.58 15.48 12.71 15.63C12.84 15.78 12.99 15.89 13.17 15.96C13.35 16.03 13.54 16.06 13.76 16.06C13.98 16.06 14.17 16.03 14.35 15.96C14.53 15.89 14.68 15.78 14.8 15.63C14.92 15.48 15.02 15.29 15.09 15.06C15.16 14.83 15.19 14.56 15.19 14.24V13.5C15.19 13.18 15.15 12.9 15.08 12.68C15.01 12.46 14.91 12.26 14.78 12.11C14.65 11.96 14.49 11.85 14.32 11.78ZM14.29 14.83C14.32 14.7 14.33 14.54 14.33 14.35H14.34V13.38C14.34 13.19 14.33 13.03 14.3 12.9C14.27 12.77 14.23 12.67 14.18 12.59C14.13 12.51 14.06 12.45 13.99 12.42C13.92 12.39 13.83 12.37 13.74 12.37C13.65 12.37 13.57 12.39 13.49 12.42C13.41 12.45 13.36 12.51 13.3 12.59C13.24 12.67 13.21 12.77 13.18 12.9C13.15 13.03 13.14 13.19 13.14 13.38V14.35C13.14 14.54 13.15 14.7 13.18 14.83C13.21 14.96 13.25 15.07 13.3 15.15C13.35 15.23 13.42 15.29 13.49 15.32C13.56 15.35 13.65 15.37 13.74 15.37C13.83 15.37 13.91 15.35 13.99 15.32C14.07 15.29 14.13 15.23 14.18 15.15C14.23 15.07 14.26 14.96 14.29 14.83Z"
        />
      </svg>
    </button>
  );
}
