import { Note } from "../../shared/types";
import { SearchQuery, isSortable, SortBy } from "./SearchQuery";
import { Hit, relevance } from "./find";

export const sortNotes = (results: Hit<Note>[], searchQuery: SearchQuery) => {
  if (isSortable(searchQuery)) {
    if (!!searchQuery.keywordsList && searchQuery.sortBy === SortBy.relevance) {
      return sortByRelevance(results, searchQuery.keywordsList);
    } else if (searchQuery.sortBy === SortBy.lastUpdated) {
      return sortByLastUpdate(results);
    } else if (searchQuery.sortBy === SortBy.oldest) {
      return sortByOldest(results);
    } else if (searchQuery.sortBy === SortBy.position) {
      return sortByPosition(results);
    }
  }
  return sortByPosition(results);
};

/**
 * Notes are sorted by their position field in an increasing lexicographic order
 *
 * All the notes with the type: "on-top-provisional" position are placed first
 * The createdAt is used as a tie-breaker
 */
export function sortByPosition(result: Hit<Note>[]) {
  return result.sort((a: Hit<Note>, b: Hit<Note>) => {
    if (a.isFromSimon !== b.isFromSimon) {
      return a.isFromSimon ? +1 : -1;
    } else {
      return compareNotesPositions(a.entry, b.entry);
    }
  });
} /** Helper function sorting the notes based on their positions */

export function compareNotesPositions(noteA: Note, noteB: Note) {
  const positionA = noteA.position;
  const positionB = noteB.position;
  if (positionA.type !== positionB.type) {
    if (positionA.type === "on-top-provisional") {
      return -1;
    } else {
      return +1;
    }
  }
  if (positionA.position < positionB.position) return -1;
  if (positionA.position > positionB.position) return 1;
  // when positions are equal, we make the order stable by ordering by createdAt
  if (noteA.createdAt < noteB.createdAt) return -1;
  if (noteA.createdAt > noteB.createdAt) return 1;
  return 0;
}

function sortByLastUpdate(result: Hit<Note>[]) {
  return result.sort((a: Hit<Note>, b: Hit<Note>) => {
    if (a.entry.updatedAt > b.entry.updatedAt) return -1;
    if (a.entry.updatedAt < b.entry.updatedAt) return 1;
    return 0;
  });
}

function sortByOldest(result: Hit<Note>[]) {
  // Sorts the notes by createdAt from oldest to newest
  return result.sort((a: Hit<Note>, b: Hit<Note>) => {
    if (a.entry.createdAt < b.entry.createdAt) return -1;
    if (a.entry.createdAt > b.entry.createdAt) return 1;
    return 0;
  });
}

export function sortByRelevance(result: Hit<Note>[], keywords: string[]) {
  result.forEach((hit) => {
    const text = (hit.asText ?? []).map((h) => h.text);
    hit.relevance = relevance(text, keywords);
  });

  return result.sort((a: Hit<Note>, b: Hit<Note>) => {
    const relA = a.relevance!;
    const relB = b.relevance!;
    // sort by relevance
    if (relA < relB) return 1;
    if (relA > relB) return -1;
    // then by updatedAt
    if (a.entry.updatedAt < b.entry.updatedAt) return 1;
    if (a.entry.updatedAt > b.entry.updatedAt) return -1;
    return 0;
  });
}
