import { Plugin, PluginKey } from "prosemirror-state";
import { undoDepth, redoDepth } from "prosemirror-history";
import { getDefaultStore } from "jotai";
import { undoDepthAtom, redoDepthAtom } from "../../../model/atoms";

const PLUGIN_KEY = new PluginKey("historyWatcherPluginKey");

export const historyWatcherPlugin = new Plugin<null>({
  key: PLUGIN_KEY,
  view: () => {
    return {
      update: ({ state }) => {
        const store = getDefaultStore();
        const prevUndo = store.get(undoDepthAtom);
        const prevRedo = store.get(redoDepthAtom);
        const undo = undoDepth(state);
        const redo = redoDepth(state);
        if (undo !== prevUndo) {
          store.set(undoDepthAtom, undo);
        }
        if (redo !== prevRedo) {
          store.set(redoDepthAtom, redo);
        }
      },
    };
  },
});
