import { getHashtagSuggestions } from "../autocomplete/getSuggestions";
import { useDebouncedMemo } from "../autocomplete/useDebouncedMemo";
import { useSearchQuery } from "./useSearchQuery";

export const useSearchSuggestions = (cueText: string) => {
  const searchQuery = useSearchQuery();

  return useDebouncedMemo(
    () => {
      if (!cueText) return [];
      // We only suggest hashtags right now, and only if the cue text starts with a #
      if (!cueText.startsWith("#")) return [];
      // Exclude the # from the cue text
      const suggestions = getHashtagSuggestions(cueText.slice(1));
      // Don't suggest any hashtag already in the search query
      return suggestions.filter((suggestion) => !searchQuery.hashtagsList?.includes(suggestion.id));
    },
    200,
    [searchQuery, cueText],
  );
};
