import styled from "@emotion/styled";
import { colors, mediaQueries } from "../../utils/style";

export const NavButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  min-width: 40px;
  min-height: 40px;
  background: transparent;
  border: 0;
  color: var(--color-text-secondary);

  :disabled {
    color: var(--color-text-tertiary);
    cursor: default;
  }

  -webkit-tap-highlight-color: transparent;
  :active {
    background-color: ${colors.bg.accent.tertiary};
  }

  ${mediaQueries.mobile} {
    &.desktop-only {
      display: none;
    }
  }

  padding: 0;
`;
