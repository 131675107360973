import { EditorState, Plugin, PluginKey } from "prosemirror-state";

function getAudioIds(doc: EditorState["doc"]) {
  const audioIds: string[] = [];
  doc.descendants((node) => {
    if (node.type.name === "audioInsert") {
      audioIds.push(node.attrs.audioId);
    }
  });
  return audioIds;
}

export const createAudioPlugin = (stopRecording: (audioId: string) => void) => {
  return new Plugin({
    key: new PluginKey("audio"),
    view: () => {
      return {
        update: (view, prevState) => {
          // This function finds the difference between the old and new
          // states and figures which audioIds to stop recording for.
          // If an AudioInsertView is deleted, that means, in the previous state
          // we had a greater number of audioIds.

          const audioIds = getAudioIds(view.state.doc);
          const prevAudioIds = getAudioIds(prevState.doc);

          if (prevAudioIds.length <= audioIds.length) {
            return;
          }

          const audioIdsToStopRecording = new Set(prevAudioIds.filter((id) => !audioIds.includes(id)));
          audioIdsToStopRecording.forEach((id) => {
            stopRecording(id);
          });
        },
      };
    },
  });
};
