import { useAtomValue } from "jotai";
import { Selection } from "prosemirror-state";
import { Mic } from "react-feather";

import { AudioInsertToken } from "../../../shared/types";
import { addToast } from "../../components/Toast";
import { topLevelTokenToProsersmirorNodes } from "../../editor/bridge";
import { globalAudioRecordingCoordinator } from "../../editor/features/audioInsert/audioRecordingCoordinator";
import { schema } from "../../editor/schema";
import { findDescendant } from "../../editor/utils/find";
import { editorViewAtom, getEditorView } from "../../model/atoms";
import { makeParagraph } from "../../model/defaults";
import { generateId } from "../../model/generateId";
import logger from "../../utils/logger";
import { useTapOrLongPress } from "../../utils/useTapOrLongPress";
import { NEW_AUDIO_BUTTON_ID } from "../../utils/constants";
import { useCreateNoteAtTop } from "../hooks/useCreateNote";
import { useShortcuts } from "../../shortcuts/useShortcuts";
import { getShortcutString } from "../../shortcuts/shortcuts";
import { NavButton } from "./NavButton";

export function useInsertAudio() {
  const createNote = useCreateNoteAtTop();
  return async (createNewNote = false) => {
    const editorView = getEditorView();
    if (!editorView) throw new Error("Editor view is not available");
    const audioId = generateId();
    const audioInsertToken: AudioInsertToken = {
      type: "audioInsert",
      tokenId: generateId(),
      audioId,
      updatedAt: Date.now(),
      transcriptGenId: null,
      // Set to null to distinguish from legacy audio which has chunkCount = 0
      chunkCount: null,
      durations: null,
      transcript: null,
      isRevealed: true,
      transcriptLanguageOverride: null,
    };

    // When inserting into a new note, include a paragraph before and after
    // so the user has somewhere to type. When inserting below the current
    // selection, you only need to include a paragraph after.
    if (createNewNote) {
      createNote([makeParagraph(), audioInsertToken, makeParagraph()]);
    } else if (editorView.state.selection.$anchor.node(1)?.type === schema.nodes.note) {
      const sel = editorView.state.selection;
      const tr = editorView.state.tr;
      const { nodes } = topLevelTokenToProsersmirorNodes([audioInsertToken, makeParagraph()]);
      tr.insert(sel.$anchor.pos, nodes);
      tr.setSelection(Selection.near(tr.doc.resolve(sel.$anchor.pos), -1));
      editorView.dispatch(tr);
    } else {
      logger.warn("Cannot insert audio at selection. Inserting into new note.");
      createNote([makeParagraph(), audioInsertToken, makeParagraph()]);
    }

    try {
      await globalAudioRecordingCoordinator.startRecording(audioId);
    } catch (e) {
      logger.error("Audio recording cannot be started", { error: e });
      addToast({
        content: "Couldn't start the recording",
        type: "error",
      });
      globalAudioRecordingCoordinator.abandonRecording(audioId);
      // Remove the audio insert node
      const { state, dispatch } = editorView;
      const [node, pos] = findDescendant(state.doc, (node) => {
        return node.type === schema.nodes.audioInsert && node.attrs.audioId === audioId;
      });
      if (!node) return;
      dispatch(state.tr.delete(pos, pos + node.nodeSize));
    }
  };
}

export function MicButton() {
  const { shortcuts } = useShortcuts();
  const insertAudio = useInsertAudio();
  const editorView = useAtomValue(editorViewAtom);
  const { handleDown, handleUp } = useTapOrLongPress({
    // Insert inside new note at the top
    onTap: () => insertAudio(true),
    // Insert at selection if editor has focus, otherwise insert inside
    // new note at the top
    onLongPress: () => insertAudio(!editorView?.hasFocus()),
  });
  return (
    <NavButton
      id={NEW_AUDIO_BUTTON_ID}
      aria-label={`Click to create a new audio note ${getShortcutString(
        shortcuts.toggleRecording,
      )}\nLong press to insert audio at selection`}
      data-microtip-position="bottom-left"
      role="tooltip"
      onTouchStart={handleDown}
      onTouchEnd={handleUp}
      onMouseDown={handleDown}
      onMouseUp={handleUp}
    >
      <Mic
        size={20}
        style={{
          marginLeft: 1,
          marginBottom: 1,
        }}
      />
    </NavButton>
  );
}
