import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { useApiClient } from "../api/client";
import { isSimonSearchLoadingAtom } from "../model/atoms";
import { getUserSettings } from "../model/userSettings";
import { isSimonSearchEnabled } from "./environment";
import logger from "./logger";

export const useSimonSearch = () => {
  const apiClient = useApiClient();
  const setIsSimonSearchLoading = useSetAtom(isSimonSearchLoadingAtom);

  const simonSearch = useCallback(
    async (q: string) => {
      if (!isSimonSearchEnabled || !getUserSettings().simonSearchEnabled || !q) return [];
      setIsSimonSearchLoading(true);
      try {
        const res = await apiClient.simon.search(q);
        const noteIds = res.map((note) => note.id);
        return Array.from(new Set(noteIds));
      } catch (e) {
        logger.error("Failed to search Simon", { error: e });
        return [];
      } finally {
        setIsSimonSearchLoading(false);
      }
    },
    [apiClient, setIsSimonSearchLoading],
  );

  const simonQuery = useCallback(
    async (q: string) => {
      if (!isSimonSearchEnabled || !q) return { answer: "Sorry but Simon search is currently disabled", ids: [] };
      return await apiClient.simon.query(q);
    },
    [apiClient],
  );

  return { simonSearch, simonQuery };
};
