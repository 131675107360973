import { DOMSerializer, Slice } from "prosemirror-model";
import { EditorView } from "prosemirror-view";

// Based off prosemirror implementation https://github.com/ProseMirror/prosemirror-view/blob/ff74b1d4e973ccf3e5af524f680c7de1ffca0d1c/src/clipboard.ts#L5
export function serializeForClipboard(view: EditorView, slice: Slice) {
  const serializer = DOMSerializer.fromSchema(view.state.schema);

  const html = document.createElement("div");
  html.appendChild(serializer.serializeFragment(slice.content));

  const plainText =
    view.someProp("clipboardTextSerializer", (f) => f(slice, view)) ||
    slice.content.textBetween(0, slice.content.size, "\n\n");

  return { html, plainText };
}
