import { FetchWrapper } from "../fetchWrapper";

export const FileApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Uploads a file to the server.
     * @param dataUri Base64 string representation of the file.
     * @param fileName Name of the file.
     */
    upload: async (dataUri: string, fileName: string): Promise<void> =>
      client.post("/files", {
        dataUri,
        fileName,
      }),
    getMetadata: async (fileName: string): Promise<{ creationDate: string }> =>
      client.get(`/files/${fileName}/metadata`),
    getOCRText: async (dataUri: string): Promise<{ data: string | null; error: string | null }> =>
      client.post(`/ocr`, {
        dataUri,
      }),
  };
};
