import { LastUpdated } from "../ram/LastUpdated";
import { ManyToManyIndex } from "../ram/ManyToManyIndex";
import { NoteId, Note, Hashtag, TopLevelToken, NotePosition, SettledNote } from "../../../shared/types";
import { BlockText, FormatNoteOptions, NoteFormatter } from "../../../shared/noteFormatter";
import { applyOnInline, mapBlockTokens } from "../../../shared/tokenIterators/mapBlockTokens";
import { isWorker } from "../../utils/environment";
import { fixTopLevelTokens } from "../../export/fixTokens";
import { generateId } from "../generateId";
import { formatInsertedAt } from "../../editorPage/utils/insertedAt";
import { makeNotePositionGenerator, settleNotes } from "../positionGenerator";
import { compareNotesPositions } from "../../search/sortUtils";
import { HitWithMatch, findInArray } from "../../search/find";
import { SimonSyncProvider } from "../../utils/SimonSyncProvider";
import { getUserId } from "../atoms";
import { LocalStore } from "./LocalStore";
import { FirstLineIndex } from "./FirstLineIndex";

if (isWorker) {
  throw new Error("The worker needs to use the persisted note list");
}

export type NoteInsert = Partial<Omit<Note, "updatedAt">> & {
  strings?: string[];
};

// This type transforms the provided type argument to one that requires the presence
// of the specified properties (they are no longer optional)
type RequireProp<T, P extends keyof T> = Omit<T, P> & { [K in P]-?: T[K] };

type NoteCreate = NoteInsert;

export type NoteUpdate = {
  id: NoteId;
  tokens?: TopLevelToken[];
  position?: NotePosition;
  readAll?: boolean;
  isSharedPrivately?: boolean;
  directUrlOnly?: boolean;
  folderId?: string | null;
  positionInPinned?: string | null;
  deletedAt?: Date | null;
  expansionSetting?: "auto" | "expand" | "collapse";
  simonHash?: string | null;
  simonUpdatedAt?: Date | null;
};

type NoteUpsert = Partial<Note> & { id: NoteId };

/**
 * Orchestrates Notes CRUD operations.
 *
 * Creating a note require several side effects:
 * - storing it in RAM
 * - indexing it
 * - sending it to DB
 */
export class NoteStore {
  private localStore: LocalStore;
  public notePositions: ReturnType<typeof makeNotePositionGenerator>;
  public noteFormatter: NoteFormatter;
  private notes: Map<NoteId, Note>;
  public hashtags: ManyToManyIndex;
  public backlinks: ManyToManyIndex;
  public audio: ManyToManyIndex;
  private lastUpdatedHashtags: LastUpdated;
  private textIndex: Map<NoteId, BlockText[]>;
  private firstLineIndex: FirstLineIndex;
  private simonSyncProvider: SimonSyncProvider;

  constructor({
    localStore,
    noteFormatter = new NoteFormatter(),
    notes = new Map(),
    hashtags = new ManyToManyIndex(true),
    backlinks = new ManyToManyIndex(false),
    audio = new ManyToManyIndex(false),
    lastUpdatedHashtags = new LastUpdated(30),
    textIndex = new Map(),
  }: {
    localStore: LocalStore;
    noteFormatter?: NoteFormatter;
    notes?: Map<NoteId, Note>;
    hashtags?: ManyToManyIndex;
    backlinks?: ManyToManyIndex;
    audio?: ManyToManyIndex;
    lastUpdatedHashtags?: LastUpdated;
    textIndex?: Map<NoteId, BlockText[]>;
  }) {
    this.localStore = localStore;
    this.localStore.noteHandler = (note) => {
      this.upsert([note], false);
    };
    this.noteFormatter = noteFormatter;
    this.notes = notes;
    this.hashtags = hashtags;
    this.backlinks = backlinks;
    this.audio = audio;
    this.lastUpdatedHashtags = lastUpdatedHashtags;
    this.textIndex = textIndex;
    this.notePositions = makeNotePositionGenerator(this.getAll.bind(this));
    this.firstLineIndex = new FirstLineIndex();
    this.simonSyncProvider = new SimonSyncProvider(
      (noteId) => this.get(noteId),
      (note) => this.noteFormatter.noteToString(note, (n) => this.get(n)),
      (updates) => {
        if (updates.length === 0) return;
        const newNotes = updates
          .map((u) => {
            const note = this.get(u.id);
            if (!note) return null;
            return { ...note, simonHash: u.simonHash, simonUpdatedAt: u.simonUpdatedAt };
          })
          .filter((n) => n !== null) as Note[];
        this.set(newNotes, true, false);
      },
    );
  }

  /**
   * Takes partial note and returns a note object with missing properties
   * added with default values.
   * The note object is not inserted into the list.
   * */
  private create(note: NoteCreate = {}): Note {
    const tokens = fixTopLevelTokens(
      note.tokens ||
        (note.strings || [""]).map((string) => ({
          type: "paragraph",
          tokenId: generateId(),
          content: [{ type: "text", marks: [], content: string }],
        })),
    );
    const start = new Date();
    return {
      id: note.id || generateId(),
      authorId: note.authorId || getUserId() || "",
      tokens,
      createdAt: note.createdAt || start,
      updatedAt: start,
      insertedAt: note.insertedAt || formatInsertedAt(note.createdAt || new Date()),
      position: note.position || this.notePositions.generateFirst()[0],
      folderId: note.folderId || null,
      deletedAt: note.deletedAt || null,
      readAll: note.readAll || false,
      isSharedPrivately: note.isSharedPrivately || false,
      directUrlOnly: note.directUrlOnly || true,
      expansionSetting: note.expansionSetting || "auto",
    };
  }

  renameHashtag(oldHashtag: string, newHashtag: string) {
    const noteIds = this.hashtags.getNoteIdsForItem(oldHashtag);
    const newNotes: Note[] = [];
    noteIds
      .map((id) => this.notes.get(id))
      .filter((v): v is Note => Boolean(v))
      .forEach((note) => {
        const newNote = { ...note };
        applyOnInline(newNote.tokens, (t) => {
          if (t.type === "hashtag" && t.content === oldHashtag) {
            t.content = newHashtag;
          }
        });
        newNotes.push(newNote);
      });
    this.update(newNotes);
  }

  /**
   * Takes partial notes objects, each with an id of a note which already exists,
   * and updates the corresponding notes with the provided properties.
   */
  update(updateNotes: NoteUpdate[] | NoteUpdate, shouldPersist = true) {
    const start = new Date();
    if (!Array.isArray(updateNotes)) updateNotes = [updateNotes];
    const { wasANoteUndeleted, notes } = this.computeMergedNotes(
      updateNotes.map((note) => {
        const oldNote = this.notes.get(note.id);
        if (!oldNote) throw new Error(`cannot update a non existing note (id: ${note.id})`);

        if (note.tokens) {
          note.tokens = fixTopLevelTokens(note.tokens);
        }
        return { ...note, updatedAt: start };
      }),
      false,
    );
    this.set(notes, shouldPersist);
    if (wasANoteUndeleted) this.reindexAllNotes();
    return notes;
  }

  /** Takes partial note objects, fills with defaults, and inserts into the list.*/
  insert(insertNotes: NoteInsert[] | NoteInsert = {}, shouldPersist = true) {
    const start = new Date();
    if (!Array.isArray(insertNotes)) insertNotes = [insertNotes];
    const notes: Note[] = insertNotes.map((note) => {
      if (note.id && this.notes.has(note.id)) {
        throw new Error(`Note with id ${note.id} already exists`);
      }
      return this.create({ ...note, createdAt: note.createdAt || start });
    });
    this.set(notes, shouldPersist);
    return notes;
  }

  /**
   * Same as insert method, but positions the inserted notes after the note with
   * the given id. If insertedAt or folderId are not provided, they will be
   * copied over from that note.
   */
  insertAfter(noteId: string, insertNotes: NoteInsert[] | NoteInsert = {}, shouldPersist = true) {
    if (!Array.isArray(insertNotes)) insertNotes = [insertNotes];
    const noteBefore = this.get(noteId);
    if (!noteBefore) throw new Error(`Note with id ${noteId} does not exist`);
    const positions = this.notePositions.generateAfter(noteBefore.position, insertNotes.length);
    const notes = insertNotes.map((note, i) => {
      if (note.id && this.notes.has(note.id)) {
        throw new Error(`Note with id ${note.id} already exists`);
      }
      // We check for undefined because we want callers to be able to set these to null
      note.insertedAt = note.insertedAt === undefined ? noteBefore.insertedAt : note.insertedAt;
      note.folderId = note.folderId === undefined ? noteBefore.folderId : note.folderId;
      note.position = positions[i];
      return this.create(note);
    });
    this.set(notes, shouldPersist);
    return notes;
  }

  /** Sets the deletedAt field to the current date */
  delete(ids: NoteId[], shouldPersist = true) {
    return ids.map((id) => this.update({ id, deletedAt: new Date() }, shouldPersist));
  }

  private computeMergedNotes(updates: RequireProp<Partial<Note>, "id">[], allowNewNoteCreation: boolean) {
    let wasANoteUndeleted = false;

    const notes: Note[] = updates.map((note) => {
      const existingNote = this.notes.get(note.id);
      if (!existingNote) {
        if (allowNewNoteCreation) {
          const newNote = this.create(note);
          return Object.assign(newNote, note);
        } else {
          throw new Error(`Note with id ${note.id} does not exist`);
        }
      } else {
        wasANoteUndeleted = wasANoteUndeleted || !!(existingNote.deletedAt && note.deletedAt === null);
        return { ...existingNote, ...note };
      }
    });
    return { wasANoteUndeleted, notes };
  }

  private set(notes: Note[], shouldPersist = true, shouldSyncToSimon = true) {
    for (const note of notes) {
      this.notes.set(note.id, note);
    }
    // we batch all indexes after the note is correct in the main repo
    // this is due to dependencies such as + references.
    for (const note of notes) {
      this.upsertIndexes(note);
    }
    if (shouldPersist && notes.length > 0) {
      this.localStore.upsertNotes(notes);
    }
    if (shouldSyncToSimon) {
      this.simonSyncProvider.syncMany(notes);
    }
  }

  getStats() {
    return {
      sampleDate: Date.now(),
      noteCount: this.notes.size,
      mostRecentUpdatedAt: Math.max(...this.getAll().map((n) => n.updatedAt.getTime())),
    };
  }

  clear() {
    this.notes.clear();
    this.hashtags.clear();
    this.backlinks.clear();
    this.audio.clear();
    this.lastUpdatedHashtags.clear();
    this.firstLineIndex.clear();
  }

  private reindexAllNotes() {
    for (const note of this.getAll()) {
      this.upsertIndexes(note);
    }
  }

  private upsertIndexes(note: Note) {
    if (note.deletedAt) {
      this.hashtags.delete(note.id, false);
      this.backlinks.delete(note.id, true);
      this.audio.delete(note.id, true);
      this.textIndex.delete(note.id);
      return;
    }
    const hashtagsInNote: Hashtag[] = [];
    const referencesInNote: string[] = [];
    if (note.tokens) {
      applyOnInline(note.tokens, (t) => {
        if (t.type === "hashtag") {
          hashtagsInNote.push({ content: t.content });
        } else if (t.type === "spaceship") {
          referencesInNote.push(t.linkedNoteId);
        }
      });
    }

    // hashtags
    const hashtagsNoLongerInDoc = this.hashtags.delete(note.id, false);
    const hashtagIdsInNote = hashtagsInNote.map((h) => h.content);
    this.hashtags.set(note.id, hashtagIdsInNote);

    // backlinks
    this.backlinks.delete(note.id, false); // this function is only called on upsert so don't need to check references
    this.backlinks.set(note.id, referencesInNote);

    // audio
    this.audio.delete(note.id, false);
    const audioIdsInNote: string[] = [];
    note.tokens.forEach((t) => {
      mapBlockTokens((blockToken) => {
        if (blockToken.type === "audioInsert") {
          audioIdsInNote.push(blockToken.audioId);
        }
      })(t);
    });
    this.audio.set(note.id, audioIdsInNote);

    // search index
    if (note.tokens && !note.deletedAt) {
      this.textIndex.set(
        note.id,
        this.noteFormatter.getNoteAsBlockTexts(note.tokens, (n) => this.get(n)),
      );
    } else {
      this.textIndex.delete(note.id);
    }

    // last updated
    // @todo track it upstream, when we edit
    this.lastUpdatedHashtags.remove(hashtagsNoLongerInDoc);
    this.lastUpdatedHashtags.update(hashtagIdsInNote);

    // first line index
    const firstLine = this.noteFormatter.getFirstLine(note);
    if (firstLine) {
      this.firstLineIndex.set(note.id, firstLine);
    }
  }

  // *****************
  // Retrieval methods
  // *****************

  get(id: NoteId) {
    return this.notes.get(id);
  }

  getAll(withDeleted = false) {
    if (withDeleted) return [...this.notes.values()];
    else return [...this.notes.values()].filter((e) => !e.deletedAt);
  }

  has(id: NoteId) {
    const element = this.notes.get(id);
    return element && !element.deletedAt;
  }

  hasOrHad(id: NoteId) {
    return this.notes.has(id);
  }

  getCanonicalByName(name: string) {
    if (!name) return null;

    const matchingNoteIds = this.firstLineIndex.get(name);
    if (!matchingNoteIds) return null;

    const candidateNotes = matchingNoteIds
      .map((id) => this.get(id))
      .filter((n): n is Note => !!n)
      .filter((n) => !n.deletedAt);
    if (candidateNotes.length === 0) return null;
    if (candidateNotes.length === 1) return candidateNotes[0];

    // Tie-breaker: canonical is the note with the most backlinks
    return candidateNotes.sort(
      (a, b) => this.backlinks.getNoteCountForItem(a.id) - this.backlinks.getNoteCountForItem(b.id),
    )[0];
  }

  getAllWithTextMatch(queryString: string): HitWithMatch<Note>[] {
    return findInArray(this.getAll(), this.textIndex, queryString);
  }

  // ***************
  // Note attributes
  // ***************

  getNoteLength(noteId: NoteId) {
    return (
      this.textIndex
        .get(noteId)
        ?.map((b) => b.text)
        .join("").length || 0
    );
  }

  getLastUpdatedHashtags(): string[] {
    return this.lastUpdatedHashtags.lastUpdated.reverse();
  }

  getNoteAsBlockTexts(noteId: NoteId) {
    return this.textIndex.get(noteId);
  }

  // ******************
  // Formatting methods
  // ******************

  getNoteEllipsis(noteId: NoteId, options?: FormatNoteOptions) {
    return this.noteFormatter.getNoteEllipsis(this.get(noteId), (n) => this.get(n), options);
  }

  getNotePreview(noteId: NoteId, options?: FormatNoteOptions) {
    return this.noteFormatter.getNotePreview(this.get(noteId), (n) => this.get(n), options);
  }

  getNoteSuggestionText(noteId: NoteId, options?: FormatNoteOptions) {
    return this.noteFormatter.getNoteSuggestionText(this.get(noteId), (n) => this.get(n), options);
  }

  getNoteAsString(noteId: NoteId, options?: FormatNoteOptions) {
    return this.noteFormatter.noteToString(this.get(noteId), (n) => this.get(n), options);
  }

  /**
   * Takes a list of note ids, settles any provisional positions, then returns
   * the settled notes, sorted by position.
   * If no ids are given, all notes are settled and returned.
   */
  settle(ids?: NoteId[]): SettledNote[] {
    ids = ids ? ids : Array.from(this.notes.keys());

    // Settle and save provisional notes
    const notesToSettle = ids.map((id) => this.notes.get(id)).filter((v): v is Note => Boolean(v));
    const { alreadySettled, newlySettled } = settleNotes(notesToSettle, Object.values(this.notes));
    this.set(newlySettled);

    // Return all provided notes, sorted by position
    return [...alreadySettled, ...newlySettled].sort(compareNotesPositions);
  }

  // **************************************************************************
  // Semi-private service worker stuff (not for app code use) beyond this point
  // **************************************************************************

  /**
   * Updates or inserts into list with the given partial notes.
   *
   * Unlike insert and update methods, this method does not modify the given
   * notes in any way before inserting them (e.g. it does not fix tokens or set updateAt).
   *
   * @warning This method shouldn't only be used by sync.
   * Client features should use insert and update methods.
   */
  upsert(noteUpserts: NoteUpsert[], shouldPersist = true) {
    const { wasANoteUndeleted, notes } = this.computeMergedNotes(
      noteUpserts.map((note) => {
        if (!note.id) {
          return { ...note, id: generateId() };
        } else {
          return note as RequireProp<Partial<Note>, "id">;
        }
      }),
      true,
    );
    this.set(notes, shouldPersist);
    if (wasANoteUndeleted) this.reindexAllNotes();
    return notes;
  }
}
