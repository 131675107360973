import styled from "@emotion/styled";

import { useAtom, useSetAtom } from "jotai";
import { Note } from "../../../shared/types";
import { formatDate } from "../../utils/date";
import { mobileNoteIdMenuAtom } from "../../model/atoms";
import { appFolderStore } from "../../model/services";
import { getDepthFromPath } from "../../editor/utils/path";
import { FolderList } from "./FolderList";
import { Background } from "./NoteMenu";
import { useMenuActions } from "./useMenuActions";
import {
  PublishIcon,
  ExpandIcon,
  PinIcon,
  ShareLinkIcon,
  StopIcon,
  UnpinIcon,
  Assistant,
  CopyTextIcon,
  AddToFolderIcon,
  InternalLinkIcon,
  ShowDayIcon,
  DeleteIcon,
  JumpToIcon,
  FolderIcon,
} from "./Icons";

const MobileMenuContainer = styled.div`
  position: fixed;
  right: 18px;
  top: 36px;
  z-index: 11;
`;

const MainMenu = styled.div`
  background-color: var(--color-bg-primary);
  width: 240px;
  min-height: 200px;
  color: var(--color-text-primary);
  padding: 8px 0;
  border-radius: var(--radius-large);
  border: var(--color-border-secondary);
  button {
    width: 100%;
  }
`;

// Used on mobile and narrow displays

export const ModalMenu = ({ note, notePath }: { note: Note; notePath: string }) => {
  const [, setNoteId] = useAtom(mobileNoteIdMenuAtom);
  const closeMenu = () => {
    setNoteId(null);
    document.querySelectorAll(".note-menu-button-mobile.active").forEach((button) => button.classList.remove("active"));
  };
  const a = useMenuActions(note, notePath, closeMenu);
  const setMobileNoteId = useSetAtom(mobileNoteIdMenuAtom);

  const folder = note.folderId !== null ? appFolderStore.get(note.folderId) : null;

  return (
    <>
      <Background
        style={{ backgroundColor: `black`, opacity: 0.2 }}
        onClick={() => {
          a.toggleMenuExpanded(null);
          closeMenu();
        }}
      />
      <MobileMenuContainer>
        {a.menuExpanded() === null && (
          <MainMenu>
            {note.readAll ? (
              <button
                className="menu-item"
                onClick={() => {
                  a.stopPublishing();
                  setMobileNoteId(null);
                }}
              >
                <StopIcon />
                <div className="menu-icon-spacer">Stop publishing</div>
              </button>
            ) : (
              <button className="menu-item" onClick={a.publish}>
                <PublishIcon />
                <div className="menu-icon-spacer">Publish</div>
              </button>
            )}
            <button className="menu-item" onClick={a.share}>
              <ShareLinkIcon />
              <div className="menu-icon-spacer">Share direct link</div>
            </button>
            <button className="menu-item" onClick={a.jump}>
              <JumpToIcon />
              <div className="menu-icon-spacer">Jump to note</div>
            </button>
            <button className="menu-item" onClick={a.openAsPage}>
              <ExpandIcon />
              <div className="menu-icon-spacer">Open as page</div>
            </button>
            <button className="menu-item" onClick={a.togglePin}>
              {note.positionInPinned ? (
                <>
                  <UnpinIcon />
                  <div className="menu-icon-spacer">Unpin note</div>
                </>
              ) : (
                <>
                  <PinIcon />
                  <div className="menu-icon-spacer">Pin Note</div>
                </>
              )}
            </button>
            <button className="menu-item" onClick={a.extractEntitiesWithAI}>
              <Assistant />
              <div className="menu-icon-spacer">Parse with AI</div>
            </button>
            <button className="menu-item" onClick={a.copy}>
              <CopyTextIcon />

              <div className="menu-icon-spacer">Copy text</div>
            </button>
            <button className="menu-item" onClick={a.copyUrl}>
              <InternalLinkIcon />
              <div className="menu-icon-spacer">Copy internal link</div>
            </button>

            <button
              className="menu-item"
              onClick={() => {
                a.toggleMenuExpanded("folder")();
              }}
            >
              {folder ? (
                <>
                  <FolderIcon />
                  <div className="menu-icon-spacer">Change folder</div>
                </>
              ) : (
                <>
                  <AddToFolderIcon />
                  <div className="menu-icon-spacer">Add to folder...</div>
                </>
              )}
            </button>
            {folder && (
              <div
                style={{
                  paddingLeft: "40px",
                  paddingBottom: "16px",
                  height: "8px",
                  color: "var(--color-text-secondary)",
                  fontSize: "12px",
                }}
                className="menu-item"
              >
                Current: {folder.name}
              </div>
            )}

            <button className="menu-item" onClick={a.goToDay}>
              <ShowDayIcon />
              <div className="menu-icon-spacer">Jump to day</div>
            </button>

            {/* deleteNote only works on top level notes */}
            {getDepthFromPath(notePath) === 0 && (
              <button className="menu-item" onClick={a.deleteNote}>
                <DeleteIcon />
                <div className="menu-icon-spacer">Delete note</div>
              </button>
            )}
            <button className="menu-item" onClick={a.cycleCollapse}>
              {note.expansionSetting === "collapse" && (
                <>
                  <input type="checkbox" checked={false} readOnly />
                  Always condense
                </>
              )}
              {note.expansionSetting === "expand" && (
                <>
                  <input type="checkbox" checked={true} readOnly />
                  Always expand
                </>
              )}
              {(note.expansionSetting === "auto" || !note.expansionSetting) && (
                <>
                  <input
                    type="checkbox"
                    readOnly
                    ref={(input) => {
                      if (input) {
                        input.indeterminate = true;
                      }
                    }}
                  />
                  Normal condense
                </>
              )}
            </button>
            <hr />
            <div className="menu-info">Created {formatDate(note.createdAt).toLowerCase()}</div>
            <div className="menu-info">Updated {formatDate(note.updatedAt).toLowerCase()}</div>
          </MainMenu>
        )}
        {a.menuExpanded() === "folder" && (
          <FolderList note={note} forceUpdate={a.forceUpdate} closeMenu={() => a.toggleMenuExpanded(null)()} />
        )}
      </MobileMenuContainer>
    </>
  );
};
