type FindMatchesMatch<T> = { start: number; end: number; type: T };

export function findMatches<T>(
  text: string,
  ...regexes: { type: T; regex: RegExp; skipFirstGroup: boolean }[]
): FindMatchesMatch<T>[] {
  return findMatchesInRange(text, regexes, 0);
}

function findMatchesInRange<T>(
  text: string,
  regexes: { type: T; regex: RegExp; skipFirstGroup: boolean }[],
  startRegex: number,
  start = 0,
  end: number = text.length,
): FindMatchesMatch<T>[] {
  text = end === text.length ? text : text.slice(0, end);
  const { regex, type, skipFirstGroup } = regexes[startRegex];
  const nextRegex = startRegex < regexes.length - 1 ? startRegex + 1 : null;
  if (!regex.global) {
    throw new Error("regex must be global");
  }
  const matches: FindMatchesMatch<T>[] = [];
  let match;
  let lastIndex = start;
  while ((match = findNextMatch(text, regex, skipFirstGroup, lastIndex))) {
    if (match.start > lastIndex && nextRegex !== null) {
      matches.push(...findMatchesInRange(text, regexes, nextRegex, lastIndex, match.start));
    }
    matches.push({ ...match, type });
    lastIndex = match.end;
  }
  if (text.length > lastIndex && nextRegex !== null) {
    matches.push(...findMatchesInRange(text, regexes, nextRegex, lastIndex, text.length));
  }
  return matches;
}

function findNextMatch(text: string, regex: RegExp, skipFirstGroup: boolean, lastIndex: number) {
  regex.lastIndex = lastIndex;
  const result = regex.exec(text);
  if (result) {
    return {
      start: result.index + (skipFirstGroup ? result[1]?.length ?? 0 : 0),
      end: result.index + result[0].length,
    };
  }
  return null;
}
