import { nanoid } from "nanoid";
import { TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { addToast } from "../../../components/Toast";
import { urlUpload } from "../../../utils/environment";
import logger from "../../../utils/logger";
import { schema } from "../../schema";
import { getUserSettings, upsertUserSettings } from "../../../model/userSettings";
import { findParent } from "../find";
import { ImageToken } from "../../../../shared/types";
import { useCreateNoteAtTopAsSoonAsPossible } from "../../../nativeIntegration/hooks";
import { getEditorView, getIsOnline } from "../../../model/atoms";
import { makeParagraph } from "../../../model/defaults";
import { ApiClient } from "../../../api/client";
import { ImagePayload } from "../../../nativeIntegration/types";
import { readBlobAsDataUri } from "./utils";

export function getImageItems(dt: DataTransfer | null) {
  return Array.from(dt?.items ?? []).filter((item) => item.type.includes("image"));
}

export async function handleRawImageUploadPaste(
  file: File,
  view: EditorView,
  insertPosition?: number,
): Promise<boolean> {
  // Process the image
  const extension = file.name.split(".").pop();
  const filename = nanoid() + "." + extension;
  const { naturalWidth, naturalHeight, smallPreviewDataURL, dataUri } = await generateImagePropsFromFile(file);

  // Insert the image node into the editor
  const src = filenameToSrc(filename);
  const imageNode = view.state.schema.nodes.image.create({
    // When creating the image node, we need to set the img src
    //   to the correct image location optimistically (instead of setting the src
    //   as the base64 placeholder and then resetting the src post-upload)
    // We do this even though we know it will cause a 404 right away
    //   b/c our src setting is done outside of Prosemirror, and
    //   on undo and redo we don't want PM to give us back the placeholder
    src,
    width: null,
    naturalWidth,
    naturalHeight,
    smallPreviewDataURL,
  });
  const paragraphNode = view.state.schema.nodes.paragraph.create();
  const tr = view.state.tr;
  if (insertPosition) {
    tr.setSelection(new TextSelection(tr.doc.resolve(insertPosition)));
  }
  tr.replaceSelectionWith(imageNode, false);

  const posAfterImage = tr.selection.to + 1; // Position after the image node
  tr.insert(posAfterImage, paragraphNode);
  tr.setSelection(TextSelection.create(tr.doc, posAfterImage + 1));

  view.dispatch(tr.scrollIntoView().setMeta("paste", true).setMeta("uiEvent", "paste"));

  // Upload the image and OCR it
  try {
    await uploadImageAndOCR(file, dataUri, filename);
  } catch (e) {
    logger.error("Error uploading imported image", { error: e });
  }

  return true;
}

const filenameToSrc = (filename: string) => urlUpload + "/" + filename;

export async function handleImportImageFromNative(
  createNoteAtTopAsSoonAsPossible: ReturnType<typeof useCreateNoteAtTopAsSoonAsPossible>,
  payload: ImagePayload,
) {
  // Create file from base64 image
  let file: File;
  if ("imageData" in payload) {
    const buffer = Buffer.from(payload.imageData, "base64");
    file = new File([buffer], nanoid() + "." + payload.imageExtension, { type: "image/jpeg" });
  } else {
    const response = await fetch(payload.imageURL);
    const blob = await response.blob();
    const ext = payload.imageURL.split(".").pop() ?? "jpg";
    file = new File([blob], nanoid() + "." + ext, { type: "image/jpeg" });
  }

  const { naturalWidth, naturalHeight, smallPreviewDataURL, dataUri } = await generateImagePropsFromFile(file);

  // Create a new note with the image
  const src = filenameToSrc(file.name);
  const imageToken: ImageToken = {
    type: "image",
    src,
    width: null,
    naturalWidth,
    naturalHeight,
    smallPreviewDataURL,
  };
  const content = [makeParagraph([imageToken])];
  if (payload.note) {
    content.push(makeParagraph(payload.note));
  }
  createNoteAtTopAsSoonAsPossible(content);

  // Upload the image
  try {
    await uploadImageAndOCR(file, dataUri, file.name);
  } catch (e) {
    logger.error("Error uploading imported image", { error: e });
  }
}

async function ocrImage(dataUri: string, filename: string) {
  const view = getEditorView();
  if (!view) return;
  const result = await ApiClient().files.getOCRText(dataUri);

  if (result.error || !result.data) {
    throw new Error(result.error || "Something went wrong");
  }

  const ocrText: string = result.data;
  const src = filenameToSrc(filename);
  let posImage: number | null = null;
  view.state.doc.descendants((node: any, pos: any) => {
    if (node.type === schema.nodes.image && node.attrs.src === src) {
      posImage = pos;
    }
  });
  if (posImage === null) {
    logger.error("Couldn't find image node in the document", { context: { src, filename } });
    return;
  }
  // find paragraph wrapping the image
  const [imgParagraph, posImgParagraph] = findParent(
    view.state.doc,
    posImage,
    (n) => n.type === schema.nodes.paragraph,
  );
  if (!imgParagraph) {
    logger.error("Couldn't find paragraph wrapping the image");
    return;
  }
  // create a paragraph after the image with the OCR text
  const newParagraphs = ocrText
    .split("\n")
    .map((line) => schema.nodes.paragraph.create({}, [schema.text(line || " ")]));
  const tr = view.state.tr.insert(posImgParagraph + imgParagraph.nodeSize, newParagraphs);
  view.dispatch(tr);
  if (!getUserSettings().seenOcrToast) {
    const toastMessage =
      "Text in images is automatically detected and added to the note. This can be disabled in settings";
    addToast(toastMessage);
    upsertUserSettings({ seenOcrToast: true });
  }
}

async function generateImagePropsFromFile(file: File): Promise<{
  dataUri: string;
  naturalWidth: number;
  naturalHeight: number;
  smallPreviewDataURL: string | null;
}> {
  const img = document.createElement("img");
  const dataUri = await readBlobAsDataUri(file);
  return new Promise((res, rej) => {
    img.onload = () =>
      res({
        dataUri,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
        smallPreviewDataURL: generatePreview(img),
      });
    img.onerror = () => rej(new Error("Couldn't load the image"));
    img.src = dataUri;
  });
}

export function generatePreview(img: HTMLImageElement) {
  // Limit the resolution of the preview to fit in the 64x32
  const scale = Math.min(64 / img.naturalWidth, 32 / img.naturalHeight);
  const canvas = document.createElement("canvas");
  canvas.width = img.naturalWidth * scale;
  canvas.height = img.naturalHeight * scale;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(img, 0, 0, img.naturalWidth * scale, img.naturalHeight * scale);
  try {
    return canvas.toDataURL("image/jpeg");
  } catch (e) {
    // It's possible the image server did not send the "Access-Control-Allow-Origin"
    // and the canvas is considered tainted after drawing the image to it.
    // In those situations we can't generate the preview on the client side
    logger.warn(e);
    return null;
  }
}

// set a shaded version of the image as the placeholder while waiting on upload
//   - don't do this operation in a PM transaction bc we want correct undo redo stacks
function applyFadeToImage(src: string, placeholderSrc: string) {
  const imageToRefresh: HTMLImageElement | null = document.querySelector(`img[src='${src}']`);
  if (!imageToRefresh) return;
  imageToRefresh.src = placeholderSrc;
  imageToRefresh.style.opacity = "0.2";
}

function removeFadeFromImage(src: string, placeholderSrc: string) {
  const imageToRefresh: HTMLImageElement | null = document.querySelector(`img[src='${placeholderSrc}']`);
  if (!imageToRefresh) return;
  imageToRefresh.src = src;
  imageToRefresh.style.opacity = "1";
}

async function uploadImageAndOCR(file: File, dataUri: string, filename: string) {
  if (!getIsOnline()) {
    addToast({
      type: "info",
      content: `We're don't support yet uploads while being offline`,
    });
    deleteImageFromEditor(filename);
  } else if (file.size > 1024 * 1024 * 10) {
    addToast({
      type: "error",
      content: `You can only upload files up to 10MB in size`,
    });
    deleteImageFromEditor(filename);
  } else {
    try {
      const userSettings = getUserSettings();
      if (userSettings.imageOcrEnabled) {
        console.log("OCRing image", filename);
        ocrImage(dataUri, filename);
      } else {
        console.log("OCR not enabled, skipping");
      }
      const src = filenameToSrc(filename);
      applyFadeToImage(src, dataUri);
      await ApiClient().files.upload(dataUri, filename);
      removeFadeFromImage(src, dataUri);
    } catch (error) {
      addToast({
        type: "error",
        content: `We're having a problem processing this image. ${getErrorMessage(error)}`,
      });
      deleteImageFromEditor(filename);
    }
  }
}

function deleteImageFromEditor(filename: string) {
  const view = getEditorView();
  if (!view) return;
  view.state.tr.doc.descendants((node, pos) => {
    if (node.type === schema.nodes.image && node.attrs.id === filename) {
      const tr = view.state.tr.replace(pos, pos + node.nodeSize);
      view.dispatch(tr);
    }
  });
}

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  if (!error) return "";
  return String(error);
}
