import { Command } from "prosemirror-state";
import { schema } from "../../schema";
import { handleSplitWithCheckbox } from "../checkbox/splitWithCheckboxesCommand";
import { isCaretOnReference, preserveExpandedReferencesAround } from "../reference/referenceExpansionUtils";

export const insertBr: Command = (state, dispatch, view): boolean => {
  const { $from } = state.selection;

  if (!dispatch) return false;

  if ($from.parent.type === state.schema.nodes.codeblock || $from.node(-1).type === schema.nodes.listItem) {
    return false;
  }

  if (isCaretOnReference(state.selection)) {
    return false;
  }

  const tr = state.tr;

  preserveExpandedReferencesAround(state, tr, () => {
    if (!handleSplitWithCheckbox(state, tr)) {
      tr.deleteSelection().split(state.tr.mapping.map($from.pos), 1);
    }
  });

  dispatch(tr);
  return true;
};
