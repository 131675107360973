export const DocumentSplitIcon = ({ size = 25 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" height={size} viewBox="0 0 20 20" width={size}>
      <g>
        <path d="m16 2.4999v3.5001c0 .82797-.6786 1.5-1.5 1.5h-9.0119c-.8214 0-1.4881-.67203-1.4881-1.5v-3.5001c0-.27614.22208-.5.49603-.5s.49603.22386.49603.5v3.5001c0 .2751.22207.5.49604.5h9.0119c.274 0 .5-.2249.5-.5v-3.5001c0-.27614.226-.5.5-.5s.5.22386.5.5z" />
        <path d="m3 9.5c-.27614 0-.5.22386-.5.5 0 .2761.22386.5.5.5h1.2c.27614 0 .5-.2239.5-.5 0-.27614-.22386-.5-.5-.5z" />
        <path d="m6.2 9.5c-.27614 0-.5.22386-.5.5 0 .2761.22386.5.5.5h1.2c.27614 0 .5-.2239.5-.5 0-.27614-.22386-.5-.5-.5z" />
        <path d="m8.9 10c0-.27614.22386-.5.5-.5h1.2c.2761 0 .5.22386.5.5 0 .2761-.2239.5-.5.5h-1.2c-.27614 0-.5-.2239-.5-.5z" />
        <path d="m12.6 9.5c-.2761 0-.5.22386-.5.5 0 .2761.2239.5.5.5h1.2c.2761 0 .5-.2239.5-.5 0-.27614-.2239-.5-.5-.5z" />
        <path d="m15.3 10c0-.27614.2239-.5.5-.5h1.2c.2761 0 .5.22386.5.5 0 .2761-.2239.5-.5.5h-1.2c-.2761 0-.5-.2239-.5-.5z" />
        <path d="m16 14v3.5001c0 .2761-.226.5-.5.5s-.5-.2239-.5-.5v-3.5001c0-.2751-.226-.5-.5-.5h-9.0119c-.27397 0-.49604.2249-.49604.5v3.5001c0 .2761-.22208.5-.49603.5s-.49603-.2239-.49603-.5v-3.5001c0-.828.6667-1.5 1.4881-1.5h9.0119c.8214 0 1.5.672 1.5 1.5z" />
      </g>
    </svg>
  );
};
