import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { Node as PMNode, ResolvedPos } from "prosemirror-model";

// Fix a bug having to do with pressing the up and down arrow keys with the text cursor
// at the beginning of a line.
//
// 1) In Chrome, Safari, and Firefox, if there are one or more contentEditable=false islands at the
// beginning of the line you are moving up or down to, the text cursor will be positioned
// after them rather than before them.  We detect this case and issue a new transaction
// to fix the cursor position, at the cost of a brief flicker in Chrome.
interface State {
  arrowDirection: "up" | "down" | "left" | "right" | null;
}

const key = new PluginKey("arrowKeyFix");
export const arrowKeyFixPlugin = new Plugin<State>({
  key,

  state: {
    init: () => ({ arrowDirection: null }),
    apply(tr, oldState) {
      const newStateToMerge: State = tr.getMeta(key);
      return newStateToMerge ? { ...oldState, ...newStateToMerge } : oldState;
    },
  },

  props: {
    handleKeyDown(view, event) {
      const isDownKey = event.code === "ArrowDown";
      const isUpKey = event.code === "ArrowUp";
      const isRightKey = event.code === "ArrowRight";
      const isLeftKey = event.code === "ArrowLeft";
      if (isUpKey || isDownKey || isRightKey || isLeftKey) {
        view.updateState(
          view.state.apply(
            view.state.tr.setMeta(key, {
              arrowDirection: isUpKey ? "up" : isDownKey ? "down" : isRightKey ? "right" : "left",
            }),
          ),
        );
      }
      return false;
    },
  },

  appendTransaction(transactions, oldState, newState) {
    const { arrowDirection } = arrowKeyFixPlugin.getState(newState)!;
    if (!transactions.length || !arrowDirection) return;

    // Workaround for (1)
    const changedSelection =
      transactions.every((t) => t.steps.length === 0) && transactions.some((t) => t.selectionSet);

    // if this transaction doesn't change selection
    if (!changedSelection) return;

    const oldSel = oldState.selection;
    const newSel = newState.selection;

    const tr = newState.tr.setMeta(arrowKeyFixPlugin, {
      arrowDirection: null,
    });

    // if we are not moving with the arrow keys
    if (oldSel.head !== oldSel.anchor) return tr;
    if (newSel.head !== newSel.anchor) return tr;

    const oldPos = oldSel.head;
    const newPos = newSel.head;
    const newBlockStart = newState.doc.resolve(newPos).start();
    const newBlockEnd = newState.doc.resolve(newPos).end();
    const comingFromStartOfLine = oldState.doc.resolve(oldPos).start() === oldPos;

    // if we are moving between two different block nodes
    if (!oldSel.$head.sameParent(newSel.$head)) {
      const oldPosAtEndOfParagraph = oldState.doc.resolve(oldPos).end() === oldPos;
      if (arrowDirection === "right" && oldPosAtEndOfParagraph) {
        // on firefox, if the caret is at the end of a block node and there
        // is an entity(s) at the beginning of the next block, it will skip
        // them, as described below, so we need to set the cursor to the start
        // of the new block
        return tr.setSelection(new TextSelection(newState.doc.resolve(newBlockStart)));
      }
      if (arrowDirection === "left" && comingFromStartOfLine) {
        // on firefox, if the caret is at the start of a block node and there
        // is an entity(s) at the end of the next block, it will skip them
        // as described below, so we need to set the cursor to the end of the
        // new block
        return tr.setSelection(new TextSelection(newState.doc.resolve(newBlockEnd)));
      }
    } else if (arrowDirection === "left" || arrowDirection === "right") {
      return tr;
    }

    // if we are not dealing with the beginning of a line
    if (!comingFromStartOfLine) return tr;

    // if there is nothing in the block, do not apply the fix
    if (newBlockStart === newBlockEnd) return tr;

    // bat: for some reasons, when pressing the down arrow key on a line that starts with an entity and a paragraph,
    // the new selection position is  after the entity instead of the start of the next paragraph.
    // the bug is currently not fixed, but seeing all of that makes me wonder if we should just handle caret movement by ourselves and never letting prose try anything

    let p = newPos;
    let r: ResolvedPos;
    let adjustedPos = newPos;
    // The nightmare case is we have a note where each line consists only of entities,
    // for example two lines each containing "@foo@foo@foo".  Arrow-keying down into this
    // note in Chrome, starting with the text cursor at the beginning of the last line
    // of the previous note, may put the text cursor at the end of all six entities!
    // We have to scan all the way back to the beginning of the note.  When you then
    // press down arrow again, we have to only scan back to the beginning of the second line.
    // When arrow-keying up into a note from the start of the first line of the next note,
    // we have to make sure to stop our scan at the beginning of the last line.
    while (
      (arrowDirection === "down" ? p > oldPos : true) &&
      (r = newState.doc.resolve(p)) &&
      (!r.nodeBefore || isIslandNode(r.nodeBefore))
    ) {
      if (!r.nodeBefore) {
        adjustedPos = p;
        if (arrowDirection === "up") {
          break;
        }
      }
      if (!r.nodeBefore) {
        break;
      }
      p--;
    }

    if (adjustedPos !== newPos && newSel.anchor >= adjustedPos && newSel.anchor <= newPos) {
      return tr.setSelection(new TextSelection(newState.doc.resolve(adjustedPos)));
    }

    return tr;
  },
});

const isIslandNode = (node?: PMNode | null) => node && node.nodeSize === 1 && node.isAtom && node.isText === false;
