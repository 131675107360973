import { EMPTY_NOTE_TEXT } from "../../shared/noteFormatter";
import { NoteId } from "../../shared/types";
import { appSearcher, appNoteStore } from "../model/services";
import { queryFromSearchString, SortBy } from "../search/SearchQuery";
import { ShortcutIcons } from "../shortcuts/KeyIcons";
import { nonCustomizableShortcuts } from "../shortcuts/rawShortcuts";
import { isMobile } from "../utils/environment";
import Suggestion from "./Suggestion";

const MAX_SUGGESTIONS = 20;

export const getHashtagSuggestions = (text: string, maxSuggestions = MAX_SUGGESTIONS) => {
  const currentHashtag = "#" + text;
  const exactHashtagAlreadyExists = appNoteStore.hashtags.getNoteCountForItem(currentHashtag) > 1;
  return appSearcher
    .searchHashtags(text)
    .filter((h) => h !== currentHashtag || (h === currentHashtag && exactHashtagAlreadyExists))
    .slice(0, maxSuggestions)
    .map((h) => ({ id: h, content: h }));
};

export const getReferenceSuggestions = (
  text: string,
  noteId: NoteId,
  maxSuggestions = MAX_SUGGESTIONS,
): Suggestion[] => {
  const { notes } =
    text.length > 0
      ? appSearcher.searchNotes({
          ...queryFromSearchString(text),
          upperLimit: maxSuggestions,
          sortBy: SortBy.relevance,
        })
      : appSearcher.searchNotes({ isAll: true, upperLimit: maxSuggestions, sortBy: SortBy.lastUpdated });
  const suggestions = notes
    .map(({ entry: note }) => ({
      id: note.id,
      content: appNoteStore.getNoteSuggestionText(note.id),
      extraData: {
        referenceCount: appNoteStore.backlinks.getNoteCountForItem(note.id),
      },
    }))
    .filter((s) => s.id !== noteId && s.content !== EMPTY_NOTE_TEXT);

  const createNoteSuggestion: Suggestion =
    text === ""
      ? {
          id: "",
          display: `Create blank note`,
          content: text,
          action: "create-and-expand",
        }
      : {
          id: "",
          display: (
            <>
              Create new note "{text}"{" "}
              {!isMobile && (
                <span style={{ fontStyle: "normal" }}>
                  (<ShortcutIcons keys={nonCustomizableShortcuts.createNewNoteFromSearch.keys} />)
                </span>
              )}
            </>
          ),
          content: text,
          action: "create",
        };

  if (text.length === 0) {
    return [createNoteSuggestion, ...suggestions];
  } else if (isMobile) {
    return [...suggestions.slice(0, 2), createNoteSuggestion, ...suggestions.slice(2)];
  } else {
    return [...suggestions, createNoteSuggestion];
  }
};
