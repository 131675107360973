import styled from "@emotion/styled";
import { colors } from "../utils/style";
import { formatAndSplitKeyString } from "./shortcuts";

const KbdKey = styled.kbd`
  background: ${colors.bg.secondary};
  padding: 2px 5px;
  margin: 0 2px;
  font-size: 14px;
  border-radius: 4px;
  font-family: inherit;
`;

export const ShortcutIcons = ({ keys }: { keys: string }) => {
  const buttons = formatAndSplitKeyString(keys);
  return (
    <span>
      {buttons.map((btn) => (
        <KbdKey key={btn}>{btn}</KbdKey>
      ))}
    </span>
  );
};
