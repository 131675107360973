import { EditorScroll } from "../model/editorScroll";
import { EDITOR_ID } from "../utils/constants";

function getTopVisibleNote(editorDiv: HTMLDivElement) {
  const notes = Array.from(editorDiv.querySelectorAll(".note"));
  for (const note of notes) {
    const noteId = note.getAttribute("data-noteid");
    const rect = note.getBoundingClientRect();
    if (
      // Note is fully visible
      (rect.top >= 0 && rect.bottom <= window.innerHeight) ||
      // Top of note is visible
      (rect.top >= 0 && rect.top < window.innerHeight) ||
      // Note stretches beyond the top edge or both edges of the screen
      (rect.top < 0 && rect.bottom > 0)
    ) {
      return { noteId, rect };
    }
  }
  return null;
}

export function getCurrentEditorScroll(): EditorScroll {
  const editor = document.getElementById(EDITOR_ID) as HTMLDivElement;
  const topNote = getTopVisibleNote(editor);
  return {
    topVisibleNote: topNote?.noteId || null,
    noteOffsetY: topNote?.rect.top || 0,
    fallbackScrollTop: editor.scrollTop,
  };
}

/**
 * Given the saved scroll position from a previous editor, calculate the
 * corresponding scrollTop in the current editor.
 */
export function calculateEditorScrollTop(scroll: EditorScroll): number {
  const noteId = scroll.topVisibleNote;
  if (!noteId) return scroll.fallbackScrollTop;

  const editor = document.getElementById(EDITOR_ID);
  const editorOffset = editor?.offsetTop || 0;
  const noteSelector = `.note[data-noteid="${noteId}"]`;
  const note = editor?.querySelector(noteSelector) as HTMLElement;
  if (!note) return scroll.fallbackScrollTop;

  return note.offsetTop - scroll.noteOffsetY + editorOffset;
}
