export const RightIndentIcon = ({ size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 6H21" stroke="#737777" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 6L21 6" stroke="#737777" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M3 10L13 10" stroke="#737777" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M3 14L13 14" stroke="#737777" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M3 18L21 18" stroke="#737777" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M22 12L17.5 15.4641L17.5 8.5359L22 12Z" fill="#737777" />
    </svg>
  );
};
