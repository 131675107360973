import { DOMParser, ResolvedPos, Schema } from "prosemirror-model";

/**
 * A function to parse text from the clipboard into a document slice.
 * Called after transformPastedText. The default behavior is to split the
 * text into lines, wrap them in <p> tags, and call clipboardParser on it.
 * The plain flag will be true when the text is pasted as plain text.
 */
export const clipboardTextParser = (schema: Schema) => (text: string, $context?: ResolvedPos, plain?: boolean) => {
  const dom = document.createElement("div");
  // Encode all html elements
  const encodedText = text.replace(/[\u00A0-\u9999<>&]/gim, (i) => "&#" + i.charCodeAt(0) + ";");

  // Split double newlines into paragraphs
  dom.innerHTML = encodedText.replace(/(\r\n|\n){2}/g, "</p><p>");
  // Replace other new lines with line breaks
  dom.innerHTML = dom.innerHTML.replace(/(\r\n|\n)/g, "<br />");
  // Create <li> tags for lists (indicated by tabs or 4 spaces)
  dom.innerHTML = dom.innerHTML.replace(/<p>(\t| {4})([\w\s]+)<\/p>/g, "<li>$2</li>");
  dom.innerHTML = dom.innerHTML.replace(/(\t| {4})([\w\s]+)<br>/g, "<li>$2</li>");
  // Handles semi-special case of last item in a list
  dom.innerHTML = dom.innerHTML.replace(/(\t| {4})([\w\s]+)<p>/g, "<li>$2</li><p>");
  dom.innerHTML = dom.innerHTML.replace(/<br ?\/?>/g, "<p>");
  dom.innerHTML = dom.innerHTML.replace(/\[ ?\]/g, "<input type='checkbox' />");
  dom.innerHTML = dom.innerHTML.replace(/\[x\]/g, "<input type='checkbox' checked='true' />");

  const parser: DOMParser = DOMParser.fromSchema(schema);
  return parser.parseSlice(dom, {
    preserveWhitespace: true,
    context: $context,
  });
};
