import { useEffect, useState } from "react";

type TimerProps = {
  lastStartupTime: number | null;
};

export function Timer({ lastStartupTime }: TimerProps) {
  const [, setFrame] = useState(0);
  useEffect(() => {
    let isCancelled = false;
    function animationLoop() {
      if (isCancelled) return;
      setFrame((f) => f + 1);
      window.requestAnimationFrame(animationLoop);
    }
    window.requestAnimationFrame(animationLoop);
    return () => {
      isCancelled = true;
    };
  }, []);

  const diffInMilliseconds = lastStartupTime !== null ? Date.now() - lastStartupTime : 0;
  const diffInSeconds = diffInMilliseconds / 1000;
  const hours = Math.floor(diffInSeconds / (60 * 60));
  const minutes = Math.floor((diffInSeconds - hours * 60 * 60) / 60);
  const seconds = Math.floor(diffInSeconds - hours * 60 * 60 - minutes * 60);
  return (
    <>
      {(minutes + "").padStart(2, "0")}:{(seconds + "").padStart(2, "0")}
    </>
  );
}
