import {
  X,
  Globe,
  Link,
  Trash2,
  FolderPlus,
  Calendar,
  Copy,
  Link2,
  MoreHorizontal,
  Maximize2,
  Folder,
  FolderMinus,
  ArrowDownCircle,
} from "react-feather";

export const PinIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 1.5H8.5V2V5.75C8.5 7.10035 9.4684 8.22036 10.75 8.45485V9H6.7275H6.2275V9.5V14.5429L5.9775 14.7929L5.7275 14.5429V9.5V9H5.2275H1.25V8.45485C2.5316 8.22036 3.5 7.10035 3.5 5.75V2V1.5H3H2.25C2.11364 1.5 2 1.38636 2 1.25C2 1.11364 2.11364 1 2.25 1H9.75C9.88636 1 10 1.11364 10 1.25C10 1.38636 9.88636 1.5 9.75 1.5H9Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </svg>
);

export const UnpinIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 1.5H8.5V2V5.75C8.5 7.10035 9.4684 8.22036 10.75 8.45485V9H6.7275H6.2275V9.5V14.5429L5.9775 14.7929L5.7275 14.5429V9.5V9H5.2275H1.25V8.45485C2.5316 8.22036 3.5 7.10035 3.5 5.75V2V1.5H3H2.25C2.11364 1.5 2 1.38636 2 1.25C2 1.11364 2.11364 1 2.25 1H9.75C9.88636 1 10 1.11364 10 1.25C10 1.38636 9.88636 1.5 9.75 1.5H9Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export const EnterKey = () => (
  <svg width="16" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.34766 13.8242C6.62891 13.8242 6.81055 13.6309 6.81055 13.3555C6.81055 13.2207 6.76953 13.1211 6.68164 13.0332L5.43945 11.832L4.60156 11.1348L5.69141 11.1875H11.3516C12.5 11.1875 12.957 10.6777 12.957 9.58203V6.89844C12.957 5.75 12.5 5.29883 11.3516 5.29883H8.93164C8.63867 5.29883 8.45117 5.50977 8.45117 5.77344C8.45117 6.03125 8.63867 6.24219 8.93164 6.24219H11.3516C11.8262 6.24219 12.0078 6.42969 12.0078 6.89844V9.58203C12.0078 10.0566 11.8262 10.2441 11.3516 10.2441H5.69141L4.60156 10.2969L5.43945 9.59961L6.68164 8.39258C6.76953 8.31055 6.81055 8.20508 6.81055 8.07031C6.81055 7.79492 6.62891 7.60742 6.34766 7.60742C6.23047 7.60742 6.0957 7.66016 6.00195 7.75391L3.33008 10.3613C3.22461 10.4609 3.17188 10.584 3.17188 10.7129C3.17188 10.8418 3.22461 10.9648 3.33008 11.0703L6.00195 13.6777C6.0957 13.7656 6.23047 13.8242 6.34766 13.8242Z"
      fill="currentColor"
    />
  </svg>
);

export const Assistant = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.542 10.603 6 12.5l-.542-1.897a3 3 0 0 0-2.06-2.06L1.5 8l1.897-.542a3 3 0 0 0 2.06-2.06L6 3.5l.542 1.897a3 3 0 0 0 2.06 2.06L10.5 8l-1.897.542a3 3 0 0 0-2.06 2.06h-.001Zm5.63-4.793L12 6.5l-.173-.69a2.25 2.25 0 0 0-1.636-1.637L9.5 4l.69-.173a2.25 2.25 0 0 0 1.637-1.637L12 1.5l.173.69a2.25 2.25 0 0 0 1.637 1.637L14.5 4l-.69.173a2.25 2.25 0 0 0-1.637 1.637Zm-.91 7.901L11 14.5l-.263-.789a1.5 1.5 0 0 0-.948-.948L9 12.5l.789-.263a1.5 1.5 0 0 0 .948-.948L11 10.5l.263.789a1.5 1.5 0 0 0 .948.948L13 12.5l-.789.263a1.5 1.5 0 0 0-.948.948Z"
      strokeWidth="1"
    />
  </svg>
);

export const ShareLinkIcon = () => <Link size={16} color="currentColor" />;
export const InternalLinkIcon = () => <Link2 size={16} color="currentColor" />;
export const PublishIcon = () => <Globe size={16} color="currentColor" />;
export const StopIcon = () => <X size={16} color="currentColor" />;
export const JumpToIcon = () => <ArrowDownCircle size={16} color="currentColor" />;
export const DeleteIcon = () => <Trash2 size={16} color="currentColor" />;
export const AddToFolderIcon = () => <FolderPlus size={16} color="currentColor" />;
export const RemoveFromFolderIcon = () => <FolderMinus size={16} color="currentColor" />;
export const FolderIcon = () => <Folder size={16} color="currentColor" />;
export const ShowDayIcon = () => <Calendar size={16} color="currentColor" />;
export const CopyTextIcon = () => <Copy size={16} color="currentColor" />;
export const MoreIcon = () => <MoreHorizontal size={16} color="currentColor" />;
export const ExpandIcon = () => <Maximize2 size={16} color="currentColor" />;
