import React, { useCallback, useEffect, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { ChevronDown, Folder } from "react-feather";
import { appFolderStore, appNoteStore } from "../../../model/services";
import { scrollEditorToTop } from "../../../editorPage/App";
import { mediumWidth } from "../../../utils/style";
import { SelectedLink } from "../SelectedLink";
import { Menu } from "../../SidebarItem/Menu";
import { EditInput } from "../../SidebarItem/EditInput";
import { editorViewAtom, isOnlineAtom, isSidebarOpenAtom } from "../../../model/atoms";
import { isMobile } from "../../../utils/environment";
import OneLiner from "../OneLiner";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { updateSearchQuery } from "../../../search/useSearchQuery";
import { useNotifySidebarUpdate } from "../../atoms/sidebarUpdate";
import { addToast } from "../../../components/Toast";
import logger from "../../../utils/logger";
import { findDescendantNote } from "../../../editor/utils/find";
import { ApiClient } from "../../../api/client";
import { FolderIcon as FolderFilled } from "../../img/FolderFillIcon";
import { GrabHandle } from "../GrabHandle";

interface FolderItemProps {
  id: string;
  name: string;
  newId: string | null;
  setNewId: (id: string | null) => void;
  handleProps: any; // not typed in the lib
  childCount: number;
  collapsed: boolean;
  onCollapse?: () => void;
}

export const FolderItem: React.FC<FolderItemProps> = ({
  id,
  name: content,
  handleProps,
  newId,
  setNewId,
  childCount,
  collapsed,
  onCollapse,
}) => {
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);
  const [isRenaming, setIsRenaming] = useState(id === newId);
  const [isHovering, setIsHovering] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateSidebar = useNotifySidebarUpdate();

  useEffect(() => {
    if (id === newId) {
      setIsRenaming(true);
    }
  }, [newId, id]);

  const select = useCallback(() => {
    if (window.innerWidth <= mediumWidth) {
      setShowSidebar(false);
    }
    scrollEditorToTop();
    updateSearchQuery({ folderId: id });
  }, [id, setShowSidebar]);

  const rename = (name: string) => {
    if (error === null) {
      appFolderStore.update({ id, name }, true);
    }
    setIsRenaming(false);
    setNewId && setNewId(null);
    updateSearchQuery({ folderId: id });
    trackEvent("update_folder", id);
  };

  const remove = () => {
    const { folder, undoDelete } = appFolderStore.delete(id, true);
    addToast({
      content: `Folder ${folder.name} deleted`,
      buttons: [
        {
          text: "undo",
          onClick() {
            undoDelete();
            updateSidebar();
          },
        },
      ],
    });
    updateSearchQuery({ isAll: true });
    trackEvent("delete_folder", id);
  };

  const getEditorView = useAtomCallback((get) => get(editorViewAtom));

  const isOnline = useAtomValue(isOnlineAtom);

  const onPublishChildren = () => {
    const notes = appNoteStore.getAll().filter((n) => n.folderId === id);
    notes.forEach(async (note) => {
      if (note.readAll) return;

      // First, update the note in the local store
      appNoteStore.update({ id: note.id, readAll: true, isSharedPrivately: true });

      // Then, send an immediate request to the server to update the note, preventing
      // issues with the update not syncing to the server before the user navigates away (ENT-2801)
      if (isOnline) {
        const result = await ApiClient().notes.setPublishVisibility(note.id, true);
        if (!result.status) logger.error("Error updating note publish state on server", { context: result });
      }

      // Finally, update the note's display in the editor
      const view = getEditorView();
      if (!view) return;
      const tr = view.state.tr;
      const [node, posNote] = findDescendantNote(tr.doc, (n) => n.attrs.noteId === note.id);
      if (!node) return;
      tr.setNodeMarkup(posNote, undefined, {
        ...node.attrs,
        readAll: true,
      });
      view.dispatch(tr);
    });
  };

  const FolderIconWrapper: React.FC<{ collapsed: boolean; childCount: number }> = ({ collapsed, childCount }) => {
    const handleIconClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (childCount > 0) {
        onCollapse?.();
      }
    };

    const folderIcon =
      childCount === 0 ? (
        <Folder size={16} opacity={0.75} />
      ) : collapsed ? (
        <FolderFilled isActive={true} size={16} opacity={0.75} />
      ) : (
        <Folder size={16} opacity={0.75} />
      );

    const chevronIcon = (
      <ChevronDown
        size={16}
        style={{
          transform: collapsed ? "rotate(-90deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
          cursor: "pointer",
          width: 16,
          position: "relative",
          left: isMobile ? 0 : 2,
        }}
        onClick={handleIconClick}
      />
    );

    if (isMobile) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {childCount > 0 && chevronIcon}
          {folderIcon}
        </div>
      );
    }

    return isHovering && childCount > 0 ? chevronIcon : folderIcon;
  };

  return (
    <div className="nonselectable" style={{ flexGrow: 1, minWidth: 0 }} onKeyDown={select}>
      <SelectedLink
        onMouseDown={select}
        tabIndex={0}
        title={content}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <OneLiner
          icon={<FolderIconWrapper collapsed={collapsed} childCount={childCount} />}
          allowOverflow={isRenaming}
          content={
            isRenaming ? (
              <EditInput
                submitEditChanges={rename}
                errorMessage={error}
                setErrorMessage={setError}
                setIsEditing={setIsRenaming}
                content={content}
              />
            ) : (
              <span style={{ flexGrow: 1 }}>{content}</span>
            )
          }
          trailingButtons={
            !isRenaming && (
              <>
                <Menu
                  isHovering={isHovering}
                  isSelected={false}
                  setIsHoveringSidebarItem={setIsHovering}
                  setIsEditing={setIsRenaming}
                  errorMessage={error}
                  onDelete={remove}
                  onPublishChildren={onPublishChildren}
                />
                <GrabHandle className="grab-handle" {...handleProps} title="Reorder folder">
                  ⠿
                </GrabHandle>
              </>
            )
          }
        />
      </SelectedLink>
    </div>
  );
};
