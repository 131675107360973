import { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useAtomValue } from "jotai";
import Sidebar from "../sidebar/Sidebar";
import { useAuth } from "../auth/useAuth";
import { alwaysShowMobileKeyboardBar, isMobile, isTouchDevice } from "../utils/environment";
import { isLoadedAtom, mobileCloseKeyboardPressedAtom } from "../model/atoms";
import { useSearchQuery, useSyncSearchQueryAndUrl } from "../search/useSearchQuery";
import { EDITOR_ID } from "../utils/constants";
import { NoteId } from "../../shared/types";
import { generatePathFromNoteId } from "../editor/utils/path";
import { saveLastEditorScroll } from "../model/editorScroll";
import MobileAutocompleteMenu from "../editor/features/autocomplete/MobileAutocompleteMenu";
import DesktopAutocompleteMenu from "../editor/features/autocomplete/DesktopAutocompleteMenu";
import { useNativeResponseHandler } from "../nativeIntegration/useNativeResponseHandler";
import { Header } from "./header/Header";
import { useInitNavigationHistory } from "./navigateHistory";
import { ContentContainer, EditorContentContainer, EditorScrollContainer } from "./styledComponents";
import { useKeyboardVisibility } from "./hooks/useKeyboardVisibility";
import { useKeepCaretInView } from "./hooks/useKeepCaretInView";
import { getCurrentEditorScroll } from "./scrollTools";
import { EditorHeader } from "./EditorHeader";
import { MobileKeyboardBar } from "./MobileKeyboardBar";
import { Editor } from "./Editor";
import { EditorFooter, EditorPadding } from "./EditorPadding";
import { useCloseSidebarAfterNavOnMobile } from "./hooks/useCloseSidebarAfterNavOnMobile";

const contentMaxWidth = 720;

export const EditorPageApp = () => {
  const { user } = useAuth();
  const searchQuery = useSearchQuery();

  const isCloseKeyboardPressed = useAtomValue(mobileCloseKeyboardPressedAtom);

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const autocompleteKeyDownRef = useRef<((event: KeyboardEvent) => boolean) | null>(null);
  const includeMobileKeyboardBar = (isTouchDevice && !isCloseKeyboardPressed) || alwaysShowMobileKeyboardBar;

  const updateScrollPosition = useDebouncedCallback(
    () => {
      if (!editorContainerRef.current) return;
      saveLastEditorScroll(getCurrentEditorScroll());
    },
    200,
    { trailing: true },
  );

  useKeepCaretInView();
  useNativeResponseHandler();
  useInitNavigationHistory();
  useKeyboardVisibility();
  useSyncSearchQueryAndUrl();
  useCloseSidebarAfterNavOnMobile();
  const isLoaded = useAtomValue(isLoadedAtom);

  if (user == null) return null;
  return (
    <>
      <Header contentMaxWidth={contentMaxWidth} />
      <ContentContainer role="presentation" style={{ marginTop: "55px" }}>
        <Sidebar />
        <EditorScrollContainer onScroll={updateScrollPosition} role="main" id={EDITOR_ID} ref={editorContainerRef}>
          <EditorContentContainer id="editor-content-container">
            <EditorHeader />
            <Editor autocompleteKeyDownRef={autocompleteKeyDownRef} />
            {isLoaded && (
              <EditorFooter searchQuery={searchQuery} editorContainerRef={editorContainerRef}></EditorFooter>
            )}
            <EditorPadding />
            {isMobile ? (
              <MobileAutocompleteMenu autocompleteKeyDownRef={autocompleteKeyDownRef} />
            ) : (
              <DesktopAutocompleteMenu
                autocompleteKeyDownRef={autocompleteKeyDownRef}
                editorContainerRef={editorContainerRef}
              />
            )}
          </EditorContentContainer>
        </EditorScrollContainer>
        {includeMobileKeyboardBar && <MobileKeyboardBar />}
      </ContentContainer>
      <div id="note-menu-container" />
    </>
  );
};

/**
 * Get the current scroll position of the editor
 */
export const getEditorPosition = () => {
  const editor = document.getElementById(EDITOR_ID) as HTMLDivElement;
  if (!editor) throw new Error("could not find editor");
  return getCurrentEditorScroll();
};

/**
 * Scroll the editor smoothly to the top
 */
export const scrollEditorToTop = () => {
  const editor = document.getElementById(EDITOR_ID);
  if (!editor) throw new Error("could not find editor");
  editor.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

/**
 * Set the editor scroll position to a specific value immediately
 */
export const setEditorPosition = (position: number) => {
  const editor = document.getElementById(EDITOR_ID);
  if (!editor) throw new Error("could not find editor");
  editor.scrollTo({ top: position, left: 0 });
};

/**
 * Set the editor scroll position at a specific note
 */
export const setEditorPositionAtNote = (noteId: NoteId) => {
  document.querySelector(`[data-path="${generatePathFromNoteId(noteId)}"]`)?.scrollIntoView({ block: "start" });
};
