import { Plugin } from "prosemirror-state";
import { getSearchQuery } from "../../../search/useSearchQuery";
import { condenseAndHighlight } from "./applySearchMatchMarks";

/** Apply search highlights */
export const highlightPlugin = new Plugin({
  appendTransaction: (_trs, _oldState, newState) => {
    const tr = newState.tr;
    tr.setMeta("type", "highlight");
    tr.setMeta("addToHistory", false);
    tr.setMeta("noChangeToModel", true);

    const searchQuery = getSearchQuery();
    condenseAndHighlight(tr, searchQuery);

    if (tr.docChanged) return tr;

    return null;
  },
});
