import { createNodeViewFromReactComponent } from "../utils";
import { AudioInsertViewWithLocalCache } from "./AudioInsertViewWithLocalCache";
import { globalAudioRecordingCoordinator } from "./audioRecordingCoordinator";
import { AudioAttrs } from "./audioTypes";

export const AudioInsertView = createNodeViewFromReactComponent<AudioAttrs>(
  (node) => node.attrs.audioId,
  (node) => {
    const dom = document.createElement("div");
    dom.className = "audio-insert";
    if (!node.attrs.isRevealed) {
      dom.className += " audio-insert-collapsed";
    }
    dom.setAttribute("data-audio-id", node.attrs.audioId);
    const reactRoot = document.createElement("div");
    reactRoot.className = "audio-insert-content";
    dom.appendChild(reactRoot);
    return { dom, reactRoot };
  },
  (props) => {
    const { attrs } = props;
    const ephemeralAttrs = globalAudioRecordingCoordinator.getAudioAttrs(attrs.audioId);

    // If we have a greater chunk count in the map then that means
    // then ephemeralAttrs map as source of truth because a chunk
    // is missing in node attributes
    if (ephemeralAttrs.chunkCount > attrs.chunkCount) {
      console.log("ephemeralAttrs.chunkCount > attrs.chunkCount");
      props.setAttrs(
        {
          chunkCount: ephemeralAttrs.chunkCount,
          durations: ephemeralAttrs.durations,
        },
        false,
      );
    }

    return <AudioInsertViewWithLocalCache {...props} />;
  },
  (node, dom) => {
    if (node.attrs.isRevealed) {
      dom.classList.remove("audio-insert-collapsed");
    } else {
      dom.classList.add("audio-insert-collapsed");
    }
  },
);
