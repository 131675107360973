import { Node, Fragment } from "prosemirror-model";
import { TextSelection, Transaction } from "prosemirror-state";
import { noteBlockMatches, appNoteStore, isCondensed } from "../../../model/services";
import { assert } from "../../../utils/assert";
import { topLevelTokenToProsersmirorNodes } from "../../bridge";
import { schema } from "../../schema";
import getNode from "../../utils/getNode";
import { findDescendant, findParent } from "../../utils/find";

function isFragment(node: Node | Fragment): node is Fragment {
  return node instanceof Fragment;
}

/**
 * Create a copy of the given note node, but with it's compact/expanded state
 * toggled.
 */
function createNoteNodeWithCondensedToggled(node: Node) {
  assert(node.type === schema.nodes.note, "node must be a note");
  const note = appNoteStore.get(node.attrs.noteId);
  assert(!!note, "note must exist in noteStore to update its node");
  const { nodes } = topLevelTokenToProsersmirorNodes(
    note.tokens,
    noteBlockMatches.get(node.attrs.path) || [],
    // if the note was originally condensable, and expanded, then create condensed content
    node.attrs.condensable && !node.attrs.isCondensed,
  );
  return schema.nodes.note.create(
    {
      ...node.attrs,
      isCondensed: !node.attrs.isCondensed,
    },
    nodes,
  );
}

/**
 * Create a transaction which replaces a note node with the same note node, but
 * with it's compact/expanded state toggled.
 */
export function toggleCondensedOnNoteNode(tr: Transaction, pos: number) {
  const node = getNode(tr.doc, pos, schema.nodes.note);

  // Save selection as offsets from their parent token, so we can
  // restore it after replacing the node
  const { from, to } = tr.selection;
  const [fromToken, fromTokenPos] = findParent(tr.doc, from, (n) => n.attrs.tokenId !== undefined);
  const [toToken, toTokenPos] = findParent(tr.doc, to, (n) => n.attrs.tokenId !== undefined);
  if (fromToken === null || toToken === null) return tr;
  const fromTokenOffset = from - fromTokenPos;
  const toTokenOffset = to - toTokenPos;

  // Replace with toggled note node
  const newNode = createNoteNodeWithCondensedToggled(node);
  isCondensed.set(node.attrs.noteId, !node.attrs.isCondensed);
  tr.replaceWith(pos, pos + node.nodeSize, newNode);

  // Restore selection
  const [newFromToken, newFromTokenPos] = findDescendant(tr.doc, (n) => n.attrs.tokenId === fromToken.attrs.tokenId);
  const [newToToken, newToTokenPos] = findDescendant(tr.doc, (n) => n.attrs.tokenId === toToken.attrs.tokenId);
  if (newFromToken === null || newToToken === null) return tr;
  const selection = TextSelection.create(tr.doc, newFromTokenPos + fromTokenOffset, newToTokenPos + toTokenOffset);
  tr.setSelection(selection);

  return tr;
}

export function filterEllipsis<T extends Node | Fragment>(node: T): T {
  if (isFragment(node)) {
    const content: Node[] = [];
    node.forEach((child) => {
      content.push(filterEllipsis(child));
    });
    return Fragment.fromArray(content) as T;
  } else {
    if (node.isText) return node;
    const content: Node[] = [];
    node.content.forEach((child) => {
      if (child.type === schema.nodes.ellipsisContainer) return;
      content.push(child);
    });
    return node.type.create(node.attrs, Fragment.fromArray(content.map((n) => filterEllipsis(n)))) as T;
  }
}
