import { type NoteId } from "../../shared/types";

import { AutoDeleteMap } from "../utils/autoDeleteMap";
import { isHistoryState } from "../utils/isHistoryState";
import appLogger from "../utils/logger";

const logger = appLogger.with({ namespace: "editorScroll" });

const MAX_PAGES_TO_REMEMBER = 100;

/**
 * The scroll state of an editor represented as an offset of the
 * top visible note from the top of the editor.
 * Used to restore the scroll position of an editor after a refresh.
 */
export type EditorScroll = {
  topVisibleNote: NoteId | null;
  noteOffsetY: number;
  fallbackScrollTop: number;
};

export const TOP_SCROLL: EditorScroll = Object.freeze({
  topVisibleNote: null,
  noteOffsetY: 0,
  fallbackScrollTop: 0,
});

const lastScrollPerPage = new AutoDeleteMap<EditorScroll>(MAX_PAGES_TO_REMEMBER);

/**
 * Save the editor scroll position. A key can be provided to save the scroll
 * position for a specific history state. If no key is provided, the current
 * history state key is used.
 */
export function saveLastEditorScroll(scroll: EditorScroll): void {
  if (!isHistoryState(window.history.state)) {
    logger.error("Could not save editor scroll because the history state value is unexpected", {
      context: { scroll, state: window.history.state },
    });
  } else {
    lastScrollPerPage.set(window.history.state.key, scroll);
  }
}

/** Get the last scroll position of an editor for a given page */
export function getLastEditorScroll(): EditorScroll {
  return lastScrollPerPage.get(window.history.state.key) || TOP_SCROLL;
}
