import { MinHeap } from "@datastructures-js/heap";

type Key = string;
type Time = number;
type Pair = { key: Key; lastUpdated: Time };

/** Automatically deletes the least recently updated item if the limit is reached */
export class AutoDeleteMap<T = any> {
  #data: Record<Key, T> = {};
  #heap: MinHeap<Pair> = new MinHeap((pair) => pair.lastUpdated);
  #limit: number;

  constructor(limit: number) {
    this.#limit = limit;
  }

  set(key: Key, value: T): void {
    this.#data[key] = value;
    this.#heap.push({ key, lastUpdated: Date.now() });

    // Delete based on the data map size, not the heap size
    if (Object.keys(this.#data).length > this.#limit) {
      // It's safe since we just pushed to the queue
      const { key } = this.#heap.pop() as Pair;
      delete this.#data[key];
    }
  }

  get(key: string): T | null {
    return this.#data[key] || null;
  }
}
