import { EditorState, Plugin } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";
import { dropCursor } from "prosemirror-dropcursor";
import { baseKeymap } from "prosemirror-commands";
import { Node } from "prosemirror-model";
import { history } from "prosemirror-history";
import { isTouchDevice, isIOs, isLocal, isUnsyncedDecoratorEnabled } from "../utils/environment";
import { trackEvent } from "../analytics/analyticsHandlers";
import { nonCustomizableShortcuts } from "../shortcuts/rawShortcuts";
import { updateSearchQuery } from "../search/useSearchQuery";
import { handleListActionsPlugin } from "./features/list/handleListActionsPlugin";
import { schema } from "./schema";
import { autocompletePlugin } from "./features/autocomplete/autocompletePlugin";
import { arrowKeyFixPlugin } from "./fix/arrowKeyFixPlugin";
import { fixDragDropPlugin } from "./fix/fixDragDropPlugin";
import { ensureAtLeastOneNote } from "./features/doc/ensureAtLeastOneNote";
import { toggleCheckboxPlugin } from "./features/checkbox/toggleCheckboxCommand";
import { linkPreviewPlugin } from "./features/link/linkPreview";
import { dateDividerPlugin } from "./features/dateDivider/dateDividerPlugin";
import { noteDebuggerPlugin } from "./features/note/noteDebuggerPlugin";
import { noteUnsyncedPlugin } from "./features/note/noteUnsyncedPlugin";
import { checkboxPlugin } from "./features/checkbox/checkboxPlugin";
import { mobileNoteMenuPlugin } from "./features/note/mobileNoteMenuPlugin";
import { textExpanderPlugin } from "./features/text/textExpanderPlugin";
import { createNestedListPlugin } from "./features/list/createNestedListPlugin";
import { applyMarksPlugin } from "./utils/mark/applyMarksPlugin";
import { persistencePlugin } from "./utils/persistencePlugin";
import { backlinkTogglePlugin } from "./features/backlink/backlinkPlugin";
import { selectAllPlugin } from "./features/note/SelectAllPlugin";
import { highlightPlugin } from "./features/highlight/highlightPlugin";
import { expansionEditMirrorPlugin } from "./features/reference/expansionEditMirrorPlugin/expansionEditMirrorPlugin";
import { historyWatcherPlugin } from "./features/historyWatcher/historyWatcherPlugin";
import { toggleEllipsisPlugin } from "./features/ellipsis/toggleEllipsisPlugin";
import { preventDeletionAcrossExpansionPlugin } from "./features/reference/preventDeletionAcrossExpansionPlugin";
import { fixDeletedExpandedNote } from "./features/reference/fixDeletedExpandedNote";
import { fixTokenIdsPlugin } from "./utils/fixTokenIdsPlugin";
import { getShowNoteInTimeline } from "./features/search/showNoteInTimeline";
import { splitNoteOnTripleDashPlugin } from "./features/note/splitNoteOnTripleDash";
import { wrapSelectionInText } from "./features/autocomplete/replaceSelection";
import { listifyCommand } from "./features/list/listifyCommand";
import { trackRangesPlugin } from "./features/trackRanges/trackRangesPlugin";
import { warnOnManyDeletePlugin } from "./features/note/warnOnManyDeletePlugin";
import { mobileEventHandlerPlugin } from "./features/mobileEventHandler/mobileEventHandlerPlugin";
import { handleParagraphActionsPlugin } from "./features/paragraph/handleParagraphActionPlugin";
import { atSignToHashtagPlugin } from "./features/hashtag/atSignToHashtagPlugin";
import { codeInputRulesPlugin } from "./features/code/inputRules";
import { codePlugin } from "./features/code/plugin";
import { createAudioPlugin } from "./features/audioInsert/audioPlugin";
import keymapPlugin from "./keymapPlugin";
import { ignoreAutocorrectDuringHashtagAutocomplete } from "./features/autocomplete/ignoreHashtagAutocorrect";
import { tildeToHashtagPlugin } from "./features/tilde/tildeToHashtagPlugin";

/** The configuration common to both createEditorState and createEditorView */
export interface EditorStateAndViewConfig {
  isReadOnly: boolean;
  autocompleteKeyDownRef?: React.RefObject<((event: KeyboardEvent) => boolean) | null>;
}

// createEditorState is exported for tests
export function createEditorState(
  editorStateAndViewConfig: EditorStateAndViewConfig,
  /** The configuration exclusive to createEditorState */
  editorStateExclusiveConfig: {
    isStartingWithNoResults: boolean;
    initialDoc: Node;
    stopRecording: (audioId: string) => void;
  },
): EditorState {
  const { isReadOnly, autocompleteKeyDownRef } = editorStateAndViewConfig;
  const { isStartingWithNoResults, initialDoc, stopRecording } = editorStateExclusiveConfig;
  return EditorState.create({
    doc: initialDoc,
    plugins: isReadOnly
      ? []
      : createPlugins({
          autocompleteKeyDownRef,
          isStartingWithNoResults,
          stopRecording,
        }),
  });
}

export function createPlugins({
  autocompleteKeyDownRef,
  isStartingWithNoResults = false,
  stopRecording = () => {},
}: {
  autocompleteKeyDownRef?: React.RefObject<((event: KeyboardEvent) => boolean) | null>;
  isStartingWithNoResults?: boolean;
  stopRecording?: (audioId: string) => void;
} = {}): Plugin[] {
  const showInTimeline = getShowNoteInTimeline(updateSearchQuery);
  return [
    persistencePlugin, // must be 1st because it updates the model that many of the other plugins rely on
    mobileEventHandlerPlugin,
    trackRangesPlugin,
    fixTokenIdsPlugin,
    dropCursor({}),
    // autocomplete plugin comes next so it can trap arrow keys
    autocompletePlugin([schema.nodes.paragraph], autocompleteKeyDownRef),
    checkboxPlugin,
    splitNoteOnTripleDashPlugin,
    createNestedListPlugin,
    textExpanderPlugin({
      "→": /->$/g,
      "←": /<-$/g,
      "—": /--$/g,
    }),
    ignoreAutocorrectDuringHashtagAutocomplete,
    atSignToHashtagPlugin,
    tildeToHashtagPlugin,
    handleParagraphActionsPlugin,
    arrowKeyFixPlugin,
    codePlugin,
    codeInputRulesPlugin,
    dateDividerPlugin,
    ...(isUnsyncedDecoratorEnabled ? [noteUnsyncedPlugin] : []),
    // add note debugger plugin in non-prod environments
    ...(isLocal ? [noteDebuggerPlugin] : []),
    ...(isTouchDevice ? [mobileNoteMenuPlugin] : []),
    ensureAtLeastOneNote({ isStartingWithNoResults }),
    toggleEllipsisPlugin,
    applyMarksPlugin,
    expansionEditMirrorPlugin,
    backlinkTogglePlugin,
    warnOnManyDeletePlugin,
    preventDeletionAcrossExpansionPlugin,
    fixDeletedExpandedNote,
    linkPreviewPlugin,
    fixDragDropPlugin,
    ...(isIOs ? [selectAllPlugin] : []),
    history({ newGroupDelay: 300 }),
    handleListActionsPlugin,
    keymapPlugin,
    toggleCheckboxPlugin,
    keymap({
      "Meta-i": () => true,
      "-": listifyCommand,
      [nonCustomizableShortcuts.jumpTo.keys]: (state, dispatch) => {
        const res = showInTimeline(state, dispatch);
        if (!res) return false;
        trackEvent("jump_to_note", "button");
        return true;
      },
      '"': (state, dispatch) => {
        return wrapSelectionInText('"', state, dispatch);
      },
      "'": (state, dispatch) => {
        return wrapSelectionInText("'", state, dispatch);
      },
    }),
    keymap(baseKeymap),
    createAudioPlugin(stopRecording),
    highlightPlugin, // must appear at the end, because it depends on all the other plugins modifying the content.
    historyWatcherPlugin,
  ];
}
