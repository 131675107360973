import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { PluginKey, Plugin, EditorState } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { NoteId } from "../../../../shared/types";
import { descendNotes } from "../../utils/descendNotes";
import { getBacklinkOffsetInNote } from "../backlink/backlinkPlugin";
import { findDescendantNote } from "../../utils/find";
import { nonCustomizableShortcuts } from "../../../shortcuts/rawShortcuts";
import { getShortcutString } from "../../../shortcuts/shortcuts";
import { appNoteStore } from "../../../model/services";
import { toggleCondensedOnNoteNode } from "./utils";

/** Note character length above which we show the toggle button at the top of the note*/
const SHOW_TOGGLE_ABOVE_THRESHOLD = 500;

// Overwriteable data for handling touch events as clicks
let touchStartData: { x: number; y: number; time: number } | null = null;

interface StateEntry {
  decorationOffset: number;
  noteOffset: number;
  node: Node;
  length: number;
}

type State = Map<NoteId, StateEntry>;

const getNodes = (doc: EditorState["doc"]): State => {
  const nodes: State = new Map();
  descendNotes(doc, (node, pos) => {
    if (node.attrs.condensingEnabled && node.attrs.condensable) {
      nodes.set(node.attrs.path, {
        decorationOffset: pos + getBacklinkOffsetInNote(node),
        node,
        noteOffset: pos,
        length: appNoteStore.getNoteLength(node.attrs.noteId),
      });
    }
  });
  return nodes;
};

const getDom = (isCondensed: boolean) => {
  const dom = document.createElement("div");
  dom.className = "expand-button";
  dom.textContent = isCondensed ? "... Show more" : "Show less";
  dom.role = "tooltip";
  dom.setAttribute("data-microtip-position", "left");
  dom.setAttribute(
    "aria-label",
    isCondensed
      ? `${getShortcutString(nonCustomizableShortcuts.expandReference)}`
      : `${getShortcutString(nonCustomizableShortcuts.collapseReference)}`,
  );
  return dom;
};

const handleEvent = (view: EditorView, event: MouseEvent | TouchEvent) => {
  if (!isEventOnEllipsisButton(event)) return;
  event.preventDefault();

  // get the noteid of the note we're in
  const notePath = (event.target as HTMLElement)?.closest(".note[data-path]")?.getAttribute("data-path");
  if (!notePath) return false;

  // find the corresponding node
  const [node, pos] = findDescendantNote(view.state.doc, (n) => n.attrs.path === notePath);
  if (node === null || pos === null) return false;

  const tr = view.state.tr;
  toggleCondensedOnNoteNode(tr, pos);
  view.dispatch(tr);

  return true;
};

export const toggleEllipsisPlugin: Plugin<State> = new Plugin({
  key: new PluginKey("condenseNote"),
  state: {
    init: (_config, state) => getNodes(state.doc),
    apply: (tr, pluginState) => (tr.docChanged ? getNodes(tr.doc) : pluginState),
  },
  props: {
    handleDOMEvents: {
      click: handleEvent,
      touchstart: (_: EditorView, event: TouchEvent) => {
        const touch = event.touches[0];
        touchStartData = { x: touch.clientX, y: touch.clientY, time: Date.now() };
      },
      touchend: (view: EditorView, event: TouchEvent) => {
        if (!touchStartData) return;
        const touch = event.changedTouches[0];
        const dx = touch.clientX - touchStartData.x;
        const dy = touch.clientY - touchStartData.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const duration = Date.now() - touchStartData.time;

        if (distance < 20 && duration < 150) {
          handleEvent(view, event);
        }

        touchStartData = null;
      },
    },
    decorations(state) {
      const decorations: Decoration[] = [];
      (this as Plugin)
        .getState(state)!
        .forEach(({ decorationOffset, node, noteOffset, length }: StateEntry, noteId: NoteId) => {
          if (length > SHOW_TOGGLE_ABOVE_THRESHOLD) {
            decorations.push(
              Decoration.widget(noteOffset + 1, () => getDom(node.attrs.isCondensed), {
                side: -1,
                key: `${noteId}-expanded-${node.attrs.isCondensed}-top`,
              }),
            );
          }
          decorations.push(
            Decoration.widget(decorationOffset, () => getDom(node.attrs.isCondensed), {
              side: -1,
              key: `${noteId}-expanded-${node.attrs.isCondensed}-bottom`,
            }),
          );
        });

      return DecorationSet.create(state.doc, decorations);
    },
  },
});

function isEventOnEllipsisButton(e: MouseEvent | TouchEvent) {
  const el = e.target as Element;
  if (!el) return false;
  const isExpandButton = el.classList.contains("expand-button");
  const isEllipsisDot = el.classList.contains("ellipsis-dot") || el.parentElement?.classList.contains("ellipsis-dot");
  return isExpandButton || isEllipsisDot;
}
