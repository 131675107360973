import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { useSetAtom } from "jotai";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Hash, X } from "react-feather";
import { scrollEditorToTop } from "../../../editorPage/App";
import { isSidebarOpenAtom } from "../../../model/atoms";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { mediumWidth } from "../../../utils/style";
import { GrabHandle } from "../GrabHandle";
import { addToast } from "../../../components/Toast";
import { useNotifySidebarUpdate } from "../../atoms/sidebarUpdate";
import { getUserSettings, upsertUserSettings } from "../../../model/userSettings";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { pinnedPositions } from "../../../model/services";
import { PinnedHashtag as PinnedHashtagType } from "../../../../shared/types";
import { PinnedLink } from "./PinnedLink";

interface Props {
  hashtag: string;
  setSearch: SearchQuerySetter;
}

export const PinnedHashtag: React.FC<Props> = ({ hashtag, setSearch }) => {
  const updateSidebar = useNotifySidebarUpdate();

  const togglePin = useCallback(() => {
    const { pinnedHashtags } = getUserSettings();
    if (pinnedHashtags.find((h) => h.id === hashtag)) {
      const newHashtags = pinnedHashtags.filter((h) => h.id !== hashtag);
      upsertUserSettings({ pinnedHashtags: newHashtags });
      addToast("Hashtag unpinned");
      trackEvent("hashtag_unpinned", hashtag);
    } else {
      const newHashtags = [
        { id: hashtag, positionInPinned: pinnedPositions.generateFirst()[0], type: "hashtag" },
        ...pinnedHashtags,
      ] as PinnedHashtagType[];
      upsertUserSettings({ pinnedHashtags: newHashtags });
      addToast("Hashtag pinned");
      trackEvent("hashtag_pinned", hashtag);
    }
  }, [hashtag]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: hashtag });

  const setShowSidebar = useSetAtom(isSidebarOpenAtom);

  const onSelect = () => {
    if (window.innerWidth <= mediumWidth) setShowSidebar(false);
    scrollEditorToTop();
    setSearch({ hashtagsList: [hashtag] });
  };

  // Remove the leading '#' from the hashtag
  const displayHashtag = hashtag.startsWith("#") ? hashtag.slice(1) : hashtag;

  return (
    <PinnedItemWrapper
      className="pinned-item"
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition as any,
      }}
      ref={setNodeRef}
      onClick={onSelect}
      onKeyPress={(evt) => evt.key === "Enter" && onSelect()}
      {...attributes}
    >
      <PinnedLink id={`pinned-hashtag-${hashtag}`} title={displayHashtag}>
        <PinnedItemContent>
          <GrabHandleWrapper onClick={(e) => e.stopPropagation()}>
            <GrabHandle title="Reorder this pinned hashtag" {...listeners}>
              ⠿
            </GrabHandle>
          </GrabHandleWrapper>
          <HashtagContainer className="hashtag-container">
            <HashtagContent>
              <IconWrapper className="icon-wrapper">
                <Hash size={16} />
              </IconWrapper>
              <HashtagPreview>{displayHashtag}</HashtagPreview>
            </HashtagContent>
            <CloseButton
              className="close-button"
              onClick={(e) => {
                e.stopPropagation();
                togglePin();
                updateSidebar();
              }}
              title="Unpin hashtag"
            >
              <X size={16} />
            </CloseButton>
          </HashtagContainer>
        </PinnedItemContent>
      </PinnedLink>
    </PinnedItemWrapper>
  );
};

const PinnedItemWrapper = styled.div`
  margin: 1px 0;
  user-select: none;
`;

const PinnedItemContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const HashtagContainer = styled.div`
  width: 0;
  flex: 1;
  display: flex;
  align-items: center;
  background-color: var(--color-bg-secondary);
  height: 36px;
  padding: 0 8px 0 4px;
  border-radius: 6px;
  justify-content: space-between;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: var(--color-bg-tertiary);
  }

  &:hover .close-button {
    opacity: 1;
  }

  &:hover .icon-wrapper {
    opacity: 1;
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  opacity: 0.75;
  transition: opacity 0.152s ease-in-out;
`;

const GrabHandleWrapper = styled.div`
  position: absolute;
  left: -10px;
`;

const HashtagContent = styled.div`
  width: 0;
  flex: 1;
  display: flex;
  align-items: center;
`;

const HashtagPreview = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 6px;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: var(--color-text-tertiary);
  padding: 0;
  margin-left: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  transition:
    color 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
  opacity: 0;

  &:hover {
    color: var(--color-text-secondary);
  }
`;
