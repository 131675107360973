import { addToast } from "../components/Toast";
import { ModalEnum, openModal } from "../model/modals";

export function exportPromptOnUserSave() {
  addToast({
    content: (
      <div>
        <span>You notes are saved to the cloud automatically. Would you like to </span>
        <span className="link no-word-break" onClick={(e) => openModal(ModalEnum.EXPORT)}>
          export
        </span>
        <span> a copy of your notes?</span>
      </div>
    ),
  });
}
