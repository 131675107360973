import appLogger from "../../../utils/logger";
import { MediaRecorder, CreateiOSMediaRecorderProps, MediaRecorderState } from "./mediaRecorder";

const logger = appLogger.with({ namespace: `RecordingManager` });

export async function createiOSMediaRecorder({
  audioId,
  onStateChange,
  cleanup,
}: CreateiOSMediaRecorderProps): Promise<MediaRecorder> {
  await startiOSRecording(audioId);
  let state: MediaRecorderState = "recording";

  function interruptRecording() {
    onStateChange();
    cleanupListener();
    cleanup();
  }

  // Is this relevant when recording is not handled directly by the browser?
  window.addEventListener("beforeunload", interruptRecording);
  const cleanupListener = () => window.removeEventListener("beforeunload", interruptRecording);

  function startiOSRecording(audioId: string) {
    logger.info("Starting recording from iOS");
    const message = { action: "recordAudio", audioId, message: "startRecording" };

    window.webkit?.messageHandlers.recordAudio.postMessage(message);
  }

  function endiOSRecording(audioId: string) {
    logger.info("Stopping recording from iOS");
    const message = { action: "recordAudio", audioId, message: "endRecording" };

    window.webkit?.messageHandlers.recordAudio.postMessage(message);
  }

  return {
    getState() {
      return state;
    },
    async stop() {
      state = "inactive";
      endiOSRecording(audioId);
      onStateChange();
      await stop();
      cleanupListener();
      cleanup();
    },
    setState(newState: MediaRecorderState) {
      state = newState;
      onStateChange();
    },
    onStateChange,
  };
}
