import camelCase from "lodash.camelcase";
import { ResolvedPos } from "prosemirror-model";
import { Command, EditorState, TextSelection, Transaction } from "prosemirror-state";
import { schema } from "../../schema";
import { appNoteStore } from "../../../model/services";
import { noteToProsemirrorNode } from "../../bridge";
import { generateId } from "../../../model/generateId";
import { addToast } from "../../../components/Toast";

export function wrapSelectionInText(
  text: string,
  state: EditorState,
  dispatch?: ((tr: Transaction) => void) | undefined,
): boolean {
  if (!dispatch) return false;
  if (state.selection.empty) return false;
  const { from, to } = state.selection;
  const tr = state.tr.insertText(text, from).insertText(text, to + text.length);
  tr.setSelection(TextSelection.create(tr.doc, from + text.length, to + text.length));
  dispatch(tr);
  return true;
}

/**
 * Selection is plain text if it contains only text nodes and those text nodes
 * aren't hashtags or links.
 */
function selectionIsPlainText(state: EditorState): boolean {
  let allText = true;
  const slice = state.doc.slice(state.selection.from, state.selection.to);
  slice.content.forEach((node) => {
    if (!node.isText) {
      allText = false;
    } else if (node.marks.some((m) => m.type === schema.marks.hashtag || m.type === schema.marks.link)) {
      allText = false;
    }
  });
  return allText;
}

/**
 * Replace the current selection with a hashtag for the selected text,
 * converting the text to camel case.
 *
 * Similar to {@link selectionToReference}
 */
export const selectionToHashtag: Command = (state, dispatch) => {
  if (!dispatch) return false;
  if (!selectionIsPlainText(state)) return false;

  // Must have a selection
  const { from, to } = state.selection;
  if (from === to) return false;

  // Convert selection to camel case hashtag
  let hashtag = "#";
  hashtag += camelCase(state.doc.textBetween(from, to, " "));
  if (!spaceOrStartBehind(state.selection.$from)) {
    hashtag = " " + hashtag;
  }

  // Insert hashtag and set selection to end
  const tr = state.tr;
  tr.insertText(hashtag, from, to);
  const newTo = tr.mapping.map(to);
  tr.setSelection(TextSelection.create(tr.doc, newTo));

  dispatch(tr);
  return true;
};

/**
 * Replace the current selection with a reference. If the selection is within a
 * single line, replace selection with an autocomplete for the selected text. If
 * the selection spans multiple lines, create a new note with the selected text
 * as the content and insert a reference to the new note.
 *
 * Similar to {@link selectionToHashtag}
 */
export const selectionToReference: Command = (state, dispatch) => {
  if (!dispatch) return false;
  // Must have a selection
  const { $from, $to } = state.selection;
  if ($from.pos === $to.pos) return false;
  if ($from.parent === $to.parent) {
    // Selection spans a single line
    const prefix = spaceOrStartBehind(state.selection.$from) ? "+" : " +";
    const selectedText = state.doc.textBetween($from.pos, $to.pos, " ");
    if (selectedText.includes("+")) {
      addToast("Can't create reference from selection containing a '+' character");
      return true;
    }
    dispatch(state.tr.replaceSelectionWith(schema.text(prefix + selectedText)));
    return true;
  } else {
    // Selection spans multiple lines
    // If the selection spans multiple notes, do nothing
    const commonAncestor = $from.node($from.sharedDepth($to.pos));
    if (commonAncestor.type === schema.nodes.doc) return false;
    // Insert a new note in the model after the top-level note containing the selection
    const noteNode = $to.node(1);
    const [newNote] = appNoteStore.insertAfter(noteNode.attrs.noteId);
    // Insert the new note in the editor
    const notePos = $to.before(1);
    const newNotePos = notePos + noteNode.nodeSize;
    const tr = state.tr
      .insert(newNotePos, noteToProsemirrorNode(newNote)) // insert empty note
      .replace(newNotePos + 1, newNotePos + 2, state.doc.slice($from.pos, $to.pos)); // then insert it's content
    // Replace the selection with a reference to the new note
    tr.replaceSelectionWith(schema.nodes.reference.create({ tokenId: generateId(), linkedNoteId: newNote.id }));
    dispatch(tr);
    return true;
  }
};

function spaceOrStartBehind($pos: ResolvedPos) {
  if ($pos.parent.type === schema.nodes.paragraph) {
    const c = $pos.parent.textContent[$pos.parentOffset - 1];
    if (c === " " || $pos.parentOffset === 0) {
      return true;
    }
  }
  return false;
}
