import styled from "@emotion/styled";
import React from "react";
import { Plus } from "react-feather";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { appFolderStore } from "../../../model/services";
import { updateSearchQuery } from "../../../search/useSearchQuery";
import { colors, radii } from "../../../utils/style";

const StyledButton = React.memo(styled.button`
  font-size: 16px;
  border-radius: ${radii.medium};
  margin: 0;
  width: 28px;
  height: 24px;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  border: none;
  box-sizing: border-box;
  color: ${colors.text.secondary};
  background: none;

  &:hover {
    color: ${colors.text.accent};
    background-color: ${colors.bg.accent.tertiary};
  }
`);

export const CreateNewFolderButton: React.FC<{
  setNewItemId: (id: string) => void;
}> = React.memo(({ setNewItemId }) => {
  const onClick = () => {
    const [folder] = appFolderStore.insert();

    updateSearchQuery({
      folderId: folder.id,
    });
    setNewItemId(folder.id);
    trackEvent("create_folder", folder.id);
  };

  return (
    <StyledButton onClick={onClick}>
      <Plus size={16} strokeWidth="2" />
    </StyledButton>
  );
});
