import { EditorState, Transaction } from "prosemirror-state";
import { schema } from "../../schema";

const currentLineStartsWithCheckbox = (state: EditorState): boolean => {
  const { $from } = state.selection;
  return (
    $from.parent.type === state.schema.nodes.paragraph &&
    $from.parent.firstChild?.type === state.schema.nodes.checkbox &&
    // If the cursor is at the start of node (before checkbox), don't create a
    // checkbox on the next line because the current checkbox will go there
    $from.start($from.depth) !== $from.pos
  );
};

const currentLineHasOnlyCheckbox = (state: EditorState): boolean => {
  const { $from } = state.selection;
  return $from.parent.nodeSize === 3 && $from.parent.firstChild?.type === schema.nodes.checkbox;
};

// This abstracts out the logic for "when user hits Enter on a line starting
// with a [], create another []at the start of the next line."
// Returns true if line should not be split (because Enter was instead used to
// remove the checkbox from the line).
export const handleSplitWithCheckbox = (state: EditorState, tr: Transaction): boolean => {
  const { $from } = state.selection;
  if (currentLineHasOnlyCheckbox(state)) {
    tr.deleteRange($from.start($from.depth), $from.end($from.depth));
    return true;
  } else {
    if (currentLineStartsWithCheckbox(state)) {
      tr.insert(state.tr.mapping.map($from.pos), state.schema.nodes.checkbox.create());
    }
    return false;
  }
};
