import { Transaction } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { getDefaultStore } from "jotai";
import { schema } from "../../schema";
import { descendNotes } from "../descendNotes";
import { autocompleteModules } from "../../features/autocomplete/modules";
import { autocompleteStateAtom } from "../../features/autocomplete/useAutocompleteState";
import { regexpMatchers } from "./regexpMatchers";
import { getMatchesForEachRegex, getTextRunsForNode, TextRun } from "./regexMarkUtils";
import { equalMarks } from "./equalMarks";

export const MarkSpecs = [
  {
    type: "hashtag" as const,
    regex: new RegExp(regexpMatchers.hashtag.source, "gu"),
    skipFirstGroup: true,
  },
  {
    type: "highConfidenceLink" as const,
    regex: regexpMatchers.highConfidenceLink,
    skipFirstGroup: false,
  },
  {
    type: "lowConfidenceLink" as const,
    regex: regexpMatchers.lowConfidenceLink,
    skipFirstGroup: true,
  },
];

/** There are a few Marks (e.g. hashtags and URL links) that are not user-set,
 * and are instead defined only as a pure function of syntax. Every transaction,
 * applyMarks runs to (re)apply these Marks in the correct places.
 */
export function applyMarks(tr: Transaction, nodesThatChangedOrAdded: Node[]): void {
  descendNotes(tr.doc, (noteNode, pos) => {
    if (!nodesThatChangedOrAdded.includes(noteNode)) return;
    const textRuns = getTextRunsForNode(noteNode, pos);
    textRuns.forEach((textRun) => applyMarksInText(tr, textRun));
  });
}

function applyMarksInText(transaction: Transaction, textRun: TextRun): void {
  const startPos = textRun.startPos;
  const text = textRun.textNodes.map((n) => n.text!).join("");
  const matches = getMatchesForEachRegex(text, MarkSpecs);
  if (equalMarks(textRun, matches)) return;
  transaction
    .removeMark(startPos, startPos + text.length, schema.marks.link)
    .removeMark(startPos, startPos + text.length, schema.marks.hashtag)
    .setMeta("addToHistory", false);

  matches.forEach(({ start, end, type }) => {
    const mark =
      type === "hashtag"
        ? schema.marks.hashtag.create()
        : schema.marks.link.create({ content: text.slice(start, end) });

    // Do not apply a hashtag mark when you are inside a reference autocomplete because
    // it breaks/ends the reference autocomplete and starts a new hashtag autocomplete
    const isReferenceAutocomplete = (
      [
        autocompleteModules.angleBrackets.matcherName,
        autocompleteModules.bulletReference.matcherName,
        autocompleteModules.conversationWithReferences.matcherName,
        autocompleteModules.plusReference.matcherName,
        autocompleteModules.relatesToReference.matcherName,
      ] as readonly string[]
    ).includes(getDefaultStore().get(autocompleteStateAtom)?.matcherName || "");

    if (
      isReferenceAutocomplete &&
      transaction.doc.rangeHasMark(startPos + start, startPos + end, schema.marks.autocompleteRegion)
    ) {
      return;
    }

    transaction.addMark(startPos + start, startPos + end, mark);
  });
}
