import { Keybinding, CustomizableShortcuts, NonCustomizableShortcuts, Shortcuts } from "../../shared/types";
import { isApple, isMobile } from "../utils/environment";

const ctrlCmd = (shortcutPart: Keybinding) => {
  return `${isApple ? "Meta" : "Ctrl"}-${shortcutPart}`;
};

// Annoyingly, on macOS, "Shift" then "k" registers the event as {key: 'K'} while
// on Linux it registers as {key: 'k'} (lowercase). This helper papers over this
// difference.
const shift = (key: string) => {
  return `Shift-${isApple ? key : key.toUpperCase()}`;
};

export const nonCustomizableShortcuts: NonCustomizableShortcuts = {
  splitNote: {
    id: "splitNote",
    label: "Split current note",
    keys: ctrlCmd("Enter"),
    isCustomizable: false,
  },
  openAsPage: {
    id: "openAsPage",
    label: "Open note as page",
    keys: ctrlCmd("j"),
    isCustomizable: false,
  },
  searchForSelection: {
    id: "searchForSelection",
    label: "Search for hashtag or selection under cursor",
    keys: ctrlCmd("Alt-/"),
    isCustomizable: false,
  },
  insertParagraph: {
    id: "insertParagraph",
    // to break out of codeblock
    label: "Leave code block",
    keys: "Shift-Enter",
    isCustomizable: false,
  },
  insertBr: {
    id: "insertBr",
    keys: "Enter",
    isCustomizable: false,
  },
  tab: {
    id: "tab",
    keys: "Tab",
    isCustomizable: false,
  },
  shiftTab: {
    id: "shiftTab",
    keys: "Shift-Tab",
    isCustomizable: false,
  },
  selectAll: {
    id: "selectAll",
    label: "Select note",
    keys: ctrlCmd("a"),
    isCustomizable: false,
  },
  undo: {
    id: "undo",
    label: "Undo",
    keys: ctrlCmd("z"),
    isCustomizable: false,
  },
  redo: {
    id: "redo",
    label: "Redo",
    // We intentionally don't use the shift('z') utility function here because
    // that seems to break things and make Redo not work on at least macOS.
    keys: ctrlCmd("Shift-z"),
    isCustomizable: false,
  },
  goToLineStart: {
    id: "goToLineStart",
    label: "Go to start of line",
    keys: isApple ? "Ctrl-a" : "Home",
    isCustomizable: false,
  },
  goToLineEnd: {
    id: "goToLineEnd",
    label: "Go to end of line",
    keys: isApple ? "Ctrl-e" : "End", // TODO : attention!
    isCustomizable: false,
  },
  goToNoteStart: {
    id: "goToNoteStart",
    label: "Go to start of note",
    keys: ctrlCmd("ArrowUp"),
    isCustomizable: false,
  },
  goToNoteEnd: {
    id: "goToNoteEnd",
    label: "Go to end of note",
    keys: ctrlCmd("ArrowDown"),
    isCustomizable: false,
  },
  collapseReference: {
    id: "collapseReference",
    label: "Collapse relation",
    keys: "Alt-ArrowUp",
    isCustomizable: false,
  },
  expandReference: {
    id: "expandReference",
    label: "Expand relation",
    keys: "Alt-ArrowDown",
    isCustomizable: false,
  },
  collapseBacklinks: {
    id: "collapseBacklinks",
    label: "Collapse backlinks",
    keys: ctrlCmd("Alt-ArrowUp"),
    isCustomizable: false,
  },
  expandBacklinks: {
    id: "expandBacklinks",
    label: "Expand backlinks",
    keys: ctrlCmd("Alt-ArrowDown"),
    isCustomizable: false,
  },
  selectUntilNoteStart: {
    id: "selectUntilNoteStart",
    label: "Select to start of note",
    keys: ctrlCmd("Shift-ArrowUp"),
    isCustomizable: false,
  },
  selectUntilNoteEnd: {
    id: "selectUntilNoteEnd",
    label: "Select to end of note",
    keys: ctrlCmd("Shift-ArrowDown"),
    isCustomizable: false,
  },
  createNewNoteFromSearch: {
    id: "createNewNoteFromSearch",
    label: "Create new note from search bar",
    keys: isMobile ? "Enter" : ctrlCmd("Enter"),
    isCustomizable: false,
  },
  toggleItalics: {
    id: "toggleItalics",
    label: "Italicize text",
    keys: ctrlCmd("i"),
    isCustomizable: false,
  },
  toggleBold: {
    id: "toggleBold",
    label: "Bold text",
    keys: ctrlCmd("b"),
    isCustomizable: false,
  },
  toggleUnderline: {
    id: "toggleUnderline",
    label: "Underline text",
    keys: ctrlCmd("u"),
    isCustomizable: false,
  },
  clearFormatting: {
    id: "clearFormatting",
    label: "Clear Formatting",
    keys: isApple ? ctrlCmd("\\") : ctrlCmd(" "), // TODO : attention!
    isCustomizable: false,
  },
  toggleCheckboxShortcut: {
    id: "toggleCheckboxShortcut",
    label: "Toggle checkbox shortcut",
    keys: ctrlCmd(shift("y")),
    isCustomizable: false,
  },
  toggleStrikethrough: {
    id: "toggleStrikethrough",
    label: "Strikethrough text",
    keys: ctrlCmd(shift("x")),
    isCustomizable: false,
  },
  backspace: {
    id: "backspace",
    keys: "Backspace",
    isCustomizable: false,
  },
  delete: {
    id: "delete",
    keys: "Delete",
    isCustomizable: false,
  },
  jumpTo: {
    id: "jumpTo",
    label: "Jump to",
    keys: "Ctrl-Shift-3",
    isCustomizable: false,
  },
  plus: {
    id: "plus",
    label: "Create relation",
    keys: "+",
    isCustomizable: false,
  },
  hashtag: {
    id: "hashtag",
    label: "Create hashtag",
    keys: "#",
    isCustomizable: false,
  },
  insertCodeblock: {
    id: "insertCodeblock",
    label: "Insert code block",
    keys: "```",
    isCustomizable: false,
  },
  savePage: {
    id: "savePage",
    label: "Export notes",
    keys: ctrlCmd("s"),
    isCustomizable: false,
  },
};

/** These shortcuts can change in runtime */
export const customizableShortcuts: CustomizableShortcuts = {
  createNewNote: {
    id: "createNewNote",
    label: "Create a new note",
    keys: ctrlCmd("k"),
    isCustomizable: true,
  },
  toggleRecording: {
    id: "toggleRecording",
    label: "Insert an audio recording at the top",
    keys: ctrlCmd(shift("j")),
    isCustomizable: true,
  },
  createNewPage: {
    id: "createNewPage",
    label: "Create a new blank page",
    keys: ctrlCmd(shift("k")),
    isCustomizable: true,
  },
  search: {
    id: "search",
    label: "Search",
    keys: ctrlCmd("/"),
    isCustomizable: true,
  },
  toggleSidebar: {
    id: "toggleSidebar",
    label: "Toggle sidebar",
    keys: ctrlCmd("d"),
    isCustomizable: true,
  },
  backToAllNotes: {
    id: "backToAllNotes",
    label: "Return to All Notes",
    keys: "Escape",
    isCustomizable: true,
  },
  toggleSettings: {
    id: "toggleSettings",
    label: "Open/close settings",
    // NOTE: AdBlock Plus intercepts Ctrl/Cmd + . before we can get it. But this
    // is deemed ok because "open settings" is not a freq used shortcut
    keys: ctrlCmd("."),
    isCustomizable: true,
  },
  toggleHelp: {
    id: "toggleHelp",
    label: "Open/close help",
    keys: ctrlCmd(isApple ? "Shift-." : "Shift->"),
    isCustomizable: true,
  },
  toggleCheckboxKey: {
    id: "toggleCheckboxKey",
    label: "Toggle checkbox key (while multiple lines selected)",
    keys: "[",
    isCustomizable: true,
  },
  extractEntitiesWithAI: {
    id: "extractEntitiesWithAI",
    label: "Extract entities and their relations with AI",
    keys: ctrlCmd(shift("p")),
    isCustomizable: true,
  },
  openTodos: {
    id: "openTodos",
    label: "Go to to-dos page",
    keys: ctrlCmd("Shift-1"),
    isCustomizable: true,
  },
};

export const shortcuts: Shortcuts = {
  ...nonCustomizableShortcuts,
  ...customizableShortcuts,
};

export const devOnlyLogoutShortcut = ctrlCmd(shift("l"));
