import { FetchWrapper } from "../fetchWrapper";
import {
  BatchNotesUpsertResponse,
  DeltaResponse,
  NoteWithPosition,
  PublicNoteResponse,
  UpsertFolderResponse,
} from "../../../shared/types";

export const NoteApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Returns stats related to notes.
     */
    getStats: async (): Promise<{
      aggregate: {
        count: number;
        max: {
          updated_at: string;
        };
      };
    }> => client.get("/notes/stats"),
    /**
     * Starts an export job on the server
     * @param exportImage
     * @param exportAudio
     */
    exportMedia: async (exportImage: boolean, exportAudio: boolean): Promise<void> =>
      client.post("/notes/export-media", {
        exportImage,
        exportAudio,
      }),

    getPublicNote: async (noteId: string): Promise<PublicNoteResponse> => client.get(`/public-notes/${noteId}`),
    getDelta: async (timestamp: string): Promise<DeltaResponse> => client.get(`/delta?timestamp=${timestamp}`),
    setPublishVisibility: async (noteId: string, visibility: boolean): Promise<{ status: boolean }> =>
      client.patch(`/notes/${noteId}/publish`, {
        visibility,
      }),
    upsertFolder: async (payload: {
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      parentId: string | null;
      id: string;
      name: string;
      position: string | null;
    }): Promise<UpsertFolderResponse> => {
      return client.post("/folders", {
        ...payload,
        createdAt: payload.createdAt.toISOString(),
        updatedAt: payload.updatedAt ? payload.updatedAt.toISOString() : null,
        deletedAt: payload.deletedAt ? payload.deletedAt.toISOString() : null,
      });
    },
    extractEntities: async (
      operationId: string,
      text: string,
    ): Promise<{ data: { outputText: string } | null; error: string | null }> =>
      client.post("/entities/extract", {
        text,
        operationId,
      }),
    upsertNotes: async (notes: NoteWithPosition[]): Promise<BatchNotesUpsertResponse> =>
      client.post("/notes", {
        notes,
      }),
  };
};
