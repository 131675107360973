import { useCallback, useEffect, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { Node } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import { Note } from "../../../shared/types";
import { appNoteStore, pinnedPositions } from "../../model/services";
import { updateSearchQuery } from "../../search/useSearchQuery";
import { useNotifySidebarUpdate } from "../../sidebar/atoms/sidebarUpdate";
import { copyRichTextToClipboard, copyTextToClipboard } from "../../export/copyToClipboard";
import { parseInsertedAt } from "../utils/insertedAt";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { toUrl } from "../../search/SearchQuery";
import { extractEntitiesInNoteAndAppendToNote } from "../../entities/extractEntitiesInNote";
import { editorViewAtom, getUserHandleOrId, isOnlineAtom, menuUpdate } from "../../model/atoms";
import { findDescendantNote } from "../../editor/utils/find";
import { addToast } from "../../components/Toast";
import { transformCopied } from "../../editor/utils/clipboard/transforms";
import { serializeForClipboard } from "../../editor/utils/serializeForClipboard";
import { noteToProsemirrorNode } from "../../editor/bridge";
import { fixPublicUrlInNativeApp } from "../utils/fixPublicUrlInNativeApp";
import logger from "../../utils/logger";
import { schema } from "../../editor/schema";
import { ModalEnum, openModal } from "../../model/modals";
import { ApiClient } from "../../api/client";

// Shared actions between the mobile and desktop menu.
export const useMenuActions = (
  note: Note,
  notePath: string,
  closeMenu: () => void,
  setShouldStayVisible = (_: boolean) => {},
) => {
  const update = useSetAtom(menuUpdate);
  const forceUpdate = useCallback(() => update((x) => x + 1), [update]);

  const updateSidebar = useNotifySidebarUpdate();
  const [menuExpanded, setMenuExpanded] = useState<"folder" | "more" | null>(null);
  const prevCloseMenu = closeMenu;
  closeMenu = useCallback(() => {
    prevCloseMenu();
    setMenuExpanded(null);
  }, [prevCloseMenu, setMenuExpanded]);

  // reset menu expansion when hovering another noteId.
  useEffect(() => setMenuExpanded(null), [note.id]);

  const getEditorView = useAtomCallback(useCallback((get) => get(editorViewAtom), []));
  const isOnline = useAtomValue(isOnlineAtom);

  // toggle menu expansion
  const toggleMenuExpanded = useCallback(
    (menu: "folder" | "more" | null) => () => {
      // when we click on a menu already expanded, we want to the menu to be able to disappear again
      // and if we open a new menu, we want it to stay visible.
      setShouldStayVisible(!!menu && menu !== menuExpanded);
      setMenuExpanded((m) => (m !== menu ? menu : null));
      forceUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [note, setMenuExpanded, setShouldStayVisible, menuExpanded, forceUpdate],
  );

  // pin
  const togglePin = useCallback(() => {
    const positionInPinned = note.positionInPinned ? null : pinnedPositions.generateFirst()[0];
    appNoteStore.update({ id: note.id, positionInPinned });
    forceUpdate();
    updateSidebar();
    closeMenu();
    addToast(positionInPinned ? "Note pinned" : "Note unpinned");
    trackEvent("toggle_note_pinned", note.id);
  }, [forceUpdate, note, updateSidebar, closeMenu]);

  // open as page
  const openAsPage = useCallback(() => {
    updateSearchQuery({ noteIdList: [note.id] });
    closeMenu();
    trackEvent("open_note_as_page", note.id);
  }, [note.id, closeMenu]);

  async function togglePublish(note: Note) {
    const newReadAll = !note.readAll;

    // First, upadate the note in the local store
    appNoteStore.update({ id: note.id, readAll: newReadAll, isSharedPrivately: true });

    // Then, send an immediate request to the server to update the note, preventing
    // issues with the update not syncing to the server before the user navigates away (ENT-2801)
    if (isOnline) {
      const result = await ApiClient().notes.setPublishVisibility(note.id, newReadAll);
      if (!result.status) {
        logger.error("Error updating note publish state on server", { context: result });
      }
    }

    // Finally, update the note's display in the editor
    const view = getEditorView();
    if (!view) return;
    const tr = view.state.tr;
    const [node, posNote] = findDescendantNote(tr.doc, (n) => n.attrs.noteId === note.id);
    if (!node) return;
    tr.setNodeMarkup(posNote, undefined, {
      ...node.attrs,
      readAll: newReadAll,
    });
    view.dispatch(tr);
  }

  const share = () => {
    appNoteStore.update({ id: note.id, isSharedPrivately: true });
    forceUpdate();
    const publicNoteUrl = fixPublicUrlInNativeApp(
      window.location.protocol + "//" + window.location.host + "/shared/" + note.id,
    );
    (navigator as any)?.clipboard.writeText(publicNoteUrl);
    updateSidebar();
    closeMenu();
    addToast({
      content: "Copied link to shared note!",
      buttons: [
        {
          text: "Take me there",
          onClick() {
            try {
              window.open(publicNoteUrl, "_blank")?.focus();
            } catch (e) {
              logger.error(e);
            }
          },
        },
      ],
    });
  };

  const stopPublishing = () => {
    togglePublish(note);
  };

  const publish = () => {
    const view = getEditorView();
    if (!view) return;
    const tr = view.state.tr;
    const [noteNode] = findDescendantNote(tr.doc, (n) => n.attrs.path === notePath);
    if (!noteNode) return;
    const linkedNoteIds: string[] = [];
    noteNode.descendants((node, offset) => {
      // Don't descend into expanded references. We only want references that are directly inside
      // the content of the note.
      if (node.type === schema.nodes.expandedReference) return false;
      if (node.type === schema.nodes.reference) {
        linkedNoteIds.push(node.attrs.linkedNoteId);
        return false;
      }
    });
    const closeCallback = async (accept: boolean) => {
      if (accept) {
        for (const noteId of linkedNoteIds) {
          const linkedNote = appNoteStore.get(noteId);
          if (!linkedNote) return;
          if (!linkedNote.readAll) {
            await togglePublish(linkedNote);
          }
        }
      }
      togglePublish(note);
      const handleOrId = getUserHandleOrId();
      const publicThoughtstreamUrl = fixPublicUrlInNativeApp(
        window.location.protocol + "//" + window.location.host + "/sharedBy/" + handleOrId,
      );
      (navigator as any)?.clipboard.writeText(publicThoughtstreamUrl);
      updateSidebar();
      closeMenu();
      addToast({
        content: "Published to public thoughtstream!",
        buttons: isOnline
          ? [
              {
                text: "Take me there",
                onClick() {
                  try {
                    window.open(publicThoughtstreamUrl, "_blank")?.focus();
                  } catch (e) {
                    logger.error(e);
                  }
                },
              },
            ]
          : undefined,
      });
    };
    const numUnpublishedRefs = linkedNoteIds.reduce((acc, noteId) => {
      const linkedNote = appNoteStore.get(noteId);
      if (!linkedNote) return acc;
      if (!linkedNote.readAll) {
        return acc + 1;
      }

      return acc;
    }, 0);

    if (numUnpublishedRefs) {
      openModal(ModalEnum.PUBLISH_DESCENDANTS, {
        closeCallback,
        customProps: {
          numRefs: numUnpublishedRefs,
        },
      });
    } else {
      closeCallback(false);
    }
  };

  const goToDay = () => {
    updateSearchQuery({
      date: parseInsertedAt(note.insertedAt),
    });
    setMenuExpanded(null);
    forceUpdate();
    closeMenu();
  };

  const copy = async () => {
    const view = await getEditorView();
    if (!view) return;
    const [node, position] = findDescendantNote(view.state.doc, (n) => n.attrs.noteId === note.id);
    if (!node) return;
    const selectedSlice = view.state.doc.slice(position, position + node.nodeSize);
    const { html } = serializeForClipboard(view, transformCopied(selectedSlice));
    closeMenu();
    copyRichTextToClipboard(html);
    addToast("Note copied to clipboard!");
    trackEvent("copy_note_text", note.id);
  };

  const copyUrl = () => {
    const url = toUrl({ noteIdList: [note.id] });
    closeMenu();
    copyTextToClipboard(fixPublicUrlInNativeApp(url.toString()));
    addToast("Internal link copied to clipboard!");
    trackEvent("copy_note_url");
  };

  const jump = () => {
    updateSearchQuery({
      isAll: true,
      jumpTo: note.id,
    });
    trackEvent("jump_to_note", "button");
    closeMenu();
    forceUpdate();
  };

  /**
   * Delete note by deleting the top-level node in the editor. This means it can
   * only be used to delete notes that are currently in view as a top-level
   * note, so won't work on notes that are in an expansion.
   *
   * This choice just makes the implementation easier. The persistence plugin
   * handles deleting the note, and also undeleting it if the user presses undo.
   * If we allow deleting a note from an expansion, while the top level note
   * isn't in view, we would need to add a new code path to handle that, which
   * wasn't deemed worth it at the time of writing.
   */
  const deleteNote = async () => {
    const view = await getEditorView();
    if (!view) return;
    // Find the top-level note node in the editor.
    let pos = 0;
    let node: Node | null = null;
    for (let i = 0; i < view.state.doc.content.childCount; i++) {
      const child = view.state.doc.content.child(i);
      if (child.attrs.noteId === note.id) {
        node = child;
        break;
      }
      pos += child.nodeSize;
    }
    // Delete the note if we found it.
    if (node === null) {
      addToast("Note not found");
    } else {
      const tr = view.state.tr.delete(pos, pos + node.nodeSize);
      view.dispatch(tr);
      appNoteStore.update({ id: note.id, positionInPinned: null });
      addToast("Note deleted");
      trackEvent("delete_note", note.id);
    }
    closeMenu();
  };

  const extractEntitiesWithAI = useCallback(async () => {
    const editorView = await getEditorView();
    if (!editorView) return;
    const tr = editorView.state.tr;
    // Append entities to the current note.
    const [node, posNote] = findDescendantNote(tr.doc, (n) => n.attrs.path === notePath);
    if (!node) return;
    extractEntitiesInNoteAndAppendToNote(tr, posNote);
    // Select the paragraph.
    tr.setSelection(Selection.near(tr.doc.resolve(posNote)));
    editorView.dispatch(tr);
    closeMenu();
    setMenuExpanded(null);
  }, [notePath, getEditorView, closeMenu]);

  const cycleCollapse = useCallback(async () => {
    // Update the note data model
    let newSetting: "expand" | "collapse" | "auto" = "auto";
    if (note.expansionSetting === "collapse") {
      newSetting = "expand";
    } else if (note.expansionSetting === "expand") {
      newSetting = "auto";
    } else {
      newSetting = "collapse";
    }
    const [newNote] = appNoteStore.update({
      id: note.id,
      expansionSetting: newSetting,
    });

    // Re-render the note in the editor by deleting and re-inserting it
    const view = await getEditorView();
    if (!view) return;
    const tr = view.state.tr;
    const [node, position] = findDescendantNote(tr.doc, (n) => n.attrs.path === notePath);
    if (!node) return;
    tr.delete(position, position + node.nodeSize);
    const newNode = noteToProsemirrorNode(newNote);
    tr.insert(position, newNode);
    view.dispatch(tr);

    // Force update of the "More" menu to reflect the new expansion setting
    forceUpdate();
  }, [note, getEditorView, forceUpdate, notePath]);

  return {
    toggleMenuExpanded,
    togglePin,
    stopPublishing,
    openAsPage,
    share,
    publish,
    copy,
    copyUrl,
    goToDay,
    jump,
    extractEntitiesWithAI,
    menuExpanded: () => menuExpanded,
    forceUpdate,
    deleteNote,
    cycleCollapse,
  };
};
