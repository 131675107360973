import { removeDiacritics } from "../search/find";
import { isApple } from "../utils/environment";
import type { Shortcut } from "../../shared/types";

export const formatAndSplitKeyString = (keys: string): string[] => {
  if (isApple) {
    keys = keys.replace("Alt", "⌥").replace("Meta", "⌘");
  }
  keys = keys
    .replace(" ", "space")
    .replace("Escape", "Esc")
    .replace("ArrowUp", "↑")
    .replace("ArrowDown", "↓")
    .replace("ArrowLeft", "←")
    .replace("ArrowRight", "→");
  keys = removeDiacritics(keys);
  return keys.split("-").map((c) => (c.length === 1 ? c.toUpperCase() : c));
};

export const getShortcutString = (shortcut: Shortcut) => {
  const { keys } = shortcut;
  const combo = formatAndSplitKeyString(keys);
  return `( ${combo.join(" + ")} )`;
};
