import styled from "@emotion/styled";
import React, { useState } from "react";
import { Calendar as CalendarIcon } from "react-feather";
import { SearchQuery } from "../search/SearchQuery";
import { colors } from "../utils/style";

type CalendarProps = {
  search: SearchQuery;
  setSearchDate: (date: Date) => void;
  dateHasNotes: (date: Date) => boolean;
};

const MonthYearFormatter = Intl.DateTimeFormat([], {
  month: "long",
  year: "numeric",
});

const CalendarDayButton = styled.div`
  width: calc(100% / 7);
  height: 28px;
  line-height: 28px;
  border-radius: 6px;
  margin: 2px 4px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  :hover {
    background: var(--color-bg-secondary);
  }
`;

const CalendarDay = ({
  month,
  search,
  setSearchDate,
  day,
  dateHasNotes,
}: CalendarProps & { month: Date; day: Date }) => {
  return (
    <CalendarDayButton
      className="calendarDay"
      onClick={() => {
        setSearchDate(day);
      }}
      style={{
        color: colors.text.primary,
        ...(month.getMonth() !== day.getMonth()
          ? {
              color: colors.text.tertiary,
            }
          : null),
        ...(new Date().toDateString() === day.toDateString()
          ? {
              background: colors.bg.accent.tertiary,
            }
          : search.date && search.date.getTime() === day.getTime()
            ? {
                color: colors.text.accent,
                background: colors.bg.secondary,
              }
            : null),
      }}
    >
      {day.getDate()}
      <div
        style={{
          position: "relative",
          bottom: "6px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "3px",
          height: "3px",
          borderRadius: "50%",
          background: dateHasNotes(day) ? colors.text.accent : "transparent",
        }}
      ></div>
    </CalendarDayButton>
  );
};

const CalendarWeek = ({
  month,
  search,
  setSearchDate,
  dateHasNotes,
  days,
}: CalendarProps & { month: Date; days: Date[] }) => {
  return (
    <div
      className="calendarWeek"
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "5px",
      }}
    >
      {days.map((day) => (
        <CalendarDay
          month={month}
          search={search}
          setSearchDate={setSearchDate}
          dateHasNotes={dateHasNotes}
          day={day}
          key={day.getTime()}
        />
      ))}
    </div>
  );
};

function getDatesByWeekInMonthCalendar(date: Date) {
  // the first calendar day is the last monday before or equal to the first of the month
  const currDayOfWeek = date.getDay();
  const startDate = new Date(date.getTime());
  startDate.setDate(date.getDate() - ((currDayOfWeek - 1) % 7));

  // the last calendar day is the last saturday after or equal to
  const lastCalendarDay = new Date(date.getTime());
  // set it to the last day of the month
  lastCalendarDay.setMonth(lastCalendarDay.getMonth() + 1, 1);
  lastCalendarDay.setDate(lastCalendarDay.getDate() - 1);
  // set it to the following sunday
  lastCalendarDay.setDate(lastCalendarDay.getDate() + ((7 - lastCalendarDay.getDay()) % 7));

  const daysInCalendarMonth = [];
  while (startDate <= lastCalendarDay) {
    daysInCalendarMonth.push(new Date(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }

  let currentWeek: Date[] = [];
  const daysByWeek: Date[][] = [];
  daysInCalendarMonth.forEach((day, i) => {
    if (i % 7 === 0) {
      currentWeek = [];
      daysByWeek.push(currentWeek);
    }
    currentWeek.push(day);
  });

  return daysByWeek;
}

const CalendarDefaultsButton = styled.button<{ selected?: boolean }>(
  ({ selected }) => `
  color: ${selected ? colors.bg.primary : "inherit"};
  background: ${selected ? colors.text.accent : "transparent"};
  border: 0;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  padding: 0 8px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${selected ? colors.text.accent : colors.bg.tertiary};
  }
  `,
);

const CalendarArrowButton = styled.button`
  color: inherit;
  background: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 4px;
  height: 26px;
  min-width: 26px;
  :hover {
    background: ${colors.bg.secondary};
  }
`;

function getStartOfToday(): Date {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
}

function getStartOfTomorrow(): Date {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 1);
  return d;
}

function getStartOfMonth(d: Date): Date {
  d.setDate(1);
  return d;
}
const CalendarMonth = ({
  month,
  setMonth,
  search,
  setSearchDate,
  dateHasNotes,
}: CalendarProps & { month: Date; setMonth: (d: Date) => void }) => {
  const daysByWeek = getDatesByWeekInMonthCalendar(month);

  return (
    <div
      style={{
        padding: "10px 6px",
        background: colors.bg.tertiary,
        borderRadius: "4px",
      }}
    >
      <div
        className="calendarHeader"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 8px 10px 8px",
        }}
      >
        <CalendarArrowButton
          title="Last Month"
          onClick={() => {
            const d = new Date(month.getTime());
            d.setMonth(d.getMonth() - 1);
            setMonth(d);
          }}
        >
          &larr;
        </CalendarArrowButton>
        <CalendarArrowButton
          title="Current Month"
          onClick={() => setMonth(getStartOfMonth(getStartOfToday()))}
          style={{
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
        >
          {MonthYearFormatter.format(month)}
        </CalendarArrowButton>
        <CalendarArrowButton
          title="Next Month"
          onClick={() => {
            const d = new Date(month.getTime());
            d.setMonth(d.getMonth() + 1);
            setMonth(d);
          }}
        >
          &rarr;
        </CalendarArrowButton>
      </div>
      <div className="calendarBox">
        <div
          className="weekDayInitials"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {["M", "T", "W", "R", "F", "S", "U"].map((weekDayInitial) => {
            return (
              <div
                style={{
                  width: "calc(100% / 7)",
                  textAlign: "center",
                  paddingBottom: 6,
                  color: colors.text.secondary,
                  fontSize: 14,
                }}
                key={weekDayInitial}
              >
                {weekDayInitial}
              </div>
            );
          })}
        </div>
        {daysByWeek
          .filter((days) => days.length)
          .map((daysInWeek) => {
            return (
              <CalendarWeek
                month={month}
                search={search}
                setSearchDate={setSearchDate}
                dateHasNotes={dateHasNotes}
                days={daysInWeek}
                key={daysInWeek[0].getTime()}
              />
            );
          })}
      </div>
    </div>
  );
};

const Calendar = ({ search, setSearchDate, dateHasNotes }: CalendarProps) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [month, setMonth] = useState<Date>(getStartOfMonth(getStartOfToday()));
  return (
    <div
      className="sidebarCalendar nonselectable"
      style={{
        marginTop: 2,
        color: colors.text.accent,
      }}
    >
      <div
        className="shortcuts"
        style={{
          marginBottom: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CalendarDefaultsButton
          title="Find another date"
          onClick={() => setShowCalendar(!showCalendar)}
          style={{
            border: 0,
            cursor: "pointer",
            borderRadius: 4,
            fontSize: 14,
            lineHeight: 24,
            padding: "4px 4px",
            height: 28,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: colors.text.accent,
          }}
        >
          <CalendarIcon size={16} style={{ marginRight: "8px" }} />
          Calendar
        </CalendarDefaultsButton>
        <CalendarDefaultsButton
          selected={false}
          onClick={() => {
            setMonth(getStartOfMonth(getStartOfToday()));
            setSearchDate(getStartOfToday());
          }}
        >
          Today
        </CalendarDefaultsButton>
        <CalendarDefaultsButton
          selected={false}
          onClick={() => {
            setMonth(getStartOfMonth(getStartOfToday()));
            setSearchDate(getStartOfTomorrow());
          }}
        >
          Tomorrow
        </CalendarDefaultsButton>
      </div>
      {showCalendar && (
        <CalendarMonth
          month={month}
          setMonth={setMonth}
          search={search}
          setSearchDate={setSearchDate}
          dateHasNotes={dateHasNotes}
        />
      )}
    </div>
  );
};

export const SidebarCalendar = React.memo(Calendar);
