import { getDefaultStore, useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { EditorView } from "prosemirror-view";
import type { SearchQuery } from "../../search/SearchQuery";
import type { Hit } from "../../search/find";
import type { Note } from "../../../shared/types";
import { createEditorView, replaceAllNotes } from "../../editor/editorView";
import {
  editorViewAtom,
  getEditorView,
  notesCreatedSinceLastQueryUpdateAtom,
  wasAtLeastOneNoteAddedAtom,
} from "../../model/atoms";
import { saveSelection } from "../../model/selection";
import { getSearchQuery } from "../../search/useSearchQuery";
import { getNoteSelection } from "../noteSelection";
import { saveExpansionInEditorState } from "../../model/expansions";
import { appNoteStore } from "../../model/services";
import { sortByPosition } from "../../search/sortUtils";

/** Sets up the editor with the given notes and div.*/
export function useNotesEditor(
  notes: Hit<Note>[],
  editorDiv: React.RefObject<HTMLDivElement>,
  autocompleteKeyDownRef: React.RefObject<((event: KeyboardEvent) => boolean) | null>,
  onUpdateEditor: (editor: EditorView) => void,
) {
  const lastSearchQuery = useRef<SearchQuery | null>(null);
  const notesCreatedSinceLastQueryUpdateIds = useAtomValue(notesCreatedSinceLastQueryUpdateAtom);

  useEffect(() => {
    const searchQuery = getSearchQuery();
    const editorView = getEditorView();
    if (editorDiv.current !== null && editorView) {
      const noteIds = new Set(notes.map((n) => n.entry.id));

      const notesCreatedAtTopSinceLastQueryUpdate = notesCreatedSinceLastQueryUpdateIds
        .map((id) => appNoteStore.get(id))
        .filter((n) => n !== undefined && n.deletedAt === null && !noteIds.has(n.id))
        .map((n) => ({ entry: n, matches: [] })) as Hit<Note>[];
      sortByPosition(notesCreatedAtTopSinceLastQueryUpdate);

      const initialNotes: Hit<Note>[] = [...notesCreatedAtTopSinceLastQueryUpdate, ...notes];

      getDefaultStore().set(wasAtLeastOneNoteAddedAtom, false);
      replaceAllNotes(
        {
          initialNotes: initialNotes,
          div: editorDiv.current,
          isReadOnly: false,
          noteToFocusAtInit: searchQuery.jumpTo,
          autocompleteKeyDownRef,
        },
        editorView,
        lastSearchQuery,
      );
      onUpdateEditor(editorView);
    }

    return () => {
      const editorView = getEditorView();
      if (editorView) {
        saveExpansionInEditorState(searchQuery, editorView.state);
        saveSelection(getNoteSelection(editorView));
      }
      lastSearchQuery.current = searchQuery;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  useEffect(() => {
    if (editorDiv.current) {
      getDefaultStore().set(wasAtLeastOneNoteAddedAtom, false);
      const searchQuery = getSearchQuery();
      const editor = createEditorView(
        {
          initialNotes: notes,
          div: editorDiv.current,
          isReadOnly: false,
          noteToFocusAtInit: searchQuery.jumpTo,
          autocompleteKeyDownRef,
        },
        lastSearchQuery,
      );
      onUpdateEditor(editor.view);
      getDefaultStore().set(editorViewAtom, editor.view);
    }

    return () => {
      const editorView = getEditorView();
      const searchQuery = getSearchQuery();
      if (!editorView) return;
      saveExpansionInEditorState(searchQuery, editorView.state);
      saveSelection(getNoteSelection(editorView));
      lastSearchQuery.current = searchQuery;
      editorView.destroy();
      getDefaultStore().set(editorViewAtom, null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorDiv]);
}
