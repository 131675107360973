import { useEffect } from "react";
import { trackEvent } from "../analytics/analyticsHandlers";
import type { DevShortcut, Shortcut } from "../../shared/types";

function processShortcuts(event: KeyboardEvent) {
  const { key } = event;

  Object.values(keyboardShortcutRegistry).forEach((shortcutEntry) => {
    const { shortcut, callback } = shortcutEntry;
    const { keys } = shortcut;
    const isChar = keys.length === 1 && keys !== " ";
    const standardName = modifiers(key, event, !isChar);
    if (standardName === keys) {
      event.preventDefault();
      callback(event);
      event.stopImmediatePropagation();
      trackEvent("keyboard_shortcut", shortcut.id);
    }
  });
}

interface ShortcutEntry {
  shortcut: Shortcut | DevShortcut;
  callback: (event: KeyboardEvent) => void;
}

const keyboardShortcutRegistry: { [index: string]: ShortcutEntry } = {};

// the hot reloading sets twice those events.
if (typeof document !== "undefined") {
  document.removeEventListener("keydown", processShortcuts);
  document.addEventListener("keydown", processShortcuts);
}

function registerShortcut(shortcutEntry: ShortcutEntry): string {
  keyboardShortcutRegistry[shortcutEntry.shortcut.keys] = shortcutEntry;
  return shortcutEntry.shortcut.keys;
}

function unregisterShortcut(key: string): void {
  delete keyboardShortcutRegistry[key];
}

// stolen from prosemirror for consistency
function modifiers(name: string, event: KeyboardEvent, shift: boolean) {
  if (shift !== false && event.shiftKey) name = "Shift-" + name;
  if (event.altKey) name = "Alt-" + name;
  if (event.ctrlKey) name = "Ctrl-" + name;
  if (event.metaKey) name = "Meta-" + name;
  return name;
}

export function useHotkeys(shortcut: Shortcut | DevShortcut, callback: (e: KeyboardEvent) => void) {
  useEffect(() => {
    if (shortcut.label) {
      const key = registerShortcut({ shortcut, callback });
      return () => unregisterShortcut(key);
    } else return () => {};
  }, [callback, shortcut]);
}
