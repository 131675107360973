import logger from "../utils/logger";

function fallbackCopyTextToClipboard(text: string) {
  const wrapper = document.createElement("div");
  wrapper.innerHTML = text;

  // Avoid scrolling to bottom
  wrapper.style.top = "0";
  wrapper.style.left = "0";
  wrapper.style.position = "fixed";

  document.body.appendChild(wrapper);
  try {
    const range = document.createRange();
    range.selectNode(wrapper);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand("copy");
  } catch (e) {
    logger.error("Fallback: Oops, unable to copy", { error: e });
  }

  document.body.removeChild(wrapper);
}

export function copyTextToClipboard(text: string) {
  // clipboard is not set in unit tests
  if (!(navigator as any).clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  (navigator as any).clipboard.writeText(text).then(
    () => {},
    (err: any) => {
      logger.error("could not copy text: ", { error: err });
    },
  );
}

export function copyRichTextToClipboard(htmlWrapper: HTMLDivElement) {
  // At some point in the future this "should" be implemented using the Clipboard API
  // by doing things like
  // navigator.clipboard.write([new ClipboardItem({ [htmlWrapper]: htmlWrapper })])
  // but as of Otcober 2023 the browser support is bad enough that we're going to
  // just use document.execCommand("copy") for now

  // Need to attach wrapper div to DOM to copy; first style it so that it doesn't affect the page/cause scrolling
  htmlWrapper.style.top = "0";
  htmlWrapper.style.left = "0";
  htmlWrapper.style.position = "fixed";
  document.body.appendChild(htmlWrapper);
  try {
    const range = document.createRange();
    range.selectNode(htmlWrapper);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand("copy");
  } catch (e) {
    logger.error("Fallback: Oops, unable to copy", { error: e });
  }
  document.body.removeChild(htmlWrapper);
}
