import React, { useEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import { isLoadedAtom, wasAtLeastOneNoteAddedAtom } from "../model/atoms";
import { Spinner } from "../utils/Spinner";
import { useIsPageVisible } from "../utils/useIsPageVisible";
import { useExposeIntegrationTestFunctions } from "../editor/integrationTestsHooks";
import { NoResults } from "./NoResults";
import { useNotesEditor } from "./hooks/useNoteEditor";
import { useEditorHotkeys } from "./hooks/useEditorHotkeys";
import { NoteMenu } from "./noteMenu/NoteMenu";
import { ShowMoreAboveButtons, ShowMoreBelowButtons } from "./showMoreButtons";
import { useQueriedNotes } from "./hooks/useQueriedNotes";

export function Editor({
  autocompleteKeyDownRef,
}: {
  autocompleteKeyDownRef: React.RefObject<((event: KeyboardEvent) => boolean) | null>;
}) {
  useEditorHotkeys();
  const wasAtLeastOneNoteAdded = useAtomValue(wasAtLeastOneNoteAddedAtom);
  const isLoaded = useAtomValue(isLoadedAtom);
  const editorDiv = useRef<HTMLDivElement>(null);
  const { notes, lowerLimit, upperLimit, count } = useQueriedNotes();
  const [noteCountInEditor, setNoteCountInEditor] = useState<number | null>(null);
  useNotesEditor(notes, editorDiv, autocompleteKeyDownRef, (editor) => {
    setNoteCountInEditor(editor.state.doc.childCount);
  });
  useExposeIntegrationTestFunctions();
  const loadedWithNoNotes = isLoaded && !wasAtLeastOneNoteAdded && noteCountInEditor === 0;
  return (
    <>
      {loadedWithNoNotes && <NoResults />}
      {/* When there's a lower limit set, show "jump to top" and "load more" buttons */}
      {lowerLimit > 0 && <ShowMoreAboveButtons lowerLimit={lowerLimit} upperLimit={upperLimit} />}
      <div key="editorDiv" ref={editorDiv} className="editor-div private-editor ProseMirror" />
      {!isLoaded && <LoadingMessage />}
      {/*eslint-disable-next-line */}
      {/*@ts-ignore */}
      <NoteMenu editorDiv={editorDiv} />
      {/* When there's an uppert limit set, show a "load more" button at the bottom */}
      {upperLimit < count && <ShowMoreBelowButtons lowerLimit={lowerLimit} upperLimit={upperLimit} count={count} />}
    </>
  );
}

function LoadingMessage() {
  const isPageVisible = useIsPageVisible();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [stuckLoading, setStuckLoading] = useState(false);

  useEffect(() => {
    if (!isPageVisible) return;
    timeoutRef.current = setTimeout(() => {
      setStuckLoading(true);
    }, 10_000);
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [setStuckLoading, isPageVisible]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Spinner style={{ width: 30, height: 30, margin: 5 }} />
        <span style={{ fontStyle: "italic" }}>loading...</span>
      </div>
      {stuckLoading && (
        <div style={{ fontStyle: "italic", textAlign: "center" }}>
          (This is taking longer than expected... try{" "}
          <a href={window.location.href} className="link">
            refreshing
          </a>
          )
        </div>
      )}
    </div>
  );
}
