import { NodeView } from "prosemirror-view";
import { isMac } from "../../../utils/environment";
import { updateSearchQuery } from "../../../search/useSearchQuery";

export class HashtagView implements NodeView {
  dom: HTMLSpanElement;
  contentDOM: HTMLSpanElement;
  constructor(isReadOnly: boolean) {
    this.dom = document.createElement("span");
    this.dom.className = `hashtag ${!isReadOnly && "clickable"}`;
    this.contentDOM = this.dom;

    if (isReadOnly) return;

    const handler = (e: MouseEvent | TouchEvent) => {
      const escapeKeyPressed = isMac ? e.metaKey : e.ctrlKey;
      if (escapeKeyPressed) return;
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      const hashtag = this.contentDOM.textContent;
      if (!hashtag) return;
      updateSearchQuery((q) => {
        if (!q.hashtagsList || !q.hashtagsList.includes(hashtag)) {
          const updatedHashtagsList = q.hashtagsList ? [...q.hashtagsList, hashtag] : [hashtag];
          return { ...q, hashtagsList: updatedHashtagsList, source: "other" };
        }
        return q;
      });
    };

    this.dom.onclick = handler;
  }
}
