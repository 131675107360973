import { Plugin, PluginKey } from "prosemirror-state";
import { dedentParagraphIfNecessary } from "../text/tabCommands";
import { schema } from "../../schema";

export const handleParagraphActionsPlugin = new Plugin({
  key: new PluginKey("handleParagraphActionsPlugin"),
  props: {
    handleKeyDown(view, event) {
      if (event.key === "Enter") {
        if (event.metaKey || event.ctrlKey) {
          return false;
        }
        if (event.shiftKey) {
          return false;
        }

        const { $from, empty } = view.state.selection;
        if (!empty) return false;
        const node = view.state.doc.nodeAt($from.pos - 1);
        if (node?.type !== schema.nodes.paragraph) return false;
        if (node.content.size !== 0) return false;

        return dedentParagraphIfNecessary(view.state, view.dispatch);
      }
      return false;
    },
  },
});
