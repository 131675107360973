import styled from "@emotion/styled";
import { colors } from "../../utils/style";

export const GrabHandle = styled.div`
  display: none;
  cursor: grab;
  width: 16px;
  height: 19px;
  color: ${colors.text.tertiary};
  font-size: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  touch-action: none;
  transition: transform 150ms ease;

  :active {
    cursor: grabbing;
  }

  :hover {
    transform: scale(1.2);
  }

  .pinned-item:hover &,
  .selected-link:hover &,
  .touch-device & {
    display: inline-block;
  }
`;
