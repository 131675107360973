import { Fragment, Node } from "prosemirror-model";
import { schema } from "../schema";

/**
 * Same as {@link Node.descendants}, but only calls the given callback on note nodes.
 */
export function descendNotes(node: Node | Fragment, f: (node: Node, pos: number) => boolean | void): void {
  node.descendants((node, pos) => {
    if (node.type === schema.nodes.note) {
      return f(node, pos);
    } else {
      // descend into nodes which can contain notes
      return (
        node.type === schema.nodes.backlinks ||
        node.type === schema.nodes.expandedReference ||
        node.type === schema.nodes.bulletList ||
        node.type === schema.nodes.listItem ||
        node.type === schema.nodes.ellipsisContainer ||
        node.type === schema.nodes.ellipsis
      );
    }
  });
}
