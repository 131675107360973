import React, { forwardRef } from "react";
import { TreeItemComponentProps } from "dnd-kit-sortable-tree";
type ExpandableAndDraggableProps = TreeItemComponentProps<any> & {
  children: React.ReactNode;
};

export const ExpandableAndDraggable = forwardRef<HTMLDivElement, ExpandableAndDraggableProps>(
  ({ clone, depth, ghost, style, wrapperRef, children }, ref) => {
    return (
      <li
        ref={wrapperRef}
        className={["tree-wrapper", clone && "clone", ghost && "ghost"].filter(Boolean).join(" ")}
        style={{
          ...style,
          paddingLeft: clone ? 24 : 24 * depth,
        }}
      >
        <div className="tree-item" ref={ref}>
          {children}
        </div>
      </li>
    );
  },
);
