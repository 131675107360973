import { isPersistenceEnabled } from "../../utils/environment";
import { colors } from "../../utils/style";
import { SyncStats } from "./SyncStats";

/** Time at which the app booted */
const bootTime = Date.now();

const SyncStatuses = {
  ok: { id: "ok", message: "Everything is synced", color: colors.bg.ok },
  booting: { id: "booting", message: "Booting...", color: colors.bg.tertiary },
  offline: {
    id: "offline",
    message: "You are offline",
    color: colors.bg.offline,
  },
  disabled: {
    id: "disabled",
    message: "Persistence is disabled",
    color: colors.bg.warning,
  },
  warning: { id: "warning", message: "Syncing...", color: colors.bg.warning },
  error: {
    id: "error",
    message: "There's an issue with syncing",
    color: colors.bg.error,
  },
} as const;
type SyncStatus = (typeof SyncStatuses)[keyof typeof SyncStatuses];

export function computeSyncStatus(syncStats: SyncStats): SyncStatus {
  const { client, sw, server, countSinceLastFullySynced } = syncStats;
  const now = Date.now();

  if (!isPersistenceEnabled) {
    return SyncStatuses.disabled;
  }
  if ((!client || !sw || !server) && now - bootTime < 10_000) {
    return SyncStatuses.booting;
  }
  if (!syncStats.online) {
    return SyncStatuses.offline;
  }

  if (countSinceLastFullySynced < 2) {
    return SyncStatuses.ok;
  } else if (countSinceLastFullySynced < 4) {
    return SyncStatuses.warning;
  } else {
    return SyncStatuses.error;
  }
}
