import { isFrontend } from "./environment";

export function importWorkerURLHack(specialImportWorkerFn: () => void) {
  if (!isFrontend) return null as any as string;

  // The trickery below appears to be necessary to register a compiled ServiceWorker, Worker or AudioWorklet with nextjs
  // Webpack only compiles workers imported via the pattern `new Worker(new URL("script_to_be_compiled", import.meta.url))`
  // So we need to use that exact expression and then we can grab the URL of the compiled Worker from the Worker constructor that is called
  // This is why the `specialImportWorkerFn` must call `new Worker(new URL("script_to_be_compiled", import.meta.url))`
  // The `importWorkerURLHack` call then returns the URL of the worker script to be further processed (via the service worker registration or some other mechanism)
  const RealWorker = window.Worker;

  let workerSourceURL: URL | null = null;
  (window as any).Worker = class FakeWorkerCapturingURLToTrickNextjs {
    constructor(url: URL) {
      workerSourceURL = url;
    }
  };
  specialImportWorkerFn();
  window.Worker = RealWorker;

  if (workerSourceURL === null)
    throw new Error(
      'You must call `new Worker(new URL("script_to_be_compiled", import.meta.url))` in the specialImportWorkerFn',
    );

  return workerSourceURL;
}
