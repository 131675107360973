import { ParsedUrlQuery, parse } from "querystring";
import { HashtagId, FolderId, NoteId } from "../../shared/types";
import { RoutesOption } from "../routes";
import { regexpMatchers } from "../editor/utils/mark/regexpMatchers";
import logger from "../utils/logger";
import { Token, tokenize } from "./nlp";
import {
  setIsCondensed,
  getIsCondensed,
  setIsIncludingSubFolders,
  getIsIncludingSubFolders,
  setSortBy,
  getSortBy,
} from "./persistedSearchSettings";

export enum SortBy {
  lastUpdated = "lastUpdated",
  relevance = "relevance",
  oldest = "oldest",
  position = "position",
}
export const sortByDefault = SortBy.relevance;

export type SearchQuery = {
  lowerLimit?: number; // undefined means no limit.
  upperLimit?: number; // undefined means no limit.
  isCondensed?: boolean; // denormalize
  isIncludingSubFolders?: boolean; // denormalize
  sortBy?: SortBy; // denormalize
  source?: "init" | "popstate" | "searchBar" | "other";

  jumpTo?: string;
  keywordsList?: string[];
  hashtagsList?: HashtagId[];
  noteIdList?: NoteId[];
  date?: Date;
  folderId?: FolderId;
  hasComplete?: boolean;
  hasIncomplete?: boolean;
  hasTodo?: boolean;
  hasRecording?: boolean;
  hasImage?: boolean;
  isAll?: boolean;
  isPublic?: boolean;
  isUnsynced?: boolean;
  isUntagged?: boolean;
};

type SearchQueryKey = keyof SearchQuery;

const nonFilterKeys: SearchQueryKey[] = [
  "lowerLimit",
  "upperLimit",
  "isCondensed",
  "isIncludingSubFolders",
  "sortBy",
  "source",
  "jumpTo",
  "isAll",
];

function isAll(query: SearchQuery) {
  const keys = Object.keys(query) as SearchQueryKey[];
  if (keys.length === 0) return true;
  return keys.every((key) => nonFilterKeys.includes(key) || query[key] === undefined);
}

export type SearchQuerySetter = (s: Partial<SearchQuery>) => void;

export const isCondensable = (query: SearchQuery) => {
  if (query.isPublic) return false;
  if (query.isAll) return false;
  return (
    query.keywordsList ||
    query.hashtagsList ||
    query.hasComplete ||
    query.hasIncomplete ||
    query.hasTodo ||
    query.hasRecording ||
    query.hasImage
  );
};

export const isSortable = (query: SearchQuery) => {
  return !(query.noteIdList || query.isAll);
};

export const hasHeader = (query: SearchQuery) => {
  if (query.isAll) return false;
  return isSortable(query) || isCondensable(query) || query.folderId;
};

export const toParsedUrl = (query: SearchQuery): ParsedUrlQuery => {
  const urlQuery: any = {};
  if (query.upperLimit) urlQuery.upperLimit = query.upperLimit.toString();
  if (query.lowerLimit) urlQuery.lowerLimit = query.lowerLimit.toString();

  if (query.date) urlQuery.date = midnightUTC(query.date);
  if (query.folderId) urlQuery.folderId = query.folderId;
  if (!!query.noteIdList && query.noteIdList.length > 0) {
    urlQuery.noteIdList = JSON.stringify(query.noteIdList);
  }
  if (query.hasTodo) urlQuery.hasTodo = "1";
  if (query.hasComplete) urlQuery.hasComplete = "1";
  if (query.hasIncomplete) urlQuery.hasIncomplete = "1";
  if (query.hasRecording) urlQuery.hasRecording = "1";
  if (query.hasImage) urlQuery.hasImage = "1";
  if (query.isPublic) urlQuery.isPublic = "1";
  if (query.jumpTo) urlQuery.jumpTo = query.jumpTo;
  if (!!query.keywordsList && query.keywordsList.length > 0) {
    urlQuery.keywords = JSON.stringify(query.keywordsList);
  }
  if (!!query.hashtagsList && query.hashtagsList.length > 0) {
    urlQuery.hashtags = JSON.stringify(query.hashtagsList);
  }

  if (Object.keys(urlQuery).length !== 0 && isSortable(query)) {
    urlQuery.sortBy = query.sortBy;
  }

  return urlQuery;
};

// (used in integration tests)
// ts-prune-ignore-next
export const fromParsedUrl = (searchParams: ParsedUrlQuery): Partial<SearchQuery> => {
  const newQuery: Partial<SearchQuery> = {};
  if (!!searchParams.date && isString(searchParams?.date)) {
    const match = regexpMatchers.dateYmd.exec(searchParams.date);
    if (match) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setFullYear(parseInt(match[1]));
      date.setMonth(parseInt(match[2]) - 1);
      date.setDate(parseInt(match[3]));
      newQuery.date = date;
    }
  }
  if (!!searchParams.folderId && isString(searchParams?.folderId)) newQuery.folderId = searchParams.folderId;
  if (searchParams.noteIdList) {
    const noteIds = typeof searchParams.noteIdList === "string" ? searchParams.noteIdList : searchParams.noteIdList[0];
    try {
      newQuery.noteIdList = JSON.parse(noteIds);
    } catch (e) {
      logger.warn("Failed to parse note IDs", { context: { noteIds: noteIds }, error: e });
      newQuery.noteIdList = [noteIds];
    }
  }
  if (!!searchParams.jumpTo && isString(searchParams.jumpTo)) newQuery.jumpTo = searchParams.jumpTo;
  if (searchParams.hasTodo) newQuery.hasTodo = true;
  if (searchParams.hasComplete) newQuery.hasComplete = true;
  if (searchParams.hasIncomplete) newQuery.hasIncomplete = true;
  if (searchParams.hasRecording) newQuery.hasRecording = true;
  if (searchParams.hasImage) newQuery.hasImage = true;
  if (searchParams.isPublic) newQuery.isPublic = true;
  if (searchParams.isUnsynced) newQuery.isUnsynced = true;
  if (searchParams.isUntagged) newQuery.isUntagged = true;
  if (searchParams.keywords) {
    const keywordsParam = typeof searchParams.keywords === "string" ? searchParams.keywords : searchParams.keywords[0];
    try {
      const keywords = JSON.parse(keywordsParam);
      if (!Array.isArray(keywords) || keywords.some((k) => typeof k !== "string")) {
        throw new Error("Invalid keywords");
      }
      newQuery.keywordsList = keywords;
    } catch (error) {
      logger.warn("Failed to parse keywords", { context: { keywordsParam }, error });
      newQuery.keywordsList = [keywordsParam];
    }
  }
  if (searchParams.hashtags) {
    const hashtags = typeof searchParams.hashtags === "string" ? searchParams.hashtags : searchParams.hashtags[0];
    try {
      newQuery.hashtagsList = JSON.parse(hashtags);
    } catch (e) {
      logger.warn("Failed to parse hashtags", { context: { hashtags }, error: e });
      newQuery.hashtagsList = [hashtags];
    }
  }
  newQuery.isAll = isAll(newQuery);

  if (searchParams.sortBy && !newQuery.isAll && Object.values<string>(SortBy).includes(searchParams.sortBy as string)) {
    newQuery.sortBy = searchParams.sortBy as SortBy;
  }

  if (isInt(searchParams.upperLimit)) newQuery.upperLimit = parseInt(searchParams.upperLimit);
  if (isInt(searchParams.lowerLimit)) newQuery.lowerLimit = parseInt(searchParams.lowerLimit);

  return newQuery;
};

const isString = (a: any): a is string => a && typeof a === "string";
const isInt = (a: any): a is string => isString(a) && !isNaN(parseInt(a));

export const toUrl = (query: Partial<SearchQuery>) => {
  const url = new URL(RoutesOption.Home, window.location.href);
  const queryParams = toParsedUrl(query);
  for (const [key, value] of Object.entries(queryParams)) {
    url.searchParams.set(key, value as string);
  }
  return url;
};

function midnightUTC(date: Date) {
  // Convert the date from local timezone midnight to UTC midnight
  const midnightInUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return midnightInUTC.toISOString().substring(0, 10);
}

export type PageKey = string;

/**
 * A page key is a string representation of a search query, excluding things like
 * sorting, limits, etc.
 *
 * Ideaflow is a single page app, and every view is just a different search query.
 * But certain views feel like they're the same page, even if the query has changed.
 * For example, if you're in a folder and change the sorting, that changes the query
 * but you still feel like you're in the same page.
 *
 * The concept of a page is useful for storing state which a user expects to be
 * preserved e.g. scroll position, selected items, etc.
 */
export const searchQueryToPage = (state: SearchQuery): PageKey => {
  return searchQueryToText(state);
};

export const searchQueryToText = (query: SearchQuery): string => {
  const subqueries: string[] = [];
  if (query.hasComplete) subqueries.push("has:complete");
  if (query.hasIncomplete) subqueries.push("has:incomplete");
  if (query.hasTodo) subqueries.push("has:todo");
  if (query.hasRecording) subqueries.push("has:recording");
  if (query.hasImage) subqueries.push("has:image");
  if (query.isPublic) subqueries.push("is:public");
  if (query.date) subqueries.push(`date:${midnightUTC(query.date)}`);
  if (query.folderId) subqueries.push(`folderId:${query.folderId}`);
  if (query.isUnsynced) subqueries.push("is:unsynced");
  if (query.isUntagged) subqueries.push("is:untagged");
  if (query.keywordsList) {
    query.keywordsList.forEach((keyword: string) => {
      if (keyword.includes(" ")) {
        subqueries.push(`"${keyword}"`);
      } else subqueries.push(keyword);
    });
  }
  if (query.hashtagsList) {
    query.hashtagsList.forEach((hashtag: string) => {
      subqueries.push("tag:" + hashtag);
    });
  }
  if (query.noteIdList) {
    query.noteIdList.forEach((noteId: string) => {
      subqueries.push(`noteId:${noteId}`);
    });
  }
  if (query.jumpTo) {
    subqueries.push(`jumpTo:${query.jumpTo}`);
  }
  if (subqueries.length === 0) return "";
  return subqueries.join(" ");
};

export const queryFromSearchString = (str: string): SearchQuery => {
  const keywords: string[] = [];
  const noteIds: string[] = [];
  const hashtags: string[] = [];
  const result: SearchQuery = {};

  const subqueries = splitQuery(str);
  subqueries.forEach((subquery) => {
    if (subquery.match("[A-Za-z]+:.+")) {
      const filterKey = subquery.slice(0, subquery.indexOf(":"));
      const filterValue = subquery.slice(subquery.indexOf(":") + 1);
      let match;
      switch (filterKey.toLowerCase()) {
        case "is":
          switch (filterValue) {
            case "all":
              result.isAll = true;
              break;
            case "public":
              result.isPublic = true;
              break;
            case "unsynced":
              result.isUnsynced = true;
              break;
            case "untagged":
              result.isUntagged = true;
              break;
            default:
              keywords.push(subquery);
          }
          break;
        case "has":
          switch (filterValue.toLowerCase()) {
            case "complete":
              result.hasComplete = true;
              break;
            case "incomplete":
              result.hasIncomplete = true;
              break;
            case "todo":
              result.hasTodo = true;
              break;
            case "recording":
              result.hasRecording = true;
              break;
            case "image":
              result.hasImage = true;
              break;
            default:
              keywords.push(subquery);
          }
          break;
        case "jumpto":
          if (filterValue.length > 0) {
            result.jumpTo = filterValue;
          } else {
            keywords.push(subquery);
          }
          break;
        case "folderid":
          result.folderId = subquery.slice(subquery.indexOf(":") + 1);
          break;
        case "date":
          match = regexpMatchers.dateYmd.exec(filterValue);
          if (match) {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            date.setFullYear(parseInt(match[1]));
            date.setMonth(parseInt(match[2]) - 1);
            date.setDate(parseInt(match[3]));
            result.date = date;
          } else {
            keywords.push(subquery);
          }
          break;
        case "noteid":
          if (filterValue === "") {
            keywords.push(subquery);
          } else {
            noteIds.push(filterValue);
          }
          break;
        case "tag":
          if (filterValue === "") {
            keywords.push(subquery);
          } else if (filterValue.startsWith("#")) {
            hashtags.push(filterValue);
          } else {
            hashtags.push(`#${filterValue}`);
          }
          break;
        default:
          keywords.push(subquery);
      }
    } else if (subquery.length > 0) {
      keywords.push(subquery);
    }
  });
  if (keywords.length > 0) {
    result.keywordsList = keywords;
  }
  if (noteIds.length > 0) {
    result.noteIdList = noteIds;
  }
  if (hashtags.length > 0) {
    result.hashtagsList = hashtags;
  }

  result.isAll = isAll(result);

  return result;
};

export const splitQuery = (query: string): string[] => {
  const subqueries: string[] = tokenize(query).map((t: Token) => {
    return t.text;
  });
  return subqueries;
};

export function addDefaultsToSearchQuery(query: Partial<SearchQuery>): SearchQuery {
  query.isAll = isAll(query);
  // For each persisted search settings, if a value is given then update the persisted
  // value with it. Otherwise, set the state to the current value of the persisted value.
  query.isCondensed =
    query.isCondensed !== undefined
      ? setIsCondensed(query.isCondensed)
      : isCondensable(query)
        ? getIsCondensed()
        : undefined;
  query.isIncludingSubFolders =
    query.isIncludingSubFolders !== undefined
      ? setIsIncludingSubFolders(query.isIncludingSubFolders)
      : getIsIncludingSubFolders();
  query.sortBy = query.sortBy ? setSortBy(query.sortBy) : isSortable(query) ? getSortBy() : undefined;
  query.source = query.source ?? "other";
  return query;
}

export function searchQueryFromUrlOrSearchString(urlOrSearchString: string) {
  const parsedUrlQuery = parse(urlOrSearchString.split("?")[1]);
  const query = fromParsedUrl(parsedUrlQuery);
  return addDefaultsToSearchQuery(query);
}
