import { HelpCircle } from "react-feather";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { useShortcuts } from "../../shortcuts/useShortcuts";
import { getShortcutString } from "../../shortcuts/shortcuts";
import { ModalEnum, toggleModal } from "../../model/modals";
import { NavButton } from "./NavButton";

export const HelpButton = ({ size = 20 }) => {
  const { shortcuts } = useShortcuts();
  return (
    <NavButton
      id="nav-help-button"
      className="desktop-only"
      onClick={() => toggleModal(ModalEnum.HELP, { openCallback: () => trackEvent("open_help_from_header") })}
      role="tooltip"
      data-microtip-position="bottom-left"
      aria-label={`Toggle help menu ${getShortcutString(shortcuts.toggleHelp)}`}
    >
      <HelpCircle size={size} />
    </NavButton>
  );
};
