import type { transformPasted } from "./transforms";
import type { handlePaste } from "./handlers";

/**
 * Needs to support the following scenarios:
 * - pasting from ideaflow to ideaflow
 * - pasting nested html lists from google doc
 * - pasting nested html lists from contentEditable editors like Gutenberg: https://wordpress.org/gutenberg/
 * - importing from apple notes
 * See: https://linear.app/ideaflow/issue/ENT-1603
 */
export function embedDepthInformationInHTMLDocument(doc: Document) {
  // querySelectorAll returns an ordered list (https://www.w3.org/TR/selectors-api/#queryselectorall)
  const listsInOrder = Array.from(doc.querySelectorAll("ul,ol"));
  // Find the nested depth of each <ul> list
  for (let i = 0; i < listsInOrder.length; ++i) {
    const currentList = listsInOrder[i];
    const parentList = listsInOrder
      .slice(0, i)
      .reverse()
      .find((parentList) => parentList.contains(currentList));
    const depth = parentList ? parseInt(parentList.getAttribute("data-depth")!) + 1 : 0;
    currentList.setAttribute("data-depth", depth + "");
  }

  // Give each <li> the depth of its parent list
  for (const listItem of doc.querySelectorAll("li")) {
    let parentList: HTMLElement | null = listItem;
    while (parentList !== null && parentList.tagName !== "UL" && parentList.tagName !== "OL") {
      parentList = parentList.parentElement;
    }

    if (parentList) {
      const depth = parentList.getAttribute("data-depth")!;
      // Don't override already set data-depth attributes
      // This preserves depth when copying from ideaflow
      if (!listItem.hasAttribute("data-depth")) {
        listItem.setAttribute("data-depth", depth);
      }
    }
  }

  // Flatten the tree of list items, since our prosemirror schema doesn't
  // allow for nested lists within lists and instead uses the depth attribute
  for (const currentList of Array.from(listsInOrder)) {
    currentList.replaceWith(...currentList.childNodes);
  }
}

/**
 * Transforms pasted HTML text, before it is parsed, to clean it up
 * ProseMirror plugins that are called on paste:
 * - 1st: this function
 * - 2nd: {@link transformPasted}
 * - 3rd: {@link handlePaste}
 */
export function cleanUpPastedHTML(html: string): string {
  const domParser = new DOMParser();
  const document = domParser.parseFromString(html, "text/html");
  embedDepthInformationInHTMLDocument(document);

  const rootElements = document.body.children;
  // in VS Code, we get a wrapper div that messes up the parser, so we
  // remove it. see: https://linear.app/ideaflow/issue/ENT-431
  if (rootElements.length === 1 && rootElements[0].children.length > 1) {
    return rootElements[0].innerHTML;
  }

  const serializer = new XMLSerializer();
  return serializer.serializeToString(document);
}
