import { Volume2 } from "react-feather";
import { colors } from "../../../utils/style";
import { AudioMenu } from "./SimpleHamburgerMenu";
import type { AudioAttrs } from "./audioTypes";

export default function AudioInsertViewCollapsed({
  attrs,
  setAttrs,
  menuItems,
  parseWithAI,
}: {
  attrs: AudioAttrs;
  setAttrs: (attrOverride: Partial<AudioAttrs>, addToHistory?: boolean | undefined) => void;
  menuItems: {
    text: string;
    onClick: () => void;
  }[];
  parseWithAI: (() => void) | null;
}) {
  return (
    <div
      style={{
        display: "flex",
        color: colors.text.accent,
        cursor: "pointer",
        height: "20px",
      }}
    >
      <Volume2
        size={20}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAttrs({ isRevealed: true });
        }}
      ></Volume2>
      {parseWithAI && (
        <button className="parseWithAI-button" onClick={() => parseWithAI()}>
          ✨
        </button>
      )}
      <AudioMenu items={menuItems} alignLeft={!attrs.isRevealed}></AudioMenu>
    </div>
  );
}
