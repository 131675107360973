import styled from "@emotion/styled";

export const AutocompleteWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
  * {
    pointer-events: auto;
  }
`;
