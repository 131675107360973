import { maxAudioChunkSizeInBytes } from "../../../utils/environment";
import { ChunkMetadata } from "./audioTypes";
import { startMP3Recording } from "./mp3-recording";

export type MediaRecorderState = Exclude<RecordingState, "paused">;

export interface MediaRecorder {
  onStateChange: () => void;
  getState: () => MediaRecorderState;
  setState: (state: MediaRecorderState) => void;
  stop: () => Promise<void>;
}

export type CreateiOSMediaRecorderProps = {
  audioId: string;
  onStateChange: () => void;
  cleanup: () => void;
};

type CreateMediaRecorderProps = {
  audioId: string;
  onVolumeUpdate: (vol: number) => void;
  onStateChange: () => void;
  onStopFinished: () => void;
  onChunkReady: (chunk: ChunkMetadata) => void;
  cleanup: () => void;
};

export async function createMediaRecorder({
  audioId,
  onVolumeUpdate,
  onStateChange,
  onStopFinished,
  onChunkReady,
  cleanup,
}: CreateMediaRecorderProps): Promise<MediaRecorder> {
  const { ctx, stream, stop, stopAbruptly } = await startMP3Recording(
    audioId,
    maxAudioChunkSizeInBytes,
    () => {
      state = "inactive";
      onStateChange();
      analyzer.stop();
      onStopFinished();
    },
    onChunkReady,
  );
  let state: MediaRecorderState = "recording";

  const analyzer = createAudioStreamAnalyzer(ctx, stream, onVolumeUpdate);

  function interruptRecording() {
    stopAbruptly();
    onStateChange();
    cleanupListener();
    cleanup();
  }

  window.addEventListener("beforeunload", interruptRecording);
  const cleanupListener = () => window.removeEventListener("beforeunload", interruptRecording);

  return {
    getState() {
      return state;
    },
    async stop() {
      state = "inactive";
      analyzer.stop();
      onStateChange();
      await stop();
      onStopFinished();
      cleanupListener();
      cleanup();
    },
    setState(newState: MediaRecorderState) {
      state = newState;
      onStateChange();
    },
    onStateChange,
  };
}

function createAudioStreamAnalyzer(audioCtx: AudioContext, stream: MediaStream, onVolumeUpdate: (vol: number) => void) {
  const analyser = audioCtx.createAnalyser();
  analyser.fftSize = 128;
  analyser.smoothingTimeConstant = 0.0;

  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);
  analyser.getByteFrequencyData(dataArray);

  // Connect the source to be analysed
  const source = audioCtx.createMediaStreamSource(stream);
  source.connect(analyser);

  const interval = window.setInterval(() => {
    analyser.getByteFrequencyData(dataArray);
    const pseudoVolume = Array.from(dataArray)
      .map((a) => a / 256)
      .reduce((a, b) => a + b, 0);
    onVolumeUpdate(pseudoVolume);
  }, 200);

  return {
    interval,
    stop() {
      if (interval) window.clearInterval(interval);
    },
  };
}
