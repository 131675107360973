/* eslint-disable node/no-process-env */
import { mobileWidth } from "./style";

/** **Warning**: this flag can be true on iPads too (Safari and Chrome sometimes pretend to be running on a Mac) */
export const isMac =
  (typeof navigator !== "undefined" && !!navigator.platform.match("Mac")) || process.platform === "darwin"; // We also check process.platform since `navigator` is not available in TestCafe tests' backend environment

/** Is it an IOs web? Should be only used for safari mobile exclusive things. */
export const isIOs = typeof navigator !== "undefined" && /(iPhone|iPad|iPod).*WebKit/.test(navigator.userAgent);

export const isAndroid = typeof navigator !== "undefined" && /Android/i.test(navigator.userAgent);

export const isApple = isIOs || isMac;
// TODO: Figure out how to do this without `eval`, right now the problem is that
// webpack incorrectly assumes windows is always an object in all contexts on the web and so
// it simplifies the expression `typeof window !== 'undefined'` to `true` which is incorrect
// inside of the SharedWorker
// eslint-disable-next-line no-eval
export const isFrontend = eval("typeof window !== 'undefined'");

// eslint-disable-next-line no-eval
export const isWorker = eval("typeof WorkerGlobalScope !== 'undefined'");

export const isGreaterThanMobileWidth = isFrontend && window.innerWidth > mobileWidth;

export const isMobileWidth = !isGreaterThanMobileWidth;

export const isTouchDevice = isFrontend && "ontouchstart" in window;

export const isMobile = isTouchDevice && isMobileWidth;

export const isServerSide = !(process as any).browser;

export const isClientSide = !isServerSide;

export enum Env {
  prod = "production",
  dev = "development",
  preview = "preview",
  local = "local",
}
export const env: Env = Object.values(Env).find((v) => v === process.env.NEXT_PUBLIC_ENV) ?? Env.local;

export const isProd = env === Env.prod;
export const isLocal = env === Env.local;

export const maxAudioChunkSizeInBytes = process.env.NEXT_PUBLIC_MAX_AUDIO_CHUNK_SIZE_IN_BYTES
  ? parseInt(process.env.NEXT_PUBLIC_MAX_AUDIO_CHUNK_SIZE_IN_BYTES)
  : 4_000_000;

export const defaultDevEnableDebug = !isProd ? process.env.NEXT_PUBLIC_DEFAULT_DEV_DEBUG_ENABLE : null;

/**
 * Redirect uri for auth0.
 *
 * It's set to the current origin with a trailing slash. In the native app, the
 * web view origin is "thoughtstream-app://". But auth0 doesn't accept that as a
 * valid url without a trailing slash. So we need to add a slash here to make it
 * "thoughtstream-app:///".
 */
export const redirectUri = isFrontend ? window.location.origin + "/" : "";

/// FEATURE FLAGS

export const isCloudwatchLoggingEnabled = process.env.NEXT_PUBLIC_FLAG_CLOUDWATCH_LOGGING === "true";

export const isAuthEnabled = process.env.NEXT_PUBLIC_FLAG_AUTH === "true";

export const isPersistenceEnabled = process.env.NEXT_PUBLIC_FLAG_PERSISTENCE === "true";

export const isUploadEnabled = process.env.NEXT_PUBLIC_FLAG_UPLOAD === "true";

export const isContinuousIntegration = process.env.NEXT_PUBLIC_CONTINUOUS_INTEGRATION === "true";

export const isAnalyticsEnabled = process.env.NEXT_PUBLIC_FLAG_ANALYTICS === "true" && !isContinuousIntegration;

export const isRunningInIOSWebview = process.env.NEXT_PUBLIC_RUNNING_IN_IOS_WEBVIEW === "true";

export const isNativeRecordingEnabled = process.env.NEXT_PUBLIC_NATIVE_RECORDING === "true";

export const sqliteEnabled = process.env.NEXT_PUBLIC_FLAG_SQLITE === "true";

/** Whether to enable the install banner. Defaults to true, but can be disabled. */
export const isInstallBannerEnabled = process.env.NEXT_PUBLIC_FLAG_INSTALL_BANNER !== "false";

/** Whether to enable the tutorial. Defaults to true, but can be disabled. */
export const isTutorialEnabled = process.env.NEXT_PUBLIC_FLAG_TUTORIAL !== "false";

export const isUnsyncedDecoratorEnabled = process.env.NEXT_PUBLIC_FLAG_UNSYNCED_DECORATOR === "true";

/**
 * Video URL for onboarding (must be a YouTube embed link)
 */
export const tutorialVideoURL =
  process.env.NEXT_PUBLIC_TUTORIAL_VIDEO_URL ?? "https://www.youtube.com/embed/ED-GXYSr_Bw?si=kCTlRy2cYn8rHVU5";

/**
 * Whether to enable Simon search.
 */
export const isSimonSearchEnabled = process.env.NEXT_PUBLIC_FLAG_SIMON_SEARCH === "true";

/**
 * What users should see Simon talk-to-your-notes.
 */
export const simonQueryUserIds = process.env.NEXT_PUBLIC_FLAG_SIMON_QUERY_IDS?.split(",") ?? [];

/**
 * Whether to always show the mobile keyboard bar. For testing purposes.
 * Defaults to false, but can be enabled.
 */
export const alwaysShowMobileKeyboardBar = process.env.NEXT_PUBLIC_FLAG_ALWAYS_SHOW_MOBILE_KEYBOARD_BAR === "true";

/**
 * Whether to enable service worker caching. Defaults to true, but can be disabled.
 */
export const swCachingEnabled = process.env.NEXT_PUBLIC_FLAG_SW_CACHING !== "false";

if (isContinuousIntegration && (isPersistenceEnabled || isAuthEnabled)) {
  throw new Error("Invalid environment configuration: disable persistence and auth in CI env");
}

export const hasServiceWorkerSupport = typeof navigator !== "undefined" && "serviceWorker" in navigator;

export const isTransactionLogEnabled = process.env.NEXT_PUBLIC_LOG_EDITOR_TRANSACTION === "true";

export const urlUpload = process.env.NEXT_PUBLIC_REST_API_URL + "/files";

/** When auth is disabled, this is the user that will be logged in*/
export const authMockSub = process.env.NEXT_PUBLIC_AUTH_MOCK_SUB || "ideaflow|123456789";

// ts-prune-ignore-next
export const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST || "";
export const posthogApiKey = process.env.NEXT_PUBLIC_POSTHOG_API_KEY || "";
export const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || "";
export const auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || "";
export const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const publicVersion = process.env.NEXT_PUBLIC_VERSION;
export const restApiUrl = process.env.NEXT_PUBLIC_REST_API_URL;
/** Local data model version. Format: mmddyyy */
export const modelVersion = "08212023";

export const lastestCommit = process.env.NEXT_PUBLIC_LATEST_COMMIT;

export const sentryRelease = process.env.NEXT_PUBLIC_LATEST_COMMIT || "local";
