import { getDefaultStore } from "jotai";
import { NoteUpdate } from "../model/store/NoteStore";
import { userSettingsAtom } from "../model/atoms";
import { Note, NoteId } from "../../shared/types";
import { ApiClient } from "../api/client";
import { isSimonSearchEnabled } from "./environment";
import appLogger from "./logger";

const logger = appLogger.with({ namespace: "simon" });

type SimonUpdate = Pick<NoteUpdate, "id" | "simonHash" | "simonUpdatedAt">;

/** Delay between syncs to Simon */
const SIMON_SYNC_DELAY = 1000;
/** Minimum delay between much rarer syncs to the main Ideaflow store */
const IDEAFLOW_SYNC_DELAY = SIMON_SYNC_DELAY * 10;

// TODO : figure this out!
// This might prevent some changes to the notes from being synced to Simon
// But otherwise, we have updates for a huge amount of notes on each page load
const getDate10SecFromNow = (): Date => {
  const date = new Date();
  date.setSeconds(date.getSeconds() + 10);
  return date;
};

export class SimonSyncProvider {
  #getNote: (noteId: NoteId) => Note | undefined;
  #noteToString: (note: Note) => string;
  #updateSimonPropsInNotes: (updates: SimonUpdate[]) => void;

  // Map of notes that need to be synced to Simon
  #queue: Set<NoteId>;
  #simonTimer: NodeJS.Timeout | null;

  // Map of updates that need to be synced to the main Ideaflow store
  #updatesQueue: Map<NoteId, SimonUpdate>;
  #ideaflowTimer: NodeJS.Timeout | null;

  constructor(
    getNote: (noteId: NoteId) => Note | undefined,
    noteToString: (note: Note) => string,
    updateSimonPropsInNotes: (updates: SimonUpdate[]) => void,
  ) {
    this.#getNote = getNote;
    this.#noteToString = noteToString;
    this.#updateSimonPropsInNotes = updateSimonPropsInNotes;
    this.#queue = new Set();
    this.#simonTimer = null;
    this.#updatesQueue = new Map();
    this.#ideaflowTimer = null;
  }

  isEnabled() {
    return isSimonSearchEnabled && getDefaultStore().get(userSettingsAtom).simonSearchEnabled;
  }

  syncMany(notes: Note[]) {
    if (this.isEnabled()) {
      for (const note of notes) this.#sync(note);
    }
  }

  /** Adds a note to the queue to be synced to Simon, then runs sync */
  #sync(note: Note) {
    this.#queue.add(note.id);
    if (this.#simonTimer) return;
    this.#simonTimer = setTimeout(() => {
      this.#syncToSimon();
      this.#simonTimer = null;
    }, SIMON_SYNC_DELAY);
  }

  /** Syncs all notes in the queue to Simon and prepares updates for the main Ideaflow store */
  async #syncToSimon() {
    if (!this.isEnabled()) return;

    const apiClient = ApiClient();
    const noteIds = Array.from(this.#queue.values());
    this.#queue.clear();

    logger.info("Syncing notes to Simon", { context: { noteIds } });

    // This happens sequentially on purpose, to avoid overloading the server with too many requests at once
    // Order matters (note.deletedAt is more specific than note.updatedAt)
    for (const noteId of noteIds) {
      try {
        const note = this.#getNote(noteId);
        if (!note) {
          continue;
        } else if (!note.simonHash && !note.deletedAt) {
          // Add note to Simon
          logger.info("Adding note to Simon", { context: { noteId } });
          const hash = await apiClient.simon.addNote(note.id, this.#noteToString(note));
          const update: SimonUpdate = { id: note.id, simonHash: hash, simonUpdatedAt: getDate10SecFromNow() };
          this.#updatesQueue.set(noteId, update);
          logger.info("Added note to updates queue", { context: { noteId } });
        } else if (note.simonHash && note.deletedAt) {
          // Delete the note from Simon
          await apiClient.simon.deleteNote(note.simonHash);
          const update: SimonUpdate = { id: note.id, simonHash: null, simonUpdatedAt: null };
          this.#updatesQueue.set(noteId, update);
          logger.info("Added note to updates queue", { context: { noteId } });
        } else if (note.simonHash && note.simonUpdatedAt && note.updatedAt > note.simonUpdatedAt) {
          // Update the existing note in Simon
          const hash = await apiClient.simon.updateNote(note.id, this.#noteToString(note), note.simonHash);
          const update: SimonUpdate = { id: note.id, simonHash: hash, simonUpdatedAt: getDate10SecFromNow() };
          this.#updatesQueue.set(noteId, update);
          logger.info("Added note to updates queue", { context: { noteId } });
        }
      } catch (e) {
        logger.error("Failed to sync note to Simon", { error: e });
      } finally {
        // Attempt to initiate the main Ideaflow store sync after each note
        this.#runIdeaflowSync();
      }
    }
  }

  /** Runs the main Ideaflow store sync after a delay */
  #runIdeaflowSync() {
    if (this.#ideaflowTimer) {
      // Postpone the delay until the entire Simon queue is exhausted
      clearTimeout(this.#ideaflowTimer);
      this.#ideaflowTimer = null;
    }

    this.#ideaflowTimer = setTimeout(() => {
      this.#ideaflowTimer = null;
      const updates = Array.from(this.#updatesQueue.values());
      logger.info("Updating notes with Simon props", { context: { updateNoteIds: updates.map((u) => u.id) } });
      this.#updateSimonPropsInNotes(updates);
      this.#updatesQueue = new Map();
    }, IDEAFLOW_SYNC_DELAY);
  }
}
