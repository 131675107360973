import { Command } from "prosemirror-state";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { schema } from "../../schema";
import { listCreateOrWrap } from "./createNestedListPlugin";

export const listifyCommand: Command = (state, dispatch) => {
  if (!dispatch) return false;
  // Selection must span different blocks within the same note. This will be
  // true when their first common ancestor is a note.
  const { $from, $to } = state.selection;
  const commonAncestor = $from.node($from.sharedDepth($to.pos));
  if (commonAncestor.type !== schema.nodes.note) return false;
  // Check if the nodes inside the selection are supported by listCreateOrWrap.
  let supportedSelection = true;
  commonAncestor.descendants((node, pos) => {
    if (!supportedSelection) return;
    if (pos < $from.pos || pos > $to.pos) return;
    // The entire selection must be within the same note. So it shouldn't start in
    // one note and end in another note, or start in a note and end in an expansion,
    // or start/end in the same note but span an expanded reference. We could support
    // this in the future, but at the moment listCreateOrWrap doesn't handle expanded
    // references well, so we just ignore it.
    const isNote = node.type === schema.nodes.note;
    // The listCreateOrWrap function doesn't handle expanded references well. It
    // listifies the reference but not the associated expansion, putting the note
    // in an invalid state. For now, we just don't support this case.
    const isReferenceExpanded = node.type === schema.nodes.reference && node.attrs.isExpanded;
    if (isNote || isReferenceExpanded) {
      supportedSelection = false;
    }
  });
  if (!supportedSelection) return false;
  // Create listify transaction
  const tr = listCreateOrWrap(state, false);
  if (!tr) return false;
  dispatch(tr);
  trackEvent("create_list");
  return true;
};
