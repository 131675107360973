// if you edit the regexp to support a new case, please add a new test(s)
// in ./__tests__/regexpMatchers.test.ts

const referenceMatchText = `\\S[^+]*`;
const angleBracketsReferenceMatchText = `\\S.*`;
const mandatoryHashtagChar = "[\\p{Alpha}_—\\x2d+*@~]";
const validHashtagChar = `(?:${mandatoryHashtagChar}|[0-9])`;

const commonTlds = [
  "com",
  "org",
  "gov",
  "edu",
  "net",
  "info",
  "ca",
  "uk",
  "de",
  "ru",
  "ir",
  "me",
  "io",
  "app",
  "biz",
  "xyz",
  "asia",
].join("|");

export const regexpMatchers = {
  plusReference: new RegExp(`(^|\\s|[^\\p{Alpha}\\w])(\\+)(${referenceMatchText}) ?$`, "u"),
  angleBrackets: new RegExp(`(<(?:relate[sd] to)?>\\s?)(${angleBracketsReferenceMatchText})?$`, "iu"),
  /**
   * Hashtag matcher.
   *
   * Matches a hashtag followed by a mix of alphanumeric characters and some
   * special characters. Although the hashtag can include digits, it can't
   * *only* be digits. This is to avoid matching things like "#1" as a hashtag.
   */
  hashtag: new RegExp(
    `(^|\\s|[^\\p{Alpha}\\w])(#)(${validHashtagChar}*${mandatoryHashtagChar}${validHashtagChar}*)`,
    "u",
  ),
  // High confidence links start with the http:// or https:// schemas. We allow any TLD for the high confidence links
  // ([a-z0-9\p{Emoji}-]+\.)+ matches subdomains and domain
  // ([a-z0-9]{2,24}) matches TLD
  // (:[\p{N}]+) optional port number
  // ([?/](([^\s])*([^.\s,])+)?)? optional path matching after tld/port.
  //    - [?/] Must start with a slash or question mark
  //    - (([^\s])*([^.\s,])+)? - Anything after slash is optional, but if not
  //                              match all non whitespace characters, do not end
  //                              with period or dot or whitespace
  highConfidenceLink:
    /https?:\/\/((([a-z0-9\p{Emoji}-]+\.)+([a-z0-9-]{2,24}))|localhost)(:[\p{N}]+)?([?/](([^\s])*([^.\s,])+)?)?/giu,
  // Low confidence links (without the schema) are likely typed by the user directly. We only allow the few most popular TLDs in that case.
  // Otherwise similar to highConfidenceLink.
  lowConfidenceLink: new RegExp(
    `(^|\\s)((([a-z0-9\\p{Emoji}-]+\\.)+(${commonTlds}))|localhost)(:[\\p{N}]+)?([?/](([^\\s])*([^.\\s,])+)?)?`,
    "giu",
  ),

  // Y-m-d (YYYY-MM-DD) dates e.g, 2001-09-11
  dateYmd: /^(\d{4})-(\d{2})-(\d{2})$/,
};

// Don't start a smart autocomplete if a prefix starter character is found
const validFirstAutoRefChar = `[^#+,\\s]`;
/**
 * This is a set of experimental matchers for the "smart autocomplete" feature that's
 * behind a feature flag.
 *
 * These matchers don't rely on a particular trigger character, and instead have special
 * context-sensitive logic to determine whether a match should be attempted.
 */
export const experimentalMatchers = {
  // any length key followed by a colon, a space, and a valid first reference search character
  bulletReferenceStart: new RegExp(`^([^:]*:\\s)(${validFirstAutoRefChar}?)`, "u"),
  // space/comma/tab follwed by a character that doesn't initiate some other reference
  bulletReferenceContinuation: new RegExp(`^([\\s,]*)${validFirstAutoRefChar}`, "u"),
  relatesToReference: new RegExp(`(relate[sd] to\\s?)(${referenceMatchText})$`, "iu"),
  conversationWithReferences: /#conversation with /iu,
  referenceDelimiter: /^\s?,?\s?(and)?\s?$/iu,
  notReferencePrefix: new RegExp(`${validFirstAutoRefChar}$`, "u"),
};
