import React from "react";
import { createRoot, Root } from "react-dom/client";

import { Node, Fragment } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { generateId } from "../../model/generateId";
import { schema } from "../schema";
import logger from "../../utils/logger";

export type NodeViewProps<Attrs> = {
  editorView: EditorView;
  getPos: () => number;
  attrs: Attrs;
  setAttrs: (attrOverride: Partial<Attrs>, addToHistory?: boolean) => void;
  replaceSelfWith: (nodes: Node[] | Fragment) => void;
};

export function createNodeViewFromReactComponent<Attrs>(
  // Whenever this function returns two different values for two nodes, the component will not be updated from one node to another
  getId: (node: Node) => string,
  toDOM: (node: Node) => { dom: HTMLDivElement | HTMLSpanElement; reactRoot?: HTMLDivElement | HTMLSpanElement },
  Component: React.FunctionComponent<NodeViewProps<Attrs>>,
  updateDOM?: (node: Node, dom: HTMLElement) => void,
) {
  return class ComponentNodeView implements NodeView {
    dom: HTMLElement;
    private reactRoot: Root;

    constructor(
      private node: Node,
      private view: EditorView,
      private getPos: () => number,
      isReadOnly: boolean,
    ) {
      const { dom, reactRoot } = toDOM(node);
      this.dom = dom;
      this.reactRoot = createRoot(reactRoot ?? dom);

      this.syncUI();
    }

    private syncUI() {
      this.reactRoot.render(
        <Component
          editorView={this.view}
          getPos={this.getPos}
          attrs={this.node.attrs as any}
          setAttrs={this.updateAttr}
          replaceSelfWith={this.replaceSelfWith}
        />,
      );
    }

    destroy() {
      // This has to be delayed otherwise React complains
      setTimeout(() => {
        // This allows us to run all the React cleanup functions from effects
        this.reactRoot.unmount();
      }, 0);
    }

    update(node: Node) {
      if (getId(this.node) !== getId(node)) {
        return false;
      }
      this.node = node;
      updateDOM?.(node, this.dom);

      this.syncUI();
      return true;
    }

    private updateAttr = (attrOverride: Partial<Attrs>, addToHistory: boolean = true) => {
      const tr = this.view.state.tr.setNodeMarkup(this.getPos(), undefined, {
        ...this.node.attrs,
        ...attrOverride,
      });
      if (!addToHistory) {
        tr.setMeta("addToHistory", false);
      }
      this.view.dispatch(tr);
    };

    private replaceSelfWith = (nodes: Node[] | Fragment) => {
      const pos = this.getPos();
      if (!pos) return;
      const transaction = this.view.state.tr.replaceWith(pos, pos + 1, nodes);
      this.view.dispatch(transaction);
    };
  };
}

/**
 * Map of operationId to operation promise (or true if the operation has already completed).
 */
const asyncNodeOperationRegistry = new Map<string, Promise<Node[] | Fragment> | true>();

/**
 * Registers an async operation tied to a specific node.
 * @returns operationId that can be used to retrieve the operation result
 */
export function registerAsyncNodeOperation(operation: (operationId: string) => Promise<Node[] | Fragment>) {
  const operationId = generateId();
  asyncNodeOperationRegistry.set(operationId, operation(operationId));
  return operationId;
}

export const OPERATION_TIMEOUT = 30_000;

/**
 * AsyncReplacedView is the Prosemirror node view for the "asyncReplacedElement" node
 * Every "asyncReplacedElement" node wraps some text extracted from the note content for the purpose
 * of processing by some async operation (currently only AI entity extraction).
 *
 * The job of AsyncReplacedView is to eventually replace itself with the result of that operation.
 * If the operation fails or times-out the "asyncReplacedElement" node is replaced with the original text
 */
export const AsyncReplacedView = createNodeViewFromReactComponent<{
  operationId: string;
  operationStartTimestamp: number;
  fallbackText: string;
  fallbackNodes: Node[] | Fragment;
}>(
  (node) => node.attrs.operationId,
  () => ({ dom: document.createElement("span") }),
  ({ attrs, replaceSelfWith }) => {
    React.useEffect(() => {
      const pendingOperation = asyncNodeOperationRegistry.get(attrs.operationId);
      const abandonTheAsyncOperation = () => {
        replaceSelfWith(
          attrs.fallbackNodes ? attrs.fallbackNodes : attrs.fallbackText ? [schema.text(attrs.fallbackText)] : [],
        );
      };
      // If the operation is not available abandon this decoration
      // This happens on other tabs or after page refresh
      // so we only truly revert the asyncReplacedNode back to its original content
      // after an OPERATION_TIMEOUT ms timeout
      if (!pendingOperation) {
        const timer = setTimeout(() => {
          logger.error("A timed-out operation detected, abandoning!", false);
          abandonTheAsyncOperation();
        }, OPERATION_TIMEOUT);
        return () => clearTimeout(timer);
      }
      if (pendingOperation === true) {
        abandonTheAsyncOperation();
        return;
      }
      (async () => {
        try {
          const result = await pendingOperation;
          replaceSelfWith(result);
          asyncNodeOperationRegistry.set(attrs.operationId, true);
        } catch (e) {
          const err = new Error("Async node operation failed", { cause: e });
          logger.error(err, {
            context: { operationId: attrs.operationId },
          });
          abandonTheAsyncOperation();
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attrs.operationId]);

    return (
      <span
        style={{
          padding: 4,
          margin: -4,
          borderRadius: 5,
          background: "lightblue",
        }}
      >
        Analyzing: {attrs.fallbackText.split(" ").slice(0, 5).join(" ").slice(0, 100)}... Please wait
      </span>
    );
  },
);
