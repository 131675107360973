import path from "path";
import { Plugin, PluginKey, Transaction } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { expansionSet, noteBlockMatches, appNoteStore } from "../../../../model/services";
import { schema } from "../../../schema";
import { getNotesThatChangedInTree } from "../../../utils/getNotesThatChangedInTree";
import { expandOrCollapseReference } from "../referenceExpansionUtils";
import { subTransaction } from "../../../utils/subTransaction";
import { assert } from "../../../../utils/assert";
import { areBacklinksExpanded, noteToBacklinkProsemirrorNode, toggleSection } from "../../backlink/backlinkPlugin";
import { getPathToBacklinkSection, getPathToReference } from "../../../utils/path";
import getNode from "../../../utils/getNode";
import { noteToProsemirrorNode } from "../../../bridge";
import { getParentNote } from "../../../utils/find";
import { descendNotes } from "../../../utils/descendNotes";
import logger from "../../../../utils/logger";

/**
 *
 * @param tr Transaction to update.
 * @param pos Position of the note node to update.
 * @param restoreExpanded If and how to restore the expansions after updating. Options:
 *       - true: restore the expansion state of the note to what it was before the update
 *       - false: don't restore expansion state
 *       - Set: A set of paths representing the nodes that should be expanded after the update. All other nodes will be collapsed.
 * @returns
 */
function updateNote(tr: Transaction, pos: number, updateContent = true) {
  let noteNode = getNode(tr.doc, pos, schema.nodes.note);
  const { noteId, path: notePath } = noteNode.attrs;

  // Update note content
  if (updateContent) {
    const note = appNoteStore.get(noteId)!;
    assert(!!note, "note must exist in noteStore to update it's node");
    const isBacklink = tr.doc.resolve(pos).parent.attrs.isBacklink;
    const matches = noteBlockMatches.get(notePath) || [];
    let newNote: Node;
    if (isBacklink) {
      const [parentNoteNode] = getParentNote(tr.doc, pos)!;
      if (!parentNoteNode) throw new Error("note must have a parent node");
      newNote = noteToBacklinkProsemirrorNode(note, parentNoteNode, matches);
    } else {
      newNote = noteToProsemirrorNode(note, {
        path: path.dirname(notePath),
        matches,
        condensingEnabled: noteNode.attrs.condensingEnabled,
        isCondensed: noteNode.attrs.isCondensed,
      });
    }
    const anchorBefore = tr.selection.anchor;
    tr.replaceWith(pos + 1, pos + noteNode.nodeSize - 1, newNote.content);
    if (tr.selection.anchor !== anchorBefore) {
      logger.error("ANCHOR MOVE " + anchorBefore + " --> " + tr.selection.anchor, {
        context: { noteId, notePath, replacedRange: [pos + 1, pos + noteNode.nodeSize - 1] },
        report: true,
      });
    }
    noteNode = getNode(tr.doc, pos, schema.nodes.note);
  }

  // Restore backlink section expansion state
  const expandedBefore = expansionSet.has(getPathToBacklinkSection(notePath));
  const expandedNow = areBacklinksExpanded(noteNode);
  if (expandedBefore !== expandedNow) {
    toggleSection(tr, pos);
    noteNode = getNode(tr.doc, pos, schema.nodes.note);
  }

  // Restore reference expansion state
  subTransaction(tr, (tr) => {
    noteNode.descendants((node, offset) => {
      if (node.type === schema.nodes.reference) {
        const expPath = getPathToReference(notePath, node.attrs.tokenId);
        const expandedBefore = expansionSet.has(expPath);
        const expandedNow = node.attrs.isExpanded;
        if (expandedBefore !== expandedNow) {
          const posNode = pos + tr.mapping.map(offset + 1);
          expandOrCollapseReference(tr, tr.doc.resolve(posNode), node.attrs.noteId);
        }
      }
      if (node.type === schema.nodes.expandedReference) {
        // We only want to toggle references inside the note right now, not inside
        // expanded references. We'll do that later. So return false to prevent
        // descending into them
        return false;
      }
    });
  });
  noteNode = getNode(tr.doc, pos, schema.nodes.note);

  // Restore expansion states inside subnotes
  subTransaction(tr, (tr) => {
    noteNode.descendants((node, offset) => {
      const posNode = pos + offset + 1;
      if (node.type === schema.nodes.expandedReference) {
        const posSubNote = tr.mapping.map(posNode + 1);
        updateNote(tr, posSubNote, false);
        return false;
      }
    });
  });
  return tr;
}

function updateNotes(tr: Transaction, upsertNoteNodes: Node[]) {
  const upsertNoteIds = upsertNoteNodes.map((node) => node.attrs.noteId);
  subTransaction(tr, (tr) => {
    descendNotes(tr.doc, (oldNode, oldPos) => {
      const pos = tr.mapping.map(oldPos);
      const note = getNode(tr.doc, pos, schema.nodes.note);
      // Only update notes with the same id, but different node identity (aka not the node the change originated from)
      if (upsertNoteIds.includes(note.attrs.noteId) && !upsertNoteNodes.includes(note)) {
        updateNote(tr, pos, true);
        return false;
      }
    });
  });
}

function updateReferences(tr: Transaction, deleted: Set<string>) {
  return subTransaction(tr, (tr) => {
    tr.doc.descendants((node, pos) => {
      if (node.type === schema.nodes.reference) {
        // Delete expansions whose linked note has been deleted
        if (node.attrs.isExpanded && deleted.has(node.attrs.linkedNoteId)) {
          expandOrCollapseReference(tr, tr.doc.resolve(tr.mapping.map(pos)));
        }
        // The above may have modified the node attrs, so we get the latest here
        const attrs = tr.doc.nodeAt(tr.mapping.map(pos))?.attrs;
        if (!attrs) return false;
        // Update reference content
        const newContent = appNoteStore.getNoteEllipsis(attrs.linkedNoteId);
        if (newContent !== attrs.content) {
          tr.setNodeMarkup(tr.mapping.map(pos), undefined, {
            ...attrs,
            content: newContent,
          });
        }
        return false;
      }
    });
  });
}

export const expansionEditMirrorPlugin = new Plugin({
  key: new PluginKey("expansionEditMirror"),
  appendTransaction(trs, oldState, newState) {
    if (trs.every((tr) => tr.getMeta("noChangeToModel") || !tr.docChanged)) {
      return;
    }

    // Get latest node for all notes, expansion states, and notes that changes
    const { upserts, deletes } = getNotesThatChangedInTree(oldState.doc, newState.doc);

    const tr: Transaction = newState.tr;
    tr.setMeta("type", "expansionEditMirror");
    tr.setMeta("addToHistory", false);
    tr.setMeta("noChangeToModel", true);

    // Re-render all notes that have changed
    updateNotes(tr, upserts);

    // Update references and remove expansion of deleted notes
    updateReferences(tr, new Set(deletes));
    return tr.docChanged ? tr : null;
  },
});
