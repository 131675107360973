import { Plugin, PluginKey } from "prosemirror-state";
import { generateId } from "../../model/generateId";
import { schema } from "../schema";
import { descendNotes } from "./descendNotes";

/**
 * After a node is split (e.g. press enter in a paragraph), the new node is assigned
 * the same token id as the old node. This plugin fixes that by generating a new token id
 * for the new node.
 *
 * Token ids are only unique within a note, not across the whole doc. You can have multiples
 * instances of the same note in the doc.
 */
export const fixTokenIdsPlugin = new Plugin({
  key: new PluginKey("fixUniqueIds"),
  appendTransaction(trs, oldState, newState) {
    if (trs.every((tr) => tr.getMeta("noChangeToModel") || !tr.docChanged)) {
      return;
    }
    let tr = newState.tr;
    descendNotes(tr.doc, (note, posNote) => {
      const tokenIds = new Set();
      note.descendants((node, offset) => {
        if (node.type === schema.nodes.note) {
          // We only want to enforce token uniqueness within a note, not it's
          // sub notes, so don't descend. The outer loop will handle the sub notes.
          return false;
        }
        let { tokenId } = node.attrs;
        if (tokenId === undefined) return;
        if (tokenId === "" || tokenIds.has(tokenId)) {
          tokenId = generateId();
          tr = tr.setNodeMarkup(posNote + 1 + offset, node.type, {
            ...node.attrs,
            tokenId,
          });
        }
        tokenIds.add(tokenId);
      });
    });
    return tr.docChanged ? tr : null;
  },
});
