import path from "path";

type NodeId = string;

/**
 * A unique identifier for a node in the document.
 *
 * Examples:
 * - "/note1"                        -> note1 at the root
 * - "/note1/ref1/note2"             -> note2 inside expanded ref1 in note1
 * - "/note1/ref1/note2/ref2/note3"  -> note3 inside expanded ref2 ...
 * - "/note1/backlinks1/note2"       -> note2 inside backlinks section of note1
 *
 * Previously note ids were used for the directory portion of the path, but that
 * can result in duplicate note ids. For example, if you have a note with 2 references
 * to the same note like this:
 *
 * ```
 * note1
 * - expanded ref1
 *   - note2
 * - expanded ref2
 *   - note2
 * ```
 *
 * Both note2 nodes would have the "/note1/note2" id.
 *
 * Reference ids are unique within a note, so including them avoids this problem.
 */
export type Path = string;

export const generatePathFromNoteId = (noteId: string) => `/${noteId}`;

export const getPathToBacklinkSection = (notePath: Path): Path => {
  return path.join(notePath, "backlinks");
};

export const getPathToReference = (notePath: Path, tokenId: string): Path => {
  return path.join(notePath, tokenId);
};

export const getPathToBacklinkNote = (containerNotePath: Path, linkedNoteId: string): Path => {
  return path.join(getPathToBacklinkSection(containerNotePath), linkedNoteId);
};

/**
 * @param containerNotePath path to the note containing the reference
 * @param referenceId id of the reference node (Note: *Not* the expanded reference node.
 *  This is because reference tokens are persisted so their ids are stable, while expansion
 *  tokens aren't persisted so their ids are ephemeral.)
 * @param linkedNoteId id of the note being referenced
 * @returns
 */
export const getPathToReferencedNote = (containerNotePath: Path, referenceId: string, linkedNoteId: string): Path => {
  return path.join(getPathToReference(containerNotePath, referenceId), linkedNoteId);
};

/**
 * Get note depth from it's path.
 *
 * Examples:
 * - "/note0"                       -> depth 0
 * - "/note0/ref1/note1"            -> depth 1
 * - "/note0/backlinks/note1"       -> depth 1
 */
export const getDepthFromPath = (path: Path): number => {
  return (path.split("/").length - 2) / 2;
};

export const getNoteIdFromTokenId = (path: Path): NodeId => {
  return path.split("/").pop()!;
};
