import { Node as PMNode, ResolvedPos } from "prosemirror-model";
import { schema } from "../../schema";
import { generateId } from "../../../model/generateId";
import { NoteId } from "../../../../shared/types";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { appNoteStore } from "../../../model/services";
import logger from "../../../utils/logger";
import { experimentalMatchers, regexpMatchers } from "../../utils/mark/regexpMatchers";
import Suggestion from "../../../autocomplete/Suggestion";
import { getReferenceSuggestions, getHashtagSuggestions } from "../../../autocomplete/getSuggestions";
import { FullMatch } from "./autocompletePlugin";

export const referenceAutocompleteTypes = [
  "plusReference",
  "bulletReference",
  "relatesToReference",
  "angleBrackets",
  "conversationWithReferences",
] as const;
const hashtagAutocompleteTypes = ["hashtag"] as const;

export type AutocompleteTypes = (typeof referenceAutocompleteTypes)[number] | (typeof hashtagAutocompleteTypes)[number];

export interface AutocompleteModule<T = AutocompleteTypes> {
  getSuggestions: (text: string, noteId: NoteId) => Suggestion[];
  createNode: (attrs: { id: string; content: string }) => PMNode;
  getMatch: ($caretPos: ResolvedPos) => { from: number; to: number; text: string } | null;
  matcherName: T;
  /** "Smart" autocompletes are less explicit in how they are triggered, and can be disabled in the settings*/
  isSmart: boolean;
}

// match modules
export const autocompleteModules: {
  [x in AutocompleteTypes]: AutocompleteModule<x>;
} = {
  plusReference: {
    matcherName: "plusReference",
    createNode: (attrs) => createReferenceNode(attrs),
    getSuggestions: (text, noteId) => getReferenceSuggestions(text, noteId),
    getMatch: ($caretPos) => getMatch($caretPos, regexpMatchers.plusReference, "plusReference"),
    isSmart: false,
  },
  bulletReference: {
    matcherName: "bulletReference",
    createNode: (attrs) => createReferenceNode(attrs),
    getSuggestions: (text, noteId) => getReferenceSuggestions(text, noteId),
    getMatch: ($pos) => {
      // Must be inside a list item
      const grandparent = $pos.node($pos.depth - 1);
      if (grandparent.type !== schema.nodes.listItem) return null;

      // Get text from the start of the line to the end of the word after the cursor
      const lineStart = $pos.before($pos.depth) + 1;
      const wordEnd = findSurroundingText($pos)?.textAfter.split(" ")[0] ?? "";
      const posWordEnd = $pos.pos + wordEnd.length;

      // Check if it matches bullet reference prefix pattern
      const fragment = $pos.doc.slice(lineStart, posWordEnd).content;
      const line = fragment.textBetween(0, fragment.size);
      const prefixMatch = line.match(experimentalMatchers.bulletReferenceStart);
      if (!prefixMatch) return null;
      const prefix = prefixMatch[1];
      const validFirstChar = prefixMatch[2];

      // Handle first autocomplete of a bullet reference, which we can detect by
      // checking if the entire line is text
      let isAllText = true;
      fragment.forEach((c) => (isAllText = isAllText && c.isText));
      if (validFirstChar && isAllText) {
        const to = posWordEnd;
        const from = lineStart + prefix.length;
        const text = $pos.doc.textBetween(from, to);
        return { to, from, text };
      }

      // For subsequent references, we autocomplete if directly behind the cursor position
      // is a space/comma and a reference behind that.
      let trailingText = "";
      let lastNonTextChild: PMNode | null = null;
      for (let i = fragment.childCount - 1; i >= 0; i--) {
        const child = fragment.child(i);
        if (child.isText) {
          trailingText = child.text + trailingText;
        } else {
          lastNonTextChild = child;
          break;
        }
      }
      const delimiter = trailingText.match(experimentalMatchers.bulletReferenceContinuation)?.[1] ?? null;
      if (delimiter !== null && lastNonTextChild?.type === schema.nodes.reference) {
        const to = $pos.pos;
        const from = to - trailingText.length + delimiter.length;
        return { from, to, text: trailingText.slice(delimiter.length) };
      }

      return null;
    },
    isSmart: true,
  },

  relatesToReference: {
    matcherName: "relatesToReference",
    createNode: (attrs) => createReferenceNode(attrs),
    getSuggestions: (text, noteId) => getReferenceSuggestions(text, noteId),
    getMatch: ($pos) => {
      // Get the text from the last reference in the line (or the beginning of
      // the line) to the caret position
      let start = $pos.before($pos.depth) + 1;
      $pos.doc.nodesBetween(start, $pos.pos, (node, pos) => {
        if (node.type === schema.nodes.reference) {
          start = pos;
        }
        return true;
      });
      const fragment = $pos.doc.slice(start, $pos.pos).content;
      const textBefore = fragment.textBetween(0, fragment.size);
      const match = textBefore.match(experimentalMatchers.relatesToReference);
      if (!match) return null;
      const text = match[2];
      return { to: $pos.pos, from: $pos.pos - text.length, text };
    },
    isSmart: true,
  },
  /**
   * Trigger reference creation after "#conversation with"
   *
   * After a reference has been inserted, another autocomplete
   * will trigger, allowing the user to insert a series of references.
   *
   * We check if we're in a valid context for autocomplete by:
   * - Checking if the text preceding the caret is valid for a reference autocomplete
   * - Step backwards from there, across any series of references delimited
   *   by spaces or commas (see {@link experimentalMatchers.referenceDelimiter})
   * - Then finally if the text before that matches the regex trigger
   *
   * Examples of valid trigger conditions:
   * - #conversation with |
   * - #conversation with +personA, +personB |
   */
  conversationWithReferences: {
    matcherName: "conversationWithReferences",
    createNode: (attrs) => createReferenceNode(attrs),
    getSuggestions: (text, noteId) => getReferenceSuggestions(text, noteId),
    getMatch: ($pos) => {
      const lineStart = $pos.before($pos.depth) + 1;

      // Exit right at the start if the trigger phrase is not present anywhere in the line
      if (!$pos.doc.textBetween(lineStart, $pos.pos).match(experimentalMatchers.conversationWithReferences)) {
        return null;
      }

      // Check if we're inside an existing autocomplete region, or at the
      // start of a new one.
      const lastNode = $pos.doc.slice(lineStart, $pos.pos).content.lastChild;
      let matchText: string | null = null;
      if (lastNode?.marks.map((m) => m.type).includes(schema.marks.autocompleteRegion)) {
        // We're already inside an autcomplete region
        matchText = lastNode.text ?? null;
      } else {
        // Check if we're at the start of a new one
        const startToCaretText = $pos.doc.textBetween(lineStart, $pos.pos);
        const refMatch = startToCaretText.match(experimentalMatchers.notReferencePrefix);
        matchText = refMatch?.[0] ?? null;
      }
      if (matchText === null) return null;
      const matchStart = $pos.pos - matchText.length;

      // Between the reference autocomplete and the trigger phrase, there must
      // be nothin *or* series of references separated by commas. Step back
      // until we find the start of that series.
      const fragmentBeforeMatch = $pos.doc.slice(lineStart, matchStart).content;
      let posSeriesStart = matchStart;
      let checking: "text" | "reference" = "text";
      for (let i = fragmentBeforeMatch.childCount - 1; i >= 0; i--) {
        const child = fragmentBeforeMatch.child(i);
        if (checking === "text") {
          if (!child.isText || !child.text?.match(experimentalMatchers.referenceDelimiter)) break;
          checking = "reference";
        } else if (checking === "reference") {
          if (child.type !== schema.nodes.reference) break;
          checking = "text";
        }
        posSeriesStart -= child.nodeSize;
      }

      // Finally, check if the text before match (and series) *ends* with a valid
      // trigger phrase.
      const textBeforeSeries = $pos.doc.textBetween(lineStart, posSeriesStart);
      const phraseMatch = textBeforeSeries.match(experimentalMatchers.conversationWithReferences);
      if (!phraseMatch || textBeforeSeries.slice(phraseMatch.index) !== phraseMatch[0]) {
        return null;
      }

      return { from: matchStart, to: $pos.pos, text: matchText };
    },
    isSmart: true,
  },
  angleBrackets: {
    matcherName: "angleBrackets",
    createNode: (attrs) => createReferenceNode(attrs),
    getSuggestions: (text, noteId) => getReferenceSuggestions(text, noteId),
    getMatch: ($pos) => {
      // Get the text from the last reference in the line (or the beginning of
      // the line) to the caret position
      let start = $pos.before($pos.depth) + 1;
      $pos.doc.nodesBetween(start, $pos.pos, (node, pos) => {
        if (node.type === schema.nodes.reference) {
          start = pos;
        }
        return true;
      });
      const fragment = $pos.doc.slice(start, $pos.pos).content;
      const textBefore = fragment.textBetween(0, fragment.size);
      const match = textBefore.match(regexpMatchers.angleBrackets);
      if (!match) return null;
      const text = match[2] ?? "";
      return { to: $pos.pos, from: $pos.pos - text.length, text };
    },
    isSmart: false,
  },
  hashtag: {
    matcherName: "hashtag",
    createNode: (attrs) => schema.text(attrs.content, [schema.marks.hashtag.create()]),
    getSuggestions: (text) => getHashtagSuggestions(text),
    getMatch: ($caretPos) => {
      return getMatch($caretPos, regexpMatchers.hashtag, "hashtag");
    },
    isSmart: false,
  },
};

/**
 * If a match is present at the current caret position, returns the range and the text that matches.
 */
const getMatch = ($caretPos: ResolvedPos, regex: RegExp, matcherName: AutocompleteTypes): FullMatch | null => {
  if ($caretPos.pos === 0) return null;

  const surroundingText = findSurroundingText($caretPos);
  if (!surroundingText) return null;

  const {
    textBefore: textToCursor,
    textAfter: textAfterCursor,
    startingPosition: matchingStartPosition,
  } = surroundingText;

  // Don't even try to match if the trigger character is not on the left side of the cursor
  // The matching below uses `textToCursorOrNextSpace` which takes some text past the cursor, so we need this first step
  if (matcherName === "hashtag" && !textToCursor.match(/(^|\s|\W)#/g)) return null;
  if (matcherName === "plusReference" && !textToCursor.match(/(^|\s|\W)\+/g)) return null;

  // apply the matching up to the cursor or the next space
  const textToCursorOrNextSpace = textToCursor + textAfterCursor.split(" ")[0];

  const match = textToCursorOrNextSpace.match(regex);

  if (!match) {
    // For hashtags and plus references, we just need a match on the prefix
    // to trigger the autocomplete.
    const onlyPrefixMatch =
      matcherName === "hashtag"
        ? textToCursorOrNextSpace.match(/(^|\s|\W)#$/)
        : matcherName === "plusReference"
          ? textToCursorOrNextSpace.match(/(^|\s|\W)\+$/)
          : null;
    if (!onlyPrefixMatch) return null;
    // since we only want a match when the end of the string is the prefix
    // remove the targetOffset, the add the string's we match to length minus
    // the length of the prefix
    const from = matchingStartPosition + textToCursorOrNextSpace.length - 1;
    return { from, to: from + 1, text: "" };
  }

  if (match.length !== 4) throw new Error("Invalid match! Expected 4 groups.");
  const [prePrefixMatch, prefixMatch, matchedContent] = match.slice(1);

  // The preceeding characters before the "+" or "#" are captured in the first group (match[1])
  // so we have to offset further into the match to find the actual content
  const from = matchingStartPosition + match.index! + prePrefixMatch.length;
  // the matching text plus the length of the prefix
  const to = from + prefixMatch.length + matchedContent.length;

  if (to < $caretPos.pos) {
    logger.warn("Found non-suffix match", { context: { textToCursor, match } });
    return null;
  }

  return { from, to, text: matchedContent };
};

const createReferenceNode = (attrs: { id: string; content: string }) => {
  trackEvent("create_relation");
  return schema.nodes.reference.create({
    tokenId: generateId(),
    linkedNoteId: attrs.id,
    content: appNoteStore.getNoteEllipsis(attrs.id),
  });
};

function findSurroundingText($caretPos: ResolvedPos) {
  const currentNode = $caretPos.doc.nodeAt($caretPos.pos - 1);
  if (!currentNode) return null;

  const currentNodeText = currentNode.text;
  if (!currentNodeText) return null;

  // When this position points into a text node, this returns the distance
  // between the position and the start of the text node. Will be zero
  // for positions that point between nodes.
  const targetOffset = $caretPos.textOffset === 0 ? currentNodeText.length : $caretPos.textOffset;

  const currentIndex = $caretPos.doc.resolve($caretPos.pos - 1).index();
  const nextNode = $caretPos.parent.maybeChild(currentIndex + 1);

  const textToCursor = currentNodeText.slice(0, targetOffset);
  const textAfterCursor = currentNodeText.slice(targetOffset) + (nextNode?.textContent ?? "");

  return {
    textBefore: textToCursor,
    textAfter: textAfterCursor,
    startingPosition: $caretPos.pos - targetOffset,
  };
}
