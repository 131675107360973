import { FetchWrapper } from "../fetchWrapper";
import { TranscriptionResponse } from "../../../shared/types";

export const TranscriptApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Get audio transcription
     * @param id The transcription id or request id
     */
    getTranscription: async (id: string): Promise<TranscriptionResponse> => client.get(`/transcripts/${id}`),

    startTranscription: async (payload: {
      audioId: string;
      numChunks: number;
      language: string;
      splitParagraphs: boolean;
    }): Promise<{ data: string | null; error: string | null }> => client.post("/transcripts", payload),
  };
};
