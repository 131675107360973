import { Fragment, Node, ResolvedPos, Slice } from "prosemirror-model";
import { EditorView } from "prosemirror-view";

import { appNoteStore } from "../../../model/services";
import { NoteId } from "../../../../shared/types";
import { generateId } from "../../../model/generateId";
import logger from "../../../utils/logger";
import { TrackRangesPluginState, trackRangesPluginKey } from "../../features/trackRanges/trackRangesPlugin";
import { schema } from "../../schema";
import { generatePathFromNoteId } from "../path";
import { trackEvent } from "../../../analytics/analyticsHandlers";

// TODO : move to a more generic place (as it's imported by both clipboard and audioview)
export function readBlobAsDataUri(file: Blob) {
  return new Promise<string>((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const placeholderSrc = reader.result;

      // if not a string, there is an error
      if (!(typeof placeholderSrc === "string")) {
        rej(new Error("dataUri cannot be generated"));
        return;
      }

      res(placeholderSrc);
    };
    reader.onerror = (e) => {
      logger.error("Error converting to base64", { error: e });
      rej(e);
    };
  });
}

// When pasting notes we need to generate new ids for them
// We want to generate the new id from an old id in a stable way, so when
// using the same mapper reference ids will be correctly translated
export function createNoteIdMapper() {
  const oldNoteIdToNewNoteId = new Map<string, string>();
  return (oldId: string) => {
    let newId = oldNoteIdToNewNoteId.get(oldId);
    if (newId === undefined) {
      newId = generateId();
      oldNoteIdToNewNoteId.set(oldId, newId);
    }
    return newId;
  };
}

/** Check if the slice includes any note delimiters */
export function includesNoteDelimiters(slice: Slice) {
  let doubleDashSplitsDetected = false;
  let emptyParagraphCount = 0;

  // Analyze the content to see if there are any "--" paragraphs and how many empty paragraphs are present
  slice.content.forEach((n) => {
    if (n.type === schema.nodes.paragraph && n.textContent === "") {
      emptyParagraphCount++;
    }
    if (n.type === schema.nodes.paragraph && (n.textContent === "--" || n.textContent === "—")) {
      doubleDashSplitsDetected = true;
    }
  });

  return {
    doubleDashSplitsDetected,
    emptyParagraphsSplitsDetected: emptyParagraphCount >= 3,
  };
}

/** Split pasted content on selecters delimiters */
export function performSplit(view: EditorView, skipSplitTypes?: "emptyparagraph" | "doubledash") {
  let blocks: Node[] = [];
  const notes: Node[] = [];

  const trackedRange = trackRangesPluginKey.getState(view.state) as TrackRangesPluginState;
  if (!trackedRange.currentRange) return;

  const slice = view.state.doc.slice(trackedRange.currentRange.start, trackedRange.currentRange.end);

  let isFirst = true;
  function cutNote() {
    if (isFirst) {
      notes.push(...blocks);
      blocks = [];
      isFirst = false;
      return;
    }
    const noteId = generateId();
    notes.push(
      schema.nodes.note.create(
        { noteId, path: generatePathFromNoteId(noteId) },
        blocks.length > 0 ? blocks : [schema.nodes.paragraph.create({ tokenId: generateId() })],
      ),
    );
    blocks = [];
  }
  slice.content.forEach((n) => {
    if (skipSplitTypes !== "emptyparagraph" && n.type === schema.nodes.paragraph && n.textContent === "") {
      cutNote();
      return;
    }
    if (
      skipSplitTypes !== "doubledash" &&
      n.type === schema.nodes.paragraph &&
      (n.textContent === "--" || n.textContent === "—")
    ) {
      cutNote();
      return;
    }
    blocks.push(n);
  });
  cutNote();

  const updatedSlice = new Slice(Fragment.from(notes), 0, 1);

  // Compute positions
  upsertNewNotes(view.state.doc.resolve(trackedRange.currentRange.start), updatedSlice);

  const tr = view.state.tr.replace(trackedRange.currentRange.start, trackedRange.currentRange.end, updatedSlice);
  view.dispatch(tr);
}

/**
 * @warning This function is not pure, it mutates the appNoteStore (possible TODO)
 * @param $from
 * @param slice
 */
export function upsertNewNotes($from: ResolvedPos, slice: Slice) {
  const note = $from.node(1); // note
  const notesToBePositioned: { id: NoteId }[] = [];
  slice.content.descendants((node) => {
    if (node.type === schema.nodes.note) {
      notesToBePositioned.push({ id: node.attrs.noteId });
    }
  });
  appNoteStore.insertAfter(note.attrs.noteId, notesToBePositioned);
  notesToBePositioned.forEach(({ id }) => trackEvent("create_note", id));
}
