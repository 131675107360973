import { Plugin, PluginKey } from "prosemirror-state";
import * as block from "./block";
import * as inline from "./inline";
import { isControlKeyDown, isInlineCode } from "./utils";

export const codePlugin = new Plugin({
  key: new PluginKey("code"),
  props: {
    handleKeyDown(view, event) {
      if (!view || !view.state || !view.dispatch) {
        return false;
      }
      const { $from } = view.state.selection;
      const isBlock = $from.parent.type === view.state.schema.nodes.codeblock;
      const isInline = !isBlock && isInlineCode($from);

      if (!isBlock && !isInline) {
        return false;
      }

      switch (event.key) {
        case "ArrowLeft": {
          return isInline
            ? inline.handleArrowLeft(view.state, view.dispatch)
            : block.handleArrowLeft(view.state, view.dispatch);
        }
        case "ArrowRight": {
          return isInline
            ? inline.handleArrowRight(view.state, view.dispatch)
            : block.handleArrowRight(view.state, view.dispatch);
        }
      }

      if (!isBlock) {
        return false;
      }

      switch (event.key) {
        case "ArrowUp": {
          return block.handleArrowUp(view.state, view.dispatch);
        }

        case "ArrowDown": {
          return block.handleArrowDown(view.state, view.dispatch);
        }
        case "Backspace": {
          return block.handleBackspace(view.state, view.dispatch);
        }
        case "Enter": {
          return isControlKeyDown(event)
            ? block.handleCtrlEnter(view.state, view.dispatch)
            : block.handleEnter(view.state, view.dispatch);
        }
        case "a": {
          return isControlKeyDown(event) ? block.handleSelectAll(view.state, view.dispatch) : false;
        }
      }
      return false;
    },
  },
});
