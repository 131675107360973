import { Selection, Transaction } from "prosemirror-state";
import { schema } from "../editor/schema";
import { appendToNote } from "../editor/features/note/appendToNote";
import { createAsyncExtractedEntitiesNode } from "./createAsyncExtractedEntitiesNode";

/**
 * Given the position of a note, extract entities from it and append them to
 * the note.
 */
export function extractEntitiesInNoteAndAppendToNote(tr: Transaction, pos: number) {
  const node = tr.doc.nodeAt(pos);
  if (!node || node.type !== schema.nodes.note) return;
  const paragraph = schema.nodes.paragraph.create({}, [schema.text(" ")]);
  const posParagraph = appendToNote(tr, pos, paragraph);
  // Extract entities from the note and insert them into the paragraph.
  // If no entities are found, fallback to an empty string.
  const from = posParagraph + 1;
  const to = posParagraph + paragraph.nodeSize - 1;
  const noteText = tr.doc.textBetween(pos, pos + node.nodeSize);
  tr.replaceWith(from, to, createAsyncExtractedEntitiesNode(tr, noteText));
  // Select the paragraph.
  tr.setSelection(Selection.near(tr.doc.resolve(pos)));
  return tr;
}
