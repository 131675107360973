import { NodeSpec } from "prosemirror-model";

export const checkbox: NodeSpec = {
  group: "inline",
  selectable: false,
  inline: true,
  attrs: { isChecked: { default: false } },
  toDOM(node) {
    // see the node view for the actual DOM
    return [
      "label",
      { class: "checkbox-wrapper", contenteditable: "false" },
      [
        "input",
        {
          type: "checkbox",
          ...(node.attrs.isChecked ? { checked: "true" } : undefined), // ENT-806
        },
      ],
    ];
  },
  parseDOM: [
    {
      tag: "input",
      getAttrs: (dom) => {
        if (typeof dom != "string")
          return {
            isChecked: (dom as any).getAttribute("checked") === "true",
          };
        else return {};
      },
    },
  ],
};
