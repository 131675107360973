import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { editorViewAtom } from "../../model/atoms";
import { globalAudioRecordingCoordinator } from "../../editor/features/audioInsert/audioRecordingCoordinator";
import { StopRecordingButton } from "../Buttons";

export function RecordingControlUI() {
  const recordingState = globalAudioRecordingCoordinator.useRecordingState();
  const isRecording = recordingState?.state === "recording";

  const getEditorView = useAtomCallback(useCallback((get) => get(editorViewAtom), []));

  return (
    <>
      {isRecording && (
        <div style={{ position: "relative", margin: "4px 0px 4px 4px" }}>
          <div
            className="pulsesSource"
            onMouseDown={(e) => {
              e.preventDefault();
              if (recordingState) globalAudioRecordingCoordinator.stopRecording(recordingState.audioId);
            }}
          />
          <div
            aria-label={"Stop recording the current audio note"}
            data-microtip-position="left"
            role="tooltip"
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              borderRadius: "100%",
            }}
            onMouseDown={async (e) => {
              e.preventDefault();
              if (recordingState) globalAudioRecordingCoordinator.stopRecording(recordingState.audioId);
              // Focus the editor so the user can start typing again
              const editor = await getEditorView?.();
              editor?.focus();
            }}
          >
            <StopRecordingButton />
          </div>
        </div>
      )}
    </>
  );
}
