import { useState, useEffect } from "react";

/** Hook returning true if page is visible, false otherwise. */
export const useIsPageVisible = () => {
  const [isPageVisible, setIsPageVisible] = useState(true);
  useEffect(() => {
    const onVisibilityChange = () => setIsPageVisible(!document.hidden);
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => window.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);
  return isPageVisible;
};
