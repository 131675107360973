import { CustomizableShortcutKeys } from "../../shared/types";
import { getUserSettings, upsertUserSettings, useUserSettings } from "../model/userSettings";
import { shortcuts, customizableShortcuts } from "./rawShortcuts";

type ShortcutsHookReturns = {
  /** All shortcuts along with the custom ones saved by user */
  shortcuts: typeof shortcuts;
  /** Number of custom shortcuts saved by user (always less than or equal to {@link CustomizableShortcutKeys.length}) */
  nCustomShortcuts: number;
};

export const useShortcuts = (): ShortcutsHookReturns => {
  const userSettings = useUserSettings();
  const customShortcuts = userSettings.customShortcuts ?? {};
  return {
    shortcuts: { ...shortcuts, ...customShortcuts },
    nCustomShortcuts: Object.keys(customShortcuts).length,
  };
};

export const updateShortcut = (shortcutId: CustomizableShortcutKeys[number], keys: string) => {
  // Here we only merge customizable shortcuts with custom shortcuts saved by user
  const currentShortcuts = { ...customizableShortcuts, ...getUserSettings().customShortcuts };
  // Only the keys are updated, the rest of the shortcut properties are kept the same
  const newCustomShortcuts = {
    ...currentShortcuts,
    [shortcutId]: {
      ...currentShortcuts[shortcutId],
      keys,
    },
  };

  upsertUserSettings({ customShortcuts: newCustomShortcuts });
};
