import { atom } from "jotai";
import isEqual from "lodash.isequal";
import { NoteId } from "../../../../shared/types";
import { useDebouncedMemo } from "../../../autocomplete/useDebouncedMemo";
import { autocompleteModules, AutocompleteTypes } from "./modules";

type EditorAutocompleteState = {
  noteId: NoteId;
  matcherName: AutocompleteTypes;
  cancel: () => void;
  match: { from: number; to: number };
  text: string;
} | null;

const autocompleteStateRawAtom = atom<EditorAutocompleteState>(null);
export const autocompleteStateAtom = atom(
  (get) => get(autocompleteStateRawAtom),
  (get, set, update: (prevState: EditorAutocompleteState) => EditorAutocompleteState) => {
    const oldState = get(autocompleteStateRawAtom);
    const newState = update(oldState);
    if (isEqual(oldState, newState)) return;
    set(autocompleteStateRawAtom, newState);
  },
);

export function useAutocompleteSuggestions(autocompleteState: EditorAutocompleteState) {
  return useDebouncedMemo(
    () => {
      if (!autocompleteState) return [];
      const { matcherName, text, noteId } = autocompleteState;
      const autocompleteMod = autocompleteModules[matcherName];
      const suggestions = autocompleteMod.getSuggestions(text, noteId);
      // exclude suggesting current line or entry
      return suggestions.filter((suggestion) => suggestion.id !== noteId);
    },
    200,
    [autocompleteState],
  );
}
