import { useAtomValue, getDefaultStore } from "jotai";
import { modelVersion } from "../utils/environment";
import { UserSettings } from "../../shared/types";
import { appLocalStore, isDirtySet } from "./services";
import { userSettingsAtom } from "./atoms";
import { userDirtyId } from "./store/LocalStore";

/**
 * This function is used to update the user data in the local state and send it to the service worker.
 */
export const upsertUserSettings = (newData: Partial<UserSettings>) => {
  getDefaultStore().set(userSettingsAtom, (userSettings: UserSettings) => {
    const newUserSettings = { ...userSettings, ...newData };
    const transactionId = isDirtySet.startUpsert(userDirtyId);
    appLocalStore.upsertUserSettings(newUserSettings, transactionId, modelVersion);
    return newUserSettings;
  });
};

/**
 * This hook is used to get the user data from the local state.
 */
export const useUserSettings = () => {
  return useAtomValue(userSettingsAtom);
};

export const getUserSettings = () => {
  return getDefaultStore().get(userSettingsAtom);
};
