import { getDefaultStore } from "jotai";
import { closeHistory } from "prosemirror-history";
import { Node, Slice } from "prosemirror-model";
import { EditorView } from "prosemirror-view";

import { addToast } from "../../../components/Toast";
import { userSettingsAtom } from "../../../model/atoms";
import { isUploadEnabled, urlUpload } from "../../../utils/environment";
import logger from "../../../utils/logger";
import { trackRangesPluginKey } from "../../features/trackRanges/trackRangesPlugin";
import { schema } from "../../schema";
import type { transformPasted } from "./transforms";
import type { cleanUpPastedHTML } from "./htmlTools";

import { getImageItems, handleRawImageUploadPaste } from "./imageTools";
import { normalProsemirrorPasteHandler } from "./prosemirrorHelpers";
import { includesNoteDelimiters, performSplit, upsertNewNotes } from "./utils";

export function handleDrop(view: EditorView, event: DragEvent, slice: Slice, moved: boolean): boolean {
  const imageFiles = getImageItems(event.dataTransfer).map((item) => item.getAsFile());
  if (imageFiles.length === 0) return false;

  // Inspired by: https://github.com/ProseMirror/prosemirror-dropcursor/blob/master/src/dropcursor.ts#L119
  const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });

  // Stop the default handling since it results in an image being open in the same browser tab
  if (!pos) return true;

  const uploadAllDroppedFiles = async () => {
    for (const file of imageFiles) {
      if (file) {
        await handleRawImageUploadPaste(file, view, pos.pos);
      } else {
        logger.warn("Cannot process an empty file in handleDrop");
        addToast({ type: "error", content: "Cannot process an empty file" });
      }
    }
  };
  uploadAllDroppedFiles();
  return true;
}

/**
 * The complete handlePaste function processing images/linkLoaders
 * This function returns true if the default paste event has been prevented
 * ProseMirror plugins that are called on paste:
 * - 1st: {@link cleanUpPastedHTML}
 * - 2nd: {@link transformPasted}
 * - 3rd: this function
 */
export function handlePaste(view: EditorView, pasteEvent: ClipboardEvent, slice: Slice): boolean {
  const res = handlePasteWithImages(view, pasteEvent, slice);
  // If the deafult paste event has been prevented, we don't need to look for link loaders to activate
  if (res) return true;

  view.dispatch(
    view.state.tr.setMeta(trackRangesPluginKey, {
      setRange: {
        start: view.state.selection.$from.pos,
        end: view.state.selection.$to.pos,
      },
    }),
  );

  const { doubleDashSplitsDetected, emptyParagraphsSplitsDetected } = includesNoteDelimiters(slice);

  if (doubleDashSplitsDetected || emptyParagraphsSplitsDetected) {
    const options = [
      ...(doubleDashSplitsDetected ? [`'--' lines`] : []),
      ...(emptyParagraphsSplitsDetected ? [`double newlines`] : []),
    ];
    addToast({
      content: `Split note by ${options.join(" or ")}?`,
      buttons:
        doubleDashSplitsDetected && emptyParagraphsSplitsDetected
          ? [
              {
                text: "Both",
                onClick() {
                  performSplit(view);
                },
              },
              {
                text: "Just '--' lines",
                onClick() {
                  performSplit(view, "emptyparagraph");
                },
              },
              {
                text: "Just double newlines",
                onClick() {
                  performSplit(view, "doubledash");
                },
              },
              { text: "Keep as is" },
            ]
          : [
              {
                text: "Split",
                onClick() {
                  performSplit(view);
                },
              },
              { text: "Keep as is" },
            ],
    });
  }

  return handlePasteOfLinkLoaders(view, slice);
}

/**
 * This function finds all the linkLoader nodes and sends a separate transaction that activates them
 * This allows the first undo to remove just the link loaders and not undo the entire paste event
 */
function handlePasteOfLinkLoaders(view: EditorView, slice: Slice): boolean {
  // Find all the link loader nodes added in the pasted slice
  const linkLoaderNodes: Node[] = [];
  slice.content.descendants((n) => {
    if (n.type === schema.nodes.linkLoader) {
      linkLoaderNodes.push(n);
    }
  });
  if (linkLoaderNodes.length === 0) {
    return false;
  }

  // Find the positions of the pasted link loader nodes
  const linkLoaderNodesPositions: { node: Node; pos: number }[] = [];
  normalProsemirrorPasteHandler(view, slice);
  view.state.doc.descendants((n, pos) => {
    if (linkLoaderNodes.includes(n)) {
      linkLoaderNodesPositions.push({ node: n, pos });
    }
  });

  const tr = view.state.tr;
  for (const { node, pos } of linkLoaderNodesPositions) {
    tr.setNodeMarkup(pos, undefined, {
      ...node.attrs,
      isActive: true,
    });
  }
  closeHistory(tr);
  view.dispatch(tr);

  const { seenUnfurl } = getDefaultStore().get(userSettingsAtom);
  if (!seenUnfurl) {
    addToast("You can undo to remove the unfurled link titles. Unfurling can be disabled in Settings");
  }
  return true;
}

function handlePasteWithImages(view: EditorView, pasteEvent: ClipboardEvent, slice: Slice): boolean {
  const pressingShift = (view as any).shiftKey; // undocumented api: see https://git.io/JtfDA
  if (!pasteEvent.clipboardData || pressingShift) return false; // some older browsers have a broken paste API.
  const { $from } = view.state.selection;
  const parentNode = $from.parent;
  if (parentNode.type === schema.nodes.paragraph) {
    upsertNewNotes($from, slice);
  }

  if (!isUploadEnabled) return false;
  if (!urlUpload) {
    logger.warn("You need to configure an upload endpoint to be able to upload files");
    return false;
  }

  // every paste event will have n clipboardData items
  // for each clipboardData we have a different meme type  for each event
  // we want to have different rules for each item
  // the reason we want to look through them all, is that when uploading
  // files, we get the filename as one item and the image itself as another
  // this is the same with HTML entries
  const imageItems = Array.from(pasteEvent.clipboardData.items).filter((item) => item.type.includes("image"));
  // Upload and insert the images one by one sequentially.
  // This way we preserve their order
  (async () => {
    // The files must be obtained before the paste event handler returns
    // If the item.getAsFile() is called later, the file is empty
    const files = imageItems.map((item) => item.getAsFile());
    for (const file of files) {
      if (file) {
        await handleRawImageUploadPaste(file, view);
      } else {
        logger.warn("Cannot process an empty file in handleDrop");
        addToast({ type: "error", content: "Cannot process an empty file" });
      }
    }
  })();
  // Prevent CodeMirror from automatically handling the paste if there are any images that are being uploaded
  return imageItems.length > 0;
}
