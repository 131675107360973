import React from "react";

export function Spinner({
  style,
  svgStyle,
  distanceFromCenter = 40,
  numberOfPoints = 7,
}: {
  style?: React.CSSProperties;
  svgStyle?: React.CSSProperties;
  distanceFromCenter?: number;
  numberOfPoints?: number;
}) {
  const angleGap = Math.PI / 4;
  return (
    <div style={style} className="spinner">
      <svg viewBox="-50 -50 100 100" style={svgStyle}>
        {Array.from(new Array(numberOfPoints), (_, i) => i).map((i) => {
          const angle = ((2 * Math.PI - angleGap) / numberOfPoints) * i;
          return (
            <circle
              key={i}
              cx={Math.cos(angle) * distanceFromCenter}
              cy={Math.sin(angle) * distanceFromCenter}
              r={10}
              style={{ fill: "gray", opacity: 1.0 - i / numberOfPoints }}
            />
          );
        })}
      </svg>
    </div>
  );
}
