import { useEffect, useState } from "react";
import { retryOfflineEvents, trackEvent } from "../../analytics/analyticsHandlers";
import { setIsOnline } from "../atoms";
import logger from "../../utils/logger";
import { useIsPageVisible } from "../../utils/useIsPageVisible";
import { ModalEnum, openModal } from "../modals";
import { addToast } from "../../components/Toast";
import { daysBetween } from "../../utils/date";
import { SyncStats } from "./SyncStats";
import { computeSyncStatus } from "./SyncStatus";
import { createSyncStatsWithDatetimes } from "./createSyncStatsWithDatetimes";

// A custom hook called by {@link SyncStatusIcon} that gathers together all side-effect code reacting to changes in the sync stats.
// Add code here to react to changes in sync/offline status.
export const useSyncStatsReactions = (syncStats: SyncStats) => {
  const isPageVisible = useIsPageVisible();
  const [shownOfflineWarning, setShownOfflineWarning] = useState(false);

  useEffect(() => {
    const syncStatsWithDatetimes = createSyncStatsWithDatetimes(syncStats);
    const status = computeSyncStatus(syncStats);

    // Log sync stats
    const now = Date.now();
    const dataDump = {
      ...syncStatsWithDatetimes,
      ...status,
      isPageVisible,
      now: new Date(now),
    };
    logger.info("sync stats", { namespace: "sync-main", context: dataDump });

    // Report to sentry and raise to the user if we're stuck unsynced
    if (
      status.id === "error" &&
      isPageVisible &&
      // Only report every 10 consecutive non-syncs
      syncStats.countSinceLastFullySynced % 10 === 0
    ) {
      // setMessageBox(SYNC_ISSUE_MESSAGE);
      logger.warn("Stuck unsynced", { context: dataDump });
    }

    const isOnline = status.id !== "offline";
    setIsOnline(isOnline);

    if (isOnline) {
      retryOfflineEvents();
      setShownOfflineWarning(false);
    }

    if (
      !isOnline &&
      syncStats.lastFullySyncedAt &&
      daysBetween(now, syncStats.lastFullySyncedAt) > 3 &&
      !shownOfflineWarning
    ) {
      trackEvent("offline_warning_shown");

      // Warn user to export notes if they've been offline for more than 3 days
      showOfflineWarningToast();

      // Only show the warning once per user session
      setShownOfflineWarning(true);
    }
  }, [isPageVisible, shownOfflineWarning, syncStats]);
};

const showOfflineWarningToast = () => {
  addToast({
    content: (
      <>
        <span>
          You've been offline for more than 3 days. Any changes you've made during that time are at risk of being lost.
          You can&nbsp;
        </span>
        <span className="link no-word-break" onClick={(e) => openModal(ModalEnum.EXPORT)}>
          export your notes now
        </span>
        <span>&nbsp;to make sure you have a backup.</span>
      </>
    ),
    type: "warning",
  });
};
