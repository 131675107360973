import { NodeSpec } from "prosemirror-model";
import { domAttrs, getDefaultAttrs } from "../../utils/attrs";

export const note: NodeSpec = {
  content: "topLevelNode+ backlinks?",
  group: "note",
  selectable: false,
  draggable: false,
  defining: true,
  attrs: {
    noteId: {},
    path: {},
    depth: { default: 0 },
    highlighted: { default: false },
    folderId: { default: null },
    // Is true if condensing is enabled (e.g. compact view or expansion setting).
    condensingEnabled: { default: false },
    // Whether it's possible to condense the note (e.g. a single paragraph isn't condensable).
    condensable: { default: false },
    // Whether a condensable note is currently condensed.
    isCondensed: { default: true },
    expansionSetting: { default: "auto" }, // "auto" | "expand" | "collapse"
    insertedAt: { default: null },
    readAll: { default: false },
    fromSimon: { default: false },
  },
  parseDOM: [
    {
      tag: "div.note",
      getAttrs: getDefaultAttrs([
        "noteId",
        "path",
        { key: "depth", type: "int" },
        "folderId",
        { key: "condensingEnabled", type: "boolean" },
        { key: "isCondensed", type: "boolean" },
        { key: "condensable", type: "boolean" },
        "expansionSetting",
      ]),
    },
  ],
  toDOM(node) {
    return [
      "div",
      {
        ...domAttrs(node, [
          "noteId",
          "path",
          "depth",
          "folderId",
          "condensingEnabled",
          "isCondensed",
          "condensable",
          "expansionSetting",
        ]),
        class:
          "note" +
          (node.attrs.highlighted ? " highlighted" : "") +
          (node.attrs.readAll ? " readAll" : "") +
          (node.attrs.fromSimon ? " fromSimon" : ""),
      },
      0,
    ];
  },
};
