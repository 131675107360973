import { FetchWrapper } from "../fetchWrapper";

export const VersionApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Returns the server, web and appStore version. Versions are updated every hour on the server.
     */
    get: async (): Promise<{
      serverVersion: string;
      webVersion: string;
      appStoreVersion: string;
    }> => client.get("/versions"),
  };
};
