import { NodeSpec } from "prosemirror-model";
import { domAttrs } from "../../utils/attrs";
import { nonCustomizableShortcuts } from "../../../shortcuts/rawShortcuts";
import { getShortcutString } from "../../../shortcuts/shortcuts";

export const ellipsis: NodeSpec = {
  content: "(topLevelNode | listItem)+",
  draggable: false,
  group: "ellipsisContent",
  locked: true,
  selectable: false,
  parseDOM: [{ tag: "div.ellipsis" }],
  toDOM: () => ["div", { class: "ellipsis" }, 0],
};

export const ellipsisContainer: NodeSpec = {
  // Only allow one ellipsisDot and one ellipsisContent per ellipsisContainer
  // This prevents Prosemirror from merging them together which can result in loss of data
  // since the bridge.ts is only expecting one child content node (ENT-1601)
  content: "ellipsisDot ellipsisContent", // ENT-1482
  draggable: false,
  group: "topLevelNode bulletChild",
  locked: true,
  selectable: false,
  parseDOM: [{ tag: "div.ellipsisContainer" }],
  toDOM: () => [
    "div",
    {
      class: "ellipsisContainer",
      // Disabled for now cause with this implementation, the tooltip is still shown
      // while the ellipsis is expanded. This should be easier to get right once
      // ENT-2007 is implemented.
      // role: "tooltip",
      "data-microtip-position": "left",
      "aria-label": `Show more ${getShortcutString(nonCustomizableShortcuts.expandReference)}`,
    },
    0,
  ],
};

export const ellipsisDot: NodeSpec = {
  draggable: false,
  group: "ellipsisDot",
  locked: true,
  attrs: {
    depth: { default: 0 },
    listItem: { default: false },
  },
  selectable: false,
  parseDOM: [{ tag: "span.ellipsis-dot" }],
  toDOM: (node) => [
    "span",
    {
      ...domAttrs(node, ["depth"]),
      class: "ellipsis-dot" + (node.attrs.listItem ? " ellipsis-dot-list-item" : ""),
      style: `
    margin-left: ${node.attrs.depth * 24}px;
    `,
    },
    ["b", {}, "..."],
  ],
};
