import { EditorView } from "prosemirror-view";
import { Fragment, Node, NodeRange, ResolvedPos, Slice } from "prosemirror-model";
import { Command, EditorState, Plugin, PluginKey, Selection, TextSelection, Transaction } from "prosemirror-state";
import { canSplit, ReplaceAroundStep, liftTarget } from "prosemirror-transform";
import { autoJoin, createParagraphNear, joinBackward } from "prosemirror-commands";
import { getDefaultStore } from "jotai";
import { schema } from "../../schema";
import { appNoteStore } from "../../../model/services";
import { handleSplitWithCheckbox } from "../checkbox/splitWithCheckboxesCommand";
import {
  isCaretOnReference,
  preserveExpandedReferencesAround,
  toggleReference,
} from "../reference/referenceExpansionUtils";
import { wasAtLeastOneNoteAddedAtom } from "../../../model/atoms";
import { noteToProsemirrorNode } from "../../bridge";

export const handleListActionsPlugin = new Plugin({
  key: new PluginKey("handleListActionsPlugin"),
  props: {
    handleKeyDown(view, event) {
      if (
        !(
          event.key === "Enter" ||
          event.code === "Backspace" ||
          event.code === "Tab" ||
          event.code === "BracketLeft" ||
          event.code === "BracketRight"
        )
      )
        return false;

      // `event.code` distinguishes between "NumpadEnter" and "Enter", so we use `event.key` instead
      if (event.key === "Enter") {
        // split
        if (event.metaKey || event.ctrlKey) {
          return splitNoteWithinListCommand(view.state, view.dispatch);
        }
        if (event.shiftKey) {
          // shift-enter should create a new paragraph without another bulleted list
          return createParagraphNear(view.state, view.dispatch);
        }
        // if the bullet is empty and the depth is zero, we should lift
        if (splitListItemCommand(view.state, view.dispatch)) return true;
      }

      switch (event.code) {
        case "BracketLeft":
          if (event.metaKey || event.ctrlKey) {
            if (decrementDepthCommand(view.state, view.dispatch)) return true;
          }
          break;
        case "BracketRight":
          if (event.metaKey || event.ctrlKey) {
            if (incrementDepthCommand(view.state, view.dispatch)) return true;
          }
          break;
        case "Tab":
          // if on reference, toggle it
          if (toggleReference(view.state, view.dispatch)) return true;

          // If in codeblock, we just want to insert a \t
          if (
            view.state.selection.$from.parent.type === view.state.schema.nodes.codeblock &&
            view.state.selection.$to.parent.type === view.state.schema.nodes.codeblock
          ) {
            return false;
          }
          if (event.shiftKey) {
            // lift
            if (decrementDepthCommand(view.state, view.dispatch)) return true;
            break;
          }

          if (incrementDepthCommand(view.state, view.dispatch)) return true;
          break;
        case "Backspace":
          // delete current list item and go to end of previous list item
          if (deleteListItem(view.state, view.dispatch)) return true;
          break;
      }

      return false;
    },
  },
});

export const incrementDepthCommand: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const { $from, $to } = state.selection;
  const tr = state.tr;

  const fromAncestor = $from.node(-2);
  const toAncestor = $to.node(-2);

  if (!fromAncestor || !toAncestor) return false;
  if (!$from.sharedDepth($to.pos)) return false;
  if (
    !(
      fromAncestor.type === schema.nodes.bulletList &&
      toAncestor.type === schema.nodes.bulletList &&
      fromAncestor === toAncestor
    )
  )
    return false;

  state.doc.nodesBetween($from.pos, $to.pos, (node, pos, parent) => {
    if (node.type === schema.nodes.listItem && parent === fromAncestor) {
      tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        // node.attrs.depth is ensured to be a number a string so it needs to be parsed before adding 1
        // otherwise we increment "1" -> "11"
        depth: node.attrs.depth + 1,
      });
    }
  });

  dispatch(tr);
  return true;
};

export const decrementDepthCommand: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const { $from, $to } = state.selection;
  const tr = state.tr;

  const fromAncestor = $from.node(-2);
  const toAncestor = $to.node(-2);

  if (!fromAncestor || !toAncestor) return false;
  if (!$from.sharedDepth($to.pos)) return false;
  if (
    !(
      fromAncestor.type === schema.nodes.bulletList &&
      toAncestor.type === schema.nodes.bulletList &&
      fromAncestor === toAncestor
    )
  )
    return false;

  let shouldLift = false;
  state.doc.nodesBetween($from.pos, $to.pos, (node, pos, parent) => {
    if (node.type === schema.nodes.listItem && parent === fromAncestor) {
      const depth = node.attrs.depth;
      if (depth > 0) {
        tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          depth: depth - 1,
        });
      } else if (depth === 0) {
        shouldLift = true;
      }
    }
  });

  // Get range and confirm it's inside a non-empty bullet list
  const range = $from.blockRange($to, (node) => node.childCount !== 0 && node.type === schema.nodes.bulletList);
  if (!range) return false;

  if (shouldLift) {
    return liftOutOfListCommand(state, dispatch, range);
  }

  dispatch(tr);
  return true;
};

// Split note doesn't have a logically correct behavior in the general case,
// because of lots of edge cases. For example:
// - splitting a block node within a deeply nested list item into a new note
// - splitting a deeply nested bullet within a nested list
// Hence, we handle a few special cases in this handler and otherwise just treat
// it as a newline / raw Enter.
const splitNoteWithinListCommand = (state: EditorState, dispatch: EditorView["dispatch"]): boolean => {
  const { $from, $to } = state.selection;

  // if we are not in a list, pass to splitNote handler
  if (
    !(
      ($from.parent.type === schema.nodes.paragraph || $from.parent.type === schema.nodes.codeblock) &&
      $from.node(-1).type === schema.nodes.listItem
    )
  )
    return false;

  // codeblock in a list item
  if ($from.parent.type === schema.nodes.codeblock) {
    dispatch(state.tr.split($from.pos));
    return true;
  }

  // splitNote when cursor is at the end of a note, within a bulleted list. In
  // this case, we want to act like a normal splitNote and create a new note.
  if (
    $from.parent.type === schema.nodes.paragraph &&
    // Is not a range selection
    $from.pos === $to.pos &&
    // Cursor is at the end position of the note
    $from.pos + $from.depth - 1 === $from.end(1)
  ) {
    const prevNote = appNoteStore.get($from.node(1).attrs.noteId)!;
    const [newNote] = appNoteStore.insertAfter(prevNote.id);
    const noteNodeToInsert = noteToProsemirrorNode(newNote);
    const tr = state.tr;
    if ($from.parent.nodeSize === 2) {
      // start of a blank bullet line, delete the blank line and create a new note below
      tr.insert($from.end(1), noteNodeToInsert);
      tr.deleteRange($from.pos - 2, $from.pos + 2);
    } else if ($from.end($from.depth) === $from.pos) {
      // end of a bullet line, create a new blank note below
      tr.insert($from.end(1), noteNodeToInsert);

      // move the cursor to the next note
      const insertedNote = tr.doc.childAfter($from.pos).node;
      if (insertedNote == null) throw new Error("Could not find inserted note while splitting list");
      const noteStartPos = $from.end(1);
      const cursorPos = tr.doc.resolve(noteStartPos);
      tr.setSelection(Selection.near(cursorPos));
    } else {
      throw new Error("Invariant violation: cursor is at end of a note in a bullet list, but not at end of line");
    }

    getDefaultStore().set(wasAtLeastOneNoteAddedAtom, true);

    tr.scrollIntoView();
    dispatch(tr);

    return true;
  }

  return splitListItemCommand(state, dispatch);
};

// Exported for testing
// Splits a textblock within a list item
//   adapted from https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.js
export const splitListItemCommand = (state: EditorState, dispatch: (tr: Transaction) => void): boolean => {
  const { $from, $to } = state.selection;
  if ($from.depth < 2 || !$from.sameParent($to)) return false;
  if (!($from.parent.type === schema.nodes.paragraph && $from.node(-1).type === schema.nodes.listItem)) {
    return false;
  }

  // If in an empty list item, decrement the depth
  if ($from.parent.content.size === 0) {
    return decrementDepthCommand(state, dispatch);
  }

  const nextType = $to.pos === $from.end() ? schema.nodes.paragraph : null;

  const tr = isCaretOnReference(state.selection)
    ? state.tr.setSelection(new TextSelection(state.tr.doc.resolve($from.pos - 1)))
    : state.tr.delete($from.pos, $to.pos);
  const types = nextType && [undefined, { type: nextType }];
  if (!canSplit(tr.doc, $from.pos, 2, types as any)) return false;

  preserveExpandedReferencesAround(state, tr, () => {
    if (!handleSplitWithCheckbox(state, tr)) {
      tr.split(state.tr.mapping.map($from.pos), 2, types as any);
    }
  });

  dispatch(tr.scrollIntoView());

  return true;
};

// lift the list item around the selection out of the parent bullet list
const liftListItemCommand = (state: EditorState, dispatch?: (tr: Transaction) => void) => {
  const { $from, $to } = state.selection;
  const range = $from.blockRange(
    $to,
    (node) => node.childCount !== 0 && node.firstChild?.type === schema.nodes.listItem,
  );
  if (!range) return false;
  if (!dispatch) return true;

  // if ($from.node(range.depth - 1).type === schema.nodes.listItem) {
  //   // Inside a parent list
  //   return liftToOuterList(state, dispatch, range);
  // }

  // Outer list node
  return liftOutOfListCommand(state, dispatch, range);
};

const liftOutOfListCommand = (state: EditorState, dispatch: (tr: Transaction) => void, range: NodeRange) => {
  const tr = state.tr;
  const list = range.parent;

  preserveExpandedReferencesAround(state, tr, () => {
    // Merge the list items into a single big item
    for (let pos = range.end, i = range.endIndex - 1, e = range.startIndex; i > e; i--) {
      pos -= list.child(i).nodeSize;
      tr.delete(pos - 1, pos + 1);
    }
    const $start = tr.doc.resolve(range.start);
    const item = $start.nodeAfter as Node;
    const atStart = range.startIndex === 0;
    const atEnd = range.endIndex === list.childCount;
    const parent = $start.node(-1),
      indexBefore = $start.index(-1);

    if (
      !parent.canReplace(
        indexBefore + (atStart ? 0 : 1),
        indexBefore + 1,
        item.content.append(atEnd ? Fragment.empty : Fragment.from(list)),
      )
    )
      return false;
    const start = $start.pos,
      end = start + item.nodeSize;
    // Strip off the surrounding list. At the sides where we're not at
    // the end of the list, the existing list is closed. At sides where
    // this is the end, it is overwritten to its end.
    tr.step(
      new ReplaceAroundStep(
        start - (atStart ? 1 : 0),
        end + (atEnd ? 1 : 0),
        start + 1,
        end - 1,
        new Slice(
          (atStart ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))).append(
            atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty)),
          ),
          atStart ? 0 : 1,
          atEnd ? 0 : 1,
        ),
        atStart ? 0 : 1,
      ),
    );
    tr.scrollIntoView();
  });
  dispatch(tr);
  return true;
};

const joinBackwardsAndMergeParagraphs = autoJoin(joinBackward, ["paragraph"]);

const liftAndJoinListItems = autoJoin(liftListItemCommand, ["listItem"]);

const mergeParagraphsAfterJoiningListItems = autoJoin(liftAndJoinListItems, ["paragraph"]);

// handles a backspace event while selection is within a list item
const deleteListItem = (state: EditorState, dispatch: (tr: Transaction) => void) => {
  const { $from, $to } = state.selection;

  const isRangeSelection = !($from.pos === $to.pos);

  if (
    // selecting a range
    isRangeSelection &&
    // either side of the selection is within a list item
    ($from.node(-1)?.type === schema.nodes.listItem || $to.node(-1)?.type === schema.nodes.listItem) &&
    // not within same list item
    !$from.sameParent($to)
  ) {
    dispatch(state.tr.deleteRange($from.pos, $to.pos).scrollIntoView());
    return true;
  }

  if (
    //if not in same listItem
    !$from.sameParent($to) ||
    //if is a range selection
    isRangeSelection ||
    // block where list item is the parent
    !($from.node(-1)?.type === schema.nodes.listItem) ||
    // beginning of the listItem
    !($from.parentOffset === 0 && $from.index($from.depth - 1) === 0)
  ) {
    return false;
  }

  // first child in a top level list, just lift contents out of the list
  if ($from.node(-2).firstChild === $from.node(-1) && $from.node(-3).type !== schema.nodes.listItem)
    return liftListItemCommand(state, dispatch);

  const range = $from.blockRange($to);
  if (!range) return false;
  const target = liftTarget(range);

  // listItem in a nested list + the first child + has nested list within it
  if (!target && $from.node(-3).type === schema.nodes.listItem && $from.node(-2).firstChild === $from.node(-1)) {
    return mergeParagraphsAfterJoiningListItems(state, dispatch);
  }

  // if the listItem immediately above has greater depth than the current one we are deleting,
  //   perform a lift operation instead of merging
  const $pos = findCutBefore($from);
  const before = $pos?.nodeBefore;
  if ($pos && before && before.type === schema.nodes.listItem && before.childCount > 1) {
    return liftListItemCommand(state, dispatch);
  }

  // If backspace was pressed at last listItem, with zero indentation (listItem is not child of another listItem),
  // and cursor is placed just after bullet. Convert the listItem to paragraph.
  const parent = $from.node($from.depth - 1); //Holder for BulletItem
  const grandparent = $from.node($from.depth - 2); //Holder for BulletList

  if (
    $from.node().type === schema.nodes.paragraph &&
    parent.type === schema.nodes.listItem &&
    parent.attrs.depth === 0 &&
    $from.parentOffset === 0 &&
    grandparent.lastChild &&
    parent.eq(grandparent.lastChild)
  ) {
    const tr = state.tr;
    tr.insert($from.pos + $from.node().nodeSize + 1, schema.nodes.paragraph.create({}, $from.node().content));
    dispatch(tr.delete($from.pos - 2, $from.pos + $from.node().nodeSize));
    return true;
  }

  // merging into previous listItem on same level of depth
  return joinBackwardsAndMergeParagraphs(state, dispatch);
};

/** from https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js#L92-L98 */
function findCutBefore($cut: ResolvedPos) {
  if (!$cut.parent.type.spec.isolating)
    for (let i = $cut.depth - 1; i >= 0; i--) {
      if ($cut.index(i) > 0) return $cut.doc.resolve($cut.before(i + 1));
      if ($cut.node(i).type.spec.isolating) break;
    }
  return null;
}
