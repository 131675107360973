import { EditorView } from "prosemirror-view";
import { Node } from "prosemirror-model";
import { schema } from "../../schema";

const CLICKABLE_ITEMS = [schema.marks.link, schema.marks.hashtag];

export const handleClickOn = (
  view: EditorView,
  pos: number,
  _node: Node,
  _nodePos: number,
  _event: MouseEvent,
  _direct: boolean,
) => {
  const marks = view.state.doc.nodeAt(pos)?.marks ?? [];
  const linkMark = marks.find((m) => CLICKABLE_ITEMS.includes(m.type));
  if (linkMark) {
    // ProseMirror has a default behavior of selecting the parent node of a
    // given leaf node on Ctrl/Cmd + Click. For Ctrl/Cmd + clicks to Link
    // nodes, we want the click to move the cursor, not default to selecting the
    // parent node. Returninng true here tells PM not to do its default
    // behavior.
    return true;
  }

  return false;
};
