import { DecorationSet, Decoration } from "prosemirror-view";
import { PluginKey, Plugin, EditorState } from "prosemirror-state";
import { getDirtyNoteIds } from "../../../model/services";
import { NoteId } from "../../../../shared/types";

const DELAY = 2000;
const SHOWN_CLASS = "note-unsynced";
const HIDDEN_CLASSES = `${SHOWN_CLASS} note-unsynced-hidden`;

const timeoutRef: { current: NodeJS.Timeout | null } = { current: null };

type State = {
  noteId: NoteId;
  offset: number;
}[];

const getDomId = (noteId: NoteId) => `note-unsynced__${noteId}`;

const getNotes = (doc: EditorState["doc"]): State => {
  const noteStates: State = [];
  doc.forEach((node, offset) => noteStates.push({ noteId: node.attrs.noteId, offset }));
  return noteStates;
};

const createDom = (noteId: NoteId) => () => {
  const domId = getDomId(noteId);
  let iButton = document.getElementById(domId);

  if (iButton === null) {
    iButton = document.createElement("div");
    iButton.className = HIDDEN_CLASSES;
    iButton.id = domId;
  } else {
    const newElement = iButton.cloneNode(true);
    iButton.parentNode!.replaceChild(newElement, iButton);
  }

  Array.from(iButton.childNodes).forEach((cN) => cN.remove());

  return iButton;
};

const updateClassNames = (notes: State): void => {
  const dirtyNoteIds = getDirtyNoteIds();
  notes.forEach(({ noteId }) => {
    const domId = getDomId(noteId);
    const el = document.getElementById(domId);
    if (!el) return;
    if (dirtyNoteIds.has(noteId)) {
      el.className = SHOWN_CLASS;
    } else {
      el.className = HIDDEN_CLASSES;
    }
  });

  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  timeoutRef.current = setTimeout(() => updateClassNames(notes), DELAY);
};

export const noteUnsyncedPlugin: Plugin<State> = new Plugin({
  key: new PluginKey("unsynced"),
  state: {
    init: (_, state) => getNotes(state.doc),
    apply: (tr, pluginState) => {
      return tr.docChanged ? getNotes(tr.doc) : pluginState;
    },
  },
  props: {
    decorations(state) {
      const notes: State = (this as Plugin<State>).getState(state)!;
      const dateDecorations = notes.map(({ noteId, offset }) => {
        return Decoration.widget(offset + 1, createDom(noteId));
      });
      const decorationSet = DecorationSet.create(state.doc, dateDecorations);
      updateClassNames(notes);
      return decorationSet;
    },
  },
});
