import React from "react";
import { useAtomValue } from "jotai";
import { useScrollOffsets } from "../../../editorPage/hooks/useScrollOffsets";
import { editorViewAtom } from "../../../model/atoms";
import { AUTOCOMPLETE_MENU_ID } from "../../../utils/constants";
import { AutocompleteWrapper } from "../../../editorPage/styledComponents";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { autocompleteStateAtom, useAutocompleteSuggestions } from "./useAutocompleteState";
import { EditorSuggestionList } from "./EditorSuggestionList";
import { AUTOCOMPLETE_DECORATION_CLASS } from "./autocompletePlugin";

export default function DesktopAutocompleteMenu(props: {
  editorContainerRef: React.RefObject<HTMLDivElement>;
  autocompleteKeyDownRef: React.MutableRefObject<((event: KeyboardEvent) => boolean) | null>;
}) {
  const { editorContainerRef, autocompleteKeyDownRef } = props;
  const view = useAtomValue(editorViewAtom);
  const autocompleteState = useAtomValue(autocompleteStateAtom);
  const { isValid: areSuggestionsValid } = useAutocompleteSuggestions(autocompleteState);

  // Force a rerender when the window is resized
  // This helps the autocomplete dropdown to be positioned correctly
  useWindowDimensions();

  const editorPlacement = useScrollOffsets(editorContainerRef.current);

  // Determine the autocomplete dropdown position. We want to position it right
  // under autocomplete region autocomplete region. Sometimes we have a match
  // but no autocomplete region because the match is currently empty, in which
  // case position the dropdown relative to the cursor.
  const currentInputSpan = document.getElementsByClassName(AUTOCOMPLETE_DECORATION_CLASS)[0];
  let offset = { left: 0, bottom: 0 };
  if (currentInputSpan) {
    offset = currentInputSpan.getBoundingClientRect();
  } else if (view) {
    const pos = view.state.selection.$anchor.pos;
    offset = view.coordsAtPos(pos);
  }

  const topInScrollContainer = offset.bottom - editorPlacement.top + editorPlacement.scrollTop;

  // We don't want the suggestion list to overflow the viewport, so we set a
  // max-height based on style.top. We also don't want the suggestion list to
  // be invisible, because that's weird, so we set a minimum max-height to 32,
  // which is the height of approx. 1 suggestion
  const remainingVerticalSpaceBelow = window.innerHeight - offset.bottom - 24; // make sure there is a little padding on the bottom
  const maxHeight = `${Math.max(remainingVerticalSpaceBelow, 32)}px`;

  const styles = {
    top: `${topInScrollContainer}px`,
    left: `${offset.left}px`,
    maxHeight,
    maxWidth: "100%",
    display: "block",
  };
  const className = "suggestion-item-list-container" + (areSuggestionsValid ? "" : " disabled");
  return (
    <PreventScrollFromAbsolutelyPositionedContent>
      <div className={className} style={styles} id={AUTOCOMPLETE_MENU_ID}>
        <EditorSuggestionList autocompleteKeyDownRef={autocompleteKeyDownRef} />
      </div>
    </PreventScrollFromAbsolutelyPositionedContent>
  );
}

function PreventScrollFromAbsolutelyPositionedContent({ children }: { children: React.ReactNode }) {
  const divRef = React.useRef<HTMLDivElement>(null);
  return (
    <AutocompleteWrapper
      ref={divRef}
      onScroll={() => {
        const el = divRef.current;
        if (el) {
          el.scrollLeft = 0;
        }
      }}
    >
      {children}
    </AutocompleteWrapper>
  );
}
