import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useAtom, useSetAtom } from "jotai";
import { HelpCircle, Sliders } from "react-feather";
import { colors, mediaQueries } from "../utils/style";
import { useHotkeys } from "../shortcuts/useHotkeys";
import { useShortcuts } from "../shortcuts/useShortcuts";
import { getShortcutString } from "../shortcuts/shortcuts";
import { isSidebarOpenAtom, disableSidebarAnimationAtom } from "../model/atoms";
import { ModalEnum, openModal, toggleModal } from "../model/modals";
import { isGreaterThanMobileWidth, isTouchDevice } from "../utils/environment";
import { trackEvent } from "../analytics/analyticsHandlers";
import { SidebarContent } from "./SidebarContent";

const SidebarContentContainer = styled.div`
  overflow-y: scroll;
  max-height: 100%;
  padding: 8px 16px;
  flex-grow: 1;
`;

const SidebarContainer = styled.div<{ showSidebar: boolean; disableAnimation: boolean }>(
  (props) => `
  flex-grow: 0;
  flex-shrink: 0;
  width: 300px;
  position: absolute;
  display: flex;
  top: var(--header-height);
  bottom: 0;
  background: ${colors.bg.secondary};
  border: 0;
  border-right: 1px solid ${colors.border.secondarySolid};
  list-style-type: none;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  transform: ${props.showSidebar ? "translateX(0px)" : "translateX(calc(-100% - 8px))"};
  ${
    props.disableAnimation
      ? ``
      : `
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;`
  }
`,
);

const SidebarFooter = styled.div`
  text-decoration: none;
  padding: 0.75rem 1.2rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  gap: 24px;
  flex-direction: row;
  border-top: 1px solid ${colors.border.secondary};
  bottom: 0;
  width: 100%;
`;

const SidebarFooterItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: ${colors.text.secondary};
  display: flex;
  align-items: center;
  span {
    padding-left: 0.5rem;
  }
  padding: 0.25rem 0;
  :hover {
    color: ${colors.text.primary};
  }
`;

const HelpButton = React.memo(() => {
  const { shortcuts } = useShortcuts();
  const toggleHelp = () => toggleModal(ModalEnum.HELP, { openCallback: () => trackEvent("open_help_from_sidebar") });

  return (
    <SidebarFooterItem
      onClick={isTouchDevice ? () => {} : toggleHelp}
      onTouchStart={isTouchDevice ? toggleHelp : () => {}}
    >
      <>
        <HelpCircle size={16} />
        <span
          role="tooltip"
          id="sidebar-help-button"
          data-microtip-position="top-right"
          aria-label={`Toggle help menu ${getShortcutString(shortcuts.toggleHelp)}`}
        >
          Help
        </span>
      </>
    </SidebarFooterItem>
  );
});

const SettingsCogButton = React.memo(() => {
  const { shortcuts } = useShortcuts();
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);

  const tooltip = `Open Settings ${getShortcutString(shortcuts.toggleSettings)}`;

  const openSettingsCallback = (triggeredBy: "hotkeys" | "sidebar") => {
    if (!isGreaterThanMobileWidth) setShowSidebar(false);
    trackEvent(`open_settings_from_${triggeredBy}`);
  };

  useHotkeys(shortcuts.toggleSettings, () => {
    toggleModal(ModalEnum.SETTINGS, { openCallback: () => openSettingsCallback("hotkeys") });
  });

  const openSettingsFromClick = () => {
    openModal(ModalEnum.SETTINGS, { openCallback: () => openSettingsCallback("sidebar") });
  };

  return (
    <SidebarFooterItem
      onClick={isTouchDevice ? () => {} : openSettingsFromClick}
      onTouchStart={isTouchDevice ? openSettingsFromClick : () => {}}
    >
      <Sliders size={16} />
      <span aria-label={tooltip} role="tooltip" data-microtip-position="top-right">
        Settings
      </span>
    </SidebarFooterItem>
  );
});

const BlackFilter = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  opacity: 0.2;
  top: var(--header-height);
  background-color: black;
  z-index: 5;
  display: none;
  ${mediaQueries.medium} {
    display: flex;
  }
`;

/** Mostly CSS and style components, the majority of the logic is in
 * SidebarContent
 */
const Sidebar: React.FC = () => {
  const [showSidebar, setShowSidebar] = useAtom(isSidebarOpenAtom);
  const disableSidebarAnimation = useAtom(disableSidebarAnimationAtom)[0];

  // If the user "tabs" into the sidebar from e.g. the search bar, we want
  // the sidebar to reveal itself without needing to be toggled explicitly
  const [showSidebarFromFocus, setShowSidebarFromFocus] = useState(false);

  const shouldShowSidebar = showSidebar || showSidebarFromFocus;

  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarFirstFocusableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // When the user closes the sidebar, we want to move focus out of the
    // sidebar, and when the user opens we want to move focus into it.
    const isFocusInSidebar = sidebarRef.current?.contains(document.activeElement);
    if (showSidebar && !isFocusInSidebar) {
      sidebarFirstFocusableRef.current?.focus();
      return;
    }
    if (!showSidebar && isFocusInSidebar && document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [showSidebar, sidebarRef, sidebarFirstFocusableRef]);

  const onBlackFilterClick = () => setShowSidebar(false);

  useEffect(() => {
    const updateFocusState = () => {
      const isFocusWithin = document.activeElement && sidebarRef.current?.contains(document.activeElement);
      setShowSidebarFromFocus(!!isFocusWithin);
    };
    window.addEventListener("focus", updateFocusState, true);
    window.addEventListener("blur", updateFocusState, true);
    return () => {
      window.removeEventListener("focus", updateFocusState, true);
      window.removeEventListener("blur", updateFocusState, true);
    };
  }, [setShowSidebarFromFocus]);

  return (
    <>
      {showSidebar && <BlackFilter onClick={onBlackFilterClick} />}
      <SidebarContainer
        role="complementary"
        id={shouldShowSidebar ? "sidebar-visible" : "sidebar-hidden"} // for testcafe
        ref={sidebarRef}
        showSidebar={shouldShowSidebar}
        disableAnimation={disableSidebarAnimation}
      >
        <SidebarContentContainer>
          <SidebarContent sidebarFirstFocusableRef={sidebarFirstFocusableRef} />
        </SidebarContentContainer>
        <SidebarFooter>
          <HelpButton />
          <SettingsCogButton />
        </SidebarFooter>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
