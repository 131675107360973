import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { appNoteStore } from "../../../model/services";
import { domAttrs } from "../../utils/attrs";
import { nonCustomizableShortcuts } from "../../../shortcuts/rawShortcuts";
import { getShortcutString } from "../../../shortcuts/shortcuts";
import { isCircularReference, toggleReference } from "./referenceExpansionUtils";

function setAttributes(el: HTMLElement, attrs: any) {
  for (const key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

const nbsp = "\u00a0";

const innerDom = (content: HTMLSpanElement): DocumentFragment => {
  // Wrap the reference span in special no-break spaces to make it a better click/tap target.
  // Implementation copied from entityInnerDOM
  const frag = document.createDocumentFragment();
  const startSpan = document.createElement("span");
  startSpan.style.whiteSpace = "nowrap";
  startSpan.textContent = nbsp;

  frag.appendChild(startSpan);
  frag.append(content, nbsp);
  return frag;
};

export class ReferenceView implements NodeView {
  dom: HTMLElement;
  constructor(node: ProsemirrorNode, view: EditorView, getPos: () => number | undefined) {
    this.dom = document.createElement("span");
    this.dom.className = "reference embed";

    setAttributes(this.dom, domAttrs(node, node.attrs));
    this.dom.append(innerDom(node.attrs.content));

    const pos = getPos();
    if (node.attrs.isPrivate) {
      this.dom.classList.add("is-private");
      this.dom.setAttribute("role", "tooltip");
      this.dom.setAttribute("data-microtip-position", "top");
      this.dom.setAttribute("aria-label", "Can't expand private note");
    } else if (pos && isCircularReference(view.state.doc.resolve(pos))) {
      this.dom.classList.add("not-clickable");
      setAttributes(this.dom, {
        role: "ios-tooltip tooltip",
        "aria-label": "Cannot expand circular relation",
        "data-microtip-position": "top",
      });
    } else {
      this.dom.classList.add("clickable");
      this.dom.role = "tooltip";
      this.dom.setAttribute("data-microtip-position", "top");
      this.dom.setAttribute(
        "aria-label",
        node.attrs.isExpanded
          ? `Collapse ${getShortcutString(nonCustomizableShortcuts.collapseReference)}`
          : `Expand ${getShortcutString(nonCustomizableShortcuts.expandReference)}`,
      );
    }

    this.dom.onclick = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (node.attrs.isPrivate) return;
      const note = appNoteStore.get(node.attrs.linkedNoteId);
      // Do not expand deleted notes. We now keep the deleted notes in the noteStore
      // so it's not enough to check if they are present
      if (!note || note.deletedAt !== null) {
        return;
      }
      if (document.activeElement) {
        // eslint-disable-next-line
        // @ts-ignore
        document.activeElement.blur();
      }
      toggleReference(view.state, view.dispatch, getPos());
    };
  }
}
