import { isFrontend, isRunningInIOSWebview } from "../../../utils/environment";
import { importWorkerURLHack } from "../../../utils/importWorkerURLHack";

export const audioWorkletURL = importWorkerURLHack(() => new Worker(new URL("./audioWorklet", import.meta.url)));
export const encoderWorkerURL = importWorkerURLHack(() => new Worker(new URL("./encoderWorker", import.meta.url)));

// When running under a ServiceWorker environment we want to request the audio workers scripts to warm up the SW cache
if (isFrontend && !isRunningInIOSWebview) {
  setTimeout(async () => {
    await fetch(audioWorkletURL);
    await fetch(encoderWorkerURL);
  }, 2_000);
}
