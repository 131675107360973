import { MinusCircle } from "react-feather";
import { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { useAtomValue } from "jotai";
import type { NodeViewProps } from "../utils";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { addToast } from "../../../components/Toast";
import { copyTextToClipboard } from "../../../export/copyToClipboard";
import { downloadOrShare } from "../../../utils/downloadOrShare";
import { isLocal } from "../../../utils/environment";
import { colors } from "../../../utils/style";
import logger from "../../../utils/logger";
import { ModalEnum, openModal } from "../../../model/modals";
import { RecordingControlUI } from "../../../editorPage/header/RecordingControlUI";
import { isOnlineAtom } from "../../../model/atoms";
import { ApiClient } from "../../../api/client";
import { AudioPlayer } from "./AudioPlayer";
import { audioLogsPrefix, debouncedUploadAllAudio, formatISOToUSDateTime, getAudioURLs } from "./utils";
import { useLocalAudio, useTranscript } from "./hooks";
import { globalAudioRecordingCoordinator } from "./audioRecordingCoordinator";
import type { AudioAttrs } from "./audioTypes";
import { createTranscriptCallback, insertTranscriptIntoNote, parseTranscriptAndInsert } from "./transcript";
import { Stage } from "./audioTypes";
import AudioInsertViewCollapsed from "./AudioInsertViewCollapsed";
import { AudioActivityVisualization } from "./AudioActivityVisualization";
import { Timer } from "./Timer";

enum TranscriptState {
  None,
  Offline,
  Waiting,
  Complete,
  Error,
}

enum ParseWithAIState {
  None,
  WaitingForTranscript,
  Parsing,
  Complete,
  Error,
}

export function AudioInsertViewWithLocalCache({
  attrs,
  setAttrs,
  editorView,
  getPos,
  replaceSelfWith,
}: NodeViewProps<AudioAttrs>) {
  const localAudio = useLocalAudio(attrs.audioId, [attrs.updatedAt]);
  const { audioId, transcript, transcriptLanguageOverride, transcriptGenId, chunkCount, durations } = attrs;
  const audioInsertLogger = useMemo(
    () => logger.with({ namespace: `${audioLogsPrefix}/audio-insert/${audioId}` }),
    [audioId],
  );

  const isOnline = useAtomValue(isOnlineAtom);
  const recordingState = globalAudioRecordingCoordinator.useRecordingState();

  const [stage, setStage] = useState<Stage>(
    recordingState?.audioId === audioId
      ? Stage.Recording
      : chunkCount === null && durations === null
        ? Stage.MissingRecording
        : Stage.Viewing,
  );

  let initialTranscriptState;
  if (transcript) {
    initialTranscriptState = TranscriptState.Complete;
  } else if (transcriptGenId) {
    initialTranscriptState = TranscriptState.Waiting;
  } else {
    initialTranscriptState = TranscriptState.None;
  }

  const [transcriptState, setTranscriptState] = useState(initialTranscriptState);
  const [parseWithAIState, setParseWithAIState] = useState(ParseWithAIState.None);
  const shouldParseAfterTranscript = useRef(false);
  const [showDebugChunks, setShowDebugChunks] = useState(false);
  const volumeValues = globalAudioRecordingCoordinator.useVolumeValues();
  const createTranscript = useCallback(
    async (chunkCount: number) => {
      try {
        await createTranscriptCallback(chunkCount, audioId, audioInsertLogger, transcriptLanguageOverride, setAttrs);
        setTranscriptState(TranscriptState.Waiting);
      } catch (e) {
        if (!isOnline) {
          setTranscriptState(TranscriptState.Offline);
        } else {
          setTranscriptState(TranscriptState.Error);
        }
      }
    },
    [audioId, audioInsertLogger, transcriptLanguageOverride, isOnline, setAttrs],
  );

  useEffect(() => {
    if (isOnline && transcriptState === TranscriptState.Offline) {
      createTranscript(chunkCount);
    }
  }, [isOnline, transcriptState, chunkCount, createTranscript]);

  const parseWithAI = useCallback(() => {
    if (transcriptState === TranscriptState.None || transcriptState === TranscriptState.Error) {
      setParseWithAIState(ParseWithAIState.WaitingForTranscript);
      shouldParseAfterTranscript.current = true;
      createTranscript(chunkCount);
    } else if (transcriptState === TranscriptState.Complete) {
      setParseWithAIState(ParseWithAIState.Parsing);
      parseTranscriptAndInsert(transcript!, editorView, getPos, () => setParseWithAIState(ParseWithAIState.Complete));
    } else if (transcriptState === TranscriptState.Waiting) {
      setParseWithAIState(ParseWithAIState.WaitingForTranscript);
      shouldParseAfterTranscript.current = true;
    }
    // eslint-disable-next-line
  }, [transcriptState, createTranscript, chunkCount, transcript, editorView]);

  globalAudioRecordingCoordinator.stopListeners.useRegisterListener(audioId, () => {
    audioInsertLogger.info("Recording stopped");
    setStage(Stage.Saving);
  });

  globalAudioRecordingCoordinator.savedListeners.useRegisterListener(audioId, ({ chunkCount }) => {
    audioInsertLogger.info("Recording saved");
    createTranscript(chunkCount);
    setStage(Stage.Viewing);
  });

  useTranscript(
    transcriptGenId,
    useCallback(
      (transcript) => {
        // Only insert the transcript on the tab which requested it. This prevents
        // the transcript from being inserted multiple times if you have multiple
        // tabs open at the same time.
        const shouldInsert = transcriptState === TranscriptState.Waiting;
        if (transcript === null) {
          setTranscriptState(TranscriptState.Error);
          setAttrs({ transcriptGenId: null, updatedAt: Date.now() });
          return;
        }
        setTranscriptState(TranscriptState.Complete);
        setAttrs({
          transcript,
          updatedAt: Date.now(),
          isRevealed: false,
        });
        if (shouldInsert) {
          setAttrs({
            transcriptGenId: null,
          });
          insertTranscriptIntoNote(transcript, getPos, editorView, audioInsertLogger);
          if (shouldParseAfterTranscript.current) {
            shouldParseAfterTranscript.current = false;
            setParseWithAIState(ParseWithAIState.Parsing);
            parseTranscriptAndInsert(transcript!, editorView, getPos, () =>
              setParseWithAIState(ParseWithAIState.Complete),
            );
          }
        }
      },
      // eslint-disable-next-line
      [audioInsertLogger, editorView, parseWithAI, parseWithAIState, transcriptState],
    ),
  );

  if (!localAudio) {
    return <div></div>;
  }

  const audioURLs = getAudioURLs(audioId, stage, localAudio, chunkCount, durations);
  const menuItems = [
    {
      text: "Parse with AI",
      onClick: () => parseWithAI(),
    },
    {
      text: "Regenerate transcript",
      onClick: () => createTranscript(chunkCount),
    },
    {
      text: "Regenerate transcript (no split paragraphs)",
      onClick: () => createTranscript(chunkCount),
    },
    {
      text: "Copy full transcript",
      // @todo don't show if transcript is missing
      onClick: () => {
        copyTextToClipboard(transcript ?? "");
        addToast("Transcript copied to clipboard!");
      },
    },
    {
      text: "Delete audio",
      onClick: () => {
        replaceSelfWith([]);
      },
    },
    {
      text: "Download audio",
      onClick: async () => {
        const allChunks = [];
        for (const [, { url }] of audioURLs.entries()) {
          const chunk = await (await fetch(url)).arrayBuffer();
          allChunks.push(chunk);
        }
        const blob = new Blob(allChunks, { type: "audio/mpeg" });
        const fileName = audioURLs[0].url.split("/").pop();
        let exportName = "audio-file.mp3";
        if (fileName) {
          const { creationDate } = await ApiClient().files.getMetadata(fileName);
          exportName = `audio-${formatISOToUSDateTime(creationDate)}.mp3`;
        }
        const file = new File([blob], exportName, {
          type: "audio/mpeg",
        });
        downloadOrShare(file);
      },
    },
    ...(isLocal
      ? [
          {
            text: "Toggle debug chunks",
            onClick: () => setShowDebugChunks((v) => !v),
          },
        ]
      : []),
  ];

  if (!attrs.isRevealed) {
    return (
      <AudioInsertViewCollapsed
        attrs={attrs}
        setAttrs={setAttrs}
        menuItems={menuItems}
        parseWithAI={parseWithAIState === ParseWithAIState.None ? parseWithAI : null}
      ></AudioInsertViewCollapsed>
    );
  }

  if ((stage === Stage.BrokenAudio || !audioURLs) && !isLocal) {
    logger.error("There was a problem loading the audio.", {
      context: {
        attrs,
      },
    });
  }

  return (
    <div
      className="audio-insert-view"
      // Prevent clicks on buttons from selecting the audio insert node
      onMouseDown={(e) => {
        if (!(e.target instanceof HTMLElement)) return;
        if (["A", "BUTTON", "AUDIO", "INPUT"].includes(e.target.tagName)) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {stage === Stage.Viewing && (
        <div
          style={{ position: "absolute", color: colors.text.accent, left: "-30px", top: "25px", cursor: "pointer" }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAttrs({ isRevealed: false });
          }}
        >
          <MinusCircle size={20}></MinusCircle>
        </div>
      )}
      {stage === Stage.Recording && (
        <>
          <div className="audio-recorder">
            <RecordingControlUI />
            <AudioActivityVisualization volumes={volumeValues} />
            <Timer lastStartupTime={recordingState?.lastStartupTime ?? null} />
          </div>
          <div style={{ fontStyle: "italic", textAlign: "center", fontSize: "12px", opacity: 0.7 }}>
            You can say: "new entry", "hashtag book" → #book, "at-sign mom" → #@mom,{" "}
            <span
              className="link"
              onClick={() => openModal(ModalEnum.HELP, { openCallback: () => trackEvent("open_help_from_audio") })}
            >
              and more
            </span>
          </div>
        </>
      )}
      {stage === Stage.MissingRecording && (
        <div className="audio-insert-message">
          <span style={{ fontStyle: "italic" }}>
            Recording missing. Either it's active in another tab, or it was interrupted before saving.
          </span>
        </div>
      )}
      {stage === Stage.Saving && (
        <div className="audio-insert-message">
          <span>Saving audio...</span>
        </div>
      )}
      {stage === Stage.Viewing && (
        <AudioPlayer
          urls={audioURLs}
          onError={(e) => {
            audioInsertLogger.error("Audio playback failed", { error: e });
            setStage(Stage.BrokenAudio);
          }}
          showDebugChunks={showDebugChunks}
          menuItems={menuItems}
        >
          {transcriptState === TranscriptState.Waiting && <span>Transcribing...</span>}
          {(transcriptState === TranscriptState.None ||
            transcriptState === TranscriptState.Error ||
            transcriptState === TranscriptState.Offline) && (
            <button className="audioInsertButton" onClick={() => createTranscript(chunkCount)}>
              Transcribe
            </button>
          )}
          {parseWithAIState === ParseWithAIState.Parsing ||
          parseWithAIState === ParseWithAIState.WaitingForTranscript ? (
            <span>Parsing with AI...</span>
          ) : (
            <button className="audioInsertButton" onClick={parseWithAI}>
              Parse with AI
            </button>
          )}
          {localAudio.length > 0 && (
            <button className="audioInsertButton" onClick={() => debouncedUploadAllAudio()}>
              Retry
            </button>
          )}
        </AudioPlayer>
      )}
      {(stage === Stage.BrokenAudio || !audioURLs) && (
        // Need more specific error message here
        <div className="audio-insert-message">
          <span>
            There was a problem loading the audio.
            <a
              onClick={() => {
                setAttrs({ updatedAt: Date.now() });
                setStage(Stage.Viewing);
              }}
              style={{
                color: colors.text.accent,
                textDecoration: "underline",
                marginLeft: 10,
                cursor: "pointer",
              }}
            >
              Click here to retry.
            </a>
          </span>
        </div>
      )}
      {isLocal && (
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        >
          <select
            value={stage}
            onChange={(e) => {
              try {
                setStage(parseInt(e.target.value));
              } catch (error) {
                audioInsertLogger.error("Error changing stage", { error, context: { value: e.target.value } });
              }
            }}
          >
            <option value={Stage.Recording}>Recording</option>
            <option value={Stage.MissingRecording}>MissingRecording</option>
            <option value={Stage.Saving}>Uploading</option>
            <option value={Stage.Viewing}>Viewing</option>
            <option value={Stage.BrokenAudio}>BrokenAudio</option>
          </select>
        </div>
      )}
    </div>
  );
}
