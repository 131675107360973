import { useCallback, useEffect } from "react";
import { useAtomValue } from "jotai";
import { editorViewAtom } from "../../model/atoms";
import { AUTOCOMPLETE_MENU_ID, EDITOR_ID, HEADER_ID, MOBILE_BAR_ID } from "../../utils/constants";
import { isTouchDevice } from "../../utils/environment";
import { selectionAfterCreateNote } from "./useCreateNote";

/**
 * Keeps the caret in view when the user edits their notes or moves the
 * selection. This is only used on touch devices.
 */
export function useKeepCaretInView() {
  const editorView = useAtomValue(editorViewAtom);
  const scrollIntoView = useCallback(() => {
    if (!editorView?.hasFocus()) return;
    // Don't scroll if there's a selection. Without this, when you select-all on
    // iOS, the scroll will shoot down to the bottom of the page (see
    // selectAllPlugin). I think it also makes sense that we don't mess with the
    // scroll while the user is selecting text.
    const selection = document.getSelection();
    if (selection && !selection.isCollapsed) return;

    // Get the selection, mobile keyboard bar, and header rects
    const editor = document.getElementById(EDITOR_ID);
    const selRect = getSelectionRect();
    const barRect = document.getElementById(MOBILE_BAR_ID)?.getBoundingClientRect();
    const headerRect = document.getElementById(HEADER_ID)?.getBoundingClientRect();
    if (!editor || !selRect || !barRect?.top || !headerRect?.bottom) return;

    // Get the autocomplete menu rect (if autocomplete is visible)
    const autocompleteRect = document.getElementById(AUTOCOMPLETE_MENU_ID)?.getBoundingClientRect();

    // Adjust the selection if it's above/below the header/bar
    const selCurrent = editorView.state.selection;
    const selNewNote = selectionAfterCreateNote(editorView.state.doc);
    if (selNewNote && selCurrent.from === selNewNote.from && selCurrent.to === selNewNote.to) {
      // If useCreateNote was used to place the selection at the top of the
      // note, we don't want to scroll. The scroll is handled by
      // handleScrollToSelection in that case. I initially tried moving that
      // logic here, but it resulted in a weird flicker when creating a note.
      return;
    } else if (autocompleteRect && selRect.bottom > autocompleteRect.top) {
      const diff = selRect.bottom - autocompleteRect.top;
      editor.scrollBy({ top: diff + 20, behavior: "smooth" });
    } else if (selRect.bottom > barRect.top) {
      const diff = selRect.bottom - barRect.top;
      editor.scrollBy({ top: diff + 20, behavior: "smooth" });
    } else if (selRect.top < headerRect.bottom + 10) {
      editor.scrollBy({ top: -(headerRect.bottom - selRect.top + 30), behavior: "instant" });
    }
  }, [editorView]);
  useEffect(() => {
    if (!isTouchDevice) return;
    window.visualViewport?.addEventListener("resize", scrollIntoView);
    document.addEventListener("selectionchange", scrollIntoView);
    return () => {
      window.visualViewport?.removeEventListener("resize", scrollIntoView);
      document.removeEventListener("selectionchange", scrollIntoView);
    };
  }, [scrollIntoView]);
}

/**
 * Get the bounding rect of the current selection, or null if one cannot be
 * found.
 *
 * It first tries to get the bounding rect of the current selection in the DOM.
 * If that returns a zero-coordinates selection, it traverse up the DOM tree
 * until we find a non-zero bounding rect.
 */
function getSelectionRect() {
  const selection = document.getSelection();
  if (!selection || selection.rangeCount === 0) return null;
  const range = selection.getRangeAt(0);
  const rect = range.getBoundingClientRect();
  if (rect.x !== 0 || rect.y !== 0) return rect;
  let parentNode: Node | null = range.commonAncestorContainer;
  while (parentNode) {
    if (parentNode.nodeType === Node.ELEMENT_NODE) {
      const rect = (parentNode as Element).getBoundingClientRect();
      if (rect.x !== 0 || rect.y !== 0) return rect;
    }
    parentNode = parentNode.parentNode;
  }
  return null;
}
