import styled from "@emotion/styled";
import { atom, getDefaultStore, useAtom } from "jotai";
import { feedbackIntegration } from "@sentry/react";
import Image from "next/image";
import { useEffect } from "react";
import { useColorScheme } from "../settings/ColorScheme";
import { Shortcut } from "../../shared/types";
import { GenericSearchBar } from "../components/GenericSearchBar";
import { Line } from "../components/Line";
import { Title } from "../components/Title";
import { redisplayVideoAtom } from "../components/UserOnboarding";
import { ModalEnum, closeModal } from "../model/modals";
import { upsertUserSettings } from "../model/userSettings";
import { useShortcuts } from "../shortcuts/useShortcuts";
import { CHANGELOG_URL, HELP_SEARCH_ID, ONBOARDING_GUIDE_URL } from "../utils/constants";
import { colors, mediaQueries } from "../utils/style";
import { isRunningInIOSWebview, publicVersion } from "../utils/environment";
import ShortcutEntry from "./ShortcutEntry";
import { Logo } from "../components/Icons/Logo";

const helpSearchAtom = atom("");
export const setHelpSearch = (update: string | ((prev: string) => string)) => {
  return getDefaultStore().set(helpSearchAtom, update);
};

export type HelpContentProps = {
  /** The `id` property of the element to scroll to */
  scrollTo?: "keyboard-shortcuts";
};

export const HelpContent = ({ scrollTo }: HelpContentProps) => {
  const { shortcuts, nCustomShortcuts } = useShortcuts();
  const [search, setSearch] = useAtom(helpSearchAtom);
  const setRedisplayVideo = useAtom(redisplayVideoAtom)[1];
  const colorScheme = useColorScheme();

  const feedback = feedbackIntegration({
    autoInject: false,
    showName: false,
    showEmail: false,
  });

  const showFeedback = (e: React.MouseEvent) => {
    e.preventDefault();
    feedback.openDialog();
  };

  const contact = [
    {
      key: "👋 Feedback",
      value: (
        <a href="#" onClick={showFeedback} className="link">
          👋 Feedback
        </a>
      ),
    },
    {
      key: "💬 Email us",
      value: (
        <a href="mailto:support@ideaflow.io" target="_blank" rel="noreferrer" className="link">
          💬 Email us
        </a>
      ),
    },
    {
      key: "🗣️ Join our Slack",
      value: (
        <a
          href="https://join.slack.com/t/ideaflowuserhq/shared_invite/zt-1qztlpjyp-0BrJTU5YMkIO7jCArygZgg"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          🗣️ Join our Slack
        </a>
      ),
    },
  ];

  const links = [
    {
      key: "🎥 Quickstart Video",
      value: (
        <button
          onClick={() => {
            closeModal(ModalEnum.HELP);
            setRedisplayVideo(true);
          }}
          className="link"
          style={{ border: "none", display: "inline-block" }}
        >
          🎥 Quickstart Video
        </button>
      ),
    },
    {
      key: "Onboarding Docs",
      value: (
        <a className="link" href={ONBOARDING_GUIDE_URL} target="_blank" rel="noreferrer">
          Onboarding Docs
        </a>
      ),
    },
    {
      key: "Guided Tutorial",
      value: (
        <span>
          <button
            onClick={() => {
              closeModal(ModalEnum.HELP);
              setRedisplayVideo(false);
              upsertUserSettings({ seenOnboarding: false });
            }}
            className="link"
            style={{ border: "none", display: "inline-block" }}
          >
            Guided Tutorial
          </button>
        </span>
      ),
    },
    {
      key: "Homepage",
      value: (
        <span>
          <a className="link" href="https://ideaflow.io/" target="_blank" rel="noreferrer">
            Homepage
          </a>
          <span style={{ paddingInline: "6px" }}>·</span>
          <a className="link" href={CHANGELOG_URL} target="_blank" rel="noreferrer">
            Changelog
          </a>
        </span>
      ),
    },
  ] as const;

  const basics = [
    {
      title: "#️⃣ Hashtags",
      content: "Insert hashtags like #book to tag notes into overlapping categories",
    },
    {
      title: "🔗 Note Relations",
      content: "Press “+” to link to related notes",
    },
    {
      title: "⬅️ Backlinks",
      content: "Links to a note are displayed in a section at the bottom.",
    },
    {
      title: "📁 Folders",
      content: "Add notes to folders to organize notes into a hierarchies.",
    },
  ] as const;

  const searchhelp = [
    {
      searchterm: "tag:#book",
      searchresult: "Hashtags",
    },
    {
      searchterm: "has:recording",
      searchresult: "Audio notes",
    },
    {
      searchterm: "has:image",
      searchresult: "Notes that contain images",
    },
    {
      searchterm: "is:public",
      searchresult: "Public (shared) notes",
    },
    {
      searchterm: "is:unsynced",
      searchresult: "Unsynced notes",
    },
    {
      searchterm: "is:untagged",
      searchresult: "Notes without hashtags",
    },
    {
      searchterm: "has:incomplete",
      searchresult: "To-dos",
    },
    {
      searchterm: '"[ ]"',
      searchresult: "To-dos (alternate syntax)",
    },
    {
      searchterm: '"[x]"',
      searchresult: "Completed to-dos",
    },
  ] as const;

  const audioShortcuts = [
    {
      key: `"new line"`,
      value: "add a line break to the current note",
    },
    {
      key: `"new entry"`,
      value: "make a new separate note",
    },
    {
      key: `"hashtag"`,
      value: "hashtag the next word",
    },
    {
      key: `"at sign"`,
      value: (
        <span>
          #@mention a person or organization you want to remember to say something to. See{" "}
          <a href="https://ideaflow.app/shared/ocRgDOeque" target="_blank" rel="noreferrer" className="link">
            @&nbsp;Mention Convention (#@)
          </a>
        </span>
      ),
    },
    {
      key: `"top entry"`,
      value: "adds a new note at the top",
    },
  ];

  const filteredContact = contact.filter((row) => {
    const text = ["link", row.key, row.value].join(" ");
    return text.toLowerCase().includes(search.toLowerCase());
  });

  const filteredLinks = links.filter((row) => {
    const text = ["link", row.key, row.value].join(" ");
    return text.toLowerCase().includes(search.toLowerCase());
  });

  const filteredBasics = basics.filter((basic) => {
    const text = basic.title + basic.content;
    return text.toLowerCase().includes(search.toLowerCase());
  });

  const filteredSearchHelp = searchhelp.filter((searchhelp) => {
    const text = ["search", "filter", searchhelp.searchterm, searchhelp.searchresult].join(" ");
    return text.toLowerCase().includes(search.toLowerCase());
  });

  const filteredCustomizableShortcuts = Object.values(shortcuts).filter(
    (s: Shortcut) => s.isCustomizable && !!s.label && s.label.toLowerCase().includes(search.toLowerCase()),
  );

  const filteredStaticShortcuts = Object.values(shortcuts).filter(
    (s: Shortcut) => !s.isCustomizable && !!s.label && s.label.toLowerCase().includes(search.toLowerCase()),
  );

  const filteredAudioShortcuts = audioShortcuts.filter((row) => {
    const text = [row.key, row.value].join(" ");
    return text.toLowerCase().includes(search.toLowerCase());
  });

  useEffect(() => {
    if (scrollTo) document.getElementById(scrollTo)?.scrollIntoView();
  }, [scrollTo]);

  return (
    <Content className="help-content">
      <header>
        <GenericSearchBar
          id={HELP_SEARCH_ID}
          height={30}
          inputText={search}
          setSearch={setSearch}
          placeholder={"Search Help"}
          clear={() => setSearch("")}
        />
      </header>
      <main>
        {filteredContact.length > 0 && (
          <>
            <Title>Contact Us</Title>
            {filteredContact.map((contact) => {
              return (
                <div key={contact.key}>
                  <span>{contact.value}</span>
                </div>
              );
            })}
          </>
        )}
        {filteredLinks.length > 0 && (
          <>
            <Title>Getting Started</Title>
            {filteredLinks.map((contact) => {
              return (
                <div key={contact.key}>
                  <span>{contact.value}</span>
                </div>
              );
            })}
          </>
        )}
        {filteredBasics.length > 0 && (
          <>
            <Line />
            <Title>Basics</Title>
            {filteredBasics.map(({ title, content }) => {
              return (
                <div key={title} style={{ margin: "5px 0" }}>
                  <span>
                    <b>{title}: </b>
                  </span>
                  <span>{content}</span>
                </div>
              );
            })}
          </>
        )}
        {filteredAudioShortcuts.length > 0 && (
          <>
            <Title>Audio shortcuts</Title>
            {filteredAudioShortcuts.map((contact) => {
              return (
                <div key={contact.key}>
                  <b>{contact.key}: </b>
                  <span>{contact.value}</span>
                </div>
              );
            })}
          </>
        )}
        {filteredSearchHelp.length > 0 && (
          <>
            <Line />
            <Title>Search</Title>
            <div style={{ margin: "5px 0" }}>
              You can search by title, content or tag. Additionally, the following search filters are also available:
            </div>
            {filteredSearchHelp.map(({ searchterm, searchresult }) => {
              return (
                <SearchHelpRow key={searchterm}>
                  <div style={{ minWidth: "7.5em", marginRight: "8px" }}>
                    <div className="search-term">{searchterm}</div>
                  </div>
                  <div>{searchresult}</div>
                </SearchHelpRow>
              );
            })}
          </>
        )}
        {(filteredCustomizableShortcuts.length > 0 || filteredStaticShortcuts.length > 0) && (
          <>
            <Line />
            <Title id="keyboard-shortcuts">
              Keyboard shortcuts&nbsp;
              {nCustomShortcuts > 0 && (
                <small
                  onClick={() => upsertUserSettings({ customShortcuts: {} })}
                  style={{ color: colors.text.accent, cursor: "pointer" }}
                >
                  ·&nbsp;reset custom shortcuts
                </small>
              )}
            </Title>
            {filteredCustomizableShortcuts.map((shortcut) => {
              return (
                <div key={shortcut.id} style={{ margin: "15px 0" }}>
                  <ShortcutEntry shortcut={shortcut} />
                </div>
              );
            })}
            {filteredCustomizableShortcuts.length > 0 && filteredStaticShortcuts.length > 0 && <Line />}
            {filteredStaticShortcuts.map((shortcut) => {
              return (
                <div key={shortcut.id} style={{ margin: "15px 0" }}>
                  <ShortcutEntry shortcut={shortcut} />
                </div>
              );
            })}
            <Line />
            <div>Version: {publicVersion}</div>
            <a href="https://ideaflow.io" target="_blank" rel="noopener noreferrer" style={{ marginTop: "10px" }}>
              {isRunningInIOSWebview ? (
                <Logo fill={colorScheme !== "dark" ? "black" : "white"} height="20" />
              ) : (
                <Image
                  alt="logo"
                  src={colorScheme !== "dark" ? "/logo-light.svg" : "/logo-dark.svg"}
                  width="120px"
                  height="24px"
                ></Image>
              )}
            </a>
          </>
        )}
      </main>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  header {
    margin: 3px;
    padding-bottom: 5px;
    padding-right: 20px;
  }
  hr {
    margin: 10px 0;
  }
  .help-item {
    margin: 15px 0;
  }
  a {
    color: ${colors.text.secondary};
  }
`;

const SearchHelpRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${mediaQueries.mobile} {
    float: initial;
    width: 100%;
  }
  .search-term {
    font-size: 14px;
    border-radius: 4px;
    border: 0;
    background: ${colors.bg.secondary};
    color: ${colors.text.primary};
    padding: 4px 8px;
    margin: 10px 8px 10px 0px;
  }
`;
