import { addToast } from "../../components/Toast";
import { useSyncStats } from "./SyncStats";
import { computeSyncStatus } from "./SyncStatus";
import { useSyncStatsReactions } from "./useSyncStatusReactions";

const SyncStatusIcon = () => {
  const syncStats = useSyncStats();
  const status = computeSyncStatus(syncStats);
  useSyncStatsReactions(syncStats);

  // Render the sync status icon
  const { client, sw, server } = syncStats;
  const tooltipMessage =
    status.id === "error"
      ? [
          status.message,
          `Issue: ${status.id}`,
          [
            `client count: ${client?.noteCount ?? "unknown"}`,
            `sw count: ${sw?.noteCount ?? "unknown"}`,
            `server count: ${server?.noteCount ?? "unknown"}`,
          ].join(", "),
        ].join("\n")
      : status.message;
  return (
    <div
      role="tooltip"
      data-microtip-position="bottom-left"
      aria-label={tooltipMessage}
      className={`sync-icon ${status.id}`}
      style={{
        position: "relative",
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: status.color,
        transition: "background-color 0.3s ease",
        margin: "0px 8px",
      }}
      onTouchStart={() => {
        addToast({
          content: tooltipMessage,
          type: "info",
        });
      }}
    >
      &nbsp;
    </div>
  );
};

export default SyncStatusIcon;
