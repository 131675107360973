import { useState } from "react";
import { FolderId, Note } from "../../../shared/types";
import { appFolderStore, folderPositions, appNoteStore } from "../../model/services";
import { useNotifySidebarUpdate } from "../../sidebar/atoms/sidebarUpdate";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { AddToFolderIcon, EnterKey, RemoveFromFolderIcon } from "./Icons";

export const FolderList = ({
  note,
  forceUpdate,
  closeMenu,
}: {
  note: Note;
  forceUpdate: () => void;
  closeMenu: () => void;
}) => {
  const setFolder = (folderId: FolderId) => () => {
    if (note.folderId === folderId) {
      appNoteStore.update({ id: note.id, folderId: null });
      trackEvent("remove_note_from_folder");
    } else {
      appNoteStore.update({ id: note.id, folderId });
      trackEvent(note.folderId ? "update_note_in_folder" : "add_note_to_folder");
    }
    forceUpdate();
    closeMenu();
  };

  return (
    <div className="menu-expanded" style={{ alignSelf: "flex-start" }}>
      <div className="note-menu-list">
        {appFolderStore.getAll().map((folder) => (
          <button className="folder menu-item" onClick={setFolder(folder.id)} key={folder.id}>
            {note.folderId === folder.id ? <RemoveFromFolderIcon /> : <AddToFolderIcon />} <span>{folder.name}</span>
          </button>
        ))}
      </div>
      <CreateNew setFolder={setFolder} />
    </div>
  );
};
const CreateNew = ({ setFolder }: { setFolder: (folderId: FolderId) => () => void }) => {
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  const updateSidebar = useNotifySidebarUpdate();
  return isCreatingNew ? (
    <div
      className="newFolderContainer"
      onKeyDown={async function submitFolder(e) {
        if (e.key !== "Enter") return;
        if (isCreatingNew) {
          const [folder] = await appFolderStore.insert(
            {
              name,
              position: folderPositions.generateLast(),
            },
            true,
          );
          setFolder(folder.id)();
        }
        setIsCreatingNew(false);
        updateSidebar();
        // scroll to bottom of the list
        document.getElementsByClassName("note-menu-list")[0].scrollTop = Number.MAX_SAFE_INTEGER;
      }}
    >
      <input
        type="text"
        id="foldername"
        name="foldername"
        placeholder="Folder name"
        size={12}
        enterKeyHint={"Create" as any}
        onChange={(e) => setName(e.target.value)}
        value={name}
        autoFocus
      />
      <button type="submit" className="enter-button">
        <EnterKey />
      </button>
    </div>
  ) : (
    <button className="menu-item" onClick={() => setIsCreatingNew(true)}>
      <span style={{ width: `24px`, fontSize: `18px` }}>＋</span>Create folder
    </button>
  );
};
