import { NoteId } from "../../../shared/types";
import { getUserId } from "../../model/atoms";
import { FetchWrapper } from "../fetchWrapper";

const SIMON_ROOT = "/simon";

export const SimonResource = (client: FetchWrapper) => {
  return {
    addNote: async (id: string, text: string): Promise<string> => {
      const res = await client.post(`${SIMON_ROOT}/add-note`, { id, text, userId: getUserId() });
      return res.hash;
    },
    updateNote: async (id: string, text: string, hash: string): Promise<string> => {
      const res = await client.post(`${SIMON_ROOT}/update-note`, { id, text, hash, userId: getUserId() });
      return res.hash;
    },
    deleteNote: async (hash: string): Promise<void> => {
      await client.post(`${SIMON_ROOT}/delete-note`, { hash, userId: getUserId() });
    },
    search: async (query: string): Promise<{ id: NoteId }[]> => {
      return await client.post(`${SIMON_ROOT}/search`, { query, userId: getUserId() });
    },
    query: async (query: string): Promise<{ answer: string; ids: NoteId[] }> => {
      return await client.post(`${SIMON_ROOT}/query`, { query, userId: getUserId() });
    },
  };
};
