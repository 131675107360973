import { ResolvedPos } from "prosemirror-model";
import { isApple } from "../../../utils/environment";
import { schema } from "../../schema";

/**
 * Returns true if `ctrl` (`meta` on iOS) key was pressed down.
 * @param {KeyboardEvent} event
 */
export const isControlKeyDown = (event: KeyboardEvent): boolean => {
  return isApple ? event.metaKey : event.ctrlKey;
};

/**
 * Check if we have a inline code mark at the given position
 */
export function isInlineCode($pos: ResolvedPos): boolean {
  return (
    $pos.parent.type.name === schema.nodes.paragraph.name &&
    $pos.marks().some((m) => m.type === schema.marks.inlineCode)
  );
}
