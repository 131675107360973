import React, { useEffect } from "react";
import { useAtom, useAtomValue } from "jotai";
import styled from "@emotion/styled";
import { isKeyboardVisibleAtom, mobileCloseKeyboardPressedAtom } from "../../../model/atoms";
import { AUTOCOMPLETE_MENU_ID } from "../../../utils/constants";
import { AutocompleteWrapper } from "../../../editorPage/styledComponents";
import { suggestionSpacerHeight, useMobileMenuPositioning } from "../../../autocomplete/useMobileMenuPositioning";
import logger from "../../../utils/logger";
import { EditorSuggestionList } from "./EditorSuggestionList";
import { autocompleteStateAtom, useAutocompleteSuggestions } from "./useAutocompleteState";

const SuggestionsContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: filter 0.6s;
  &.disabled {
    filter: grayscale(100%);
  }
  .suggestion-item-list {
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.25);
    background-color: var(--color-bg-primary);
  }
  .suggestion-item {
    border-bottom: 1px solid var(--color-border-secondary);
  }
`;

/**
 * This is a spacer that is used to make the touch target for the first
 * autocomplete item bigger than it appears, since users often miss the first
 * item when tapping on mobile. It is also used to make the touch target for
 * the last autocomplete item bigger, since the last item is often partially
 * hidden by the keyboard.
 */
const SuggestionSpacer = styled.div`
  height: ${suggestionSpacerHeight.toLocaleString()}px;
  width: 100%;
  background-color: transparent;
`;

export default function MobileAutocompleteMenu(props: {
  autocompleteKeyDownRef: React.MutableRefObject<((event: KeyboardEvent) => boolean) | null>;
}) {
  const { autocompleteKeyDownRef } = props;
  const menuContainerRef = React.useRef<HTMLDivElement>(null);
  const pickItRef = React.useRef<((index: number) => boolean) | null>(null);

  const isKeyboardVisible = useAtomValue(isKeyboardVisibleAtom);
  const closeKeyboardPressed = useAtomValue(mobileCloseKeyboardPressedAtom);
  const [autocompleteState, setAutocompleteState] = useAtom(autocompleteStateAtom);
  const { mostRecentValue: suggestions, isValid: areSuggestionsValid } = useAutocompleteSuggestions(autocompleteState);

  useMobileMenuPositioning(menuContainerRef, suggestions);

  useEffect(() => {
    const hidingMenu = !isKeyboardVisible || closeKeyboardPressed;
    const autocompleteActive = autocompleteState && autocompleteState.match;
    if (hidingMenu && autocompleteActive) {
      setAutocompleteState(() => null);
    }
  }, [autocompleteState, isKeyboardVisible, closeKeyboardPressed, setAutocompleteState]);

  const forceHide = !isKeyboardVisible || closeKeyboardPressed || !suggestions.length;
  return forceHide ? null : (
    <AutocompleteWrapper>
      <SuggestionsContainer
        className={areSuggestionsValid ? "" : "disabled"}
        ref={menuContainerRef}
        id={AUTOCOMPLETE_MENU_ID}
      >
        <SuggestionSpacer
          onMouseDown={(e) => {
            e.preventDefault();
            if (!pickItRef.current) {
              return logger.error("pickItRef.current is null");
            }
            pickItRef.current(0);
          }}
        />
        <EditorSuggestionList autocompleteKeyDownRef={autocompleteKeyDownRef} pickItRef={pickItRef} />
      </SuggestionsContainer>
    </AutocompleteWrapper>
  );
}
