import styled from "@emotion/styled";
import { useEffect, useState, useRef } from "react";
import { getDefaultStore, useAtom, useAtomValue } from "jotai";
import { useSwipeable } from "react-swipeable";
import { X } from "react-feather";
import { isKeyboardVisibleAtom, toastsAtom } from "../model/atoms";
import { isMobileWidth, isTouchDevice } from "../utils/environment";
import { MOBILE_BAR_HEIGHT } from "../utils/constants";

const TIMEOUT = 4000;

const zIndex = 1000;

const ToastContainerElement = styled.div<{ isKeyboardVisible: boolean; keyboardTop: number }>`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: ${zIndex};
  display: flex;
  user-select: none;
  pointer-events: none;

  /* Mobile */
  ${({ isKeyboardVisible, keyboardTop }) => {
    if (!isMobileWidth) return;
    // If the keyboard is visible, position the toast just above the keyboard.
    // Otherwise, position it about 3/4 of the way down the screen.
    const height = isKeyboardVisible ? keyboardTop - 20 : window.innerHeight * 0.7;
    return `
      top: 0;
      height: ${height}px;
      align-items: flex-end;
      justify-content: center;
      transition: height 0.2s;
    `;
  }}
`;

const ToastElement = styled.div`
  position: absolute;
  right: 24px;
  max-width: 350px;
  border-radius: 8px;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  pointer-events: auto;
  cursor: pointer;
  background: var(--color-bg-secondary);
  border: 1px solid var(--color-bg-accent-primary);
  @media (max-width: 767px) {
    right: auto;
  }
  &,
  svg {
    color: var(--color-text-primary);
  }

  &.warning {
    background-color: var(--color-bg-warning);
    border: none;
    &,
    svg {
      color: white;
    }
  }

  &.error {
    background-color: var(--color-bg-negative-primary);
    border: none;

    &,
    svg {
      color: white;
    }
  }

  box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 10%);
  transition: box-shadow 0.3s;
  :hover {
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%);
  }

  display: flex;

  .toast-main {
    padding: 6px 12px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .toast-sidebar {
    padding: 16px 14px 0 0;
  }

  .toast-title {
    flex-grow: 1;
  }

  .toast-close-button {
    background: none;
    border: none;
    display: flex;
    opacity: 50%;
  }

  .toast-close-button:hover {
    opacity: 100%;
  }

  .toast-actions:empty {
    display: none;
  }

  button {
    all: unset;
    display: block;
    cursor: pointer;
    color: var(--color-text-accent);
  }

  button:hover {
    color: var(--color-button-bg);
  }

  animation: 0.24s toast-animation-enter forwards;
  &.exit {
    animation: 0.24s toast-animation-exit forwards;
  }

  @keyframes toast-animation-enter {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes toast-animation-exit {
    0% {
      opacity: 1;
      transform: translateX(0px);
    }
    100% {
      opacity: 0;
      transform: translateX(30px);
    }
  }
`;

export type ToastProps = {
  id: number;
  title: string;
  content: JSX.Element | string;
  type: "prompt" | "info" | "warning" | "error";
  buttons: {
    text: string;
    onClick?: () => void;
    href?: string;
    desktopShortcutKey?: string;
  }[];
  zIndex?: number;
};

export function addToast(props: Partial<ToastProps> | string) {
  props = typeof props === "string" ? { content: props } : props;
  const toast = {
    id: Date.now(),
    content: props.content || "",
    type: props.type || "info",
    title: props.title || "",
    buttons: props.buttons || [],
  };
  const v = getDefaultStore().get(toastsAtom);
  if (v.length > 0 && v[v.length - 1].content === props.content) return;
  getDefaultStore().set(toastsAtom, (prevToasts) => [...prevToasts, toast]);
}

const Toast = ({
  content,
  type,
  title,
  buttons,
  onDismiss: destroy,
  zIndex,
}: ToastProps & {
  onDismiss: () => void;
}) => {
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [isExiting, setIsExiting] = useState(false);
  const dismiss = useRef(() => {});
  dismiss.current = () => {
    timer.current = undefined;
    setIsExiting(true);
    setTimeout(destroy, 200);
  };

  // Dismiss the toast after a timeout.
  useEffect(() => {
    if (timer.current) return;
    timer.current = setTimeout(dismiss.current, TIMEOUT);
    return () => {
      clearTimeout(timer.current);
      timer.current = undefined;
    };
  }, []);

  // On desktop, maintain the toast on hover.
  // On mobile, dismiss on swipe.
  const maintainOnHoverHandlers = {
    handleMouseOver: () => {
      clearTimeout(timer.current);
      timer.current = undefined;
    },
    handleMouseOut: () => {
      timer.current = setTimeout(dismiss.current, TIMEOUT);
    },
  };
  const dismissOnSwipeHandlers = useSwipeable({
    onSwiped: dismiss.current,
  });
  const handlers = isMobileWidth ? dismissOnSwipeHandlers : maintainOnHoverHandlers;

  useEffect(() => {
    // Don't enable the toast button shortcuts on the touch devices since they are likely to have a software keyboard
    if (isTouchDevice) return;

    const buttonsWithShortcuts = buttons.filter((b) => !!b.desktopShortcutKey);
    if (buttonsWithShortcuts.length === 0) return;

    function handleKeydown(e: KeyboardEvent) {
      for (const button of buttonsWithShortcuts) {
        if (e.key === button.desktopShortcutKey) {
          button.onClick?.();
          dismiss.current();
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
      }
    }
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [buttons]);

  return (
    <ToastElement style={{ zIndex }} className={`toast ${type} ${isExiting ? "exit" : ""}`} {...handlers}>
      <div className="toast-main">
        <div className="toast-title">{title}</div>
        <div>{content}</div>
        <div className="toast-actions">
          {buttons?.map((button, index) =>
            button.href ? (
              <a key={index} href={button.href} onClick={dismiss.current} className="link">
                {button.text}
              </a>
            ) : (
              <button
                key={index}
                onClick={() => {
                  button.onClick?.();
                  dismiss.current();
                }}
              >
                {button.text}
                {!isTouchDevice && button.desktopShortcutKey && <>&nbsp;({button.desktopShortcutKey})</>}
              </button>
            ),
          )}
        </div>
      </div>
      <div className="toast-sidebar">
        <button className="toast-close-button" onClick={dismiss.current}>
          <X size={20} />
        </button>
      </div>
    </ToastElement>
  );
};

export const ToastContainer = () => {
  const [toasts, setToasts] = useAtom(toastsAtom);
  const isKeyboardVisible = useAtomValue(isKeyboardVisibleAtom);
  const keyboardTop = useKeyboardTop();
  const ref = useRef<HTMLDivElement>(null);

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContainerElement ref={ref} isKeyboardVisible={isKeyboardVisible} keyboardTop={keyboardTop}>
      {toasts.map((toast, i) => (
        <Toast zIndex={zIndex + i} key={toast.id} {...toast} onDismiss={() => removeToast(toast.id)} />
      ))}
    </ToastContainerElement>
  );
};

/**
 * Returns the top position of the keyboard on mobile devices (includes the mobile bar height).
 */
function useKeyboardTop() {
  const [keyboardTop, setKeyboardTop] = useState(0);
  useEffect(() => {
    const handler = () => {
      setKeyboardTop(visualViewport ? visualViewport.height + visualViewport.pageTop - MOBILE_BAR_HEIGHT : 0);
    };
    window.visualViewport?.addEventListener("resize", handler);
    window.visualViewport?.addEventListener("scroll", handler);
    return () => {
      window.visualViewport?.removeEventListener("resize", handler);
      window.visualViewport?.removeEventListener("scroll", handler);
    };
  }, []);
  return keyboardTop;
}
