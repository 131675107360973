import { getDefaultStore } from "jotai";
import { Node } from "prosemirror-model";
import { topLevelTokenToProsersmirorNodes } from "../editor/bridge";
import { userSettingsAtom } from "../model/atoms";
import { TopLevelToken, InlineToken, ListItemToken } from "../../shared/types";
import { generateId } from "../model/generateId";
import { appNoteStore } from "../model/services";
import { updateCanonicalNote } from "./updateCanonicalNote";

export const processExtractWithAiResponse = (aiResponse: string): Node[] => {
  const { allTokens, tokensByEntity } = parseResponse(aiResponse);
  const { appendExtractsToCanonicalNotes = false } = getDefaultStore().get(userSettingsAtom);
  if (appendExtractsToCanonicalNotes && tokensByEntity.size > 0) {
    tokensByEntity.forEach((tokens, entityName) => {
      updateCanonicalNote(entityName, tokens);
    });
  }

  // Return the nodes to be inserted into the audio note
  const { nodes } = topLevelTokenToProsersmirorNodes(allTokens);
  return nodes;
};

const parseResponse = (multiline: string) => {
  const tokensByEntity = new Map<string, ListItemToken[]>();
  const allTokens: TopLevelToken[] = [];

  let curEntityName = "";
  let curListContent: ListItemToken[] = [];
  for (const line of multiline.split("\n")) {
    if (line.trim() === "") {
      curEntityName = "";
      if (curListContent.length > 0) {
        allTokens.push({
          type: "list",
          content: curListContent,
        });
        curListContent = [];
      }
      continue;
    }
    const isBullet = line.trim().startsWith("-") || line.trim().startsWith("*");
    if (!isBullet) {
      // Assume it's the name of an entity acting as a header for a list of attributes
      curEntityName = line.trim();

      // This might seem like a strange check, but it's possible that GPT generates
      // multiple lists of attributes for the same entity, and we don't want to overwrite anything.
      if (!tokensByEntity.has(curEntityName)) {
        tokensByEntity.set(curEntityName, []);
      }

      allTokens.push({
        type: "paragraph",
        tokenId: generateId(),
        content: [tokenForEntityName(curEntityName)],
      });
    } else {
      const trimmedLine = line.slice(1).trim();
      const listItemToken: ListItemToken = {
        type: "listItem",
        content: [
          {
            type: "paragraph",
            tokenId: generateId(),
            content: tokenizeListItem(trimmedLine),
          },
        ],
        depth: 0,
      };
      tokensByEntity.get(curEntityName)?.push(listItemToken);
      curListContent.push(listItemToken);
    }
  }

  if (curListContent.length > 0) {
    allTokens.push({
      type: "list",
      content: curListContent,
    });
  }

  // merge any adjacent lists
  const merged: TopLevelToken[] = [];
  for (let i = 0; i < allTokens.length; i++) {
    const curr = allTokens[i];
    const prev = allTokens[i - 1];
    if (curr.type === "list" && prev?.type === "list") {
      prev.content.push(...curr.content);
    } else {
      merged.push(curr);
    }
  }
  return { allTokens: merged, tokensByEntity };
};

const tokenForEntityName = (entityName: string): InlineToken => {
  const canonicalNote = appNoteStore.getCanonicalByName(entityName);
  if (canonicalNote) {
    return {
      type: "spaceship",
      linkedNoteId: canonicalNote.id,
      tokenId: generateId(),
    };
  }
  return { type: "text", content: entityName, marks: [] };
};

function tokenizeListItem(token: string): InlineToken[] {
  const relationshipTargetRegex = /^(\w+[\w\s]+):\s((?:\w+[\w\s]+)(?:,\s(\w+[\w\s]+))*)$/;
  const regexMatch = token.match(relationshipTargetRegex);
  if (!regexMatch) {
    return [{ type: "text", content: token, marks: [] }];
  }
  const relationship = regexMatch[1];
  const targetList = regexMatch[2].split(", ");

  const resultTokens: InlineToken[] = [];
  resultTokens.push({ type: "text", content: relationship + ": ", marks: [] });
  for (const target of targetList) {
    const canonicalNote = appNoteStore.getCanonicalByName(target);
    if (canonicalNote) {
      resultTokens.push({
        type: "spaceship",
        linkedNoteId: canonicalNote.id,
        tokenId: generateId(),
      });
    } else {
      resultTokens.push({ type: "text", content: target, marks: [] });
    }
    resultTokens.push({ type: "text", content: ", ", marks: [] });
  }
  if (targetList.length > 0) {
    // Remove the trailing comma added by the loop
    resultTokens.pop();
  }
  return resultTokens;
}
