import { Plugin, PluginKey } from "prosemirror-state";

export type TrackRangesPluginState = {
  currentRange: { start: number; end: number } | null;
};

export const trackRangesPluginKey = new PluginKey("trackRangesPlugin");
export const trackRangesPlugin = new Plugin<TrackRangesPluginState>({
  key: trackRangesPluginKey,
  state: {
    init() {
      return { currentRange: null };
    },
    apply(tr, oldPluginState) {
      const input: object = tr.getMeta(trackRangesPluginKey);
      if (input && "setRange" in input) {
        return { currentRange: input.setRange as any };
      }

      if (!tr.docChanged) return oldPluginState;
      if (oldPluginState.currentRange === null) return oldPluginState;

      return {
        currentRange: {
          start: tr.mapping.map(oldPluginState.currentRange.start, -1),
          end: tr.mapping.map(oldPluginState.currentRange.end),
        },
      };
    },
  },
});
