import { useAtomValue, useSetAtom } from "jotai";
import { isSidebarOpenAtom } from "../../model/atoms";
import { ModalEnum, closeTopModal, withCloseModals, modalStackAtom, toggleModal } from "../../model/modals";
import { useShortcuts } from "../../shortcuts/useShortcuts";
import { useHotkeys } from "../../shortcuts/useHotkeys";
import { useExtractEntitiesShortcut } from "../../entities/useExtractEntitiesShortcut";
import { updateSearchQuery } from "../../search/useSearchQuery";
import { useInsertAudio } from "../header/MicButton";
import { globalAudioRecordingCoordinator } from "../../editor/features/audioInsert/audioRecordingCoordinator";
import { exportPromptOnUserSave } from "../../export/exportPromptOnUserSave";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { useCreateNoteAtTop, useCreateNoteAsPage } from "./useCreateNote";
import { useBackToAllNotes } from "./useBackToAllNotes";

export function useEditorHotkeys() {
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);
  const backToAllNotes = useBackToAllNotes();
  const createNote = useCreateNoteAtTop();
  const createNoteAsPage = useCreateNoteAsPage();
  const modalStack = useAtomValue(modalStackAtom);
  const createNoteFn = withCloseModals(createNote);
  const createNoteAsPageFn = withCloseModals(createNoteAsPage);
  const insertAudio = useInsertAudio();
  const audioRecordingState = globalAudioRecordingCoordinator.useRecordingState();
  const { shortcuts } = useShortcuts();

  useExtractEntitiesShortcut();
  useHotkeys(shortcuts.toggleRecording, () => {
    if (audioRecordingState) {
      globalAudioRecordingCoordinator.stopRecording(audioRecordingState.audioId);
    } else {
      insertAudio(true);
    }
  });
  useHotkeys(shortcuts.createNewNote, () => createNoteFn());
  useHotkeys(shortcuts.createNewPage, () => createNoteAsPageFn());
  useHotkeys(shortcuts.backToAllNotes, () => (modalStack.length > 0 ? closeTopModal() : backToAllNotes()));
  useHotkeys(shortcuts.toggleSidebar, () => setShowSidebar((v) => !v));
  useHotkeys(shortcuts.openTodos, () => {
    updateSearchQuery({ hasIncomplete: true });
  });
  useHotkeys(shortcuts.toggleHelp, () => {
    toggleModal(ModalEnum.HELP, { openCallback: () => trackEvent("open_help_from_hotkeys") });
  });
  useHotkeys(shortcuts.savePage, (e) => exportPromptOnUserSave());
}
