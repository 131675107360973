import { useCallback, useEffect } from "react";
import { useAtomValue } from "jotai";
import { MOBILE_BAR_ID } from "../utils/constants";
import useWindowDimensions from "../utils/useWindowDimensions";
import { isKeyboardVisibleAtom } from "../model/atoms";
import Suggestion from "./Suggestion";

// Adjust menu height to account for height of the suggestion spacer in MobileAutocompleteMenu
export const suggestionSpacerHeight = 30;

export const useMobileMenuPositioning = (menuRef: React.RefObject<HTMLDivElement>, suggestions: Suggestion[]) => {
  const isKeyboardVisible = useAtomValue(isKeyboardVisibleAtom);

  // Force a rerender when the window is resized
  // This helps the autocomplete dropdown to be positioned correctly
  useWindowDimensions();

  const repositionMenu = useCallback(() => {
    if (!menuRef.current) return;
    const mobileKeyboardBar = document.getElementById(MOBILE_BAR_ID);
    // If the keyboard bar isn't visible the menu won't be rendered anyway so 0s are fine here
    const keyboardBarTop = mobileKeyboardBar?.offsetTop ?? 0;
    // const keyboardBarHeight = mobileKeyboardBar?.clientHeight ?? 0;
    // Each suggestion is 35px tall, and we want to show at most 3 suggestions
    const optionsHeight = Math.min(suggestions.length, 3) * 35;
    // If there are more than 3 suggestions, add a little extra space so that we'll show the beginning
    // of the next suggestion as a hint that the user can scroll the suggestion list
    const overflowHint = suggestions.length > 3 ? 10 : 0;
    // Leave room for padding between the keyboard bar and the menu
    const menuHeight = optionsHeight + overflowHint + suggestionSpacerHeight;
    menuRef.current.style.top = `${keyboardBarTop - menuHeight}px`;
    menuRef.current.style.maxHeight = `${menuHeight}px`;
    // Unhide here to prevent flicker-y render when first opening menu
    menuRef.current.style.display = "block";
  }, [menuRef, suggestions]);

  useEffect(repositionMenu, [isKeyboardVisible, repositionMenu]);
  useEffect(() => {
    // Repositions the bar when the keyboard bar goes up/down and when the orientation
    // finishes changing.
    window.visualViewport?.addEventListener("resize", repositionMenu);
    // Repositions the bar on scroll
    window.visualViewport?.addEventListener("scroll", repositionMenu);
    return () => {
      window.visualViewport?.removeEventListener("resize", repositionMenu);
      window.visualViewport?.removeEventListener("scroll", repositionMenu);
    };
  }, [repositionMenu]);

  useEffect(() => {
    // Manually dispatch a selection change event to force rerun of useKeepCaretInView logic when ever the suggstions list changes
    document.dispatchEvent(new Event("selectionchange"));
  }, [suggestions]);
};
