import { EditorState, Plugin, PluginKey, Command, TextSelection, Transaction } from "prosemirror-state";
import { findSelectionAtEndOfNote, findSelectionAtStartOfNote } from "../../utils/find";

/**
 * If current selection partially covers a note, expand the selection to cover the whole note.
 * Otherwise, select all notes in the document (default behavior of "Select All").
 */
export const selectAll: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const { from, to } = state.selection;
  const selStart = findSelectionAtStartOfNote(state.doc, from);
  const selEnd = findSelectionAtEndOfNote(state.doc, to);
  if (selStart === null || selEnd === null) return false;

  if (selStart.from !== from || selEnd.to !== to) {
    const tr = expandSelectionToAllNoteContent(state.tr);
    if (tr) {
      dispatch(tr);
      return true;
    }
  }
  return false;
};

/**
 * This function modifies the provided transaction to expand the selection
 * to all notes at least partially included in the current selection range
 * If the noteToSelectPos is specified the note at the given position is selected instead
 * and the current selection is ignored
 */
function expandSelectionToAllNoteContent(tr: Transaction, noteToSelectPos?: number): Transaction | undefined {
  noteToSelectPos = noteToSelectPos !== undefined ? noteToSelectPos + 1 : undefined;

  const from = noteToSelectPos ?? tr.selection.from;
  const to = noteToSelectPos ?? tr.selection.to;

  const selStart = findSelectionAtStartOfNote(tr.doc, from);
  const selEnd = findSelectionAtEndOfNote(tr.doc, to);
  if (selStart === null || selEnd === null) return undefined;

  return tr.setSelection(TextSelection.create(tr.doc, selStart.from, selEnd.to));
}

/**
 * Returns true if the current selection goes from the start of a note to the
 * end of a note (the same or different)
 */
function selSpansWholeNote(state: EditorState) {
  const { from, to } = state.selection;
  const selStart = findSelectionAtStartOfNote(state.doc, from);
  const selEnd = findSelectionAtEndOfNote(state.doc, to);
  return selStart?.from === from && selEnd?.to === to;
}

/**
 * Returns true if the current selection goes from the start of the document to
 * the end of the document.
 *
 * Check if the part of the document before the selection consists only of block
 * openers, and the part of the document after the selection consists only of
 * block closers.  We don't want to be too sensitive to the exact document
 * schema.  The cut-off of 20 is for performance reasons, to avoid creating a
 * large slice for no reason.  We also don't fire if the document has only one
 * child, because suppose there is one note with one character, for example; we
 * don't want to treat selecting that character as a select-all.
 */
function selSpansWholeDocument(state: EditorState) {
  const doc = state.doc;
  const docSize = doc.content.size;
  const { from, to } = state.selection;
  return (
    from < 20 &&
    doc.slice(0, from).openEnd === from &&
    docSize - to < 20 &&
    doc.slice(to, docSize).openStart === docSize - to &&
    doc.childCount > 1
  );
}

/**
 * A native "Select All" won't use the {@link selectAll} command, so we need to
 * detect it and implement the desired select all behaviour (as described in the
 * {@link selectAll} command).
 *
 * This plugin detects a native "Select All," such as using the edit menu
 * (popover/tooltip thing) in iOS. This code isn't platform-specific.  It also
 * detects and handle some cases of "Edit > Select All" chosen with the mouse on
 * desktop.  (Android is not tested.)
 */
export const selectAllPlugin = new Plugin({
  key: new PluginKey("detectSelectAll"),
  appendTransaction: (transactions, oldState, newState) => {
    // If the user is dragging to select, we don't want to change the behavior
    // of that, but in that case, there will be a series of transactions
    // changing the selection as the user drags.
    if (transactions.length !== 1) return;
    const tr = transactions[0];
    if (tr.docChanged || !tr.selectionSet || tr.selection.empty) return;
    // If the old selection was partial and the new selection is whole-document,
    // set the selection to the start/end of the partially selected notes.
    if (!selSpansWholeNote(oldState) && selSpansWholeDocument(newState)) {
      // pass oldState because it has the original selection (and same document)
      return expandSelectionToAllNoteContent(oldState.tr);
    }
  },
});
