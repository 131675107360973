import { PluginKey, Plugin } from "prosemirror-state";
import { isRunningInIOSWebview } from "../../../utils/environment";

export const linkPreviewPlugin: Plugin = new Plugin({
  key: new PluginKey("linkPreview"),
  props: {
    handleDOMEvents: {
      mousedown(_, e: Event) {
        const targetElm = e.target as Element | null;
        if (!targetElm || targetElm.nodeName !== "A") return false;

        const url = targetElm.getAttribute("href");
        if (url == null) return false;

        openURL(url);
        return true;
      },
    },
  },
});

const openURL = (url: string) => {
  if (/^https?:\/\//.test(url)) {
    window.open(url, "_blank");
  } else if (isRunningInIOSWebview) {
    window.open(`https://${url}`, "_blank");
  } else {
    window.open(`//${url}`, "_blank");
  }
};
