import styled from "@emotion/styled";
import { X } from "react-feather";

export const CloseIcon = ({
  onClickCallback,
  width = "1.5rem",
  height = "1.5rem",
}: {
  onClickCallback: () => void;
  width: string;
  height: string;
}) => {
  return (
    <Icon>
      <X onClick={onClickCallback} width={width} height={height} />
    </Icon>
  );
};

const Icon = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  > svg {
    vertical-align: middle;
  }
`;
