import { Node, MarkType } from "prosemirror-model";
import { schema } from "../../schema";
import { Matches, TextRun } from "./regexMarkUtils";

type MarkingMatches = Matches<"hashtag" | "highConfidenceLink" | "lowConfidenceLink">;

/**
 * Returns true if textRun and matches contains the same marks.
 *
 * I implemented it via A ⊆ B && B ⊆ A
 *
 * Could be sped by exhaustively checking each marks, and simpler if using the same datatypes.
 */
export function equalMarks(textRun: TextRun, matches: MarkingMatches[]) {
  return matches.every((match) => hasNodeForMatch(textRun, match)) && hasMatchForNodes(matches, textRun);
}
const isNonTogglableMarks = (m: MarkType) => m === schema.marks.hashtag || schema.marks.link;

/* Maps Matches type to schema MarkType */
const matchToNonUserTogglableMarkType: { [index: string]: MarkType } = {
  hashtag: schema.marks.hashtag,
  highConfidenceLink: schema.marks.link,
  lowConfidenceLink: schema.marks.link,
};

/** Returns the key matching the value. Useful for looking into mapping
 * tables such as matchToNonUserTogglableMarkType.
 */
function reverseLookup<T>(object: { [index: string]: T }, searchValue: any): string[] {
  return Array.from(Object.entries(object))
    .filter(([_, value]) => value === searchValue)
    .map(([key]) => key);
}

/* Returns true if the current node carries the proper mark */
function hasMarkOfRightType(node: Node, markType: MarkType) {
  return node.marks.some((m) => m.type === markType);
}

/* Returns true if textRun contains a node corresponding to "match" */
function hasNodeForMatch(textRun: TextRun, match: Matches) {
  let pos = 0;
  return textRun.textNodes.some((n) => {
    const l = n.text!.length;
    if (pos === match.start && l === match.end - match.start) {
      return hasMarkOfRightType(n, matchToNonUserTogglableMarkType[match.type]); //@todo change type
    }
    pos += l;
    return false;
  });
}

/* Returns true if matches contains a "match" corresponding
 * to each mark in the text run.
 */
function hasMatchForNodes(matches: MarkingMatches[], textRun: TextRun) {
  let pos = 0;
  return textRun.textNodes.every((n) => {
    const l = n.text!.length;
    const found = n.marks
      .filter((mark) => isNonTogglableMarks(mark.type))
      .every((mark) =>
        matches.some(
          (match) =>
            match.start === pos &&
            // if we have a link, make sure the content matches (ENT-828)
            (!(match.type === "highConfidenceLink" || match.type === "lowConfidenceLink") ||
              match.content === mark.attrs.content) &&
            reverseLookup(matchToNonUserTogglableMarkType, mark.type).includes(match.type) &&
            match.end === pos + l,
        ),
      );
    pos += l;
    return found;
  });
}
