import {InlineToken, TopLevelToken} from "../types";

/** Creates a function that loops though each token in a
 *  note and applies a callback function to block nodes.
 * @param blockTokenMapFn callback function that applies some operation to block nodes
 */
export const mapBlockTokens = <T>(blockTokenMapFn: (blockToken: TopLevelToken) => T) => {
  const processTopLevelToken = (topLevelToken: TopLevelToken): void => {
    switch (topLevelToken.type) {
      case "codeblock":
      case "paragraph":
        blockTokenMapFn(topLevelToken);
        return;
      case "list": {
        blockTokenMapFn(topLevelToken);
        topLevelToken.content.forEach((listItem) => listItem.content.forEach(blockTokenMapFn));
        return;
      }
      case "audioInsert": {
        blockTokenMapFn(topLevelToken);
        return;
      }
      default:
        topLevelToken satisfies never;
        throw new Error("unknown top level token");
    }
  };
  return processTopLevelToken;
};

export const applyOnInline = (tokens: TopLevelToken[], callback: (t: InlineToken) => any) => {
  tokens.forEach((bT) => {
    if (bT.type === "paragraph") {
      bT.content.forEach(callback);
    }
    if (bT.type === "list") {
      if (bT.content) {
        bT.content.forEach((li) => applyOnInline(li.content, callback));
      }
    }
  });
};
