import React from "react";
import logger from "../utils/logger";

function useDepsChanged(deps: readonly unknown[]) {
  const depsRef = React.useRef<readonly unknown[] | null>(null);
  try {
    // Consider the first deps as "not changed"
    if (depsRef.current === null) {
      return false;
    }
    if (depsRef.current.length !== deps.length) {
      logger.warn("Provided deps array changed in length", {
        context: { depsRefArray: depsRef.current, length: deps.length },
      });
      return true;
    }
    for (let i = 0; i < deps.length; ++i) {
      if (!Object.is(deps[i], depsRef.current[i])) {
        return true;
      }
    }
    return false;
  } finally {
    // Save the newest deps no matter what the block above returns
    // (finally blocks run after returning)
    depsRef.current = deps;
  }
}

function callIfFunction<S>(valueOrFunction: S | (() => S)) {
  return typeof valueOrFunction === "function" ? (valueOrFunction as () => S)() : valueOrFunction;
}

// Whenever any of the deps in the provided array changes, the state is reset to the inital value
// The inital value can be generated by the fuction (just like the normal useState)
// In that case the state is reset to the new value returned by calling the initalValue fn again
export default function useResettableState<S>(initalValue: S | (() => S), deps: readonly unknown[]) {
  type InternalState = { state: S; epoch: number };
  // Internal state is possible overwritten by the overrideValue
  const [internalState, internalSetState] = React.useState<InternalState>({
    state: callIfFunction(initalValue),
    epoch: 0,
  });
  const overrideValueRef = React.useRef<{ state: S; epoch: number } | null>(null);
  const getCurrentInternalState = React.useCallback((internalState: { state: S; epoch: number }) => {
    // The overriding value is used only if its epoch is strictly larger than the epoch of the state
    // This guarantees that updates performed after the state was reset (and override set) take
    // precedence over the overriding value
    if (overrideValueRef.current && overrideValueRef.current.epoch > internalState.epoch) {
      return overrideValueRef.current;
    } else {
      // Invalidate the old overriding value to not leak memory
      overrideValueRef.current = null;
      return internalState;
    }
  }, []);
  if (useDepsChanged(deps)) {
    overrideValueRef.current = {
      state: callIfFunction(initalValue),
      epoch: getCurrentInternalState(internalState).epoch + 1,
    };
  }

  // The setState doesn't immediately reset the overrideValueRef.current
  // in order to keep state updates "pure". New React `startTransition` API
  // may be used to rerender the component without all of the scheduled state updates
  // applied yet. This happens when a high-priority state update is scheduled at the same
  // time a low priority update is scheduled as well.
  const setState = React.useCallback(
    (stateUpdate: React.SetStateAction<S>) => {
      internalSetState((internalState) => {
        const currentInternalState = getCurrentInternalState(internalState);
        return {
          state:
            typeof stateUpdate === "function"
              ? (stateUpdate as (prevState: S) => S)(currentInternalState.state)
              : stateUpdate,
          epoch: currentInternalState.epoch,
        };
      });
    },
    // Neither internalSetState nor getCurrentInternalState ever change between renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [getCurrentInternalState(internalState).state, setState] as const;
}
