import { InputRule, inputRules } from "prosemirror-inputrules";
import { schema } from "../../schema";

function createTodo(regexp: any) {
  return new InputRule(regexp, (state, match, start, end) => {
    const checkbox = schema.nodes.checkbox.create({
      isChecked: match[0] === "[x]",
    });
    return state.tr.replaceWith(start, end, checkbox);
  });
}

const checkboxPlugin = inputRules({
  rules: [createTodo(/\[(\s|x)?\]$/g)],
});

export { checkboxPlugin };
