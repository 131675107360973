import { getDefaultStore } from "jotai";
import { userSettingsAtom } from "../atoms";
import { appNoteStore } from "../services";
import { upsertUserSettings } from "../userSettings";

// TODO: this is a bit of a hack, we should have a better model for this so that hashtag renames
// don't have to go through NoteStore to begin with.
// But for now this utility function makes sure that the update in both NoteStore and UserSettings always run together
// as part of the rename without making NoteStore import UserSettings which causes a circular dependency.
export const renameHashtag = (oldHashtag: string, newHashtag: string) => {
  appNoteStore.renameHashtag(oldHashtag, newHashtag);

  // Check if the hashtag is pinned and update it in the user settings if so
  const { pinnedHashtags } = getDefaultStore().get(userSettingsAtom);
  if (pinnedHashtags.find((h) => h.id === oldHashtag)) {
    pinnedHashtags.find((h) => h.id === oldHashtag)!.id = newHashtag;
    upsertUserSettings({ pinnedHashtags });
  }
};
