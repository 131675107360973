import { FractionKeyEncoding, FractionKey } from "./FractionKey";
import { IntegerKeyEncoding, IntegerKey } from "./IntegerKey";

export type OrderKey = string;

export class OrderKeyEncoding {
  digits: string;

  fractions: FractionKeyEncoding;
  integers: IntegerKeyEncoding;

  constructor(digits: string) {
    this.digits = digits;
    this.fractions = new FractionKeyEncoding(digits);
    this.integers = new IntegerKeyEncoding(digits);
  }

  validate(key: OrderKey) {
    if (!key) throw new Error("position is undefined");
    if (key === this.integers.SMALLEST_INTEGER) {
      throw new Error("invalid order key: " + key);
    }
    // getParts itself does most of the validation
    const f = this.getParts(key)[1];

    if (f.slice(-1) === "0") {
      throw new Error("invalid order key: " + key);
    }
  }

  getParts(key: OrderKey): [IntegerKey, FractionKey] {
    const i = this.integers.getPrefix(key);
    const f = key.slice(i.length);
    return [i, f];
  }

  // `a` is an order key or null (START).
  // `b` is an order key or null (END).
  // `a < b` lexicographically if both are non-null.
  // digits is a string such as '0123456789' for base 10.  Digits must be in
  // ascending character code order!
  generateBetween(a: OrderKey | null | undefined, b: OrderKey | null | undefined): OrderKey {
    if (a != null) {
      this.validate(a);
    }
    if (b != null) {
      this.validate(b);
    }
    if (a != null && b != null && a >= b) {
      throw new Error(a + " >= " + b);
    }
    if (a == null && b == null) {
      return this.integers.INTEGER_ZERO;
    }
    if (a == null) {
      const [ib, fb] = this.getParts(b!);
      if (ib === this.integers.SMALLEST_INTEGER) {
        return ib + this.fractions.midpoint("", fb);
      }
      return ib < b! ? ib : this.integers.decrement(ib)!;
    }
    if (b == null) {
      const [ia, fa] = this.getParts(a);
      const i = this.integers.increment(ia);
      return i == null ? ia + this.fractions.midpoint(fa, null) : i;
    }
    const [ia, fa] = this.getParts(a);
    const [ib, fb] = this.getParts(b);
    if (ia === ib) {
      return ia + this.fractions.midpoint(fa, fb);
    }
    const i = this.integers.increment(ia);
    return i! < b ? i! : ia + this.fractions.midpoint(fa, null);
  }

  // same preconditions as generateKeysBetween.
  // n >= 0.
  // Returns an array of n distinct keys in sorted order.
  // If a and b are both null, returns [a0, a1, ...]
  // If one or the other is null, returns consecutive "integer"
  // keys.  Otherwise, returns relatively short keys between
  // a and b.
  generateMultipleBetween(a: OrderKey | null | undefined, b: OrderKey | null | undefined, n: number): OrderKey[] {
    if (n === 0) {
      return [];
    }
    if (n === 1) {
      return [this.generateBetween(a, b)];
    }
    if (b === null) {
      let c = this.generateBetween(a, b);
      const result = [c];
      for (let i = 0; i < n - 1; i++) {
        c = this.generateBetween(c, b);
        result.push(c);
      }
      return result;
    }
    if (a === null) {
      let c = this.generateBetween(a, b);
      const result = [c];
      for (let i = 0; i < n - 1; i++) {
        c = this.generateBetween(a, c);
        result.push(c);
      }
      result.reverse();
      return result;
    }
    const mid = Math.floor(n / 2);
    const c = this.generateBetween(a, b);
    return [...this.generateMultipleBetween(a, c, mid), c, ...this.generateMultipleBetween(c, b, n - mid - 1)];
  }
}
