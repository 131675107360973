import { Command, Transaction, Plugin } from "prosemirror-state";
import { schema } from "../../schema";
import { trackEvent } from "../../../analytics/analyticsHandlers";

/**
 * Toggle checkbox by caret.
 *
 * If there is a checkbox immediately before or after the cursor, toggle it.
 * Otherwise, toggle or create a checkbox at the start of the current line.
 *
 * @param tr Transaction to mutation
 */
function toggleCheckboxByCaret(tr: Transaction): Transaction {
  const selection = tr.selection;
  const range = selection.ranges[0];
  if (!range) return tr;

  const cursorPos = range.$from.pos;
  let checkboxPos: number | null = null;

  // Is there a checkbox immediately before cursor?
  if (cursorPos > 0) {
    const nodeBeforePos = tr.doc.nodeAt(cursorPos - 1);
    if (nodeBeforePos && nodeBeforePos.type === schema.nodes.checkbox) {
      checkboxPos = cursorPos - 1;
    }
  }

  // Is there a checkbox immediately after cursor?
  if (checkboxPos === null) {
    const nodeAfterPos = tr.doc.nodeAt(cursorPos);
    if (nodeAfterPos && nodeAfterPos.type === schema.nodes.checkbox) {
      checkboxPos = cursorPos;
    }
  }

  // Is there a checkbox in the current paragraph?
  if (checkboxPos === null) {
    const parent = range.$from.parent;
    const parentStartPos = range.$from.pos - range.$from.parentOffset;
    parent.forEach((node, childPos) => {
      if (checkboxPos !== null) return;
      if (node.type === schema.nodes.checkbox) {
        checkboxPos = parentStartPos + childPos;
      }
    });
  }

  // If we found a checkbox, toggle it. Otherwise, insert a new checkbox at start.
  return toggleCheckboxAtPos(tr, checkboxPos ?? cursorPos);
}

/**
 * Toggle checkbox at position or insert one at start of paragraph.
 *
 * @param tr Transaction to mutate
 * @param pos Position of checkbox. If there is no checkbox at this position,
 * the transaction is not mutated.
 */
function toggleCheckboxAtPos(tr: Transaction, pos: number): Transaction {
  const $pos = tr.doc.resolve(pos);
  if ($pos.parent.type !== schema.nodes.paragraph) return tr;
  const node = tr.doc.nodeAt(pos);
  if (!node || node.type !== schema.nodes.checkbox) {
    tr.insert(
      $pos.start(),
      schema.nodes.checkbox.create({
        isChecked: false,
      }),
    );
  } else if (!node.attrs.isChecked) {
    tr.setNodeMarkup(pos, node.type, {
      isChecked: true,
    });
  } else {
    tr.delete(pos, pos + node.nodeSize);
  }
  return tr;
}

export const toggleCheckboxCommand: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const { $from, $to } = state.selection;
  if ($from.pos === $to.pos) {
    dispatch(toggleCheckboxByCaret(state.tr));
    return true;
  } else {
    // Iterate though all non-empty paragraphs containing selection
    const tr = state.tr;
    const fromParentStartPos = $from.pos - $from.parentOffset;
    const toParentEndPos = $to.pos + $to.parent.nodeSize - $to.parentOffset - 1;
    state.doc.nodesBetween(fromParentStartPos, toParentEndPos, (node, stalePos) => {
      if (node.type !== schema.nodes.paragraph) return;
      if (node.childCount === 0) return;
      // Toggle all checkboxes in selection
      let toggled = false;
      node.forEach((childNode, staleChildOffset) => {
        const staleChildPos = stalePos + staleChildOffset + 1;
        const childPos = tr.mapping.map(staleChildPos);
        const insideSelection = staleChildPos >= $from.pos && staleChildPos <= $to.pos;
        if (insideSelection && childNode.type === schema.nodes.checkbox) {
          toggleCheckboxAtPos(tr, childPos);
          toggled = true;
        }
      });
      // If no checkboxes were toggled, toggle or insert one at start of paragraph
      if (!toggled) {
        const paragraphStartPos = tr.mapping.map(stalePos + 1);
        if (node.firstChild?.type === schema.nodes.checkbox) {
          toggleCheckboxAtPos(tr, paragraphStartPos);
        } else {
          tr.insert(paragraphStartPos, schema.nodes.checkbox.create());
        }
      }
    });
    if (!tr.docChanged) return false;
    dispatch(tr);
    return true;
  }
};

/**
 * Plugin to toggle checkboxes by pressing "[" with a selection.
 *
 * We do this with a plugin instead of a prosemirror's `keymap` because on iOS,
 * prosemirror interprets "[" presses as "{" presses. This is because on iOS,
 * when you press "[", the event.shiftKey is true and prosemirror uses the
 * event.keyCode and event.shiftKey to determine the key name, which maps to
 * "{".
 *
 * See:
 * - Call to mapper from event to key name: https://github.com/ProseMirror/prosemirror-keymap/blob/master/src/keymap.ts#L80
 * - Key name mapper: https://github.com/marijnh/w3c-keyname/blob/master/index.js#L77
 *
 */
export const toggleCheckboxPlugin = new Plugin({
  props: {
    handleKeyDown: (view, event) => {
      const { state, dispatch } = view;
      if (event.key === "[" && !event.ctrlKey && !event.metaKey && !event.altKey) {
        if (!dispatch) return false;
        if (state.selection.empty) return false;
        const executed = toggleCheckboxCommand(state, dispatch);
        if (!executed) return false;
        trackEvent("toggle_checkbox_from_key");
        return true;
      }
    },
  },
});
