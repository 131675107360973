import styled from "@emotion/styled";
import React from "react";
import { Search, X } from "react-feather";
import { colors, mediaQueries, radii, shadows, transitions } from "../utils/style";

const SInput = styled.input`
  width: 0;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  outline: none;
  border-color: transparent;
  background: transparent;
  color: ${colors.text.primary};

  transition: ${transitions.light};
  -webkit-tap-highlight-color: transparent;

  :placeholder {
    color: ${colors.text.tertiary};
    transition: ${transitions.light};
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: ${colors.text.secondary};
  :hover {
    color: ${colors.text.primary};
  }
`;

const MagnifyingGlass = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
  color: ${colors.text.tertiary};
  ${mediaQueries.mobile} {
    display: none;
  }
`;

const Container = styled.div`
  flex: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 0;
  padding: 0 8px 0 10px;

  border: 2;
  border-radius: ${radii.medium};

  background: ${colors.bg.secondary};
  cursor: text;

  :hover {
    background: ${colors.bg.tertiary};
  }

  &.focused {
    background: ${colors.bg.secondary};
    box-shadow:
      0 0 0 3px ${colors.bg.accent.secondary},
      ${shadows.medium};
  }

  ${mediaQueries.mobile} {
    height: 40px;
  }
`;

const ActionContainer = styled.div`
  ${mediaQueries.mobile} {
    display: none;
  }
`;

export const GenericSearchBar = (
  {
    inputText,
    setSearch,
    clear,
    height = 36,
    gap = 8,
    ActionButton = () => null,
    ...props
  }: {
    inputText: string;
    setSearch: (value: string) => void;
    clear: () => void;
    height?: number;
    gap?: number;
    outline?: boolean;
    ActionButton?: React.FC;
  } & React.InputHTMLAttributes<HTMLInputElement>,
  // and any other prop that a input element can take
) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  return (
    <Container
      ref={containerRef}
      role="search"
      style={{
        height,
        gap,
      }}
      onClick={() => inputRef.current?.focus()}
      onKeyDown={(e) => {
        if (inputText && e.key === "Escape") {
          e.preventDefault();
          e.stopPropagation();
          clear();
        }
      }}
    >
      <MagnifyingGlass>
        <Search size={0.52 * height} />
      </MagnifyingGlass>
      <SInput
        {...props}
        type="text"
        autoComplete="off"
        value={inputText}
        ref={inputRef}
        placeholder={props.placeholder || ""}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={(e) => {
          containerRef.current?.classList.add("focused");
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          containerRef.current?.classList.remove("focused");
          props.onBlur?.(e);
        }}
      />
      {inputText !== "" && (
        <>
          <ActionContainer>
            <ActionButton />
          </ActionContainer>
          <CloseButton onClick={clear}>
            <X size={0.55 * height} />
          </CloseButton>
        </>
      )}
    </Container>
  );
};
