import { getDefaultStore } from "jotai";
import { Attrs, Fragment, Node, Slice } from "prosemirror-model";
import { EditorView } from "prosemirror-view";

import { userSettingsAtom } from "../../../model/atoms";
import { generateId } from "../../../model/generateId";
import { appNoteStore } from "../../../model/services";
import logger from "../../../utils/logger";
import { filterEllipsis } from "../../features/ellipsis/utils";
import { schema } from "../../schema";
import { regexpMatchers } from "../mark/regexpMatchers";
import { generatePathFromNoteId } from "../path";

import { searchQueryFromUrlOrSearchString } from "../../../search/SearchQuery";
import { clipboardTextParser } from "./textParser";
import { createNoteIdMapper } from "./utils";
import type { handlePaste } from "./handlers";
import type { cleanUpPastedHTML } from "./htmlTools";

export const isSingleLink = (text: string) => {
  const match = text.match(regexpMatchers.highConfidenceLink);
  return match?.length === 1 && match[0] === text;
};

type MapperResult = Partial<Attrs> | Node | Fragment | null;

function deepMapNodeTree(node: Node, mapper: (node: Node) => MapperResult): Fragment | null;
function deepMapNodeTree(node: Fragment, mapper: (node: Node) => MapperResult): Fragment;
function deepMapNodeTree(node: Node | Fragment, mapper: (node: Node) => MapperResult): Fragment | null {
  if (node instanceof Node) {
    const newAttrs = mapper(node);
    if (!newAttrs) return null;
    if (newAttrs instanceof Node) return Fragment.from(newAttrs);
    if (newAttrs instanceof Fragment) return newAttrs;
    if (node.isText) {
      logger.error("You must map every text node to a text node");
      return Fragment.from(node);
    }
    return Fragment.from(node.type.create({ ...node.attrs, ...newAttrs }, getMappedChildren(node.content, mapper)));
  } else {
    return getMappedChildren(node, mapper);
  }
}

function getMappedChildren(fragment: Fragment, mapper: (node: Node) => MapperResult): Fragment {
  const mappedChildren: Node[] = [];
  fragment.forEach((n) => {
    const mappedChildNode = deepMapNodeTree(n, mapper);
    if (!mappedChildNode) return;
    if (mappedChildNode instanceof Node) mappedChildren.push(mappedChildNode);
    if (mappedChildNode instanceof Fragment) mappedChildNode.forEach((child) => mappedChildren.push(child));
  });
  return Fragment.fromArray(mappedChildren);
}

function findChildNodesGroupedIntoTextGroups(node: Node) {
  if (node.isText) {
    return [{ isText: true as const, textNodes: [node] }];
  }
  const children: ({ isText: true; textNodes: Node[] } | { isText: false; node: Node })[] = [];
  let textAccumulator: null | Node[] = null;
  const flushTextAccumulator = () => {
    if (textAccumulator !== null) {
      children.push({ isText: true, textNodes: textAccumulator });
      textAccumulator = null;
    }
  };
  node.content.forEach((n) => {
    if (n.isText) {
      textAccumulator = [...(textAccumulator ?? []), n];
    } else {
      flushTextAccumulator();
      children.push({ isText: false, node: n });
    }
  });
  flushTextAccumulator();
  return children;
}

function remapMatches(node: Node, regex: RegExp, createNodesFromMatch: (match: RegExpMatchArray) => Node[]) {
  return deepMapNodeTree(node, (node) => {
    // Don't recurse into the codeblock nodes (we don't search for matches there)
    if (node.type === schema.nodes.codeblock) return node;
    if (node.type !== schema.nodes.paragraph && !node.isText) return {};
    const children = findChildNodesGroupedIntoTextGroups(node);
    const mappedChildren = children.flatMap((c) => (c.isText ? processText(c.textNodes) : [c.node]));

    if (node.type === schema.nodes.paragraph) {
      return schema.nodes.paragraph.create(node.attrs, mappedChildren);
    } else {
      return Fragment.from(mappedChildren);
    }
  })!;

  function processText(textNodes: Node[]): Node[] {
    if (textNodes.length === 0) return [];
    regex.lastIndex = 0;
    const firstMatch = regex.exec(textNodes.map((tn) => tn.textContent).join(""));
    if (!firstMatch) return textNodes;

    const { textNodesBeforeMatch, textNodesAfterMatch } = spliceOutRangeFromTextNodes(
      textNodes,
      firstMatch.index,
      firstMatch.index + firstMatch[0].length,
    );

    return [...textNodesBeforeMatch, ...createNodesFromMatch(firstMatch), ...processText(textNodesAfterMatch)];
  }
}

function spliceOutRangeFromTextNodes(textNodes: Node[], startPosition: number, endPosition: number) {
  const textNodesBeforeMatch: Node[] = [];
  const textNodesAfterMatch: Node[] = [];
  let position = 0;
  for (const textNode of textNodes) {
    if (position + textNode.nodeSize <= startPosition) {
      textNodesBeforeMatch.push(textNode);
    } else if (position > endPosition) {
      textNodesAfterMatch.push(textNode);
    } else {
      const textSliceBefore = textNode.textContent.slice(0, Math.max(0, startPosition - position));
      if (textSliceBefore.length > 0) {
        textNodesBeforeMatch.push(schema.text(textSliceBefore, textNode.marks));
      }
      const textSliceAfter = textNode.textContent.slice(endPosition - position);
      if (textSliceAfter.length > 0) {
        textNodesAfterMatch.push(schema.text(textSliceAfter, textNode.marks));
      }
    }
    position += textNode.textContent.length;
  }

  return { textNodesBeforeMatch, textNodesAfterMatch };
}

export function parseAsPastedText(text: string, view: EditorView, unfurlingEnabled = false): Node[] {
  const outSlice = transformPasted(clipboardTextParser(schema)(text), view);
  const nodes: Node[] = [];
  outSlice.content.forEach((node) => nodes.push(node));
  return nodes;
}

/**
 * Changes nodes in the pasted slice to conform with the editor's schema and state
 * ProseMirror plugins that are called on paste:
 * - 1st: {@link cleanUpPastedHTML}
 * - 2nd: this function
 * - 3rd: {@link handlePaste}
 */
export function transformPasted(slice: Slice, view: EditorView): Slice {
  const { unfurlingEnabled = false } = getDefaultStore().get(userSettingsAtom);

  const pressingShift = (view as any).input.shiftKey;
  let hasCodeblock = false;
  const noteIdMapper = createNoteIdMapper();
  const newNoteIds: string[] = [];

  let transformedFragment = deepMapNodeTree(slice.content, (item: Node) => {
    // Note
    if (item.type === schema.nodes.note) {
      if (item.attrs.depth < 1) {
        const newNoteNode = deepMapNodeTree(item, (node) => {
          // Preserve the text nodes
          if (node.isText) {
            return node;
          } else if (node.type === schema.nodes.note) {
            // We need a new ID here since we copying a note (see ENT-460)
            const newId = node.attrs.noteId ? noteIdMapper(node.attrs.noteId) : generateId();
            if (node.attrs.noteId) {
              newNoteIds.push(newId);
            }
            return { noteId: newId, path: generatePathFromNoteId(newId) };
          } else if (node.type === schema.nodes.expandedReference || node.type === schema.nodes.backlinks) {
            return null;
          }
          return {};
        });
        return newNoteNode;
      }
    } else if (item.type === schema.nodes.codeblock) {
      hasCodeblock = true;
    } else if (item.type === schema.nodes.paragraph) {
      // Don't treat the paragraphs starting with "--" as a bullets, those are meant to be splitting a note
      if (item.textContent.startsWith("--")) return {};

      const bulletMatch = item.textContent.match(/^((\t)*)-/);
      if (!bulletMatch) return {};

      const newContent = findChildNodesGroupedIntoTextGroups(item);
      const firstTextGroup = newContent[0];
      if (!firstTextGroup.isText) return item;
      const fixedText = firstTextGroup.textNodes
        .map((tn) => tn.textContent)
        .join("")
        .replace(/^((\t)*)-/, "");
      newContent[0] = {
        isText: true,
        textNodes: fixedText === "" ? [] : [schema.text(fixedText)],
      };
      return schema.nodes.bulletList.create({}, [
        schema.nodes.listItem.create({ depth: bulletMatch[1].length }, [
          schema.nodes.paragraph.create(
            item.attrs,
            newContent.flatMap((n) => (n.isText ? n.textNodes : [n.node])),
          ),
        ]),
      ]);
    }
    return item;
  });

  // Remap the linkedNoteId in pasted references.
  // If a referenced note cannot be found replace it with the first line of the referenced note
  transformedFragment = deepMapNodeTree(transformedFragment, (node) => {
    // Preserve the text nodes
    if (node.isText) return node;
    if (node.type !== schema.nodes.reference) return {};

    // The referenced note exits in the currently loaded thoughtstream, keep it as is
    const oldLinkedNodeId = node.attrs.linkedNoteId;
    if (appNoteStore.has(oldLinkedNodeId)) return {};

    const newLinkedNodeId = noteIdMapper(node.attrs.linkedNoteId);
    if (!newNoteIds.includes(newLinkedNodeId)) {
      return schema.text(node.attrs.content);
    }
    return { linkedNoteId: newLinkedNodeId };
  });

  // Replace links to notes within the same account with references
  if (!pressingShift) {
    transformedFragment = deepMapNodeTree(transformedFragment, (node) => {
      const text = node.textContent;
      if (!node.isText || !text) return {};
      const nodes = [];
      let endOfLastMatch = 0;
      for (const match of text.matchAll(regexpMatchers.highConfidenceLink)) {
        if (match.index === undefined) continue;
        const url = match[0];
        const query = searchQueryFromUrlOrSearchString(url);
        if (
          // It's a link to a single ideaflow note in this account
          url.startsWith(window.location.origin) &&
          Object.keys(query).length === 1 &&
          query.noteIdList &&
          query.noteIdList.length === 1 &&
          appNoteStore.has(query.noteIdList[0])
        ) {
          const textBefore = text.slice(endOfLastMatch, match.index);
          if (textBefore) nodes.push(schema.text(textBefore));
          nodes.push(
            schema.nodes.reference.create({
              linkedNoteId: query.noteIdList,
              content: appNoteStore.getNotePreview(query.noteIdList[0]),
            }),
          );
          endOfLastMatch = match.index + match[0].length;
        }
      }
      if (nodes.length === 0) return {};
      const textBefore = text.slice(endOfLastMatch);
      if (textBefore) nodes.push(schema.text(textBefore));
      return Fragment.from(nodes);
    });
  }

  // Unfurl links if unfurling is enabled, the user isn't pressing shift and the
  // pasted text is a single link
  if (unfurlingEnabled && !pressingShift) {
    let text = "";
    const extractText = (n: Node) => {
      if (n.isText) text += n.text;
      n.forEach(extractText);
    };
    slice.content.forEach(extractText);
    if (isSingleLink(text)) {
      // Prepend a linkLoader node in front of every link detected with high confidence
      // The linkLoader node is responsible for unfurling the link (detecting and inserting the title of the linked page)
      transformedFragment = deepMapNodeTree(transformedFragment, (node) =>
        remapMatches(node, regexpMatchers.highConfidenceLink, (match) => {
          const linkLoaderNode = schema.nodes.linkLoader.create({
            tokenId: generateId(),
            url: match[0],
            title: null,
            description: null,
            image: null,
          });

          return [linkLoaderNode, schema.text(match[0])];
        }),
      );
    }
  }

  return new Slice(
    transformedFragment,
    // if we have a codeblock, we want to go one level higher so that
    // prosemirror knows at which level to merge into
    slice.openStart + (hasCodeblock ? -1 : 0),
    slice.openEnd + (hasCodeblock ? -1 : 0),
  );
}

/**
 * This function is called upon copying from the editor
 * Its purpose is to:
 * - trim referenced notes to their starting text
 * - replace the expanded reference node with its contents
 */
export function transformCopied(slice: Slice): Slice {
  const mapper = (node: Node): MapperResult => {
    // Preserve the text nodes
    if (node.isText) {
      if (node.marks.some((m) => m.type.name === schema.marks.inlineCode.name)) {
        return schema.text("`" + node.text + "`");
      }
      return node;
    } else if (node.type === schema.nodes.reference) {
      return {
        // Get the first non-empty line of the referenced note without any other truncation
        content: appNoteStore.getNotePreview(node.attrs.linkedNoteId, { maxLengthOverride: Infinity }),
      };
    } else if (node.type === schema.nodes.expandedReference) {
      // Replace the expanded reference node with the contents of the referenced note
      const noteContent = node.firstChild?.content;
      if (!noteContent) return null;
      return deepMapNodeTree(noteContent, mapper);
    } else {
      return {};
    }
  };

  // Attach the full content of each refernced note to each reference object
  // This way if the referenced note itself is not being copied at least the
  // text content can be preserved
  let fragment = deepMapNodeTree(slice.content, mapper);

  // While in condensed view, condensed nodes are hidden in ellipsis nodes.
  // Filter those out when copying.
  fragment = filterEllipsis(fragment);
  return new Slice(fragment, slice.openStart, slice.openEnd);
}
