import React from "react";
import { isIOs } from "../../../utils/environment";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const PinnedLink = React.forwardRef<HTMLDivElement, Props>(({ onMouseDown, ...props }: Props, ref) => {
  return (
    <div className="pinned-link" ref={ref} style={isIOs ? { outline: "none" } : {}} {...props} onClick={onMouseDown} />
  );
});
