import { ResolvedPos } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { isNoteEmpty } from "./isNoteEmpty";

/** custom patch to merge the last paragraph of the current
 * node with the last paragraph of the next node
 * many of the false cases are taken from `joinForward`,
 * `deleteBarrier`, & `joinMaybeClear` in prosemirror-commands
 */
export const joinNotesOnDeleteCommand = (state: EditorState, dispatch?: EditorView["dispatch"], view?: EditorView) => {
  if (dispatch == null) return false;
  const { $anchor } = state.selection;
  if (
    !$anchor ||
    (view ? !view.endOfTextblock("forward", state) : $anchor.parentOffset < $anchor.parent.content.size)
  ) {
    return false;
  }
  const $cut = findCutAfter($anchor);
  if ($cut == null) return false;

  const before = $cut.nodeBefore;
  const after = $cut.nodeAfter;
  // if we don't have a node before of after
  if (before == null || after == null) return false;
  // When enabled (default is false), the sides of nodes of this type count as
  // boundaries that regular editing operations,
  // like backspacing or lifting, won't cross
  if (before.type.spec.isolating || after.type.spec.isolating) return false;

  // if the node before is not compatible with the node after the position
  // typecast since compatibleContent is not a public API
  if (!(before.type as any).compatibleContent(after.type)) return false;

  const currentNoteIndex = $cut.index(0);
  const currentNote = state.doc.resolve($cut.posAtIndex(currentNoteIndex, 0));

  // if we are at the end of a line and deleting into the next node
  if (currentNote === $cut) {
    if (before && isNoteEmpty(before)) {
      dispatch(state.tr.deleteRange($cut.pos - before.nodeSize, $cut.pos));
      return true;
    }
    const lastParagraphOfPrevNote = state.doc.resolve(currentNote.before(currentNote.depth + 1) - 1);
    const firstParagraphOfCurrentNote = state.doc.resolve(currentNote.before(currentNote.depth + 1) + 1);

    if (
      before.lastChild === lastParagraphOfPrevNote.nodeBefore &&
      after.firstChild === firstParagraphOfCurrentNote.nodeAfter
    ) {
      dispatch(
        state.tr
          .clearIncompatible($cut.pos, before.type, before.contentMatchAt(before.childCount))
          // join the last paragraph of the current note with the
          // first paragraph of the next note
          .join($cut.pos, 2),
      );
      return true;
    }
  }
  return false;
};

function findCutAfter($pos: ResolvedPos) {
  if (!$pos.parent.type.spec.isolating)
    for (let i = $pos.depth - 1; i >= 0; i--) {
      const parent = $pos.node(i);
      if ($pos.index(i) + 1 < parent.childCount) return $pos.doc.resolve($pos.after(i + 1));
      if (parent.type.spec.isolating) break;
    }
  return null;
}
