import styled from "@emotion/styled";
import { StopCircle } from "react-feather";
import { colors, radii } from "../utils/style";

export const BUTTON_BG = colors.bg.accent.primary;
export const BUTTON_HOVER_BG = "#e4e9e9"; // TODO! : find the right color in colors

export const STOP_BUTTON_BG = "#dd2619";
export const STOP_BUTTON_COLOR = "#ffffff";

export const ICON_STYLES = {
  marginLeft: 1,
  marginBottom: 1,
};

export const ButtonContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.medium};
  background: ${BUTTON_BG};
  color: ${colors.bg.primary};

  :hover {
    background: ${colors.text.accent};
  }
`;

export const StopRecordingButton = () => (
  <ButtonContainer style={{ background: STOP_BUTTON_BG, color: STOP_BUTTON_COLOR }}>
    <StopCircle size={20} style={ICON_STYLES} />
  </ButtonContainer>
);
