import { Command, EditorState, TextSelection, Transaction } from "prosemirror-state";
import { findParent, findSelectionAtEndOfNote } from "../../utils/find";
import { schema } from "../../schema";

/**
 * On Ctrl+A combo key press, if the cursor is inside a codeblock,
 * do not select the note, limit the selection inside codeblock
 */
export const handleSelectAll: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $from, $to } = state.selection;

  const [parentByFromPos, parentStartPos] = findParent(
    state.doc,
    $from.pos,
    (node) => node.type === schema.nodes.codeblock,
  );
  const [parentByToPos] = findParent(state.doc, $to.pos, (node) => node.type === schema.nodes.codeblock);

  if (
    parentByFromPos === null ||
    parentByToPos === null ||
    parentByFromPos?.attrs.tokenId !== parentByToPos?.attrs.tokenId
  ) {
    return false;
  }

  if (parentByToPos.content.size === $to.pos - $from.pos) {
    return false;
  }

  const tr = state.tr;

  //parentStartPos points to just before codeblock node start token.
  //(parentStartPos + 1) points to just after codeblock start token, before inner content.

  dispatch(
    tr.setSelection(
      new TextSelection(
        tr.doc.resolve(parentStartPos + 1), //parentStartPos points
        tr.doc.resolve(parentStartPos + parentByFromPos.content.size + 1),
      ),
    ),
  );

  return true;
};

/**
 * On Ctrl+Enter combo key press, do not split the codeblock into a new note,
 * instead move caret at the bottom of the codeblock, if codeblock is the last
 * node, create a new empty paragraph.
 */
export const handleCtrlEnter: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $from } = state.selection;

  const isLastNode = $from.end($from.depth) === findSelectionAtEndOfNote(state.doc, $from.pos)?.$from.pos;

  const tr = isLastNode ? state.tr.insert($from.end($from.depth) + 1, schema.nodes.paragraph.create()) : state.tr;

  dispatch(tr.setSelection(new TextSelection(tr.doc.resolve($from.end($from.depth) + 1))));

  return true;
};

/**
 * On enter key press, checks if caret is at the bottom and the enter
 * key was pressed 3 times sequentially. If yes, come out of codeblock
 * and delete the created empty new lines. If codeblock is the last node,
 * create an empty paragraph below.
 * @param state
 * @param dispatch
 * @return boolean
 */
export const handleEnter = (state: EditorState, dispatch: (tr: Transaction) => void): boolean => {
  const { $from } = state.selection;

  const isCursorAfterLastChar = $from.parentOffset === $from.parent.content.size;

  const isLastNode = $from.end($from.depth) === findSelectionAtEndOfNote(state.doc, $from.pos)?.$from.pos;

  if ($from.parent.textContent.slice(-2) === "\n\n" && isCursorAfterLastChar) {
    let tr = isLastNode ? state.tr.insert($from.end($from.depth) + 1, schema.nodes.paragraph.create()) : state.tr;
    tr = tr.setSelection(new TextSelection(tr.doc.resolve($from.end($from.depth) + 1)));
    dispatch(tr.deleteRange($from.end($from.depth) - 2, $from.end($from.depth)));
    return true;
  }

  return false;
};

/**
 * On arrow-up key press anywhere on the first line, move to the line above.
 * If codeblock is the first node inside it's parent, create an empty paragraph above.
 */
export const handleArrowUp: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $from } = state.selection;
  const isFirstNode = $from.start($from.depth - 1) === $from.start($from.depth) - 1;
  const isCursorBeforeFirstChar = $from.parentOffset === 0;
  const isCursorOnFirstLine =
    isCursorBeforeFirstChar ||
    $from.parent.textContent.indexOf("\n") === -1 ||
    $from.parentOffset <= $from.parent.textContent.indexOf("\n");

  if (isFirstNode && isCursorOnFirstLine) {
    dispatch(state.tr.insert($from.start($from.depth) - 1, schema.nodes.paragraph.create()));
  }

  return false;
};

/**
 * On arrow-left key press, move to the line above but only if the caret is at extreme left on the
 * first line. If codeblock is the first node inside it's parent, create an empty paragraph above.
 */
export const handleArrowLeft: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $from } = state.selection;

  const isFirstNode = $from.start($from.depth - 1) === $from.start($from.depth) - 1;

  const isCursorBeforeFirstChar = $from.parentOffset === 0;

  if (isFirstNode && isCursorBeforeFirstChar) {
    const tr = state.tr.insert($from.start($from.depth) - 1, schema.nodes.paragraph.create());
    dispatch(tr.setSelection(new TextSelection(tr.doc.resolve($from.start($from.depth) - 1))));
    return true;
  }

  return false;
};

/**
 * On arrow-down key press anywhere on the last line, move to the line above.
 * If codeblock is the last node inside it's parent, create an empty paragraph below.
 */
export const handleArrowDown: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $from } = state.selection;

  const isLastNode = $from.end($from.depth) === findSelectionAtEndOfNote(state.doc, $from.pos)?.$from.pos;

  const isCursorAfterLastChar = $from.parentOffset === $from.parent.content.size;

  const indexOfLastLineBreak = $from.parent.textContent.lastIndexOf("\n");

  const isCursorOnLastLine =
    isCursorAfterLastChar || indexOfLastLineBreak === -1 || $from.parentOffset > indexOfLastLineBreak;

  if (isLastNode && isCursorOnLastLine) {
    const tr = state.tr.insert($from.end($from.depth - 1) - 1, schema.nodes.paragraph.create());
    dispatch(tr.setSelection(new TextSelection(tr.doc.resolve($from.end($from.depth) + 1))));
    return true;
  }

  return false;
};

/**
 * On arrow-right key press, move to the line below but only if the caret is at extreme right on the
 * last line. If codeblock is the last node inside it's parent, create an empty paragraph below.
 */
export const handleArrowRight: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $from } = state.selection;

  const isLastNode = $from.end($from.depth) === $from.end($from.depth - 1) - 1;

  const isCursorAfterLastChar = $from.parentOffset === $from.parent.content.size;

  if (isLastNode && isCursorAfterLastChar) {
    const tr = state.tr.insert($from.end($from.depth - 1) - 1, schema.nodes.paragraph.create());
    dispatch(tr.setSelection(new TextSelection(tr.doc.resolve($from.end($from.depth) + 1))));
    return true;
  }

  return false;
};

/**
 * On backspace key press:
 * - If no content is selected but caret is on extreme left of first line, move first line to a new paragraph above it.
 * - If all content of codeblock is selected, convert codeblock to paragraph.
 */
export const handleBackspace: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }
  const { $from, $to } = state.selection;

  // Handle case when there is no selection and cursor is before the first
  // character in the codeblock. Move the first line above the codeblock.

  if ($from.pos === $to.pos && $from.parentOffset === 0) {
    const indexOfFirstNewLine = $from.parent.textContent.indexOf("\n");

    if (indexOfFirstNewLine === -1) {
      dispatch(state.tr.setNodeMarkup($from.pos - 1, schema.nodes.paragraph));
      return true;
    }

    const firstLineText = $from.parent.textBetween(0, indexOfFirstNewLine);

    const paragraph =
      firstLineText.trim().length === 0
        ? schema.nodes.paragraph.create()
        : schema.nodes.paragraph.createAndFill({}, schema.text(firstLineText));

    if (paragraph === null) {
      return false;
    }

    const tr = state.tr.delete($from.pos, $from.pos + firstLineText.length + 1).insert($from.pos - 1, paragraph);

    dispatch(tr.setSelection(new TextSelection(tr.doc.resolve($from.pos - 1))));
    return true;
  }

  //Handle case when everything in the codeblock is selected and backspace is
  //pressed, convert codeblock into a paragraph.
  if ($from.pos === $from.start($from.depth) && $to.pos === $to.end($to.depth)) {
    dispatch(state.tr.setNodeMarkup($from.pos - 1, state.schema.nodes.paragraph));
    return true;
  }

  return false;
};
