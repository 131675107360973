/**
 * Describes the special Nodes we handle in prosemirror as well as their attributes.
 */
import { Schema, NodeType, MarkType, NodeSpec, MarkSpec } from "prosemirror-model";
import { backlinks } from "./features/backlink/backlinkSpec";
import { checkbox } from "./features/checkbox/checkboxSpecs";
import { codeblock, inlineCode } from "./features/code/spec";
import { ellipsis, ellipsisContainer, ellipsisDot } from "./features/ellipsis/ellipsisSpec";
import { hashtag } from "./features/hashtag/hashtagSpec";
import { highlight } from "./features/highlight/highlightSpec";
import { image } from "./features/image/imageSpec";
import { link, linkLoader } from "./features/link/linkSpec";
import { bulletList, listItem } from "./features/list/listSpec";
import { note } from "./features/note/noteSpec";
import { paragraph } from "./features/paragraph/paragraphSpec";
import { expandedReference } from "./features/reference/expandedReferenceSpec";
import { reference } from "./features/reference/referenceSpec";
import { bold, italic, strikethrough, underline } from "./features/text/formattedTextSpec";
import { audioInsert } from "./features/audioInsert/audioInsertSpec";

// NOTE: the order of these nodes matters. ProseMirror seems to determine a
// "default" type if it needs to autogenerate a node (ContentMatch.defaultType)
// by picking the first one off this list deterministically.
// For example, "paragraph" and "expandedNote" are both blockNodes. If
// expandedNote comes first, wherever ProseMIrror needs to insert an unspecified
// blockNode, it will create an expandedNote.
const nodes = {
  text: { group: "inline" },
  doc: { content: "note*" },
  note,
  reference,
  image,
  checkbox,
  paragraph,
  codeblock,
  listItem,
  bulletList,
  expandedReference,
  linkLoader,
  ellipsis,
  ellipsisContainer,
  ellipsisDot,
  backlinks,
  audioInsert,
  /**
   * Please see AsyncReplacedView for the description of the "asyncReplacedElement"
   */
  asyncReplacedElement: {
    group: "inline",
    inline: true,
    atom: true,
    attrs: {
      operationId: {},
      operationStartTimestamp: { default: 0 },
      // Text or nodes to fall back to if async operation fails
      fallbackText: {},
      fallbackNodes: { default: [] }, // Nodes[] | Fragment
    },
    toDOM(node) {
      // copying async-replaced elements should only copy the text they are processing
      // We cannot allow the user to paste the asyncReplacedElement back, since the operation
      // it's waiting for may be long done
      return node.attrs.fallbackText;
    },
    leafText(node) {
      return node.attrs.fallbackText;
    },
  } as NodeSpec,
};

const autocompleteRegion: MarkSpec = {
  group: "inline",
  attrs: {
    type: { default: "" },
  },
  parseDOM: [],
  toDOM: (mark) => ["span", { class: "autocomplete-region", type: mark.attrs.type }, 0],
};

const marks = {
  inlineCode,
  hashtag,
  link,
  italic,
  bold,
  underline,
  highlight,
  strikethrough,
  autocompleteRegion,
};

// Define our own type StrictSchema to make schema.nodes.foobar a type error for unknown foobar
type StrictSchema<S extends Schema> =
  S extends Schema<infer N, infer M>
    ? // eslint-disable-next-line no-unused-vars
      Omit<S, "nodes" | "marks"> & {
        nodes: { [n in N]: NodeType };
        marks: { [m in M]: MarkType };
      }
    : never;

const s = new Schema({ nodes, marks });

export const schema: StrictSchema<typeof s> = s;
