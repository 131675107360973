import { Node } from "prosemirror-model";
import { EditorView } from "prosemirror-view";

// A checkbox to support todos
// I added a view to be able to handle the click event
export class CheckboxView {
  innerDom: HTMLInputElement;
  dom: HTMLSpanElement;
  // contentDOM: HTMLElement;
  constructor(
    private node: Node,
    view: EditorView,
    getPos: any,
    isReadOnly: boolean,
  ) {
    this.dom = document.createElement("label");
    this.dom.classList.add("checkbox-wrapper");
    this.dom.classList.toggle("checked", this.node.attrs.isChecked);
    this.dom.contentEditable = "false";
    this.innerDom = document.createElement("input");
    this.innerDom.type = "checkbox";
    this.innerDom.className = "checkbox";
    this.innerDom.checked = this.node.attrs.isChecked;
    this.innerDom.tabIndex = 0;
    this.dom.appendChild(this.innerDom);

    if (!isReadOnly) {
      this.innerDom.onclick = (e) => {
        e.preventDefault();
        // toggle the checkbox
        const transaction = view.state.tr.setNodeMarkup(getPos(), undefined, {
          isChecked: !this.node.attrs.isChecked,
        });

        view.dispatch(transaction);
      };

      this.dom.onmousedown = (e) => e.preventDefault();
    }
  }

  stopEvent(_: Event) {
    // only called on mouse click, it avoids the caret to be moved before the node
    return true;
  }

  selectNode() {
    this.dom.classList.add("ProseMirror-selectednode");
  }

  deselectNode() {
    this.dom.classList.remove("ProseMirror-selectednode");
  }
}
