import { StoragePayload } from "../nativeIntegration/types";
import logger from "../utils/logger";
import { DataStorage } from "./DataStorage";

// Because data is ultimately stored as a string in the SQLite database, we need to go through returned
// objects and parse any date strings into Date objects.
const parseDates = (obj: any) => {
  if (!obj || typeof obj !== "object") {
    return obj;
  }
  const updated: any = {};
  for (const [k, v] of Object.entries(obj)) {
    if (typeof v === "string") {
      // If value is a string and looks like a date, parse it, otherwise leave it as is
      updated[k] = /^\d{4}-\d{2}-\d{2}/.test(v) ? new Date(v) : v;
    } else if (Array.isArray(v)) {
      updated[k] = v.map(parseDates);
    } else if (typeof v === "object") {
      updated[k] = parseDates(v);
    } else {
      updated[k] = v;
    }
  }
  return updated;
};

class WebViewStorageHandler implements DataStorage {
  constructor(
    private nextRequestId = 0,
    private pendingRequests: Map<number, { resolve: (value: any) => void; reject: (reason?: any) => void }> = new Map(),
  ) {}

  async findAllKeys(): Promise<string[]> {
    try {
      const response = (await this.postMessage("findAllKeys")) as string[];
      return response;
    } catch (e) {
      logger.error("Error in findAllKeys", { error: e });
      throw e;
    }
  }
  async findAllKeysWithPrefix(prefix: string): Promise<string[]> {
    try {
      const response = (await this.postMessage({ action: "findAllKeysWithPrefix", prefix: prefix })) as string[];
      return response;
    } catch (e) {
      logger.error("Error in findAllKeysWithPrefix", { error: e });
      throw e;
    }
  }

  async getItem<T>(key: string): Promise<T | null> {
    try {
      const response = await this.postMessage({ action: "getItem", key: key });
      if (!response || response.length === 0) {
        return null;
      }
      const parsed = parseDates(JSON.parse(response)) as T;
      return parsed;
    } catch (e) {
      logger.error("Error in getItem", { error: e });
      throw e;
    }
  }
  async getItems<T>(keys: string[]): Promise<T[]> {
    try {
      const response = await this.postMessage({ action: "getItems", keys: keys });
      const parsed = parseDates(JSON.parse(response)) as T[];
      return parsed;
    } catch (e) {
      logger.error("Error in getItems", { error: e });
      throw e;
    }
  }

  async setItem(key: string, val: any): Promise<void> {
    try {
      await this.postMessage({ action: "setItem", key: key, value: val });
    } catch (e) {
      logger.error("Error in setItem", { error: e });
      throw e;
    }
  }
  async setItems(keyValues: { key: string; val: any }[]): Promise<void> {
    try {
      await this.postMessage({ action: "setItems", items: keyValues });
    } catch (e) {
      logger.error("Error in setItems", { error: e });
      throw e;
    }
  }

  async removeItem(key: string): Promise<void> {
    try {
      await this.postMessage({ action: "removeItem", key: key });
    } catch (e) {
      logger.error("Error in removeItem", { error: e });
      throw e;
    }
  }
  async removeItems(keys: string[]): Promise<void> {
    try {
      await this.postMessage({ action: "removeItems", keys: keys });
    } catch (e) {
      logger.error("Error in removeItems", { error: e });
      throw e;
    }
  }
  async removeAllItems(): Promise<void> {
    try {
      await this.postMessage("removeAllItems");
    } catch (e) {
      logger.error("Error in removeAllItems", { error: e });
      throw e;
    }
  }

  postMessage(messageData: string | Record<string, any>): Promise<any> {
    const requestId = this.nextRequestId++;
    if (typeof messageData === "string") {
      messageData = { action: messageData };
    }
    const message = {
      requestId,
      ...messageData,
    };

    const promise = new Promise((resolve, reject) => {
      this.pendingRequests.set(requestId, { resolve, reject });
    });

    window.webkit?.messageHandlers.storage.postMessage(message);

    return promise;
  }

  handleStorageResponse(payload: StoragePayload) {
    const { requestId, result } = payload;
    if (!this.pendingRequests.has(requestId)) {
      logger.warn("Received response for unknown request", { context: { response: payload }, report: true });
      return;
    }

    const { resolve, reject } = this.pendingRequests.get(requestId)!;
    if (result.error) {
      reject(result.error);
    } else {
      resolve(result.data);
    }
    this.pendingRequests.delete(requestId);
  }
}

const webViewStorageHandler = new WebViewStorageHandler();
export default webViewStorageHandler;
