import { MarkSpec } from "prosemirror-model";

export const highlight: MarkSpec = {
  group: "inline",
  parseDOM: [
    {
      tag: "span.search-query-match",
    },
  ],
  toDOM: () => ["span", { class: "search-query-match" }, 0],
};
