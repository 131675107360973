import styled from "@emotion/styled";
import React from "react";
import { useAtomValue } from "jotai";
import MenuItem from "../autocomplete/MenuItem";
import useResettableState from "../autocomplete/useResettableState";
import { useMobileMenuPositioning } from "../autocomplete/useMobileMenuPositioning";
import { getSearchBarInput, isKeyboardVisibleAtom, mobileCloseKeyboardPressedAtom } from "../model/atoms";
import { setSearchBarAndQuery } from "./SearchBar";
import { useSearchSuggestions } from "./useSearchSuggestions";
import { useSearchInputText, useSearchInputCueText } from "./searchInputText";

const SearchSuggestionsWrapper = styled.div`
  display: block;
  position: fixed;
  left: 0;
  z-index: 100;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background-color: var(--color-bg-primary);
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.25);
  padding-bottom: 12px;

  transition: filter 0.6s;

  .suggestion-item-list {
    width: 100%;
  }
  .suggestion-item {
    border-bottom: 1px solid var(--color-border-secondary);
  }
`;

interface Props {
  keyDownHandlerRef: React.MutableRefObject<((event: React.KeyboardEvent<HTMLInputElement>) => boolean) | null>;
}

export const MobileSearchSuggestionList = ({ keyDownHandlerRef }: Props) => {
  const menuContainerRef = React.useRef<HTMLDivElement>(null);
  const searchInputText = useSearchInputText();
  const cueText = useSearchInputCueText();

  const isKeyboardVisible = useAtomValue(isKeyboardVisibleAtom);
  const closeKeyboardPressed = useAtomValue(mobileCloseKeyboardPressedAtom);

  const { mostRecentValue: suggestions } = useSearchSuggestions(cueText);

  // activeElementIndex is reset back to initial value whenever the list of suggestions changes
  const [activeElementIndex, setActiveElementIndex] = useResettableState(0, [suggestions]);

  const pickIt = (index: number) => {
    const suggestion = suggestions[index];
    const newQuery = searchInputText.slice(0, -cueText.length) + `tag:${suggestion.content} `;
    setSearchBarAndQuery(newQuery);
  };

  keyDownHandlerRef.current = (event) => {
    // don't attempt to handle if the suggestion list isn't visible
    if (!suggestions.length) {
      return false;
    }
    if (event.key === "Enter") {
      pickIt(activeElementIndex);
      return true;
    }
    return false;
  };

  useMobileMenuPositioning(menuContainerRef, suggestions);

  if (!getSearchBarInput() || !suggestions.length) {
    return null;
  }
  if (!isKeyboardVisible || closeKeyboardPressed) {
    return null;
  }

  return (
    <SearchSuggestionsWrapper style={{ display: "none" }} ref={menuContainerRef}>
      <div className="suggestion-item-list">
        {suggestions.map((s, i) => (
          <MenuItem
            key={s.id + i}
            setAsActiveElement={() => {
              setActiveElementIndex(i);
            }}
            isHighlighted={i === activeElementIndex}
            suggestion={s}
            onClick={() => pickIt(i)}
          />
        ))}
      </div>
    </SearchSuggestionsWrapper>
  );
};
