import { useMemo } from "react";
import { getDefaultStore, useSetAtom } from "jotai";
import { sidebarUpdateAtom } from "../../model/atoms";

/**
 * Notifies the sidebar it needs to refresh.
 */
export const useNotifySidebarUpdate = () => {
  const set = useSetAtom(sidebarUpdateAtom);
  return useMemo(() => () => set(new Date().getTime()), [set]);
};

export const notifySidebarUpdate = () => {
  getDefaultStore().set(sidebarUpdateAtom, new Date().getTime());
};
