import { EditorState, Transaction } from "prosemirror-state";
import { PageKey, SearchQuery, searchQueryToPage } from "../search/SearchQuery";
import { schema } from "../editor/schema";
import { descendNotes } from "../editor/utils/descendNotes";
import { Path, getPathToBacklinkSection, getPathToReference } from "../editor/utils/path";
import { expandOrCollapseReference } from "../editor/features/reference/referenceExpansionUtils";
import { areBacklinksExpanded, toggleSection } from "../editor/features/backlink/backlinkPlugin";
import getNode from "../editor/utils/getNode";
import { subTransaction } from "../editor/utils/subTransaction";

/**
 * Map from page (~searchquery) to the set of expanded references and backlink sections.
 */
const pageExpansions = new Map<PageKey, Set<Path>>();

/**
 * Saves the expansion states of the current editor.
 */
export function saveExpansionInEditorState(page: SearchQuery, editorState: EditorState) {
  const expansions = new Set<Path>();
  const { doc } = editorState;
  descendNotes(doc, (note) => {
    if (areBacklinksExpanded(note)) {
      expansions.add(getPathToBacklinkSection(note.attrs.path));
    }
    note.descendants((node, pos) => {
      if (node.type === schema.nodes.reference && node.attrs.isExpanded) {
        const path = getPathToReference(note.attrs.path, node.attrs.tokenId);
        expansions.add(path);
      }
    });
  });
  pageExpansions.set(searchQueryToPage(page), expansions);
}

/**
 * Sets the saved expansion states on the current editor.
 */
export function setExpansionsOnState(page: SearchQuery, tr: Transaction) {
  const expansions = pageExpansions.get(searchQueryToPage(page)) || new Set();
  tr.setMeta("type", "restoreExpansions");
  tr.doc.forEach((node, pos) => {
    applyExpansionsToNote(tr, tr.mapping.map(pos), expansions);
  });
}

function applyExpansionsToNote(tr: Transaction, posNote: number, expansionSet: Set<Path>) {
  // Apply expansion states to references inside note
  let note = getNode(tr.doc, posNote, schema.nodes.note);
  subTransaction(tr, (tr) => {
    note.descendants((node, oldOffset) => {
      const pos = tr.mapping.map(posNote + 1 + oldOffset);
      if (node.type === schema.nodes.reference) {
        const p = getPathToReference(note.attrs.path, node.attrs.tokenId);
        if (expansionSet.has(p) !== node.attrs.isExpanded) {
          expandOrCollapseReference(tr, tr.doc.resolve(pos));
        }
        return false;
      } else if (node.type === schema.nodes.expandedReference) {
        return false; // Don't descend into subnotes
      }
    });
  });
  // Apply expansion state to backlink section
  note = getNode(tr.doc, posNote, schema.nodes.note);
  const p = getPathToBacklinkSection(note.attrs.path);
  if (expansionSet.has(p) !== areBacklinksExpanded(note)) {
    toggleSection(tr, posNote);
  }
  // Apply expansion states inside subnotes
  note = getNode(tr.doc, posNote, schema.nodes.note);
  subTransaction(tr, (tr) => {
    note.descendants((node, oldOffset) => {
      if (node.type === schema.nodes.expandedReference) {
        const posExp = tr.mapping.map(posNote + 1 + oldOffset);
        const posSubNote = posExp + 1;
        applyExpansionsToNote(tr, posSubNote, expansionSet);
        return false;
      }
    });
  });
  return tr;
}
