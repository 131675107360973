import List from "@researchgate/react-intersection-list";
import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { GenericSearchBar } from "../../components/GenericSearchBar";
import { SidebarItem } from "../SidebarItem/SidebarItem";
import { SidebarLinkGroup, SidebarLinkGroupType } from "./SidebarLinkGroup";
import { SidebarSectionEmptyState } from "./SidebarSectionEmptyState";
/** data required to render a sidebar element */
export interface SidebarElement {
  id: string;
  content: string; // Hashtag,
  count?: number;
  setSearch: () => void; // a preset SearchQuerySetter
  rename?: (newName: string) => void;
  isPinned?: boolean;
  setIsPinned?: (newVal: boolean) => void;
}
interface Props {
  header: string;
  elements: SidebarElement[];
  type: SidebarLinkGroupType;
  localStorageKey: string;
  placeholderText: string;
  Icon?: React.ComponentType<any>;
  newItemId?: string;
  setNewItemId?: (id: string) => void;
}
export type HashtagSortMode = "popularity" | "alphabetical";
const ElementBrowser: React.FC<Props> = ({ header, elements: items, type, Icon, localStorageKey, placeholderText }) => {
  const [sortMode, setSortMode] = useState(localStorage.getItem(localStorageKey) || "popularity");
  const [filter, setFilter] = useState("");
  const setPersistedSortMode = (newSortMode: HashtagSortMode) => {
    setSortMode(newSortMode);
    localStorage.setItem(localStorageKey, newSortMode);
  };
  const sortedItems = useMemo(() => {
    // add clearing filter to setSearch function
    let res = items.map((item) => {
      return {
        ...item,
        setSearch: () => {
          item.setSearch();
          setFilter("");
        },
      };
    });
    if (filter) {
      const searchLower = filter.toLowerCase();
      res = res.filter((item) => item.content.toLowerCase().includes(searchLower));
    }
    if (sortMode === "popularity") {
      res.sort((a, b) => b.count! - a.count!);
    } else {
      res.sort((a, b) => {
        return a.content.trim().localeCompare(b.content.trim(), undefined, {
          sensitivity: "base",
        });
      });
    }
    // add clearing search to setSearch function
    return res;
  }, [filter, items, sortMode]);
  const renderToken = useCallback(
    (index: number) => {
      const { id, content, count, setSearch, rename, isPinned, setIsPinned } = sortedItems[index];
      return (
        <SidebarItem
          key={id}
          itemId={id}
          Icon={Icon}
          count={count || null}
          content={content}
          setSearch={setSearch}
          rename={rename}
          isPinned={isPinned}
          setIsPinned={setIsPinned}
        />
      );
    },
    [sortedItems, Icon],
  );

  return (
    <SidebarLinkGroup header={header} type={type} sortMode={sortMode} setSortMode={setPersistedSortMode}>
      <SearchBarContainer style={{ marginLeft: -4 }}>
        <GenericSearchBar
          height={28}
          inputText={filter}
          setSearch={setFilter}
          placeholder={"Search"}
          clear={() => setFilter("")}
          style={{ fontSize: 14 }}
          gap={4}
        />
      </SearchBarContainer>

      {items.length ? (
        <List itemCount={sortedItems.length} renderItem={renderToken} pageSize={50} />
      ) : (
        <SidebarSectionEmptyState text={placeholderText} />
      )}
    </SidebarLinkGroup>
  );
};

const SearchBarContainer = styled.div`
  margin: 2px 0;
`;

export default ElementBrowser;
