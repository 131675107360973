import { useCallback, useRef, useEffect } from "react";

/**
 * This hook is used to handle taps and long presses on a component.
 *
 * It returns two callbacks, `handleDown` and `handleUp`, which should be
 * attached to the mouse/touch/pointer events of the component.
 *
 * If the down event is followed by an up event within `tapThreshold`
 * milliseconds, the `onTap` callback is called. If `tapThreshold` milliseconds
 * pass before the up event, the `onLongPress` callback is called, whether or
 * not the up event occurs.
 *
 * @param onTap The callback to be called when a tap is detected
 * @param onLongPress The callback to be called when a long press is detected
 * @param tapThreshold The number of milliseconds to wait before a long press is
 * detected
 */
export const useTapOrLongPress = ({
  onTap,
  onLongPress,
  tapThreshold = 800,
}: {
  onTap: () => void;
  onLongPress: () => void;
  tapThreshold?: number;
}) => {
  const start = useRef<number | undefined>(undefined);
  const timerId = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  useEffect(() => () => clearTimeout(timerId.current), []); // Clear timer on unmount

  // We use a ref to store the function so we can update it without needing
  // to reset the timeout which calls it
  const executeAction = useRef(() => {});
  executeAction.current = () => {
    if (start.current === undefined) return;
    if (new Date().getTime() - start.current < tapThreshold) {
      onTap();
    } else {
      onLongPress();
    }
    cancelHandling();
  };

  // Start the timer on down
  const handleDown = useCallback(
    (e: React.PointerEvent | React.TouchEvent | React.MouseEvent) => {
      e.preventDefault();
      clearTimeout(timerId.current);
      start.current = new Date().getTime();
      timerId.current = setTimeout(() => executeAction.current(), tapThreshold);
    },
    [tapThreshold],
  );

  // Execute the action on up
  const handleUp = useCallback((e: React.PointerEvent | React.TouchEvent | React.MouseEvent) => {
    e.preventDefault();
    executeAction.current();
  }, []);

  const cancelHandling = useCallback(() => {
    clearTimeout(timerId.current);
    start.current = undefined;
    timerId.current = undefined;
  }, []);

  return { handleDown, handleUp, cancelHandling };
};
