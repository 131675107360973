import { join as joinPaths } from "path";
import { Node, Fragment, Mark } from "prosemirror-model";
import {
  ParagraphToken,
  InlineToken,
  SecondaryMark,
  CodeblockToken,
  ListToken,
  ListItemToken,
  TopLevelToken,
  Note,
  TokenId,
  BlockContentToken,
} from "../../shared/types";
import { appNoteFormatter, appNoteStore } from "../model/services";
import { isProd } from "../utils/environment";
import logger from "../utils/logger";
import { generateId } from "./../model/generateId";
import { schema } from "./schema";
import { getDepthFromPath, Path } from "./utils/path";
import { joinCompatibleAdjacentInlineTokens } from "./joinCompatibleAdjacentInlineTokens";
import { removeMark } from "./utils/mark/removeMark";

// ------------ Tokens -> PM Nodes ------------

/**
 * Converts a note to a prosemirror node.
 *
 * The `matches` array contains the token ids that should be displayed while
 * condensed. If empty, all tokens are displayed (@todo is this right?)
 *
 * When `condensingEnabled` is true, the note will be condensed if it's possible
 * to do so (e.g. one paragraph can't be condensed). If the note's expansion
 * setting is "collapse", condensing is enabled regardless of what's passed in.
 *
 * The `isCondensed` specifies whether a note which is condensable should be
 * condensed. If it isn't provided, it defaults to true unless the note's
 * expansion setting is "expand", in which case it's set to false.
 *
 */
export function noteToProsemirrorNode(
  note: Note,
  {
    path = "/",
    matches = [],
    highlighted = false,
    condensingEnabled,
    isCondensed,
    fromSimon,
  }: {
    path?: Path;
    matches?: TokenId[];
    highlighted?: boolean;
    condensingEnabled?: boolean;
    isCondensed?: boolean;
    fromSimon?: boolean;
  } = {},
): Node {
  condensingEnabled = condensingEnabled || note.expansionSetting === "collapse";
  isCondensed = isCondensed ?? note.expansionSetting !== "expand" ?? !(fromSimon === true);
  // Convert note's content to prosemirror nodes. Also returns whether the note
  // is compactable. Even if compactability is enabled, some notes aren't can't
  // be compacted (e.g. a single paragraph)
  const { nodes, condensable } = topLevelTokenToProsersmirorNodes(
    note.tokens,
    matches,
    condensingEnabled && isCondensed,
  );

  // Create the note node
  const notePath = joinPaths(path, note.id);
  return schema.nodes.note.create(
    {
      noteId: note.id,
      path: notePath,
      depth: getDepthFromPath(notePath),
      highlighted,
      folderId: note.folderId,
      insertedAt: note.insertedAt,
      readAll: note.readAll,
      expansionSetting: note.expansionSetting,
      condensingEnabled,
      condensable,
      isCondensed,
      fromSimon,
    },
    nodes,
  );
}

export function topLevelTokenToProsersmirorNodes(
  tokens: TopLevelToken[], // @todo should be top level
  matches: TokenId[] = [],
  shouldCondense = false,
) {
  matches = matches || [];
  const nodes: Node[] = [];
  let hasFoundMatch = false;
  const firstNonEmptyLine = appNoteFormatter
    .getNoteAsStrings(tokens, undefined, { breakOnFirstNonEmptyLine: true, maxDepth: 0 })
    .findIndex((line) => line.trim() !== "");
  let condensable = false;

  /** Adjacent non-matching nodes*/
  let prevNonMatches: Node[] = [];
  function processPrevNonMatches(): Node[] {
    const isSingleEmptyParagraph = prevNonMatches.length === 1 && prevNonMatches[0].childCount === 0;
    let res: Node[] = [];
    if (!isSingleEmptyParagraph && prevNonMatches.length > 0) {
      condensable = true;
      res = shouldCondense ? condenseFragment(prevNonMatches) : prevNonMatches;
    } else {
      res = prevNonMatches;
    }
    prevNonMatches = [];
    return res;
  }

  tokens.forEach((token, i) => {
    /**
     * True if the token may represent a title. We determine this by checking
     * if it's the first non-empty line in the note. If it is, we'll always show
     * it, even if it doesn't contain a match. But not if it's an audio insert,
     * which obviously isn't a title.
     */
    const isMaybeTitle = i === firstNonEmptyLine && token.type !== "audioInsert";
    /**
     * Always show the first line under an audion insert, so that when you
     * search for recordings, you can see any transcripts inserted under them
     */
    const audioInsertAbove = tokens[i - 1]?.type === "audioInsert";
    switch (token.type) {
      case "codeblock":
      case "paragraph":
      case "audioInsert": {
        const { node, hasMatch } = blockTokenToProsemirrorNode(token, matches);
        hasFoundMatch = hasFoundMatch || hasMatch;
        if (hasMatch || audioInsertAbove || isMaybeTitle) {
          nodes.push(...processPrevNonMatches(), node);
        } else {
          prevNonMatches.push(node);
        }
        break;
      }
      case "list": {
        const { node, hasMatch, condensable: listCondensable } = listToProsemirrorNode(token, matches, shouldCondense);
        condensable = condensable || listCondensable;
        hasFoundMatch = hasFoundMatch || hasMatch;
        if (hasMatch) {
          // if the list contains a match, don't condense it's parent
          const parent = prevNonMatches.pop();
          nodes.push(...processPrevNonMatches());
          if (parent) nodes.push(parent);
          nodes.push(node);
        } else {
          prevNonMatches.push(node);
        }
        break;
      }
      default:
        token satisfies never;
        throw new Error(`invalid blockToken type '${(token as any).type}'`);
    }
  });
  nodes.push(...processPrevNonMatches());

  return { nodes, condensable };
}

const listToProsemirrorNode = (token: ListToken, matches: TokenId[] = [], shouldCondense = false) => {
  const nodes: Node[] = [];
  let hasFoundMatch = false;
  let condensable = false;
  /** Adjacent non-matching nodes*/
  let prevNonMatches: Node[] = [];
  /**
   * Condense adjacent non-matches together
   * (excluding ancestors of the match which are left as-is).
   * @param depth the depth of the current match
   */
  function processPrevNonMatches(depth = -1): Node[] {
    /** Used to collect adjacent nodes that will be condensed together*/
    const toCondense: Node[] = [];
    /** Condense nodes in `toCondense` into an ellipses node */
    function processToCondense(): Node[] {
      if (toCondense.length === 0) return [];
      condensable = true;
      const nodes = toCondense.splice(0, toCondense.length); // empty toCondense
      if (!shouldCondense) return nodes;
      // We added nodes from prevNonMatches to toCondense in reverse order, so
      // reverse it back before condensing.
      nodes.reverse();
      // Set the depth of the condensed ellipsis is set to the depth of the first
      // node in the series.
      const firstDepth = nodes[0]?.attrs?.depth;
      const condensedNodes = condenseFragment(nodes, {
        depth: typeof firstDepth === "number" ? firstDepth : 0,
        listItem: true,
      });
      return condensedNodes;
    }

    const result: Node[] = [];
    for (let i = prevNonMatches.length - 1; i >= 0; i--) {
      const node = prevNonMatches[i];
      if (node.attrs.depth < depth) {
        // If we hit a node at a lower depth than the last non-condensed node,
        // then we've found an ancestor of a match. Condense all the nodes up
        // to it and then add the ancestor to the list of nodes without condensing.
        result.push(...processToCondense(), node);
        depth = node.attrs.depth;
      } else {
        toCondense.push(node);
      }
    }
    result.push(...processToCondense());
    prevNonMatches = [];
    return result.reverse(); // reverse because we processed in reverse order
  }

  for (const listItemToken of token.content) {
    const {
      node,
      hasMatch,
      condensable: listItemCondensable,
    } = listItemToProsemirrorNode(listItemToken, matches, shouldCondense);
    condensable = condensable || listItemCondensable;
    if (hasMatch) {
      nodes.push(...processPrevNonMatches(listItemToken.depth), node);
    } else {
      prevNonMatches.push(node);
    }
    hasFoundMatch = hasFoundMatch || hasMatch;
  }
  nodes.push(...processPrevNonMatches());

  return {
    node: schema.nodes.bulletList.create(undefined, nodes),
    hasMatch: hasFoundMatch,
    condensable,
  };
};

const listItemToProsemirrorNode = (token: ListItemToken, matches: string[] = [], shouldCondense = false) => {
  const nodes: Node[] = [];
  let hasFoundMatch = false;
  let condensable = false;
  /** Adjacent non-matching nodes*/
  let prevNonMatches: Node[] = [];
  function processPrevNonMatches(): Node[] {
    const result: Node[] = [];
    if (prevNonMatches.length === 0) return result;
    condensable = true;
    if (
      !shouldCondense ||
      // If all the nodes are non-matching, don't condense them here. They'll be
      // condensed by the parent list.
      prevNonMatches.length === token.content.length
    ) {
      result.push(...prevNonMatches);
      prevNonMatches = [];
      return result;
    }
    const ellipses = schema.nodes.ellipsisContainer.create(null, [
      schema.nodes.ellipsisDot.create(null, null),
      schema.nodes.ellipsis.create(null, prevNonMatches),
    ]);
    prevNonMatches = [];
    return [ellipses];
  }

  token.content.forEach((blockToken, i) => {
    const audioInsertAbove = token.content[i - 1]?.type === "audioInsert";
    const { node, hasMatch } = blockTokenToProsemirrorNode(blockToken, matches);
    if (hasMatch || audioInsertAbove) {
      nodes.push(...processPrevNonMatches(), node);
    } else {
      prevNonMatches.push(node);
    }
    hasFoundMatch = hasFoundMatch || hasMatch;
  });
  nodes.push(...processPrevNonMatches());

  return {
    node: schema.nodes.listItem.create({ depth: token.depth, id: generateId() }, nodes),
    hasMatch: hasFoundMatch,
    condensable,
  };
};

const blockTokenToProsemirrorNode: TokenToPMNode<BlockContentToken> = (token, matches = []) => {
  let node;
  switch (token.type) {
    case "paragraph":
      node = schema.nodes.paragraph.create(
        { tokenId: token.tokenId || generateId(), depth: token.depth || 0 },
        token.content.map((token) => inlineTokenToProseMirrorNode(token)).filter((token) => token != null) as Node[],
      );
      break;
    case "codeblock":
      node = schema.nodes.codeblock.create(
        { tokenId: token.tokenId || generateId() },
        token.content.map((token) => inlineTokenToProseMirrorNode(token)).filter((token) => token != null) as Node[],
      );
      break;
    case "audioInsert":
      node = schema.nodes.audioInsert.create({
        ...token,
        tokenId: token.tokenId || generateId(),
        shouldParseTranscript: false,
      });
      break;
  }

  return {
    node,
    hasMatch: matches.includes(token.tokenId),
  };
};

function generateMarkArray(marks: SecondaryMark[]): Mark[] {
  if (!marks) return [];
  const marksArr: Mark[] = [];
  marks.forEach((mark) => {
    if (mark === "italic") marksArr.push(schema.marks.italic.create());
    if (mark === "bold") marksArr.push(schema.marks.bold.create());
    if (mark === "underline") marksArr.push(schema.marks.underline.create());
    if (mark === "strikethrough") marksArr.push(schema.marks.strikethrough.create());
    if (mark === "inlineCode") marksArr.push(schema.marks.inlineCode.create());
  });
  return marksArr;
}

function inlineTokenToProseMirrorNode(token: InlineToken): Node | null {
  try {
    switch (token.type) {
      case "text":
        return schema.text(token.content === "" ? " " : token.content, generateMarkArray(token.marks));
      case "image":
        return schema.nodes.image.create({
          ...token,
        });
      case "asyncReplacedElement":
        return schema.nodes.asyncReplacedElement.create({
          ...token,
        });
      case "checkbox":
        return schema.nodes.checkbox.create({ isChecked: token.isChecked });
      case "spaceship":
        return schema.nodes.reference.create(
          {
            tokenId: token.tokenId || generateId(),
            linkedNoteId: token.linkedNoteId,
            content: appNoteStore.getNoteEllipsis(token.linkedNoteId),
          },
          undefined,
        );
      case "hashtag":
        return schema.text(token.content, [schema.marks.hashtag.create()]);
      case "link":
        return schema.text(token.content, [schema.marks.link.create({ content: token.content })]);
      case "linkloader":
        return schema.nodes.linkLoader.create({
          tokenId: token.tokenId,
          url: token.url,
          isActive: token.isActive,
        });
      default:
        token satisfies never;
        throw new Error("Unknown node type");
    }
  } catch (e) {
    logger.warn(`${token} could not be processed`, { error: e });
    if (!isProd) throw e;
  }
  return null;
}

function condenseFragment(nodes: Node[], attrs: { depth?: number; listItem?: boolean } | null = null): Node[] {
  const result: Node[] = [];
  if (nodes.length > 0) {
    const node = schema.nodes.ellipsisContainer.create(null, [
      schema.nodes.ellipsisDot.create(attrs, null),
      schema.nodes.ellipsis.create(null, nodes),
    ]);
    result.push(node);
  }
  return result;
}

/**
 * Converts a prosemirror node to a list of tokens
 * @param token The prosemirror node to convert
 * @param matches The indices of the block's containing a match
 */
type TokenToPMNode<T> = (
  token: T,
  matches?: TokenId[],
) => {
  node: Node;
  hasMatch: boolean;
};

// ------------ PM Nodes -> Tokens ------------

export function getTopLevelTokensFromContent(fragment: Fragment): TopLevelToken[] {
  fragment = removeMark(fragment, schema.marks.highlight);

  const result: TopLevelToken[] = [];
  fragment.forEach((node) => {
    switch (node.type) {
      case schema.nodes.codeblock:
      case schema.nodes.audioInsert:
      case schema.nodes.paragraph: {
        const block = getBlockContentTokensFromContent(node);
        if (block) result.push(block);
        break;
      }
      case schema.nodes.bulletList: {
        const content: ListItemToken[] = [];
        node.forEach((child) => {
          const listItemNodes: Node[] = [];
          switch (child.type) {
            case schema.nodes.listItem:
              listItemNodes.push(child);
              break;
            case schema.nodes.ellipsisContainer:
              child.lastChild?.forEach((node) => {
                listItemNodes.push(node);
              });
              break;
            default:
              throw new Error(`Invalid list item type ${child.type.name}`);
          }
          listItemNodes.forEach((listItemNode) => {
            const blockContent: BlockContentToken[] = [];
            listItemNode.forEach((child) => {
              const blockTokens = [];
              if (child.type === schema.nodes.ellipsisContainer) {
                child.lastChild?.forEach((node) => {
                  blockTokens.push(node);
                });
              } else {
                blockTokens.push(child);
              }
              blockTokens.forEach((blockToken) => {
                const block = getBlockContentTokensFromContent(blockToken);
                if (block) blockContent.push(block);
              });
            });
            const depth = parseInt(listItemNode.attrs.depth);
            const token: ListItemToken = {
              type: "listItem",
              content: blockContent,
              depth: isNaN(depth) ? 0 : depth,
            };
            content.push(token);
          });
        });
        const token: ListToken = {
          type: "list",
          content,
        };
        result.push(token);
        break;
      }
      case schema.nodes.expandedReference:
        break;
      case schema.nodes.backlinks:
        break;
      case schema.nodes.ellipsisContainer:
        result.push(
          ...getTopLevelTokensFromContent(node.content.child(1)!.content), // container [..., ellipsis [ real content ] ]
        );
        break;
      default:
        throw new Error(`Unknown topLevelToken node type '${node.type.name}'`);
    }
  });
  return result;
}

function getBlockContentTokensFromContent(node: Node): BlockContentToken | null {
  switch (node.type) {
    case schema.nodes.paragraph: {
      const content: ParagraphToken["content"] = [];
      node.forEach((n, i) => {
        content.push(inlineNodeToInlineToken(n));
      });
      return {
        tokenId: node.attrs.tokenId,
        type: "paragraph",
        depth: node.attrs.depth || 0,
        content: joinCompatibleAdjacentInlineTokens(content),
      };
    }
    case schema.nodes.audioInsert:
      return {
        type: "audioInsert",
        tokenId: node.attrs.tokenId,
        audioId: node.attrs.audioId,
        chunkCount: node.attrs.chunkCount,
        durations: node.attrs.durations,
        updatedAt: node.attrs.updatedAt,
        transcript: node.attrs.transcript,
        transcriptGenId: node.attrs.transcriptGenId,
        transcriptLanguageOverride: node.attrs.transcriptLanguageOverride,
        isRevealed: node.attrs.isRevealed,
      };
    case schema.nodes.codeblock: {
      const content: CodeblockToken["content"] = [];
      node.forEach((n) => content.push(inlineNodeToInlineToken(n) as CodeblockToken["content"][0]));
      return {
        tokenId: node.attrs.tokenId,
        type: "codeblock",
        content,
      };
    }
    case schema.nodes.expandedReference:
      return null;
    default:
      throw new Error(`Unknown blockNode type '${node.type.name}'`);
  }
}

function getSecondaryMarks(marks: readonly Mark[]): SecondaryMark[] {
  const marksLst: SecondaryMark[] = [];
  marks.forEach((mark) => {
    switch (mark.type) {
      case schema.marks.italic:
        marksLst.push("italic");
        break;
      case schema.marks.bold:
        marksLst.push("bold");
        break;
      case schema.marks.underline:
        marksLst.push("underline");
        break;
      case schema.marks.strikethrough:
        marksLst.push("strikethrough");
        break;
      case schema.marks.inlineCode:
        marksLst.push("inlineCode");
        break;
    }
  });
  return marksLst;
}

function inlineNodeToInlineToken(node: Node): InlineToken {
  const type = node.type;
  switch (type) {
    case schema.nodes.text: {
      // based on the documentation, the text property will always be non-null
      // for a text node, but typescript doesn't distinguish between the two
      // https://prosemirror.net/docs/ref/#model.Node.text
      if (node.text == null) {
        throw new Error("found a text node with a null text property");
      }
      const isHashtag = !!node.marks.find((m) => m.type.name === "hashtag");
      const isLink = !!node.marks.find((m) => m.type.name === "link");
      if (isHashtag) {
        return {
          type: "hashtag",
          content: node.text,
        };
      }
      if (isLink) {
        return {
          type: "link",
          content: node.text,
          slug: node.text,
        };
      }
      return {
        type: "text",
        marks: getSecondaryMarks(node.marks),
        content: node.text,
      };
    }
    case schema.nodes.reference:
      return {
        type: "spaceship",
        linkedNoteId: node.attrs.linkedNoteId,
        tokenId: node.attrs.tokenId,
      };
    case schema.nodes.checkbox:
      return {
        type: "checkbox",
        isChecked: node.attrs.isChecked,
      };
    case schema.nodes.linkLoader: {
      return {
        type: "linkloader",
        tokenId: node.attrs.tokenId ?? generateId(),
        url: node.attrs.url,
        isActive: node.attrs.isActive,
      };
    }
    case schema.nodes.image:
      return {
        type: "image",
        src: node.attrs.src,
        width: node.attrs.width,
        naturalWidth: node.attrs.naturalWidth,
        naturalHeight: node.attrs.naturalHeight,
        smallPreviewDataURL: node.attrs.smallPreviewDataURL,
      };
    case schema.nodes.asyncReplacedElement:
      return {
        type: "asyncReplacedElement",
        operationId: node.attrs.operationId,
        operationStartTimestamp: node.attrs.operationStartTimestamp,
        fallbackText: node.attrs.fallbackText,
      };
    default:
      throw new Error("Don't know how to format this node type into an inline token: " + node.type.name);
  }
}
