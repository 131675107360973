import { atom, useAtomValue, getDefaultStore } from "jotai";

const searchInputTextAtom = atom<string>("");
export const useSearchInputText = () => useAtomValue(searchInputTextAtom);
export const setSearchInputText = (value: string) => getDefaultStore().set(searchInputTextAtom, value);

// Used by SuggestionList components to cue suggestsions based off the last word in the search input
export const useSearchInputCueText = () => {
  const searchInputText = useSearchInputText();
  const cueText = searchInputText.slice(searchInputText.lastIndexOf(" ") + 1);
  if (cueText.includes(":")) {
    // Don't show suggestions if user is in the middle of typing a structured query
    return "";
  }
  return cueText;
};
