import { MarkSpec, NodeSpec } from "prosemirror-model";

export const link: MarkSpec = {
  group: "inline",
  attrs: { content: { default: "" } },
  toDOM: (node) => {
    return [
      "a",
      {
        href: node.attrs.content.startsWith("http") ? node.attrs.content : `https://${node.attrs.content}`,
        class: "link clickable",
        target: "_blank",
      },
      0,
    ];
  },
  parseDOM: [
    {
      tag: "a",
      getAttrs: (element: any) => {
        const content = element.getAttribute("href");
        return { content };
      },
    },
  ],
  excludes: "italic bold underline strikethrough",
};

export const linkLoader: NodeSpec = {
  group: "inline",
  selectable: false,
  inline: true,
  atom: true,
  attrs: {
    tokenId: {},
    url: {},
    isActive: { default: false },
    // Before the link is processed the following properties can be missing
    title: { default: null },
    description: { default: null },
    image: { default: null },
  },
  parseDOM: [
    {
      tag: ".linkLoader",
      getAttrs: (element: Node | string) => {
        if (typeof element === "string" || !(element instanceof HTMLDivElement)) return false;
        const url = element.getAttribute("data-url");
        const title = element.getAttribute("data-title");
        const description = element.getAttribute("data-description");
        const image = element.getAttribute("data-image");
        return { url, title, description, image };
      },
    },
  ],
  toDOM: (node) => ["div", { class: "linkLoader" }],
};
