import { CloudwatchLog } from "../../utils/logger";
import { FetchWrapper } from "../fetchWrapper";

export const CloudwatchApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Uploads a log to cloudwatch.
     * @param logEventString List of Serialized event JSON strings.
     */
    upload: async (logEvents: CloudwatchLog[]): Promise<void> =>
      client.post("/cloudwatch", {
        logEvents,
      }),
  };
};
