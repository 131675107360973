import { Command } from "prosemirror-state";
import { schema } from "../../schema";
import {
  findParent,
  findSelectionAtEndOfNote,
  findSelectionAtEndOfNotesFirstLine,
  getParentNote,
} from "../../utils/find";
import { getBacklinkOffsetInNote, expandSection, collapseSection } from "../backlink/backlinkPlugin";

export const expandBacklinksCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const [noteNode, notePos] = getParentNote(state.doc, state.selection.$from.pos);
  if (noteNode === null || notePos === null) return false;
  const tr = expandSection(state.tr, notePos);
  const posBacklinks = notePos + getBacklinkOffsetInNote(noteNode);
  const posFirstNote = posBacklinks + 2; // +1 to enter the backlinks section and +1 to enter expansion
  const sel = findSelectionAtEndOfNotesFirstLine(tr.doc, posFirstNote);
  if (sel) tr.setSelection(sel);
  tr.scrollIntoView();
  dispatch(tr);
  return true;
};

export const collapseBacklinksCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const { $from } = state.selection;
  const [expRefNode, expRefPos] = findParent(state.doc, $from.pos, (n) => n.type === schema.nodes.expandedReference);
  const tr = state.tr;
  if (!!expRefNode && expRefNode.attrs.isBacklink) {
    // If the cursor is inside a backlink, collapse the section containing
    // the backlink and move the cursor to the end of the note.
    const notePos = getParentNote(state.doc, expRefPos)[1];
    if (notePos === null) return false;
    collapseSection(tr, notePos);
    const sel = findSelectionAtEndOfNote(tr.doc, notePos);
    if (sel) tr.setSelection(sel);
    tr.scrollIntoView();
  } else {
    // Otherwise, just collapse the section of the note containing the cursor.
    const notePos = getParentNote(state.doc, $from.pos)[1];
    if (notePos === null) return false;
    collapseSection(tr, notePos);
  }
  dispatch(tr);
  return true;
};
