import { useAtomValue } from "jotai";
import React from "react";
import { ArrowLeft, ArrowUp, ChevronLeft, ChevronUp } from "react-feather";
import styled from "@emotion/styled";
import { editorViewAtom, isSimonSearchLoadingAtom } from "../model/atoms";
import { findSelectionAtEndOfNote } from "../editor/utils/find";
import { SearchQuery } from "../search/SearchQuery";
import { Spinner } from "../utils/Spinner";
import { colors } from "../utils/style";
import { isGreaterThanMobileWidth } from "../utils/environment";
import { useHome } from "./navigateHistory";

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px;
  background: transparent;
  border: 0;
  font-size: 16px;
  cursor: pointer;
  color: var(--color-text-tertiary);
  transition: color 0.3s ease;

  &:hover {
    color: var(--color-text-accent);
  }
`;

export const EditorFooter = ({
  searchQuery,
  editorContainerRef,
}: {
  searchQuery: SearchQuery;
  editorContainerRef: React.RefObject<HTMLDivElement>;
}) => {
  const home = useHome();
  const isSimonSearchLoading = useAtomValue(isSimonSearchLoadingAtom);

  if (searchQuery.isAll) {
    return (
      <>
        <hr style={{ width: "720px", margin: "0 auto", opacity: 0.19 }} />
        <div style={{ width: "720px", margin: "0 auto" }}>
          <BackButton
            onClick={() => {
              editorContainerRef.current?.scrollTo({ top: 0 });
            }}
            role="tooltip"
            data-microtip-position="right"
            aria-label="Scroll to top"
          >
            {isGreaterThanMobileWidth ? <ArrowUp size={16} /> : <ChevronUp size={16} />}
          </BackButton>
        </div>
      </>
    );
  } else {
    return (
      <>
        {isSimonSearchLoading && (
          <div
            style={{
              width: "100%",
              maxWidth: 720,
              margin: "0 auto",
              padding: 10,
              background: colors.bg.accent.tertiary,
            }}
          >
            <Spinner style={{ width: 30, height: 30, margin: "5px auto" }} />
          </div>
        )}
        <>
          <hr style={{ width: "720px", margin: "0 auto", opacity: 0.19 }} />
          <div style={{ width: "720px", margin: "0 auto" }}>
            <BackButton
              onClick={() => {
                home();
              }}
              role="tooltip"
              data-microtip-position="right"
              aria-label="Back to home"
            >
              {isGreaterThanMobileWidth ? <ArrowLeft size={16} /> : <ChevronLeft size={16} />}
            </BackButton>
          </div>
        </>
      </>
    );
  }
};

export const EditorPadding = () => {
  const editorView = useAtomValue(editorViewAtom);
  return (
    <div
      className="editor-padding"
      onClick={() => {
        // Put selection at the end of the last note
        if (!editorView) return;
        const tr = editorView.state.tr;
        if (!tr.doc.lastChild) return;
        const pos = tr.doc.nodeSize - tr.doc.lastChild.nodeSize;
        const sel = findSelectionAtEndOfNote(tr.doc, pos);
        if (!sel) return;
        tr.setSelection(sel);
        editorView.dispatch(tr);
        editorView.focus();
      }}
    />
  );
};
