import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useAtom, useAtomValue } from "jotai";
import { colors } from "../utils/style";
import { colorSchemeAtom } from "./colorSchemeAtom";

// Syncs the <meta name="theme-color"> value, which sets status bar color on
// mobile, to match the app's background color given an applied color theme.
function setDocumentThemeColor(): void {
  const themeColor = getComputedStyle(document.body).getPropertyValue("--color-bg-primary");
  const themeColorMeta = document.head.querySelector("meta[name=theme-color]");
  if (themeColorMeta) {
    themeColorMeta.setAttribute("content", themeColor);
  }
}

const getRenderedColorScheme = (colorScheme: string) => {
  if (colorScheme === "system") {
    const isDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    return isDarkMode ? "dark" : "light";
  } else return colorScheme;
};

export const useColorScheme = () => {
  const colorScheme = useAtomValue(colorSchemeAtom);

  // injects into the html when the theme changes
  useEffect(() => {
    const renderedColorScheme = getRenderedColorScheme(colorScheme);
    document.body.setAttribute("data-theme", renderedColorScheme);
    setDocumentThemeColor();
  }, [colorScheme]);

  // listens to the system and toggles the theme if required.
  // could be simplified
  useEffect(() => {
    if (colorScheme !== "system") return;

    const onSystemColorSchemeChange = (event: MediaQueryListEvent) => {
      document.body.setAttribute("data-theme", event.matches ? "dark" : "light");
      setDocumentThemeColor();
    };
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener?.("change", onSystemColorSchemeChange);
    return () => mediaQuery.removeEventListener?.("change", onSystemColorSchemeChange);
  }, [colorScheme]);

  return getRenderedColorScheme(colorScheme);
};

const ColorSchemeButton = styled.button<{ selected: boolean }>(
  (props) => `
  font-size: 14px;
  border-radius: 4px;
  border: 0;
  background: ${props.selected ? colors.text.accent : colors.bg.secondary};
  color: ${props.selected ? colors.bg.primary : colors.text.primary};
  padding: 4px 6px;
  margin-right: 8px;
`,
);

export const ColorScheme = () => {
  const [colorScheme, setColorScheme] = useAtom(colorSchemeAtom);

  return (
    <label style={{ display: "block", marginBottom: 12 }}>
      <p style={{ fontSize: 14, color: colors.text.primary }}>Color Scheme</p>
      <ColorSchemeButton
        key="light"
        selected={colorScheme === "light"}
        onClick={() => {
          setColorScheme("light");
        }}
      >
        Light
      </ColorSchemeButton>
      <ColorSchemeButton
        key="dark"
        selected={colorScheme === "dark"}
        onClick={() => {
          setColorScheme("dark");
        }}
      >
        Dark
      </ColorSchemeButton>
      <ColorSchemeButton
        key="system"
        selected={colorScheme === "system"}
        onClick={() => {
          setColorScheme("system");
        }}
      >
        Match System
      </ColorSchemeButton>
    </label>
  );
};
