import { Node, ResolvedPos } from "prosemirror-model";
import { EditorState, Selection, NodeSelection, Transaction } from "prosemirror-state";
import { appNoteStore, expansionSet } from "../../../model/services";
import { noteToProsemirrorNode } from "../../bridge";
import { schema } from "../../schema";
import { getPathToReference } from "../../utils/path";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { findParent, getParentNote } from "../../utils/find";
import { generateId } from "../../../model/generateId";
import { NoteId } from "../../../../shared/types";
import { noteToBacklinkProsemirrorNode } from "../backlink/backlinkPlugin";
import getNode from "../../utils/getNode";
import logger from "../../../utils/logger";

/**
 * Command that takes a position (or optionally falls back to the current selection's reference) and toggles the reference expansion.
 */
export const toggleReference = (state: EditorState, dispatch: any, pos?: number) => {
  if (!dispatch) return false;

  if (pos === null && state.selection.$from.pos === state.selection.$to.pos) return false;

  const resolvedPos = pos != null ? state.doc.resolve(pos) : state.selection.$from;
  const reference = state.doc.nodeAt(resolvedPos.pos);
  if (!reference || reference.type !== schema.nodes.reference) return false;

  // if the reference references a parent note, don't expand because it's a
  // circular reference
  const [node] = findParent(state.doc, resolvedPos.pos, (n) => n.attrs.noteId === reference.attrs.linkedNoteId);
  if (node) {
    return false;
  }

  const tr = state.tr;
  expandOrCollapseReference(tr, resolvedPos);

  // The selection should be set AFTER setNodeMarkup in
  // expandOrCollapseReference. otherwise the markup destroys the previous
  // selection.
  tr.setSelection(NodeSelection.create(tr.doc, tr.mapping.map(resolvedPos.pos)));

  tr.setMeta("type", "toggleReference");
  tr.setMeta("noChangeToModel", true);
  trackEvent("toggle_relation");
  dispatch(tr);
  return true;
};

/**
 * Insert an expanded reference at the given position.
 *
 * @param tr Transaction
 * @param pos Position to insert at
 * @param note Note which the reference is pointing to
 * @param context Whether the reference is in a note or backlinks section, and
 *  if in a note, the associated reference's token id
 * @returns
 */
export const insertExpandedReference = (
  tr: Transaction,
  pos: number,
  noteId: NoteId,
  context: { parent: "note"; referenceTokenId: string } | { parent: "backlinks" },
) => {
  // Get path to parent note
  const [parentNoteNode] = findParent(tr.doc, pos, (n) => n.type === schema.nodes.note);
  const parentPath = parentNoteNode?.attrs.path;
  if (!parentPath) {
    throw new Error(`Expansion parent missing path`);
  }

  const note = appNoteStore.get(noteId);
  if (!note) throw new Error(`Referenced note not found: ${noteId}`);

  let noteNode: Node;
  if (context.parent === "backlinks") {
    noteNode = noteToBacklinkProsemirrorNode(note, parentNoteNode);
  } else {
    const dir = getPathToReference(parentPath, context.referenceTokenId);
    noteNode = noteToProsemirrorNode(note, { path: dir });
  }
  const expansionNode = schema.nodes.expandedReference.create(
    {
      tokenId: generateId(),
      referenceTokenId: context.parent === "note" ? context.referenceTokenId : null,
      containedNoteId: noteId,
      isBacklink: context.parent === "backlinks",
      circular: parentNoteNode.attrs.path.split("/").includes(noteId),
    },
    noteNode,
  );
  tr.insert(pos, expansionNode);

  return tr;
};

/**
 * Adds necessary steps to a PM transaction in order to expand a reference at the given resolved position.
 *
 * @param tr The transaction to add steps to
 * @param resolvedPos The resolved position of the reference to expand
 * @param node The node to insert into the expansion. if not provide, the
 *    reference's linked note attribute will be used to find the node.
 * @returns Whether the expansion was successful
 */
export const expandOrCollapseReference = (tr: Transaction, resolvedPos: ResolvedPos, node?: Node): boolean => {
  // Get reference and path to reference
  const reference = tr.doc.nodeAt(resolvedPos.pos);
  if (!reference || reference.type !== schema.nodes.reference) {
    throw new Error("no reference found at given position");
  }
  const { attrs } = reference;
  const [parentNoteNode] = getParentNote(tr.doc, resolvedPos.pos);
  if (!parentNoteNode) throw new Error("no parent note found for reference");
  const pathReference = getPathToReference(parentNoteNode.attrs.path, attrs.tokenId);

  if (!attrs.isExpanded) {
    if (parentNoteNode.attrs.path.includes(reference.attrs.linkedNoteId)) {
      logger.info("Prevented circular reference expansion", {
        namespace: "editor",
      });
      return false;
    }
    const posExpandedRef = getLinkedNotePosFromReferencePos(tr.doc, resolvedPos.pos, "start");
    insertExpandedReference(tr, posExpandedRef, node || attrs.linkedNoteId, {
      parent: "note",
      referenceTokenId: attrs.tokenId,
    });
    expansionSet.add(pathReference);
  } else {
    // collapse
    const startPos = getLinkedNotePosFromReferencePos(tr.doc, resolvedPos.pos, "start");
    const endPos = getLinkedNotePosFromReferencePos(tr.doc, resolvedPos.pos, "end");
    tr.delete(startPos, endPos);
    expansionSet.delete(pathReference);
  }
  // Toggle reference isExpanded state
  tr.setNodeMarkup(resolvedPos.pos, undefined, {
    ...attrs,
    isExpanded: !attrs.isExpanded,
  });

  return true;
};

/**
 * Maps an expanded note pos in doc to the pos of its corresponding reference node.
 * If the given pos does not point to an expanded note, it throws.
 */
export const getReferencePosFromExpandedNotePos = (doc: Node, pos: number): number => {
  const resolvedPos = doc.resolve(pos);
  const expandedNote = doc.nodeAt(pos);
  if (!expandedNote || expandedNote.type !== schema.nodes.expandedReference) {
    throw new Error("Could not find an expandedNote at given position");
  }

  // First find paragraph that comes right before current set of expandedNotes.
  const expandedNoteIndex = resolvedPos.index(resolvedPos.depth);
  const parentNode = resolvedPos.parent;
  let paragraphIndex = -1;
  for (let i = expandedNoteIndex - 1; i >= 0; i--) {
    const child = parentNode.child(i);
    if (child.type === schema.nodes.paragraph) {
      paragraphIndex = i;
      break;
    }
  }
  if (paragraphIndex === -1) {
    throw new Error("Invalid state: could not find a paragraph before an expandedNote");
  }

  const expandedReferenceCount = expandedNoteIndex - paragraphIndex;

  const paragraph = parentNode.child(paragraphIndex);
  const paragraphPos = resolvedPos.posAtIndex(paragraphIndex);

  /**
   * Iterate through expanded references in that paragraph, finding the one that
   * corresponds to the currently expanded expandedNote.
   */
  let expandedReferencesSoFar = 0;
  let referencePos = -1;
  let breakLoop = false;
  paragraph.forEach((inlineNode, offset) => {
    if (breakLoop) return;

    if (inlineNode.type === schema.nodes.reference && inlineNode.attrs.isExpanded) {
      expandedReferencesSoFar++;
    }

    if (expandedReferencesSoFar === expandedReferenceCount) {
      referencePos = paragraphPos + offset + 1; // +1 for start of paragraph node
      breakLoop = true;
    }
  });

  if (referencePos === -1) {
    throw new Error("invalid state: could not find a reference matching an expandedNote");
  }

  return referencePos;
};

/**
 * Maps a reference pos in doc to the pos of its corresponding expanded note.
 *
 * If the reference is not yet expanded, it returns the position at which the
 * expanded note should be added.  Pass side = 'end' to get pos at end of the
 * expanded note rather than start.
 *
 * If the given pos does not point to a reference, it throws.
 */
export const getLinkedNotePosFromReferencePos = (doc: Node, pos: number, side: "start" | "end" = "start"): number => {
  const resolvedPos = doc.resolve(pos);
  const reference = doc.nodeAt(pos);
  if (!reference || reference.type !== schema.nodes.reference) {
    throw new Error("Could not find a reference at given position");
  }

  // First find the pos at which we need to insert the expanded note, by
  // checking if the paragraph already has other references expanded.
  const paragraph = resolvedPos.parent;
  let expandedReferenceOffset = 0;
  let breakLoop = false;
  paragraph.forEach((inlineNode) => {
    if (breakLoop) return;

    if (inlineNode.eq(reference)) {
      breakLoop = true;
      return;
    }

    if (inlineNode.type === schema.nodes.reference && inlineNode.attrs.isExpanded) {
      expandedReferenceOffset++;
    }
  });

  // We insert expanded note nodes after the paragraph, then after other
  // expanded notes from that paragraph, keeping order of references and their
  // expanded notes in sync.
  return resolvedPos.posAtIndex(
    resolvedPos.indexAfter(-1) + expandedReferenceOffset + (side === "start" ? 0 : 1), // +1 for paragraph
    -1,
  );
};

/**
 * This function wraps a transaction-modifying block of code so that the wrapped
 * block doesn't have to worry about expanded references in the paragraphs it is
 * splicing. It first collapses all expanded references in the paragraph
 * containing the selection and re-expands them after updating the transactions.
 * This means the wrapped block of code can work as if no references are ever
 * expanded. Most of the time, this produces reasonable expected behavior on
 * edits. Sometimes this does not, e.g. backspacing into a previous paragraph.
 *
 */
export const preserveExpandedReferencesAround = (state: EditorState, tr: Transaction, performTrs: () => void): void => {
  // const { $from } = state.selection;
  const { $from } = tr.selection;

  // If any references are expanded in the current paragraph being split:
  // 1. Collapse all those references, and remember their positions
  // 2. Split the paragraph
  // 3. Best-effort: re-expand any of those references given the new positions
  const paragraph = $from.parent;
  const paragraphPos = $from.posAtIndex($from.index($from.depth - 1), $from.depth - 1);
  const referencePositions: number[] = [];
  paragraph.forEach((inlineNode, offset) => {
    if (inlineNode.type === schema.nodes.reference && inlineNode.attrs.isExpanded) {
      referencePositions.push(paragraphPos + offset + 1); // +1 for paragraph boundary
    }
  });

  for (const pos of referencePositions) {
    const resolvedPos = tr.doc.resolve(tr.mapping.map(pos));
    expandOrCollapseReference(tr, resolvedPos);
  }
  performTrs();

  for (const pos of referencePositions) {
    const resolvedPos = tr.doc.resolve(tr.mapping.map(pos));
    if (tr.doc.nodeAt(tr.mapping.map(pos))?.type === state.schema.nodes.reference) {
      expandOrCollapseReference(tr, resolvedPos);
    }
  }
};

/**
 * Checks if a caret is on top of a reference button.
 * @param selection
 */
export const isCaretOnReference = (selection: Selection): boolean => {
  const { $from, $to } = selection;
  return !!(
    selection instanceof NodeSelection &&
    $from.nodeAfter &&
    $from.nodeAfter.type === schema.nodes.reference &&
    $to.pos - $from.pos === 1
  );
};

/**
 * Returns true if the given reference is circular.
 * @param rpos resolved position of a reference node
 * @throws if the given position is not a reference node
 */
export function isCircularReference(rpos: ResolvedPos): boolean {
  const { pos, doc } = rpos;
  const reference = getNode(doc, pos, schema.nodes.reference);
  const [node] = findParent(
    doc,
    pos,
    (node) => node.type === schema.nodes.note && node.attrs.noteId === reference.attrs.linkedNoteId,
  );
  return node !== null;
}
