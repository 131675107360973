import React from "react";
import saveAs from "file-saver";
import Hls, { Events } from "hls.js";
import { isLocal } from "../../../utils/environment";
import { AudioMenu } from "./SimpleHamburgerMenu";
import CustomAudioElement from "./CustomAudioElement";

export function AudioPlayer(props: {
  urls: { url: string; duration: number | null }[];
  onError(e: React.SyntheticEvent<HTMLAudioElement, Event>): void;
  showDebugChunks?: boolean;
  menuItems: typeof AudioMenu.prototype.props.items;
  children: React.ReactNode;
}) {
  return (
    <div className="audio-player">
      <main>
        {/* Legacy audio notes don't have the durations attribute */}
        {props.urls.length === 1 && props.urls[0].duration === null ? (
          <audio src={props.urls[0].url} controls style={{ display: "block", width: "100%" }} onError={props.onError} />
        ) : (
          <AudioCombinePlayer
            urls={props.urls}
            onError={props.onError}
            showDebugChunks={props.showDebugChunks}
            menuItems={props.menuItems}
          />
        )}
      </main>
      <footer>{props.children}</footer>
    </div>
  );
}

function AudioCombinePlayer({
  urls,
  onError,
  showDebugChunks,
  menuItems,
}: {
  urls: { url: string; duration: number | null }[];
  onError(e: React.SyntheticEvent<HTMLAudioElement, Event>): void;
  showDebugChunks?: boolean;
  menuItems: typeof AudioMenu.prototype.props.items;
}) {
  const urlsWithDurationsFixed = React.useMemo(
    () => urls.filter((t): t is { url: string; duration: number } => t.duration !== null),
    [urls],
  );

  // If the "Media Source Extensions API" is not available we cannot use the HLS.js library to play the m3u8 file
  // This happens on iPhones but not on iPads. Thankfully Safari on iOS can play HLS natively (although it doesn't accept non-integer durations)
  const shouldTryToPlayHLSNatively = React.useMemo(() => {
    // eslint-disable-next-line no-eval
    return eval('"undefined" === typeof MediaSource');
  }, []);

  const hlsContent = React.useMemo(() => {
    // HLS file, see: https://datatracker.ietf.org/doc/html/rfc8216
    const file = `
#EXTM3U
#EXT-X-VERSION:7
#EXT-X-TARGETDURATION:${Math.max(...urlsWithDurationsFixed.map((t) => Math.ceil(t.duration)))}
#EXT-X-MEDIA-SEQUENCE:1
#EXT-X-PLAYLIST-TYPE:VOD
#EXT-X-INDEPENDENT-SEGMENTS
${urlsWithDurationsFixed
  .map(({ url, duration }) =>
    `
#EXTINF:${duration},
${url}
`.trim(),
  )
  .join("\n")}
#EXT-X-ENDLIST
      `.trim();
    return file;
  }, [urlsWithDurationsFixed]);

  const hlsSource = React.useMemo(() => {
    return URL.createObjectURL(new Blob([hlsContent], { type: "application/vnd.apple.mpegurl" }));
  }, [hlsContent]);

  async function download() {
    for (const [i, { url }] of urls.entries()) {
      saveAs(url, `file-${(i + "").padStart(3, "0")}`);
      await new Promise((res) => setTimeout(res, 300));
    }
  }

  return (
    <>
      {shouldTryToPlayHLSNatively ? (
        <HLSNativePlayer onError={onError} hlsContent={hlsContent} menuItems={menuItems} />
      ) : (
        <HLSPlayerViaPolyfill onError={onError} hlsSource={hlsSource} menuItems={menuItems} />
      )}
      {isLocal && showDebugChunks && (
        <>
          CHUNKS:
          {urls.map(({ url }, i) => (
            <div key={i}>
              url: {url}
              <audio src={url} controls style={{ display: "block", width: "100%" }} />
            </div>
          ))}
          <button onClick={download}>download all chunks</button>
        </>
      )}
    </>
  );
}

function HLSNativePlayer({
  onError,
  hlsContent,
  menuItems,
}: {
  onError: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void;
  hlsContent: string;
  menuItems: typeof AudioMenu.prototype.props.items;
}) {
  const audioRef = React.useRef<HTMLAudioElement>(null);
  // For some reason Safari doesn't play the m3u8 files from the blob URLs created with
  // URL.createObjectURL(new Blob([hlsContent], { type: "application/vnd.apple.mpegurl" }))
  // Fortunatelly it does play the data URIs, so we must generate one here
  const [url, setUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    const fr = new FileReader();
    fr.readAsDataURL(new Blob([hlsContent], { type: "application/vnd.apple.mpegurl" }));
    fr.onload = (e) => setUrl(fr.result as string);
  }, [hlsContent]);

  /* NATIVE PLAYER */
  if (url) {
    return (
      <CustomAudioElement src={url} audioRef={audioRef} onError={onError} menuItems={menuItems}></CustomAudioElement>
    );
  }
  return null;
}

function HLSPlayerViaPolyfill({
  onError,
  hlsSource,
  menuItems,
}: {
  onError: (e: any) => void;
  hlsSource: string;
  menuItems: typeof AudioMenu.prototype.props.items;
}) {
  const audioRef = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (!audioRef.current) return;
    const hls = new Hls({});
    hls.attachMedia(audioRef.current!);
    hls.loadSource(hlsSource);
    hls.on("hlsError" as Events.ERROR, (_e, data) => {
      onError(data.error);
    });

    return () => {
      hls.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hlsSource, audioRef]);

  /* HLS.JS PLAYER: */
  return <CustomAudioElement audioRef={audioRef} onError={onError} menuItems={menuItems}></CustomAudioElement>;
}
