import { Transaction } from "prosemirror-state";
import { trackEvent } from "../analytics/analyticsHandlers";
import { addToast } from "../components/Toast";
import { registerAsyncNodeOperation } from "../editor/features/utils";
import { schema } from "../editor/schema";
import logger from "../utils/logger";
import { ApiClient } from "../api/client";
import { processExtractWithAiResponse } from "./processExtractWithAiResponse";

/**
 * Create a node which will be replaced with the extracted entities
 * asynchonously.
 *
 * If the operation fails, the node is replaced with the the provided fallback,
 * which can be text or a range in the document. By default it's an empty
 * string.
 */
export function createAsyncExtractedEntitiesNode(
  tr: Transaction,
  text: string,
  fallback: string | { from: number; to: number } = " ",
  onFinished?: () => void,
) {
  const fallbackText = typeof fallback === "string" ? fallback : tr.doc.textBetween(fallback.from, fallback.to);
  const fallbackNodes =
    typeof fallback === "string" ? [schema.text(fallback)] : tr.doc.slice(fallback.from, fallback.to).content;

  // Register async request to get extracted entities for the given text
  const operationId = registerAsyncNodeOperation(async (operationId) => {
    const result = await ApiClient().notes.extractEntities(operationId, text);
    trackEvent("extract_entities");
    try {
      // check for errors
      if (result.error || !result.data) {
        throw new Error(`Response not ok: ${result.data} - ${result.error}`);
      }

      const { outputText } = result.data;
      // handle empty response
      if (!outputText) {
        addToast("No entities found");
        return fallbackNodes;
      }
      // handle success
      return processExtractWithAiResponse(outputText);
    } catch (e) {
      logger.error("Extract entities failed", { error: e });
      addToast("Extract entities isn't available right now. Try again later.");
      return fallbackNodes;
    } finally {
      onFinished?.();
    }
  });
  return schema.nodes.asyncReplacedElement.create({
    operationId,
    operationStartTimestamp: Date.now(),
    fallbackText,
    fallbackNodes,
  });
}
