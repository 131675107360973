import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { colors } from "../../../utils/style";
import { PinnedItem } from "../../../../shared/types";
import { PinnedNote } from "./PinnedNote";
import { PinnedHashtag } from "./PinnedHashtag";

interface Props {
  elements: PinnedItem[];
  setElements: (draggedItem: PinnedItem, result: PinnedItem[]) => void;
  setSearch: SearchQuerySetter;
}

export const PinnedList = ({ elements, setElements, setSearch }: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const content = elements.map((el) => {
    if (el.type === "note") {
      return <PinnedNote key={el.id} noteId={el.id} setSearch={setSearch} />;
    }
    if (el.type === "hashtag") {
      return <PinnedHashtag key={el.id} hashtag={el.id} setSearch={setSearch} />;
    }
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeEl = elements.find((el) => el.id === active.id)!;
      const overEl = elements.find((el) => el.id === over.id)!;
      const oldIndex = elements.indexOf(activeEl);
      const newIndex = elements.indexOf(overEl);

      const newArr = arrayMove(elements, oldIndex, newIndex);
      setElements(activeEl, newArr);
    }
  };

  if (content.length === 0)
    return (
      <div
        style={{
          color: colors.text.secondary,
          fontSize: 14,
          textAlign: "left",
          marginLeft: "1em",
          marginTop: "0.4em",
          marginBottom: "0.4em",
          fontStyle: "italic",
        }}
      >
        (no notes referenced)
      </div>
    );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={elements} strategy={verticalListSortingStrategy}>
        {content}
      </SortableContext>
    </DndContext>
  );
};
