import { Selection, Command } from "prosemirror-state";
import { schema } from "../../schema";
import { appNoteStore } from "../../../model/services";
import { generateId } from "../../../model/generateId";
import { noteToProsemirrorNode } from "../../bridge";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { findParent } from "../../utils/find";
import {
  expandOrCollapseReference,
  getLinkedNotePosFromReferencePos,
  getReferencePosFromExpandedNotePos,
} from "./referenceExpansionUtils";

/**
 * Create new note and append expanded reference to it below current selection.
 */
export const appendExpandedReferenceCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;

  const { $from, $to } = state.selection;

  // Get root note and expansion
  const rootNote = $from.node(1);
  const [expansion, posExpansion] = findParent(state.doc, $from.pos, (n) => n.type === schema.nodes.expandedReference);

  // selection must be inside an expansion
  if (!expansion) {
    return false;
  }
  const posExpansionEnd = posExpansion + expansion.nodeSize;
  if ($from.pos < posExpansion || $to.pos > posExpansionEnd) {
    return false;
  }

  // Don't allow command inside a backlink expansion
  const expParent = state.doc.resolve(posExpansion).parent;
  if (expParent.type === schema.nodes.backlinks) {
    return false;
  }

  const tr = state.tr;

  const note = appNoteStore.get(rootNote.attrs.noteId);
  if (!note) return false;

  // create new blank note and insert below
  const [newNote] = appNoteStore.insert({
    position: appNoteStore.notePositions.generateAfter(note.position)[0],
  });
  tr.insert(tr.selection.$anchor.after(1), noteToProsemirrorNode(newNote));

  // create a reference to the new note and insert beside the
  // reference linked to the current expansion
  const posReference = getReferencePosFromExpandedNotePos(state.doc, tr.mapping.map(posExpansion));
  const newReference = schema.nodes.reference.create({
    tokenId: generateId(),
    linkedNoteId: newNote.id,
  });
  tr.insert(posReference + 1, [schema.text(" "), newReference]);
  const posNewReference = posReference + 2; // +1 for the space, +1 for the new node

  // Expand the new reference
  if (tr.doc.nodeAt(posNewReference)?.attrs.linkedNoteId !== newNote.id) {
    throw new Error("Can't find new reference to expand");
  }
  expandOrCollapseReference(tr, tr.doc.resolve(posNewReference));
  const posNewExpansion = getLinkedNotePosFromReferencePos(tr.doc, posNewReference);
  // Set the cursor inside it
  tr.setSelection(Selection.near(tr.doc.resolve(posNewExpansion)));

  tr.setMeta("type", "appendExpansion");
  dispatch(tr);

  trackEvent("append_expanded_relation");

  return true;
};
