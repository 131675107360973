import { ArrowUp, Plus } from "react-feather";
import styled from "@emotion/styled";
import { NOTE_LIMIT_INCREMENT } from "../search/Searcher";
import { updateSearchQuery } from "../search/useSearchQuery";

const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;

const ShowMoreButton = styled.button`
  /* 
  The following is necessary to preserve the correct z order of elements.
  Without it the button is obstructed by the autocomplete container.
  */
  position: relative;
  min-height: 44px;
  border: 0;
  color: var(--color-text-secondary);
  background-color: var(--color-bg-accent-quaternary);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0 0.5rem;
  :hover {
    color: var(--color-text-primary);
    background-color: var(--color-bg-secondary);
  }
`;

export function ShowMoreAboveButtons({ lowerLimit, upperLimit }: { lowerLimit: number; upperLimit: number }) {
  return (
    <ShowMoreContainer>
      <ShowMoreButton
        onClick={() =>
          updateSearchQuery((q) => ({
            ...q,
            lowerLimit: 0,
            upperLimit: undefined,
            jumpTo: undefined,
          }))
        }
      >
        <ArrowUp />
        <span>Jump to top</span>
      </ShowMoreButton>
      <ShowMoreButton
        onClick={() =>
          updateSearchQuery((q) => ({
            ...q,
            lowerLimit: Math.max(0, lowerLimit - NOTE_LIMIT_INCREMENT),
            upperLimit,
            jumpTo: undefined,
          }))
        }
      >
        <Plus />
        <span>Load more ({lowerLimit} hidden)</span>
      </ShowMoreButton>
    </ShowMoreContainer>
  );
}

export function ShowMoreBelowButtons({
  lowerLimit,
  upperLimit,
  count,
}: {
  lowerLimit: number;
  upperLimit: number;
  count: number;
}) {
  return (
    <ShowMoreContainer>
      <ShowMoreButton
        onClick={() =>
          updateSearchQuery((q) => ({
            ...q,
            lowerLimit,
            upperLimit: upperLimit + NOTE_LIMIT_INCREMENT,
            jumpTo: undefined,
          }))
        }
      >
        <Plus />
        <span>Load more ({count - upperLimit} hidden)</span>
      </ShowMoreButton>
    </ShowMoreContainer>
  );
}
