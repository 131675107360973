import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { colors } from "../utils/style";
import Suggestion from "./Suggestion";

interface Props {
  suggestion: Suggestion;
  isHighlighted: boolean;
  setAsActiveElement: () => void;
  onClick: () => void;
}

const SuggestionText = styled.span`
  display: block;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReferenceCountPill = styled.span`
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 0.9em;
  background-color: ${colors.bg.secondary};
  color: ${colors.text.secondary};
  padding: 1px 5px 0;
  border-radius: 5px;
  vertical-align: middle;
`;

export default function MenuItem({ suggestion, isHighlighted, setAsActiveElement, onClick }: Props) {
  const referenceCount = suggestion.extraData?.referenceCount ?? 0;
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isHighlighted) {
      divRef.current?.scrollIntoView({ block: "nearest" });
    }
  }, [isHighlighted]);
  return (
    <div
      className={`suggestion-item ${suggestion.action ? `suggestion-${suggestion.action}` : ""} ${
        isHighlighted ? "suggestion-item-active" : ""
      }`}
      onMouseDown={(e) => {
        e.preventDefault();
        onClick();
      }}
      onMouseOver={() => setAsActiveElement()}
      onMouseOut={() => setAsActiveElement()}
      ref={divRef}
    >
      <SuggestionText>{suggestion.display ?? suggestion.content}</SuggestionText>
      {referenceCount > 0 && <ReferenceCountPill>{referenceCount}</ReferenceCountPill>}
    </div>
  );
}
