import { FetchWrapper } from "../fetchWrapper";
import { UserPublicNotesResponse, UserSettings } from "../../../shared/types";

export const UserApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Gets a logged in user.
     * @param id
     */
    get: async (
      id: string,
    ): Promise<{
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      handle: string | null;
    }> => client.get(`/user`),
    /**
     * Deletes a logged in user.
     */
    delete: async (): Promise<{ deleted: boolean }> => client.delete(`/user`),
    /**
     * Creates a new user
     */
    create: async (payload: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      settings: UserSettings;
    }): Promise<{ status: boolean }> => client.post(`/users`, payload),
    /**
     * updates a logged in user.
     * @param body
     */
    update: async (body: { password: string }): Promise<{ updated: true } | { updated: false; error: string }> =>
      client.patch(`/user`, body),

    /**
     * Update a handle for logged in user
     */
    updateHandle: async (newHandle: string): Promise<{ status: boolean }> =>
      client.patch(`/user/handle`, {
        handle: newHandle,
      }),
    /**
     * Get all public notes of a user
     * @param id user id
     */
    getPublicNotes: async (id: string): Promise<UserPublicNotesResponse> => client.get(`/users/${id}/public-notes`),
    createDefaultNotes: async (): Promise<{ status: boolean }> => client.post(`/user/initialize`),
    updateSettings: async (settings: Record<string, unknown>): Promise<{ status: boolean }> =>
      client.patch(`/user/settings`, {
        settings,
      }),
  };
};
