import { NoteId } from "../../../shared/types";

export class FirstLineIndex {
  private firstLineToNotes: Map<string, NoteId[]> = new Map();
  private noteToFirstLine: Map<NoteId, string> = new Map();

  set(noteId: NoteId, firstLine: string) {
    const oldFirstLine = this.noteToFirstLine.get(noteId);
    if (oldFirstLine) {
      this.firstLineToNotes.set(
        oldFirstLine,
        this.firstLineToNotes.get(oldFirstLine)!.filter((id) => id !== noteId),
      );
    }

    const normalized = firstLine.toLowerCase().trim();
    this.noteToFirstLine.set(noteId, normalized);
    const current = this.firstLineToNotes.get(normalized);
    if (current) {
      current.push(noteId);
    } else {
      this.firstLineToNotes.set(normalized, [noteId]);
    }
  }

  get(firstLine: string) {
    const normalized = firstLine.toLowerCase().trim();
    const noteIds = this.firstLineToNotes.get(normalized);
    if (!noteIds || noteIds.length === 0) return null;
    return noteIds;
  }

  clear() {
    this.firstLineToNotes.clear();
    this.noteToFirstLine.clear();
  }
}
