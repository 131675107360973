import { colors, zIndexes } from "../utils/style";
import { ICON_STYLES } from "./Buttons";

export const BUTTON_SIZE = 64;

export const FLOATING_BUTTON_STYLES: React.CSSProperties = {
  position: "fixed",
  display: "flex",
  height: BUTTON_SIZE,
  width: BUTTON_SIZE,
  zIndex: zIndexes.newNoteButton,
  justifyContent: "center",
  alignItems: "center",
  color: colors.bg.primary,
  borderRadius: "100%",
  cursor: "pointer",
  // disable selection
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  KhtmlUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export const FLOATING_ICON_STYLES: React.CSSProperties = {
  ...ICON_STYLES,
  strokeWidth: "1.5px",
};
