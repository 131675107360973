import styled from "@emotion/styled";
import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { Edit2, ExternalLink, Link2, X } from "react-feather";
import { useAuth } from "../auth/useAuth";
import { addToast } from "../components/Toast";
import { getUserHandleOrId, isLoadedAtom } from "../model/atoms";
import { appFolderStore } from "../model/services";
import { hasHeader, SearchQuery } from "../search/SearchQuery";
import { updateSearchQuery, useSearchQuery } from "../search/useSearchQuery";
import { FolderIcon } from "../sidebar/img/FolderIcon";
import { colors, radii, zIndexes } from "../utils/style";
import logger from "../utils/logger";
import { regexpMatchers } from "../editor/utils/mark/regexpMatchers";
import { EditInput } from "../sidebar/SidebarItem/EditInput";
import { isMobile } from "../utils/environment";
import { renameHashtag } from "../model/store/utils";
import { trackEvent } from "../analytics/analyticsHandlers";
import { fixPublicUrlInNativeApp } from "./utils/fixPublicUrlInNativeApp";
import { SearchResultsHeader } from "./SearchResultsHeader";

interface HashtagComponentProps {
  hashtag: string;
  onEdit: (newHashtag: string) => void;
  onDelete: () => void;
}

interface FolderComponentProps {
  folderId: string;
  folderName: string;
  onEdit: (newName: string) => void;
}

const EditorHeaderFormatter = Intl.DateTimeFormat([], {
  weekday: "long",
  day: "numeric",
  month: "long",
});

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Helvetica Neue"; // override for iOS to ensure consistent Unicode font sizing
  font-size: 20;
  border: none;
  background: none;
  color: ${colors.text.secondary};
  border-radius: ${radii.medium};
  :hover {
    color: ${colors.text.primary};
  }
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Helvetica Neue";
  padding: 4px;
  border: 1px solid ${colors.border.primary};
  background: rgba(255, 255, 255, 0.7);
  color: ${colors.text.secondary};
  border-radius: ${radii.medium};
  :hover {
    color: ${colors.text.primary};
  }
  position: absolute;
  top: 0;
  transform: translateY(30%);
`;

const LinkButton = styled.button`
  padding: 8px;
  border-radius: 4px;
  background: transparent;
  z-index: 60;
  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: var(--color-bg-accent-secondary);
  }
`;

const Wrapper = styled.div<{ shouldWrap: boolean }>`
  display: flex;
  align-items: center;
  ${({ shouldWrap }) => shouldWrap && "flex-wrap: wrap;"}
`;

const HashtagComponent: React.FC<HashtagComponentProps> = ({ hashtag, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsEditing(true);
    setIsHovering(false);
  };

  const handleEditSubmit = (newHashtag: string) => {
    onEdit(newHashtag);
    setIsEditing(false);
    setIsHovering(false);
    setIsFocused(false);
  };

  const handleDelete = () => {
    onDelete();
  };

  // Listens for all taps.
  // If the tap is on/in the hashtag, we show the edit button.
  // If it’s outside the hashtag, we hide the button
  useEffect(() => {
    if (!isMobile) return;
    const handleClick = (event: MouseEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        setIsFocused(true);
      } else if (isFocused) {
        setIsFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, isFocused]);

  return (
    <div
      ref={ref}
      style={{ position: "relative" }}
      onMouseEnter={() => {
        if (isMobile) return;
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        if (isMobile) return;
        setIsHovering(false);
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Wrapper shouldWrap={Boolean(ref.current?.scrollWidth ?? 0 > (ref.current?.clientWidth ?? 0))}>
          <div style={{ position: "relative" }}>
            {isEditing ? (
              <EditInput
                submitEditChanges={handleEditSubmit}
                errorMessage={error}
                setErrorMessage={setError}
                setIsEditing={setIsEditing}
                content={hashtag}
              />
            ) : (
              <span>{hashtag}</span>
            )}
            {(isHovering || isFocused) && !isEditing && (
              <EditButton className="hashtag-edit-button" onClick={handleClick}>
                <Edit2 size={15} />
              </EditButton>
            )}
          </div>
          <CloseButton onClick={handleDelete} style={{ cursor: "pointer", marginLeft: 4 }}>
            <X size={20} />
          </CloseButton>
        </Wrapper>
      </div>
    </div>
  );
};

const FolderComponent: React.FC<FolderComponentProps> = ({ folderId, folderName, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsEditing(true);
    setIsHovering(false);
  };

  const handleEditSubmit = (newName: string) => {
    onEdit(newName);
    setIsEditing(false);
    setIsHovering(false);
    setIsFocused(false);
  };

  useEffect(() => {
    if (!isMobile) return;
    const handleClick = (event: MouseEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        setIsFocused(true);
      } else if (isFocused) {
        setIsFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, isFocused]);

  return (
    <div
      ref={ref}
      style={{ position: "relative" }}
      onMouseEnter={() => {
        if (isMobile) return;
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        if (isMobile) return;
        setIsHovering(false);
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ position: "relative" }}>
          {isEditing ? (
            <EditInput
              submitEditChanges={handleEditSubmit}
              errorMessage={error}
              setErrorMessage={setError}
              setIsEditing={setIsEditing}
              content={folderName}
            />
          ) : (
            <span>{folderName}</span>
          )}
          {(isHovering || isFocused) && !isEditing && (
            <EditButton className="folder-edit-button" onClick={handleClick}>
              <Edit2 size={15} />
            </EditButton>
          )}
        </div>
      </div>
    </div>
  );
};

function getSearchTitle(query: SearchQuery, userId: string | undefined): JSX.Element | null {
  const titles: JSX.Element[] = [];
  const folder = query.folderId ? appFolderStore.get(query.folderId) : null;

  const handleHashtagEdit = (oldHashtag: string, newHashtag: string) => {
    if (oldHashtag === newHashtag) {
      return;
    }
    const match = newHashtag.match(regexpMatchers.hashtag);
    if (match?.[0].length !== newHashtag.length) {
      if (!newHashtag.startsWith("#")) {
        addToast({ content: "Hashtag must start with #" });
      } else if (newHashtag.length < 2) {
        addToast({ content: "Hashtag cannot be empty" });
      } else if (newHashtag.includes(" ")) {
        addToast({ content: "Hashtag cannot contain spaces" });
      } else {
        addToast({ content: "Invalid hashtag syntax" });
      }
      return;
    }
    if (newHashtag.length > 50) {
      addToast({ content: "Hashtag must be shorter than 50 characters" });
      return;
    }

    renameHashtag(oldHashtag, newHashtag);
    updateSearchQuery({ hashtagsList: [newHashtag] });
    const toastMessage = `${oldHashtag} renamed to ${newHashtag}\n`;
    addToast({
      content: toastMessage,
      buttons: [
        {
          text: "undo?",
          onClick() {
            try {
              renameHashtag(newHashtag, oldHashtag);
              updateSearchQuery({ hashtagsList: [oldHashtag] });
            } catch (e) {
              logger.error(e);
            }
          },
        },
      ],
    });
    trackEvent("update_hashtag", userId);
  };

  const handleHashtagDelete = (index: number) => {
    if (!query.hashtagsList) return;
    const newHashtagsList = [...query.hashtagsList];
    newHashtagsList.splice(index, 1);
    updateSearchQuery({
      ...query,
      hashtagsList: newHashtagsList.length > 0 ? newHashtagsList : undefined,
      source: "other",
    });
  };

  const handleFolderEdit = (folderId: string, newName: string) => {
    if (newName.trim() === "") {
      addToast({ content: "Folder cannot be empty" });
      return;
    }

    appFolderStore.update({ id: folderId, name: newName }, true);
    updateSearchQuery({ ...query, folderId, source: "other" });
    trackEvent("update_folder", folderId);
  };

  if (folder) {
    titles.push(
      <>
        <FolderIcon size={28} color={colors.text.secondary} />
        <span style={{ paddingLeft: 4 }} />
        <FolderComponent
          folderId={folder.id}
          folderName={folder.name}
          onEdit={(newName) => handleFolderEdit(folder.id, newName)}
        />
      </>,
    );
  }
  if (query.date) titles.push(<span>{EditorHeaderFormatter.format(query.date)}</span>);
  if (query.hasTodo || query.hasComplete || query.hasIncomplete) titles.push(<span>To-dos</span>);
  if (query.hashtagsList) {
    query.hashtagsList.forEach((hashtag, index) => {
      titles.push(
        <HashtagComponent
          key={index}
          hashtag={hashtag}
          onEdit={(newHashtag) => handleHashtagEdit(hashtag, newHashtag)}
          onDelete={() => handleHashtagDelete(index)}
        />,
      );
    });
  }
  if (query.isPublic) {
    titles.push(
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <span>Publicly Shared Notes</span>
        <div style={{ display: "flex", gap: "8px" }}>
          <LinkButton
            role="tooltip"
            data-microtip-position="left"
            aria-label="Go to your public feed"
            onClick={() => {
              const handleOrId = getUserHandleOrId();
              const publicThoughtstreamUrl = fixPublicUrlInNativeApp(
                window.location.protocol + "//" + window.location.host + "/sharedBy/" + handleOrId,
              );
              try {
                window.location.href = publicThoughtstreamUrl;
              } catch (e) {
                logger.error(e);
              }
            }}
          >
            <ExternalLink color="var(--color-text-secondary)" size={20} strokeWidth={2} />
          </LinkButton>
          <LinkButton
            role="tooltip"
            data-microtip-position="left"
            aria-label="Copy link to public feed"
            onClick={() => {
              const handleOrId = getUserHandleOrId();
              const publicThoughtstreamUrl = fixPublicUrlInNativeApp(
                window.location.protocol + "//" + window.location.host + "/sharedBy/" + handleOrId,
              );
              if (handleOrId) {
                if (!(navigator as any).clipboard) return;
                (navigator as any).clipboard.writeText(publicThoughtstreamUrl);
                addToast({
                  content: "Link to public feed copied to clipboard!",
                  buttons: [
                    {
                      text: "Take me there",
                      onClick() {
                        try {
                          window.location.href = publicThoughtstreamUrl;
                        } catch (e) {
                          logger.error(e);
                        }
                      },
                    },
                  ],
                });
              } else {
                logger.error("No handle or id found for user", { context: { handleOrId } });
                addToast("Error copying public thoughtstream link to clipboard");
              }
            }}
          >
            <Link2 color="var(--color-text-secondary)" size={20} strokeWidth={2} />
            {/* Copy link to your public notes feed */}
          </LinkButton>
        </div>
      </div>,
    );
  }
  if (titles.length === 0) return null;
  return titles.reduce((prev, curr) => (
    <>
      {prev}
      <span style={{ paddingLeft: 4, paddingRight: 4 }}>&</span>
      {curr}
    </>
  ));
}

const TitleStyle = styled.h1`
  margin: 0;
  margin-top: 6px;
  font-weight: normal;
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color-text-strong);
  white-space: nowrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const EditorHeader = () => {
  const searchQuery = useSearchQuery();
  const { user } = useAuth();
  // Subscribe to the isLoadedAtom to regenerate the editor header once the data is loaded
  useAtomValue(isLoadedAtom);

  const title = getSearchTitle(searchQuery, user?.id);

  if (searchQuery.isAll) {
    return null;
  }

  return (
    <div
      className="editorHeader"
      style={{
        width: "calc(100% - 48px)",
        maxWidth: 720,
        marginTop: 16,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: -6,
        zIndex: zIndexes.editorHeader,
      }}
    >
      {hasHeader(searchQuery) && <SearchResultsHeader setSearchQuery={updateSearchQuery} searchQuery={searchQuery} />}
      {title && <TitleStyle>{title}</TitleStyle>}
    </div>
  );
};
