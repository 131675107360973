import { useAtomValue } from "jotai";
import { useRef, useEffect, useCallback } from "react";
import { useCreateNoteAtTop } from "../editorPage/hooks/useCreateNote";
import { AudioInsertToken, TopLevelToken } from "../../shared/types";
import { editorViewAtom, getEditorView } from "../model/atoms";
import { globalAudioRecordingCoordinator } from "../editor/features/audioInsert/audioRecordingCoordinator";
import { generateId } from "../model/generateId";
import { makeParagraph } from "../model/defaults";
import appLogger from "../utils/logger";

const logger = appLogger.with({ namespace: "nativeIntegrations/hooks" });

/**
 * This function is necessary for when the app is started with a queue of notes to be added
 * This currently happens when the iOS share sheet is used
 */
export const useCreateNoteAtTopAsSoonAsPossible = () => {
  const createNoteAtTop = useCreateNoteAtTop();
  const queue = useRef<(string | TopLevelToken[])[]>([]);
  const editorView = useAtomValue(editorViewAtom);

  useEffect(() => {
    if (editorView) {
      const nextQueue: (string | TopLevelToken[])[] = [];
      for (const note of queue.current) {
        const wasAdded = createNoteAtTop(note);
        if (!wasAdded) {
          nextQueue.push(note);
        }
      }
      queue.current = nextQueue;
    }
  }, [editorView, createNoteAtTop]);

  function createNote(content: string | TopLevelToken[] = "") {
    if (!createNoteAtTop(content)) {
      queue.current.push(content);
    }
  }
  const newestCreateRef = useRef(createNote);
  newestCreateRef.current = createNote;
  return useCallback((content: string | TopLevelToken[]) => newestCreateRef.current(content), []);
};

/**
 * Start recording audio as soon as the editor and recording system are ready.
 * Retries up to 3 times.
 */
async function startRecordingWhenPossible(audioId: string) {
  for (let i = 0; i < 3; i++) {
    if (getEditorView()) {
      try {
        await globalAudioRecordingCoordinator.startRecording(audioId);
        logger.info("Audio recording started successfully", { context: { attempt: i } });
        return true;
      } catch (error) {
        logger.info("Audio recording failed to start", { context: { attempt: i, error } });
      }
    } else {
      logger.info("Audio recording cannot be started, editor view is not available", { context: { attempt: i } });
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  return false;
}

/**
 * Create a new audio note at the top of the editor as soon as it's possible to do so.
 */
export const useCreateAudioAtTopAsSoonAsPossible = () => {
  const createNote = useCreateNoteAtTop();
  return useCallback(() => {
    if (globalAudioRecordingCoordinator.isRecording()) {
      logger.info("Audio recording already in progress, not starting a new one");
      return;
    }
    const audioId = generateId();
    startRecordingWhenPossible(audioId).then((success) => {
      if (success) {
        const audioInsertToken: AudioInsertToken = {
          type: "audioInsert",
          tokenId: generateId(),
          audioId,
          updatedAt: Date.now(),
          transcriptGenId: null,
          // Set to null to distinguish from legacy audio which has chunkCount = 0
          chunkCount: null,
          durations: null,
          transcript: null,
          isRevealed: true,
          transcriptLanguageOverride: null,
        };
        createNote([makeParagraph(), audioInsertToken, makeParagraph()]);
        logger.info("Audio note created");
      } else {
        logger.error("Audio recording could not be started after 10 attempts");
      }
    });
  }, [createNote]);
};
