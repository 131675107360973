import { appNoteStore } from "../../model/services";
import { SearchQuerySetter } from "../../search/SearchQuery";
import { countReferences } from "../countUtils";
import ElementBrowser, { SidebarElement } from "./ElementBrowser";

export const ReferencedNotesList: React.FC<{
  setSearch: SearchQuerySetter;
}> = ({ setSearch }) => {
  const items: SidebarElement[] = countReferences(appNoteStore).map(({ id, count, content }) => ({
    id,
    count,
    content,
    setSearch: () => setSearch({ noteIdList: [id] }),
  }));

  return (
    <ElementBrowser
      header="Notes with Relations"
      type="note"
      elements={items}
      localStorageKey="referenced_notes_sort"
      placeholderText={"Type + to add your first relation"}
    />
  );
};
