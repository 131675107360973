import { useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useState } from "react";
import { MinusCircle, PlusCircle, XCircle } from "react-feather";
import Joyride from "react-joyride";
import { disableSidebarAnimationAtom, isSidebarOpenAtom, userSettingsAtom } from "../model/atoms";
import { upsertUserSettings } from "../model/userSettings";
import { NEW_NOTE_BUTTON_ID, TODO_LINK_ID } from "../utils/constants";
import {
  isApple,
  isMobileWidth,
  isRunningInIOSWebview,
  isTouchDevice,
  isTutorialEnabled,
  tutorialVideoURL,
} from "../utils/environment";
import { ModalEnum, openModal } from "../model/modals";
import { trackEvent } from "../analytics/analyticsHandlers";
import { addToast } from "./Toast";

export const redisplayVideoAtom = atomWithStorage("redisplayVideo", false);

const joyrideSteps = [
  {
    target: ".editor-div",
    title: "Ideaflow Interactive Tutorial",
    content:
      "Ideaflow is an infinitely long text file with transcribed audio and image inserts too. We call it your thoughtstream. You can instantly capture new thoughts without needing to categorize, and filter them later.",
    showSkipButton: true,
    placement: "center",
    placementBeacon: "top",
    disableOverlayClose: true,
  },
  {
    target: isTouchDevice ? `#${NEW_NOTE_BUTTON_ID}` : `#header-trailing-icons`,
    title: "New Note or Audio Clip",
    content: isTouchDevice
      ? `Tap here to create your first note! Even faster, press the mic to record audio, which is saved and transcribed.`
      : `Press ${
          isApple ? "⌘K" : "Ctrl-K"
        } or click here to create your first note, which appears at the top. Even faster, press the mic to record audio, which is saved and transcribed.`,
    event: "hover",
    isFixed: true,
    showSkipButton: true,
    disableOverlayClose: true,
  },
  {
    target: `#toggle-sidebar-button`,
    title: "Sidebar",
    content: `Open the sidebar for to-dos, hashtags, settings, and more.`,
    event: "hover",
    showSkipButton: true,
    disableOverlayClose: true,
  },
  {
    target: `#${TODO_LINK_ID}-icon`,
    title: "To-dos",
    content: `Click to here to view to-dos. Make any note into a to-do list by typing brackets [ ] or tapping ☑️ on mobile`,
    event: "hover",
    showSkipButton: true,
    disableOverlayClose: true,
  },
  {
    target: ".selected-link-group--hashtag",
    title: "Hashtags",
    content: `${
      isTouchDevice ? "Tap" : "Click"
    } to filter by hashtag. We recommend adding hashtags to your notes to categorize them.`,
    event: "hover",
    placement: "auto",
    disableOverlayClose: true,
  },
];

if (!isMobileWidth) {
  joyrideSteps.push({
    target: `#nav-help-button`,
    title: "Help",
    content: `Click to open the help page. Hit "Last" to finish, and please watch the first 45 seconds of our video :)!`,
    event: "hover",
    showSkipButton: true,
    disableOverlayClose: true,
  });
} else {
  joyrideSteps.push({
    target: "#sidebar-help-button",
    title: "Help",
    content: `Click to open the help page. Hit "Last" to finish, and please watch the 45 seconds of our video :)!`,
    event: "hover",
    showSkipButton: true,
    isFixed: true,
    disableOverlayClose: true,
  });
}

const joyrideOptions = {
  arrowColor: "rgb(66, 66, 66)",
  backgroundColor: "rgb(66, 66, 66)",
  beaconSize: 36,
  overlayColor: "#333",
  primaryColor: "rgb(32, 189, 203)",
  spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
  textColor: "#eee",
  zIndex: 100,
};

export const UserOnboarding = () => {
  const setShowSidebar = useAtom(isSidebarOpenAtom)[1];
  const setDisableAnimation = useAtom(disableSidebarAnimationAtom)[1];
  const [showVideo, setShowVideo] = useState(false);
  const [redisplayVideo, setRedisplayVideo] = useAtom(redisplayVideoAtom);
  const userSettings = useAtomValue(userSettingsAtom);
  const [isMinimized, setIsMinimized] = useState(false);

  const notifyAppReady = () => {
    if (!isRunningInIOSWebview) return;

    console.log("Notifying app ready");
    window.webkit?.messageHandlers.appReady.postMessage({
      type: "appReady",
      isOnboarded: true,
    });
  };

  const dismissOnboarding = () => {
    if (!redisplayVideo) {
      addToast({
        content: (
          <>
            Visit{" "}
            <span
              className="link"
              onClick={() => openModal(ModalEnum.HELP, { openCallback: () => trackEvent("open_help_from_onboarding") })}
            >
              Help
            </span>{" "}
            to replay tutorial.
          </>
        ),
      });
    }
    setShowVideo(false);
    setRedisplayVideo(false);
    notifyAppReady();
  };
  const videoPlayer = (
    <iframe
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        maxWidth: "100%",
      }}
      src={tutorialVideoURL}
      title="YouTube video player"
      // eslint-disable-next-line
      // @ts-ignore
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );

  if (showVideo || redisplayVideo) {
    if (isMobileWidth && !isMinimized) {
      return (
        <div
          style={{
            width: "100%",
            position: "fixed",
            display: "flex",
            left: "10px",
            zIndex: 1000,
            boxShadow: "-5px -5px 5px #222",
          }}
        >
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              width: "98%",
              boxShadow: "0px 0px 10px #222",
            }}
          >
            <div
              style={{
                background: getComputedStyle(document.body).getPropertyValue("--color-bg-primary"),
                position: "absolute",
                paddingTop: "4px",
                top: "-13px",
                right: "0px",
                boxShadow: "0px 0px 5px #222",
                borderRadius: "6px",
                zIndex: 1001,
              }}
            >
              <button
                style={{
                  background: "transparent",
                  border: "none",
                }}
                id="dismiss-onboarding-video"
                onClick={() => setIsMinimized(true)}
              >
                <MinusCircle
                  size={"24px"}
                  style={{ color: getComputedStyle(document.body).getPropertyValue("--color-text-primary") }}
                ></MinusCircle>
              </button>
              <button
                style={{
                  background: "transparent",
                  border: "none",
                }}
                id="dismiss-onboarding-video"
                onClick={dismissOnboarding}
              >
                <XCircle
                  size={"24px"}
                  style={{ color: getComputedStyle(document.body).getPropertyValue("--color-text-primary") }}
                ></XCircle>
              </button>
            </div>
            {videoPlayer}
          </div>
        </div>
      );
    } else {
      const MinimizeIcon = isMinimized ? PlusCircle : MinusCircle;
      const minimizeBtn = (
        <button
          style={{
            background: "transparent",
            border: "none",
          }}
          id="dismiss-onboarding-video"
          onClick={() => setIsMinimized(!isMinimized)}
        >
          <MinimizeIcon
            size={"24px"}
            style={{ color: getComputedStyle(document.body).getPropertyValue("--color-text-primary") }}
          ></MinimizeIcon>
        </button>
      );
      const xBtn = (
        <button
          style={{
            background: "transparent",
            border: "none",
          }}
          id="dismiss-onboarding-video"
          onClick={dismissOnboarding}
        >
          <XCircle
            size={"24px"}
            style={{ color: getComputedStyle(document.body).getPropertyValue("--color-text-primary") }}
          ></XCircle>
        </button>
      );
      return (
        <div
          style={{
            maxWidth: "50%",
            width: isMinimized && !isMobileWidth ? "35%" : "50%",
            position: "fixed",
            right: isMobileWidth ? undefined : "10px",
            left: isMobileWidth ? "10px" : undefined,
            bottom: "10px",
            zIndex: 1000,
            boxShadow: "-5px -5px 5px #222",
          }}
        >
          <div
            style={{
              background: getComputedStyle(document.body).getPropertyValue("--color-bg-primary"),
              position: "absolute",
              top: "-13px",
              left: isMobileWidth ? undefined : "-18px",
              right: isMobileWidth ? "-18px" : undefined,
              boxShadow: "0px 0px 5px #222",
              paddingTop: "4px",
              borderRadius: "6px",
              zIndex: 1001,
            }}
          >
            {isMobileWidth ? minimizeBtn : xBtn}
            {isMobileWidth ? xBtn : minimizeBtn}
          </div>
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              height: 0,
              paddingBottom: "56.25%",
            }}
          >
            {videoPlayer}
          </div>
        </div>
      );
    }
  }

  if (!isTutorialEnabled || userSettings.seenOnboarding) {
    notifyAppReady();
    return null;
  }

  return (
    <Joyride
      // eslint-disable-next-line
      // @ts-ignore
      steps={joyrideSteps}
      styles={{
        options: joyrideOptions,
      }}
      continuous={true}
      callback={(data) => {
        if (data.action === "next" && data.step.title === "To-dos") {
          trackEvent("onboarding_next_clicked");
          setDisableAnimation(true);
          setShowSidebar(true);
        } else {
          setDisableAnimation(false);
        }

        if (data.action === "close" || data.action === "skip") {
          trackEvent("onboarding_closed_early");
          upsertUserSettings({ seenOnboarding: true });
          setShowVideo(true);
        }

        if (data.index === joyrideSteps.length - 1 && data.lifecycle === "complete") {
          trackEvent("onboarding_finished");
          setShowVideo(true);
          upsertUserSettings({ seenOnboarding: true });
        }
      }}
    />
  );
};
