interface FetchWrapperConfig {
  baseUrl?: string;
  defaultHeaders?: Record<string, string>;
}

interface RequestConfig {
  headers?: Record<string, string>;
}

enum HTTPMethod {
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
}

/**
 * A very simple polyfill wrapper around the fetch API to provide axios-like functionality,
 * since service workers do not have XMLHttpRequest support and Axios won't work.
 * This wrapper helps:
 * - automatically set authentication headers for each API call.
 * - centralized point for error handling and logging.
 * - set a baseUrl for each API call.
 */
export class FetchWrapper {
  /**
   * Default headers for the fetch requests.
   */
  private readonly defaultHeaders: Record<string, string> = {};
  private readonly baseUrl: string | null = null;

  public constructor(config: FetchWrapperConfig = {}) {
    if (config.baseUrl) {
      this.baseUrl = config.baseUrl;
    }
    if (config.defaultHeaders) {
      this.defaultHeaders = config.defaultHeaders;
    }
  }

  public async get(url: string, config: RequestConfig = {}) {
    return this.request(HTTPMethod.GET, url, null, config);
  }

  public async put(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.PUT, url, body, config);
  }

  public async post(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.POST, url, body, config);
  }

  public async delete(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.DELETE, url, body, config);
  }

  public async patch(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.PATCH, url, body, config);
  }

  public async request(method: string, url: string, body: unknown = null, config: RequestConfig = {}) {
    const requestOptions = {
      method,
      headers: { ...this.defaultHeaders, ...config.headers },
      body: body ? JSON.stringify(body) : null,
      //Todo: Can extend this to accept form data later.
    };
    const response = await fetch(this.baseUrl ? this.baseUrl + url : url, requestOptions);
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`API call failed with status: ${response.status}`);
    }
  }

  public addDefaultHeader(key: string, value: string): void {
    this.defaultHeaders[key] = value;
  }

  public removeDefaultHeader(key: string): void {
    delete this.defaultHeaders[key];
  }
}
