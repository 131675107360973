import { useSetAtom } from "jotai/index";
import { useEffect } from "react";
import { isKeyboardVisibleAtom } from "../../model/atoms";
import logger from "../../utils/logger";

export function useKeyboardVisibility() {
  const setIsKeyboardVisible = useSetAtom(isKeyboardVisibleAtom);
  useEffect(() => {
    const f = () => setIsKeyboardVisible(getKeyboardVisibility());
    visualViewport?.addEventListener("resize", f);
    document.addEventListener("focusin", f);
    document.addEventListener("focusout", f);
    return () => {
      visualViewport?.removeEventListener("resize", f);
      document.removeEventListener("focusin", f);
      document.removeEventListener("focusout", f);
    };
  }, [setIsKeyboardVisible]);
}

/**
 * Returns true if the virtual keyboard is visible.
 *
 * Because the VirtualKeyboard API isn't yet widely available, we need infer that the
 * keyboard is visible based on properties we do have available. Our heurisitic
 * is that the keyboard is open when the visual viewport is less than 70% of the
 * screen height.
 *
 * When selecting properties to use for this heuristic, there were quite a few
 * that resulted in unexpected behaviour. I've listed these gotch's here so
 * anyone who needs to modify this function is aware of them:
 *
 * - You can't use `window.innerHeight > window.innerWidth` to determine the
 *   orientation. When the selection is put low down on a page, the browser will
 *   shift *and* shrink the window vertically to keep the selection in view.
 *   When this happens, the window height can actually be smaller than the width
 *   even while you're in portrait.
 * - The `window.outerHeight` gives the up-down dimension of the whole browser.
 *   But it doesn't update until after the window/visualVisport "resize" and
 *   "orientationchange" events. That's a problem because those events are often
 *   when we want to check the keyboard visibility.
 * - The `window.innerHeight` gives the up-down dimension of page. Unlike
 *   `window.outerHeight`, this prop *does* change before the "resize" and
 *   "orientation" change events. But it has it's own problem: when the window
 *   is shifted and shrunk to keep the selection in view, the window.innerHeight
 *   is reduced too. It seems like the amount it shrinks is equal to the visual
 *   viewport offset though, so you can add them together to get the
 *   window.innerHeight you'd expect.
 * - The `screen.orientation.type` isn't widely available on Safari. see
 *   https://caniuse.com/screen-orientation
 */
function getKeyboardVisibility() {
  const activeElement = document.activeElement as HTMLElement;
  if (!activeElement.isContentEditable && activeElement.tagName !== "INPUT" && activeElement.tagName !== "TEXTAREA") {
    return false;
  }
  if (!visualViewport) {
    return false;
  }
  if (getScreenOrientation() === "portrait") {
    return visualViewport.height / window.screen.height < 0.7;
  } else {
    return visualViewport.height / window.screen.width < 0.7;
  }
}

/**
 * Return whether the screen is in landscape or portrait.
 *
 * The Screen Orientation API isn't widely supported on safari mobile, so this helper includes
 * a fall back to the deprecated `window.orientation` property.
 */
function getScreenOrientation(): "landscape" | "portrait" {
  if (window?.screen?.orientation?.type) {
    return window.screen.orientation.type.includes("portrait") ? "portrait" : "landscape";
  } else if (window?.orientation !== undefined) {
    return window.orientation === 0 ? "portrait" : "landscape";
  } else {
    logger.error(
      "Can't determine screen orientation. Neither window.screen.orientation or window.orientation are available.",
    );
    return "portrait";
  }
}
