import React from "react";
import { colors } from "../utils/style";
import { useHotkeys } from "../shortcuts/useHotkeys";
import { useShortcuts } from "../shortcuts/useShortcuts";
import { ShortcutIcons } from "../shortcuts/KeyIcons";
import { useSearchQuery } from "../search/useSearchQuery";
import { useCreateNoteAtTop } from "./hooks/useCreateNote";

export const NoResults = () => {
  const { shortcuts } = useShortcuts();
  const searchQuery = useSearchQuery();
  const createNewNote = useCreateNoteAtTop();
  const newNoteString = (searchQuery.keywordsList ?? []).join(" ");
  useHotkeys(shortcuts.createNewNoteFromSearch, () => {
    createNewNote(newNoteString);
  });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: 36,
        color: colors.text.secondary,
      }}
    >
      <div
        style={{
          maxWidth: "720px",
          padding: "0 24px",
        }}
      >
        No notes yet. Why not{" "}
        <button
          style={{
            background: "transparent",
            border: 0,
            display: "inline",
            textDecoration: "underline",
            padding: 0,
            margin: 0,
            cursor: "pointer",
            color: colors.text.accent,
          }}
          onClick={() => {
            createNewNote(newNoteString);
          }}
        >
          create one
        </button>
        {"? "}
        (<ShortcutIcons keys={shortcuts.createNewNoteFromSearch.keys} />)
      </div>
    </div>
  );
};
