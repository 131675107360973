import React from "react";

const OneLiner = (props: {
  icon?: React.ReactNode;
  content: React.ReactNode;
  allowOverflow?: boolean;
  trailingButtons?: React.ReactNode;
}) => {
  const style: React.CSSProperties = {
    textOverflow: "ellipsis",
    overflow: props.allowOverflow ? "initial" : "hidden",
    textAlign: "left",
    whiteSpace: "nowrap",
    zIndex: "30",
  };
  return (
    <div
      className="selected-link-title"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        width: props.trailingButtons ? "100%" : "90%",
      }}
    >
      {props.icon && (
        <div style={{ flexShrink: 0, display: "flex", alignItems: "center", paddingRight: 8 }}>{props.icon}</div>
      )}
      <div style={style}>{props.content}</div>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {props.trailingButtons}
      </div>
    </div>
  );
};

export default OneLiner;
