import { TextSelection, Transaction } from "prosemirror-state";
import { SearchQuery } from "../../../search/SearchQuery";
import { getMatchesForEachRegex, getTextRunsForNode } from "../../utils/mark/regexMarkUtils";
import { schema } from "../../schema";
import { prepareKeywordList } from "../../../search/filterUtils";
import { escapeRegExp, removeDiacritics } from "../../../search/find";

/**
 * For each node,
 * remove all search match marks
 */
export function condenseAndHighlight(tr: Transaction, searchQuery: SearchQuery) {
  if (!searchQuery.keywordsList) return;
  const keywordRegexes = prepareKeywordList(searchQuery.keywordsList).map((k) => new RegExp(escapeRegExp(k), "gi"));

  let { anchor: prevAnchor, head: prevHead } = tr.selection;
  tr.doc.forEach((node, pos) => {
    const textRuns = getTextRunsForNode(node, pos);
    textRuns.forEach((textRun) => {
      const text = textRun.textNodes
        .map((n) => n.text!)
        .map(removeDiacritics)
        .join("");

      tr.removeMark(textRun.startPos, textRun.startPos + text.length, schema.marks.highlight);
      keywordRegexes.forEach((regex: RegExp) => {
        applySearchMatchMarksToTextRuns(tr, textRun.startPos, text, regex);
      });
    });
  });

  // In some cases, the selection collapses when we remove marks.
  // So we save the selection and restore it after we're done.
  // See:
  // - https://linear.app/ideaflow/issue/ENT-1426
  // - https://linear.app/ideaflow/issue/ENT-1817
  prevAnchor = tr.mapping.map(prevAnchor);
  prevHead = tr.mapping.map(prevHead);
  const { anchor, head } = tr.selection;
  if (anchor !== prevAnchor || head !== prevHead) {
    tr.setSelection(new TextSelection(tr.doc.resolve(prevAnchor), tr.doc.resolve(prevHead)));
  }
}

function applySearchMatchMarksToTextRuns(transaction: Transaction, startPos: number, text: string, regex: RegExp) {
  const matches = getMatchesForEachRegex(text, [{ type: "highlight" as const, regex, skipFirstGroup: false }], false);

  matches.forEach(({ start, end }) => {
    if (!transaction.doc.rangeHasMark(startPos + start, startPos + end, schema.marks.autocompleteRegion)) {
      transaction.addMark(startPos + start, startPos + end, schema.marks.highlight.create());
    }
  });
}
