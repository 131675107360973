import { openDB } from "idb";
import { isClientSide, isWorker } from "../utils/environment";
import logger from "../utils/logger";
import { DataStorage } from "./DataStorage";

const DBNAME = "localforage";
const STORENAME = "keyvaluepairs";

async function openKeyValDB() {
  return await openDB(DBNAME, 2, {
    upgrade(db) {
      db.createObjectStore(STORENAME);
    },
  });
}

let store: DataStorage = {
  async getItem<T>(key: string) {
    return (await openKeyValDB()).get(STORENAME, key) as T;
  },
  async getItems<T>(keys: string[]) {
    const db = await openKeyValDB();
    const tr = db.transaction(STORENAME, "readonly");
    const values = Promise.all(keys.map((key) => tr.store.get(key)));
    tr.commit();
    await tr.done;
    return (await values) as T[];
  },
  async findAllKeys() {
    const db = await openKeyValDB();
    const keys = await db.getAllKeys(STORENAME);
    return keys.map((key) => key.toString());
  },
  async findAllKeysWithPrefix(prefix: string) {
    const db = await openKeyValDB();
    const keys = await db.getAllKeys(
      STORENAME,
      // We assume keys are alphanumeric with [-.] so tilde `${prefix}~` is larger than all the keys starting with the prefix
      IDBKeyRange.bound(prefix, prefix + "~~~~~~~~", false, false),
    );
    return keys.map((key) => key.toString());
  },
  async setItem(key: string, val: any) {
    const db = await openKeyValDB();
    await db.put(STORENAME, val, key);
  },
  async setItems(keyValues: { key: string; val: any }[]) {
    const db = await openKeyValDB();
    const tr = db.transaction(STORENAME, "readwrite");
    for (const { key, val } of keyValues) {
      tr.store.put(val, key);
    }
    tr.commit();
    await tr.done;
  },
  async removeItem(key: string) {
    return (await openKeyValDB()).delete(STORENAME, key);
  },
  async removeItems(keys: string[]) {
    const db = await openKeyValDB();
    const tr = db.transaction(STORENAME, "readwrite");
    for (const key of keys) {
      tr.store.delete(key);
    }
    tr.commit();
    await tr.done;
  },
  async removeAllItems() {
    const db = await openKeyValDB();
    const keys = await db.getAllKeys(STORENAME);
    for (const key of keys) {
      await db.delete(STORENAME, key);
    }
  },
};

if (!isClientSide && !isWorker) {
  logger.info("using dummy idbKeyVal", { namespace: "cache" });
  store = {
    findAllKeys: () => {},
    findAllKeysWithPrefix: () => {},
    setItem: () => {},
    setItems: () => {},
    getItem: () => {},
    getItems: () => {},
    removeItem: () => {},
    removeItems: () => {},
  } as any;
} else {
  logger.info("using real idbKeyVal", { namespace: "cache" });
}

export default store;
