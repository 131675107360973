export type AudioDataMessage = {
  type: "audio-data";
  buffer: Float32Array;
  trigger: "requested" | "auto";
};
export function isAudioData(msg: any): msg is AudioDataMessage {
  return msg.type === "audio-data";
}

export type EncoderWorkerEventMessage = { type: "stop" } | { type: "stop-abruptly" };

export function isEncoderWorkerEvent(msg: any): msg is EncoderWorkerEventMessage {
  return msg.type === "stop" || msg.type === "stop-abruptly";
}

export type RequestFlush = { type: "flush-request" };
export function isRequestFlush(msg: any): msg is RequestFlush {
  return msg.type === "flush-request";
}

export type ChunkMetadata = {
  audioId: string;
  chunkIndex: number;
  duration: number;
  isFinal: boolean;
};

export type ChunksSavedLocallyMessage = {
  type: "chunk-saved-locally";
  data: ChunkMetadata;
};
export function isChunksSavedLocallyMessage(msg: any): msg is ChunksSavedLocallyMessage {
  return msg.type === "chunk-saved-locally";
}

export type AllChunksSavedMessage = {
  type: "all-chunks-saved";
  audioId: string;
};
export function isAllChunksSavedMessage(msg: any): msg is AllChunksSavedMessage {
  return msg.type === "all-chunks-saved";
}

export type AudioAttrs = {
  audioId: string;
  chunkCount: number;
  durations: number[] | null;
  transcript: string | null;
  transcriptLanguageOverride: string | null;
  updatedAt: number;
  transcriptGenId: string | null;
  isTranscriptCollapsed: boolean;
  shouldParseTranscript: boolean;
  isRevealed: boolean;
};

export enum Stage {
  /** The audio is being recorded in this tab */
  Recording,
  /** This token has no active recording in this tab and is missing references
   * to persisted recordings (as chunk counts). This can happen if the recording
   * is active in another tab, or if the recording was interrupted without saving
   * the chunk count and durations to this token. */
  MissingRecording,
  /** The audio chunks are being saved to the server */
  Saving,
  /** Viewing a completed audio recording */
  Viewing,
  /** The audio is broken or unavailable */
  BrokenAudio,
}
