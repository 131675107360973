import { useAtomValue } from "jotai";
import { Edit, Mic, StopCircle } from "react-feather";
import { isKeyboardVisibleAtom } from "../model/atoms";
import { useShortcuts } from "../shortcuts/useShortcuts";
import { getShortcutString } from "../shortcuts/shortcuts";
import { withCloseModals } from "../model/modals";
import { NEW_NOTE_BUTTON_ID, NEW_AUDIO_BUTTON_ID } from "../utils/constants";
import { useTapOrLongPress } from "../utils/useTapOrLongPress";
import { globalAudioRecordingCoordinator } from "../editor/features/audioInsert/audioRecordingCoordinator";
import { BUTTON_BG, BUTTON_HOVER_BG, ButtonContainer, ICON_STYLES, STOP_BUTTON_BG, STOP_BUTTON_COLOR } from "./Buttons";
import { CircularMenu, NO_STEALING_POINTER_EVENTS, useCircularMenu } from "./CircularMenu";
import { BUTTON_SIZE, FLOATING_BUTTON_STYLES, FLOATING_ICON_STYLES } from "./FloatingButton";
import { useInsertAudio } from "./header/MicButton";
import { useCreateNoteAtTop } from "./hooks/useCreateNote";

/**
 * Desktop version of the new note button
 *
 * On hover, show a tooltip with the shortcut to create a new note
 */
export const NewNoteButtonWithInteraction = () => {
  const createNote = useCreateNoteAtTop();
  const { shortcuts } = useShortcuts();

  return (
    <div
      id={NEW_NOTE_BUTTON_ID}
      aria-label={`Create a new note ${getShortcutString(shortcuts.createNewNote)}.\nSplit notes ${getShortcutString(
        shortcuts.splitNote,
      )}`}
      data-microtip-position="bottom-left"
      role="tooltip"
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        padding: "4px",
        borderRadius: "100%",
      }}
      onClick={() => createNote()}
    >
      <ButtonContainer>
        <Edit size={20} style={ICON_STYLES} />
      </ButtonContainer>
    </div>
  );
};

/**
 * Mobile version of the new note button
 *
 * If the user taps the button, create a new note
 * If the user presses and holds the button, create a new page
 */
export const NewNoteButtonMobileWithInteraction = () => {
  const recordingState = globalAudioRecordingCoordinator.useRecordingState();
  const isRecording = recordingState?.state === "recording";

  const insertAudio = useInsertAudio();
  const { buttons, isCircularMenuOpen, handlePress, handleMove, handleRelease } = useCircularMenu(true);
  const { handleDown: handleMicDown, handleUp: handleMicUp } = useTapOrLongPress({
    onTap: () => insertAudio(true),
    onLongPress: () => insertAudio(false),
  });

  const isKeyboardVisible = useAtomValue(isKeyboardVisibleAtom);
  if (isKeyboardVisible) return null;

  return (
    <div onContextMenu={(e) => e.preventDefault()}>
      <div
        id={NEW_AUDIO_BUTTON_ID}
        style={{
          ...FLOATING_BUTTON_STYLES,
          background: isRecording ? STOP_BUTTON_BG : BUTTON_HOVER_BG,
          color: isRecording ? STOP_BUTTON_COLOR : "#343737",
          right: 32 + 18 + BUTTON_SIZE,
          bottom: 32,
        }}
        onPointerDown={withCloseModals((e: React.PointerEvent<HTMLDivElement>) => {
          if (isRecording) {
            globalAudioRecordingCoordinator.stopRecording(recordingState.audioId);
          } else {
            handleMicDown(e);
          }
        })}
        onPointerUp={handleMicUp}
      >
        {isRecording ? <StopCircle size={30} /> : <Mic size={30} style={FLOATING_ICON_STYLES} />}
      </div>
      <div
        id={NEW_NOTE_BUTTON_ID}
        style={{
          ...FLOATING_BUTTON_STYLES,
          background: isCircularMenuOpen ? BUTTON_HOVER_BG : BUTTON_BG,
          right: 32,
          bottom: 32,
          ...NO_STEALING_POINTER_EVENTS,
        }}
        onPointerDown={withCloseModals(handlePress)}
        onPointerMove={handleMove}
        onPointerUp={handleRelease}
      >
        <Edit size={30} style={FLOATING_ICON_STYLES} />
        <CircularMenu isCircularMenuOpen={isCircularMenuOpen} buttons={buttons} />
      </div>
    </div>
  );
};
