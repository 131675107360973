import { EditorState, TextSelection, Selection, Command, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { appNoteStore } from "../../../model/services";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { schema } from "../../schema";
import { generatePathFromNoteId } from "../../utils/path";
import { preserveExpandedReferencesAround } from "../reference/referenceExpansionUtils";
import { getParentNote } from "../../utils/find";
import { getSearchQuery } from "../../../search/useSearchQuery";
import { isNoteEmpty } from "./isNoteEmpty";

export const trSplitNote = (state: EditorState, transaction?: Transaction, folderId?: string): Transaction | null => {
  const tr = transaction || state.tr;
  const { $from } = state.selection;

  // Make sure we are not inside a reference
  const parentNode = $from.node(-1);
  const grandparentNode = $from.node(-2);
  if (grandparentNode?.type === schema.nodes.expandedReference || parentNode?.type === schema.nodes.expandedReference) {
    return null;
  }

  // Create a new note in the model positioned after the current note
  const [prevNoteNode] = getParentNote(tr.doc, $from.pos);
  if (prevNoteNode === null) throw new Error("Must be inside a note to split it");
  const prevNote = appNoteStore.get(prevNoteNode.attrs.noteId);
  if (!prevNote) throw new Error("Note not found in noteStore");
  const [note] = appNoteStore.insertAfter(prevNote.id);

  if (tr.selection instanceof TextSelection) tr.deleteSelection();
  preserveExpandedReferencesAround(state, tr, () => {
    tr.split(tr.mapping.map($from.pos), 2, [
      {
        type: schema.nodes.note,
        attrs: {
          path: generatePathFromNoteId(note.id),
          noteId: note.id,
          insertedAt: note.insertedAt,
          folderId,
        },
      },
    ]);
  });

  // If the cursor is at the start of a non-empty note when the note is split,
  // the user is probably simply trying to create a new note at the top of the
  // note stack, and wants to keep the cursor there. We special-case this to
  // keep the cursor on prevNote [ENT-208]
  const noteStartPos = $from.start(1);
  const splitCursorPos = $from.pos - 1;
  if (noteStartPos === splitCursorPos && !isNoteEmpty(prevNoteNode)) {
    const cursorPos = tr.doc.resolve(noteStartPos);
    tr.setSelection(Selection.near(cursorPos));
  }

  tr.scrollIntoView();

  return tr;
};

export const splitNoteCommand: Command = (state: EditorState, dispatch?: EditorView["dispatch"]): boolean => {
  if (!dispatch) return false;
  const searchQuery = getSearchQuery();
  const tr = trSplitNote(state, state.tr, searchQuery.folderId);
  if (tr == null) return false;
  dispatch(tr);
  trackEvent(searchQuery.folderId ? "split_note_from_folder" : "split_note");
  return true;
};
