import { EditorState, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Node } from "prosemirror-model";
import { RefObject } from "react";
import logger from "../utils/logger";
import { Note } from "../../shared/types";
import { isIOs, isTransactionLogEnabled } from "../utils/environment";
import { Hit } from "../search/find";
import { EDITOR_ID } from "../utils/constants";
import { selectionAfterCreateNote } from "../editorPage/hooks/useCreateNote";
import { getSearchQuery } from "../search/useSearchQuery";
import { setExpansionsOnState } from "../model/expansions";
import { SearchQuery } from "../search/SearchQuery";
import { isCondensed } from "../model/services";
import { createEditorState, EditorStateAndViewConfig } from "./editorState";
import { schema } from "./schema";
import { noteToProsemirrorNode } from "./bridge";
import { serializeNode } from "./utils/serializeNode";
import { handleDrop, handlePaste } from "./utils/clipboard/handlers";
import { handleClickOn } from "./features/link/handleClickOn";
import { ReferenceView } from "./features/reference/ReferenceView";
import { ImageView } from "./features/image/ImageView";
import { LinkLoaderView } from "./features/link/LinkLoaderView";
import { HashtagView } from "./features/hashtag/HashtagView";
import { CheckboxView } from "./features/checkbox/CheckboxView";
import { AudioInsertView } from "./features/audioInsert/AudioInsertView";
import { cleanUpPastedHTML } from "./utils/clipboard/htmlTools";
import { clipboardTextParser } from "./utils/clipboard/textParser";
import { transformCopied, transformPasted } from "./utils/clipboard/transforms";
import { getTransactionOrigin } from "./transactionTraps";
import { ExpandedReferenceView } from "./features/reference/ExpandedReferenceView";
import { nodeTreeToString } from "./utils/nodesToString";
import { AsyncReplacedView } from "./features/utils";
import { globalAudioRecordingCoordinator } from "./features/audioInsert/audioRecordingCoordinator";
import { setSelectionAndScroll } from "./setSelectionAndScroll";

interface EditorViewConfig extends EditorStateAndViewConfig {
  div: HTMLElement;
  initialNotes: Hit<Note>[];
  noteToFocusAtInit?: string;
}

function createInitialDoc(args: EditorViewConfig, searchQuery: SearchQuery) {
  const initialDoc = schema.nodes.doc.create(
    null,
    args.initialNotes.map((hit) => {
      const note = hit.entry;
      return noteToProsemirrorNode(note, {
        matches: hit.matches,
        highlighted: note.id === args.noteToFocusAtInit,
        condensingEnabled: searchQuery.isCondensed && !searchQuery.isAll,
        isCondensed: isCondensed.get(note.id),
        fromSimon: hit.isFromSimon,
      });
    }),
  );
  return createEditorState(args, {
    initialDoc,
    isStartingWithNoResults: args.initialNotes.length === 0,
    stopRecording: (id) => globalAudioRecordingCoordinator.stopRecording(id),
  });
}

export function replaceAllNotes(
  args: EditorViewConfig,
  view: EditorView,
  lastSearchQuery: RefObject<SearchQuery | null>,
) {
  const searchQuery = getSearchQuery();
  const state = createInitialDoc(args, searchQuery);
  const tr = state.tr;
  setExpansionsOnState(searchQuery, tr);
  const newState = state.apply(tr);
  view.updateState(newState);

  // With the following line we ask the autocomplete plugin to search for the match
  // even though no document change occured (https://linear.app/ideaflow/issue/ENT-1737/)
  // TODO: refactor plugins a bit and remove this
  const newTr = view.state.tr;
  newTr.setMeta("reapplyAutocompletePlugin", true);
  setSelectionAndScroll(newTr, searchQuery, lastSearchQuery);
  view.dispatch(newTr);
}

export function createEditorView(args: EditorViewConfig, lastSearchQuery?: RefObject<SearchQuery | null>) {
  const { div, isReadOnly } = args;

  div.classList.add("editor-div");
  // makes sure that the first word after a period is autocapitalized on safari
  div.autocapitalize = "sentences";
  div.id = "editor-div";
  if (isIOs) {
    div.classList.add("editor-div-ios");
  }

  const searchQuery = getSearchQuery();
  let state = createInitialDoc(args, searchQuery);
  if (lastSearchQuery) {
    const tr = state.tr;
    setExpansionsOnState(searchQuery, tr);
    setSelectionAndScroll(tr, searchQuery, lastSearchQuery);
    state = state.apply(tr);
  }
  const editorLogger = logger.with({ namespace: "editor" });

  const view = new EditorView(
    { mount: div },
    {
      state,
      clipboardTextSerializer: serializeNode,
      handleClickOn,
      handlePaste,
      handleDrop, // Prosemirror types for the handleDrop are broken
      handleScrollToSelection: (view: EditorView) => {
        // Handle scroll to selection after creating a new note at the top of the editor
        const { from, to } = view.state.selection;
        const sel = selectionAfterCreateNote(view.state.doc);
        // Check the selection is the one we expect
        if (!sel || from !== sel.from || to !== sel.to) return false;
        const editor = document.getElementById(EDITOR_ID);
        if (!editor) throw new Error("could not find editor");
        editor.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        // If a new note is created while the viewport is offset from the top,
        // the note will still be out of view after the scroll above, because
        // it's in the part of the editor that's offscreen. Scrolling the window
        // to the top fixes that.
        window.scrollTo(0, 0);
        return true;
      },
      transformCopied,
      transformPasted,
      transformPastedHTML: cleanUpPastedHTML,
      clipboardTextParser: clipboardTextParser(schema),
      editable: (editorState) => {
        if (isReadOnly) return false;
        const doc = editorState.doc;
        return doc.childCount > 0;
      },
      // DEBUG: Set NEXT_PUBLIC_LOG_EDITOR_TRANSACTION=true in .env to enable logging of editor states and transactions
      // This is useful for preparing editor tests since the editor state before and after some operation
      // can be copied over into the test
      dispatchTransaction: isTransactionLogEnabled
        ? (tr) => {
            editorLogger.info("before \n" + nodeTreeToString(view.state.doc));
            let state: EditorState, transactions: readonly Transaction[];
            try {
              ({ state, transactions } = view.state.applyTransaction(tr));
            } catch (e) {
              logger.error("transaction origin", {
                context: { transactionOrigin: getTransactionOrigin(tr) },
                error: e,
              });
              throw e;
            }
            editorLogger.info("after \n" + nodeTreeToString(state.doc));
            editorLogger.info("transactions", {
              context: { appliedTransactions: transactions },
            });
            editorLogger.info("Transaction origins", {
              context: {
                transactionOrigins: transactions.map(getTransactionOrigin),
              },
            });
            view.updateState(state);
          }
        : undefined,
      nodeViews: {
        audioInsert: (node: Node, view: EditorView, getPos) =>
          new AudioInsertView(node, view, getPos as () => number, isReadOnly),
        asyncReplacedElement: (node: Node, view: EditorView, getPos) =>
          new AsyncReplacedView(node, view, getPos as () => number, isReadOnly),
        image: (node: Node, view: EditorView, getPos) => new ImageView(node, view, getPos, isReadOnly),
        linkLoader: (node, view, getPos) => new LinkLoaderView(node, view, getPos),
        hashtag: () => new HashtagView(isReadOnly),
        reference: (node: Node, view: EditorView, getPos) => new ReferenceView(node, view, getPos),
        checkbox: (node: Node, view: EditorView, getPos) => new CheckboxView(node, view, getPos, isReadOnly),
        expandedReference: (node: Node, view: EditorView, getPos) => new ExpandedReferenceView(node, view, getPos),
      },
    },
  );

  if (isReadOnly) div.contentEditable = "false";

  div.spellcheck = false;

  return {
    view,
    destroy: () => view.destroy(),
  };
}
