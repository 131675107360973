import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { zIndexes } from "../../utils/style";

interface Props {
  submitEditChanges: (inputValue: string) => void;
  errorMessage: string | null;
  setErrorMessage: (v: string | null) => void;
  setIsEditing: (v: boolean) => void;
  content: string;
}

const generateErrorMessage = (v: string, content: string): string | null => {
  if (v.toLowerCase() === content.toLowerCase()) return null;
  if (v === "") {
    return "missing content";
  }
  if (v.length > 50) {
    return "must be shorter than 50 characters";
  }
  return null;
};

export const EditInput = ({ submitEditChanges, errorMessage, setErrorMessage, setIsEditing, content }: Props) => {
  const [inputValue, setInputValue] = useState(content);
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputEl.current?.focus();
    inputEl.current?.select();
  }, []);

  useEffect(() => {
    const error = generateErrorMessage(inputValue, content);
    setErrorMessage(error);
  }, [inputValue, content, setErrorMessage]);

  return (
    <div style={{ position: "relative", flexGrow: 1, display: "flex" }}>
      <InputWrapper>
        {errorMessage && <ErrorMessage>Error: {errorMessage}</ErrorMessage>}
        <StyledInput
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            const error = generateErrorMessage(e.target.value, content);
            setErrorMessage(error);
          }}
          onClick={(e) => e.stopPropagation()}
          onKeyPress={(e) => {
            e.stopPropagation();
            if (e.key === "Enter") {
              submitEditChanges(inputValue);
            }
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "Escape") {
              setIsEditing(false);
              setInputValue(content);
              setErrorMessage(null);
            }
          }}
          ref={inputEl}
          onBlur={() => {
            submitEditChanges(inputValue);
          }}
        />
      </InputWrapper>
    </div>
  );
};

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
`;

const StyledInput = styled.input`
  flex-grow: 1;
  width: 100%;
  height: 24px;
  flex-basis: 50px;
  border: 0.5px solid var(--color-border-secondary-solid);
  border-radius: 6px;
  padding: 8px 4px;
  font-size: 14px;
  color: var(--color-text-primary);
  background-color: var(--color-bg-secondary);
  transition: color 0.15s ease-in-out;
  outline: none;
  z-index: 30;

  &:hover {
    border-color: var(--color-border-primary);
  }

  &:focus {
    border-color: var(--color-bg-accent-primary);
    box-shadow: 0 0 0 3px var(--color-bg-accent-quaternary);
  }

  &::placeholder {
    color: var(--color-text-tertiary);
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  color: var(--color-text-negative);
  bottom: 30px;
  left: -8px;
  background: #fbf4f4;
  white-space: pre;
  word-break: break-word;
  font-size: 12px;
  max-width: 268px;
  padding: 2px 8px;
  border-radius: 6px;
  z-index: ${zIndexes.editInput};
  overflow: hidden;
  cursor: default;
`;
