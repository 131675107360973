import React, { useState } from "react";
import OneLiner from "../Elements/OneLiner";
import { SelectedLink } from "../Elements/SelectedLink";
import { isTouchDevice } from "../../utils/environment";
import { CountMenu, Menu } from "./Menu";
import { EditInput } from "./EditInput";

interface Props {
  count: number | null;
  content: string;
  itemId: string;
  Icon?: React.ComponentType<any>;
  setSearch: () => void;
  rename?: (newName: string) => void;
  isPinned?: boolean;
  setIsPinned?: (newVal: boolean) => void;
}

export const SidebarItem = React.memo((props: Props) => {
  const { count, content, Icon, setSearch, rename, isPinned, setIsPinned } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const showMenu = (isHovered || isTouchDevice) && (rename || setIsPinned);

  return (
    <li>
      <SelectedLink
        tabIndex={0}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={(e) => {
          e.preventDefault();
          setSearch();
        }}
      >
        <OneLiner
          icon={Icon && <Icon size={16} style={{ opacity: isHovered ? 1 : 0.75 }} />}
          content={
            rename && isRenaming ? (
              <EditInput
                submitEditChanges={(newName) => {
                  rename?.(newName);
                  setIsRenaming(false);
                }}
                setErrorMessage={setError}
                setIsEditing={setIsRenaming}
                content={content}
                errorMessage={error}
              />
            ) : (
              content
            )
          }
        />
        {showMenu && (
          <Menu
            isHovering={isHovered}
            isSelected={false}
            setIsHoveringSidebarItem={() => setIsHovered(false)}
            setIsEditing={setIsRenaming}
            isPinned={isPinned}
            setIsPinned={setIsPinned}
            errorMessage={error}
          />
        )}
        {count && (
          <CountMenu className="selected-link-count" isSelected={false}>
            {count.toLocaleString()}
          </CountMenu>
        )}
      </SelectedLink>
    </li>
  );
});
