import { Command } from "prosemirror-state";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { getParentNote } from "../../utils/find";

/**
 * Returns a command that either opens the note containing the current
 * selection. Unless the selection is right on top of a reference, in which case
 * it opens the referenced note.
 */
export function getOpenNoteAsPage(setSearchQuery: SearchQuerySetter): Command {
  return (state) => {
    const { from, to } = state.selection;
    let noteId: string | undefined;
    const fragment = state.doc.slice(from, to).content;
    if (fragment.size === 1 && fragment.firstChild?.type.name === "reference") {
      noteId = fragment.firstChild.attrs.linkedNoteId;
    } else {
      const [note] = getParentNote(state.doc, from);
      noteId = note?.attrs.noteId;
    }
    if (!noteId) return false;
    setSearchQuery({ noteIdList: [noteId] });
    return true;
  };
}
