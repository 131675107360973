import styled from "@emotion/styled";
import { useAtomValue } from "jotai";
import React, { forwardRef, useRef, useState } from "react";
import { Clipboard, Edit, Maximize2, Mic, Icon, StopCircle } from "react-feather";
import { colors, zIndexes } from "../utils/style";
import { addToast } from "../components/Toast";
import { useTapOrLongPress } from "../utils/useTapOrLongPress";
import { editorViewAtom } from "../model/atoms";
import { withCloseModals } from "../model/modals";
import { globalAudioRecordingCoordinator } from "../editor/features/audioInsert/audioRecordingCoordinator";
import { isSingleLink } from "../editor/utils/clipboard/transforms";
import { useInsertAudio } from "./header/MicButton";
import { useCreateNoteAsPage, useCreateNoteAtTop } from "./hooks/useCreateNote";
import { BUTTON_BG, BUTTON_HOVER_BG } from "./Buttons";
import { BUTTON_SIZE, FLOATING_ICON_STYLES } from "./FloatingButton";
import clipboardManager, { ClipboardPermissionError } from "./utils/ClipboardManager";
import { getAsLinkLoaderTokens } from "./utils/urlPaste";

export const NO_STEALING_POINTER_EVENTS = { touchAction: "none" };

const LONG_TAP_THRESHOLD = 400;

const CIRCLE_SIZE = 280;
const MIN_ACTION_DISTANCE = 20;
const MAX_ACTION_DISTANCE = 200;
const CLOSE_MENU_DISTANCE = 300;

type Coords = { clientX: number; clientY: number };

const getDistance = (x1: number, y1: number, x2: number, y2: number): number => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

// TODO : check this function as it's half Copilot-generated
const findClosestButton = (x: number, y: number, buttonDescriptors: ButtonDescriptors): [BUTTON, number] => {
  const distances: { [key: string]: number } = {};
  Object.entries(buttonDescriptors).forEach(([key, { ref }]) => {
    const elem = ref.current;
    if (elem) {
      const { top, left, width, height } = elem.getBoundingClientRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;
      distances[key] = getDistance(x, y, centerX, centerY);
    }
  });

  const minDistanceKey = Object.entries(distances).reduce((acc, [key, value]) => {
    const minDistance = distances[acc];
    return value < minDistance ? key : acc;
  }, Object.keys(distances)[0]);

  return [minDistanceKey as BUTTON, distances[minDistanceKey]];
};

const hover = (target: HTMLElement | null) => {
  if (!target) return;
  target.style.background = BUTTON_HOVER_BG;
};

const unhoverAll = (buttonDescriptors: ButtonDescriptors) => {
  Object.entries(buttonDescriptors).forEach(([_, { ref }]) => {
    const elem = ref.current;
    if (elem) elem.style.background = BUTTON_BG;
  });
};

enum BUTTON {
  NOTE_AT_TOP = "note-at-top",
  NOTE_AS_PAGE = "note-as-page",
  AUDIO_RECORDING = "audio-recording",
  FROM_CLIPBOARD = "from-clipboard",
}

type ButtonDescriptor = {
  ref: React.RefObject<HTMLDivElement>;
  icon: Icon;
  bottom: number;
  right: number;
  callback: () => void;
};

type ButtonDescriptors = Record<BUTTON, ButtonDescriptor>;

export const useCircularMenu = (blurEditor: boolean) => {
  const createNoteAtTop = useCreateNoteAtTop();
  const createNoteAsPage = useCreateNoteAsPage();
  const insertAudio = useInsertAudio();
  const editorView = useAtomValue(editorViewAtom);

  const [isCircularMenuOpen, setIsCircularMenuOpen] = useState(false);
  const [downCoords, setDownCoords] = useState<Coords | null>(null);

  // Need to be done synchronously because useState is way too slow
  const nMoveEvents = useRef<number>(0);

  const recordingState = globalAudioRecordingCoordinator.useRecordingState();
  const isRecording = recordingState?.state === "recording";

  const buttons: ButtonDescriptors = {
    [BUTTON.NOTE_AT_TOP]: {
      ref: useRef(null),
      icon: Edit,
      bottom: 0,
      right: 0,
      callback: createNoteAtTop,
    },
    [BUTTON.NOTE_AS_PAGE]: {
      ref: useRef(null),
      icon: Maximize2,
      bottom: 90,
      right: 0,
      callback: createNoteAsPage,
    },
    [BUTTON.AUDIO_RECORDING]: {
      ref: useRef(null),
      icon: isRecording ? StopCircle : Mic,
      bottom: 0,
      right: 90,
      callback: () =>
        isRecording ? globalAudioRecordingCoordinator.stopRecording(recordingState.audioId) : insertAudio(true),
    },
    [BUTTON.FROM_CLIPBOARD]: {
      ref: useRef(null),
      icon: Clipboard,
      bottom: 65,
      right: 65,
      callback: async () => {
        try {
          const clipboardContent = await clipboardManager.readClipboard();
          if (clipboardContent) {
            if (isSingleLink(clipboardContent)) {
              createNoteAtTop(getAsLinkLoaderTokens(clipboardContent));
            } else {
              createNoteAtTop(clipboardContent);
            }
          } else {
            addToast("Clipboard is empty");
            createNoteAtTop();
          }
        } catch (e) {
          if (e instanceof ClipboardPermissionError) {
            addToast("Clipboard permission has been denied");
          }
        }
      },
    },
  };

  const openCircularMenu = () => {
    nMoveEvents.current = 0;
    setIsCircularMenuOpen(true);
    unhoverAll(buttons);
    hover(buttons[BUTTON.NOTE_AT_TOP].ref.current);
  };

  // The release event in handled by the circular menu (therefore no need in handleUp)
  const { handleDown, cancelHandling } = useTapOrLongPress({
    tapThreshold: LONG_TAP_THRESHOLD,
    onTap: createNoteAtTop,
    onLongPress: openCircularMenu,
  });

  const closeCircularMenu = () => {
    nMoveEvents.current = 0;
    setDownCoords(null);
    cancelHandling();
    unhoverAll(buttons);
    setIsCircularMenuOpen(false);
  };

  const onMoveInCircularMenu = (coords: Coords, downCoords: Coords | null) => {
    // If CircularMenu has been closed, downCoords will be null
    if (!downCoords) return;
    // If user moves finger instantly after opening the menu, we show the menu instantly
    if (nMoveEvents.current === 0) setIsCircularMenuOpen(true);
    nMoveEvents.current += 1;

    const distance = getDistance(downCoords.clientX, downCoords.clientY, coords.clientX, coords.clientY);
    if (downCoords && distance > CLOSE_MENU_DISTANCE) {
      closeCircularMenu();
    } else {
      const [closestButton] = findClosestButton(coords.clientX, coords.clientY, buttons);
      unhoverAll(buttons);
      hover(buttons[closestButton].ref.current);
    }
  };

  const onReleaseOfCircularMenu = (e: React.PointerEvent) => {
    // If user's finger wanders off, the menu closes, then it returns back and releases — we ignore that
    if (!isCircularMenuOpen && nMoveEvents.current > 10) return;

    const { clientX, clientY } = e;
    const d = !downCoords ? 0 : getDistance(downCoords.clientX, downCoords.clientY, clientX, clientY);

    closeCircularMenu();

    if (d < MIN_ACTION_DISTANCE) {
      // Prevent the too slight movement from triggering the non-default action
      buttons[BUTTON.NOTE_AT_TOP].callback();
    } else {
      // Find the closest action button and click it (this happens only once)
      const [closestButton, distance] = findClosestButton(clientX, clientY, buttons);
      if (distance < MAX_ACTION_DISTANCE) {
        buttons[closestButton].callback();
      }
    }
  };

  const handlePress = withCloseModals((e: React.PointerEvent) => {
    // Prevent the keyboard from opening on long press if editor is focused
    if (blurEditor) editorView?.dom.blur();

    const { clientX, clientY } = e;
    setDownCoords({ clientX, clientY });
    handleDown(e);
  });

  const handleMove = (e: React.PointerEvent) => {
    const { clientX, clientY } = e;
    onMoveInCircularMenu({ clientX, clientY }, downCoords);
  };

  const handleRelease = (e: React.PointerEvent) => {
    onReleaseOfCircularMenu(e);
  };

  return { buttons, isCircularMenuOpen, handlePress, handleMove, handleRelease };
};

type CircularMenuProps = {
  isCircularMenuOpen: boolean;
  buttons: ButtonDescriptors;
};

export const CircularMenu = ({ isCircularMenuOpen, buttons }: CircularMenuProps) => {
  return (
    <>
      <CircularMenuCircle style={isCircularMenuOpen ? { height: CIRCLE_SIZE, width: CIRCLE_SIZE, opacity: 1 } : {}} />
      {Object.entries(buttons).map(([key, { ref, icon, bottom, right }]) => (
        <CircularMenuButton
          key={key}
          ref={ref}
          isShown={isCircularMenuOpen} // Passed here to enable animation
          icon={icon}
          bottom={bottom}
          right={right}
        />
      ))}
    </>
  );
};

type CircularButtonProps = {
  ref: React.RefObject<HTMLDivElement>;
  isShown: boolean;
  icon: Icon;
  bottom: number;
  right: number;
};

const CircularMenuButton = forwardRef((props: CircularButtonProps, ref: React.Ref<HTMLDivElement>) => {
  const { isShown, icon, bottom, right } = props;
  const style = { bottom, right, display: "block", opacity: 1.0 };
  const IconClass = icon;

  return (
    <CircularButtonDiv ref={ref} style={isShown ? style : {}}>
      <IconClass size={30} style={FLOATING_ICON_STYLES} />
    </CircularButtonDiv>
  );
});

const CircularButtonDiv = styled.div`
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: ${zIndexes.newNoteButton + 2};
  padding-top: 15px;
  padding-right: 4px;
  text-align: center;
  border-radius: 50%;
  background: ${BUTTON_BG};
  color: ${colors.bg.primary};
  transition: all 0.15s ease-in-out;

  :hover {
    background: ${BUTTON_HOVER_BG};
  }
`;

const CircularMenuCircle = styled.div`
  position: absolute;
  opacity: 0;
  z-index: ${zIndexes.newNoteButton + 1};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: ${BUTTON_BG};
  color: ${colors.bg.primary};
  height: 0;
  width: 0;
  border-radius: 100%;
  transition: all 0.15s ease-in-out;
  // disable selection
  webkittouchcallout: none;
  webkituserselect: none;
  khtmluserselect: none;
  mozuserselect: none;
  msuserselect: none;
  userselect: none;
`;
