import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { Selection } from "prosemirror-state";
import { getParentNote } from "../editor/utils/find";
import { editorViewAtom } from "../model/atoms";
import { useShortcuts } from "../shortcuts/useShortcuts";
import { useHotkeys } from "../shortcuts/useHotkeys";
import { createAsyncExtractedEntitiesNode } from "./createAsyncExtractedEntitiesNode";
import { extractEntitiesInNoteAndAppendToNote } from "./extractEntitiesInNote";

/**
 * If there's a selection, extract entities from it and replace selection with
 * them.
 * Otherwise, extract entities from the current note and append them to
 * it.
 *
 * Uses {@link createAsyncExtractedEntitiesNode} and {@link extractEntitiesInNoteAndAppendToNote}.
 * See those functions for more details.
 */
export function useExtractEntitiesShortcut() {
  const editorView = useAtomValue(editorViewAtom);
  const { shortcuts } = useShortcuts();
  const callback = useCallback(() => {
    if (!editorView) return;
    const tr = editorView.state.tr;
    const { from, to } = editorView.state.selection;
    if (from === to) {
      // If the selection is empty, extract entities from the current note and append them to it.
      const pos = getParentNote(tr.doc, from)[1];
      if (pos === null) return;
      extractEntitiesInNoteAndAppendToNote(tr, pos);
    } else {
      // Replace the selection with the extracted entities.
      tr.replaceWith(
        from,
        to,
        createAsyncExtractedEntitiesNode(tr, editorView.state.doc.textBetween(from, to), { from, to }),
      );
    }
    tr.setSelection(Selection.near(tr.doc.resolve(from)));
    editorView.focus();
    editorView.dispatch(tr);
  }, [editorView]);
  useHotkeys(shortcuts.extractEntitiesWithAI, callback);
}
