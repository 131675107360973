import { useMemo } from "react";
import { useSetAtom } from "jotai";
import { editorUpdateAtom } from "../../model/atoms";

/**
 * Helper to notify the editor has updated
 *
 */
export const useUpdateEditor = () => {
  const set = useSetAtom(editorUpdateAtom);
  return useMemo(
    () => () => {
      // Left intentionally, useful for debugging unnecessary editor updates
      // logger.warn("FORCING EDITOR UPDATE");
      set(new Date().getTime());
    },
    [set],
  );
};
