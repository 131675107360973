import { Fragment, Slice } from "prosemirror-model";
import { Share } from "react-feather";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { noteToProsemirrorNode } from "../../editor/bridge";
import { filterEllipsis } from "../../editor/features/ellipsis/utils";
import { serializeNode } from "../../editor/utils/serializeNode";
import { copyTextToClipboard } from "../../export/copyToClipboard";
import { appSearcher } from "../../model/services";
import logger from "../../utils/logger";
import { getSearchQuery } from "../../search/useSearchQuery";
import { addToast } from "../../components/Toast";
import { NavButton } from "./NavButton";

/**
 * Share all notes that match the current search query.
 */
function shareQueriedNotes() {
  const searchQuery = getSearchQuery();
  const { notes } = appSearcher.searchNotes(searchQuery);
  const noteNodes = notes.map((hit) =>
    noteToProsemirrorNode(hit.entry, { matches: hit.matches, condensingEnabled: searchQuery.isCondensed }),
  );
  let fragment = Fragment.from(noteNodes);
  fragment = filterEllipsis(fragment);
  const contentToCopy = serializeNode(new Slice(fragment, 0, fragment.childCount - 1));
  if (contentToCopy.length === 0) {
    return;
  }
  if (navigator.share) {
    navigator
      .share({
        text: contentToCopy,
      })
      // We can safely ignore the error since an error is thrown if the user cancels the share sheet
      .catch((e) =>
        logger.info(`navigator.share error: ${e}`, {
          namespace: "editor",
        }),
      );
  } else {
    // As fallback, try to copy text to clipboard
    addToast({
      content: "Notes in view copied to clipboard",
    });
    copyTextToClipboard(contentToCopy);
  }
  trackEvent("share_queried_notes");
}

export function ShareButton() {
  return (
    <NavButton onMouseDown={shareQueriedNotes}>
      <Share size={20} />
    </NavButton>
  );
}
