import styled from "@emotion/styled";

export const EditorScrollContainer = styled.div`
  position: relative;
  margin: 0 auto;
  min-width: 125px;
  max-width: 100%;
  padding: 0;
  min-height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  isolation: isolate; /* Form a new stacking context to prevent dropdowns inside of the editor from showing on top of the sidebar */
  overscroll-behavior: contain; /* Fixes an issue with iOS PWA: https://linear.app/ideaflow/issue/ENT-1839/ios-pwa-installed-app-locks-scrolling-after-certain-actions */
  flex-grow: 1;
`;
