import { useEffect, useState } from "react";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import { Note, NoteId } from "../../../shared/types";
import { generatePathFromNoteId } from "../../editor/utils/path";
import { appNoteStore, appSearcher, expansionSet, noteBlockMatches } from "../../model/services";
import { SearchResults } from "../../search/Searcher";
import { useSearchQuery } from "../../search/useSearchQuery";
import { editorUpdateAtom, noteListUpdatedSourceAtom, searchResultsCountAtom } from "../../model/atoms";
import { useSimonSearch } from "../../utils/useSimonSearch";
import { Hit } from "../../search/find";
import logger from "../../utils/logger";

/** Returns the notes that match the current search query */
export function useQueriedNotes() {
  const latestNetworkEdit = useAtomValue(editorUpdateAtom);
  const searchQuery = useSearchQuery();
  const setSearchResultsCount = useSetAtom(searchResultsCountAtom);
  const { simonSearch } = useSimonSearch();

  const jotaiStore = getDefaultStore();

  const [results, setResults] = useState<SearchResults>();

  useEffect(() => {
    // First set the results to a synchronous local search
    setResults(appSearcher.searchNotes(searchQuery));
    jotaiStore.set(noteListUpdatedSourceAtom, null);

    // Then do an async simon search, and append the results
    const queryAsText = searchQuery.keywordsList?.join(" ") || "";
    let isCancelled = false;
    simonSearch(queryAsText)
      .then((noteIds: NoteId[]) => {
        if (noteIds.length === 0 || isCancelled) return;

        setResults((prev) => {
          // If prev is undefined, we don't have any local results to append to (shouldn't happen)
          if (prev === undefined) return prev;

          // Simon only returns 15 notes so this won't be a performance issue (for now)
          const simonResultIds = noteIds.filter((noteId) => !prev.notes.some((n) => n.entry.id === noteId));
          const simonResultsAsHits: Hit<Note>[] = simonResultIds
            .map((id) => appNoteStore.get(id))
            .filter((n): n is Note => n !== undefined && !n.deletedAt)
            .map((entry) => ({ entry, isFromSimon: true }));

          jotaiStore.set(noteListUpdatedSourceAtom, "simon");

          return {
            ...prev,
            upperLimit: prev.upperLimit + simonResultsAsHits.length,
            notes: [...prev.notes, ...simonResultsAsHits],
          };
        });
      })
      .catch((e) => {
        jotaiStore.set(noteListUpdatedSourceAtom, null);
        if (isCancelled) return;
        logger.error("Failed to get search results from Simon", { error: e });
      });

    // Cleanup if the component unmounts
    return () => {
      isCancelled = true;
      jotaiStore.set(noteListUpdatedSourceAtom, null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, latestNetworkEdit, simonSearch]);

  useEffect(() => {
    if (!results) return;
    setSearchResultsCount(results.count);
    expansionSet.clear();
    noteBlockMatches.clear();
    results.notes.forEach((note) => {
      const path = generatePathFromNoteId(note.entry.id);
      noteBlockMatches.set(path, note.matches || []);
    });
  }, [results, setSearchResultsCount]);

  return results || { notes: [], lowerLimit: 0, upperLimit: 0, count: 0 };
}
