import { EditorState } from "prosemirror-state";
import { getTopLevelTokensFromContent } from "../editor/bridge";
import { createPlugins } from "../editor/editorState";
import { descendNotes } from "../editor/utils/descendNotes";
import { appNoteStore, appFolderStore } from "../model/services";
import { schema } from "../editor/schema";
import { createEditorView } from "../editor/editorView";

/**
 * Create empty notes with the noteIds in editorStateJson to resolve an
 * error where the plugins expect the notes to already be in appNoteStore
 * TODO: Find a way around needing to do this
 */
const initNotesFromEditorState = (editorStateJson: any) => {
  const noteIds: string[] = editorStateJson.doc.content.map((note: any) => {
    return note.attrs["noteId"];
  });
  appNoteStore.upsert(
    noteIds.map((noteId) => {
      return { id: noteId };
    }),
  );
};

const setStoresFromEditorState = (state: EditorState) => {
  appNoteStore.clear();
  appFolderStore.clear();
  descendNotes(state.doc, (node) => {
    const tokens = getTopLevelTokensFromContent(node.content);
    appNoteStore.insert([
      {
        id: node.attrs.noteId,
        insertedAt: node.attrs.insertedAt,
        readAll: node.attrs.readAll,
        folderId: node.attrs.folderId,
        tokens,
      },
    ]);
  });
};

/**
 * Given a JSON representation of an editor state, create a new editor state and
 * set the stores to match
 */
export const createEditorStateAndSetStoreFromJSON = (json: any) => {
  initNotesFromEditorState(json);
  const newEditorState = EditorState.fromJSON({ schema, plugins: createPlugins() }, json);
  setStoresFromEditorState(newEditorState);
  return newEditorState;
};

/**
 * Given a JSON representation of an editor state, create a new editor view and
 * set the stores to match
 */
export const createEditorViewAndSetStoreFromJSON = (json: any, div: HTMLElement = document.createElement("div")) => {
  const { view } = createEditorView({
    div,
    isReadOnly: false,
    initialNotes: [],
  });
  const state = createEditorStateAndSetStoreFromJSON(json);
  view.updateState(state);
  return view;
};
