import React, { useRef } from "react";
import styled from "@emotion/styled";
import { nanoid } from "nanoid";
import { colors } from "../utils/style";

const Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  background-color: ${colors.bg.tertiary};
  :checked + label span {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  :checked + label {
    background-color: ${colors.bg.accent.primary};
  }
`;

const BlobContainer = styled.label({
  margin: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  width: "24px",
  height: "calc(var(--handle-size) + 6px)",
  background: colors.bg.accent.tertiary,
  borderRadius: "100px",
  border: "1px solid " + colors.bg.primary,
  position: "relative",
});

const BlobButton = styled.span(`
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-size);
  background-color: white;
`);

const TextLabel = styled.label`
  font-size: 14px;
  color: ${colors.text.secondary};
  cursor: pointer;
  user-select: none;
`;

export function Toggle({
  isChecked,
  onChange,
  label,
  id,
}: {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  id?: string;
}) {
  const ref = useRef(nanoid());
  return (
    <Wrapper id={id}>
      {label ? <TextLabel htmlFor={ref.current}>{label}</TextLabel> : null}
      <Input checked={isChecked} onChange={onChange} id={ref.current} type="checkbox" />
      <BlobContainer htmlFor={ref.current}>
        <BlobButton />
      </BlobContainer>
    </Wrapper>
  );
}
