import { FolderId, Folder, IdeaPosition, IdeaDate } from "../../../shared/types";
import { isWorker } from "../../utils/environment";
import { generateId } from "../generateId";
import { PositionGenerator } from "../positionGenerator";
import { NoteStore } from "./NoteStore";
import { LocalStore } from "./LocalStore";

if (isWorker) {
  throw new Error("The worker needs to use the persisted folder list");
}

type FolderInsert = Partial<Omit<Folder, "updatedAt">>;
type FolderCreate = Partial<Folder>;
type FolderUpdates = {
  id: FolderId;
  name?: string;
  parentId?: FolderId | null;
  position?: IdeaPosition;
  deletedAt?: IdeaDate | null;
};
type FolderUpsert = Partial<Folder> & { id: FolderId };

/**
 * Orchestrates Folder CRUD operations.
 *
 * Will dispatch updates to the sw.
 */
export class FolderStore {
  constructor(
    private localStore: LocalStore,
    private noteStore: NoteStore,
    private folders: Map<FolderId, Folder> = new Map(),
    private folderPositions: PositionGenerator<Folder> = new PositionGenerator(
      () => this.getAll(),
      (e) => e.position,
    ),
  ) {
    this.localStore.folderHandler = (folder) => {
      this.upsert(folder, false);
    };
  }

  /**
   * Takes partial folder and returns a folder object with missing properties
   * added with default values.
   * The folder object is not inserted into the list.
   * */
  private create(folder?: FolderCreate): Folder {
    folder = folder || {};
    return {
      id: folder.id || generateId(),
      name: folder.name || "New Folder",
      parentId: folder.parentId || null,
      position: folder.position || this.folderPositions.generateFirst()[0],
      updatedAt: folder.updatedAt ?? new Date(),
      createdAt: folder.createdAt ?? new Date(),
      deletedAt: folder.deletedAt || null,
    };
  }

  /** Takes partial folder objects, fills with defaults, and inserts into the list.*/
  insert(newFolders: FolderInsert[] | FolderInsert = {}, shouldPersist = true) {
    if (!Array.isArray(newFolders)) newFolders = [newFolders];
    const start = new Date();
    const folders: Folder[] = newFolders.map((folder) => {
      if (folder.id && this.folders.has(folder.id)) {
        throw new Error("Folder already exists");
      }
      return this.create({ ...folder, updatedAt: start });
    });
    return this.set(folders, shouldPersist);
  }

  /**
   * Takes partial folders objects, each with an id of a folder which already exists,
   * and updates the corresponding folders with the provided properties.
   */
  update(folderUpdates: FolderUpdates[] | FolderUpdates, shouldPersist = true) {
    if (!Array.isArray(folderUpdates)) folderUpdates = [folderUpdates];
    const start = new Date();
    const folders: Folder[] = folderUpdates.map((folderUpdate) => {
      const oldFolder = this.folders.get(folderUpdate.id);
      if (!oldFolder) {
        throw new Error(`Folder with id ${folderUpdate.id} does not exist`);
      }
      return {
        ...oldFolder,
        ...folderUpdate,
        updatedAt: start,
      };
    });
    return this.set(folders, shouldPersist);
  }

  /**
   * Updates or inserts into list with the given partial folders.
   *
   * Unlike insert and update methods, this method does not modify the given
   * notes in any way (e.g. it does not fix tokens or set updateAt).
   *
   * @warning This method shouldn't only be used by sync.
   * Client features should use insert and update methods.
   */
  upsert(folderUpserts: FolderUpsert[] | FolderUpsert, shouldPersist = true) {
    if (!Array.isArray(folderUpserts)) folderUpserts = [folderUpserts];
    return folderUpserts.map((folder) => {
      folder.id = folder.id || generateId();
      const oldFolder = this.folders.get(folder.id);
      if (!oldFolder) {
        return this.set({ ...this.create(), ...folder }, shouldPersist);
      } else {
        return this.set({ ...oldFolder, ...folder }, shouldPersist);
      }
    });
  }

  set(folders: Folder[] | Folder, shouldPersist = true) {
    if (!Array.isArray(folders)) folders = [folders];
    folders.forEach((folder) => this.folders.set(folder.id, folder));
    if (shouldPersist && folders.length > 0) {
      this.localStore.upsertFolders(folders);
    }
    return folders;
  }

  /** Removes all notes from the folder and set the deletedAt field to the current date */
  delete(id: FolderId, shouldPersist: boolean) {
    // deassign all notes from this folder
    const notes = this.noteStore.getAll(true).filter((n) => n.folderId === id);
    this.noteStore.update(
      notes.map((n) => ({ ...n, folderId: null })),
      shouldPersist,
    );
    // deparent all child folders
    const childFolders = this.getAll().filter((n) => n.parentId === id);
    this.update(
      childFolders.map((folder) => ({ ...folder, parentId: null })),
      shouldPersist,
    );
    return {
      folder: this.update({ id, deletedAt: new Date() }, shouldPersist)[0],
      undoDelete: () => {
        this.update({ id, deletedAt: null }, shouldPersist);
        this.noteStore.update(
          notes.map((n) => ({ id: n.id, folderId: id })),
          shouldPersist,
        );
        this.update(
          childFolders.map((n) => ({ id: n.id, parentId: id })),
          shouldPersist,
        );
      },
    };
  }

  getSubFoldersId(id: FolderId): FolderId[] {
    const folders = this.getAll();
    let out: FolderId[] = [id];
    let toScan = [id];
    while (toScan.length) {
      const parentId = toScan.pop()!;
      const children = folders.filter((f) => f.parentId === parentId).map((f) => f.id);
      toScan = toScan.concat(children);
      out = out.concat(children);
    }
    return out;
  }

  hasSubfolder(id: string): boolean {
    return this.getAll().some((f) => f.parentId === id);
  }

  getAll(): Folder[] {
    return [...this.folders.values()]
      .filter((e) => !e.deletedAt)
      .sort((a, b) => {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      });
  }

  getAllAsMap() {
    return this.folders;
  }

  has(id: FolderId) {
    const element = this.folders.get(id);
    return element && !element.deletedAt;
  }

  hasOrHad(id: FolderId) {
    return this.folders.has(id);
  }

  get(id: FolderId) {
    return this.folders.get(id);
  }

  clear() {
    this.folders.clear();
  }
}
