import { FetchWrapper } from "../fetchWrapper";

export const AnalyticsApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Adds a row to the product_analytics_events table on the thoughtstream-analytics DB on RDS.
     * @param userId username
     * @param userEmail user email
     * @param eventName name of event being tracked: AnalyticsEventName
     * @param eventValues currently an object with one value {value: string | number | undefined}
     * @param eventBlob any serializable object
     * @param timestamp ISO 8601 timestamp string
     * @param platform currently "Native iOS" | "iOS browser or PWA" | "Android" | "Mac" | "Other mobile" | "Other desktop"
     * @param environment local / dev / prod
     * @param isOffline whether event was stored while offline to be retried later
     * @param latestCommitId latest git commit id, used to track version
     */
    track: async (
      userId: string,
      userEmail: string,
      eventName: string,
      eventValues: any,
      eventBlob: any,
      timestamp: string,
      platform: string,
      environment: string,
      isOffline: boolean,
      latestCommitId: string,
    ): Promise<void> =>
      client.post("/analytics", {
        userId,
        userEmail,
        eventName,
        eventValues,
        eventBlob,
        timestamp,
        platform,
        environment,
        isOffline,
        latestCommitId,
      }),
  };
};
