import styled from "@emotion/styled";
import { useAtomValue } from "jotai";
import { Toggle } from "../components/Toggle";
import { isGreaterThanMobileWidth } from "../utils/environment";
import { isCondensable, SearchQuery, SortBy, sortByDefault } from "../search/SearchQuery";
import { colors } from "../utils/style";
import { trackEvent } from "../analytics/analyticsHandlers";
import { appFolderStore } from "../model/services";
import { searchResultsCountAtom } from "../model/atoms";
import { SearchResultsSortMenu } from "./SearchResultsSortMenu";

function SearchResultsCount() {
  const count = useAtomValue(searchResultsCountAtom);
  const Text = styled.div`
    margin-left: 8px;
    font-size: 14px;
    color: ${colors.text.secondary};
  `;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text>{count.toLocaleString()} results</Text>
    </div>
  );
}

function trackSortBy(sortBy: SortBy) {
  if (sortBy === SortBy.position) trackEvent("sort_by_position");
  if (sortBy === SortBy.lastUpdated) trackEvent("sort_by_last_updated");
  if (sortBy === SortBy.oldest) trackEvent("sort_by_oldest");
  if (sortBy === SortBy.relevance) trackEvent("sort_by_relevance");
}

function SearchResultsControls({
  setSearchQuery,
  searchQuery,
}: {
  setSearchQuery: (search: SearchQuery) => void;
  searchQuery: SearchQuery;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 5,
      }}
    >
      <SearchResultsSortMenu
        label={isGreaterThanMobileWidth ? "Sort by" : ""}
        currentValue={searchQuery.sortBy ?? sortByDefault}
        setValue={(sortBy: SearchQuery["sortBy"]) => {
          setSearchQuery({
            ...searchQuery,
            sortBy,
          });
          if (!sortBy) return;
          trackSortBy(sortBy);
        }}
      />
      {isCondensable(searchQuery) && (
        <Toggle
          id="compact-view-toggle"
          label={isGreaterThanMobileWidth ? "Compact view" : "Compact"}
          isChecked={!!searchQuery.isCondensed}
          onChange={() => {
            const isCondensed = !searchQuery.isCondensed;
            setSearchQuery({
              ...searchQuery,
              isCondensed,
            });
            trackEvent(isCondensed ? "toggle_compact_view_on" : "toggle_compact_view_off");
          }}
        />
      )}
      {searchQuery.folderId && appFolderStore.hasSubfolder(searchQuery.folderId) && (
        <Toggle
          id="subfolders-toggle"
          label="Subfolders"
          isChecked={!!searchQuery.isIncludingSubFolders}
          onChange={() => {
            const isIncludingSubFolders = !searchQuery.isIncludingSubFolders;
            setSearchQuery({
              ...searchQuery,
              isIncludingSubFolders,
            });
            trackEvent(isIncludingSubFolders ? "toggle_with_subfolders_on" : "toggle_with_subfolders_off");
          }}
        />
      )}
    </div>
  );
}

export function SearchResultsHeader({
  setSearchQuery,
  searchQuery,
}: {
  setSearchQuery: (search: SearchQuery) => void;
  searchQuery: SearchQuery;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <SearchResultsCount />
      <SearchResultsControls setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
    </div>
  );
}
