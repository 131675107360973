import { Command, TextSelection } from "prosemirror-state";
import { findSelectionAtStartOfNote } from "../../utils/find";
import { getSearchQuery } from "../../../search/useSearchQuery";

/**
 * While filtering by hashtags, if a note's prefix is exactly the series of
 * hashtags in the search query, and the user's caret is just to the right of
 * those, selects the hashtags.
 *
 * The reason for this is that, while in a hashtag filter, new notes are created
 * with a hashtag at the start of the note. In this context, we want to make it
 * easy for the user to delete the hashtags if they don't want it. This command
 * allows them to delete the hashtags by pressing backspace once.
 */
export const getSelectHashtagOnBackspaceCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const searchQuery = getSearchQuery();

  if (!(searchQuery.hashtagsList && searchQuery.hashtagsList.length)) return false;
  const { from, to } = state.selection;
  const left = state.doc.nodeAt(from - 1);
  if (!left || from !== to) return false; // check caret collapsed and node to left
  const posStart = findSelectionAtStartOfNote(state.doc, from)?.from;
  if (posStart == null) return false;
  const prefix = state.doc.textBetween(posStart, from).toLocaleLowerCase();
  const hashtagListPrefix = searchQuery.hashtagsList.join(" ").toLocaleLowerCase() + " ";
  if (prefix === hashtagListPrefix) {
    const tr = state.tr;
    tr.setSelection(new TextSelection(tr.doc.resolve(posStart), tr.doc.resolve(from)));
    dispatch(tr);
    return true;
  }
  return false;
};
