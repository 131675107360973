import React from "react";
import { pinnedPositions, appNoteStore } from "../../../model/services";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { useNotifySidebarUpdate } from "../../atoms/sidebarUpdate";
import { SidebarLinkGroup } from "../SidebarLinkGroup";
import { SidebarSectionEmptyState } from "../SidebarSectionEmptyState";
import { PinnedHashtag, PinnedItem } from "../../../../shared/types";
import { upsertUserSettings } from "../../../model/userSettings";
import { PinnedList } from "./PinnedList";

const updatePinnedIds = (draggedItem: PinnedItem, result: PinnedItem[]) => {
  // set the new position, depending on where the dragged note was moved to
  const indexOfElBefore = result.indexOf(draggedItem) - 1;
  let pos: string;
  if (indexOfElBefore >= 0) {
    const precedingElPos = result[indexOfElBefore].positionInPinned!;
    pos = pinnedPositions.generateAfter(precedingElPos)[0];
  } else {
    pos = pinnedPositions.generateFirst()[0];
  }

  if (draggedItem.type === "note") {
    appNoteStore.update({ id: draggedItem.id, positionInPinned: pos });
  } else {
    // Dragged element is a hashtag
    const pinnedHashtags = result.filter((el) => el.type === "hashtag") as PinnedHashtag[];

    // Update the position of the dragged hashtag
    pinnedHashtags.find((el) => el.id === draggedItem.id)!.positionInPinned = pos;

    // Save pinned hashtags to user settings
    upsertUserSettings({ pinnedHashtags });
  }
};

const getPinnedItemsOrdered = () => {
  return pinnedPositions.getAllWithPosition().sort((a, b) => {
    const positionA = a.positionInPinned ?? "";
    const positionB = b.positionInPinned ?? "";
    if (positionA < positionB) return -1;
    if (positionA === positionB) return 0;
    return 1;
  });
};

export const PinnedSection: React.FC<{
  setSearch: SearchQuerySetter;
}> = ({ setSearch }) => {
  const elements = getPinnedItemsOrdered();
  const updateSidebar = useNotifySidebarUpdate();

  return (
    <SidebarLinkGroup header="Pinned" type="pinned">
      {elements.length ? (
        <PinnedList
          elements={elements}
          setElements={(...args) => {
            updatePinnedIds(...args);
            updateSidebar();
          }}
          setSearch={setSearch}
        />
      ) : (
        <SidebarSectionEmptyState text="Tap the pin in a note's menu to stick it here" />
      )}
    </SidebarLinkGroup>
  );
};
