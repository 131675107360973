import { compareNotesPositions } from "../search/sortUtils";
import { Note, NotePosition, SettledNote } from "../../shared/types";
import { Positions } from "./position/Positions";

/**
 * PositionGenerator derives new fractional positions from a list of nullable fractional positions.
 *
 * It is a stateless class.
 */
export class PositionGenerator<Element> {
  constructor(
    private getAll: (withDeleted: boolean) => Element[],
    private getPos: (element: Element) => string | null,
  ) {}

  START_POS = Positions.integers.INTEGER_ZERO; // "a0"

  /**
   * Does not insert a new position into the array, it needs to be done manually
   */
  generateAfter(position: string, number = 1): string[] {
    const positionsAfter = this.getAllPositions().filter((pos) => pos > position);
    const next =
      positionsAfter.length === 0
        ? null
        : positionsAfter.reduce((current, pos) => {
            return pos < current ? pos : current;
          });
    return Positions.generateMultipleBetween(position, next, number);
  }

  generateFirst(number = 1) {
    const positions = this.getAllPositions();
    if (positions.length === 0) {
      return [this.START_POS, ...Positions.generateMultipleBetween(this.START_POS, null, number - 1)];
    }
    // find first
    const first = positions.reduce((current, pos) => {
      return pos < current ? pos : current;
    });
    return Positions.generateMultipleBetween(null, first, number);
  }

  generateLast() {
    const positions = this.getAllPositions();
    if (positions.length === 0) {
      return this.START_POS;
    }
    const last = positions.reduce((current, pos) => {
      return pos > current ? pos : current;
    });
    return Positions.generateMultipleBetween(last, null, 1)[0];
  }

  private getAllPositions() {
    const positions: string[] = [];
    this.getAll(true).forEach((element) => {
      const pos = this.getPos(element);
      if (pos) positions.push(pos);
    });
    return positions;
  }

  getAllWithPosition() {
    const elements: Element[] = [];
    this.getAll(true).forEach((element) => {
      const pos = this.getPos(element);
      if (pos) elements.push(element);
    });
    return elements;
  }
}

/** This function creates a note position generator */
export function makeNotePositionGenerator(getAll: (withDeleted: boolean) => Note[]) {
  // The position generator for the settled positions
  const noteSettledPositions = new PositionGenerator(getAll, (e) =>
    e.position.type === "settled" ? e.position.position : null,
  );

  // The position generator for the on-top-provisional positions
  const noteProvisionalTopPositions = new PositionGenerator(getAll, (e) =>
    e.position.type === "on-top-provisional" ? e.position.position : null,
  );

  // notePositions delegates to either `noteSettledPositions` or `noteProvisionalTopPositions`
  return {
    generateAfter(position: NotePosition, number = 1): NotePosition[] {
      if (position.type === "settled") {
        return noteSettledPositions.generateAfter(position.position, number).map((position) => ({
          type: "settled",
          position,
        }));
      }
      if (position.type === "on-top-provisional") {
        return noteProvisionalTopPositions.generateAfter(position.position, number).map((position) => ({
          type: "on-top-provisional",
          position,
        }));
      }
      // `satisfies never` ensures this never executes
      return position satisfies never;
    },

    generateFirst(number = 1): NotePosition[] {
      return noteProvisionalTopPositions.generateFirst(number).map((position) => ({
        type: "on-top-provisional",
        position,
      }));
    },

    findDelegate(position: NotePosition) {
      return position.type === "settled" ? noteSettledPositions : noteProvisionalTopPositions;
    },
  };
}

/**
 * Takes a list of notes, which may or may not have settled positions
 * and returns a list of notes with settled positions.
 * */
export function settleNotes(notesToSettle: Note[], allNotes: Note[]) {
  // Select notes that are already settled
  const settledNotes = notesToSettle.filter((n): n is SettledNote => n.position.type === "settled");

  // Find all the notes with provisional positions (in their provisional order)
  const provisionalNotes = notesToSettle
    .map((n) => ({ ...n }))
    .filter((n) => n.position.type === "on-top-provisional")
    .sort(compareNotesPositions);

  // Generate the real positions for those notes
  const newPositions = new PositionGenerator(
    (withDeleted) => allNotes.filter((n) => withDeleted || !n.deletedAt),
    (n) => (n.position.type === "settled" ? n.position.position : null),
  ).generateFirst(provisionalNotes.length);

  return {
    alreadySettled: settledNotes,
    newlySettled: provisionalNotes.map((note, i) => ({
      ...note,
      position: { type: "settled" as const, position: newPositions[i]! },
    })),
  };
}
