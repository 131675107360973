import throttle from "lodash.throttle";
import { addToast } from "../../components/Toast";
import { ModalEnum, openModal } from "../modals";

const addSyncIssueToast = (n: number) => {
  addToast({
    content: (
      <>
        <span>An unexpected error occured, {n ? n : "a few"} of your recent notes may not be saved. Please&nbsp;</span>
        <span className="link no-word-break" onClick={(e) => openModal(ModalEnum.EXPORT)}>
          export your notes
        </span>
        <span>&nbsp;and refresh the page. If the problem persists, please re-login.</span>
      </>
    ),
    type: "error" as const,
  });
};

export const throttledAddSyncIssueToast = throttle(addSyncIssueToast, 10_000, { leading: true });
