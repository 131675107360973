import { EditorState, Transaction, Plugin } from "prosemirror-state";
import { Fragment, Node, NodeRange, NodeType, Slice } from "prosemirror-model";
import { findWrapping, ReplaceAroundStep, canSplit, canJoin, ReplaceStep, AddMarkStep } from "prosemirror-transform";
import { undo, closeHistory } from "prosemirror-history";
import { schema } from "../../schema";
import { preserveExpandedReferencesAround } from "../reference/referenceExpansionUtils";
import { regexpMatchers } from "../../utils/mark/regexpMatchers";
import { findParent } from "../../utils/find";
import logger from "../../../utils/logger";

const listCreateOrWrap = (state: EditorState, createdFromLineStart = true) => {
  const { $from, $to } = state.selection;

  const range = $from.blockRange($to);
  if (!range) return null;
  const wrap = findWrapping(range, schema.nodes.bulletList, undefined, range);
  if (!wrap) return null;
  const tr = state.tr;
  preserveExpandedReferencesAround(state, tr, () => {
    // wrap the content
    doWrapInList(tr, range!, wrap, false, schema.nodes.bulletList);

    // remove the bullet/spaces at the start of the line
    if (createdFromLineStart) {
      tr.delete(tr.selection.$from.start(), tr.selection.$from.pos);
    }

    // if the new bulleted list directly borders another bulleted list at the same depth, merge them
    joinAdjacentLists(tr);
  });

  return tr;
};

function joinAdjacentLists(tr: Transaction): Transaction {
  if (!tr.isGeneric) return tr;

  const isJoinable = (before: Node, after: Node) => {
    return before.type === schema.nodes.bulletList && after.type === schema.nodes.bulletList;
  };

  const ranges: any = [];
  for (let i = 0; i < tr.mapping.maps.length; i++) {
    const map = tr.mapping.maps[i];
    for (let j = 0; j < ranges.length; j++) ranges[j] = map.map(ranges[j]);
    map.forEach((_s, _e, from, to) => ranges.push(from, to));
  }

  // Figure out which joinable points exist inside those ranges,
  // by checking all node boundaries in their parent nodes.
  const joinable = [];
  for (let i = 0; i < ranges.length; i += 2) {
    const from = ranges[i],
      to = ranges[i + 1];
    const $from = tr.doc.resolve(from),
      depth = $from.sharedDepth(to),
      parent = $from.node(depth);
    for (let index = $from.indexAfter(depth), pos = $from.after(depth + 1); pos <= to; ++index) {
      const after = parent.maybeChild(index);
      if (!after) break;
      if (index && joinable.indexOf(pos) === -1) {
        const before = parent.child(index - 1);
        if (before.type === after.type && isJoinable(before, after)) joinable.push(pos);
      }
      pos += after.nodeSize;
    }
  }
  // Join the joinable points
  joinable.sort((a, b) => a - b);
  for (let i = joinable.length - 1; i >= 0; i--) {
    if (canJoin(tr.doc, joinable[i])) tr.join(joinable[i]);
  }
  return tr;
}

function doWrapInList(
  tr: Transaction,
  range: NodeRange,
  wrappers: {
    type: NodeType;
    attrs?:
      | {
          [key: string]: any;
        }
      | null
      | undefined;
  }[],
  joinBefore: boolean,
  listType: NodeType,
) {
  let content = Fragment.empty;
  for (let i = wrappers.length - 1; i >= 0; i--)
    content = Fragment.from(wrappers[i].type.create(wrappers[i].attrs, content));

  tr.step(
    new ReplaceAroundStep(
      range.start - (joinBefore ? 2 : 0),
      range.end,
      range.start,
      range.end,
      new Slice(content, 0, 0),
      wrappers.length,
      true,
    ),
  );

  let found = 0;
  for (let i = 0; i < wrappers.length; i++) if (wrappers[i].type === listType) found = i + 1;
  const splitDepth = wrappers.length - found;

  let splitPos = range.start + wrappers.length - (joinBefore ? 2 : 0);
  const parent = range.parent;
  for (let i = range.startIndex, e = range.endIndex, first = true; i < e; i++, first = false) {
    if (!first && canSplit(tr.doc, splitPos, splitDepth)) {
      tr.split(splitPos, splitDepth);
      splitPos += 2 * splitDepth;
    }
    splitPos += parent.child(i).nodeSize;
  }
}

function isTextInsert(trs: readonly Transaction[]) {
  trs = trs.filter((tr) => !tr.steps.every((s) => s instanceof AddMarkStep));
  if (trs.length !== 1) return false;
  if (trs[0].steps.length !== 1) return false;
  const step = trs[0].steps[0];
  return step instanceof ReplaceStep && step.from === step.to;
}

type PluginState = {
  lastWasCreateList: boolean;
};
/**
 * Creates a bullet list the user inputs a dash/asterisk followed by
 * any character at the start of a line. The user can undo this action
 * by pressing backspace immediately after creating the list.
 *
 * Design notes:
 * - We don't use prosemirror's InputRule here because it doesn't handle the case
 * where the character following the dash/asterisk was inserted by a transaction
 * rather than a user input event (e.g. from the mobile keyboard bar).
 * - We don't want to insert a list item *anytime* a line starts with a dash or
 * asterisk. For example, if a user backspaces or deletes their way into `*\s`-like
 * line start, we don't want to create a list item. This is why we use the
 * lastWasInput state to check whether the last keystroke was an input.
 */
const createNestedListPlugin = new Plugin<PluginState>({
  state: {
    init() {
      return { lastWasCreateList: false };
    },
    apply(tr, prev) {
      const newVal = tr.getMeta("lastWasCreateList") || (tr.getMeta("noChangeToModel") && prev.lastWasCreateList);
      return {
        lastWasCreateList: newVal,
      };
    },
  },
  props: {
    handleKeyDown(view, event) {
      const { lastWasCreateList } = createNestedListPlugin.getState(view.state) as PluginState;
      if (event.key === "Backspace" && lastWasCreateList) {
        event.preventDefault();
        undo(view.state, view.dispatch);
        return;
      }
    },
  },
  /**
   * If the user has typed a dash or asterisk followed by any character (with a few
   * exceptions) at the start of a line, create a list item.
   */
  appendTransaction: (trs, oldState, state) => {
    if (!isTextInsert(trs)) return;
    // Check the current line starts with a dash or asterisk followed by any character
    const { $from, $to } = state.selection;
    if ($from.parent.type !== schema.nodes.paragraph) return;
    const line = state.doc.textBetween($from.start(), $to.pos);
    if (!line) return;
    const bulletCharMatch = line.match(/^\t*-[^-,.]$|^\t*\*[^*,.]$/);
    const bulletLinkMatch = line[0].match(/\t*[*-]/) && line.slice(1).match(regexpMatchers.highConfidenceLink);
    if (!bulletCharMatch && !bulletLinkMatch) return null;

    // Try wrapping the current line in a list
    const tr = listCreateOrWrap(state, false);
    if (!tr) return null;

    const paragraph = tr.doc.nodeAt(tr.selection.$from.start() - 1);
    if (paragraph !== null) {
      tr.setNodeMarkup(tr.selection.$from.start() - 1, undefined, {
        ...paragraph?.attrs,
        depth: 0,
      });
    }
    // Delete dash or asterisk at start of bullet
    const bulletStart = tr.selection.$from.start();
    const text = tr.doc.textBetween(bulletStart, tr.selection.from);
    const match = text.match(/(\t*)[-*]\s*/);
    const bulletIndent = match ? match[1].length : 0;
    logger.info(`BULLET INDENT ${bulletIndent}`);
    if (match) tr.delete(bulletStart, bulletStart + match[0].length);

    // set the indentation of the list item to the indentation of the paragraph
    const [node, pos] = findParent(tr.doc, bulletStart, (n) => n.type === schema.nodes.listItem);
    const depth = $from.parent.attrs.depth || 0;
    if (pos !== null) {
      tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        depth: bulletIndent + depth,
      });
    }
    closeHistory(tr);
    return tr.setMeta("lastWasCreateList", true);
  },
});

export { createNestedListPlugin, listCreateOrWrap };
