import { MessageSquare } from "react-feather";
import { ModalEnum, toggleModal } from "../model/modals";
import { BUTTON_BG } from "./Buttons";
import { NO_STEALING_POINTER_EVENTS } from "./CircularMenu";
import { FLOATING_BUTTON_STYLES, FLOATING_ICON_STYLES } from "./FloatingButton";

export const SimonQueryButton = () => {
  return (
    // TODO : probably the wrapper is not needed
    <div onContextMenu={(e) => e.preventDefault()}>
      <div
        style={{
          ...FLOATING_BUTTON_STYLES,
          zIndex: 10,
          background: BUTTON_BG,
          right: 32,
          bottom: 32,
          ...NO_STEALING_POINTER_EVENTS,
        }}
        onClick={() => toggleModal(ModalEnum.SIMON_QUERY)}
      >
        <MessageSquare size={30} style={FLOATING_ICON_STYLES} />
      </div>
    </div>
  );
};
