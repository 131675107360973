import { User, useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { authMockSub, isAuthEnabled } from "../utils/environment";

export type IdeaflowUser = {
  /** Unique Ideaflow ID */
  id: string;
  /** ID received from auth0 */
  sub: string;
  first_name: string;
  last_name: string;
  email: string;
};

type Auth0Interface = Pick<
  ReturnType<typeof useAuth0>,
  | "error"
  | "isAuthenticated"
  | "isLoading"
  | "getAccessTokenSilently"
  | "getAccessTokenWithPopup"
  | "loginWithRedirect"
  | "loginWithPopup"
  | "logout"
> & {
  /** Ideaflow user */
  user?: User & IdeaflowUser;
};

const mockAuth = {
  // Auth state:
  error: undefined,
  isAuthenticated: true,
  isLoading: false,
  user: {
    id: authMockSub,
    sub: authMockSub,
    first_name: "Tyler",
    last_name: "Durden",
    nickname: "tyler",
    name: "Tyler Durden",
    picture: "/profile-default.jpg",
    locale: "en",
    updated_at: "2020-03-24T17:49:22.464Z",
    email: "bat@ideapad.io",
    email_verified: true,
  },
  // Auth methods:
  getAccessTokenSilently: () => Promise.resolve("INVALID_JWT_MOCK_AUTH" as any),
  getAccessTokenWithPopup: () => Promise.resolve("INVALID_JWT"),
  loginWithRedirect: () => Promise.resolve(),
  loginWithPopup: () => Promise.resolve(),
  logout: () => {},
};

const useMockAuth = (): Auth0Interface => mockAuth;

export const useAuth: () => Auth0Interface = !isAuthEnabled
  ? useMockAuth
  : () => {
      const auth = useAuth0();
      const user = auth.user;

      const ideaflowUser = useMemo(() => {
        return user?.sub
          ? {
              ...user,
              id: user.sub,
              sub: user.sub,
              email: user.email ?? "unknown",
              first_name: user.first_name ?? user.given_name ?? "unknown",
              last_name: user.last_name ?? user.family_name ?? "unknown",
            }
          : undefined;
      }, [user]);

      return {
        ...auth,
        user: ideaflowUser,
      };
    };
