import { createRoot } from "react-dom/client";
import { EditorView, NodeView } from "prosemirror-view";
import { Node } from "prosemirror-model";
import debounce from "lodash.debounce";
import { getDefaultStore } from "jotai";
import { Spinner } from "../../../utils/Spinner";
import { schema } from "../../schema";
import { addToast } from "../../../components/Toast";
import { upsertUserSettings } from "../../../model/userSettings";
import { userSettingsAtom } from "../../../model/atoms";
import { ApiClient } from "../../../api/client";
import logger from "../../../utils/logger";

const previouslyActivatedLinkLoaders = new Set<string>();

export class LinkLoaderView implements NodeView {
  dom: HTMLSpanElement;
  batchedToastLinkRemoval: () => void;

  constructor(
    private node: Node,
    private view: EditorView,
    private getPos: () => number | undefined,
  ) {
    this.dom = document.createElement("span");
    this.dom.classList.add("linkLoader");
    this.batchedToastLinkRemoval = debounce(() => {
      const { seenUnfurlUndo } = getDefaultStore().get(userSettingsAtom);
      if (seenUnfurlUndo) return;
      addToast("Link titles were removed");
      upsertUserSettings({ seenUnfurlUndo: true });
    });
    this.updateContent();
    this.onUpdate();
  }

  private onUpdate() {
    if (this.node.attrs.isActive) {
      this.unfurlTheLink();
      previouslyActivatedLinkLoaders.add(this.node.attrs.tokenId);
    } else if (previouslyActivatedLinkLoaders.has(this.node.attrs.tokenId)) {
      // If the link loader is not active and has already been activated in the past
      // the activation has been undone (via cmd+z) and the LinkLoaderView should self-destruct
      // setTimeout is necessary for the Prosemirror to not get confused and override the change anyway
      setTimeout(() => {
        this.replaceSelfWith([]);
        this.batchedToastLinkRemoval();
      }, 0);
    }
  }

  destroy() {}

  update(newNode: Node) {
    if (newNode.type !== this.node.type) return false;
    this.node = newNode;

    this.updateContent();
    this.onUpdate();

    return true;
  }

  private updateContent() {
    createRoot(this.dom).render(
      <Spinner
        style={{
          display: "inline-flex",
          height: "1.0em",
          width: "1.0em",
          margin: 0,
          verticalAlign: "middle",
        }}
        svgStyle={{ width: "100%", height: "100%", transform: "scale(1.5)" }}
        distanceFromCenter={35}
        numberOfPoints={6}
      />,
    );
  }

  private async unfurlTheLink() {
    upsertUserSettings({ seenUnfurl: true });
    let response = null;
    try {
      const apiClient = ApiClient();
      response = await apiClient.links.unfurl(this.node.attrs.url);
    } catch (err) {
      this.replaceSelfWith([]);
      return;
    }

    const { title } = response;
    // If the title is not available in the OpenGraph data, remove the loader without adding anything
    if (!title) {
      logger.warn("Link unfurling returned no title", { context: { url: this.node.attrs.url } });
      this.replaceSelfWith([]);
    } else {
      this.replaceSelfWith([schema.text(title, [schema.marks.italic.create()]), schema.text(" — ", [])]);
    }
  }

  private replaceSelfWith(nodes: Node[]) {
    const pos = this.getPos();
    if (!pos) return;
    const transaction = this.view.state.tr.replaceWith(pos, pos + 1, nodes).setMeta("addToHistory", false);
    this.view.dispatch(transaction);
  }

  selectNode() {
    this.dom.classList.add("ProseMirror-selectednode");
  }

  deselectNode() {
    this.dom.classList.remove("ProseMirror-selectednode");
  }
}
