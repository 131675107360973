import React from "react";
import styled from "@emotion/styled";
import { useAtomValue, useSetAtom } from "jotai";
import Search from "../../search/SearchBar";
import { NewNoteButtonMobileWithInteraction, NewNoteButtonWithInteraction } from "../NewNoteButton";
import { SimonQueryButton } from "../SimonQueryButton";
import { colors } from "../../utils/style";
import { isProd, isTouchDevice, simonQueryUserIds } from "../../utils/environment";
import SyncStatusIcon from "../../model/sync/SyncStatusIcon";
import { getUserId, isKeyboardVisibleAtom, mobileCloseKeyboardPressedAtom } from "../../model/atoms";
import { HEADER_ID } from "../../utils/constants";
import { globalAudioRecordingCoordinator } from "../../editor/features/audioInsert/audioRecordingCoordinator";
import { HelpButton } from "./HelpButton";
import { ShareButton } from "./ShareButton";
import { HomeButton } from "./HomeButton";
import { BackButton } from "./BackButton";
import { ToggleSideBarButton } from "./ToggleSideBarButton";
import { MicButton } from "./MicButton";
import { RecordingControlUI } from "./RecordingControlUI";

const HeaderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.border.secondary};
  height: var(--header-height);
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  background: ${colors.bg.primary};
  -webkit-app-region: drag;
  user-select: none;
`;

const HeaderContent = styled.div<{ width: number }>(
  (props) => `
  display: flex;
  margin: 0px auto;
  padding: 0 0 0 8px;
  align-items: center;
  width: ${props.width}px;

  /* Mobile */
  @media (max-width: 767px) {
    padding: 0;
  }
`,
);

const HeaderLeft = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const HeaderRight = styled.div`
  min-width: fit-content;
  width: 100%;
  height: 100%;
  padding-right: 8px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  justify-items: center;
`;

const HeaderTrailingIcons = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding-left: 8px;
  padding-right: 8px;
`;

interface Props {
  contentMaxWidth: number;
}

export const Header = (props: Props) => {
  const currentUserId = getUserId();
  const isKeyboardVisible = useAtomValue(isKeyboardVisibleAtom);
  const setCloseKeyboardPressed = useSetAtom(mobileCloseKeyboardPressedAtom);

  const recordingState = globalAudioRecordingCoordinator.useRecordingState();
  const isRecording = recordingState?.state === "recording";

  React.useEffect(() => {
    if (!isKeyboardVisible) {
      setCloseKeyboardPressed(false);
    }
  }, [isKeyboardVisible, setCloseKeyboardPressed]);

  const offsetTop = useVisualViewportOffsetTop();

  return (
    <>
      <HeaderContainer role="presentation" id={HEADER_ID} style={{ transform: `translateY(${offsetTop}px)` }}>
        <HeaderLeft>
          <ToggleSideBarButton />
          <HomeButton />
        </HeaderLeft>
        <HeaderContent width={props.contentMaxWidth}>
          <BackButton />
          <Search />
          <HeaderTrailingIcons id="header-trailing-icons">
            {/* On mobile devices the new note button is displayed outside of the header, see <NewNoteButtonMobileWithInteraction /> below */}
            {!isTouchDevice && <NewNoteButtonWithInteraction />}
            {/* On mobile devices the mic button is displayed outside of the header */}
            {!isTouchDevice && (!isRecording ? <MicButton /> : <RecordingControlUI />)}
            {isTouchDevice && isRecording && <RecordingControlUI />}
            {!isTouchDevice && <ShareButton />}
          </HeaderTrailingIcons>
        </HeaderContent>
        <HeaderRight>
          <SyncStatusIcon />
          {isTouchDevice && <ShareButton />}
          <HelpButton />
        </HeaderRight>
      </HeaderContainer>
      {isTouchDevice && <NewNoteButtonMobileWithInteraction />}
      {(!isProd || (currentUserId && simonQueryUserIds.includes(currentUserId))) && <SimonQueryButton />}
    </>
  );
};

const useVisualViewportOffsetTop = !globalThis.visualViewport
  ? () => 0
  : () => {
      const [state, setState] = React.useState(window.visualViewport!.offsetTop);
      React.useEffect(() => {
        const handleResize = () => setState(window.visualViewport!.offsetTop);
        window.visualViewport!.addEventListener("resize", handleResize);
        window.visualViewport!.addEventListener("scroll", handleResize);
        return () => {
          window.visualViewport!.removeEventListener("resize", handleResize);
          window.visualViewport!.removeEventListener("scroll", handleResize);
        };
      });
      return state;
    };
