import { ListItemToken, ListToken } from "../../shared/types";
import { topLevelTokenToProsersmirorNodes } from "../editor/bridge";
import { appendToNote } from "../editor/features/note/appendToNote";
import { getEditorView } from "../model/atoms";
import { appNoteStore } from "../model/services";
import logger from "../utils/logger";

export const updateCanonicalNote = (entityName: string, extractedAttributes: ListItemToken[]) => {
  const canonicalNote = appNoteStore.getCanonicalByName(entityName);
  if (!canonicalNote) {
    return;
  }

  let docChanged = false;
  const view = getEditorView();
  if (view) {
    const tr = view.state.tr;
    view.state.doc.descendants((node, pos) => {
      if (node.type.name === "note" && node.attrs.noteId === canonicalNote.id) {
        const listToken: ListToken = {
          type: "list",
          content: extractedAttributes,
        };
        const { nodes } = topLevelTokenToProsersmirorNodes([listToken]);
        appendToNote(tr, pos, nodes);
      }
    });
    if (tr.docChanged) {
      logger.info("Updating note tokens in editor state", {
        context: { noteId: canonicalNote.id, extractedAttributes },
      });
      view.dispatch(tr);
      docChanged = true;
    }
  }
  if (!docChanged) {
    const clonedNote = { ...canonicalNote };
    if (clonedNote.tokens.at(-1)?.type === "list") {
      const lastList = clonedNote.tokens.at(-1) as ListToken;
      lastList.content.push(...extractedAttributes);
      clonedNote.tokens = [...clonedNote.tokens.slice(0, -1), lastList];
    } else {
      const listToken: ListToken = {
        type: "list",
        content: extractedAttributes,
      };
      clonedNote.tokens = [...clonedNote.tokens, listToken];
    }
    logger.info("Updating note tokens in appNoteStore", {
      context: { noteId: canonicalNote.id, extractedAttributes },
    });
    appNoteStore.update(clonedNote);
  }
};
