import { ClipboardPayload } from "../../nativeIntegration/types";
import { isRunningInIOSWebview } from "../../utils/environment";
import logger from "../../utils/logger";

export class ClipboardPermissionError extends Error {}

class ClipboardManager {
  constructor(
    private nextRequestId = 0,
    private pendingRequests: Map<
      number,
      { resolve: (value: string) => void; reject: (reason?: any) => void }
    > = new Map(),
  ) {}

  readClipboard = async (): Promise<string> => {
    if (isRunningInIOSWebview) {
      return this.readIOSClipboard();
    } else {
      return this.readRegularClipboard();
    }
  };

  private readIOSClipboard(): Promise<string> {
    const requestId = this.nextRequestId++;
    const message = { requestId, action: "clipboard" };

    const promise = new Promise<string>((resolve, reject) => {
      this.pendingRequests.set(requestId, { resolve, reject });
    });

    window.webkit?.messageHandlers.clipboard.postMessage(message);

    return promise;
  }

  private readRegularClipboard = async (): Promise<string> => {
    const browser = navigator as any;
    const permission = await browser.permissions.query({ name: "clipboard-read" });

    if (permission.state === "denied") {
      throw new ClipboardPermissionError();
    } else {
      const clipboardContent = await browser.clipboard?.readText();
      if (clipboardContent) {
        return clipboardContent;
      } else {
        return "";
      }
    }
  };

  handleClipboardResponse(payload: ClipboardPayload) {
    const { requestId, result } = payload;
    if (this.pendingRequests.has(requestId)) {
      const { resolve, reject } = this.pendingRequests.get(requestId)!;
      this.pendingRequests.delete(requestId);
      if (result.error) {
        reject(result.error);
      } else {
        resolve(result.data);
      }
    } else {
      logger.error(`ClipboardManager: No pending request found for requestId ${requestId}`);
    }
  }
}

const clipboardManager = new ClipboardManager();
export default clipboardManager;
