import styled from "@emotion/styled";
import { ChevronLeft, ArrowLeft } from "react-feather";
import { useAtom } from "jotai";
import { isGreaterThanMobileWidth } from "../../utils/environment";
import { navigationHistoryAtom, useBack } from "../navigateHistory";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { NavButton } from "./NavButton";

const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

export const BackButton = ({ size = 20 }: { size?: number }) => {
  const [navigateHistory] = useAtom(navigationHistoryAtom);
  const back = useBack();
  return (
    <NavButton
      className="desktop-only"
      id="back-button"
      role="tooltip"
      data-microtip-position="right"
      aria-label="Click to go back"
      onMouseDown={() => {
        back();
        trackEvent("navigate_back_from_header");
      }}
      disabled={navigateHistory.length <= 1}
    >
      <ChevronContainer>
        {isGreaterThanMobileWidth ? <ArrowLeft size={size} /> : <ChevronLeft size={size} />}
      </ChevronContainer>
    </NavButton>
  );
};
