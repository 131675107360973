import { MinHeap } from "@datastructures-js/heap";
import appLogger, { Logger } from "../utils/logger";

type Task = { taskId: string; priority: number; callback: () => Promise<any> };
const DEFAULT_PRIORITY = 10;

export class ExclusiveRunner {
  private iteration = Math.random();
  private loopMs = 50;

  private readonly onTimeout: (taskId: string) => void;
  private readonly debug: (namespace: string) => (...data: any[]) => void;
  private readonly timeout: number;
  private readonly onCanceled: (taskId: string) => void;

  private queue = new MinHeap((task: Task) => task.priority);

  /**
   * A flag to allow or disallow pushing new tasks to the queue.
   */
  private allowPush = true;

  /**
   * A timer that calls the processTask method (delay is defined by `this.loopMs`) to pick tasks from the queue.
   */
  private loop: NodeJS.Timer;

  /**
   * A map to hold promises that are resolved when tasks are processed since runExclusive
   * returns a promise. Don't wanna change too much at once so preserving this behaviour.
   *
   * Since "runExclusive" returns a promise that resolves when the callback has finished.
   * We store a handle to a dummy promise and just resolve it when a task/callback has finished.
   */
  private promiseMap = new Map<string, { resolve: (value: any) => void; reject: (reason?: any) => void }>();
  private currentPriority: number | null = null;

  /**
   * An AbortController instance to handle abort signals for the currently running task.
   */
  private abortController: AbortController | null = null;

  /**
   * A flag indicating if a task is currently under processing.
   */
  private isLocked: boolean = false;

  constructor(
    private name: string,
    {
      timeout = 5_000,
      onTimeout = () => {},
      debug = () => () => {},
      onCanceled = () => {},
    }: {
      timeout?: number;
      onTimeout?: (taskId: string) => void;
      debug?: (namespace: string) => (...data: any[]) => void;
      onCanceled?: (taskId: string) => void;
    } = {},
  ) {
    this.onTimeout = onTimeout;
    this.debug = debug;
    this.timeout = timeout;
    this.onCanceled = onCanceled;
    this.loop = setInterval(this.processTask.bind(this), this.loopMs);
  }

  /**
   * Add callback to the queue. It will be called after all previous callbacks
   * have finished unless it has a higher priority.
   *
   * Note: The lower the priority value, the higher the priority/importance of the task.
   * Highest <-----------> Lowest
   * 0 > 1 > ... > 9 > 10 > +inf
   *
   * We wait a few seconds for each callback to finish (defined by the timeout param).
   * If it takes longer, we call onTimeout and start the next task.
   *
   * Returns a promise that resolves when the callback has finished, whether or
   * not it timed out.
   */
  runExclusive<T>(taskId: string, callback: () => Promise<T>, priority: number = DEFAULT_PRIORITY): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Preserve resolve handle to an empty promise that we can
      // lookup and fulfill when task has been processed.
      this.promiseMap.set(taskId, { resolve, reject });
      this.addTask({
        taskId,
        callback,
        priority,
      });
    });
  }

  private addTask(task: Task) {
    if (!this.allowPush) {
      this.onCanceled(task.taskId);
      const dummyPromise = this.promiseMap.get(task.taskId);
      dummyPromise?.reject("Unable to add new tasks");
      return;
    }
    this.queue.push(task);

    //If the pushed task has a higher priority, and a task is running with lower priority
    //cancel it. It's guaranteed that the next task to be picked up would be the pushed
    //task since it's a heap.
    if (this.isLocked && this.currentPriority && this.currentPriority > task.priority) {
      if (this.abortController) {
        this.abortController.abort();
      }
    }
  }

  private async processTask() {
    if (this.isLocked) return;
    const task = this.queue.pop();
    if (!task) return;
    const { taskId, callback, priority } = task;

    this.isLocked = true;
    this.abortController = new AbortController();

    this.currentPriority = priority;

    const debugTask = this.debug(`${this.name}:${taskId}`);
    const dummyPromise = this.promiseMap.get(taskId);

    let timeoutId: NodeJS.Timeout | undefined;
    let abortListener: () => void = () => {};
    try {
      const result = await Promise.race([
        callback(),
        new Promise((_, reject) => {
          abortListener = () => {
            debugTask("Aborting Task");
            this.onCanceled(taskId);
            reject(new Error("Task aborted"));
          };
          this.abortController?.signal.addEventListener("abort", abortListener, { once: true });
        }),
        new Promise((_, reject) => {
          timeoutId = setTimeout(() => {
            debugTask("Task Timed Out");
            this.onTimeout(taskId);
            reject(new Error("Task timed out"));
          }, this.timeout);
        }),
      ]);
      debugTask("Task Finished");
      dummyPromise?.resolve(result);
    } catch (e) {
      debugTask("Oops, something went wrong");
      dummyPromise?.reject(e);
    } finally {
      clearTimeout(timeoutId);
      this.abortController?.signal.removeEventListener("abort", abortListener);
      this.promiseMap.delete(taskId);
      this.abortController = null;
      this.currentPriority = null;
      this.isLocked = false;
    }
  }

  /**
   * Stop processing new event. Delete all tasks.
   */
  public destroy() {
    this.allowPush = false;
    clearInterval(this.loop);
    this.queue.clear();
    this.abortTask();
  }

  /**
   * Cancels a currently running task, if any.
   */
  private abortTask(): void {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  reset() {
    this.isLocked = true;
    this.iteration = Math.random();
    clearInterval(this.loop);
    this.abortTask();
    while (this.queue.size() > 0) {
      const task = this.queue.pop();
      if (task) {
        this.onCanceled(task.taskId);
        const dummyPromise = this.promiseMap.get(task.taskId);
        dummyPromise?.reject("Task aborted due to reset");
      }
    }
    this.abortController = null;
    this.currentPriority = null;
    this.isLocked = false;
    this.allowPush = true;
    this.loop = setInterval(this.processTask.bind(this), this.loopMs);
    return this;
  }
}

class SingleQueueAsyncRunner<T> {
  private currentTask: Promise<T> | null = null;
  private queuedTask: Promise<T> | null = null;
  private asyncTask: (logger: Logger) => Promise<any>;
  private logger: Logger;
  public name: string;

  constructor(asyncTask: (logger: Logger) => Promise<T>, { name, logger }: { name?: string; logger?: Logger } = {}) {
    this.name = name ?? this.constructor.name;
    this.asyncTask = asyncTask;
    this.logger = logger ?? appLogger.with({ namespace: this.name });
  }

  public run(): Promise<T> {
    if (!this.currentTask) {
      this.logger.info("No current task, starting new one");
      return this.startCurrentTask();
    } else if (!this.queuedTask) {
      this.logger.info("Current task exists, queueing new task");
      this.queuedTask = (async () => {
        await this.currentTask?.catch(() => {}); // next task should still run if current task fails
        this.queuedTask = null;
        return this.startCurrentTask();
      })();
      return this.queuedTask;
    } else {
      this.logger.info("Already have a queued task, skipping");
      return this.queuedTask;
    }
  }

  private startCurrentTask = () => {
    this.logger.info("Task start");
    this.currentTask = this.asyncTask(this.logger).finally(() => {
      this.logger.info("Task end");
      this.currentTask = null;
    });
    return this.currentTask;
  };
}

/**
 * Returns a function that will run an async task exclusively.
 *
 * When invoked:
 * - if no task is running, the task will start immediately and return a
 *   promise to the task.
 * - if the task is running, a new task will be queued to run after the current
 *   task finishes and return a promise to the queued task.
 * - if there's already a queued task, the function will return a promise to
 *   the queued task.
 */
export function createSingleQueueAsync<T>(
  fn: (logger: Logger) => Promise<T>,
  options: { name?: string; logger?: Logger } = {},
) {
  const runner = new SingleQueueAsyncRunner(fn, options);
  return () => runner.run();
}
