import { useAtomValue } from "jotai";
import { insertTextInEditor } from "../editorPage/MobileKeyboardBar";
import { useUpdateEditor } from "../editorPage/atomHelpers/editorUpdate";
import { isContinuousIntegration, isLocal } from "../utils/environment";
import { editorViewAtom, getEditorView } from "../model/atoms";
import { useSearchQuery, updateSearchQuery } from "../search/useSearchQuery";
import { createEditorStateAndSetStoreFromJSON } from "../utils/setEditorStateAndStores";
import { copyCurrentSelectionAsHTML, __clipboardTestingUtils } from "./utils/clipboard/__clipboardTestingUtils";

/**
 * Exposes functions to the global window object for integration tests.
 */
export function useExposeIntegrationTestFunctions() {
  const view = useAtomValue(editorViewAtom);
  const searchQuery = useSearchQuery();
  const updateEditor = useUpdateEditor();
  if (isContinuousIntegration || isLocal) {
    window.setStateFromJSON = (json: any) => {
      getEditorView()?.updateState(createEditorStateAndSetStoreFromJSON(json));
    };
    window.refreshEditor = () => {
      updateEditor();
    };
    window.insertTextInEditor = (type: string) => {
      insertTextInEditor(type);
    };
    window.copyCurrentSelectionAsHTML = () => {
      if (!view) return "";
      return copyCurrentSelectionAsHTML(view);
    };
    window.pasteHtml = (html: string) => {
      if (!view) return;
      const { pasteEvent } = __clipboardTestingUtils.newPasteEvent(view, {
        dataType: "text/html",
        data: html,
      });

      view.dom.dispatchEvent(pasteEvent);
    };
    window.setSearchQuery = (q) => updateSearchQuery(q);
    window.searchQuery = searchQuery;
  }
}
