import {
  InlineToken,
  AudioInsertToken,
  TopLevelToken,
  BlockContentToken,
  ListItemToken,
  ParagraphToken,
} from "../../shared/types";
import { joinCompatibleAdjacentInlineTokens } from "../editor/joinCompatibleAdjacentInlineTokens";
import { generateId } from "../model/generateId";
import { OPERATION_TIMEOUT } from "../editor/features/utils";
import logger from "../utils/logger";

function fixInlineTokens(tokens: InlineToken[]): InlineToken[] {
  // Notes saved with an older version of the app may contain hashtag tokens
  // split into two adjacent tokens. This fixes it. (linear.app/ideaflow/issue/ENT-1525)
  return joinCompatibleAdjacentInlineTokens(
    tokens
      .map((token: InlineToken) => {
        switch (token.type) {
          case "text":
            return token.content.length > 0 ? token : null;
          case "checkbox":
          case "linkloader":
          case "link":
          case "image":
          case "hashtag":
            return token;
          case "asyncReplacedElement": {
            const age = Date.now() - token.operationStartTimestamp;
            // Swap the timed-out asyncReplacedElements for their fallback text
            if (age > 3 * OPERATION_TIMEOUT) {
              return token.fallbackText.length > 0 ? { type: "text", content: token.fallbackText } : null;
            }
            return token;
          }
          case "spaceship":
            token.tokenId = token.tokenId || generateId();
            return token;
          default:
            token satisfies never;
            logger.warn("unknown inline token " + JSON.stringify(token));
            return { type: "text", content: JSON.stringify(token) };
        }
      })
      .filter((t): t is InlineToken => Boolean(t)),
  );
}

export function fixTopLevelTokens(tokens: TopLevelToken[] | any): TopLevelToken[] {
  if (tokens.length === 0) {
    return [
      {
        type: "paragraph",
        tokenId: generateId(),
        content: [{ type: "text", content: "", marks: [] }],
      },
    ];
  }
  return (tokens as TopLevelToken[])
    .flatMap((token): TopLevelToken[] => {
      if (token.type === "paragraph") {
        return fixParagraphContainingAudioInserts(token);
      }
      if (token.type === "list") {
        return [
          {
            type: token.type,
            content: token.content.map((listItem) => ({
              type: listItem.type,
              depth: listItem.depth,
              content: listItem.content.flatMap((t): BlockContentToken[] =>
                t.type === "paragraph" ? fixParagraphContainingAudioInserts(t) : [t],
              ),
            })),
          },
        ];
      }
      return [token];
    })
    .map((token: TopLevelToken) => {
      switch (token.type) {
        case "paragraph":
        case "codeblock":
          return fixBlockToken(token);
        case "list": {
          const listItems: ListItemToken[] = [];
          token.content.forEach((listItem) => {
            const token: ListItemToken = {
              type: "listItem",
              content: listItem.content.map(fixBlockToken),
              depth: listItem.depth,
            };
            listItems.push(token);
          });
          return {
            type: "list",
            content: listItems,
          };
        }
        case "audioInsert":
          return token;
        default:
          token satisfies never;
          logger.warn("Unknown token type " + (token as any).type);

          return {
            type: "paragraph",
            tokenId: generateId(),
            content: (token as any).content
              ? fixInlineTokens((token as any).content)
              : [
                  {
                    type: "text",
                    content: JSON.stringify(token),
                    marks: [],
                  },
                ],
          } satisfies ParagraphToken;
      }
    });
}

function fixBlockToken(token: BlockContentToken): BlockContentToken {
  switch (token.type) {
    case "paragraph":
      return {
        type: "paragraph",
        tokenId: token.tokenId || generateId(),
        content: fixInlineTokens(token.content),
        depth: token.depth ?? 0,
      };
    case "codeblock":
      return {
        type: "codeblock",
        tokenId: token.tokenId || generateId(),
        content: token.content.filter((token) => token.content?.length > 0),
      };
    case "audioInsert":
      return {
        ...token,
        tokenId: token.tokenId || generateId(),
      };
  }
}

function fixParagraphContainingAudioInserts(token: ParagraphToken): BlockContentToken[] {
  if (token.content.length === 0) {
    return [token];
  }
  let correctInlineTokens: InlineToken[] = [];
  const finalBlockTokens: BlockContentToken[] = [];
  function cutParagraph() {
    finalBlockTokens.push({
      type: "paragraph",
      tokenId: generateId(),
      content: correctInlineTokens,
    });
    correctInlineTokens = [];
  }
  let anyBrokenAudioToken = false;
  for (let i = 0; i < token.content.length; i++) {
    const inlineToken = token.content[i];
    // The audio token used to be inline tokens, we must now split all paragraphs into pieces where each audio-insert is
    const brokenAudioToken =
      (inlineToken.type as any) === "audioInsert" ? (inlineToken as any as AudioInsertToken) : null;
    if (brokenAudioToken) {
      anyBrokenAudioToken = true;
      // If there's no text before the audio insert, we don't need to cut the paragraph
      if (i > 0) cutParagraph();
      finalBlockTokens.push(brokenAudioToken);
    } else {
      correctInlineTokens.push(inlineToken);
    }
  }
  if (!anyBrokenAudioToken) {
    return [token];
  }
  cutParagraph();
  return finalBlockTokens;
}
