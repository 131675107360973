import { Command, Plugin } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";
import { chainCommands, joinBackward, joinForward, selectNodeBackward, selectNodeForward } from "prosemirror-commands";
import { undo, redo } from "prosemirror-history";
import { trackEvent } from "../analytics/analyticsHandlers";
import { NonCustomizableShortcut } from "../../shared/types";
import { nonCustomizableShortcuts } from "../shortcuts/rawShortcuts";
import { getSearchQuery, updateSearchQuery } from "../search/useSearchQuery";
import { schema } from "./schema";
import { goToLineStart, goToLineEnd } from "./features/paragraph/goToParagraphCommands";
import { expandReferenceCommand, collapseReferenceCommand } from "./features/reference/toggleExpandedReferenceCommands";
import { toggleCheckboxCommand } from "./features/checkbox/toggleCheckboxCommand";
import { splitNoteCommand } from "./features/note/splitNoteCommand";
import { insertParagraphCommand } from "./features/paragraph/insertParagraphCommand";
import { dedent, dedentParagraphIfNecessary, indent } from "./features/text/tabCommands";
import { insertBr } from "./features/text/insertBrCommand";
import { toggleAllowedMarks, clearAllowedMarks } from "./utils/mark/toggleAllowedMarks";
import { selectAll } from "./features/note/SelectAllPlugin";
import {
  goToNoteEnd,
  goToNoteStart,
  selectToNoteEnd,
  selectToNoteStart,
} from "./features/note/goToNoteStartEndCommand";
import { backspaceNoteCommand } from "./features/note/backSpaceNoteCommand";
import { joinNotesOnBackspaceCommand } from "./features/note/joinNotesOnBackspaceCommand";
import { collapsePrevExpandedNote } from "./features/reference/collapsePrevExpandedReference";
import { joinListOnBackspaceCommand } from "./features/list/joinListOnBackspaceCommand";
import { appendExpandedReferenceCommand } from "./features/reference/appendExpandedReferenceCommand";
import { deleteExpandedReferenceCommand } from "./features/reference/deleteExpandedReferenceCommand";
import { collapseEllipsisCommand, expandEllipsisCommand } from "./features/ellipsis/toggleEllipsisCommands";
import { backspaceEllipsisReveal } from "./features/note/backspaceEllipsisRevealCommand";
import { getSearchForSelection } from "./features/search/searchForSelection";
import { getOpenNoteAsPage } from "./features/search/openNoteAsPage";
import { joinNotesOnDeleteCommand } from "./features/note/joinNotesOnDeleteCommand";
import { deleteEmptyTopNoteOnBackspace } from "./features/note/deleteEmptyTopNoteOnBackspace";
import { collapseBacklinksCommand, expandBacklinksCommand } from "./features/backlink/toggleBacklinksCommands";
import { selectionToReference, selectionToHashtag } from "./features/autocomplete/replaceSelection";
import { getSelectHashtagOnBackspaceCommand } from "./features/hashtag/selectHashtagOnBackspaceCommand";
import { selectReferenceOnBackspaceCommand } from "./features/reference/selectReferenceOnBackspaceCommand";

function keymapWithEventTracking(commands: Map<NonCustomizableShortcut, Command>): Plugin {
  const bindings: { [key: string]: Command } = {};
  commands.forEach((command, shortcut) => {
    bindings[shortcut.keys] = (state, dispatch, view) => {
      trackEvent("keyboard_shortcut", shortcut.id);
      return command(state, dispatch, view);
    };
  });
  return keymap(bindings);
}

export default keymapWithEventTracking(
  new Map<NonCustomizableShortcut, Command>([
    [nonCustomizableShortcuts.splitNote, chainCommands(splitNoteCommand, appendExpandedReferenceCommand)],
    [nonCustomizableShortcuts.undo, undo],
    [nonCustomizableShortcuts.redo, redo],
    [nonCustomizableShortcuts.selectAll, selectAll],
    [nonCustomizableShortcuts.goToLineStart, goToLineStart],
    [nonCustomizableShortcuts.goToLineEnd, goToLineEnd],
    [nonCustomizableShortcuts.goToNoteStart, goToNoteStart],
    [nonCustomizableShortcuts.goToNoteEnd, goToNoteEnd],
    [
      nonCustomizableShortcuts.expandReference,
      chainCommands(expandReferenceCommand, (...args) => {
        const searchQuery = getSearchQuery();
        return searchQuery.keywordsList && searchQuery.isCondensed ? expandEllipsisCommand(...args) : false;
      }),
    ],
    [
      nonCustomizableShortcuts.collapseReference,
      chainCommands(collapseReferenceCommand, (...args) => {
        const searchQuery = getSearchQuery();
        return searchQuery.keywordsList && searchQuery.isCondensed ? collapseEllipsisCommand(...args) : false;
      }),
    ],
    [nonCustomizableShortcuts.expandBacklinks, expandBacklinksCommand],
    [nonCustomizableShortcuts.collapseBacklinks, collapseBacklinksCommand],
    [nonCustomizableShortcuts.selectUntilNoteEnd, selectToNoteEnd],
    [nonCustomizableShortcuts.selectUntilNoteStart, selectToNoteStart],
    [
      nonCustomizableShortcuts.backspace,
      chainCommands(
        backspaceEllipsisReveal,
        backspaceNoteCommand,
        dedentParagraphIfNecessary,
        joinNotesOnBackspaceCommand,
        collapsePrevExpandedNote,
        joinListOnBackspaceCommand,
        joinBackward,
        deleteExpandedReferenceCommand,
        selectNodeBackward,
        deleteEmptyTopNoteOnBackspace,
        getSelectHashtagOnBackspaceCommand,
        selectReferenceOnBackspaceCommand,
      ),
    ],
    [
      nonCustomizableShortcuts.delete,
      chainCommands(backspaceNoteCommand, joinNotesOnDeleteCommand, joinForward, selectNodeForward),
    ],
    [nonCustomizableShortcuts.tab, indent],
    [nonCustomizableShortcuts.shiftTab, dedent],
    [nonCustomizableShortcuts.insertBr, insertBr],
    [nonCustomizableShortcuts.insertParagraph, insertParagraphCommand],
    [nonCustomizableShortcuts.clearFormatting, clearAllowedMarks],
    [nonCustomizableShortcuts.toggleItalics, toggleAllowedMarks(schema.marks.italic)],
    [nonCustomizableShortcuts.toggleBold, toggleAllowedMarks(schema.marks.bold)],
    [nonCustomizableShortcuts.toggleUnderline, toggleAllowedMarks(schema.marks.underline)],
    [nonCustomizableShortcuts.toggleStrikethrough, toggleAllowedMarks(schema.marks.strikethrough)],
    [nonCustomizableShortcuts.toggleCheckboxShortcut, toggleCheckboxCommand],
    [nonCustomizableShortcuts.openAsPage, getOpenNoteAsPage(updateSearchQuery)],
    [nonCustomizableShortcuts.searchForSelection, getSearchForSelection(updateSearchQuery)],
    [nonCustomizableShortcuts.plus, selectionToReference],
    [nonCustomizableShortcuts.hashtag, selectionToHashtag],
  ]),
);
