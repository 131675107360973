import { useAtom, useAtomValue, getDefaultStore } from "jotai";
import styled from "@emotion/styled";
import { useState } from "react";
import { useAuth } from "../auth/useAuth";
import { menuUpdate, mobileNoteIdMenuAtom } from "../model/atoms";
import { appNoteStore } from "../model/services";
import { Note } from "../../shared/types";
import { useMenuActions } from "../editorPage/noteMenu/useMenuActions";
import { formatDate } from "../utils/date";
import { fixPublicUrlInNativeApp } from "../editorPage/utils/fixPublicUrlInNativeApp";
import { StopIcon } from "../editorPage/noteMenu/Icons";

const Background = styled.div`
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const PublicNoteMenuContainer = styled.div`
  position: fixed;
  right: 18px;
  top: 36px;
  z-index: 11;
`;

const MainMenu = styled.div`
  background-color: var(--color-bg-elevated);
  width: 240px;
  min-height: 150px;
  color: var(--color-text-primary);
  padding: 8px 0;
  border-radius: var(--radius-large);
  box-shadow: var(--shadow-heavy);
  border: var(--color-border-secondary);
  button {
    width: 100%;
  }
`;

export const closeNoteMenu = () => {
  getDefaultStore().set(mobileNoteIdMenuAtom, null);
  document.querySelectorAll(".note-menu-button-mobile.active").forEach((button) => button.classList.remove("active"));
};

const PublicModalMenu = ({ note, notePath }: { note: Note; notePath: string }) => {
  const { user } = useAuth();

  const a = useMenuActions(note, notePath, closeNoteMenu);

  const [isPublished, setIsPublished] = useState(note.readAll);
  const stopPublishing = () => {
    a.stopPublishing();
    setIsPublished(!isPublished);
  };

  return (
    <>
      <Background
        style={{ backgroundColor: `black`, opacity: 0.2 }}
        onClick={() => {
          a.toggleMenuExpanded(null);
          closeNoteMenu();
        }}
      />
      <PublicNoteMenuContainer>
        {a.menuExpanded() === null && (
          <MainMenu>
            <button className="menu-item" onClick={a.share}>
              Copy public link
            </button>
            <button
              className="menu-item"
              onClick={() => {
                window.open(
                  fixPublicUrlInNativeApp(
                    window.location.protocol + "//" + window.location.host + "/shared/" + note.id,
                  ),
                  "_blank",
                );
              }}
            >
              Open in new browser tab
            </button>
            <button className="menu-item" onClick={a.copy}>
              Copy text
            </button>
            {user && user?.id === note.authorId && note.readAll && (
              <button className="menu-item" onClick={stopPublishing}>
                <StopIcon />
                Stop publishing
              </button>
            )}
            <hr />
            <div className="menu-info">Created {formatDate(note.createdAt).toLowerCase()}</div>
            <div className="menu-info">Updated {formatDate(note.updatedAt).toLowerCase()}</div>
          </MainMenu>
        )}
      </PublicNoteMenuContainer>
    </>
  );
};

export const PublicNoteMenu = () => {
  const mobileNote = useAtomValue(mobileNoteIdMenuAtom);
  const noteId = mobileNote?.id ?? null;
  useAtom(menuUpdate);
  const note = noteId ? appNoteStore.get(noteId) ?? null : null;
  return note && mobileNote && <PublicModalMenu note={note} notePath={mobileNote.path} />;
};
