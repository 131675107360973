import { Note, TokenId, TopLevelToken, InlineToken, NoteId } from "../../shared/types";
import { NoteStore } from "../model/store/NoteStore";
import { isIOs } from "../utils/environment";
import { formatInsertedAt } from "../editorPage/utils/insertedAt";
import { FolderStore } from "../model/store/FolderStore";
import { mapBlockTokens } from "../../shared/tokenIterators/mapBlockTokens";
import { prepareKeywordList } from "./filterUtils";
import { Hit, relevance, removeDiacritics } from "./find";
import { SearchQuery } from "./SearchQuery";
import { sortNotes } from "./sortUtils";

const DEFAULT_NOTE_LIMIT = isIOs ? 100 : 300;
const MAXIMUM_ESTIMATED_TOTAL_LENGTH = 500;
export const NOTE_LIMIT_INCREMENT = 50;

export type SearchResults = {
  notes: Hit<Note>[];
  lowerLimit: number;
  upperLimit: number;
  count: number;
};

export class Searcher {
  constructor(
    private noteStore: NoteStore,
    private folderStore: FolderStore,
    private getDirtyNoteIds: () => Set<NoteId>,
  ) {}

  searchNotes(searchQuery: SearchQuery): SearchResults {
    const notes = sortNotes(this.getMatches(searchQuery), searchQuery);
    const { lowerLimit, upperLimit } = chooseLimits(notes, searchQuery);
    return {
      notes: notes.slice(lowerLimit, upperLimit),
      lowerLimit,
      upperLimit,
      count: notes.length,
    };
  }

  private getMatches(searchQuery: SearchQuery): Hit<Note>[] {
    const notes: Hit<Note>[] = [];
    this.noteStore.getAll().forEach((note: Note): void => {
      const hit = this.matchNote(note, searchQuery);
      if (hit !== null) notes.push(hit);
    });
    return notes;
  }

  private matchNote(note: Note, query: SearchQuery): Hit<Note> | null {
    if (query.isAll) return { entry: note, matches: [] };
    if (note.deletedAt) return null;
    if (!!query.isPublic && !note.readAll) return null;

    const blockTexts = this.noteStore
      .getNoteAsBlockTexts(note.id)
      ?.map((b) => ({ ...b, text: removeDiacritics(b.text.toLocaleLowerCase()) }));
    if (blockTexts === undefined) return null;

    // If we match on noteId, we always include it
    if (
      query.noteIdList &&
      query.noteIdList.length &&
      query.noteIdList.filter((n) => n.toLowerCase() === note.id.toLowerCase()).length
    ) {
      return {
        entry: note,
        matches: [],
        asText: blockTexts,
      };
    }

    const matches: Set<TokenId> = new Set<TokenId>();

    if (!!query.date && note.insertedAt !== formatInsertedAt(query.date)) return null;
    if (query.folderId) {
      if (!note.folderId) return null;
      const ids = query.isIncludingSubFolders ? this.folderStore.getSubFoldersId(query.folderId) : [query.folderId];
      if (!ids.includes(note.folderId)) return null;
    }
    if (query.isUnsynced && !this.getDirtyNoteIds().has(note.id)) return null;

    // no hashtags
    if (query.isUntagged) {
      const hashtagMatches = this.findInlineTokenMatches(note, (t) => t.type === "hashtag");
      if (hashtagMatches.size !== 0) return null;
    }

    // hashtags
    const includedHashtags = this.noteStore.hashtags.getItemsForNote(note.id).map((t) => t.toLocaleLowerCase());
    const queriedHashtags = (query.hashtagsList ?? []).map((h) => h.toLocaleLowerCase());
    for (const hashtag of queriedHashtags) {
      if (!includedHashtags.includes(hashtag)) return null;
      const hashtagMatches = this.findInlineTokenMatches(
        note,
        (t) => t.type === "hashtag" && t.content.toLocaleLowerCase() === hashtag,
      );
      if (hashtagMatches.size === 0) return null;
      hashtagMatches.forEach((m) => matches.add(m));
    }

    // checkboxes
    if (query.hasTodo) {
      const todoMatches = this.findInlineTokenMatches(note, (t) => t.type === "checkbox");
      if (todoMatches.size === 0) return null;
      todoMatches.forEach((m) => matches.add(m));
    }
    if (query.hasComplete) {
      const completeMatches = this.findInlineTokenMatches(note, (t) => t.type === "checkbox" && t.isChecked);
      if (completeMatches.size === 0) return null;
      completeMatches.forEach((m) => matches.add(m));
    }
    if (query.hasIncomplete) {
      const incompleteMatches = this.findInlineTokenMatches(note, (t) => t.type === "checkbox" && !t.isChecked);
      if (incompleteMatches.size === 0) return null;
      incompleteMatches.forEach((m) => matches.add(m));
    }

    // recordings
    if (query.hasRecording) {
      const recordingMatches = this.findBlockTokenMatches(note, (b) => b.type === "audioInsert");
      if (recordingMatches.size === 0) return null;
      recordingMatches.forEach((m) => matches.add(m));
    }

    // images
    if (query.hasImage) {
      const imageMatches = this.findInlineTokenMatches(note, (b) => b.type === "image");
      if (imageMatches.size === 0) return null;
      imageMatches.forEach((m) => matches.add(m));
    }

    // keywords
    const keywords = prepareKeywordList(query.keywordsList);
    if (query.keywordsList?.length && keywords.length === 0) return null; // if all keywords are stopwords
    for (const keyword of keywords) {
      let match = false;
      for (const block of blockTexts) {
        if (block.text.includes(keyword)) {
          matches.add(block.tokenId);
          match = true;
        }
      }
      if (!match) return null;
    }

    // If there is a note ID component to the query and we haven't matched any other part of the query, then we drop the note.
    // This lets the note ID filter be an "or" filter when combined with any other query component, but still have an effect when used on its own.
    if (query.noteIdList?.length && matches.size === 0) return null;

    return {
      entry: note,
      matches: Array.from(matches.values()),
      asText: blockTexts,
    };
  }

  /**
   * Return a list of block token ids which match the given block token predicate
   */
  private findBlockTokenMatches(note: Note, predicate: (blockToken: TopLevelToken) => boolean): Set<TokenId> {
    const matches: Set<TokenId> = new Set<TokenId>();
    note.tokens.forEach((childToken: TopLevelToken) => {
      mapBlockTokens((blockToken) => {
        if (predicate(blockToken) && blockToken.type !== "list") {
          matches.add(blockToken.tokenId);
        }
      })(childToken);
    });
    return matches;
  }

  /**
   * Returns a list of block token ids which contain an inline token matching the given predicate
   */
  private findInlineTokenMatches(note: Note, predicate: (blockToken: InlineToken) => boolean): Set<TokenId> {
    return this.findBlockTokenMatches(note, (blockToken) => {
      if (blockToken.type !== "paragraph") return false;
      return blockToken.content.some((inlineToken) => predicate(inlineToken));
    });
  }

  /**
   * Returns a list of hashtags that match the given text, sorted by relevance
   */
  searchHashtags(text: string): string[] {
    if (text === "") {
      return this.noteStore.getLastUpdatedHashtags();
    } else {
      const hashtags = this.noteStore.hashtags.getAllItems().map((h) => h[0].trim());
      const textLower = text.toLocaleLowerCase();
      return hashtags
        .filter((h) => h.toLocaleLowerCase().includes(textLower))
        .sort((a, b) => {
          // the slice removes the # prefix, which is not part of the query
          // relevance weights exact matching and prefix matching weighting
          const relA = relevance([a.slice(1)], [text]);
          const relB = relevance([b.slice(1)], [text]);
          if (relA < relB) return 1;
          if (relA > relB) return -1;
          return 0;
        });
    }
  }
}

/**
 * Returns an upper limit that caps the estimated total length of the notes
 * to avoid performance issues when rendering a large number of notes.
 */
function calculateUpperLimit(notes: Hit<Note>[], defaultValue: number) {
  const estimatedLengths = notes.map((note) => note.entry.tokens.length);

  let upperLimit = 0;
  let consumedLength = 0;
  while (consumedLength < MAXIMUM_ESTIMATED_TOTAL_LENGTH && upperLimit < notes.length) {
    consumedLength += estimatedLengths[upperLimit++];
  }

  return Math.min(upperLimit, defaultValue);
}

function chooseLimits(notes: Hit<Note>[], searchQuery: SearchQuery) {
  if (searchQuery.isAll && searchQuery.jumpTo) {
    const index = notes.findIndex((n) => n.entry.id === searchQuery.jumpTo);
    if (index !== -1) {
      return {
        lowerLimit: Math.max(0, Math.floor(index - DEFAULT_NOTE_LIMIT / 2)),
        upperLimit: index + calculateUpperLimit(notes, Math.floor(DEFAULT_NOTE_LIMIT / 2) + 1),
      };
    }
  }
  return {
    lowerLimit: searchQuery.lowerLimit ?? 0,
    upperLimit: searchQuery.upperLimit ?? calculateUpperLimit(notes, DEFAULT_NOTE_LIMIT),
  };
}
