// This import must be the first one to avoid other import errors from overshadowing this guard
import "./guardModuleNotOnSW";

import debug from "debug";
import { Path } from "../editor/utils/path";
import { Searcher } from "../search/Searcher";
import { NoteFormatter } from "../../shared/noteFormatter";
import { NoteId, PinnedItem, TokenId } from "../../shared/types";
import { isLocal } from "../utils/environment";
import { FolderStore } from "./store/FolderStore";

/**
 * Instantiates all the services needed when running the app.
 */

import { NoteStore } from "./store/NoteStore";
import { PositionGenerator } from "./positionGenerator";
import RamDirtySet from "./sync/RamDirtySet";
import { LocalStore } from "./store/LocalStore";
import { getSyncStats } from "./sync/SyncStats";
import { getUserSettings } from "./userSettings";

// The merged dirty set for both notes and folders on the main thread
// Never serialized, used only to prevent "echo" of the change propagating from service worker back to the tab
export const isDirtySet = new RamDirtySet();

export const appLocalStore = new LocalStore(isDirtySet);

export const appNoteFormatter = new NoteFormatter();
export const appNoteStore = new NoteStore({
  localStore: appLocalStore,
  noteFormatter: appNoteFormatter,
});
export const appFolderStore = new FolderStore(appLocalStore, appNoteStore);

export const getDirtyNoteIds = () => getSyncStats().sw?.dirtyNoteIds ?? new Set();
export const appSearcher = new Searcher(appNoteStore, appFolderStore, getDirtyNoteIds);

export const pinnedPositions = new PositionGenerator(
  (withDeleted) => {
    const notes = appNoteStore.getAll(withDeleted).map((note) => ({ ...note, type: "note" }));
    const { pinnedHashtags } = getUserSettings();
    const hashtags = appNoteStore.hashtags.getAllItems().map(([id]) => {
      const pinned = pinnedHashtags.find((h) => h.id === id);
      return pinned ?? { id, positionInPinned: null, type: "hashtag" };
    });
    return [...notes, ...hashtags] as PinnedItem[];
  },
  (e) => e.positionInPinned,
);
export const folderPositions = new PositionGenerator(
  () => appFolderStore.getAll(),
  (e) => e.position,
);

/**
 * `isCondensedMap` is used to preserve the condensed state of a note across editor refreshes.
 * Each note has an `isCondensed` attribute but is lost whenever a new editor is created
 * (e.g., during searching or appending notes).
 *
 * If a condensed state is not found for a particular note,
 * fallback to `true`.
 */
const isCondensedMap = new Map<NoteId, boolean>();

export const isCondensed = {
  get: (id: NoteId): boolean => (isCondensedMap.has(id) ? !!isCondensedMap.get(id) : true),
  set: (id: NoteId, state: boolean) => isCondensedMap.set(id, state),
};

/**
 * Index of note blocks that are considered matches in the current view.
 * These blocks should *not* be collapsed while in compact view.
 */
export const noteBlockMatches = new Map<Path, TokenId[]>();

/** A set of paths to references and backlink sections that should be expanded.*/
export const expansionSet = new Set<Path>();

export const enableDebug = (namespaces: string) => {
  localStorage.setItem("debug", namespaces);
  debug.enable(namespaces);
  appLocalStore.enableDebug(namespaces);
};

// expose services
(globalThis as any).enableDebug = enableDebug;
if (isLocal) {
  (globalThis as any).noteStore = appNoteStore;
  (globalThis as any).folderList = appFolderStore;
  (globalThis as any).searcher = appSearcher;
  (globalThis as any).isDirtySet = isDirtySet;
  (globalThis as any).pinnedNotePositions = pinnedPositions;
  (globalThis as any).folderPositions = folderPositions;
  (globalThis as any).noteBlockMatches = noteBlockMatches;
  (globalThis as any).expansionSet = expansionSet;
}
