import { closeHistory } from "prosemirror-history";
import { Transaction, Plugin } from "prosemirror-state";
import { ReplaceStep } from "prosemirror-transform";
import { getUserSettings, upsertUserSettings } from "../../../model/userSettings";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { addToast } from "../../../components/Toast";

/**
 * Replace @ insertions with #@.
 * We do this with an appendTransaction rather than an input rule because
 * we want to be able to undo the insertion of the #.
 */
export const atSignToHashtagPlugin = new Plugin({
  appendTransaction: (trs, oldState, newState) => {
    if (!getUserSettings().atSignHashtagEnabled) return null;
    // Must be a single @ inserted
    if (!trs.some((tr) => tr.docChanged)) return null;
    if (trs.some((tr) => tr.getMeta("paste"))) return null;
    const text = toInsertedText(trs);
    // (We allow one space before the @ because on mobile, when you hit the "@"
    // button on the keyboard bar, it inserts a space before if there is none.)
    if (!text?.match(/\s?@/)) return null;

    // Must be at start of line or after a space
    const startOfLine = oldState.selection.$from.parentOffset === 0;
    const atSignPos = newState.selection.from - 1;
    const afterSpace = newState.doc.textBetween(atSignPos - 1, atSignPos) === " ";
    if (!(startOfLine || afterSpace)) return null;

    // Insert # before the @
    const $to = newState.selection.$to;
    const tr = newState.tr.insertText("#", $to.pos - 1).setMeta("type", "atSignToHashtag");
    closeHistory(tr); // Ensures we can undo the insertion of the #
    trackEvent("at_sign_created");

    // Check if '@' is being typed for the first time
    if (!getUserSettings().seenAtSignToast) {
      const toastMessage = "@ autocompletes to #@ workflow. [Disable] in [Settings]";
      addToast(toastMessage);
      upsertUserSettings({ seenAtSignToast: true });
    }

    return tr;
  },
});

/**
 * If the transactions represent a single text insertion, return the text.
 * Otherwise, return null.
 */
function toInsertedText(trs: readonly Transaction[]): string | null {
  // Aside from marks, we should have a single replace step
  trs = trs.filter((tr) => !tr.getMeta("appendedTransaction"));
  if (trs.length !== 1) return null;
  if (trs[0].steps.length !== 1) return null;
  const step = trs[0].steps[0];
  if (!(step instanceof ReplaceStep)) return null;
  if (step.from !== step.to) return null;
  // and the content should be all text
  let allText = true;
  let text = "";
  step.slice.content.descendants((node, pos) => {
    if (!allText) return false;
    if (node.isText) {
      text += node.text;
      return;
    } else {
      allText = false;
      return false;
    }
  });
  return allText ? text : null;
}
