import { NodeSpec } from "prosemirror-model";
import { generateId } from "../../../model/generateId";
import { domAttrs, entityInnerDOM, getDefaultAttrs } from "../../utils/attrs";

export const reference: NodeSpec = {
  group: "inline",
  inline: true,
  draggable: false,
  atom: true,
  attrs: {
    tokenId: { default: "" },
    linkedNoteId: { default: "" },
    content: { default: "" },
    isExpanded: { default: false },
    isHighlighted: { default: false },
    isPrivate: { default: false },
  },
  toDOM: (node) => {
    // See ReferenceView for how references are actually constructed in the app
    // to dom is used when copying things out.
    return [
      "span",
      {
        ...domAttrs(node, ["linkedNoteId", "content"]), // we don't include isExpanded here, since we don't want the expansion state preserved on copy and paste
        class: "reference embed",
        role: "tooltip",
        "data-microtip-position": "left",
      },
      entityInnerDOM("", node.attrs.content),
    ];
  },
  parseDOM: [
    {
      tag: ".reference",
      getAttrs: (dom) => ({
        ...getDefaultAttrs(["linkedNoteId", "content"])(dom),
        // tokenId needs to be globally unique so for every copy-pasted node generate a new one
        tokenId: generateId(),
      }),
    },
  ],
};
