import { Command, TextSelection, Selection } from "prosemirror-state";
import { findSelectionAtEndOfNote, findSelectionAtStartOfNote } from "../../utils/find";

/**
 * Go to the start of the note. If already at the start, go to the start of the document.
 */
export const goToNoteStart: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const { $head } = state.selection;
  const selAtStart = findSelectionAtStartOfNote(state.doc, $head.pos);
  if (!selAtStart) {
    throw new Error("Couldn't find valid selection at start of note");
  }
  if ($head.pos === selAtStart.$from.pos) {
    // start of document, ENT-1256
    const selection = Selection.atStart(state.doc);
    dispatch(state.tr.setSelection(selection).scrollIntoView());
  } else {
    dispatch(state.tr.setSelection(selAtStart).scrollIntoView());
  }
  return true;
};

/**
 * Go to the end of the note. If already at the end, go to the end of the document.
 */
export const goToNoteEnd: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const { $head } = state.selection;
  const selAtEnd = findSelectionAtEndOfNote(state.doc, $head.pos);
  if (!selAtEnd) {
    throw new Error("Couldn't find valid selection at end of note");
  }
  if ($head.pos === selAtEnd.$to.pos) {
    // end of document, ENT-1256
    const selection = Selection.atEnd(state.doc);
    dispatch(state.tr.setSelection(selection).scrollIntoView());
  } else {
    dispatch(state.tr.setSelection(selAtEnd).scrollIntoView());
  }
  return true;
};

export const selectToNoteStart: Command = (state, dispatch) => {
  const { $head } = state.selection;
  const selAtStart = findSelectionAtStartOfNote(state.doc, $head.pos);
  if (!selAtStart || !dispatch) return false;
  dispatch(state.tr.setSelection(new TextSelection(selAtStart.$from, $head)).scrollIntoView());
  return true;
};

export const selectToNoteEnd: Command = (state, dispatch) => {
  const { $head } = state.selection;
  const selAtEnd = findSelectionAtEndOfNote(state.doc, $head.pos);
  if (!selAtEnd || !dispatch) return false;
  dispatch(state.tr.setSelection(new TextSelection(selAtEnd.$to, $head)).scrollIntoView());
  return true;
};
