import { EditorView } from "prosemirror-view";
import { EditorState, Selection } from "prosemirror-state";
import { deleteSelection } from "prosemirror-commands";
import { findSelectionAtEndOfNote, findSelectionAtStartOfNote } from "../../utils/find";

export function backspaceNoteCommand(state: EditorState, dispatch?: EditorView["dispatch"]): boolean {
  if (dispatch == null) return false;

  const { $from, $to } = state.selection;

  /*
   * Manually handle content deletion in the note.
   * If everything is selected and backspace is pressed, perform
   * deletion in two steps, such that, the content is transformed
   * into an empty paragraph, and the latter is deleted.
   *
   * For some reason, when the last element of a note is a "list",
   * Prosemirror deletes the note itself. A guess that this happens
   * only with "list" nodes is because they are not wrapped inside
   * a "p"/"div" node, while everything else is.
   *
   * It was noticed that this happens when the Backspace event is
   * handled natively with Prosemirror's keymap plugin, or Prosemirror's
   * `deleteSelection`/`deleteRange` method was called inside any plugin.
   * In this specific case it was being handled by:
   * handleListActionsPlugin (file) > deleteListItem (method) > deleteRange
   *
   * Hence this is NOT a problem with or side-effects of our plugins, and
   * can simply be reproduced by calling/ Prosemirror's `deleteSelection`
   * method (or chain it inside SelectAll plugin itself).
   *
   * Note:
   * - `deleteSelection` is binded to the Backspace key inside `keymap(baseKeymap)`.
   * - The selection range of SelectAll plugin is correct.
   */

  if ($to.pos > $from.pos) {
    const selStart = findSelectionAtStartOfNote(state.doc, $from.pos);
    const selEnd = findSelectionAtEndOfNote(state.doc, $to.pos);
    if (selStart?.$from.pos === $from.pos && selEnd?.$to.pos === $to.pos) {
      const tr = state.tr.insertText(" ", selStart.from, selEnd.to).deleteRange(selStart.from, selStart.from + 1);
      dispatch(tr);
      return true;
    }
  }

  // If the selection ends at the start of a note, only delete up to but not
  // including the last position. Without this tweak, selecting a paragraph and
  // deleting results in the merge of two notes, which users find frustrating.
  const isRangeSelection = $from.pos !== $to.pos;
  const endsAtStartOfNote = $to.pos === $to.start(1) + 1;
  if (isRangeSelection && endsAtStartOfNote) {
    const tr = state.tr;

    // Selection.$to that corrects for selection end extending into the next note
    const intendedSelectionEnd = Selection.findFrom(tr.doc.resolve($to.pos - 1), -1);
    if (!intendedSelectionEnd) return false;

    tr.delete($from.pos, intendedSelectionEnd.to);

    // attempt to find a suitable cursor position after the deletion
    const afterDeleteSelection = Selection.findFrom(tr.doc.resolve(tr.mapping.map($from.pos, -1)), -1);
    if (!afterDeleteSelection) return false;

    dispatch(tr.setSelection(afterDeleteSelection));
    return true;
  }

  return deleteSelection(state, dispatch);
}
