import throttle from "lodash.throttle";
import { ClientToSWMessage, ClientToSWMessageType } from "../../service-worker/message/swMessage";
import { syncChannel } from "../../service-worker/sync-channel";
import { isRunningInIOSWebview } from "../../utils/environment";
import logger from "../../utils/logger";

// The `self` can be typed as either `Window` or `WorkerGlobalScope` (in jest tests)
// To simulate the jest environment add `declare let self: WorkerGlobalScope`
declare let self: Window | WorkerGlobalScope;
function getServiceWorkerContainer(): ServiceWorkerContainer | null {
  if (self instanceof Window) {
    // When the code is running in the testcafe test environment the serviceWorker is missing
    return self.navigator.serviceWorker ?? null;
  } else {
    return null;
  }
}

export const sendMessageToSW = (message: ClientToSWMessage) => {
  if (isRunningInIOSWebview) {
    syncChannel.port2.postMessage(message);
    return true;
  }

  // When the code is running in the testcafe test environment the serviceWorker is missing
  const serviceWorker = getServiceWorkerContainer();
  if (!serviceWorker) return;

  // in unit tests, navigator is set to WorkerNavigator
  if (!serviceWorker.controller) {
    askServiceWorkerToClaimClients();
    return false;
  } else {
    logger.info(`sending ${message.type} message to sw`, {
      namespace: "sw-message",
    });
    serviceWorker.controller.postMessage(message);
    return true;
  }
};

// After a "hard reload" in Chrome, the service worker registration exists but that
// service worker is not controlling the hard reloaded page. To fix that we
// send the message to the service worker asking it to claim all clients. See https://linear.app/ideaflow/issue/ENT-1696
async function askServiceWorkerToClaimClients() {
  // This is a no-op in the iOS webview env
  if (isRunningInIOSWebview) {
    return;
  }

  // When the code is running in the testcafe test environment the serviceWorker is missing
  const serviceWorker = getServiceWorkerContainer();
  if (!serviceWorker) return;

  let registration = await serviceWorker.getRegistration();
  if (!registration) {
    registration = await serviceWorker.ready;
  }
  registration.active?.postMessage({
    type: ClientToSWMessageType.CLAIM_CLIENTS,
  } as ClientToSWMessage);
  reportDisconnectedServiceWorkerToSentry();
}

const reportDisconnectedServiceWorkerToSentry = throttle(() => {
  logger.warn("Disconnected service worker detected", {
    report: true,
    tags: { "service-worker": true },
    context: { legacyMessage: "disconnected-service-worker-detected" },
  });
}, 15_000);

function waitForSW() {
  // When the code is running in the testcafe test environment the serviceWorker is missing
  const serviceWorker = getServiceWorkerContainer();
  if (!serviceWorker) return;

  return new Promise<void>((res) => {
    serviceWorker.addEventListener("controllerchange", (e: any) => {
      res();
    });
  });
}

export async function sendMessageToSWWhenReady(message: ClientToSWMessage) {
  if (isRunningInIOSWebview) {
    return sendMessageToSW(message);
  }

  while (!sendMessageToSW(message)) {
    // wait for the SW to be available
    await waitForSW();
  }
}
