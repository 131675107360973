import originalDebug from "debug";
import { captureMessage, captureException } from "@sentry/nextjs";
import { getDefaultStore } from "jotai";
import { throttle } from "lodash";
import { accessTokenAtom, getUserEmail, getUserId } from "../model/atoms";
import { ApiClient } from "../api/client";
import { env, isCloudwatchLoggingEnabled, isContinuousIntegration } from "./environment";

type DebugFn = (namespace: string) => (...data: any[]) => void;

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

type Meta = {
  /** Optional namespace (if passed, `debug(namespace)` is used) */
  namespace?: string;
  /** Send to sentry, regardless of level */
  report?: boolean;
  /** Optionally include what was thrown */
  error?: any;
  /** Any relevant objects/strings */
  context?: Record<string, any>;
  /** Sentry tags */
  tags?: {
    [key: string]: Primitive;
  };
};

enum LEVELS {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  FATAL = "fatal",
}

export type CloudwatchLog = {
  message: string;
  timestamp: number;
};

const MAX_CLOUDWATCH_MESSAGE_SIZE = 262144;
const CLOUDWATCH_THROTTLE_TIME_MS = 60000;

const levelsToConsole: Record<LEVELS, typeof console.log> = {
  [LEVELS.INFO]: console.debug,
  [LEVELS.WARNING]: console.warn,
  [LEVELS.ERROR]: console.error,
  [LEVELS.FATAL]: console.error,
};

const cloudwatchLogQueue: CloudwatchLog[] = [];

const throttledLogToCloudwatch = throttle(() => {
  const apiClient = ApiClient();
  apiClient.cloudwatch.upload(cloudwatchLogQueue);
  cloudwatchLogQueue.splice(0, cloudwatchLogQueue.length);
}, CLOUDWATCH_THROTTLE_TIME_MS);

function concatMessages(mainMsg: string, contextMsg?: string): string {
  return contextMsg ? `${mainMsg} (${contextMsg})` : mainMsg;
}

function logToCloudwatch(level: LEVELS, message: string, meta: Meta): void {
  if (isContinuousIntegration || !getDefaultStore().get(accessTokenAtom)) return;

  const logEventString = JSON.stringify({
    level,
    userId: getUserId(),
    email: getUserEmail(),
    environment: env,
    message,
    ...meta.context,
    error: meta.error ? meta.error.toString() : undefined,
  });
  if (new Blob([logEventString]).size < MAX_CLOUDWATCH_MESSAGE_SIZE) {
    cloudwatchLogQueue.push({
      message: logEventString,
      timestamp: Date.now(),
    });
    throttledLogToCloudwatch();
  } else {
    captureMessage("Log event too large for Cloudwatch: " + message, {
      level: LEVELS.ERROR,
      tags: meta.tags,
      extra: meta.context,
    });
  }
}

type LoggerProps = {
  debugFn: DebugFn;
  namespace: string;
  tags: Record<string, Primitive>;
};

/**
 * A simple logger that sends errors to sentry and uses `debug` for namespaces
 * @example
 * const logger = new Logger();
 * logger.info("Hello world");
 * logger.warn("Something is wrong", { context: { foo: "bar" } });
 * logger.error("Something is wrong", { error: new Error("Something is wrong") });
 */
class Logger {
  #debugFn: DebugFn;
  #namespace: string;
  #tags: Record<string, Primitive>;

  constructor({ debugFn, namespace = "", tags = {} }: Partial<LoggerProps> = {}) {
    this.#debugFn = debugFn || originalDebug;
    this.#namespace = namespace;
    this.#tags = tags;
  }

  #log(level: LEVELS, message: any, meta: Meta): void {
    // Put the error in the meta if it's an Error
    if (message instanceof Error) {
      if (meta.error) {
        throw new Error("Cannot pass both meta.error and an Error as message");
      }
      meta.error = message;
      message = message.message;
    }

    if (isCloudwatchLoggingEnabled) {
      logToCloudwatch(level, message, meta);
    }

    const isError = level === "error" || level === "fatal";
    const sendToSentry = isError || meta.report || meta.error;

    // Send to sentry if it's an error, or if the meta says to report
    if (sendToSentry && meta.report !== false) {
      if (meta.error) {
        captureException(meta.error, {
          level,
          tags: { ...this.#tags, ...meta.tags },
          extra: {
            ...meta.context,
            message: concatMessages(message, meta.context?.message),
          },
        });
      } else {
        captureMessage(message, {
          level,
          tags: { ...this.#tags, ...meta.tags },
          extra: meta.context,
        });
      }
    }

    // Use `debug(namespace)` with a namespace, otherwise use console.* methods
    const namespace = meta.namespace || this.#namespace;
    const data = meta.context ? [message, meta.context] : [message];
    if (namespace) {
      this.#debugFn(namespace)(...data);
    } else {
      const consoleFn = levelsToConsole[level];
      consoleFn(...data);
    }
    if (meta.error) console.error(meta.error);
  }

  info(message: any, meta: Meta = {}): void {
    this.#log(LEVELS.INFO, message, meta);
  }

  warn(message: any, meta: Meta = {}): void {
    this.#log(LEVELS.WARNING, message, meta);
  }

  error(message: any, shouldReportToSentry?: boolean): void;
  error(message: any, meta?: Meta): void;
  error(message: any, meta: Meta | boolean = {}): void {
    if (typeof meta === "boolean") {
      meta = { report: meta };
    }
    this.#log(LEVELS.ERROR, message, meta);
  }

  fatal(message: any, shouldReportToSentry?: boolean): void;
  fatal(message: any, meta?: Meta): void;
  fatal(message: any, meta: Meta | boolean = {}): void {
    if (typeof meta === "boolean") {
      meta = { report: meta };
    }
    this.#log(LEVELS.FATAL, message, meta);
  }

  /** Allows to substitute the original debug function and namespace with custom ones */
  with(props: Partial<LoggerProps>): Logger;
  with(debugFn: DebugFn): Logger;
  with(props: Partial<LoggerProps> | DebugFn): Logger {
    const currentProps: LoggerProps = { debugFn: this.#debugFn, namespace: this.#namespace, tags: this.#tags };
    if (typeof props === "function") {
      return new Logger({ ...currentProps, debugFn: props });
    } else {
      return new Logger({ ...currentProps, ...props });
    }
  }
}

const logger = new Logger({ tags: { thread: "main" } });

export type { Logger };
export default logger;
