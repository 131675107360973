import { atom, getDefaultStore, useAtomValue } from "jotai";
import isEqual from "lodash.isequal";

export type SyncStats = {
  sw: (SyncMetrics & { dirtyNoteIds: Set<string> }) | null;
  server: SyncMetrics | null;
  client: SyncMetrics | null;
  online: boolean;
  /** Time this object was last updated */
  updatedAt: number;
  /** Issue with the current sync state, if any */
  issue:
    | "none"
    | "sw-unresponsive"
    | "server-unresponsive"
    | "client-sw-count-mismatch"
    | "sw-server-count-mismatch"
    | "sw-server-update-at-mismatch"
    | "missing-client-stats"
    | "missing-sw-stats"
    | "missing-server-stats";
  /** Last time we were fully synced */
  lastFullySyncedAt: number;
  /** Count of updates since last fully synced */
  countSinceLastFullySynced: number;
};

/**
 * Sync metrics for a given source. If null, it means we don't know yet.
 */
type SyncMetrics = null | {
  sampleDate: number;
  noteCount: number;
  mostRecentUpdatedAt: number;
  checkpoint?: number;
  userId: string;
};

/** Input to update the sync stats function */
export type SyncStatsUpdate = {
  sw?: SyncStats["sw"];
  server?: SyncStats["server"];
  client?: SyncStats["client"];
  online?: boolean;
};
/**
 * This hooks into the local store and updates the sync stats atom whenever the
 * local store receives a sync stats update from the service worker.
 */

const determineSyncIssue = ({
  client,
  sw,
  server,
  maxSampleDelay = 5000,
}: {
  client: SyncStats["client"];
  sw: SyncStats["sw"];
  server: SyncStats["server"];
  maxSampleDelay?: number;
}): SyncStats["issue"] => {
  const now = Date.now();
  // missing
  if (!client) {
    return "missing-client-stats";
  }
  if (!sw) {
    return "missing-sw-stats";
  }
  if (!server) {
    return "missing-server-stats";
  }

  // unresponsive
  if (now - sw.sampleDate > maxSampleDelay) {
    return "sw-unresponsive";
  }
  if (now - server.sampleDate > maxSampleDelay) {
    return "server-unresponsive";
  }

  // count mismatch
  if (client.noteCount !== sw.noteCount) {
    return "client-sw-count-mismatch";
  }
  if (sw.noteCount !== server.noteCount) {
    return "sw-server-count-mismatch";
  }

  return "none";
};

const syncStatsAtom = atom<SyncStats>({
  client: null,
  sw: null,
  server: null,
  online: true,
  issue: "none",
  lastFullySyncedAt: 0,
  updatedAt: 0,
  countSinceLastFullySynced: 0,
});

export function updateSyncStats(syncStats: SyncStatsUpdate) {
  getDefaultStore().set(syncStatsAtom, (prev: SyncStats) => {
    const newStats = { ...prev, ...syncStats };
    if (isEqual(prev, newStats)) return prev;
    const { countSinceLastFullySynced } = prev;
    const issue = determineSyncIssue(newStats);
    const now = Date.now();
    return {
      ...newStats,
      updatedAt: now,
      issue,
      lastFullySyncedAt: issue === "none" ? now : prev.lastFullySyncedAt,
      countSinceLastFullySynced: issue === "none" ? 0 : countSinceLastFullySynced + 1,
    };
  });
}

export function getSyncStats() {
  return getDefaultStore().get(syncStatsAtom);
}

export function useSyncStats() {
  return useAtomValue(syncStatsAtom);
}
