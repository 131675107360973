import { SortableTree, TreeItem, TreeItemComponentProps } from "dnd-kit-sortable-tree";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FolderStore } from "../../../model/store/FolderStore";
import { appFolderStore, folderPositions } from "../../../model/services";
import { Folder, FolderId } from "../../../../shared/types";
import { SidebarLinkGroup } from "../SidebarLinkGroup";
import { SidebarSectionEmptyState } from "../SidebarSectionEmptyState";
import { assert } from "../../../utils/assert";
import { ExpandableAndDraggable } from "./ExpandableAndDraggable";
import { FolderItem } from "./FolderItem";

export const FoldersSection = () => {
  const [newId, setNewId] = useState<string | null>(null);
  const folders = appFolderStore.getAll().sort((a, b) => a.position.localeCompare(b.position));

  return (
    <SidebarLinkGroup header="Folders" type="folder" setNewItemId={setNewId}>
      {folders.length ? (
        <FoldersList folders={folders} newId={newId} setNewId={setNewId} />
      ) : (
        <SidebarSectionEmptyState text="Create a folder to organize your notes" />
      )}
    </SidebarLinkGroup>
  );
};

type DraggableFolder = {
  id: FolderId; // already added by dnd, added for clarity
  name: string;
  newId: FolderId | null;
  setNewId: any;
};

interface FoldersListProps {
  folders: Folder[];
  newId: FolderId | null;
  setNewId: (id: FolderId | null) => void;
}

const FoldersList: React.FC<FoldersListProps> = ({ folders, newId, setNewId }) => {
  const [items, setItems] = useState(() => nest(folders, null, [], { newId, setNewId }));

  useEffect(() => {
    setItems((currentItems) => {
      const toBeExpanded = persistChanges(appFolderStore, currentItems);
      if (toBeExpanded.length) {
        return expand(currentItems, toBeExpanded);
      }
      return currentItems;
    });
  }, []);

  useEffect(() => {
    setItems((currentItems) => nest(folders, null, expandedIds(currentItems), { newId, setNewId }));
  }, [folders, newId, setNewId]);

  const handleItemsChanged = useCallback((newItems: TreeItem<DraggableFolder>[]) => {
    setItems(newItems);
    persistChanges(appFolderStore, newItems);
  }, []);

  const memoizedItems = useMemo(() => items, [items]);

  return (
    <SortableTree items={memoizedItems} onItemsChanged={handleItemsChanged} TreeItemComponent={DraggableFolderItem} />
  );
};

const DraggableFolderItem = React.forwardRef<HTMLDivElement, TreeItemComponentProps<DraggableFolder>>((props, ref) => {
  return (
    <ExpandableAndDraggable {...props} ref={ref}>
      <FolderItem
        {...props.item}
        handleProps={props.handleProps}
        childCount={props.childCount ?? 0}
        collapsed={props.collapsed ?? false}
        onCollapse={props.onCollapse}
      />
    </ExpandableAndDraggable>
  );
});

// Turns a flat list of folders into a nested tree
function nest(
  folders: Folder[],
  root: null | string,
  expanded: FolderId[],
  extraProps: {
    newId: FolderId | null;
    setNewId: any;
  },
): TreeItem<DraggableFolder>[] {
  const childFolders = folders.filter((f) => f.parentId === root).sort((a, b) => (a.position < b.position ? -1 : 1));

  return childFolders.map((folder, index) => {
    const children = nest(folders, folder.id, expanded, extraProps);

    return {
      id: folder.id,
      name: folder.name,
      children,
      collapsed: !expanded.includes(folder.id),
      position: folder.position,
      ...extraProps,
    };
  });
}

// Reads a tree view of folders, detect changes in position and parent, and persist those.
function persistChanges(list: FolderStore, folders: TreeItem<DraggableFolder>[]) {
  const toExpand: string[] = [];
  const depthFirst = folders.map((folder) => ({ ...folder, parentId: null as string | null }));

  let prevPosition = folderPositions.START_POS;

  while (depthFirst.length) {
    const { id, parentId, children } = depthFirst.shift()!;
    const folder = list.get(id)!;
    assert(Boolean(folder), "folder present in sidebar, but missing in storage.");

    let newPosition = folder.position;
    if (folder.position.localeCompare(prevPosition) <= 0) {
      newPosition = folderPositions.generateAfter(prevPosition)[0];
    }

    // the folder changed parent or is out of order.
    if (folder.parentId !== parentId || folder.position !== newPosition) {
      list.update({
        id,
        position: newPosition,
        parentId,
      });
      if (parentId) toExpand.push(parentId);
    }

    prevPosition = newPosition;

    if (children?.length) {
      depthFirst.unshift(...children.map((child) => ({ ...child, parentId: id })));
    }
  }

  return toExpand;
}

// Returns the expanded folderIds, depth first.
const expandedIds = (folders: TreeItem<DraggableFolder>[]): FolderId[] => {
  return folders.flatMap((folder) => {
    const elements = folder.children ? expandedIds(folder.children) : [];
    if (!folder.collapsed) elements.push(folder.id);
    return elements;
  });
};

// Expands specified folders in the folder tree
const expand = (folders: TreeItem<DraggableFolder>[], ids: FolderId[]): TreeItem<DraggableFolder>[] => {
  return folders.map((folder) => {
    const newFolder = { ...folder };
    if (ids.includes(folder.id)) {
      newFolder.collapsed = false;
    }
    if (folder.children && folder.children.length > 0) {
      newFolder.children = expand(folder.children, ids);
    }
    return newFolder;
  });
};
