import { SortBy, sortByDefault } from "./SearchQuery";

export const setIsIncludingSubFolders = (isIncludingSubFolders: boolean) => {
  localStorage.setItem("search-is-including-sub-folders", isIncludingSubFolders.toString());
  return isIncludingSubFolders;
};

export const getIsIncludingSubFolders = (): boolean => {
  return localStorage.getItem("search-is-including-sub-folders") === "true";
};

export const setIsCondensed = (isCondensed: boolean) => {
  localStorage.setItem("search-is-condensed", isCondensed.toString());
  return isCondensed;
};

/** Returns true unless the user has explicitly set the condensed setting to false.*/
export const getIsCondensed = (): boolean => {
  return localStorage.getItem("search-is-condensed") !== "false";
};

export const setSortBy = (sortBy: SortBy) => {
  localStorage.setItem("search-sort-by", sortBy);
  return sortBy;
};

export const getSortBy = (): SortBy => {
  const value = localStorage.getItem("search-sort-by");
  return isSortBy(value) ? value : sortByDefault;
};
const isSortBy = (value: any): value is SortBy => Object.values(SortBy).includes(value);
