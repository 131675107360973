import { useEffect } from "react";
import { RoutesOption } from "../../routes";
import { useSearchQuery } from "../../search/useSearchQuery";

export const useHtmlTitle = () => {
  const query = useSearchQuery();
  useEffect(() => {
    switch (window.location.pathname) {
      case RoutesOption.Home: {
        document.title = query.isAll ? "Ideaflow" : "Search Results | Ideaflow";
        break;
      }
      default:
        document.title = "Ideaflow";
    }
    return () => {
      document.title = "Ideaflow";
    };
  }, [query]);
};
