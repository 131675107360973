import { Auth } from "../../auth/auth";
import { SyncStatsUpdate } from "../../model/sync/SyncStats";
import { Folder, FolderId, Note, NoteId, UserSettings } from "../../../shared/types";

// SW to CLI

export enum SWToClientMessageType {
  UPSERT = "upsert",
  UPSERT_ACKNOWLEDGE = "upsert-acknowledge",
  ERROR = "error",
  SYNC_STATS = "sync-stats",
  REQUEST_ACCESS = "request-access-token",
  LOAD_RESPONSE = "load-response",
  ACKNOWLEDGE = "ack",
}

type UpsertToClientMessage = {
  type: SWToClientMessageType.UPSERT;
  modelVersion: string;
  notes?: (Partial<Note> & { id: string })[];
  folders?: (Partial<Folder> & { id: string })[];
  userSettings?: UserSettings;
};

type UpsertAcknowledgeMessage = {
  type: SWToClientMessageType.UPSERT_ACKNOWLEDGE;
  noteIds?: NoteId[];
  folderIds?: FolderId[];
  userSettings?: UserSettings;
  transactionId: number;
  modelVersion: string;
};

type SyncStatsMessage = {
  type: SWToClientMessageType.SYNC_STATS;
  syncStats: SyncStatsUpdate;
  modelVersion: string;
};

type RequestAccessTokenMessage = {
  type: SWToClientMessageType.REQUEST_ACCESS;
};

type LoadResponseMessage = {
  type: SWToClientMessageType.LOAD_RESPONSE;
  notes: Note[];
  folders: Folder[];
  userSettings: UserSettings;
  modelVersion: string;
};

type AckMessage = {
  type: SWToClientMessageType.ACKNOWLEDGE;
  ackId: string;
  ok?: boolean;
};

type ErrorMessage = {
  type: SWToClientMessageType.ERROR;
  message: string;
};

export type SWToClientMessage =
  | UpsertToClientMessage
  | UpsertAcknowledgeMessage
  | SyncStatsMessage
  | RequestAccessTokenMessage
  | LoadResponseMessage
  | AckMessage
  | ErrorMessage;

// CLI to SW

export enum ClientToSWMessageType {
  SYNC = "sync",
  DEBUG = "debug",
  LOAD = "load",
  AUTH = "auth",
  DELETE_ACCOUNT = "delete-account",
  CLAIM_CLIENTS = "claim-clients",
  ERROR_TEST = "error-test",
  RESET = "reset",
  UPSERT = "upsert",
  SPAM_LONG = "spam-long",
  SPAM_MANY = "spam-many",
}

// Ask the server to get all new updates from the server
// then persist all pending updates
// typically done at load time, when the connection goes back online
// it is automatically done after an upsert or delete
type SyncMessage = {
  type: ClientToSWMessageType.SYNC;
  modelVersion: string;
};

type DebugMessage = {
  type: ClientToSWMessageType.DEBUG;
  namespaces: string;
};

type LoadMessage = {
  type: ClientToSWMessageType.LOAD;
  modelVersion: string;
};

// Tells the sw to load the current user and access token from the cache
// in order to make the API calls on behalf of a particular user and go through authentication
type AuthStateMessage = {
  type: ClientToSWMessageType.AUTH;
  auth: Auth;
  // The id the SW needs to respond with to tell CLI the message was handled
  ackId?: string;
};

type DeleteAccountMessage = {
  type: ClientToSWMessageType.DELETE_ACCOUNT;
  ackId: string;
};

type ClaimClientsMessage = {
  type: ClientToSWMessageType.CLAIM_CLIENTS;
};

type ErrorTestMessage = {
  type: ClientToSWMessageType.ERROR_TEST;
  error: string;
};

type ResetSwMessage = {
  type: ClientToSWMessageType.RESET;
};

type UpsertToSWMessage = {
  type: ClientToSWMessageType.UPSERT;
  notes?: Note[];
  folders?: Folder[];
  userSettings?: UserSettings;
  modelVersion: string;
  transactionId: number;
};

type SpamLongToSWMessage = {
  type: ClientToSWMessageType.SPAM_LONG;
  modelVersion: string;
};

type SpamManyToSWMessage = {
  type: ClientToSWMessageType.SPAM_MANY;
  modelVersion: string;
  total: number;
  current: number;
};

export type ClientToSWMessage =
  | SyncMessage
  | DebugMessage
  | LoadMessage
  | AuthStateMessage
  | DeleteAccountMessage
  | ClaimClientsMessage
  | ErrorTestMessage
  | ResetSwMessage
  | UpsertToSWMessage
  | SpamLongToSWMessage
  | SpamManyToSWMessage;
