import { Fragment, Node } from "prosemirror-model";
import { Transaction } from "prosemirror-state";
import { schema } from "../../schema";
import getNode from "../../utils/getNode";

/**
 * @param tr The transaction to update
 * @param pos The position of the note
 * @param content The content to append
 */
export function appendToNote(tr: Transaction, pos: number, content: Fragment | Node[] | Node): number {
  const noteNode = getNode(tr.doc, pos);
  let insertPos = pos + noteNode.nodeSize - 1;
  const lastChild = noteNode.lastChild;
  if (lastChild?.type === schema.nodes.backlinks) {
    insertPos -= lastChild.nodeSize;
  }
  tr.insert(insertPos, content);
  return insertPos;
}
