import appLogger from "../../../utils/logger";
import {
  ChunkMetadata,
  EncoderWorkerEventMessage,
  isAllChunksSavedMessage,
  isChunksSavedLocallyMessage,
} from "./audioTypes";
import { encoderWorkerURL, audioWorkletURL } from "./setupAudioWorkers";
import { audioLogsPrefix } from "./utils";

const logger = appLogger.with({ namespace: `${audioLogsPrefix}/mp3-recording` });

function waitForMessage(worker: Worker | MessagePort, predicate: (msg: MessageEvent<any>) => boolean) {
  return new Promise<MessageEvent>((res) => {
    const handler = (e: MessageEvent) => {
      if (predicate(e)) {
        worker.removeEventListener("message", handler as any);
        res(e);
      }
    };
    worker.addEventListener("message", handler as any);
  });
}

export async function startMP3Recording(
  audioId: string,
  maxChunkSizeInBytes: number | null,
  onInterrupt: () => void,
  onChunkReady: (chunk: ChunkMetadata) => void,
) {
  let sampleRate = 44100;
  const ctx = new AudioContext({ sampleRate, latencyHint: "interactive" });
  await ctx.resume();
  sampleRate = ctx.sampleRate;

  ctx.onstatechange = (e) => {
    logger.info("ctx.onstatechange", { context: { state: ctx.state } });
    // "interrupted" is a special off-spec value Safari provides when a call or another recording interrupts this recording
    // See: https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/state#resuming_interrupted_play_states_in_ios_safari
    if (ctx.state === ("interrupted" as any)) {
      onInterrupt();
    }
  };

  const channel = new MessageChannel();
  const encoderWorker = new Worker(encoderWorkerURL);
  await waitForMessage(encoderWorker, (e) => e.data.type === "ready");
  encoderWorker.postMessage(
    {
      type: "audioPort",
      audioPort: channel.port1,
      audioId,
      sampleRate,
      maxChunkSizeInBytes,
    },
    [channel.port1],
  );
  await waitForMessage(encoderWorker, (e) => e.data.type === "ready-port");

  await ctx.audioWorklet.addModule(audioWorkletURL);

  const micStream = await navigator.mediaDevices.getUserMedia({
    audio: {
      sampleRate,
      // iOS WARNING: THE FOLLOWING THREE PROPERTIES MUST BE SET TO `true` OR LEFT MISSING
      // OTHERWISE WE DON'T GET THE AUTO GAIN ON THE AUDIO AND IT'S VERY QUIET
      echoCancellation: true,
      autoGainControl: true,
      noiseSuppression: true,
      channelCount: 1,
    },
  });
  const micSourceNode = ctx.createMediaStreamSource(micStream);

  const destination = ctx.createMediaStreamDestination();

  const forwardToEncoderNode = new AudioWorkletNode(ctx, "forward-to-encoder-worklet");
  micSourceNode.connect(forwardToEncoderNode).connect(destination);

  forwardToEncoderNode.port.postMessage({ type: "audioPort", audioPort: channel.port2 }, [channel.port2]);

  encoderWorker.addEventListener("message", (e) => {
    if (isChunksSavedLocallyMessage(e.data)) {
      logger.info("Received chunk saved locally", { context: e.data.data });
      onChunkReady(e.data.data);
    }
  });

  return {
    ctx,
    stream: micStream,
    /**
     * Stops the recording and resolves once all the chunks have been
     * encoded and saved.
     */
    async stop() {
      logger.info("Stopping recording");
      const msg: EncoderWorkerEventMessage = { type: "stop" };
      encoderWorker.postMessage(msg);
      await waitForMessage(encoderWorker, (e) => isAllChunksSavedMessage(e.data) && e.data.audioId === audioId);
      micStream.getTracks().forEach((t) => t.stop());
      ctx.close();
    },
    stopAbruptly() {
      logger.info("Stopping recording abruptly");
      const msg: EncoderWorkerEventMessage = { type: "stop-abruptly" };
      encoderWorker.postMessage(msg);
    },
  };
}
