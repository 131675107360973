import { atom, getDefaultStore, useAtomValue } from "jotai";
import Router from "next/router";
import { useEffect } from "react";
import debounce from "lodash.debounce";
import logger from "../utils/logger";
import { isFrontend } from "../utils/environment";
import { isHistoryState } from "../utils/isHistoryState";
import { notesCreatedSinceLastQueryUpdateAtom } from "../model/atoms";
import { toParsedUrl, searchQueryFromUrlOrSearchString, addDefaultsToSearchQuery } from "./SearchQuery";

import type { SearchQuery } from "./SearchQuery";

const searchQueryAtom = atom<SearchQuery>(
  isFrontend
    ? {
        ...searchQueryFromUrlOrSearchString(window.location.search),
        source: "init",
      }
    : { source: "init" },
);

// In some situations `searchQueryAtom` needs to be synced back to the actual window.location
// For example this happens when the /new page redirects back to /?noteIdList=XYZ
export function reinitializeSearchQueryAtom() {
  getDefaultStore().set(searchQueryAtom, {
    ...searchQueryFromUrlOrSearchString(window.location.search),
    source: "init",
  });
}

export function updateSearchQuery(update: SearchQuery | ((prev: SearchQuery) => SearchQuery)) {
  const oldQuery = getDefaultStore().get(searchQueryAtom);
  const newQuery = update instanceof Function ? update(oldQuery) : update;
  addDefaultsToSearchQuery(newQuery);
  getDefaultStore().set(searchQueryAtom, newQuery);
  getDefaultStore().set(notesCreatedSinceLastQueryUpdateAtom, []);
}

export function useSearchQuery() {
  return useAtomValue(searchQueryAtom);
}

export function getSearchQuery() {
  return getDefaultStore().get(searchQueryAtom);
}

/**
 * Keep the search query in sync with the URL.
 * This should be called once at the root of the app.
 */
export function useSyncSearchQueryAndUrl() {
  const searchQuery = useAtomValue(searchQueryAtom);

  // Update the search query when we navigate using the browser back/forward buttons
  useEffect(() => {
    const onPopState = (pop: PopStateEvent) => {
      const state = pop.state;
      if (!isHistoryState(state)) {
        logger.error("popstate event received with invalid history state", { context: { state } });
        return;
      }
      try {
        updateSearchQuery({ ...searchQueryFromUrlOrSearchString(state.url || ""), source: "popstate" });
      } catch (e) {
        logger.error("Failed to parse URL query", {
          context: { state },
          error: e,
        });
      }
    };
    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  // Update the URL whenever the app updates the search query
  useEffect(() => {
    switch (searchQuery.source) {
      case "searchBar":
        debouncedRouterPush(searchQuery);
        return;
      case "other":
        debouncedRouterPush.cancel();
        Router.push({ query: toParsedUrl(searchQuery) });
        return;
      case "popstate":
      case "init":
      case undefined:
        debouncedRouterPush.cancel();
        return;
      default:
        return searchQuery.source satisfies never;
    }
  }, [searchQuery]);
}

// Helpers to push the search query to the URL
const routerPush = (q: SearchQuery) => Router.push({ query: toParsedUrl(q) });
const debouncedRouterPush = debounce(routerPush, 1000);
