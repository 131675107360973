import { Plugin } from "prosemirror-state";
import { isAndroid } from "../../../utils/environment";

/**
 * On Android, for each KeyDown, we get events with `key` as `Unidentified` but when `Enter` is
 * pressed ProseMirror detects this and dispatches another KeyDown event with the correct
 * `key` property, `Enter`. However this is insufficient, apparently this event has a
 * empty `code` property and hence does not trigger commands attached to the keymaps.
 * This plugin listens for this "incomplete" `Enter` KeyDown event, and dispatches a new event
 * with the `code` property set as `Enter`.
 */
export const mobileEventHandlerPlugin = new Plugin({
  props: {
    handleDOMEvents: {
      keydown(view, event) {
        if (!isAndroid) return false;
        if (event.key === "Enter" && event.code.length === 0) {
          event.preventDefault();
          event.stopPropagation();
          view.dispatchEvent(
            new KeyboardEvent("keydown", {
              repeat: event.repeat,
              altKey: event.altKey,
              shiftKey: event.shiftKey,
              composed: event.composed,
              ctrlKey: event.ctrlKey,
              key: "Enter",
              code: "Enter",
            }),
          );
        }
      },
    },
  },
});
