import { useCallback } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { Node } from "prosemirror-model";
import {
  isSidebarOpenAtom,
  editorViewAtom,
  userSettingsAtom,
  wasAtLeastOneNoteAddedAtom,
  notesCreatedSinceLastQueryUpdateAtom,
} from "../../model/atoms";
import { updateSearchQuery, useSearchQuery } from "../../search/useSearchQuery";
import { mediumWidth } from "../../utils/style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { appFolderStore, appNoteStore } from "../../model/services";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { noteToProsemirrorNode } from "../../editor/bridge";
import { useNotifySidebarUpdate } from "../../sidebar/atoms/sidebarUpdate";
import { generateId } from "../../model/generateId";
import { TopLevelToken } from "../../../shared/types";
import { findSelectionAtEndOfNotesFirstLine } from "../../editor/utils/find";
import { upsertUserSettings } from "../../model/userSettings";
import { addToast } from "../../components/Toast";
import appLogger from "../../utils/logger";
import { NoteInsert } from "../../model/store/NoteStore";
import { formatInsertedAt } from "../utils/insertedAt";
import { TOP_SCROLL, saveLastEditorScroll } from "../../model/editorScroll";
import { getEditorPosition } from "../App";
import { closeNoteMenu } from "../../components/PublicNoteMenu";

const logger = appLogger.with({ namespace: "useCreateNote" });

/**
 * A custom hook that returns a callback function for creating a note.
 *
 * @returns A callback function that accepts an object with the following
 * properties:
 * @property text - The text to insert into the note. Defaults to an empty
 * string if not provided.
 * @property inContext - By default, the note is created in the context of the
 * current search query. Set this to false to create a note without context.
 */
const useCreateNote = () => {
  const searchQuery = useSearchQuery();
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);
  const { width } = useWindowDimensions();
  const updateSidebar = useNotifySidebarUpdate();
  const userSettings = useAtomValue(userSettingsAtom);
  const setWasAtLeastOneNoteAdded = useSetAtom(wasAtLeastOneNoteAddedAtom);

  const callback = useCallback(
    ({ content = "", inContext = true }: { content?: string | TopLevelToken[]; inContext?: boolean }) => {
      if (width < mediumWidth) setShowSidebar(false);

      // Save current scroll position and create a new history state with the
      // top position. This allows the user to go back to the previous scroll
      // position when they press the back button.
      saveLastEditorScroll(getEditorPosition());
      const newState = { ...window.history.state, key: generateId() };
      window.history.pushState(newState, "");
      saveLastEditorScroll(TOP_SCROLL);

      const topLevelBlockTokens: TopLevelToken[] =
        typeof content === "string"
          ? content.split("\n").map((line) => ({
              type: "paragraph",
              tokenId: generateId(),
              content: [{ type: "text", content: line, marks: [] }],
            }))
          : content;

      const noteInsert: NoteInsert = {};
      if (inContext) {
        // While in a hashtag search, prepend the hashtag to the note.
        // TODO: Parhaps we should prepend the hashtags to the audio inserts too?
        if (searchQuery.hashtagsList?.length && topLevelBlockTokens[0].type === "paragraph") {
          topLevelBlockTokens[0].content.unshift(
            ...searchQuery.hashtagsList.flatMap((hashtag: string) => [
              {
                type: "hashtag" as const,
                content: hashtag,
              },
              { type: "text" as const, content: " ", marks: [] },
            ]),
          );
        }
        // While in a public note search, show a toast message
        // That lets the user know the new note is not public until they share it.
        if (searchQuery.isPublic) {
          addToast('New notes created in "Publicly Shared Notes" remain private until shared by you!');
        }
        if (searchQuery.date) {
          noteInsert.insertedAt = formatInsertedAt(searchQuery.date);
        }
        if (searchQuery.folderId && appFolderStore.has(searchQuery.folderId)) {
          noteInsert.folderId = searchQuery.folderId;
        }
        if ((searchQuery.hasIncomplete || searchQuery.hasTodo) && topLevelBlockTokens[0].type === "paragraph") {
          if (!userSettings.seenToDoToast) {
            addToast("Start a new to-do list by typing []");
            upsertUserSettings({ seenToDoToast: true });
          }
          topLevelBlockTokens[0].content.unshift({ type: "checkbox", isChecked: false });
        }
      }

      noteInsert.tokens = topLevelBlockTokens;
      const [note] = appNoteStore.insert(noteInsert);
      setWasAtLeastOneNoteAdded(true);
      updateSidebar();
      return note;
    },
    [setShowSidebar, searchQuery, width, setWasAtLeastOneNoteAdded, updateSidebar, userSettings],
  );

  return callback;
};

/**
 * Returns the selection that should be set after creating a new note at the
 * top, which is the end of the first line of the note.
 */
export function selectionAfterCreateNote(doc: Node) {
  return findSelectionAtEndOfNotesFirstLine(doc, 0);
}

export const useCreateNoteAtTop = () => {
  closeNoteMenu();
  const editorView = useAtomValue(editorViewAtom);
  const createNote = useCreateNote();
  const setNotesCreatedSinceLastQueryUpdate = useSetAtom(notesCreatedSinceLastQueryUpdateAtom);
  return useCallback(
    (content: string | TopLevelToken[] = "") => {
      if (!editorView) {
        logger.warn(
          "Cannot create a note, the editor is not yet loaded, use useCreateNoteAtTopAsSoonAsPossible if you need to queue a note for insertion at the top",
        );
        return false;
      }

      // Create note and insert it at the top of the doc
      const note = createNote({ content });
      const pos = 0;
      const tr = editorView.state.tr.insert(pos, noteToProsemirrorNode(note));

      // Put the cursor at the end of the first line of the note
      const sel = selectionAfterCreateNote(tr.doc);
      if (sel) tr.setSelection(sel);
      tr.scrollIntoView();

      setNotesCreatedSinceLastQueryUpdate((prev) => [...prev, note.id]);
      trackEvent(note.folderId ? "create_note_from_folder" : "create_note", note.id);
      editorView.dispatch(tr);
      editorView.focus();

      return true;
    },
    [editorView, createNote, setNotesCreatedSinceLastQueryUpdate],
  );
};

export function useCreateNoteAsPage() {
  const createNote = useCreateNote();
  return useCallback(
    (content: string | TopLevelToken[] = "") => {
      const note = createNote({ content, inContext: false });
      updateSearchQuery({ noteIdList: [note.id] });
      trackEvent("create_note_as_page", note.id);
    },
    [createNote],
  );
}
