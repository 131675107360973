import { MarkSpec } from "prosemirror-model";

export const hashtag: MarkSpec = {
  group: "inline",
  // for the real render on screen, check the view
  // the toDom is still used for copying things out.
  toDOM: () => ["span", { class: "hashtag" }, 0],
  parseDOM: [{ tag: "span.hashtag" }],
  excludes: "italic bold underline strikethrough",
};
