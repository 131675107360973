import { NodeSpec } from "prosemirror-model";

export const image: NodeSpec = {
  group: "inline",
  inline: true,
  atom: true,
  // Remember to update inlineTokenToProseMirrorNode and inlineNodeToInlineToken functions
  // as well as the decompressInline and compressInline functions
  // when adding new attributes to an existing node type
  attrs: {
    src: { default: "" },
    width: { default: null },
    naturalWidth: { default: null },
    naturalHeight: { default: null },
    placeholder: { default: null },
    smallPreviewDataURL: { default: null },
    id: { default: null },
  },
  toDOM(node) {
    // see the node view for the actual DOM
    // to dom is used when copying things out.
    return [
      "img",
      {
        class: "img",
        src: node.attrs.src,
        id: node.attrs.id,
      },
    ];
  },
  parseDOM: [
    {
      tag: "img",
      getAttrs: (element: Node | string) => {
        if (typeof element === "string" || !(element instanceof HTMLImageElement)) return {};
        const src = element.getAttribute("src");
        const id = element.getAttribute("id");
        const widthAttr = element.getAttribute("width");
        // Prefer the style extracted width over the attribute
        const width = element.style.width || (widthAttr ? widthAttr + "px" : null);
        return { src, id, width };
      },
    },
  ],
};
