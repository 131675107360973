/* eslint-disable */

import { Slice } from "prosemirror-model";
import { EditorView } from "prosemirror-view";

// This following functions come from unexposed prosemirror code: https://github.com/ProseMirror/prosemirror-view/blob/ef10a89919a10e1a2edcbdc39a5f35c294b00d93/src/input.ts#L593

function sliceSingleNode(slice: Slice) {
  return slice.openStart == 0 && slice.openEnd == 0 && slice.content.childCount == 1 ? slice.content.firstChild : null;
}

/**
 * This function is the default handler prosemirror uses to paste a Slice.
 * We need it, because we sometimes want to dispatch some transaction right after a normal paste
 * event has happened (it's kind of a afterPaste handler, see our handlePaste function)
 */
export function normalProsemirrorPasteHandler(view: EditorView, slice?: Slice) {
  if (!slice) return false;
  let singleNode = sliceSingleNode(slice);
  const tr = singleNode
    ? view.state.tr.replaceSelectionWith(singleNode, (view as any).input.shiftKey)
    : view.state.tr.replaceSelection(slice);
  view.dispatch(tr.scrollIntoView().setMeta("paste", true).setMeta("uiEvent", "paste"));
}
