import { useEffect, useRef, useState } from "react";

type AudioActivityVisualizationProps = {
  volumes: number[];
  numberOfSamplesOnScreen?: number;
};

export function AudioActivityVisualization({ volumes, numberOfSamplesOnScreen = 60 }: AudioActivityVisualizationProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const [{ width, height }, setSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const ro = new ResizeObserver((sizeEntries) => {
      for (const size of sizeEntries) {
        setSize({
          width: size.contentRect.width,
          height: size.contentRect.height,
        });
      }
    });
    ro.observe(divRef.current!);
    return () => ro.disconnect();
  }, []);

  if (width < 100) {
    numberOfSamplesOnScreen = Math.floor(numberOfSamplesOnScreen / 3);
  }

  const slicedVolumes = volumes.slice(-numberOfSamplesOnScreen);
  const minVolume = Math.min(...slicedVolumes);
  const maxVolume = Math.max(...slicedVolumes);
  const normalizedVolumes = [
    ...new Array(numberOfSamplesOnScreen - slicedVolumes.length).fill(0),
    ...slicedVolumes.map((v) => (v - minVolume) / Math.max(0.0001, maxVolume - minVolume)),
  ];
  const offsetX = width / numberOfSamplesOnScreen;

  return (
    <div
      style={{
        flexGrow: 1,
        alignSelf: "stretch",
        margin: "0 10px",
        position: "relative",
      }}
      ref={divRef}
    >
      {normalizedVolumes.map((v, i) => (
        <div
          key={i}
          style={{
            position: "absolute",
            top: height / 2,
            left: 0,
            width: offsetX * 0.7,
            height: 5 + height * 0.8 * v,
            borderRadius: 5,
            transform: `translateY(-50%) translateX(${width + (i - numberOfSamplesOnScreen) * offsetX}px)`,
            backgroundColor: `var(--audio-vizualizer-bar)`,
          }}
        />
      ))}
    </div>
  );
}
