import { NodeSpec } from "prosemirror-model";
import { generateId } from "../../../model/generateId";
import { domAttrs, getDefaultAttrs } from "../../utils/attrs";

export const paragraph: NodeSpec = {
  content: "inline*",
  group: "topLevelNode blockNode",
  draggable: false,
  selectable: false,
  attrs: {
    tokenId: { default: "" },
    depth: { default: 0 },
  },
  parseDOM: [
    {
      tag: "p",
      getAttrs: getDefaultAttrs([{ key: "depth", type: "int" }]),
    },
    {
      tag: "div",
      // When the browser and jsdom support for the :not and :has pseudoclasses are better we can just use the following
      // instead of the getAttrs filtering below
      // tag: "div:not(:has(li))",
      getAttrs(node) {
        if (typeof node === "string") return null;
        // Fail this parsing rule explicitly if the div contains a bullet list, those cannot be a part of a paragraph
        if (node.querySelector("li")) return false;
        return null;
      },
    },
    // we don't want to use all brs/spans since they can be used in different contexts
    //
    // The first context is after a span, which in practice is used as a block
    // element (how a <div> would be used), so rather than convert the span
    // to a new element, we only convert the linebreak to an empty paragraph
    { tag: "span+br" },
    // The second context is before a span, which in practice is used as a real
    // linebreak, so rather than convert br, we create a new paragraph with the
    // content of the span
    { tag: "br+span" },
    // The third is after a div, which in practice is used as a real linebreak
    // so we create a new paragraph with no content
    { tag: "div+br" },
  ],
  toDOM: (node) => [
    "p",
    {
      ...domAttrs(node, { tokenId: generateId(), depth: 0 }),
      class: "paragraph",
      spellcheck: "true",
      style: `
        margin-left: ${(node.attrs.depth || 0) * 24}px;
        `,
    },
    0,
  ],
};
