import { FetchWrapper } from "../fetchWrapper";

export const LinkApiResource = (client: FetchWrapper) => {
  return {
    /**
     * Returns the details of an URL.
     * @param url A valid url with or without HTTP protocol.
     */
    unfurl: async (url: string): Promise<{ title: string }> =>
      client.post("/links/unfurl", {
        url,
      }),
  };
};
