import React from "react";

import { Plugin, PluginKey, Selection } from "prosemirror-state";
import { NodeType } from "prosemirror-model";
import { DecorationSet, Decoration, EditorView } from "prosemirror-view";

import { getDefaultStore } from "jotai";
import { getParentNote } from "../../utils/find";
import { schema } from "../../schema";
import { fixedRemoveMark } from "../../utils/fixedRemoveMark";
import { userSettingsAtom } from "../../../model/atoms";
import { autocompleteStateAtom } from "./useAutocompleteState";
import { AutocompleteModule, autocompleteModules } from "./modules";

interface State {
  match: { from: number; to: number } | null;
  canceledAt: number | null;
  matcherName: string | null;
}

export interface FullMatch {
  from: number;
  to: number;
  text: string;
}

export const AUTOCOMPLETE_DECORATION_CLASS = "prosemirror-suggestion";

function isSelectionInMatchRange(selection: Selection, match: { from: number; to: number }) {
  return !(selection.from < match.from) && !(selection.from > match.to + 1);
}

/**
 * pass in a whitelist of valid nodes we should show the plugin on
 */
export const autocompletePlugin = (
  validNodeTypes: NodeType[],
  autocompleteKeyDownRef?: React.RefObject<((event: KeyboardEvent) => boolean) | null>,
) => {
  const plugin: Plugin<State> = new Plugin<State>({
    key: new PluginKey("autocomplete"),
    state: {
      init(): State {
        return {
          match: null,
          canceledAt: null,
          matcherName: null,
        };
      },
      apply(tr, oldPluginState, oldState): State {
        // see https://discuss.prosemirror.net/t/3420
        const newPluginState = tr.getMeta(plugin);
        const pluginState: State = { ...oldPluginState, ...newPluginState };

        if (
          tr.getMeta("type") === "mobileBarHashtagInsert" &&
          oldPluginState.match &&
          getDefaultStore().get(autocompleteStateAtom)?.matcherName !== "hashtag"
        ) {
          // If the user pressed the "#" button in the mobile bar, we only want to break
          // existing autocomplete progress if the user was typing another hashtag, (in which case
          // hittin # will start a new one).
          // To preserve that progress, we just update the "to" position of the match to include the newly
          // inserted character and then return without changing anything else.
          // This matches the behavior of typing in a "#" character from a regular keyboard.
          pluginState.match = oldPluginState.match;
          pluginState.match.to = tr.selection.$to.pos;
          return pluginState;
        }

        // Deactivate the autocomplete when there is a selection, to prevent
        // flickering as you drag across a # sign (for example) while selecting text.
        if (tr.selection.from !== tr.selection.to) {
          return { match: null, canceledAt: null, matcherName: null };
        }

        // If the user puts the cursor before or after the match, cancel that match
        // if they click anywhere after the prevMatch, close the menu
        const caretExitedPreviousMatch = pluginState.match && !isSelectionInMatchRange(tr.selection, pluginState.match);

        // only find a new match if the doc has been updated
        // or the caret exited the previously selected match
        // or the transaction explicitly asks the autocompletePlugin to search for the match
        // The last condition is important for when an editor gets recreated (see https://linear.app/ideaflow/issue/ENT-1737)
        const shouldLookForNewMatch =
          tr.docChanged || caretExitedPreviousMatch || tr.getMeta("reapplyAutocompletePlugin");
        if (!shouldLookForNewMatch) return pluginState;

        if (pluginState.canceledAt != null) {
          pluginState.canceledAt = tr.mapping.map(pluginState.canceledAt);
        }

        const $caretPos = tr.selection.$from;

        // only run the plugin on a whitelist of nodes
        if (!validNodeTypes.includes($caretPos.parent.type)) return pluginState;

        // Check each module to see if there is a match
        let match: FullMatch | null = null;
        let autocompleteMod: AutocompleteModule | null = null;
        for (const mod of Object.values(autocompleteModules)) {
          const { smartAutocompleteEnabled } = getDefaultStore().get(userSettingsAtom);
          if (!smartAutocompleteEnabled && mod.isSmart) continue;
          const newMatch = mod.getMatch($caretPos);
          if (newMatch && (!pluginState.matcherName || pluginState.matcherName === mod.matcherName)) {
            match = newMatch;
            autocompleteMod = mod;
            pluginState.matcherName = mod.matcherName;
          }
        }

        if (!match || !autocompleteMod) {
          // Preserve the canceledAt to avoid rematching the same string after
          // user adds a new line and then deletes it
          return { ...pluginState, match: null, matcherName: null };
        }

        // If the found match is explicitly rejected by the user, treat it as no match
        if (match.from === pluginState.canceledAt) {
          return { ...pluginState, match: null, matcherName: null };
        }

        // if the brand new match does not contain the cursor reject it
        if (!isSelectionInMatchRange(tr.selection, match)) {
          return { ...pluginState, match: null, matcherName: null };
        }

        // When the pastes, we don't want to prevent the autocomplete from
        // showing even if it contains a match. We prevent that by checking if
        // the match is within the pasted range, and canceling it.
        if (tr.getMeta("paste")) {
          const pasteFrom = oldState.selection.from;
          const pasteTo = tr.selection.to;
          if (match.from >= pasteFrom && match.to === pasteTo) {
            return { canceledAt: match.from, match: null, matcherName: null };
          }
        }

        const $matchFrom = $caretPos.doc.resolve(match.from);
        const [parentNote] = getParentNote(tr.doc, $matchFrom.pos);
        const noteId = parentNote?.attrs.noteId;

        if (!noteId) throw new Error("missing parent noteId");

        getDefaultStore().set(autocompleteStateAtom, (s) => {
          if (!autocompleteMod || !match) return s;
          return {
            ...(s || { cancel() {} }),
            noteId,
            matcherName: autocompleteMod.matcherName,
            match: { to: match.to, from: match.from },
            text: match.text,
          };
        });

        return {
          ...pluginState,
          canceledAt: null,
          match: { to: match.to, from: match.from },
        };
      },
    },
    appendTransaction(trs, oldState, newState) {
      const oldPluginState = plugin.getState(oldState)!;
      const newPluginState = plugin.getState(newState)!;

      if (!newPluginState.match && !oldPluginState.match) return null;

      const tr = newState.tr;
      tr.setMeta("noChangeToModel", true);
      if (oldPluginState.match) {
        // Remove the autocompleteRegion mark from old matches
        const oldFrom = trs.reduce((pos, tr) => tr.mapping.map(pos, -1), oldPluginState.match.from);
        const oldTo = trs.reduce((pos, tr) => tr.mapping.map(pos, +1), oldPluginState.match.to);
        if (newPluginState.match) {
          // If the old and new match ranges are the same do nothing
          const { from: newFrom, to: newTo } = newPluginState.match;
          if (oldFrom === newFrom && oldTo === newTo) return null;
        }

        // For some reason prosemirror sometimes cannot preserve a selection when a mark is removed.
        // without this we lose selecting after cmd+a shortcut (see https://linear.app/ideaflow/issue/ENT-1738)
        fixedRemoveMark(tr, oldFrom, oldTo, schema.marks.autocompleteRegion);
      }

      if (newPluginState.match) {
        const { from: newFrom, to: newTo } = newPluginState.match;
        tr.removeMark(newFrom, newTo, schema.marks.highlight);
        tr.addMark(
          newFrom,
          newTo,
          schema.marks.autocompleteRegion.create({
            type: getDefaultStore().get(autocompleteStateAtom)?.matcherName,
          }),
        );
      }
      return tr;
    },
    props: {
      // to decorate the currently active entity text in ui
      decorations(editorState) {
        const { match, canceledAt } = (this as Plugin).getState(editorState);
        if (!match || match.from === canceledAt) return null;

        return DecorationSet.create(editorState.doc, [
          Decoration.inline(match.from, match.to, {
            nodeName: "span",
            class: AUTOCOMPLETE_DECORATION_CLASS,
          }),
        ]);
      },
      // The actual handling of the keydown event is delegated to the
      // function passed from the EditorSuggestionList component through autocompleteKeyDownRef
      handleKeyDown(_, event) {
        return autocompleteKeyDownRef?.current?.(event) ?? false;
      },
    },
    view(view: EditorView) {
      // This function is created only once to avoid messing with the setAutocompleteState shallow optimization
      function cancel() {
        const { match } = plugin.getState(view.state)!;
        // updated the plugin state https://discuss.prosemirror.net/t/3420
        view.updateState(
          view.state.apply(
            view.state.tr.setMeta(plugin, {
              // Match needs to be reset here, since after just pressing Escape, we have tr.docChanged === false
              // So the apply logic above will not be looking for a new match
              match: null,
              canceledAt: match?.from,
            }),
          ),
        );
      }
      return {
        update(view) {
          const { match, canceledAt } = plugin.getState(view.state)!;

          getDefaultStore().set(autocompleteStateAtom, (state) =>
            match && state
              ? {
                  ...state,
                  match,
                  canceledAt,
                  cancel,
                }
              : null,
          );
        },
        destroy: () => {
          getDefaultStore().set(autocompleteStateAtom, () => null);
        },
      };
    },
  });

  return plugin;
};
