import { useRef, useState } from "react";
import { Background } from "../../../editorPage/noteMenu/NoteMenu";
import { zIndexes } from "../../../utils/style";

type ButtonConfig = { text: string; onClick(): void | Promise<void> };

export function AudioMenu({ items, alignLeft }: { items: ButtonConfig[]; alignLeft: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      {isOpen && (
        <Background
          onClick={() => setIsOpen(false)}
          // Prevent clicks on buttons from selecting the audio insert node
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}

      <div
        style={{
          boxShadow: isOpen ? "var(--shadow-light)" : undefined,
          position: "relative",
        }}
      >
        <button
          ref={buttonRef}
          className={"audio-menu-button" + (isOpen ? " active" : "")}
          onClick={() => setIsOpen((o) => !o)}
        >
          •••
        </button>
        {isOpen && (
          <div
            className="menu-expanded"
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: "24px",
              zIndex: zIndexes.audioMenu + 1,
              ...(alignLeft ? { left: 0 } : { right: 0 }),
            }}
          >
            {items.map((button, i) => (
              <SimpleHamburgerMenuButton key={i} button={button} closeMenu={() => setIsOpen(false)} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

function SimpleHamburgerMenuButton({ button, closeMenu }: { button: ButtonConfig; closeMenu(): void }) {
  const [isProcessing, setIsProcessing] = useState(false);
  return (
    <button
      className="menu-item"
      onClick={async () => {
        const res = button.onClick();
        if (res instanceof Promise) {
          setIsProcessing(true);
          try {
            await res;
          } finally {
            setIsProcessing(false);
          }
        }
        closeMenu();
      }}
      disabled={isProcessing}
    >
      {isProcessing ? "Please wait..." : button.text}
    </button>
  );
}
