import { Command, TextSelection } from "prosemirror-state";
import { schema } from "../../schema";

export const selectReferenceOnBackspaceCommand: Command = (state, dispatch) => {
  if (dispatch == null) return false;
  if (!state.selection.empty) return false;
  const from = state.selection.from;
  const left = state.doc.nodeAt(from - 1);
  if (!left || left.type !== schema.nodes.reference) return false;
  const tr = state.tr;
  tr.setSelection(new TextSelection(tr.doc.resolve(from - left.nodeSize), tr.doc.resolve(from)));
  dispatch(tr);
  return true;
};
