import { SyncStats } from "./SyncStats";

/** Create a sync stats object with all dates converted to Date objects */

export function createSyncStatsWithDatetimes(stats: SyncStats) {
  return {
    ...stats,
    sw: stats.sw
      ? {
          ...stats.sw,
          sampleDate: new Date(stats.sw.sampleDate),
          mostRecentUpdatedAt: new Date(stats.sw.mostRecentUpdatedAt),
          checkpoint: stats.sw.checkpoint ? new Date(stats.sw.checkpoint) : undefined,
        }
      : null,
    server: stats.server
      ? {
          ...stats.server,
          sampleDate: new Date(stats.server.sampleDate),
          mostRecentUpdatedAt: new Date(stats.server.mostRecentUpdatedAt),
        }
      : null,
    client: stats.client
      ? {
          ...stats.client,
          sampleDate: new Date(stats.client.sampleDate),
          mostRecentUpdatedAt: new Date(stats.client.mostRecentUpdatedAt),
        }
      : null,
    lastFullySyncedAt: new Date(stats.lastFullySyncedAt),
    updatedAt: new Date(stats.updatedAt),
  };
}
