import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { isMobileWidth } from "../../utils/environment";
import { isSidebarOpenAtom } from "../../model/atoms";
import { useSearchQuery } from "../../search/useSearchQuery";

export function useCloseSidebarAfterNavOnMobile() {
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);
  const searchQuery = useSearchQuery();
  useEffect(() => {
    if (isMobileWidth) {
      setShowSidebar(false);
    }
  }, [searchQuery, setShowSidebar]);
}
