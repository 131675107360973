import { Path } from "../editor/utils/path";

export enum SELECTION_TYPE {
  EDITOR = "editor",
  SEARCH = "search",
}

export type EditorSelection = {
  type: SELECTION_TYPE.EDITOR;
  fromNotePath: Path | null;
  toNotePath: Path | null;
  fromOffset: number;
  toOffset: number;
};

// This is not used yet and is a placeholder for future search selection
type SearchSelection = {
  type: SELECTION_TYPE.SEARCH;
  fromPos: number;
  toPos: number;
};

type AppSelection = EditorSelection | SearchSelection;

type StoredSelection = { current: AppSelection | null };
const selection: StoredSelection = { current: null };

export function saveSelection(sel: AppSelection | null) {
  selection.current = sel;
}

export function getEditorSelection(): EditorSelection | null {
  if (selection.current?.type === SELECTION_TYPE.EDITOR) {
    return selection.current;
  } else {
    return null;
  }
}
