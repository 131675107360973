import { NoteId } from "../../../shared/types";

/**
 *
 * Tracks which notes contain which "items" and vice versa.
 * Items are entities, hashtags, or anything else with an ID that needs to be indexed.
 *
 * This could be generalized to any many to many relationship between A and B.
 */
export class ManyToManyIndex {
  // maps a given item ID to a set of notes it appears in
  private itemToNotes = new Map<string, Set<NoteId>>();

  // maps a given note ID to a set of item ids that it contains
  private noteToItems = new Map<string, Set<string>>();

  // flag for whether or not the indexing should be case INsensitive

  constructor(private isCaseInsensitive: boolean) {}

  /**
   * Indexes items within a note.
   */
  set(noteId: NoteId, items: string[]): void {
    this.noteToItems.set(noteId, new Set(items)); // no duplicates
    items.forEach((_id) => {
      const id = this.isCaseInsensitive ? toCaseSensitive(_id) : _id;
      if (this.itemToNotes.has(id)) {
        const notes = this.itemToNotes.get(id)!;
        notes.add(noteId);
      } else {
        this.itemToNotes.set(id, new Set([noteId]));
      }
    });
  }

  /**
   * Gets the number of notes in which a given item appears at least once
   */
  getNoteCountForItem = (id: string) => {
    return this.itemToNotes.get(id)?.size ?? 0;
  };

  /**
   * Gets the noteIds of all items in which a given item appears at least once
   */
  getNoteIdsForItem = (id: string) => {
    return [...(this.itemToNotes.get(id) ?? [])];
  };

  /**
   * Gets the ids of all items within a note
   */
  getItemsForNote = (id: NoteId) => {
    return [...(this.noteToItems.get(id) ?? [])];
  };

  /**
   * Clears all items for a given note.
   */
  delete(noteId: NoteId, shouldCheckReferences: boolean): string[] {
    const previousItems = this.noteToItems.get(noteId);
    if (!previousItems) return [];
    this.noteToItems.delete(noteId);

    const itemsNoLongerInDoc: string[] = [];

    previousItems.forEach((itemId) => {
      const id = this.isCaseInsensitive ? toCaseSensitive(itemId) : itemId;
      const noteIds = [...(this.itemToNotes.get(id) ?? [])];

      //
      const otherNotesContainingItem = noteIds.filter((id) => id !== noteId);
      if (otherNotesContainingItem.length === 0) {
        // this item no longer appears anywhere in the document
        itemsNoLongerInDoc.push(id);
        this.itemToNotes.delete(id);
      } else {
        this.itemToNotes.set(id, new Set(otherNotesContainingItem));
      }
    });

    if (shouldCheckReferences) {
      // for references, need to do an extra clearing step
      const entriesForDeletedReference = this.itemToNotes.get(noteId) || [];
      this.itemToNotes.delete(noteId);

      entriesForDeletedReference.forEach((entryId) => {
        // no need for case checking here
        const prevReferenceIds = [...(this.noteToItems.get(entryId) ?? [])];
        const filtered = prevReferenceIds.filter((id) => id !== noteId);
        this.noteToItems.set(entryId, new Set(filtered));
      });
    }

    return itemsNoLongerInDoc;
  }

  clear() {
    this.itemToNotes = new Map();
    this.noteToItems = new Map();
  }

  getAllItems() {
    return [...this.itemToNotes.entries()];
  }
}

/** Maps the lowercase version to case sensitive version. Currently
 * using first found instance (https://linear.app/ideaflow/issue/ENT-368).
 */
const toCaseSensitive = (() => {
  const caseInsToSensitiveStrings = new Map<string, string>();
  return (s: string) => {
    const caseSens = caseInsToSensitiveStrings.get(s.toLowerCase());
    if (caseSens) return caseSens;
    caseInsToSensitiveStrings.set(s.toLowerCase(), s);
    return s;
  };
})();
