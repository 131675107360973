import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import styled from "@emotion/styled";
import { Download } from "react-feather";
import { atomWithStorage } from "jotai/utils";
import Image from "next/image";
import { useEffect } from "react";
import {
  isContinuousIntegration,
  isIOs,
  isInstallBannerEnabled,
  isMobile,
  isRunningInIOSWebview,
  isTutorialEnabled,
} from "../utils/environment";
import { APP_STORE_URL } from "../utils/constants";
import { userSettingsAtom } from "../model/atoms";
import { CloseIcon } from "./Icons/CloseIcon";

const rejectedAppNudgeAtom = atomWithStorage("hasRejectedAppNudge", false);
const rejectedPwaNudgeAtom = atomWithStorage("hasRejectedPwaNudge", false);

const isIOS = isIOs || (typeof navigator !== "undefined" && /(Mac).*WebKit/.test(navigator.userAgent));

export const pwaEventAtom = atom<BeforeInstallPromptEvent | null>(null);

export const InstallBanner = () => {
  const userSettings = useAtomValue(userSettingsAtom);
  const [hasRejectedPwaNudge, setHasRejectedPwaNudge] = useAtom(rejectedPwaNudgeAtom);
  const [hasRejectedAppNudge, setHasRejectedAppNudge] = useAtom(rejectedAppNudgeAtom);
  const [pwaEvent, setPwaEvent] = useAtom(pwaEventAtom);

  //Hide banner(s) if tutorials are enabled but user hasn't seen it.
  //Don't popup during onboarding flow (if enabled).
  if ((!userSettings.seenOnboarding && isTutorialEnabled) || isContinuousIntegration || !isInstallBannerEnabled) {
    return null;
  }

  if (isIOS && !isRunningInIOSWebview && isMobile && !hasRejectedAppNudge) {
    return getBanner({
      title: "Ideaflow",
      installButtonText: "Install App",
      installButtonCallback: () => {
        window.open(APP_STORE_URL);
      },
      closeButtonCallback: () => {
        setHasRejectedAppNudge(true);
      },
    });
  }

  if (pwaEvent && !hasRejectedPwaNudge) {
    return getBanner({
      title: "Ideaflow",
      installButtonText: "Install App",
      installButtonCallback: () => {
        pwaEvent.prompt();
        setPwaEvent(null);
      },
      closeButtonCallback: () => {
        setHasRejectedPwaNudge(true);
      },
    });
  }

  return null;
};

const getBanner = ({
  title,
  installButtonText,
  installButtonCallback,
  closeButtonCallback,
}: {
  title: string;
  installButtonText: string;
  installButtonCallback: () => void;
  closeButtonCallback: () => void;
}) => {
  return (
    <Banner>
      <Logo>
        <Image src="/logo192.png" height="56px" width="56px" alt="Ideaflow Logo" />
      </Logo>
      <InstallButton onClick={installButtonCallback}>
        <span
          style={{
            marginRight: "5px",
            color: "#fff",
          }}
        >
          {installButtonText}
        </span>

        <Download width={"1.5rem"} height={"1.5rem"} color={"#fff"} />
      </InstallButton>
      <span
        style={{
          display: "grid",
          alignItems: "center",
          color: "var(--color-text-secondary)",
        }}
      >
        <CloseIcon onClickCallback={closeButtonCallback} height="1.5rem" width="1.5rem" />
      </span>
    </Banner>
  );
};

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export function useCapturePWAEventAtStartup() {
  const setPwaEvent = useSetAtom(pwaEventAtom);
  useEffect(() => {
    const beforeInstallPromptEventListener = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setPwaEvent(event);
    };
    window.addEventListener("beforeinstallprompt", beforeInstallPromptEventListener);
    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPromptEventListener);
    };
  }, [setPwaEvent]);
}

const Banner = styled.div`
  padding: 1rem;
  width: 100vw;
  min-width: fit-content;
  overflow: hidden;
  position: fixed;
  top: 55px;
  border-top: 4px solid var(--color-bg-accent-primary);
  color: var(--color-text-primary);
  background-color: var(--color-bg-primary);
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const InstallButton = styled.button`
  padding: 0.625rem 1.25rem;
  border-radius: 50px;
  max-height: 50px;
  align-items: center;
  text-align: center;
  display: inline-flex;
  gap: 8px;
  border-radius: var(--radius-smooth);
  border-width: 0;
  border-style: solid;
  margin: 0 10px;
  background-color: #00b4bb;
  &:hover {
    opacity: 0.8;
  }
  > span {
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    display: grid;
    align-items: center;
    color: #000;
    font-family: "Inter", sans-serif;
  }
`;

const Logo = styled.div`
  width: 56px;
  height: 56px;
  flex-grow: 1;
  @media screen and (max-width: 430px) {
    display: none;
  }
`;
