export const EDITOR_ID = "editor-container";
export const HEADER_ID = "editor-page-header";

export const MOBILE_BAR_ID = "mobile-keyboard-bar";
export const MOBILE_BAR_HEIGHT = 47;
export const HELP_SEARCH_ID = "help-search";
export const AUTOCOMPLETE_MENU_ID = "autocomplete-menu";
export const NEW_NOTE_BUTTON_ID = "newNoteButton";
export const NEW_AUDIO_BUTTON_ID = "newAudioButton";
export const ALL_NOTES_LINK_ID = "all-notes";
export const TODO_LINK_ID = "all-incompletes";

export const ONBOARDING_GUIDE_URL = "https://onboarding.ideaflow.app";
export const CHANGELOG_URL = "https://changelog.ideaflow.app";
export const APP_STORE_URL = "https://apps.apple.com/us/app/ideaflow-notes/id6463830566";
export const DELETE_WARNING_THRESHOLD = 20;
