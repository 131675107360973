import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { useSetAtom } from "jotai";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { X } from "react-feather";
import { appNoteStore, pinnedPositions } from "../../../model/services";
import { scrollEditorToTop } from "../../../editorPage/App";
import { NoteId } from "../../../../shared/types";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { mediumWidth } from "../../../utils/style";
import { GrabHandle } from "../GrabHandle";
import { isSidebarOpenAtom } from "../../../model/atoms";
import { useNote } from "../../../editorPage/noteMenu/NoteMenu";
import { useNotifySidebarUpdate } from "../../atoms/sidebarUpdate";
import { addToast } from "../../../components/Toast";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { PinIcon } from "../../../editorPage/noteMenu/Icons";
import { PinnedLink } from "./PinnedLink";

export const PinnedNote: React.FC<{
  noteId: NoteId;
  setSearch: SearchQuerySetter;
}> = (props) => {
  const { noteId, setSearch } = props;
  const note = useNote(noteId);
  const updateSidebar = useNotifySidebarUpdate();
  const togglePin = useCallback(() => {
    if (!note) return;
    const positionInPinned = note.positionInPinned ? null : pinnedPositions.generateFirst()[0];
    appNoteStore.update({ id: note.id, positionInPinned });
    addToast(positionInPinned ? "Note pinned" : "Note unpinned");
    trackEvent("toggle_note_pinned", `${note.id} ${note.positionInPinned ? "unpinned" : "pinned"}`);
  }, [note]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: noteId });

  const setShowSidebar = useSetAtom(isSidebarOpenAtom);

  const onSelect = () => {
    if (window.innerWidth <= mediumWidth) setShowSidebar(false);
    scrollEditorToTop();
    setSearch({ noteIdList: [noteId] });
  };

  const notePreviewRef = React.useRef<HTMLSpanElement>(null);
  useEffect(() => {
    //We added code to remove pin when deleting notes. However deleted notes will
    //still be pinned prior to the fix, this code below will make sure a pin
    //wil automatically remove itself if a note is not found.
    if (noteId && !note) {
      appNoteStore.update({ id: noteId, positionInPinned: null });
      return;
    }
    // Slightly convoluted approach but necessary to insert the span element into JSX
    notePreviewRef.current?.replaceChildren(appNoteStore.getNoteEllipsis(noteId));
  }, [notePreviewRef, noteId, note]);

  return (
    <PinnedItemWrapper
      className="pinned-item"
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition as any,
      }}
      ref={setNodeRef}
      onClick={onSelect}
      onKeyPress={(evt) => evt.key === "Enter" && onSelect()}
      {...attributes}
    >
      <PinnedLink id={`pinned-note-${noteId}`} title={appNoteStore.getNotePreview(noteId)}>
        <PinnedItemContent>
          <GrabHandleWrapper onClick={(e) => e.stopPropagation()}>
            <GrabHandle title="Reorder this pinned note" {...listeners}>
              ⠿
            </GrabHandle>
          </GrabHandleWrapper>
          <NoteContainer className="note-container">
            <NoteContent>
              <IconWrapper className="icon-wrapper">
                <PinIcon />
              </IconWrapper>
              <NotePreview ref={notePreviewRef} />
            </NoteContent>
            <CloseButton
              className="close-button"
              onClick={(e) => {
                e.stopPropagation();
                togglePin();
                updateSidebar();
              }}
              title="Unpin note"
            >
              <X size={16} />
            </CloseButton>
          </NoteContainer>
        </PinnedItemContent>
      </PinnedLink>
    </PinnedItemWrapper>
  );
};

const PinnedItemWrapper = styled.div`
  margin: 1px 0;
  user-select: none;
`;

const PinnedItemContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const NoteContainer = styled.div`
  width: 0;
  flex: 1;
  display: flex;
  align-items: center;
  background-color: var(--color-bg-secondary);
  height: 36px;
  padding: 0 8px 0 4px;
  border-radius: 6px;
  justify-content: space-between;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: var(--color-bg-tertiary);
  }

  &:hover .close-button {
    opacity: 1;
  }

  &:hover .icon-wrapper {
    opacity: 1;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: var(--color-text-tertiary);
  padding: 0;
  margin-left: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;
  transition:
    color 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
  opacity: 0;

  &:hover {
    color: var(--color-text-secondary);
  }
`;

const GrabHandleWrapper = styled.div`
  position: absolute;
  left: -10px;
`;

const NoteContent = styled.div`
  width: 0;
  flex: 1;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  opacity: 0.75;
  width: 16px;
  padding-left: 2px;
  align-items: center;
  transition: opacity 0.15s ease-in-out;
`;

const NotePreview = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 6px;
`;
