import styled from "@emotion/styled";

export const EditorContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* Make the editor content always overflow. This ensures:
  - The autocomplete menu doesn't get cutoff, which it does at the border or the
    content container.
  - On mobile, scrolling is always done on the editor container, not the body.
    This is important because the mobile keyboard bar and header only remain
    fixed if the container is scrolled, not the body.
  */
  min-height: 120%;
  /* This padding ensures the 'load more' button is in view even when the
  virtual keyboard is open on mobile */
  .editor-padding {
    min-height: 60vh;
    flex-grow: 1;
  }
`;
