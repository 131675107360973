import React from "react";
import styled from "@emotion/styled";
import { colors } from "./style";

export const Error = () => {
  return (
    <ErrorContainer>
      <Heading>An error occurred, please refresh the page</Heading>
      <RefreshButton onClick={() => window.location.reload()}>Refresh</RefreshButton>
    </ErrorContainer>
  );
};

/** A component that makes the error page in the main background color, with the elements in the center, working beautifully both for desktop and mobile */
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.bg.primary};
  color: ${colors.text.primary};
  height: 100vh;
  width: 100vw;
  text-align: center;
`;

const Heading = styled.h3`
  font-size: 1.5rem;
`;

/** Button in the style of other Ideaflow buttons */
const RefreshButton = styled.button`
  all: unset;
  background: ${colors.bg.accent.primary};
  color: ${colors.text.primary};
  cursor: pointer;
  font-weight: 700;
  font-size: 22px;
  border-radius: 6px;
  border: none;
  padding: 8px 22px;
  :hover {
    background-color: #118b96;
  }
  :focus {
    outline: 2px solid #00636b;
    outline-offset: 2px;
  }
`;
