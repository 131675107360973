import { trackEvent } from "../../analytics/analyticsHandlers";
import { useHome } from "../../editorPage/navigateHistory";

export function useBackToAllNotes() {
  const home = useHome();
  return () => {
    home();
    trackEvent("navigate_home_from_shortcut");
  };
}
