import { NodeSpec } from "prosemirror-model";
import { getDefaultAttrs, domAttrs } from "../../utils/attrs";

export const audioInsert: NodeSpec = {
  group: "topLevelNode blockNode",
  // We would like to make this selectable, but we were having issues with the
  // responsiveness of the recorder buttons and suspected this was the cause, so
  // disabled it for now. (We weren't able to the issue though, so the property
  // could be unrelated. This should be revisited.)
  selectable: false,
  atom: true,
  attrs: {
    audioId: { default: null },
    tokenId: { default: "" },
    updatedAt: { default: 0 },
    // checkCount: 0 = legacy audio insert, null = no audio saved, >=1 = audio saved
    chunkCount: { default: 0 },
    durations: { default: null },
    transcript: { default: null },
    transcriptLanguageOverride: { default: null },
    transcriptGenId: { default: null },
    isTranscriptCollapsed: { default: false },
    shouldParseTranscript: { default: false },
    isRevealed: { default: false },
  },
  parseDOM: [
    {
      tag: "audio.audio-insert",
      getAttrs: getDefaultAttrs([
        { key: "audioId", type: "string" },
        { key: "updatedAt", type: "int" },
        { key: "chunkCount", type: "int" },
        { key: "durations", type: "int[]" },
        { key: "transcript", type: "string" },
        { key: "transcriptLanguageOverride", type: "string" },
        { key: "transcriptGenId", type: "string" },
        { key: "isTranscriptCollapsed", type: "boolean" },
        { key: "shouldParseTranscript", type: "boolean" },
        { key: "stage", type: "string" },
        { key: "isRevealed", type: "boolean" },
      ]),
    },
  ],
  toDOM(node) {
    return [
      "div",
      {
        ...domAttrs(node, [
          "audioId",
          "updatedAt",
          "chunkCount",
          "durations",
          "transcript",
          "transcriptLanguageOverride",
          "transcriptGenId",
          "isTranscriptCollapsed",
          "shouldParseTranscript",
          "stage",
          "isRevealed",
        ]),
        class: "audio-insert",
      },
    ];
  },
};
