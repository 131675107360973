import { Transaction } from "prosemirror-state";

/**
 * Use this when your transaction updates need to repeatedly map positions from
 * the current transaction positions to the latest transaction positions.
 *
 * Calls to `tr.mapping.map` within the callback will map positions from the `tr.doc`
 * state at the time you call this function, as opposed to the usual behaviour of
 * `tr.mapping.map` which maps positions from before the transaction was applied.
 *
 * @param tr transaction
 * @param fn transaction updater
 * @returns tr with fn steps applied
 */
export function subTransaction(tr: Transaction, fn: (subTransaction: Transaction) => void) {
  // The Prosemirror typings are wrong here, Transaction constructor doesn't take a document Node, but an EditorState
  const subTransaction = new Transaction({
    doc: tr.doc,
    selection: tr.selection,
    storedMarks: tr.storedMarks,
  } as any);
  fn(subTransaction);
  for (let i = 0; i < subTransaction.steps.length; i++) {
    tr.step(subTransaction.steps[i]);
  }
  return tr;
}
