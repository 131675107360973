import { SortBy } from "../search/SearchQuery";
import { SelectMenu } from "../components/SelectMenu";

type Props = {
  label: string;
  currentValue: SortBy;
  setValue: (value: SortBy) => void;
};

export function SearchResultsSortMenu({ label, currentValue, setValue }: Props) {
  const options: { value: SortBy; label: string }[] = [
    { value: SortBy.relevance, label: "Most relevant" },
    { value: SortBy.lastUpdated, label: "Last updated" },
    { value: SortBy.oldest, label: "Oldest" },
    { value: SortBy.position, label: "Position" },
  ];
  const currentOptionLabel = options.find((opt) => opt.value === currentValue)?.label;
  if (!currentOptionLabel) {
    throw new Error(`Invalid sortBy value: ${currentValue}`);
  }
  return (
    <SelectMenu id="search-sort-menu" label={label} options={options} currentValue={currentValue} setValue={setValue} />
  );
}
