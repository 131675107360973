import { EditorView, NodeView } from "prosemirror-view";
import { Node } from "prosemirror-model";
import { generatePreview } from "../../utils/clipboard/imageTools";
import { zIndexes } from "../../../utils/style";

const IMAGE_MIN_WIDTH_IN_PX = 200;

export class ImageView implements NodeView {
  dom: HTMLSpanElement;
  imgContainer: HTMLDivElement;
  handle: HTMLSpanElement;

  constructor(
    private node: Node,
    view: EditorView,
    getPos: () => number | undefined,
    isReadOnly: boolean,
  ) {
    const { src, id, width, naturalWidth, naturalHeight, smallPreviewDataURL } = this.node.attrs;

    const outer = document.createElement("span");
    outer.style.position = "relative";
    outer.style.display = "inline-block";
    outer.style.minWidth = `${IMAGE_MIN_WIDTH_IN_PX}px`;
    outer.style.maxWidth = "100%";
    // Fixes ENT-2411 on webkit browsers. Also fixes caret visibility on right side only on chrome
    outer.style.paddingRight = "1px";
    outer.style.paddingLeft = "2px";
    outer.style.lineHeight = "0px";
    if (width !== null) {
      outer.style.width = width;
      outer.style.minWidth = `calc(min(${IMAGE_MIN_WIDTH_IN_PX}px, ${width})`;
    }

    // On iOS Safari the image must be made unselectable otherwise the default behaviour of the
    // browser is to extend the selection when the user drags the handle
    if (!window.matchMedia("(hover: hover)").matches) {
      outer.classList.add("nonselectable");
    }

    this.imgContainer = document.createElement("div");
    this.imgContainer.style.overflow = "hidden";
    this.imgContainer.style.aspectRatio = `${naturalWidth} / ${naturalHeight}`;

    const img = document.createElement("img");
    img.setAttribute("src", src);
    img.setAttribute("id", id);
    img.setAttribute("crossOrigin", "anonymous");
    img.setAttribute("loading", "lazy");
    // If the precise width is specified force the image to be the full width
    // of the container which is of the desired width.
    // Letting the image scale arbitrarily causes problems, since the image might be smaller
    // than the specified width
    if (width !== null) {
      img.style.width = "100%";
      img.style.minWidth = `calc(min(${IMAGE_MIN_WIDTH_IN_PX}px, ${width})`;
    } else {
      img.style.maxWidth = "100%";
      img.style.minWidth = `${IMAGE_MIN_WIDTH_IN_PX}px`;
    }
    img.style.height = "auto";
    if (naturalWidth !== null && naturalHeight !== null) {
      // Older browsers use width and height to calculate aspect ratio
      img.setAttribute("width", naturalWidth);
      img.setAttribute("height", naturalHeight);
      outer.style.aspectRatio = `${naturalWidth} / ${naturalHeight}`;
    }

    if (smallPreviewDataURL) {
      img.style.backgroundImage = `url(${smallPreviewDataURL})`;
      img.style.filter = `blur(10px)`;
      img.style.transition = "filter 0.3s";
      img.style.backgroundSize = "cover";
      img.style.backgroundRepeat = "none";
    }
    img.addEventListener(
      "load",
      () => {
        // We need to remove the background preview since the loaded image might have transparency
        img.style.backgroundImage = "none";
        img.style.filter = "none";
      },
      { once: true },
    );

    this.handle = document.createElement("span");
    this.handle.style.position = "absolute";
    this.handle.style.zIndex = zIndexes.imageView.toString();
    this.handle.style.width = "20px";
    this.handle.style.height = "20px";
    this.handle.style.backgroundColor = "#37BDC9";
    this.handle.style.borderRadius = "15px";
    this.handle.style.display = "none";
    this.handle.style.bottom = "-10px";
    this.handle.style.right = "-10px";
    this.handle.style.cursor = "nwse-resize";

    if (!isReadOnly) {
      const getXPosition = (e: MouseEvent | TouchEvent) => {
        if ("pageX" in e) {
          return e.pageX;
        } else {
          return e.touches.item(0)?.pageX;
        }
      };
      const handleMouseOrTouchDown = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();

        const startX = getXPosition(e);

        if (!startX) return;

        const startWidth = outer.getBoundingClientRect().width;

        // Invariants of image resizing:
        // 1. The image as displayed never overflows the containing note
        // 2. We do not resize images on behalf of the user without their intent
        //    (though we may display a smaller version to fit in the viewport)
        //
        // To achieve this, we do the following:
        // 1. Store all image sizes in terms of px
        // 2. Default to no set px-based size on new images, and only set a px
        //    width on user's resizing.
        // 3. If the screen is too small, we display images that would overflow in
        //    a smaller box with max-width: 100%, but we do not modify its saved
        //    width value.
        const noteWidth = (outer.parentElement ? outer.parentElement : view.dom).getBoundingClientRect().width;
        const onMouseOrTouchMove = (e: MouseEvent | TouchEvent) => {
          e.preventDefault();
          const currentX = getXPosition(e);
          if (!currentX) return;
          const diffInPx = currentX - startX;
          // Do not let the user resize an image larger than the containing note
          outer.style.width = `${Math.min(startWidth + diffInPx, noteWidth)}px`;
          img.style.width = "100%";
        };

        const onMouseOrTouchUp = () => {
          document.removeEventListener("mousemove", onMouseOrTouchMove);
          document.removeEventListener("touchmove", onMouseOrTouchMove);
          document.removeEventListener("mouseup", onMouseOrTouchUp);
          document.removeEventListener("touchend", onMouseOrTouchUp);

          const newWidthInPx = Math.max(parseInt(window.getComputedStyle(outer).width), IMAGE_MIN_WIDTH_IN_PX);

          const pos = getPos();
          if (pos === undefined) return;

          const transaction = view.state.tr.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            // Old images that don't have their natural sizes measured can be updated
            naturalWidth: node.attrs.naturalWidth ?? img.naturalWidth,
            naturalHeight: node.attrs.naturalHeight ?? img.naturalHeight,
            // Old images that don't have the small preview generated yet can now get the preview
            smallPreviewDataURL: node.attrs.smallPreviewDataURL ?? generatePreview(img),
            width: `${newWidthInPx}px`,
          });

          view.dispatch(transaction);
        };

        document.addEventListener("mousemove", onMouseOrTouchMove);
        document.addEventListener("touchmove", onMouseOrTouchMove);
        document.addEventListener("mouseup", onMouseOrTouchUp);
        document.addEventListener("touchend", onMouseOrTouchUp);
      };
      this.handle.addEventListener("mousedown", handleMouseOrTouchDown, {
        passive: false,
      });
      this.handle.addEventListener("touchstart", handleMouseOrTouchDown, {
        passive: false,
      });
    }

    outer.appendChild(this.handle);
    outer.appendChild(this.imgContainer);
    this.imgContainer.appendChild(img);

    this.dom = outer;
  }

  selectNode() {
    this.imgContainer.classList.add("ProseMirror-selectednode");

    this.handle.style.display = "";
  }

  deselectNode() {
    this.imgContainer.classList.remove("ProseMirror-selectednode");

    this.handle.style.display = "none";
  }
}
