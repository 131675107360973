import { Path, getDepthFromPath } from "../../editor/utils/path";
import { Note } from "../../../shared/types";
import { formatDate } from "../../utils/date";
import { useShortcuts } from "../../shortcuts/useShortcuts";
import { getShortcutString } from "../../shortcuts/shortcuts";
import type { useMenuActions } from "./useMenuActions";
import {
  PublishIcon,
  ShareLinkIcon,
  StopIcon,
  Assistant,
  DeleteIcon,
  ShowDayIcon,
  CopyTextIcon,
  InternalLinkIcon,
} from "./Icons";

export const More = ({
  note,
  notePath,
  actions: a,
}: {
  note: Note;
  notePath: Path;
  actions: ReturnType<typeof useMenuActions>;
}) => {
  const { shortcuts } = useShortcuts();
  return (
    <div className="menu-expanded">
      {note.readAll ? (
        <button className="menu-item" onClick={a.stopPublishing}>
          <StopIcon />
          <div className="menu-icon-spacer">Stop publishing</div>
        </button>
      ) : (
        <button className="menu-item" onClick={a.publish}>
          <PublishIcon />
          <div className="menu-icon-spacer">Publish</div>
        </button>
      )}
      <button className="menu-item" onClick={a.share}>
        <ShareLinkIcon />
        <div className="menu-icon-spacer">Share direct link</div>
      </button>
      <button
        className="menu-item"
        onClick={a.extractEntitiesWithAI}
        role="tooltip"
        data-microtip-position="left"
        aria-label={`${getShortcutString(shortcuts.extractEntitiesWithAI)}`}
      >
        <Assistant />
        <div className="menu-icon-spacer">Parse with AI</div>
      </button>
      <button className="menu-item" onClick={a.goToDay}>
        <ShowDayIcon />
        <div className="menu-icon-spacer">Jump to day</div>
      </button>
      <button className="menu-item" onClick={a.copy}>
        <CopyTextIcon />

        <div className="menu-icon-spacer">Copy text</div>
      </button>
      <button className="menu-item" onClick={a.copyUrl}>
        <InternalLinkIcon />

        <div className="menu-icon-spacer">Copy internal link</div>
      </button>
      {/* deleteNote only works on top level notes */}
      {getDepthFromPath(notePath) === 0 && (
        <button className="menu-item" onClick={a.deleteNote}>
          <DeleteIcon />
          <div className="menu-icon-spacer">Delete note</div>
        </button>
      )}
      <button
        className="menu-item"
        onClick={a.cycleCollapse}
        role="tooltip"
        data-microtip-position="left"
        aria-label={
          note.expansionSetting === "collapse"
            ? "Always show condensed, even while compact view is off"
            : note.expansionSetting === "expand"
              ? "Always show full note, even while compact view is on"
              : "Show full note when compact view is off, and condense when it's on"
        }
      >
        {note.expansionSetting === "collapse" && (
          <>
            <input type="checkbox" checked={true} readOnly />
            Always condense
          </>
        )}
        {note.expansionSetting === "expand" && (
          <>
            <input type="checkbox" checked={false} readOnly />
            Always expand
          </>
        )}
        {(note.expansionSetting === "auto" || !note.expansionSetting) && (
          <>
            <input
              type="checkbox"
              readOnly
              ref={(input) => {
                if (input) {
                  input.indeterminate = true;
                }
              }}
            />
            Normal condense
          </>
        )}
      </button>
      <hr />
      <div className="menu-info">Created {formatDate(note.createdAt).toLowerCase()}</div>
      <div className="menu-info">Updated {formatDate(note.updatedAt).toLowerCase()}</div>
    </div>
  );
};
