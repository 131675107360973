import { Node } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import { schema } from "../schema";
import { descendNotes } from "./descendNotes";

type findPredicate = (node: Node, pos?: number) => boolean;
export type findResult = [Node, number] | [null, null];

/**
 * Traverse upwards from the given position, returning the
 * first position and node matching the predicate.
 *
 * @param doc The document to traverse
 * @param pos The position to start from
 * @param fn The predicate to match
 * @returns The position and node matching the predicate
 *
 * */
export function findParent(doc: Node, pos: number, fn: findPredicate): findResult {
  const rpos = doc.resolve(pos);
  for (let depth = rpos.depth; depth > 0; depth--) {
    const pos = rpos.before(depth);
    const node = rpos.node(depth);
    if (fn(node, pos)) {
      return [node, pos];
    }
  }
  return [null, null];
}

export function findDescendant(doc: Node, fn: findPredicate): findResult {
  let res: findResult = [null, null];
  doc.descendants((node, pos) => {
    if (res[0] !== null) return false; // stop traversal
    if (fn(node, pos)) {
      res = [node, pos];
    }
  });
  return res;
}

export function findDescendantNote(doc: Node, fn: findPredicate): findResult {
  let res: findResult = [null, null];
  descendNotes(doc, (node, pos) => {
    if (res[0] !== null) return false; // stop traversal
    if (fn(node, pos)) {
      res = [node, pos];
    }
  });
  return res;
}

export function getParentNote(doc: Node, pos: number): findResult {
  return findParent(doc, pos, (n) => n.type === schema.nodes.note);
}

/** Return the note node and it's position which is at or containg given position*/
function findNoteContainingPos(doc: Node, pos: number): findResult {
  if (doc.nodeAt(pos)?.type === schema.nodes.note) {
    return [doc.nodeAt(pos)!, pos];
  } else {
    return findParent(doc, pos, (n) => n.type === schema.nodes.note);
  }
}

/**
 * Returns selection at start of note or expanded note.
 * Ignores backlinks and ellipsis container if note is condensed.
 * @param doc The document to traverse
 * @param pos The position at or inside the note
 * */
export const findSelectionAtStartOfNote = (doc: Node, pos: number): Selection | null => {
  const [noteNode, notePos] = findNoteContainingPos(doc, pos);
  if (notePos === null || noteNode === null) {
    return null;
  }
  const skipNodes = [schema.nodes.backlinks, schema.nodes.expandedReference];
  if (noteNode.attrs.condensable && noteNode.attrs.isCondensed) {
    skipNodes.push(schema.nodes.ellipsisContainer);
  }
  // Move start position to first selectable node
  let startPos = notePos + 1;
  for (let idx = 0; idx < noteNode.childCount; idx++) {
    const child = noteNode.child(idx);
    if (!skipNodes.includes(child.type)) {
      break;
    }
    startPos += child.nodeSize;
  }
  // Then find selection from there
  return Selection.findFrom(doc.resolve(startPos), 1);
};

/**
 * Returns selection at end of note or expanded note
 * Ignores backlinks and ellipsis container if note is condensed.
 * @param doc The document to traverse
 * @param pos The position at or inside the note
 * */
export const findSelectionAtEndOfNote = (doc: Node, pos: number): Selection | null => {
  const [noteNode, notePos] = findNoteContainingPos(doc, pos);
  if (notePos === null || noteNode === null) {
    return null;
  }
  const skipNodes = [schema.nodes.backlinks, schema.nodes.expandedReference];
  if (noteNode.attrs.condensable && noteNode.attrs.isCondensed) {
    skipNodes.push(schema.nodes.ellipsisContainer);
  }
  // Move end position to last selectable node
  let endPos = notePos + noteNode.nodeSize - 1;
  for (let idx = noteNode.childCount - 1; idx >= 0; idx--) {
    const child = noteNode.child(idx);
    if (!skipNodes.includes(child.type)) {
      break;
    }
    endPos -= child.nodeSize;
  }
  // Then find selection from there
  return Selection.findFrom(doc.resolve(endPos), -1);
};

/**
 * Returns selection at end of first selectable line of a note or expanded note.
 * Ignores backlinks and ellipsis container if note is condensed.
 * @param doc The document to traverse
 * @param pos The position at or inside the note
 * */
export const findSelectionAtEndOfNotesFirstLine = (doc: Node, pos: number): Selection | null => {
  const [noteNode, notePos] = findNoteContainingPos(doc, pos);
  if (notePos === null || noteNode === null) {
    return null;
  }
  const skipNodes = [schema.nodes.backlinks, schema.nodes.expandedReference];
  if (noteNode.attrs.condensable && noteNode.attrs.isCondensed) {
    skipNodes.push(schema.nodes.ellipsisContainer);
  }
  // Move start position to first selectable node
  let startPos = notePos + 1;
  for (let idx = 0; idx < noteNode.childCount; idx++) {
    const child = noteNode.child(idx);
    startPos += child.nodeSize;
    if (!skipNodes.includes(child.type)) {
      break;
    }
  }
  // Then find selection from there
  return Selection.findFrom(doc.resolve(startPos), -1);
};
