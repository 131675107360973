import { openDB } from "idb";

const DBNAME = "ideaflow";
const STORENAME = "analytics-offline";

async function openKeyValDB() {
  return await openDB(DBNAME, 1, {
    upgrade(db) {
      db.createObjectStore(STORENAME);
    },
  });
}

const analyticsCache = {
  async getItem<T>(key: string) {
    return (await openKeyValDB()).get(STORENAME, key) as T;
  },
  async findAllKeys() {
    return (await openKeyValDB()).getAllKeys(STORENAME);
  },
  async setItem(key: string, val: any) {
    return (await openKeyValDB()).put(STORENAME, val, key);
  },
  async removeItem(key: string) {
    return (await openKeyValDB()).delete(STORENAME, key);
  },
};

export default analyticsCache;
