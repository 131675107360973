import { SetStateAction, atom, getDefaultStore } from "jotai";
import { HelpContentProps } from "../help/Help";
import { PublishDescendantProps } from "../editorPage/noteMenu/PublishDescendantsModal";

export enum ModalEnum {
  IMPORT = "import",
  EXPORT = "export",
  DELETE_ACCOUNT = "delete-account",
  RESET_PASSWORD = "reset-password",
  SETTINGS = "settings",
  HELP = "help",
  UNSYNCED_LOGOUT = "unsynced-logout",
  PUBLISH_DESCENDANTS = "publish-descendants",
  SIMON_QUERY = "simon-query",
}

type CustomPropsByModal = {
  [ModalEnum.HELP]: HelpContentProps;
  [ModalEnum.PUBLISH_DESCENDANTS]: PublishDescendantProps;
};

export type ModalOptions<T extends ModalEnum = ModalEnum> = {
  /** If true, there won't be a backdrop behind the modal and it won't be closed on click outside */
  noBackdrop?: boolean;
  /** If true, the modal will be displayed without the container (e.g. if there's custom styling) */
  withoutContainer?: boolean;
  /** If true, the modal will be closed whatever its position is in the stack */
  independent?: true;
  /** Whatever properties a particular modal needs */
  customProps?: T extends keyof CustomPropsByModal ? CustomPropsByModal[T] : undefined;
  /** Callback to be called when the modal is opened */
  openCallback?: () => void;
  closeCallback?: (data?: any) => void;
};

const DEFAULT_OPTIONS: Partial<Record<ModalEnum, ModalOptions>> = {
  [ModalEnum.HELP]: { noBackdrop: true, withoutContainer: true, independent: true },
  [ModalEnum.SIMON_QUERY]: { noBackdrop: true, withoutContainer: true },
};

const extractOptions = <T extends ModalEnum>(name: T, options?: ModalOptions<T>) => {
  const optionsToApply = { ...DEFAULT_OPTIONS[name], ...options };
  delete optionsToApply.openCallback; // Don't pass the openCallback to the modal component
  return optionsToApply;
};

type ModalDescriptor<T extends ModalEnum = ModalEnum> = {
  name: ModalEnum;
  locked: boolean;
  options?: ModalOptions<T>;
};

export const modalStackAtom = atom<ModalDescriptor[]>([]);
const getModalStack = () => getDefaultStore().get(modalStackAtom);
const setModalStack = (updater: SetStateAction<ModalDescriptor[]>) => getDefaultStore().set(modalStackAtom, updater);

const hasModalOpen = (name: ModalEnum) => getModalStack().some((modal) => modal.name === name);

export function openModal<T extends ModalEnum>(name: T, options?: ModalOptions<T>) {
  if (hasModalOpen(name)) return; // If the modal is already open, do nothing
  setModalStack((modalStack) => [...modalStack, { name, locked: false, options: extractOptions(name, options) }]);
  if (options?.openCallback) options.openCallback();
}

export function closeTopModal() {
  const modalStack = getModalStack();
  if (modalStack[modalStack.length - 1].locked) return;
  setModalStack(modalStack.slice(0, modalStack.length - 1));
}

export function closeModal(name: ModalEnum, data?: any) {
  const modalStack = getModalStack();
  const modalIndex = modalStack.findIndex((modal) => modal.name === name);
  if (modalIndex === -1) return;

  const modal = modalStack[modalIndex];
  if (modal.locked) return;

  if (modal.options?.independent) {
    // Close the independent modal wherever it is in the stack
    setModalStack(modalStack.filter((modal) => modal.name !== name));
  } else {
    const nonIndependentStack = modalStack.filter((modal) => !modal.options?.independent);
    // Close the modal if it's the top one among the non-independent modals
    if (modal === nonIndependentStack[nonIndependentStack.length - 1]) {
      setModalStack(modalStack.filter((modal) => modal.name !== name));
    }
  }

  if (modal.options?.closeCallback) {
    modal.options?.closeCallback(data);
  }
}

export function toggleModal<T extends ModalEnum>(name: T, options?: ModalOptions<T>) {
  if (hasModalOpen(name)) {
    closeModal(name);
  } else {
    openModal(name, options);
  }
}

function setModalLocked(name: ModalEnum, locked: boolean) {
  setModalStack((modalStack) => modalStack.map((modal) => (modal.name === name ? { ...modal, locked } : modal)));
}

export function lockModal(name: ModalEnum) {
  setModalLocked(name, true);
}

export function unlockModal(name: ModalEnum) {
  setModalLocked(name, false);
}

type AnyFunction = (...args: any[]) => any;
export function withCloseModals<T extends AnyFunction>(fn: T) {
  return ((...args: any[]) => {
    const modalStack = getModalStack();
    if (modalStack.some((modal) => modal.locked)) return; // If any modal is locked, do nothing
    setModalStack([]);
    fn(...args);
  }) as T;
}
