import { Plugin, PluginKey } from "prosemirror-state";
import { Node } from "prosemirror-model";

import isEqual from "lodash.isequal";
import debounce from "lodash.debounce";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { getTopLevelTokensFromContent } from "../bridge";
import { appNoteStore } from "../../model/services";
import { notifySidebarUpdate } from "../../sidebar/atoms/sidebarUpdate";
import logger from "../../utils/logger";

import { getSearchQuery } from "../../search/useSearchQuery";
import { getNotesThatChangedInTree } from "./getNotesThatChangedInTree";

const debouncedTrackUpdateNoteEvent = debounce((depth, noteId) => {
  trackEvent(depth === 0 ? "update_note" : "update_note_from_expansion", noteId);
}, 1000);

export const persistencePlugin = new Plugin({
  key: new PluginKey("persistence"),
  state: {
    init: () => {},
    /** we don't keep a state on the plugin, but we use the apply to watch
     * all transactions and update our model to match
     */
    apply(tr, _, oldState, newState) {
      if (tr.getMeta("noChangeToModel") || !tr.docChanged) return;

      const notesThatChanged = getNotesThatChangedInTree(oldState.doc, newState.doc);

      // Synchronize deletes to the model
      notesThatChanged.deletes.forEach((noteId) => {
        trackEvent(getSearchQuery().folderId ? "delete_note_from_folder" : "delete_note", noteId);
      });
      appNoteStore.delete(notesThatChanged.deletes);

      const upsertsByNoteId = new Map<string, Node[]>();
      for (const node of notesThatChanged.upserts) {
        const arr = upsertsByNoteId.get(node.attrs.noteId) ?? [];
        arr.push(node);
        upsertsByNoteId.set(node.attrs.noteId, arr);
      }

      // Synchronize upserts to the model
      const updates = Array.from(upsertsByNoteId.entries()).map(([noteId, upsertedNotes]) => {
        const [firstNote] = upsertedNotes;
        const topLevelTokens = getTopLevelTokensFromContent(firstNote.content);
        for (const otherNote of upsertedNotes.slice(1)) {
          if (!isEqual(getTopLevelTokensFromContent(otherNote.content), topLevelTokens)) {
            logger.error(`Two edited nodes with the same noteId (${noteId}) disagree on tokens!`, {
              context: {
                legacyMessage: "persistence-plugin-inconsistent-tokens",
              },
            });
          }
        }
        const { insertedAt, depth } = firstNote.attrs;
        debouncedTrackUpdateNoteEvent(depth, noteId);
        return {
          id: noteId,
          tokens: topLevelTokens,
          ...(insertedAt ? { insertedAt } : {}),
          // The upserted note might have been brought back by undo, so we must mark it as not deleted
          deletedAt: null,
        };
      });
      appNoteStore.update(updates);

      if (notesThatChanged.deletes.length > 0) {
        // The notes count has changed, update the sidebar
        notifySidebarUpdate();
      }
    },
  },
});
