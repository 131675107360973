import dynamic from "next/dynamic";
import "normalize.css";
import "./global.css";
import "./microtip.css";
import "../sidebar/Elements/selectedLink.css";
import "../sidebar/Elements/Pinned/PinnedLink.css";
import "../sidebar/Elements/Folders/Folders.css";
// we need to include css in the main file in nextjs
import "../editor/baseProsemirror.css";
import "../editor/note.css";
import "../editorPage/noteMenu/menu.css";
import "../editor/tokens.css";
import type { AppProps } from "next/app";
import { Suspense, useEffect } from "react";
import { ErrorBoundary } from "@sentry/nextjs";
import Head from "next/head";
import posthog from "posthog-js";
import { useKeyboardStatusWatcher } from "../shortcuts/useKeyboardStatusWatcher";
import {
  isAnalyticsEnabled,
  isClientSide,
  isContinuousIntegration,
  isIOs,
  isProd,
  isServerSide,
  isTouchDevice,
  posthogApiKey,
  posthogHost,
} from "../utils/environment";
import { useHtmlTitle } from "../editorPage/hooks/useHtmlTitle";
import { Error } from "../utils/Error";
// After cleaning up imports in 6a7f3be (PR #1792), a bug was introduced
// only on the iOS native build, where every time you do a navigation
// the app reloads. Through bisecting I found that it was the fact we stopped
// importing all the src/editorPage/hooks here that resulted in the bug.
// I haven't finished debugging yet, but importing the module below hides the
// issue, so I'm deploying this for now and will get to the bottom of the issue
// on ENT-2658.
import "../editorPage/hooks/useNoteEditor";
import { useCapturePWAEventAtStartup } from "../components/InstallBanner";

if (isClientSide) {
  if (isProd) {
    const stylesArray = [
      `background-image: url('/ideaflow-bg.png')`,
      "background-size: cover",
      "color: #0000ff",
      "padding: 10px 20px",
      "font-size: 18px",
      "line-height: 35px",
      "width : 70px",
      "height : 126px",
      "border : 5px solid black",
    ].join(";");
    console.log("%c Ideaflow ❤ developers", stylesArray);
    console.log("Apply at jobs@ideaflow.io");
  }
}

function InnerApp({ Component }: AppProps) {
  // Allow the SharedPage component to be used without login
  useHtmlTitle();
  useKeyboardStatusWatcher();
  useCapturePWAEventAtStartup();
  useEffect(() => {
    if (isServerSide) return;
    // https://stackoverflow.com/q/47802530/
    if (!isIOs) {
      document.body.classList.add("not-ios");
    }
    if (isTouchDevice) {
      document.body.classList.add("touch-device");
    }
    if (isContinuousIntegration) {
      document.body.classList.add("continuous-integration");
    }
    if (!isAnalyticsEnabled) return;
    posthog.init(posthogApiKey, { api_host: posthogHost });
  }, []);
  return (
    <>
      <Head>
        <title>Ideaflow</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="theme-color" content="#313131" />
        <meta name="description" content="Intelligence Amplification Ecosystem." />
        <meta httpEquiv="Content-Security-Policy" content="script-src 'self' 'unsafe-inline' 'unsafe-eval'" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      </Head>
      <ErrorBoundary fallback={<Error />}>
        <Suspense fallback={null}>
          <Component />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default dynamic(() => Promise.resolve(InnerApp), {
  ssr: false,
});
