import { Command } from "prosemirror-state";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { getParentNote } from "../../utils/find";

export function getShowNoteInTimeline(setSearchQuery: SearchQuerySetter): Command {
  return (state) => {
    const { $from } = state.selection;
    const [note] = getParentNote(state.doc, $from.pos);
    if (!note) return false;
    setSearchQuery({ jumpTo: note.attrs.noteId });
    return true;
  };
}
