/**
 * Tracks which items were updated most recently.
 */
export class LastUpdated {
  // A set of items were updated most recently. We do not use Set() since
  // we care about the order and it is always sorted from oldest at 0 to newest
  lastUpdated: string[] = [];

  // The max allowed size for the lastUpdated array. This is currently slightly
  // larger than the autocomplete's MAX_SUGGESTIONS such that even if we exclude
  // some hashtags used in the current note, we can still get MAX_SUGGESTIONS suggestions.
  private _historyMaxSize: number;

  constructor(historyMaxSize: number) {
    this._historyMaxSize = historyMaxSize;
  }

  clear() {
    this.lastUpdated = [];
  }

  private removeSimilar(id: string) {
    const pos = this.lastUpdated.findIndex((e) => e.toLocaleLowerCase() === id.toLocaleLowerCase());
    if (pos !== -1) this.lastUpdated.splice(pos, 1);
  }

  private removeOldest() {
    if (this.lastUpdated.length > this._historyMaxSize)
      this.lastUpdated.splice(0, this.lastUpdated.length - this._historyMaxSize);
  }
  /**
   *  Removes items that no longer appear in the document
   */
  remove(ids: string[]) {
    ids.forEach((id) => {
      this.removeSimilar(id);
    });
  }

  /**
   *  Adds new items and removes the x first older items used
   */
  update(ids: string[]) {
    ids.forEach((id) => {
      this.removeSimilar(id);
      this.lastUpdated.push(id);
    });
    this.removeOldest();
  }
}
