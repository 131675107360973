import { TopLevelToken } from "../../../shared/types";
import { generateId } from "../../model/generateId";

export const getAsLinkLoaderTokens = (url: string): TopLevelToken[] => {
  return [
    {
      type: "paragraph",
      tokenId: generateId(),
      content: [
        {
          type: "linkloader",
          tokenId: generateId(),
          url: url,
          isActive: true,
        },
        {
          type: "link",
          content: url,
          slug: url,
        },
      ],
    },
  ];
};
