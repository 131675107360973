import { MarkSpec } from "prosemirror-model";

export const italic: MarkSpec = {
  parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
  toDOM: () => ["em", { class: "italic" }, 0],
};

export const bold: MarkSpec = {
  parseDOM: [
    { tag: "strong" },
    // https://github.com/ProseMirror/prosemirror-schema-basic/blob/master/src/schema-basic.js#L136
    // This works around a Google Docs misbehavior where
    // pasted content will be inexplicably wrapped in `<b>`
    // tags with a font-weight normal.
    {
      tag: "b",
      getAttrs: (element: any) => element.style.fontWeight !== "normal" && null,
    },
    {
      style: "font-weight",
      getAttrs: (value: any) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
    },
  ],
  toDOM: () => ["strong", { class: "bold" }, 0],
};

export const underline: MarkSpec = {
  parseDOM: [{ tag: "u" }, { style: "text-decoration=underline" }],
  toDOM() {
    return ["u", { class: "underline" }, 0];
  },
};

export const strikethrough: MarkSpec = {
  parseDOM: [{ tag: "s" }],
  toDOM() {
    return ["s"];
  },
};
