import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import MenuItem from "../autocomplete/MenuItem";
import useResettableState from "../autocomplete/useResettableState";
import { getSearchBarRef } from "../model/atoms";
import { setSearchBarAndQuery } from "./SearchBar";
import { useSearchInputCueText, useSearchInputText } from "./searchInputText";
import { useSearchSuggestions } from "./useSearchSuggestions";

const SearchSuggestionsWrapper = styled.div`
  display: block;
  position: absolute;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;

  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  background-color: var(--color-bg-primary);
  border-radius: 4px;

  transition: filter 0.6s;

  .suggestion-item-list {
    width: 100%;
  }
`;

interface Props {
  keyDownHandlerRef: React.MutableRefObject<((event: React.KeyboardEvent<HTMLInputElement>) => boolean) | null>;
}

export const DesktopSearchSuggestionList = ({ keyDownHandlerRef }: Props) => {
  const [suggestionsCancelled, setSuggestionsCancelled] = useState(false);
  const searchInputText = useSearchInputText();
  const cueText = useSearchInputCueText();

  const { mostRecentValue: suggestions } = useSearchSuggestions(cueText);

  // activeElementIndex is reset back to initial value whenever the list of suggestions changes
  const [activeElementIndex, setActiveElementIndex] = useResettableState(0, [suggestions]);

  const pickIt = (index: number) => {
    const suggestion = suggestions[index];
    const newQuery = searchInputText.slice(0, -cueText.length) + `tag:${suggestion.content} `;
    setSearchBarAndQuery(newQuery);
  };

  keyDownHandlerRef.current = (event) => {
    // don't attempt to handle if the suggestion list isn't visible
    if (!suggestions.length || suggestionsCancelled) {
      return false;
    }

    let idx: number;
    switch (event.key) {
      case "Escape":
        event.stopPropagation();
        setSuggestionsCancelled(true);
        return true;
      case "ArrowDown":
        idx = activeElementIndex + 1;
        setActiveElementIndex(idx % suggestions.length);
        return true;
      case "ArrowUp":
        idx = activeElementIndex > 0 ? activeElementIndex - 1 + suggestions.length : suggestions.length - 1;
        setActiveElementIndex(idx % suggestions.length);
        return true;
      case "Enter":
        pickIt(activeElementIndex);
        return true;
    }
    return false;
  };

  useEffect(() => {
    // Reset cancelled state when suggestions change
    setSuggestionsCancelled(false);
  }, [suggestions]);

  const searchBarRef = getSearchBarRef();
  if (!searchBarRef.current || !suggestions.length || suggestionsCancelled) {
    return null;
  }

  const searchBarRect = searchBarRef.current.getBoundingClientRect();

  // Each suggestion about 35px tall, and we want to show at most 3 suggestions
  const maxHeight = 35 * 3 + "px";

  const styles = {
    top: `${searchBarRect.top + searchBarRect.height}px`,
    left: `${searchBarRect.left}px`,
    maxHeight,
    width: `${searchBarRect.width}px`,
    display: "block",
  };
  return (
    <SearchSuggestionsWrapper style={styles}>
      <div className="suggestion-item-list">
        {suggestions.map((s, i) => (
          <MenuItem
            key={s.id + i}
            setAsActiveElement={() => {
              setActiveElementIndex(i);
            }}
            isHighlighted={i === activeElementIndex}
            suggestion={s}
            onClick={() => pickIt(i)}
          />
        ))}
      </div>
    </SearchSuggestionsWrapper>
  );
};
