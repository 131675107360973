import { Node } from "prosemirror-model";

/**
 * The note node is empty when it contains exactly one empty paragraph.
 * The size of the text node is 0, the size of the paragraph is 2 and
 * the size of the note node is 4. See: https://prosemirror.net/docs/ref/#model.Node.nodeSize
 */
export function isNoteEmpty(node: Node) {
  return node.nodeSize === 4;
}
