import { Plugin, PluginKey } from "prosemirror-state";
import { Step } from "prosemirror-transform";
import { Node } from "prosemirror-model";
import { schema } from "../../schema";
import logger from "../../../utils/logger";
import { findParent } from "../../utils/find";

/**
 * Checks if a given step performs a delete operation that partially affects
 * an expandedNote.
 * @param {Node} doc
 * @param {Step} step
 */
function isStepCrossingExpansionNode(doc: Node, step: Step): boolean {
  const stepMap = step.getMap();
  let isCrossing = false;
  let iterations = 0;

  stepMap.forEach((deletionStart, deletionEnd) => {
    iterations++;

    const isDeleteStep = stepMap.mapResult(deletionStart).deleted;

    if (isCrossing || !isDeleteStep) {
      return;
    }

    // Internally inside findParent method, we resolve the positions: deletionStart and deletionEnd
    // to respective nodes, and both walk to the root (doc) respectively.
    // If they find an expandedNote on their path to root, they return the expandedNoteNode and it's position.
    // On comparison:
    // - If both of them have same expandedNoteId:
    //    that means they were inside the same expandedNote. Deletion can be approved.
    // - If both of them don't have any expandedNoteId,
    //    then the deletion range encapsulates zero, one or multiple expandedNotes. Deletion can be approved.
    // -If both of them have different expandedNodeId, or one of them is null and another not.
    //    that means some expandedNote is being partially deleted.

    const [expandedParentByStart] = findParent(
      doc,
      deletionStart,
      (node) => node.type === schema.nodes.expandedReference,
    );
    const [expandedParentByEnd] = findParent(doc, deletionEnd, (node) => node.type === schema.nodes.expandedReference);

    //Ignore later step map ranges, we only care about the first step map range.
    //The later ones are just side effects of tokens being merging after content deletion.
    if (iterations === 1 && expandedParentByStart?.attrs.tokenId !== expandedParentByEnd?.attrs.tokenId) {
      isCrossing = true;
    }
  });

  return isCrossing;
}

/** Plugin that prevents user selections from deleting across expanded notes */
export const preventDeletionAcrossExpansionPlugin = new Plugin({
  key: new PluginKey("preventDeletionAcrossExpansion"),
  filterTransaction(tr) {
    if (tr.getMeta("noChangeToModel")) return true;

    let filterTransaction = false;

    tr.steps.forEach((step, i) => {
      const docBefore = tr.docs[i];
      if (filterTransaction) return;
      filterTransaction = isStepCrossingExpansionNode(docBefore, step);
    });

    if (filterTransaction) {
      logger.warn("Preventing deletion across note expansion boundary");
    }

    return !filterTransaction;
  },
});
