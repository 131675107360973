import { Command } from "prosemirror-state";
import { getParentNote } from "../../utils/find";

/**
 * Delete the top note on backspace if it's empty.
 */
export const deleteEmptyTopNoteOnBackspace: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const [note, pos] = getParentNote(state.doc, state.selection.from);
  if (!note) return false;
  // Note must be the first in the document and have no content (zero children
  // or one empty paragraph)
  if (note === state.doc.child(0) && note.childCount <= 1 && !note.firstChild?.childCount) {
    dispatch(state.tr.deleteRange(pos, pos + note.nodeSize));
    return true;
  }
  return false;
};
