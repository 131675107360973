import { InputRule, inputRules } from "prosemirror-inputrules";
import { TextSelection } from "prosemirror-state";
import { schema } from "../../schema";
import { serializeNode } from "../../utils/serializeNode";
import { isInlineCode } from "./utils";

const codeblockInputRule = new InputRule(/`{3}/g, (state, match, start, end) => {
  const $start = state.doc.resolve(start);
  const $end = state.doc.resolve(end);

  if ($start.parent.type === schema.nodes.codeblock) return null;

  //Do not start a codeblock when inside inline code
  if (isInlineCode($start)) {
    return null;
  }

  const startOfPara = $end.start();
  const endOfPara = $end.end();
  const $endOfParagraph = state.doc.resolve(endOfPara);

  // if the paragraph is empty, just replace it with the codeblock
  // if it not, append the codeblock
  const atStartOfPara = start === startOfPara;
  const targetStart = atStartOfPara ? start - 1 : start;
  const sec = new TextSelection($end, $endOfParagraph);
  const existingText = serializeNode(sec.content());

  const tr = state.tr;
  tr.replaceRangeWith(
    targetStart,
    endOfPara,
    schema.nodes.codeblock.create(null, existingText !== "" ? [schema.text(existingText)] : []),
  ).setSelection(
    // +1 to place the cursor inside the code block, rather than at the start of
    // the code block (which is an invalid place to insert text).
    new TextSelection(tr.doc.resolve(tr.mapping.map(targetStart, -1) + 1)),
  );

  return tr;
});

const inlineCodeInputRule = new InputRule(/`\b.+\b`/g, (state, match, start, end) => {
  const $start = state.doc.resolve(start);
  const $end = state.doc.resolve(end);

  if ($start.parent.type.name !== schema.nodes.paragraph.name || !$start.parent.eq($end.parent)) {
    return null;
  }

  const tr = state.tr;

  const matchedText = match[0].slice(1, -1);

  tr.insertText(matchedText + " ", start, start + matchedText.length + 1)
    .addMark(start, start + matchedText.length, schema.marks.inlineCode.create())
    .setSelection(new TextSelection(tr.doc.resolve(start + matchedText.length + 1)));
  return tr;
});

export const codeInputRulesPlugin = inputRules({ rules: [codeblockInputRule, inlineCodeInputRule] });
