import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import { findParent } from "../../utils/find";
import { schema } from "../../schema";
import { toggleCondensedOnNoteNode } from "../ellipsis/utils";

// Pressing backspace should reveal some ellipsis since they are being edited
export function backspaceEllipsisReveal(state: EditorState, dispatch?: EditorView["dispatch"]) {
  if (dispatch == null) return false;

  if (state.selection.$anchor.pos === 0) return false;

  // If the previous node is an ellipsis
  const { nodeBefore } = state.doc.resolve(state.selection.$anchor.pos - 1);
  if (nodeBefore?.type !== schema.nodes.ellipsisContainer) return false;

  const [note, pos] = findParent(state.doc, state.selection.$anchor.pos, (n) => n.type === schema.nodes.note);
  if (!note || !note.attrs.isCondensed) {
    return false;
  }
  dispatch(toggleCondensedOnNoteNode(state.tr, pos));

  return true;
}
