import { getDefaultStore } from "jotai";
import { PluginKey, Plugin, Transaction } from "prosemirror-state";
import { ReplaceStep } from "prosemirror-transform";
import appLogger from "../../../utils/logger";
import { getUserSettings } from "../../../model/userSettings";
import { autocompleteStateAtom } from "./useAutocompleteState";

const name = "ignoreAutocorrectDuringHashtagAutocomplete";
const key = new PluginKey(name);
const logger = appLogger.with({ namespace: name });
const isHashtagAutocomplete = () => getDefaultStore().get(autocompleteStateAtom)?.matcherName === "hashtag";

/**
 * The purpose of this plugin is to ignore edits that come from autocorrect
 * while the user is typing a hashtag.
 *
 * Cases we handle:
 * - Autocorrect corrects previous text (inserts text behind where the user is typing)
 * - Autocorrect inserts a space at the caret during swipe typing
 * - Autocorrect prefixes a swipe-typed word with a space
 */
export const ignoreAutocorrectDuringHashtagAutocomplete = new Plugin({
  key,
  props: {
    // Insert space ourselves so we can differentiate between autocorrect and user input
    handleKeyDown(view, event) {
      if (!getUserSettings().ignoreHashtagAutocorrect) return;
      if (getDefaultStore().get(autocompleteStateAtom)?.matcherName !== "hashtag") return;
      if (event.key === " ") {
        const tr = view.state.tr.insertText(" ", view.state.selection.from).setMeta(key, event.key);
        view.dispatch(tr);
        return true;
      }
    },
  },
  // Filter out edits that come from autocorrect
  filterTransaction(tr, state) {
    if (!getUserSettings().ignoreHashtagAutocorrect) return true;
    if (!isHashtagAutocomplete()) return true;
    if (tr.getMeta("type") === "atSignToHashtag") return true;
    if (tr.getMeta("type") === "autocomplete") return true;
    if (tr.getMeta(key)) return true;
    const insert = getInsertedText(tr);
    if (!insert) return true;
    // Filter autocorrect text insertions behind caret
    const isUserDelete = insert.text.length === 0 && insert.to === state.selection.from;
    if (!isUserDelete && insert.from !== state.selection.from) {
      logger.info("Filtering insert during hashtag autocomplete at a location other than the caret", {
        context: { insertPos: insert.from, selectionFrom: state.selection.from },
      });
      return false;
    }
    // Filter autocorrect space insertion at the caret
    if (insert.text === " ") {
      logger.info("Filtering space insert during hashtag autocomplete because it was probably from autocorrect");
      return false;
    }
    return true;
  },
  // When swipe typing, sometimes iOS will insert the swipe-typed word with a
  // space before it, e.g. " ideaflow". This detects that and removes the space.
  // We only want to do this when the user is directly after a hash though. If
  // they're in the middle of a hashtag, then they probably finished typing the
  // hashtag and intend to insert a separate word.
  appendTransaction(trs, oldState, newState) {
    if (!getUserSettings().ignoreHashtagAutocorrect) return;
    // Must be in hashtag autocomplete and directly after a hash (or at-hash if that's enabled)
    if (!isHashtagAutocomplete()) return;
    const { from, empty } = oldState.selection;
    const caretAfterHash = oldState.doc.textBetween(from - 1, from) === "#" && empty;
    const caretAfterAtHash =
      getUserSettings().atSignHashtagEnabled && oldState.doc.textBetween(from - 2, from) === "#@" && empty;
    if (!(caretAfterHash || caretAfterAtHash)) return;
    // Must be a single text insert
    const nonAppendedTrs = trs.filter((tr) => !tr.getMeta("appendedTransaction"));
    if (nonAppendedTrs.length !== 1) return;
    const tr = trs[0];
    if (!tr.docChanged) return;
    if (tr.getMeta("type") === "autocomplete") return;
    if (tr.getMeta("type") === "mobileBarInsert") return;
    if (tr.getMeta("type") === "mobileBarHashtagInsert") return;
    if (tr.getMeta("paste")) return;
    const insert = getInsertedText(tr);
    if (!insert) return;
    // If text was inserted that's prefixed by whitespace, then assume it
    // was swipe typing and remove it.
    const whitespaceLength = insert?.text.match(/^\s*/)?.[0].length ?? 0;
    if (whitespaceLength > 0 && whitespaceLength < insert.text.length) {
      logger.info("Removing whitespace prefix on swipe-typed word during hashtag autocomplete", {
        context: { whitespaceLength, insertLength: insert.text.length },
      });
      return newState.tr.delete(insert.from, insert.from + whitespaceLength);
    }
  },
});

/**
 * If the given transaction is a single ReplaceStep that inserts text, return
 * the position and text that was inserted.
 */
function getInsertedText(tr: Transaction): { from: number; to: number; text: string } | null {
  if (tr.steps.length !== 1) return null;
  const step = tr.steps[0];
  if (!(step instanceof ReplaceStep)) return null;
  let isAllText = true;
  let text = "";
  step.slice.content.descendants((node, pos) => {
    if (!isAllText) return false;
    if (node.isText) {
      text += node.text;
      return;
    } else {
      isAllText = false;
      return false;
    }
  });
  return isAllText
    ? {
        from: step.from,
        to: step.to,
        text,
      }
    : null;
}
