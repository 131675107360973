import { Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { addToast } from "../../../components/Toast";
import { getUserSettings } from "../../../model/userSettings";
import logger from "../../../utils/logger";

let dispatchTransaction = (tr: Transaction) => {};

/**
 * Plugin that warns the user if they are about to delete 5+ notes at once.
 */
export const warnOnManyDeletePlugin = new Plugin({
  view(view: EditorView) {
    dispatchTransaction = view.dispatch.bind(view);
    return {};
  },
  filterTransaction(tr, state) {
    // Auto-allow if transaction doesn't change the document
    if (!tr.docChanged) return true;
    if (tr.getMeta("noChangeToModel")) return true;
    // Allow if user has already confirmed the delete
    if (tr.getMeta("confirmedManyDelete")) return true;
    const threshold = getUserSettings().deleteWarningThreshold;
    // If there is no threshold, don't show the warning (set to -1)
    if (threshold <= 0) return true;
    // If we're below the threshold, don't show the warning
    const noteCountBefore = state.doc.childCount;
    const noteCountAfter = tr.doc.childCount;
    const notesDeleted = noteCountBefore - noteCountAfter;
    if (typeof notesDeleted !== "number" || typeof threshold !== "number") {
      // This should never happen, but we had reports of this plugin misfiring in prod
      // so we're adding this check to prevent it / get more info if it does happen
      logger.warn("Invalid note count or threshold", { context: { noteCountBefore, noteCountAfter, threshold } });
      return true;
    }
    if (notesDeleted < threshold) return true;
    // Otherwise, show the warning
    logger.info("Show delete warning toast", { context: { noteCountBefore, noteCountAfter, threshold }, report: true });
    addToast({
      content: `Are you sure you want to delete ${notesDeleted} notes?`,
      buttons: [
        {
          text: "Yes, delete them all",
          desktopShortcutKey: "y",
          onClick() {
            try {
              // Mark transaction as confirmed and re-dispatch
              tr.setMeta("confirmedManyDelete", true);
              // Will throw if document has been changed since the toast was shown
              dispatchTransaction(tr);
            } catch (e) {
              // Probably caused by document edit; do nothing
            }
          },
        },
        { text: "Cancel", desktopShortcutKey: "n" },
      ],
    });

    return false;
  },
});
