import { Home } from "react-feather";
import { useCallback } from "react";
import { useShortcuts } from "../../shortcuts/useShortcuts";
import { getShortcutString } from "../../shortcuts/shortcuts";
import { useHome } from "../navigateHistory";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { NavButton } from "./NavButton";

export const HomeButton = ({ disabled = false, size = 20 }: { disabled?: boolean; size?: number }) => {
  const { shortcuts } = useShortcuts();
  const home = useHome();
  const onClick = useCallback(() => {
    // Don't use onMouseDown for this because it leads to the following
    // error when pressed white the focus is in the search bar:
    //
    // Uncaught TypeError: Cannot read properties of null (reading
    // 'domFromPos') at coordsAtPos (index.js:459:32) at
    // EditorView.coordsAtPos (index.js:5306:1)
    //
    // There's a deeper problem here of the editor being in this null state
    // while features expect it exists, but for now this is a quick fix.
    home();
    trackEvent("navigate_home_from_header");
  }, [home]);
  return (
    <NavButton
      id="home-button"
      role="tooltip"
      data-microtip-position="right"
      aria-label={`All Notes ${getShortcutString(shortcuts.backToAllNotes)}`}
      onClick={onClick}
      disabled={disabled}
    >
      <Home size={size} />
    </NavButton>
  );
};
