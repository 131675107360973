import { Node, NodeType } from "prosemirror-model";
import { assert } from "../../utils/assert";

export default function getNode(doc: Node, pos: number, type?: NodeType): Node {
  const node = doc.nodeAt(pos)!;
  assert(!!node, `no node at pos ${pos}`);
  if (type) {
    assert(node?.type === type, `node at pos is not of type ${type.name}`);
  }
  return node;
}
