import { useColorScheme } from "../../settings/ColorScheme";

export const TildeIcon = ({ size = 24 }) => {
  const colorScheme = useColorScheme();
  const iconColor = colorScheme === "dark" ? "#B5B5B5" : "#757678";

  return (
    <svg width={size} height={size} viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
      <g style={{ strokeWidth: 0.025, stroke: iconColor, fill: "none" }}>
        <text
          x="-7"
          y="75"
          fill={iconColor}
          font-family="Times"
          font-style="normal"
          font-weight="normal"
          font-size="200"
          text-anchor="start"
        >
          ~
        </text>
      </g>
    </svg>
  );
};
