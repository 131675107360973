import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { schema } from "../../schema";
import { preserveExpandedReferencesAround } from "../reference/referenceExpansionUtils";

export const insertParagraphCommand = (state: EditorState, dispatch?: EditorView["dispatch"]) => {
  const { $from } = state.selection;
  const tr = state.tr;
  preserveExpandedReferencesAround(state, tr, () => {
    tr.deleteSelection().split(state.tr.mapping.map($from.pos), 1, [{ type: schema.nodes.paragraph }]);
  });
  dispatch?.(tr);
  return true;
};
