import { storage } from "../storage/storage";
import { IdeaflowUser } from "./useAuth";

export type Auth = AuthLoggedIn | AuthLoggedOut;

export type AuthLoggedIn = {
  type: "logged-in";
  accessToken: string;
  user: IdeaflowUser;
};

export type AuthLoggedOut = {
  type: "logged-out";
};

const authKey = "auth-state";

export async function getAuth(): Promise<Auth | null> {
  return await storage.getItem(authKey);
}

export async function setAuth(auth: Auth) {
  await storage.setItem(authKey, auth);
}

export async function clearAuth() {
  await storage.removeItem(authKey);
}
