import { useState, useRef, useMemo, useEffect, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";

type DebouncedMemoedValue<T> = {
  mostRecentValue: T;
  isValid: boolean;
  computeNow: () => T;
};

export function useDebouncedMemo<T, Deps extends unknown[]>(
  computeValue: () => T,
  delayInMs: number,
  deps: Deps,
): DebouncedMemoedValue<T> {
  const [state, setState] = useState<T>(computeValue);
  const currentComputeRef = useRef(computeValue);
  currentComputeRef.current = computeValue;
  const update = useDebouncedCallback(() => {
    isValidRef.current = true;
    setState(currentComputeRef.current());
  }, delayInMs);

  const isValidRef = useRef(false);
  useMemo(() => {
    isValidRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const computeNow = useCallback(() => {
    update.cancel();
    isValidRef.current = true;
    const newValue = currentComputeRef.current();
    setState(newValue);
    return newValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    mostRecentValue: state,
    isValid: isValidRef.current,
    computeNow,
  };
}
