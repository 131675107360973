import { MarkSpec, NodeSpec } from "prosemirror-model";
import { generateId } from "../../../model/generateId";
import { domAttrs } from "../../utils/attrs";

export const inlineCode: MarkSpec = {
  content: "text*",
  group: "inline",
  toDOM: (node) => {
    return [
      "code",
      {
        class: "inline",
      },
      0,
    ];
  },
  parseDOM: [
    {
      tag: "code.inline",
    },
  ],
  excludes: "_",
  code: true,
};

export const codeblock: NodeSpec = {
  content: "text*",
  group: "topLevelNode blockNode",
  draggable: false,
  attrs: {
    tokenId: { default: "" },
  },
  parseDOM: [
    { tag: "pre > code", preserveWhitespace: "full" },
    { tag: "code.inline", ignore: true },
    { tag: "pre", preserveWhitespace: "full" },
    { tag: "samp", preserveWhitespace: "full" },
  ],
  toDOM: (node) => [
    "pre",
    [
      "code",
      {
        ...domAttrs(node, { tokenId: generateId() }),
        class: "codeblock",
      },
      0,
    ],
  ],
  marks: "highlight inlineCode",
  code: true,
};
