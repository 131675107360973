import { TopLevelToken, Note, SerializedNote, NotePosition, UserSettings, InlineToken } from "../../shared/types";
import { formatInsertedAt } from "../editorPage/utils/insertedAt";
import { DELETE_WARNING_THRESHOLD } from "../utils/constants";
import { Positions } from "./position/Positions";
import { generateId } from "./generateId";
import { getUserId } from "./atoms";

const date = new Date();

export const defaultNoteFields = {
  createdAt: date,
  updatedAt: date,
  insertedAt: formatInsertedAt(date),
  folderId: null,
  readAll: false,
  isSharedPrivately: false,
  directUrlOnly: true,
  deletedAt: null,
  positionInPinned: null,
  importSource: null,
  importBatch: null,
  importForeignId: null,
};

const defaultSerializedNoteFields = {
  created_at: date.toISOString(),
  updated_at: date.toISOString(),
  insertedAt: formatInsertedAt(date),
  folder_id: null,
  read_all: false,
  is_shared_privately: false,
  direct_url_only: true,
  deleted_at: null,
  position_in_pinned: null,
  import_source: null,
  import_batch: null,
  import_foreign_id: null,
};

export function makeSettledPosition(position: string): NotePosition {
  return { type: "settled" as const, position };
}

export function makeNote(
  partialNote: Omit<Partial<Note>, "tokens" | "position"> & {
    tokens?: TopLevelToken[] | string[];
    position?: NotePosition | string;
  },
): Note {
  const date = new Date();
  const position = partialNote.position ?? Positions.integers.INTEGER_ZERO;
  return {
    ...defaultNoteFields,
    ...partialNote,
    id: partialNote.id ?? generateId(),
    authorId: getUserId() ?? "",
    position: typeof position === "string" ? makeSettledPosition(position) : position,
    tokens: (partialNote.tokens ?? []).map((t) => (typeof t === "string" ? makeParagraph(t) : t)),
    insertedAt: partialNote.insertedAt ?? formatInsertedAt(date),
    createdAt: partialNote.createdAt ?? date,
    updatedAt: partialNote.updatedAt ?? date,
  };
}

export function makeSerializedNote(partialNote: Partial<SerializedNote>): SerializedNote {
  const date = new Date();
  return {
    ...defaultSerializedNoteFields,
    ...partialNote,
    id: partialNote.id ?? generateId(),
    author_id: getUserId() ?? "",
    position: partialNote.position ?? Positions.integers.INTEGER_ZERO,
    tokens: partialNote.tokens ?? [],
    expansion_setting: partialNote.expansion_setting ?? "auto",
    inserted_at: partialNote.inserted_at ?? formatInsertedAt(date),
    created_at: partialNote.created_at ?? date.toISOString(),
    updated_at: partialNote.updated_at ?? date.toISOString(),
  };
}

export function makeParagraph(content?: string | InlineToken[]): TopLevelToken {
  return {
    type: "paragraph",
    tokenId: generateId(),
    content: !content ? [] : typeof content === "string" ? [{ type: "text", content, marks: [] }] : content,
  };
}

/**
 * Default user settings.
 *
 * We set all the values here, because when new settings are added, we want devs to come
 * to this file and make an explicit choice about the default value should be.
 *
 * NOTE: The various `seen` fields are set to `true` in the atom initialization, and then
 * updated to `false` after we load the user's settings if the user hasn't seen the feature.
 * This avoids pestering existing users with onboarding hints while their settings are still loading.
 */
export const defaultUserSettings: UserSettings = {
  unfurlingEnabled: true,
  imageOcrEnabled: true,
  smartAutocompleteEnabled: false,
  commaAutocompleteEnabled: false,
  atSignHashtagEnabled: false,
  tildeSignHashtagEnabled: false,
  seenOcrToast: false,
  ignoreHashtagAutocorrect: true,
  seenAtSignToast: false,
  seenTildeSignToast: false,
  seenOpenAsPageHint: false,
  seenToDoToast: false,
  seenUnfurl: false,
  seenUnfurlUndo: false,
  seenOnboarding: false,
  // seenExportNotesOnSaveToast: false,
  // Use to permanently dismiss export notes on save toast
  appendExtractsToCanonicalNotes: true,
  deleteWarningThreshold: DELETE_WARNING_THRESHOLD,
  showTodoStrikethrough: true,
  defaultTranscriptionLanguage: "en",
  customShortcuts: {},
  pinnedHashtags: [],
  simonSearchEnabled: true,
};

export function makeUserSettings(partialUserSettings: Partial<UserSettings> = {}): UserSettings {
  return { ...defaultUserSettings, ...partialUserSettings };
}
