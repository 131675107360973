import { useEffect } from "react";
import { getDefaultStore, useAtomValue } from "jotai";
import logger, { Logger } from "../../../utils/logger";
import { isOnlineAtom, userSettingsAtom } from "../../../model/atoms";
import { readBlobAsDataUri } from "../../utils/clipboard/utils";
import { createSingleQueueAsync } from "../../../utils/runners";
import { urlUpload } from "../../../utils/environment";
import { isOfflineError } from "../../../utils/errorCheckers";
import { TRANSCRIPTION_LANGUAGES } from "../../../../shared/transcription";
import { ApiClient } from "../../../api/client";
import { storage } from "../../../storage/storage";
import { Stage } from "./audioTypes";
import { useLocalAudio } from "./hooks";

export const audioLogsPrefix = "audio";
const audioInsertPrefix = "audio-insert";

// This function creates the key used to store an audio file in the IndexedDB and in S3
function getAudioInsertPrefix(audioId?: string, fragmentIndex?: number | string) {
  let prefix = audioInsertPrefix;
  if (audioId) prefix += `-${audioId}`;
  if (fragmentIndex !== undefined) prefix += `-${fragmentIndex}`;
  return prefix;
}

function audioChunkBlobKey(audioId: string, fragmentIndex: number | string) {
  return getAudioInsertPrefix(audioId, fragmentIndex) + ".mp3";
}

function audioChunkDurationKey(audioId: string, fragmentIndex: number | string) {
  return getAudioInsertPrefix(audioId, fragmentIndex) + "-duration";
}

function audioChunkBlobUrl(audioId: string, fragmentIndex: number | string) {
  return `${urlUpload}/${audioChunkBlobKey(audioId, fragmentIndex)}`;
}

export function getTranscriptionLanguage(transcriptLanguageOverride?: string | null) {
  // Override is set by user in the audio insert UI for this specific recording and takes precedence above all else
  if (!!transcriptLanguageOverride && Object.keys(TRANSCRIPTION_LANGUAGES).includes(transcriptLanguageOverride)) {
    return transcriptLanguageOverride;
  }

  // User has a default transcription language in their settings
  return getDefaultStore().get(userSettingsAtom).defaultTranscriptionLanguage;
}

async function uploadAudioChunk(audioId: string, chunk: number, blob: Blob) {
  const fileDataUri = await readBlobAsDataUri(blob);
  const apiClient = ApiClient();
  try {
    await apiClient.files.upload(fileDataUri, audioChunkBlobKey(audioId, chunk));
  } catch (error) {
    throw new Error(`Uploading failed, reason: ${error instanceof Error ? error.message : "Unknown"}`);
  }
}

export function getAudioURLs(
  audioId: string,
  stage: Stage,
  localAudio: NonNullable<ReturnType<typeof useLocalAudio>>,
  chunkCount: number,
  durations: number[] | null,
) {
  if (stage === Stage.Recording) return [];
  // Always prefer the local audio URLs, since "interrupted" audio is hard to distinguish from legacy audio (chunkCount is also 0)
  if (localAudio.length !== 0) return localAudio.map(({ blob, ...rest }) => rest);
  // Legacy audio uploads have no chunkCount property so the default value of 0 is used by Prosemirror
  if (chunkCount === 0) {
    return [{ url: `${urlUpload}/images/audio-insert-${audioId}`, duration: null }];
  }

  if (chunkCount === null || durations === null) {
    return [];
  }

  return Array.from(new Array(Math.min(chunkCount, durations.length)), (_, i) => ({
    url: audioChunkBlobUrl(audioId, i),
    duration: durations?.[i] ?? 0,
  }));
}

export async function getLocalAudio(audioId: string) {
  const allKeys = await storage.findAllKeysWithPrefix(getAudioInsertPrefix(audioId));

  const audioChunkIndices = allKeys
    .filter((key): key is string => typeof key === "string")
    .map((key) => key.match(new RegExp("^" + audioChunkBlobKey(audioId, "(\\d+)") + "$"))?.[1])
    .filter((m): m is string => !!m)
    .map((index) => parseInt(index))
    .sort((a, b) => a - b);

  if (!audioChunkIndices.every((v, i) => v === i)) {
    logger.error(new Error("Broken chunks of audio detected"), {
      namespace: audioLogsPrefix,
      context: { audioId, audioChunkIndices },
    });
    return [];
  }

  if (audioChunkIndices.length === 0) return [];

  const blobsAndDurations = await storage.getItems(
    audioChunkIndices.flatMap((i) => {
      return [audioChunkBlobKey(audioId, i), audioChunkDurationKey(audioId, i)];
    }),
  );
  return audioChunkIndices.map((i) => {
    const blob = blobsAndDurations[2 * i + 0];
    const duration = blobsAndDurations[2 * i + 1];
    return {
      blob: blob as Blob,
      duration: (duration ?? null) as number | null,
    };
  });
}

export async function deleteLocalAudio(audioId?: string, chunk?: number) {
  const prefix = getAudioInsertPrefix(audioId, chunk);
  const keys = await storage.findAllKeysWithPrefix(prefix);
  await storage.removeItems(keys.map((key) => key.toString()));
}

/**
 * Try uploading local audio chunks and refresh the editor if successful.
 *
 * Only one upload can be running at a time. If there is already an upload
 * running, this will be queued and run after the current upload finishes. If
 * there is already an upload queued, this is a no-op.
 */
export const debouncedUploadAllAudio = createSingleQueueAsync(
  async (logger: Logger) => {
    try {
      const allKeys = await storage.findAllKeysWithPrefix(audioInsertPrefix);

      const audioChunkIds = new Map<string, number[]>();
      for (const key of allKeys) {
        const m = key.toString().match(new RegExp("^" + audioChunkBlobKey("(.+)", "(\\d+)") + "$"));
        if (!m) continue;
        const audioId = m[1];
        const chunkNumber = parseInt(m[2]);
        const chunks = audioChunkIds.get(audioId) ?? [];
        chunks.push(chunkNumber);
        audioChunkIds.set(audioId, chunks);
      }
      const count = Array.from(audioChunkIds.values()).reduce((sum, chunks) => sum + chunks.length, 0);
      logger.info(`Found ${count} audio chunks in the cache`);
      if (count === 0) return false;

      const allBlobs: { audioId: string; chunk: number; blob: Blob }[] = [];
      for (const [audioId, chunks] of audioChunkIds.entries()) {
        chunks.sort((a, b) => a - b);
        if (!chunks.every((c, i) => c === i)) {
          logger.error("Audio missing some chunks", {
            report: true,
            context: { audioId: audioId, chunks: chunks.join(", ") },
          });
        }
        const blobs = (await storage.getItems<Blob>(chunks.map((c) => audioChunkBlobKey(audioId, c)))).map(
          (blob, i) => ({
            audioId,
            chunk: chunks[i],
            blob,
          }),
        );
        allBlobs.push(...blobs);
      }

      logger.info(`Uploading ${allBlobs.length} audio chunks`);
      await Promise.all(
        allBlobs.map(async ({ chunk, blob, audioId }) => {
          await uploadAudioChunk(audioId, chunk, blob);
          await storage.removeItems([audioChunkBlobKey(audioId, chunk), audioChunkDurationKey(audioId, chunk)]);
          logger.info(`Uploaded audio ${audioId} chunk ${chunk}`);
        }),
      );
      logger.info(`Finished uploading all local audio chunks`);
      return true;
    } catch (error) {
      if (isOfflineError(error)) {
        logger.info(`Not uploading audio chunks because we're offline`);
      } else {
        logger.error(`Error uploading all local audio chunks`, { error });
      }
      return false;
    }
  },
  { name: `${audioLogsPrefix}/uploadAudio` },
);

/**
 * Upload all local audio chunks whenever we come back online
 */
export function useAutoUploadAudio() {
  const isOnline = useAtomValue(isOnlineAtom);
  // Retry when we come online
  useEffect(() => {
    if (!isOnline) return;
    debouncedUploadAllAudio();
  }, [isOnline]);
}

/**
 * Converts ISO UTC DateTime to "MM-DD-YYYY HH-MM-SS"
 * @param datetime
 */

export const formatISOToUSDateTime = (datetime: string) => {
  const [creationDate, creationTime] = new Date(datetime).toLocaleString("en-US").split(", ");

  const creationTimeParts = creationTime.split(" ")[0].split(":");

  if (creationTime.endsWith("PM")) {
    creationTimeParts[0] = `${+creationTimeParts[0] + 12}`;
  }

  if (Number(creationTimeParts[0]) < 10) {
    creationTimeParts[0] = `0${creationTimeParts[0]}`;
  }

  return creationDate.split("/").join("-") + " " + creationTimeParts.join("-");
};

export const decodeBase64ToMp3 = (base64EncodedString: string) => {
  const binaryString = window.atob(base64EncodedString);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: "audio/mp3" });
};
