import { InlineToken, TopLevelToken, TokenId, Note, BlockContentToken } from "./types";

// ts-prune-ignore-next
export const ELLIPSIZE_TRUNCATE_AT = 40;
const BLANK_LINE_SKIP_BUFFER = 5;
const DELETED_NOTE_TEXT = "deleted note";
export const EMPTY_NOTE_TEXT = "(empty note)";
const MAX_DEPTH = 3;
export type FormatNoteOptions = { maxLengthOverride?: number; maxDepth?: number; breakOnFirstNonEmptyLine?: boolean };

export type BlockText = { tokenId: TokenId; text: string };

export class NoteFormatter {
  private expandLines = (
    tokens: TopLevelToken[],
    lines: number,
    getNote?: (noteId: string) => Note | undefined,
    { maxDepth = MAX_DEPTH }: FormatNoteOptions = {},
  ): string => {
    // We want to skip returning blank lines, but want to avoid paying the perf
    // cost of expanding every line of every note for every reference. So we
    // serialize an additional "buffer" of lines, and trim down blank lines at
    // the end.
    const targetTokens = tokens.slice(0, lines + BLANK_LINE_SKIP_BUFFER);
    const result = this.getNoteAsStrings(targetTokens, getNote, { maxDepth })
      .slice(0, lines)
      .filter((line) => line.length > 0)
      .join(" ");

    if (result.trim() === "") {
      if (lines === Infinity) return EMPTY_NOTE_TEXT;
      return "...";
    }

    return result;
  };

  /**
   * Like {@link NoteFormatter.getNoteAsBlockTexts}, but returns strings instead of {@link BlockText}s.
   * To get something to display to the user, use getNotePreview instead.
   */
  getNoteAsStrings(
    targetTokens: TopLevelToken[],
    getNote?: (noteId: string) => Note | undefined,
    { breakOnFirstNonEmptyLine = false, maxDepth = MAX_DEPTH }: FormatNoteOptions = {},
  ): string[] {
    return this.getNoteAsBlockTexts(targetTokens, getNote, { breakOnFirstNonEmptyLine, maxDepth }).map(
      (blockText) => blockText.text,
    );
  }

  /**
   * Returns a plain text version of the note suitable for indexing for search.
   * Skips all references and does not include token prefixes.
   *
   * @param targetTokens - the tokens to get the string for
   * @param shouldExpandReference - whether to expand out the first level of references
   * @param lines - the number of lines to return. Defaults to Infinity.
   */
  getNoteAsBlockTexts(
    targetTokens: TopLevelToken[],
    getNote?: (noteId: string) => Note | undefined,
    { breakOnFirstNonEmptyLine = false, maxDepth = MAX_DEPTH }: FormatNoteOptions = {},
  ): BlockText[] {
    let output: BlockText[] = [];
    const process = (blockToken: TopLevelToken): boolean => {
      if (blockToken.type === "audioInsert") {
        output.push({
          tokenId: blockToken.tokenId,
          text: "(audio)",
        });
      } else if (blockToken.type === "list") {
        output = [
          ...output,
          ...this.getNoteAsBlockTexts(blockToken.content.map((li) => li.content).flat(), getNote, {
            breakOnFirstNonEmptyLine,
            maxDepth,
          }),
        ];
      } else {
        const p = blockToken.content.map((token: InlineToken) => {
          switch (token.type) {
            case "text":
            case "link":
            case "hashtag": {
              return token.content.replace(/\n/g, " ");
            }
            case "checkbox":
              return token.isChecked ? "[x] " : "[ ] ";
            case "image":
              return "(image)";
            case "asyncReplacedElement":
              return token.fallbackText;
            case "spaceship": {
              if (!getNote || maxDepth <= 0) return "[...]";
              else {
                const ellipsisSpan = this.getNoteEllipsis(getNote(token.linkedNoteId), getNote, {
                  maxDepth: maxDepth - 1,
                });
                return `(${ellipsisSpan.textContent})`;
              }
            }
            case "linkloader":
              return "";
            default:
              token satisfies never;
              throw new Error("Could not index token " + JSON.stringify(token));
          }
        });
        const line = p.join("");
        output.push({ tokenId: blockToken.tokenId, text: line });
      }
      const lastLine = output[output.length - 1].text || "";
      return breakOnFirstNonEmptyLine && lastLine.trim().length > 0;
    };

    targetTokens.find(process);
    return output;
  }

  /**
   * Returns a textual representation of the start of the note, wrapped in a <span> tag,
   * suitable for representing the target of a reference within the editor.
   */
  getNoteEllipsis(
    note: Note | undefined,
    getNote?: (noteId: string) => Note | undefined,
    { maxDepth = MAX_DEPTH }: FormatNoteOptions = {},
  ): HTMLSpanElement {
    if (!note || note.deletedAt) {
      return span(DELETED_NOTE_TEXT, "reference-inner");
    }
    for (const token of note.tokens) {
      const result = span(tokenToSpans(token, getNote, maxDepth));
      if (result.textContent !== "") return result;
    }
    return span(EMPTY_NOTE_TEXT);
  }

  /**
   * Returns a plain text, human-readable version of the note for when the note
   * needs to be displayed in plain text, but remain human readable. Expands out
   * 1 level of references and keeps token prefixes.  For indexing and search,
   * use getNoteIndexString instead.
   */
  getNotePreview(
    note: Note | undefined,
    getNote?: (noteId: string) => Note | undefined,
    { maxDepth = MAX_DEPTH }: FormatNoteOptions = {},
  ): string {
    if (note && !note.deletedAt) {
      return this.expandLines(note.tokens, Infinity, getNote, { maxDepth });
    } else {
      return DELETED_NOTE_TEXT;
    }
  }

  /**
   * Returns a plain text version of the note or null if the provided noteId
   * doesn't exist.
   */
  noteToString(
    note?: Note,
    getNote?: (noteId: string) => Note | undefined,
    { maxDepth = MAX_DEPTH }: FormatNoteOptions = {},
  ): string {
    if (!note) return "";
    return this.getNoteAsStrings(note.tokens, getNote, { maxDepth }).join("\n");
  }

  getNoteSuggestionText(
    note: Note | undefined,
    getNote?: (noteId: string) => Note | undefined,
    { maxDepth = MAX_DEPTH }: FormatNoteOptions = {},
  ): string {
    if (!note || note.deletedAt) return DELETED_NOTE_TEXT;
    const result = this.getNoteAsStrings(note.tokens, getNote, { maxDepth })
      .filter((line) => line.length > 0)
      .join(" \\ ")
      .trim();
    if (result === "") return EMPTY_NOTE_TEXT;
    return result;
  }

  getFirstLine(note: Note, getNote?: (noteId: string) => Note | undefined): string | undefined {
    const summary = this.getNoteAsStrings(note.tokens, getNote, {
      breakOnFirstNonEmptyLine: true,
    });
    const firstNonEmptyLine = summary[summary.length - 1] || "";
    if (firstNonEmptyLine === "") return undefined;
    return firstNonEmptyLine;
  }
}

function truncate(str: string, maxSize: number): string {
  return str.length > maxSize ? str.substr(0, maxSize - 1) + "..." : str;
}

function span(content: string | HTMLSpanElement[], className?: string, addParens = false) {
  const el = document.createElement("span");
  if (className) el.className = className;

  if (typeof content === "string") {
    const truncated = truncate(content, ELLIPSIZE_TRUNCATE_AT);
    const text = addParens ? `(${truncated})` : truncated;
    el.append(text);
    return el;
  }

  for (const child of content) {
    // Avoid adding spans that are just whitespace or no-breakspace
    const hasContent = !!child.textContent?.replace(/\u00a0/g, "").trim();
    if (!hasContent) continue;
    el.appendChild(child);
  }

  if (addParens) {
    el.prepend("(");
    el.append(")");
  }

  return el;
}

function tokenToSpans(
  token: InlineToken | BlockContentToken | TopLevelToken,
  getNote?: (noteId: string) => Note | undefined,
  maxDepth = MAX_DEPTH,
): HTMLSpanElement[] {
  switch (token.type) {
    case "text":
    case "link":
    case "hashtag":
      return [span(token.content.replace(/\n/g, " "))];
    case "checkbox":
      return [span(token.isChecked ? "[x] " : "[ ] ")];
    case "image":
      return [span("(image)")];
    case "asyncReplacedElement":
      return [span(token.fallbackText)];
    case "spaceship": {
      if (getNote && maxDepth > 0) {
        const note = getNote(token.linkedNoteId);
        if (note) {
          for (const token of note.tokens) {
            const trial = span(tokenToSpans(token, getNote, maxDepth - 1));
            if (trial.textContent !== "")
              return [span(tokenToSpans(token, getNote, maxDepth - 1), "reference-inner", true)];
          }
          return [span(EMPTY_NOTE_TEXT, "reference-inner")];
        } else {
          return [span("(missing note)", "reference-inner")];
        }
      } else {
        return [span("[...]")];
      }
    }
    case "linkloader":
      return [];
    case "codeblock":
      return token.content.map((token) => tokenToSpans(token, getNote, maxDepth)).flat();
    case "audioInsert":
      return token.transcript
        ? [span(`(audio with transcript: ${token.transcript})`)]
        : [span("(audio without transcript)")];
    case "list":
      return token.content
        .map((li) => li.content)
        .flat()
        .map((token) => tokenToSpans(token, getNote, maxDepth))
        .flat();
    case "paragraph":
      return token.content.map((token) => tokenToSpans(token, getNote, maxDepth)).flat();
    default:
      throw new Error("Could not index token " + JSON.stringify(token));
  }
}
