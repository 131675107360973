import React, { useEffect } from "react";
import { Selection } from "prosemirror-state";
import { useAtomValue } from "jotai";
import { noteToProsemirrorNode } from "../../bridge";
import useResettableState from "../../../autocomplete/useResettableState";
import { useNotifySidebarUpdate } from "../../../sidebar/atoms/sidebarUpdate";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { NoteId } from "../../../../shared/types";
import { useAuth } from "../../../auth/useAuth";
import Suggestion from "../../../autocomplete/Suggestion";
import MenuItem from "../../../autocomplete/MenuItem";
import { schema } from "../../schema";
import { appNoteStore } from "../../../model/services";
import { editorViewAtom, getEditorView, userSettingsAtom } from "../../../model/atoms";
import logger from "../../../utils/logger";
import { expandOrCollapseReference, getLinkedNotePosFromReferencePos } from "../reference/referenceExpansionUtils";
import { autocompleteModules, AutocompleteTypes, referenceAutocompleteTypes } from "./modules";
import { autocompleteStateAtom, useAutocompleteSuggestions } from "./useAutocompleteState";

/** we return the attrs with an `id` and then a separate `newNoteId` so we
 * can handle creating a new note with the autocomplete
 */
const getNodeToInsertAttrs = (
  pickedSuggestion: Suggestion,
  noteId: NoteId,
  matcherName: AutocompleteTypes,
): {
  attrs: { id: string; content: string } | null;
  newNoteId: string | null;
} => {
  if (!pickedSuggestion.action || pickedSuggestion.action === "insert") {
    return {
      attrs: { id: pickedSuggestion.id, content: pickedSuggestion.content },
      newNoteId: null,
    };
  }
  const content = pickedSuggestion.content;

  switch (matcherName) {
    case "hashtag": {
      return { attrs: { id: content, content }, newNoteId: null };
    }
    case "angleBrackets":
    case "relatesToReference":
    case "conversationWithReferences":
    case "bulletReference":
    case "plusReference": {
      const parent = appNoteStore.get(noteId);
      const [note] = parent
        ? appNoteStore.insertAfter(parent.id, { strings: [content] })
        : appNoteStore.insert({ strings: [content] });
      trackEvent("create_note_from_relation", note.id);
      return {
        attrs: { id: note.id, content },
        newNoteId: note.id,
      };
    }
    default: {
      matcherName satisfies never;
      return { attrs: null, newNoteId: null };
    }
  }
};

const isSelectionInMatchRange = (selection: Selection, match: { from: number; to: number }) => {
  return !(selection.from < match.from) && !(selection.from > match.to + 1);
};

export const EditorSuggestionList = ({
  autocompleteKeyDownRef,
  pickItRef,
}: {
  autocompleteKeyDownRef: React.MutableRefObject<((event: KeyboardEvent) => boolean) | null>;
  pickItRef?: React.MutableRefObject<((index: number) => boolean) | null>;
}) => {
  const autocompleteState = useAtomValue(autocompleteStateAtom);
  const commaAutocompleteEnabled = useAtomValue(userSettingsAtom).commaAutocompleteEnabled;
  const mouseMovedRef = React.useRef(false);

  const {
    mostRecentValue: suggestions,
    isValid: areAutocompleteSuggestionsValid,
    computeNow: computeSuggestionsNow,
  } = useAutocompleteSuggestions(autocompleteState);

  const view = useAtomValue(editorViewAtom);
  const { user } = useAuth();
  const currentUserId = user ? user.sub : null;

  // activeElementIndex is reset back to initial value whenever the list of suggestions changes
  const [activeElementIndex, setActiveElementIndex] = useResettableState(0, [suggestions]);

  const updateSidebar = useNotifySidebarUpdate();
  useEffect(() => {
    if (autocompleteState === null) {
      updateSidebar();
    }
  }, [autocompleteState, updateSidebar]);

  autocompleteKeyDownRef.current = (event) => {
    // nothing to do if the user is not editing an autocomplete entry (as detected by the `autocompletePlugin.ts`)
    if (!autocompleteState) {
      return false;
    }

    if (event.key === "Escape") {
      // The Escape key, if propagated to the document, triggers a navigation
      // back to the home page if the user is at some search page URLs.  We
      // never want Escape to trigger navigation when the autocomplete is
      // open.
      event.stopPropagation();
      autocompleteState.cancel();
      return true;
    }

    // If the user presses double space, exit the autocomplete (we also check for
    // "." because on mobile, double space may be autocorrected to ". ")
    const prevChar = autocompleteState.text.slice(-1);
    if (event.key === " " && prevChar.match(/\.|\s/)) {
      autocompleteState.cancel();
      return true;
    }

    // Typing in "#" inside of the hastag match confirms the current hashtag and starts a new one
    if (event.key === "#" && autocompleteState.matcherName === "hashtag") {
      if (!view) return false;

      const tr = view.state.tr;
      tr.insert(tr.selection.to, [schema.text(" "), schema.text("#", [schema.marks.hashtag.create()])]);

      view.dispatch(tr);
      return true;
    }

    // Typing in "+" inside of the reference match confirms the autocomplete and starts a new one
    if (event.key === "+" && referenceAutocompleteTypes.includes(autocompleteState.matcherName as any)) {
      if (!pickIt(activeElementIndex)) return false;
      const view = getEditorView();
      if (!view) return false;
      view.dispatch(view.state.tr.insertText("+"));
      return true;
    }

    // user finished inserting a hashtag (with or without autocomplete menu)
    if (view && autocompleteState.matcherName === "hashtag" && [" ", "Enter", "Tab"].includes(event.key)) {
      const hashtag = view.state.doc.textBetween(autocompleteState.match.from, autocompleteState.match.to);
      const count = appNoteStore.hashtags.getNoteCountForItem(hashtag);
      if (count > 0) {
        trackEvent(count === 1 ? "hashtag_created" : "hashtag_added");
      } else {
        logger.info(`match ${hashtag} is not a hashtag or missing from index`, {
          namespace: "editor",
        });
      }
    }

    // don't handle if no suggestions
    if (!suggestions.length && areAutocompleteSuggestionsValid) {
      return false;
    }

    let idx: number;
    switch (event.key) {
      case "ArrowDown":
        idx = activeElementIndex + 1;
        setActiveElementIndex(idx % suggestions.length);
        return true;
      case "ArrowUp":
        idx = activeElementIndex > 0 ? activeElementIndex - 1 + suggestions.length : suggestions.length - 1;
        setActiveElementIndex(idx % suggestions.length);
        return true;
      case ",":
        // If the user has the comma autocomplete setting enabled, and they're
        // inside a bullet-colon context, then accept the current suggestion on
        // comma, and insert a comma after it.
        if (autocompleteState.matcherName === "bulletReference" && commaAutocompleteEnabled) {
          return pickIt(activeElementIndex, { suffix: "," });
        }
        return false;
      case "Enter":
        return pickIt(activeElementIndex, {
          preferCreate: event.metaKey || event.ctrlKey,
        });
      case "Tab":
        return pickIt(activeElementIndex);
    }

    return false;
  };

  const pickIt = (
    index: number,
    { preferCreate = false, suffix = "" }: { preferCreate?: boolean; suffix?: string } = {},
  ): boolean => {
    if (!autocompleteState) return false;

    const validSuggestions = areAutocompleteSuggestionsValid ? suggestions : computeSuggestionsNow();
    const createSuggestion = validSuggestions.find((s) => s.action === "create" || s.action === "create-and-expand");
    const pickedSuggestion = preferCreate ? createSuggestion : validSuggestions[index];

    // if we don't have a `lastUpdate` since it starts out null,
    // or if the value of the `currentSuggestions` array at the `activeIndex`
    // which is changed with the arrow keys is null, or if the current nodeId
    // since it starts out null and can be null/undefined
    if (!pickedSuggestion || currentUserId == null) {
      return false;
    }
    if (!view) return false;

    const mod = autocompleteModules[autocompleteState.matcherName];

    // Since the new note may be added from an expanded note
    // we need to find the root-level note behind which to insert the new one
    const topLevelNoteNode = view.state.doc.nodeAt(view.state.selection.$anchor.before(1));
    const topLevelNoteId = topLevelNoteNode?.type === schema.nodes.note ? topLevelNoteNode.attrs.noteId : null;
    const { attrs, newNoteId } = getNodeToInsertAttrs(
      pickedSuggestion,
      topLevelNoteId ?? autocompleteState.noteId,
      autocompleteState.matcherName,
    );
    if (!attrs) return false;

    const tr = view.state.tr;

    // Double check that the selection is still in the match range. Empirically, this can be an issue
    // with hashtag autocompletes in particular, where a user hitting enter can trigger multiple hashtag mark
    // creation codepaths and a race condition ensues.
    // https://linear.app/ideaflow/issue/ENT-2531/
    // TODO: Simplify code so that marks are only created in one place and this is no longer an issue.
    if (!isSelectionInMatchRange(tr.selection, autocompleteState.match)) {
      return false;
    }

    tr.setMeta("type", "autocomplete");

    // Insert selected suggestion
    const nodeToInserts = [mod.createNode(attrs)];

    const textAfterMatch = view.state.doc.textBetween(
      autocompleteState.match.to,
      Math.min(view.state.doc.nodeSize - 2, autocompleteState.match.to + 1),
    );

    if (suffix) {
      nodeToInserts.push(schema.text(suffix));
    }

    // Ensure there's a whitespace after the inserted content, then set the cursor after it.
    // This lets the user keep typing after insertion on iOS keyboards.
    if (textAfterMatch.trimStart() === textAfterMatch) {
      // Add whitespace if there is none. See https://linear.app/ideaflow/issue/ENT-437
      tr.replaceWith(autocompleteState.match.from, autocompleteState.match.to, [...nodeToInserts, schema.text(" ")]);
    } else {
      // If there already is whitespace, set the cursor after the existing
      // whitespace. This makes the behaviour feel consistent with the case
      // where whitespace is added.
      tr.replaceWith(autocompleteState.match.from, autocompleteState.match.to, nodeToInserts).setSelection(
        Selection.near(tr.doc.resolve(tr.selection.to + 1)),
      );
    }

    // If a new note was created, insert it after current note
    if (newNoteId) {
      const note = appNoteStore.get(newNoteId)!;
      tr.insert(tr.selection.$anchor.after(1), noteToProsemirrorNode(note));
    }

    // Optionally expand the reference and focus on it
    const action = pickedSuggestion.action;
    if (action === "create-and-expand") {
      expandOrCollapseReference(tr, tr.doc.resolve(autocompleteState.match.from));
      const posExpansion = getLinkedNotePosFromReferencePos(tr.doc, autocompleteState.match.from, "start");
      tr.setSelection(Selection.near(tr.doc.resolve(posExpansion)));
    }

    if (!tr.docChanged) return true;
    view.dispatch(tr);
    view.focus();

    return true;
  };

  if (pickItRef) {
    pickItRef.current = pickIt;
  }

  if (!autocompleteState || suggestions.length === 0 || activeElementIndex >= suggestions.length) {
    mouseMovedRef.current = false;
    return null;
  }

  return (
    <div className="suggestion-item-list">
      {suggestions.map((s, i) => (
        <MenuItem
          key={s.id + i}
          setAsActiveElement={() => {
            if (mouseMovedRef.current) setActiveElementIndex(i);
          }}
          isHighlighted={i === activeElementIndex}
          suggestion={s}
          onClick={() => pickIt(i)}
        />
      ))}
    </div>
  );
};
