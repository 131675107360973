import { colors } from "../../utils/style";

interface Props {
  text: string;
}

export const SidebarSectionEmptyState: React.FC<Props> = ({ text }) => {
  return (
    <div
      style={{
        color: colors.text.tertiary,
        fontSize: 12,
        marginLeft: 4,
      }}
    >
      {text}
    </div>
  );
};
