import React from "react";
import { Hash } from "react-feather";
import { useAtomValue } from "jotai";
import { appNoteStore, pinnedPositions } from "../../model/services";
import { SearchQuerySetter } from "../../search/SearchQuery";
import { countHashtag } from "../countUtils";
import { updateSearchQuery } from "../../search/useSearchQuery";
import { userSettingsAtom } from "../../model/atoms";
import { upsertUserSettings } from "../../model/userSettings";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { addToast } from "../../components/Toast";
import { PinnedHashtag } from "../../../shared/types";
import { renameHashtag } from "../../model/store/utils";
import ElementBrowser, { SidebarElement } from "./ElementBrowser";

export const HashtagList: React.FC<{ setSearch: SearchQuerySetter }> = ({ setSearch }) => {
  const { pinnedHashtags } = useAtomValue(userSettingsAtom);

  const elements: SidebarElement[] = countHashtag(appNoteStore).map(({ id, count, content }) => ({
    id,
    count,
    content: content.slice(1), // remove the leading #
    setSearch: () => setSearch({ hashtagsList: [id] }),
    rename: (newName: string) => {
      renameHashtag(content, "#" + newName);
      updateSearchQuery({ hashtagsList: ["#" + newName] });
    },
    isPinned: !!pinnedHashtags.find((h) => h.id === id),
    setIsPinned: (newVal: boolean) => {
      const isPinned = !!pinnedHashtags.find((h) => h.id === id);
      if (newVal && !isPinned) {
        const newPinned: PinnedHashtag = { id, positionInPinned: pinnedPositions.generateFirst()[0], type: "hashtag" };
        upsertUserSettings({ pinnedHashtags: [newPinned, ...pinnedHashtags] });
        addToast("Hashtag pinned");
        trackEvent("hashtag_pinned", id);
      } else if (!newVal && isPinned) {
        upsertUserSettings({ pinnedHashtags: pinnedHashtags.filter((h) => h.id !== id) });
        addToast("Hashtag unpinned");
        trackEvent("hashtag_unpinned", id);
      }
    },
  }));

  return (
    <ElementBrowser
      header="Hashtags"
      type="hashtag"
      Icon={Hash}
      elements={elements}
      localStorageKey={"hashtag_sort"}
      placeholderText={"Type # to add your first hashtag"}
    />
  );
};
