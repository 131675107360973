import { useAtom, useAtomValue, atom, getDefaultStore } from "jotai";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { trackEvent } from "../analytics/analyticsHandlers";
import { clearSearchBarAndQuery } from "../search/SearchBar";
import { TOP_SCROLL, saveLastEditorScroll } from "../model/editorScroll";

export const navigationHistoryAtom = atom<string[]>([]);
const navigationFromAppAtom = atom(false);
const setNavigationFromApp = (v: boolean) => getDefaultStore().set(navigationFromAppAtom, v);
const getNavigationFromApp = () => getDefaultStore().get(navigationFromAppAtom);

/**
 * A hook to go to the home page.
 * @throw Error if the navigation history hasn't been initialized with {@link useInitNavigationHistory}
 */
export const useHome = () => {
  const navigationHistory = useAtomValue(navigationHistoryAtom);
  if (navigationHistory.length === 0) {
    throw new Error(
      "Tried calling useHome while navigation history is empty. Did you forget to call useNavigationHistory?",
    );
  }
  return () => {
    clearSearchBarAndQuery();
    saveLastEditorScroll(TOP_SCROLL);
    setNavigationFromApp(true);
  };
};

/**
 * A hook to go back in the navigation history. If the user is on the first
 * page in the history, go to the home page instead.
 * @throw Error if the navigation history hasn't been initialized with {@link useInitNavigationHistory}
 */
export const useBack = () => {
  const [navigationHistory] = useAtom(navigationHistoryAtom);
  if (navigationHistory.length === 0) {
    throw new Error(
      "Tried calling useBack while navigation history is empty. Did you forget to call useNavigationHistory?",
    );
  }
  const home = useHome();
  const router = useRouter();
  return useCallback(() => {
    if (navigationHistory.length <= 1) {
      home();
    } else {
      router.back();
    }
    setNavigationFromApp(true);
  }, [navigationHistory, home, router]);
};

/**
 * Initialize navigation history
 *
 * This is used in conjunction with the `useBack` hooks to differentiate between
 * the user going back using the browser back button and the app back button.
 *
 * Navigation history is a stack of visited pages, including the current page,
 * where each page is represented by their associated `window.history.state.key`
 * value.
 *
 * When the user navigates to a new page or uses the browser forward button, add
 * it to the history stack. When the user uses the browser back button, remove
 * the last page from the history stack.
 * */
export const useInitNavigationHistory = () => {
  const router = useRouter();
  const [navigationHistory, setNavigationHistory] = useAtom(navigationHistoryAtom);

  // Initialize history with the current page
  if (navigationHistory.length === 0) {
    setNavigationHistory([window.history.state.key]);
  }

  // Update history when the user navigates to a new page
  useEffect(() => {
    const updateNavigationHistory = () => {
      setNavigationHistory((history) => {
        const current = window.history.state.key;
        if (current === history[history.length - 2]) {
          return history.slice(0, -1); // back
        } else if (current === history[history.length - 1]) {
          return history; // noop
        } else {
          return [...history, current]; // forward or new page
        }
      });
    };
    router.events.on("routeChangeComplete", updateNavigationHistory);
    return () => {
      router.events.off("routeChangeComplete", updateNavigationHistory);
    };
  }, [router, setNavigationHistory]);

  // Track when the user uses the browser history button
  useEffect(() => {
    const handlePopState = () => {
      if (getNavigationFromApp()) {
        trackEvent("navigate_history_from_browser");
      }
      setNavigationFromApp(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
};
