import { Command } from "prosemirror-state";
import { schema } from "../../schema";
import { findParent } from "../../utils/find";
import { toggleCondensedOnNoteNode } from "./utils";

export const expandEllipsisCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const { $from, $to } = state.selection;
  if ($from.pos !== $to.pos) return false;
  const [node, pos] = findParent(state.doc, $from.pos, (node) => node.type === schema.nodes.note);
  if (!node || !node.attrs.isCondensed) {
    return false;
  }
  dispatch(toggleCondensedOnNoteNode(state.tr, pos));
  return true;
};

export const collapseEllipsisCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const { $from, $to } = state.selection;
  if ($from.pos !== $to.pos) return false;
  // Get note containing ellipsis
  const [node, pos] = findParent(state.doc, $from.pos, (node) => node.type === schema.nodes.note);
  if (!node || node.attrs.isCondensed) {
    return false;
  }
  dispatch(toggleCondensedOnNoteNode(state.tr, pos));
  return true;
};
