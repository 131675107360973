import { useAtom } from "jotai";
import { useCallback } from "react";
import { useShortcuts } from "../../shortcuts/useShortcuts";
import { getShortcutString } from "../../shortcuts/shortcuts";
import { colors } from "../../utils/style";
import { isSidebarOpenAtom } from "../../model/atoms";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { NavButton } from "./NavButton";

export const ToggleSideBarButton = ({ size = 20 }: { size?: number }) => {
  const { shortcuts } = useShortcuts();
  const [showSidebar, setShowSidebar] = useAtom(isSidebarOpenAtom);
  const tooltip = `${showSidebar ? "Hide sidebar " : "Show sidebar "} ${getShortcutString(shortcuts.toggleSidebar)}`;
  const onClick = useCallback(() => {
    setShowSidebar((v) => !v);
    trackEvent("toggle_sidebar_from_header");
  }, [setShowSidebar]);

  return (
    <NavButton
      id="toggle-sidebar-button"
      aria-label={tooltip}
      tabIndex={0}
      role="tooltip"
      data-microtip-position="right"
      onClick={onClick}
    >
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.33334 2.5H16.6288C17.5702 2.5 18.3333 3.21954 18.3333 4.10714V15.8929C18.3333 16.7805 17.5702 17.5 16.6288 17.5H8.33334"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M1.66666 4.10714C1.66666 3.21954 2.41285 2.5 3.33332 2.5H8.33332V17.5H3.33332C2.41285 17.5 1.66666 16.7805 1.66666 15.8929V4.10714Z"
          fill={showSidebar ? colors.bg.tertiary : "none"}
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    </NavButton>
  );
};
