import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { CustomizableShortcut, Shortcut } from "../../shared/types";
import { updateShortcut, useShortcuts } from "../shortcuts/useShortcuts";
import { colors, mediaQueries } from "../utils/style";
import PenIcon from "../shortcuts/PenIcon";
import { ShortcutIcons } from "../shortcuts/KeyIcons";
import { isMobile } from "../utils/environment";

const Button = styled.button<{ main: boolean }>(
  (props) => `
  font-size: 14px;
  border-radius: 4px;
  border: 0;
  background: ${props.main ? colors.text.accent : colors.bg.secondary};
  color: ${props.main ? colors.bg.primary : colors.text.primary};
  padding: 4px 6px;
  margin-right: 8px;
`,
);

const ShortcutRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${mediaQueries.mobile} {
    float: initial;
    width: 100%;
  }
`;

enum EditStatus {
  Pristine,
  BeingEdited,
}

export default function ShortcutEntry({ shortcut }: { shortcut: Shortcut }) {
  const [editStatus, setEditStatus] = useState(EditStatus.Pristine);

  if (editStatus === EditStatus.Pristine) {
    return (
      <ShortcutRow>
        <div
          style={{
            minWidth: "9em",
            marginRight: "8px",
          }}
        >
          <ShortcutIcons keys={shortcut.keysForShortcutList ? shortcut.keysForShortcutList : shortcut.keys} />{" "}
          {!isMobile && shortcut.isCustomizable && <PenIcon onClick={() => setEditStatus(EditStatus.BeingEdited)} />}
        </div>
        <div className="shortcutLabel">{shortcut.label}</div>
      </ShortcutRow>
    );
  } else if (shortcut.isCustomizable) {
    return <KeyboardShortcutInput setEditStatus={setEditStatus} shortcut={shortcut} />;
  } else {
    return null;
  }
}

// To input a keyboard shortcut
function KeyboardShortcutInput({ setEditStatus, shortcut }: { setEditStatus: any; shortcut: CustomizableShortcut }) {
  const { shortcuts } = useShortcuts();

  const [keyPressed, setKeyPressed] = useState(shortcut.keys);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const isTaken = useCallback(
    (newKeys: string) => Object.values(shortcuts).some((s) => s.keys === newKeys && s.id !== shortcut.id),
    [shortcut, shortcuts],
  );

  const save = useCallback(() => {
    if (keyPressed === shortcut.keys) {
      setEditStatus(EditStatus.Pristine);
    } else if (isTaken(keyPressed)) {
      setErrorMsg("Already taken");
    } else {
      updateShortcut(shortcut.id, keyPressed);
      setEditStatus(EditStatus.Pristine);
    }
  }, [keyPressed, shortcut.keys, shortcut.id, isTaken, setEditStatus]);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      if (e.key === "Escape") {
        setEditStatus(EditStatus.Pristine);
        return;
      } else if (e.key === "Enter") {
        save();
        return;
      }

      let keyPressed = "";
      keyPressed += e.altKey ? "Alt-" : "";
      keyPressed += e.ctrlKey ? "Ctrl-" : "";
      keyPressed += e.metaKey ? "Meta-" : "";
      keyPressed += e.shiftKey ? "Shift-" : "";
      // exclude pure modifier key presses.
      if (!["Shift", "Alt", "Meta", "Cmd", "Ctrl", "Command", "Control"].includes(e.key)) {
        if (e.altKey) {
          // alt key activates diacritics
          keyPressed += String.fromCharCode(e.keyCode);
        } else {
          keyPressed += e.key;
        }
      }

      setKeyPressed(keyPressed);
      setErrorMsg(null);
    };
    document.addEventListener("keydown", keyDownHandler, true);
    return () => document.removeEventListener("keydown", keyDownHandler, true);
  }, [save, setEditStatus]);

  return (
    <ShortcutRow>
      <div style={{ paddingRight: 12 }}>
        <ShortcutIcons keys={keyPressed} />
      </div>
      <Button main={true} onClick={save}>
        Save
      </Button>
      <Button main={false} onClick={() => setEditStatus(EditStatus.Pristine)}>
        Cancel
      </Button>
      {errorMsg && <div style={{ color: colors.text.negative }}>{errorMsg}</div>}
    </ShortcutRow>
  );
}
