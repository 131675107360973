import { TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { EditorSelection, SELECTION_TYPE } from "../model/selection";
import { getParentNote } from "../editor/utils/find";
import { descendNotes } from "../editor/utils/descendNotes";
import logger from "../utils/logger";

/** Get note selection in editor */
export function getNoteSelection(view: EditorView): EditorSelection | null {
  if (!view.hasFocus()) return null;
  if (!(view.state.selection instanceof TextSelection)) {
    // Selection in the general case is much harder to restore correctly, so only restore TextSelection
    return null;
  }
  const doc = view.state.doc;
  const { from, to } = view.state.selection;
  const [fromNoteNode, fromNotePos] = getParentNote(doc, from);
  const [toNoteNode, toNotePos] = getParentNote(doc, to);
  if (!fromNoteNode || !toNoteNode) return null;
  return {
    type: SELECTION_TYPE.EDITOR,
    fromNotePath: fromNoteNode.attrs.path,
    toNotePath: toNoteNode.attrs.path,
    fromOffset: from - fromNotePos,
    toOffset: to - toNotePos,
  };
}

/** Set note selection on transaction and return whether it was set */
export function setNoteSelection(tr: Transaction, selection: EditorSelection | null): boolean {
  if (!selection) return false;
  let from: number | null = null;
  let to: number | null = null;
  if (selection) {
    const { fromNotePath, toNotePath, fromOffset, toOffset } = selection;
    descendNotes(tr.doc, (node, pos) => {
      if (from !== null && to !== null) return false;
      if (node.attrs.path === fromNotePath) {
        from = pos + fromOffset;
      }
      if (node.attrs.path === toNotePath) {
        to = pos + toOffset;
      }
    });
  }
  if (from !== null && to !== null) {
    // TextSelection.create() can throw if {from, to} is out of range of
    // the new note. Wrapping this in a try-catch is simpler than trying
    // to check the positions manually.
    try {
      tr.setSelection(TextSelection.create(tr.doc, from, to));
      return true;
    } catch (e) {
      logger.info("Could not restore cursor selection position after editor re-render", { namespace: "editor" });
    }
  }
  return false;
}
