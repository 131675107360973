import { Command } from "prosemirror-state";

/**
 * On arrow-left key press, if inline code is the first node inside it's parent (paragraph)
 * and cursor is on extreme left, insert an space before inline code and move cursor there.
 */
export const handleArrowLeft: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $from } = state.selection;

  if ($from.parentOffset === 0) {
    const tr = state.tr.insertText(" ", $from.pos).removeMark($from.pos, $from.pos + 1);
    dispatch(tr);
    return true;
  }
  return false;
};

/**
 * On arrow-right key press, if inline code is the last node inside it's parent (paragraph)
 * and cursor is on extreme right, insert an space after inline code and move cursor there.
 */
export const handleArrowRight: Command = (state, dispatch) => {
  if (!dispatch) {
    return false;
  }

  const { $to } = state.selection;

  if ($to.parentOffset === $to.parent.textContent.length) {
    const tr = state.tr.insertText(" ", $to.pos).removeMark($to.pos, $to.pos + 1);
    dispatch(tr);
    return true;
  }

  return false;
};
