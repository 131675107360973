export function formatInsertedAt(date: Date): string {
  return date.getFullYear() + ("" + (date.getMonth() + 1)).padStart(2, "0") + ("" + date.getDate()).padStart(2, "0");
}

export function parseInsertedAt(insertedAt: string): Date {
  if (insertedAt.length !== 8) return new Date(NaN);

  const d = new Date();
  d.setHours(0, 0, 0, 0);
  d.setFullYear(+insertedAt.substr(0, 4));
  d.setMonth(+insertedAt.substr(4, 2) - 1);
  d.setDate(+insertedAt.substr(6, 2));

  return d;
}
