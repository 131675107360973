import { Transaction, Selection } from "prosemirror-state";
import { getDefaultStore } from "jotai";
import { SearchQuery, searchQueryToPage } from "../search/SearchQuery";
import { calculateEditorScrollTop, getCurrentEditorScroll } from "../editorPage/scrollTools";
import { getEditorSelection } from "../model/selection";
import { getLastEditorScroll } from "../model/editorScroll";
import { scrollEditorToTop, setEditorPosition, setEditorPositionAtNote } from "../editorPage/App";
import { setNoteSelection } from "../editorPage/noteSelection";
import { noteListUpdatedSourceAtom } from "../model/atoms";
import { generatePathFromNoteId, getNoteIdFromTokenId } from "./utils/path";

export function setSelectionAndScroll(
  tr: Transaction,
  searchQuery: SearchQuery,
  lastSearchQuery: React.MutableRefObject<SearchQuery | null>,
) {
  const currentScroll = getCurrentEditorScroll();
  const lastScroll = getLastEditorScroll();

  const prevPos = calculateEditorScrollTop(lastScroll);
  const prevSel = getEditorSelection();

  const updateSource = getDefaultStore().get(noteListUpdatedSourceAtom);

  /** Whether the new query is the same page (ignores props like limit and sorting)*/
  const samePage =
    lastSearchQuery.current && searchQueryToPage(lastSearchQuery.current) === searchQueryToPage(searchQuery);

  if (lastSearchQuery.current === searchQuery) {
    if (updateSource === "simon") {
      // When Simon results are added to the list of notes,
      // the search query hasn't changed so there is no need to scroll
      getDefaultStore().set(noteListUpdatedSourceAtom, null);
      return;
    }
    // If the last query and current query are the same object, then the
    // the render wasn't from a search query change, and so must
    // have been from a network edit (see {@link latestNetworkEdit}).
    // Keep user's focus on the same note after a network edit
    if (currentScroll.topVisibleNote !== lastScroll.topVisibleNote) setEditorPosition(prevPos);
    // Restore selection but don't scroll to it (scroll position is restored above)
    setNoteSelection(tr, prevSel);
  } else if (
    samePage &&
    (lastSearchQuery.current?.upperLimit !== searchQuery.upperLimit ||
      lastSearchQuery.current?.lowerLimit !== searchQuery.lowerLimit)
  ) {
    if (searchQuery.upperLimit === undefined && searchQuery.lowerLimit === undefined) {
      // User pressed "jump to top" button or hit Escape, so scroll to the top
      scrollEditorToTop();
    }
    // Otherwise, user pressed "load more" button, so leave the scroll position as is
  } else if (searchQuery.jumpTo) {
    // User selected "jump to", so scroll to the note
    setEditorPositionAtNote(searchQuery.jumpTo);
  } else if (samePage) {
    // When the user does an action that doesn't change the page (e.g. presses
    // home button while on home page) scroll smoothly to the top
    setEditorPosition(prevPos);
  } else {
    // Otherwise, scroll immediately to saved scroll position. This is the
    // behaviour we want when a user navigates to a new page (immediately at
    // the top) or when they navigate back to a page (immediately at the last
    // scroll position)
    setEditorPosition(prevPos);
    if (searchQuery.noteIdList) {
      // Check if selection is inside a single note
      if (prevSel && prevSel.fromNotePath && prevSel.fromNotePath === prevSel.toNotePath) {
        const wasSet = setNoteSelection(tr, {
          ...prevSel,
          fromNotePath: generatePathFromNoteId(getNoteIdFromTokenId(prevSel.fromNotePath)),
          toNotePath: generatePathFromNoteId(getNoteIdFromTokenId(prevSel.toNotePath)),
        });
        if (!wasSet) {
          // If there's no saved selection for the note, set to end of first line
          const pos = tr.doc.nodeAt(0)?.firstChild?.nodeSize || 0;
          const sel = Selection.findFrom(tr.doc.resolve(pos), 1, true);
          if (sel) tr.setSelection(sel);
        }
      }
    }
  }
}
