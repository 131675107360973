import { EditorState, Selection, Command } from "prosemirror-state";
import { schema } from "../../schema";
import { findParent, findResult, findSelectionAtEndOfNotesFirstLine, getParentNote } from "../../utils/find";
import {
  expandOrCollapseReference,
  getLinkedNotePosFromReferencePos,
  getReferencePosFromExpandedNotePos,
} from "./referenceExpansionUtils";

function getReferenceNearSelection(state: EditorState): findResult {
  const { $from, $to } = state.selection;
  if ($from.parent.childCount === 0) return [null, null];
  // Get index of child containing selection but clamped to valid range
  const fromIdx = Math.min(Math.max(0, $from.index()), $from.parent.childCount - 1);
  // return first reference inside selection
  for (let idx = fromIdx; idx < $from.parent.childCount; idx++) {
    const pos = $from.posAtIndex(idx);
    if (pos > $to.pos) break;
    const node = $from.parent.child(idx);
    if (node?.type === schema.nodes.reference) {
      return [node, pos];
    }
  }
  // otherwise return first reference before selection
  for (let idx = fromIdx; idx >= 0; idx--) {
    const node = $from.parent.child(idx);
    if (node?.type === schema.nodes.reference) {
      return [node, $from.posAtIndex(idx)];
    }
  }
  return [null, null];
}

export const expandReferenceCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const [refNode, refPos] = getReferenceNearSelection(state);
  const [, notePos] = getParentNote(state.doc, state.selection.$anchor.pos);
  // Can't expand if we're not in a note or there's no reference nearby
  if (notePos === null || refNode === null) return false;
  // Can't expand if we're already expanded
  if (refNode.attrs.isExpanded) return false;

  const tr = state.tr;
  const res = expandOrCollapseReference(tr, tr.doc.resolve(refPos));
  if (!res) {
    return false;
  }
  const posExpansion = getLinkedNotePosFromReferencePos(state.doc, tr.mapping.map(refPos));
  const sel = findSelectionAtEndOfNotesFirstLine(tr.doc, posExpansion + 1);
  if (sel) tr.setSelection(sel);
  tr.setMeta("noChangeToModel", true);
  dispatch(tr);
  return true;
};

const trCollapseReference = (state: EditorState, posReference: number) => {
  const tr = state.tr;
  expandOrCollapseReference(tr, tr.doc.resolve(posReference));
  tr.setSelection(Selection.near(tr.doc.resolve(tr.mapping.map(posReference) + 1)));
  tr.setMeta("noChangeToModel", true);
  return tr;
};

export const collapseReferenceCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  // Collapse nearby reference
  const [refNode, refPos] = getReferenceNearSelection(state);
  if (refPos !== null && refNode?.attrs.isExpanded) {
    dispatch(trCollapseReference(state, refPos));
    return true;
  }
  const [expRefNode, expRefPos] = findParent(
    state.doc,
    state.selection.$anchor.pos,
    (n) => n.type === schema.nodes.expandedReference,
  );
  // Or expanded reference we're inside of
  if (!!expRefNode && !expRefNode.attrs.isBacklink) {
    const pos = getReferencePosFromExpandedNotePos(state.doc, expRefPos);
    dispatch(trCollapseReference(state, pos));
    return true;
  }
  return false;
};
